import { isMobile } from 'react-device-detect';
import useAppPublicCustomisations from 'hooks/useAppPublicCustomisations';
import Loader from 'components/loader/Loader';
import LayoutDesktop from './layoutDesktop/LayoutDesktop';
import LayoutMobile from './layoutMobile/LayoutMobile';

/**
 * PublicLayout is separated into mobile and desktop layouts.
 *
 * This is due to the mobile application having a different UI and utilizing the same routes from this web application.
 * In other words, only affecting public pages.
 *
 * You can see this behaviour in the ForgotPassword and Login component pages.
 */
const PublicLayout = ({ children }) => {
  const { isCustomisationsLoading } = useAppPublicCustomisations();

  if (isCustomisationsLoading) {
    return <Loader fullPage />;
  }

  return isMobile ? (
    <LayoutMobile>{children}</LayoutMobile>
  ) : (
    <LayoutDesktop>{children}</LayoutDesktop>
  );
};

export default PublicLayout;
