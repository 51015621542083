import { useTranslation } from 'react-i18next';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import API from 'services/API';
import { OwnerAdjustmentFileResponse } from 'models/ownerAdjustments/OwnerAdjustmentFile';

const deleteOwnerAdjustmentFile = async (file: OwnerAdjustmentFileResponse) => {
  return API.delete(`/api/internal/owner-adjustments/owner-adjustment-file`, {
    params: {
      url: file.url,
    },
  });
};

const useDeleteFileMutation = ({
  onSuccess,
}: { onSuccess?: () => void } = {}) => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  return useAppMutation({
    mutationFn: deleteOwnerAdjustmentFile,
    onSuccess,
    onError: () => {
      notifyError(t('pageOwnerAdjustments.modal.errorOnFileDelete'));
    },
  });
};

export default useDeleteFileMutation;
