import styled from 'styled-components';

export const Container = styled.div.attrs({ className: 'container' })`
  .buttons {
    display: flex;
    margin-top: 20px;
    justify-content: flex-start;

    & > * {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .valid-only-text {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .col-align {
    padding-left: 4px;
  }
`;
