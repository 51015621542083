import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const LeadDetailsFormLeftColumn = styled(Col)<{}>`
  display: flex;
  flex-direction: column;
  padding-top: 18px;

  .assignee-container {
    margin-bottom: 20px;

    .img-circle {
      top: -4px;
    }
  }

  .check-out-date-container {
    margin-bottom: 30px;
  }

  .assignee-container > .control-label,
  .property-container > .control-label {
    padding-top: 0;
  }
`;

export const LeadDetailsFormRightColumn = styled(LeadDetailsFormLeftColumn)<{
  $hasMorePadding: boolean;
}>`
  padding-top: ${({ $hasMorePadding }) => ($hasMorePadding ? 24 : 10)}px;
`;

export const AssigneeLabelContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
`;

export const AssigneeLabel = styled(Col).attrs({
  'data-testid': 'assignee-label',
})`
  font-weight: 700;
`;

export const BookingDateContainer = styled.div.attrs({
  className: 'text-right text-muted',
  'data-testid': 'booking-date',
})``;
