import { AmenityType } from 'models/Amenity';

export const propertySettingsBulkSavableFields = {
  airbnbSettings: [
    'bookingType',
    'cancellationPolicy',
    'checkInTimeStartFlexible',
    'checkInTimeEnd',
    'nonRefundableRateDiscount',
    'nonRefundableRateEnabled',
  ],
  amenities: Object.values(AmenityType),
  bookingDotComSettings: ['cancellationPolicyCode'],
  bookingSettings: [
    'bookingLeadTime',
    'bookingType',
    'bookingWindow',
    'checkInTime',
    'checkoutTime',
    'dayOfWeekMinimumStay',
    'daysOfTheWeekToCheckInOn',
    'fullPaymentTiming',
    'maximumStay',
    'minimumStay',
    'minimumWeekendStay',
    'percentageUponReservation',
    'turnoverDays',
  ],
  capacityDetails: ['extraGuestFee'],
  vrboSettings: ['bookingType', 'cancellationPolicy'],
};
