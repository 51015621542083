import { useContext, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import AnalyticsContext from '../AnalyticsContext';
import { AnalyticsFilter } from '../types/Analytics.types';

const AnalyticsFilterFormWatcher = () => {
  const { setFilter, analyticsFilterLocalStorageKey } =
    useContext(AnalyticsContext);
  const formValues = useWatch();

  useEffect(() => {
    setFilter({
      ...(formValues as AnalyticsFilter),
    });

    localStorage.setItem(
      analyticsFilterLocalStorageKey,
      JSON.stringify(formValues),
    );
  }, [formValues]);

  return null;
};

export default AnalyticsFilterFormWatcher;
