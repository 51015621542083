import { Row } from '@tanstack/react-table';
import Button from 'components/button/Button';
import { RentalConditions } from 'models/RentalCondition';
import { useTranslation } from 'react-i18next';
import useEditRentalConditions from '../modals/useEditRentalConditions';

const ActionsCell = ({ row }: { row: Row<RentalConditions> }) => {
  const { t } = useTranslation();

  const { openEditModal } = useEditRentalConditions({
    rentalConditions: row.original,
  });

  return (
    <Button bsStyle="link" onClick={openEditModal}>
      {t('common.edit')}
    </Button>
  );
};

export default ActionsCell;
