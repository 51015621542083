import styled from 'styled-components';
import AsyncTypeAheadField from 'components/forms/typeAheadField/AsyncTypeAheadField';
import theme from 'styles/theme';

export const PropertySearchOption = styled.div`
  padding: 6px 12px;
  width: 100%;
  text-align: left;

  &:hover {
    background-color: ${theme.colors.bgHover};
  }
`;

export const PropertySearchFieldAsyncTypeahead = styled(AsyncTypeAheadField)`
  .dropdown-menu {
    width: 260px !important;
  }

  [role='option'][aria-selected='true'] ${PropertySearchOption} {
    background-color: ${theme.colors.bgHover};
  }

  [role='option'].dropdown-item.rbt-menu-custom-option {
    padding: 6px 12px;
  }
`;
