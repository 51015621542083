import { useContext } from 'react';
import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';
import {
  enableQuery,
  getTimezoneDateString,
  prepareAllPropertyUids,
} from 'utils/analytics/analyticsUtils';
import useAppUser from '../../hooks/useAppUser';
import AnalyticsContext from './AnalyticsContext';
import {
  AnalyticsKeyMetric,
  AnalyticsKeyMetricsResponse,
} from './types/AnalyticsKeyMetrics.types';

const useKeyMetricsData = () => {
  const { filter, allProperties } = useContext(AnalyticsContext);
  const { agencyUid, agency } = useAppUser();

  const {
    data: keyMetrics,
    isLoading: isKeyMetricsLoading,
    error: keyMetricsError,
  } = useAppQuery(
    [
      'getKeyMetrics',
      filter?.dateType,
      filter?.currency,
      filter?.propertyUid,
      filter?.channel,
      filter?.range,
      agencyUid,
    ],
    async () => {
      const { dateType, currency, propertyUid, channel, range } = filter;
      const response = await API.post<AnalyticsKeyMetricsResponse>(
        `/api/internal/analytics/key-metrics`,
        {
          agencyUid,
          dateType,
          from: getTimezoneDateString(range.startDate, agency?.timeZone),
          to: getTimezoneDateString(range.endDate, agency?.timeZone),
          currency,
          propertyUids: prepareAllPropertyUids({
            allProperties,
            currency,
            propertyUid,
          }),
          channel,
          metrics: [
            AnalyticsKeyMetric.RENTAL_REVENUE,
            AnalyticsKeyMetric.NIGHTS_BOOKED,
            AnalyticsKeyMetric.OCCUPANCY_RATE,
            AnalyticsKeyMetric.AVERAGE_DAILY_RATE,
            AnalyticsKeyMetric.REV_PAR,
          ],
        },
      );
      return response.data.metricsValues;
    },
    {
      enabled: enableQuery({
        agencyUid,
        filter,
        allProperties,
      }),
    },
  );

  return {
    keyMetrics,
    isKeyMetricsLoading,
    keyMetricsError,
  };
};

export default useKeyMetricsData;
