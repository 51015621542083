import { PropertyMultiSelectFieldOption } from 'components/forms/domain/property/PropertyMultiSelectField/PropertyMultiSelectField.types';
import useAppModal from 'hooks/useAppModal';
import { linkSubUnitsModalId } from './LinkSubUnitsModal.constants';
import useLinkSubUnitsMutation from './useLinkSubUnitsMutation';

const useLinkSubUnitsModalForm = (propertyUid: string) => {
  const { closeModal } = useAppModal();
  const { mutate: linkSubUnits, isLoading: isLinkingSubUnits } =
    useLinkSubUnitsMutation({
      onSuccess: () => closeModal(linkSubUnitsModalId),
    });

  const handleSubmit = (formValues: {
    properties: PropertyMultiSelectFieldOption[];
  }) => {
    const propertyUidsToLink = formValues.properties?.map(({ value }) => value);
    linkSubUnits({ propertyUid, propertyUidsToLink });
  };

  return {
    handleSubmit,
    isUpdating: isLinkingSubUnits,
  };
};

export default useLinkSubUnitsModalForm;
