import { useLayoutEffect, useState } from 'react';
import { Breakpoint, getScreenBreakpoint } from '../styles/mediaQuery';
import { debounce } from '../utils/funcs';

const useScreenBreakpoints = () => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<Breakpoint>(
    getScreenBreakpoint(window.innerWidth),
  );

  useLayoutEffect(() => {
    const resizeListener = debounce(() => {
      setCurrentBreakpoint(getScreenBreakpoint(window.innerWidth));
    });
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return {
    breakpoint: currentBreakpoint,
    isXS: currentBreakpoint === Breakpoint.XS,
    isSM: currentBreakpoint === Breakpoint.SM,
    isMD: currentBreakpoint === Breakpoint.MD,
    isLG: currentBreakpoint === Breakpoint.LG,
  };
};

export default useScreenBreakpoints;
