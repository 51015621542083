import styled from 'styled-components';

export const LoadingPlaceholderStyled = styled.span<{
  $circle?: boolean;
  $linesCount?: number;
  $margin?: string;
  $noAnimation?: boolean;
  $width?: string;
}>`
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block;
  background-color: var(--base-color);
  width: ${({ $width }) => $width || '100%'};
  border-radius: 5px;
  display: inline-flex;
  line-height: ${({ $linesCount }) => $linesCount || 1};
  position: relative;
  overflow: hidden;
  z-index: 1;
  ${({ $margin }) => !!$margin && `margin: ${$margin};`}

  ${({ $circle, $width }) =>
    $circle &&
    `
      height: ${$width || '100%'};
      border-radius: 50%;
    `}

  ${({ $noAnimation }) =>
    !$noAnimation &&
    `
      @keyframes loading-placeholder-animation {
        100% {
          transform: translateX(100%);
        }
      }

      ::after {
        content: '\u200c';
        display: var(--pseudo-element-display);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        background-repeat: no-repeat;
        background-image: linear-gradient(
          90deg,
          var(--base-color),
          var(--highlight-color),
          var(--base-color)
        );
        transform: translateX(-100%);
      
        animation-name: loading-placeholder-animation;
        animation-direction: var(--animation-direction);
        animation-duration: var(--animation-duration);
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
      }
      
      @media (prefers-reduced-motion) {
        --pseudo-element-display: none;
      }    
    `}
`;
