import styled from 'styled-components';
import { Badge as BadgeBS } from 'react-bootstrap';
import theme from 'styles/theme';
import { UnreadType } from '../../Header.types';

const bgColors: Record<UnreadType, string> = {
  message: theme.colors.bgGreen,
  lead: '#b94a48',
};

export const BadgeContainer = styled(BadgeBS)<{ type: UnreadType }>`
  background-color: ${({ type }) => bgColors[type]};

  margin-left: 5px;
  padding: 2px 9px;
`;
