import { UseQueryOptions } from '@tanstack/react-query';
import useAppQuery from 'hooks/useAppQuery';
import useAppUser from 'hooks/useAppUser';
import { GetHotelsResponseTO, Hotel } from 'models/Hotel';
import API from 'services/API';
import { API_MAX_LIMIT } from 'services/API.constants';

export const HOTELS_BASE_QUERY_KEY = 'hotels';
const DEFAULT_LIMIT = API_MAX_LIMIT;
const DEFAULT_OFFSET = 0;

type ReactQueryOptions = Omit<UseQueryOptions<Hotel[]>, 'queryKey' | 'queryFn'>;

type UseFetchHotelsArgs = {
  limit?: number;
  offset?: number;
} & ReactQueryOptions;

const useFetchHotels = ({
  limit,
  offset,
  enabled,
  ...options
}: UseFetchHotelsArgs = {}) => {
  const { agencyUid } = useAppUser();

  return useAppQuery({
    queryKey: [HOTELS_BASE_QUERY_KEY, agencyUid],
    queryFn: async () => {
      const response = await API.get<GetHotelsResponseTO>(
        '/api/internal/hotels',
        {
          params: {
            agencyUid,
            offset: offset ?? DEFAULT_OFFSET,
            limit: limit ?? DEFAULT_LIMIT,
          },
        },
      );

      return response.data.hotels;
    },
    enabled: !!agencyUid && enabled !== undefined ? enabled : true,
    staleTime: 30000,
    keepPreviousData: true,
    ...(options ?? {}),
  });
};

export default useFetchHotels;
