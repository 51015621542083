import { ColumnDef } from '@tanstack/react-table';
import useTable from './useTable';

interface UseClientFilteredTableArgs<TData> {
  columns: ColumnDef<TData, unknown>[];
  data: TData[];
}

const useClientFilteredTable = <TData>({
  data,
  columns,
}: UseClientFilteredTableArgs<TData>) => {
  const tableInstance = useTable({
    data,
    columns,
    initialState: {
      pagination: { pageIndex: 0, pageSize: 20 },
    },
    autoResetPageIndex: false,
  });

  return { tableInstance, columns };
};

export default useClientFilteredTable;
