import { useEffect, useState } from 'react';
import useFetchPropertyChannelSettings from 'components/domain/channelSettings/useFetchPropertyChannelSettings';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import { Channel } from 'models/Channels';
import { AmenityChannel } from 'models/Amenity';
import useFetchAgencyChannelSettings from 'components/domain/channelSettings/useFetchAgencyChannelSettings';

const channelEnumIdToSupportedChannel = {
  [Channel.orbirental]: AmenityChannel.HOSTFULLY,
  [Channel.airbnb]: AmenityChannel.AIRBNB,
  [Channel.homeaway]: AmenityChannel.VRBO,
  [Channel.booking_com]: AmenityChannel.BOOKINGDOTCOM,
  [Channel.google]: AmenityChannel.GOOGLE,
  [Channel.hvmi]: AmenityChannel.HVMI,
};

export interface EnabledChannel {
  channel: AmenityChannel;
  isActive: boolean;
}

const useEnabledChannels = () => {
  const [channels, setChannels] = useState<EnabledChannel[]>([]);
  const {
    property: { uid: propertyUid },
    isLoading: isFetchingPropertySettings,
  } = usePropertySettingsInitData();
  const {
    channelSettings: agencyChannelSettings,
    isFetching: isFetchingChannelSettings,
  } = useFetchAgencyChannelSettings();
  const {
    channelSettings: propertyChannelSettings,
    isFetching: isFetchingPropertyChannelSettings,
  } = useFetchPropertyChannelSettings(propertyUid);

  const isFetching =
    isFetchingPropertySettings ||
    isFetchingChannelSettings ||
    isFetchingPropertyChannelSettings;

  useEffect(() => {
    if (!agencyChannelSettings || !propertyChannelSettings) {
      return;
    }

    const enabledChannels = [
      { channel: AmenityChannel.HOSTFULLY, isActive: true },
    ];

    agencyChannelSettings.forEach((agencyChannel) => {
      if (
        agencyChannel.enumId === Channel.orbirental ||
        !channelEnumIdToSupportedChannel[agencyChannel.enumId] ||
        !agencyChannel.isActive
      ) {
        return;
      }

      enabledChannels.push({
        channel: channelEnumIdToSupportedChannel[agencyChannel.enumId],
        // if channel is enabled at agency level, but not on property level, it should still show but be inactive:
        isActive: !!propertyChannelSettings.find(
          (propertyChannel) =>
            propertyChannel.enumId === agencyChannel.enumId &&
            propertyChannel.isActive,
        ),
      });
    });

    setChannels(enabledChannels);
  }, [agencyChannelSettings, propertyChannelSettings]);

  const toggleChannel = (channel: AmenityChannel) => {
    const updatedChannels = channels.map((c) => {
      if (c.channel === channel) {
        return {
          ...c,
          isActive: !c.isActive,
        };
      }

      return c;
    });

    setChannels(updatedChannels);
  };

  return {
    activeChannels: channels.filter((c) => c.isActive).map((c) => c.channel),
    channels,
    toggleChannel,
    isFetching,
  };
};

export default useEnabledChannels;
