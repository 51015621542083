import useCalendarMode from 'pages/stackedCalendar/useCalendarMode';
import { selectionLead } from '../common/body/selection/CalendarBodySelection.constants';
import { CalendarDayCellSelectionType } from '../common/body/selection/CalendaBodySelection.types';
import useCalendarDayCellSelection from '../common/body/selection/useCalendarDayCellSelection';
import {
  SelectionPricingViewContainer,
  SelectionViewContainer,
  SelectionViewWrapper,
} from './LeadView.style';

interface Props {
  dayDate: Date;
  propertyUid: string;
}

const SelectionView = ({ dayDate, propertyUid }: Props) => {
  const { isPricingMode } = useCalendarMode();
  const { selectionType } = useCalendarDayCellSelection({
    dayDate,
    propertyUid,
  });

  if (isPricingMode && selectionType === CalendarDayCellSelectionType.PRICING) {
    return <SelectionPricingViewContainer />;
  }

  if (selectionType) {
    return (
      <SelectionViewContainer $selectionType={selectionType}>
        <SelectionViewWrapper
          data-test-id="day-cell-selection"
          $lead={selectionLead}
          $selectionStart={selectionType === CalendarDayCellSelectionType.START}
          $inSelectionRange={
            selectionType === CalendarDayCellSelectionType.ONGOING
          }
          $selectionEnd={selectionType === CalendarDayCellSelectionType.END}
          $isSelectionOnSameDay={
            selectionType === CalendarDayCellSelectionType.START_AND_END
          }
        />
      </SelectionViewContainer>
    );
  }

  return null;
};

export default SelectionView;
