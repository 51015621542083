import { UserType } from '../../../models/Users';
import { ThreadParticipantType } from '../../../models/inbox/Threads';

export const UserTypeToThreadParticipantTypeMapping = {
  [UserType.Agent]: ThreadParticipantType.AGENT,
  [UserType.Employee]: ThreadParticipantType.AGENCY,
  [UserType.Guest]: ThreadParticipantType.GUEST,
  [UserType.Owner]: ThreadParticipantType.OWNER,
  [UserType.ServiceProviderUser]: ThreadParticipantType.SERVICE_PROVIDER,
};
