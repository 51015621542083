import { useCallback, useContext, useEffect, useState } from 'react';
import API from '../../../services/API';
import InboxContext from '../InboxContext';
import useAppUser from '../../../hooks/useAppUser';
import useAppQuery from '../../../hooks/useAppQuery';
import {
  GetLeadRelatedThreadsResponseTO,
  GetThreadParticipantBestProfileResponseTO,
  GetThreadResponseTO,
  GetThreadsResponseTO,
  Thread,
} from '../../../models/inbox/Threads';
import useInboxThreadFilterParams from './useInboxThreadFilterParams';
import { UserTypeToThreadParticipantTypeMapping } from './InboxThread.constants';
import {
  isAllowedCommunicationThread,
  sortThreadsByUpdateDateIfApplicable,
} from './InboxThreads.utils';

export const getThreadInterlocutorUid = ({ thread, userType }) => {
  const currentUserParticipantType =
    UserTypeToThreadParticipantTypeMapping[userType];

  const interlocutor = thread.participants.find(
    ({ participantType }) => participantType !== currentUserParticipantType,
  );
  return interlocutor?.uid;
};

const getRequestedThreadUid = async ({ leadUidParam, threadUidParam }) => {
  if (leadUidParam) {
    try {
      const leadResponse = await API.get<GetLeadRelatedThreadsResponseTO>(
        `/v3/lead-related-threads?leadUid=${leadUidParam}`,
      );

      if (leadResponse?.data?.threadRelatedLeads?.length) {
        const { threadUid } = leadResponse.data.threadRelatedLeads[0];
        return threadUid;
      }
    } catch (err) {
      return null;
    }
  }

  return threadUidParam;
};

const getThreadByUid = async (threadUid: string) => {
  const threadResponse = await API.get<GetThreadResponseTO>(
    `/v3/threads/${threadUid}`,
  );

  return threadResponse?.data?.thread;
};

const limit = 20;

const getLimitForFetching = () => {
  return limit + 1; // increase the limit by one in order to determine if there are more threads
};

const areMoreThreadsAvailable = (fetchedThreads?: Thread[]) => {
  const moreThreadsAvailable = fetchedThreads?.length > limit;
  if (moreThreadsAvailable) {
    fetchedThreads.splice(limit, 1); // not the nicest solution, should be possibly replaced with using total number of threads returned from the backend (not implemented there yet)
  }
  return moreThreadsAvailable;
};

const useInboxThreads: () => {
  isLoading: boolean;
  isLoadingMoreThreads: boolean;
  hasMore: boolean;
  loadMore: () => void;
  threads: Thread[];
} = () => {
  const [
    isThreadParticipantBestProfilesUpdating,
    setIsThreadParticipantBestProfilesUpdating,
  ] = useState(false);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [threads, setThreads] = useState<Thread[]>([]);
  const [threadsForBestProfilesFetch, setThreadsForBestProfilesFetch] =
    useState<Thread[]>();
  const { userType } = useAppUser();
  const {
    threadParticipantBestProfilesMap,
    threadsWithNewMessages,
    setThreadsWithNewMessages,
    getInitialSearchParams,
    updateActiveThreadData,
    updateThreadParticipantBestProfilesMap,
    updateActiveMessageThreadUid,
  } = useContext(InboxContext);
  const { leadUid, params, sortBy } = useInboxThreadFilterParams();

  const { data: threadsData, isLoading: isThreadsLoading } = useAppQuery(
    ['threads', leadUid, params],
    async () => {
      setOffset(0);

      if (leadUid) {
        const leadResponse = await API.get<GetLeadRelatedThreadsResponseTO>(
          `/v3/lead-related-threads?leadUid=${leadUid}`,
        );

        if (leadResponse?.data?.threadRelatedLeads?.length) {
          const { threadUid } = leadResponse.data.threadRelatedLeads[0];
          const thread = await getThreadByUid(threadUid);

          return thread ? { fetchedThreads: [thread] } : { fetchedThreads: [] };
        }

        return { fetchedThreads: [] };
      }

      const response = await API.get<GetThreadsResponseTO>('/v3/threads', {
        params: {
          ...params,
          guestAndAgencyCommunicationOnly: true,
          limit: getLimitForFetching(),
          offset: 0,
        },
      });
      const fetchedThreads = response.data.threads;

      const { leadUidParam, threadUidParam } = getInitialSearchParams();
      const requestedThreadUid = await getRequestedThreadUid({
        leadUidParam,
        threadUidParam,
      });

      if (
        requestedThreadUid &&
        !fetchedThreads.find(({ uid }) => uid === requestedThreadUid)
      ) {
        const threadResponse = await API.get<GetThreadResponseTO>(
          `/v3/threads/${requestedThreadUid}`,
        );

        if (threadResponse?.data?.thread) {
          fetchedThreads.unshift(threadResponse.data.thread);
        }
      }

      return {
        requestedLeadUid: requestedThreadUid && leadUidParam,
        requestedThreadUid,
        fetchedThreads,
      };
    },
    { enabled: !!leadUid || !!params },
  );

  const { data: moreThreads, isInitialLoading: isLoadingMoreThreads } =
    useAppQuery(
      ['moreThreads', offset],
      async () => {
        const response = await API.get<GetThreadsResponseTO>('/v3/threads', {
          params: {
            ...params,
            guestAndAgencyCommunicationOnly: true,
            limit: getLimitForFetching(),
            offset,
          },
        });

        return response.data.threads;
      },
      { enabled: offset > 0 },
    );

  const {
    data: bestProfiles,
    isInitialLoading: isThreadParticipantBestProfilesLoading,
  } = useAppQuery(
    ['bestProfiles', threadsForBestProfilesFetch],
    async () => {
      setIsThreadParticipantBestProfilesUpdating(true);
      let fetchedBestProfiles;
      await Promise.all(
        threadsForBestProfilesFetch
          .filter(({ uid }) => !threadParticipantBestProfilesMap[uid])
          .map(async (thread) => {
            const interlocutorUid = getThreadInterlocutorUid({
              thread,
              userType,
            });
            const response =
              await API.get<GetThreadParticipantBestProfileResponseTO>(
                `/v3/thread-participant-best-profile/${interlocutorUid}`,
              );

            return {
              threadUid: thread.uid,
              bestProfile: response.data.bestProfile,
            };
          }),
      ).then((profiles) => {
        fetchedBestProfiles = profiles.reduce(
          (accumulator, { threadUid, bestProfile }) => {
            return {
              ...accumulator,
              [threadUid]: bestProfile,
            };
          },
          {},
        );
      });
      return fetchedBestProfiles;
    },
    { enabled: !!threadsForBestProfilesFetch },
  );

  useEffect(() => {
    const { fetchedThreads, requestedLeadUid, requestedThreadUid } =
      threadsData || {};

    if (fetchedThreads?.length) {
      setThreadsForBestProfilesFetch(fetchedThreads);

      const activeThread =
        (requestedThreadUid &&
          fetchedThreads.find(({ uid }) => uid === requestedThreadUid)) ||
        fetchedThreads[0];
      const activeThreadLeadUid = leadUid || requestedLeadUid;

      updateActiveThreadData({
        activeThread,
        activeThreadLeadUid,
      });
    } else {
      updateActiveThreadData({});
      updateActiveMessageThreadUid(null);
    }

    const moreThreadsAvailable = areMoreThreadsAvailable(fetchedThreads);
    setThreads(fetchedThreads || []);
    setHasMore(moreThreadsAvailable);
  }, [threadsData]);

  useEffect(() => {
    if (bestProfiles) {
      updateThreadParticipantBestProfilesMap(bestProfiles);
      setIsThreadParticipantBestProfilesUpdating(false);
    }
  }, [bestProfiles]);

  useEffect(() => {
    if (moreThreads) {
      setThreadsForBestProfilesFetch(moreThreads);
      const moreThreadsAvailable = areMoreThreadsAvailable(moreThreads);
      setThreads((currentThreads) => [...currentThreads, ...moreThreads]);
      setHasMore(moreThreadsAvailable);
    }
  }, [moreThreads]);

  useEffect(() => {
    if (threadsWithNewMessages?.length) {
      const updatedThreads = [...threads];
      const newThreads = [];

      Promise.all(
        threadsWithNewMessages.map(
          async ({ participantsReadStatuses, threadUid }) => {
            let thread = updatedThreads.find(({ uid }) => uid === threadUid);

            if (!thread) {
              thread = await getThreadByUid(threadUid);

              if (!isAllowedCommunicationThread(thread)) {
                return;
              }

              updatedThreads.unshift(thread);
              newThreads.push(thread);
            }

            thread.lastUpdateDateObj = new Date();
            thread.participantsReadStatuses = participantsReadStatuses;
          },
        ),
      ).then(() => {
        sortThreadsByUpdateDateIfApplicable(updatedThreads, sortBy);
        setThreads(updatedThreads);
        setThreadsForBestProfilesFetch(newThreads);
        setThreadsWithNewMessages(null);
      });
    }
  }, [threadsWithNewMessages]);

  const loadMore = useCallback(() => {
    setOffset((currentOffset) => currentOffset + limit);
  }, [offset]);

  return {
    isLoading:
      !offset &&
      (isThreadsLoading ||
        isThreadParticipantBestProfilesLoading ||
        isThreadParticipantBestProfilesUpdating),
    isLoadingMoreThreads:
      !!offset &&
      (isLoadingMoreThreads ||
        isThreadParticipantBestProfilesLoading ||
        isThreadParticipantBestProfilesUpdating),
    hasMore,
    loadMore,
    threads,
  };
};

export default useInboxThreads;
