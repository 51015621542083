import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { PropertyForPropertyListItem } from 'pages/agencyProperties/Properties.types';
import { isDeepEqual } from 'utils/objectUtils';
import { getDefaultFormValues } from './PropertyItem.utils';

/**
 * Updates the default form values for the PropertyListItem form context.
 * Mainly useful for updating the tags field if it changes after executing an
 * action (e.g Add sub-units).
 */
const useUpdatePropertyFormState = (property: PropertyForPropertyListItem) => {
  const {
    reset,
    formState: { defaultValues },
  } = useFormContext();

  useEffect(() => {
    const nextDefaultState = getDefaultFormValues(property);

    if (!isDeepEqual(defaultValues, nextDefaultState)) {
      reset(nextDefaultState);
    }
  }, [property]);
};

export default useUpdatePropertyFormState;
