import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';
import { FormFooterStyled } from './Common.styles';

export const FormFooterStickyStyled = styled(FormFooterStyled)`
  background-color: #fff;
  bottom: 0;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.15);
  left: 0;
  padding: 15px 10px;
  position: fixed;
  width: 100%;
  z-index: 1000;
`;

export const FormFooterStickyContent = styled.div.attrs({
  className: 'container',
})`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: end;
  padding: 0 25px;

  ${mediaQuery.xs} {
    margin-right: 0;
    padding-right: 7px;
  }
`;
