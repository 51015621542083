import { useContext } from 'react';
import useAppUser from '../../../../hooks/useAppUser';
import useOpenBlockModal from '../../../../components/modal/blockModal/useOpenBlockModal';
import CalendarContext from '../../../stackedCalendar/CalendarContext';
import { LeadStatus } from '../../../../models/Leads';
import useOpenLeadModal from '../../../../components/domain/lead/modal/useOpenLeadModal';

const useCalendarOpenLeadModal = () => {
  const { isEmployee, isOwner } = useAppUser();
  const { allProperties, leadsMap } = useContext(CalendarContext);
  const { createBlockModal, updateBlockModal } = useOpenBlockModal({
    allProperties,
  });
  const { createLeadModal, updateLeadModal } = useOpenLeadModal(allProperties);

  const openCreateLeadModal = (createLeadParams: {
    dateFrom: Date;
    dateTo: Date;
    propertyUid: string;
  }) => {
    if (isEmployee) {
      createLeadModal(createLeadParams);
    } else if (isOwner) {
      createBlockModal(createLeadParams);
    }
  };

  const openEditLeadModal = (leadUid: string) => {
    const lead = leadsMap[leadUid];

    if (!lead) {
      return;
    }

    const { status } = lead;

    if (status === LeadStatus.BLOCKED && (isEmployee || isOwner)) {
      updateBlockModal({
        blockUid: leadUid,
      });
    } else if (isEmployee) {
      updateLeadModal({ leadUid });
    }
  };

  return {
    openCreateLeadModal,
    openEditLeadModal,
  };
};

export default useCalendarOpenLeadModal;
