import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const RoomsDescriptionContainer = styled.section`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const RoomsTabsContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > .form-field-container > .control-label {
    padding-left: 0;
  }
`;

export const RoomsTabsDivider = styled.div`
  border-top: 1px solid #e5e5e5;
  margin-bottom: 0.5rem;
`;

export const DeleteRoomButtonContainer = styled(Col)`
  & > .btn > span:first-child {
    margin-right: 0.5rem;
  }
`;
