import styled from 'styled-components';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { Button, Glyphicon } from 'react-bootstrap';
import { htmlMessageWrapperId } from './RichTextEditor.constants';

export const ContentEditableStyled = styled(ContentEditable).attrs(
  (props: { id: string }) => ({
    className: 'editable-area',
    'data-testid': props.id,
  }),
)<{ id: string }>`
  min-height: 150px;
  resize: vertical;
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: 15px 10px;
  caret-color: #444;
  margin-bottom: 20px;
`;

export const RichTextEditorContainer = styled.div.attrs(
  (props: { id: string }) => ({ 'data-testid': `${props.id}` }),
)<{ id: string }>`
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;

  div#${htmlMessageWrapperId} {
    margin-top: 0.8em; // prevents overlapping the 'clear editor' button
    white-space: normal; // required for rendering message templates properly
  }

  .editable-area > p {
    margin: 0 0 10px;
  }
`;

export const RichTextEditorInnerContainer = styled.div`
  position: relative;
`;

export const RichTextEditorPlaceholder = styled.div`
  color: #999;
  font-weight: 300;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 15px;
  left: 10px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
`;

export const RichTextEditorControlsWrapper = styled.div.attrs({
  'data-testid': 'rich-text-editor-controls-wrapper',
})`
  display: flex;
  justify-content: flex-end;
  padding: 0px 1em 1em 1em;
`;

export const RichTextEditorControlsLoader = styled.div.attrs({
  'data-testid': 'rich-text-editor-controls-loader',
})`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

export const RichTextEditorControls = styled.div.attrs({
  'data-testid': 'rich-text-editor-controls',
})`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;

export const RichTextEditorControlsAttachmentIconContainer = styled.label<{
  htmlFor: string;
}>`
  margin-bottom: 0;

  input[type='file'] {
    display: none;
  }
`;
export const RichTextEditorControlsAttachmentIcon = styled(Glyphicon).attrs({
  'data-testid': 'rich-text-editor-attachment-icon',
  glyph: 'paperclip',
})<{ disabled: boolean }>`
  font-size: 1.2em;
  margin-right: 0.2em;
  padding: 0.5em;

  input[type='file'] {
    display: none;
  }

  ${(props) =>
    !props.disabled &&
    `
    cursor: pointer;
    
    :hover {
      background-color: #f5f5f5;
      border-radius: 50%;
    }
  `}
`;

export const RichTextEditorSuggestionButton = styled(Button)`
  margin-right: 0.2em;
`;
