import { useWatch } from 'react-hook-form';

export interface SearchFieldFilters {
  name: string | undefined;
  propertyUids: [string] | undefined;
}

interface UseSearchFieldFilters {
  searchFieldFilters: SearchFieldFilters;
}

const useSearchFieldFilters = (): UseSearchFieldFilters => {
  const searchedProperties = useWatch({ name: 'propertySearch' });
  const searchFieldFilters = {
    name: (searchedProperties?.[0]?.label as string) ?? undefined,
    propertyUids: searchedProperties?.[0]?.uid
      ? ([searchedProperties?.[0]?.uid] as [string])
      : undefined,
  };

  return { searchFieldFilters };
};

export default useSearchFieldFilters;
