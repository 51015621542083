import { ReactNode } from 'react';
import usePropertiesUpdatingAllowed from 'components/domain/property/usePropertiesUpdatingAllowed';

const SectionWrapper = ({ children }: { children: ReactNode }) => {
  const propertiesUpdatingAllowed = usePropertiesUpdatingAllowed();

  return <fieldset disabled={!propertiesUpdatingAllowed}>{children}</fieldset>;
};

export default SectionWrapper;
