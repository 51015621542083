import FormWithProvider from 'components/forms/form/Form';
import { dashboardHideProcessLeadsLocalStorageKey } from 'utils/constants/localStorageKeys';
import Content from './Content';

const ContentWrapper = () => {
  const hideProcessedLeadsSaved = !!localStorage.getItem(
    dashboardHideProcessLeadsLocalStorageKey,
  );
  return (
    <FormWithProvider
      onSubmit={() => {}}
      defaultValues={{
        hideProcessedLeads: hideProcessedLeadsSaved,
      }}
    >
      <Content />
    </FormWithProvider>
  );
};

export default ContentWrapper;
