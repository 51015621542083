import {
  AmenityCategory,
  AmenitySubCategory,
  AmenityType,
  AvailableAmenity,
} from 'models/Amenity';
import { useTranslation } from 'react-i18next';

export type CategorizedAmenities = {
  [category in AmenityCategory]?: {
    [subCategory in AmenitySubCategory]?: AvailableAmenity[];
  };
};

const POPULAR_AMENITIES = [
  AmenityType.HAS_TV,
  AmenityType.HAS_HEATING,
  AmenityType.HAS_KITCHEN,
  AmenityType.HAS_MICROWAVE_OVEN,
  AmenityType.HAS_COFFEE_MAKER,
  AmenityType.HAS_FRIDGE,
  AmenityType.HAS_WASHER,
  AmenityType.HAS_ESSENTIALS,
  AmenityType.HAS_SHAMPOO,
  AmenityType.HAS_HAIR_DRYER,
  AmenityType.HAS_INTERNET_WIFI,
  AmenityType.HAS_FREE_PARKING,
];

const useAmenitiesDataMap = (availableAmenities: AvailableAmenity[]) => {
  const { t } = useTranslation();

  if (!availableAmenities) {
    return {};
  }

  const amenityMap = new Map<string, AvailableAmenity>();

  availableAmenities.forEach((amenity) => {
    if (amenity.subCategory === AmenitySubCategory.UNDEFINED) {
      amenityMap.set(amenity.amenityType, {
        ...amenity,
        subCategory: AmenitySubCategory.OTHER,
        children: [],
      });
    } else {
      amenityMap.set(amenity.amenityType, { ...amenity, children: [] });
    }
  });

  availableAmenities.forEach((amenity) => {
    const { groupedUnder, amenityType } = amenity;
    if (groupedUnder) {
      const parentAmenity = amenityMap.get(groupedUnder);
      const childAmenity = amenityMap.get(amenityType);
      if (parentAmenity && childAmenity) {
        parentAmenity.children!.push(childAmenity);
      }

      if (!parentAmenity && childAmenity) {
        // this is an orphaned child, it should display with new parent disabled:
        amenityMap.set(groupedUnder, {
          amenityType: groupedUnder as unknown as AmenityType,
          children: [childAmenity],
          category: childAmenity.category,
          subCategory: childAmenity.subCategory,
          channels: childAmenity.channels, // this is only for type checking
          supportsDescription: false,
          groupedUnder: undefined,
          disabled: true,
        });
      }

      amenityMap.delete(amenityType);
    }
  });

  // transform the amenityMap to a categorized and sorted object
  const amenitiesWithChildren = Array.from(amenityMap.values());

  // categorize amenities by category and subCategory
  const categorizedAmenities: CategorizedAmenities =
    amenitiesWithChildren.reduce((acc, amenity) => {
      const { category, subCategory } = amenity;

      if (!category) {
        return acc;
      }

      if (!acc[category]) {
        acc[category] = {};
      }

      if (!acc[category]![subCategory]) {
        acc[category]![subCategory] = [];
      }

      acc[category]![subCategory]!.push(amenity);

      return acc;
    }, {} as CategorizedAmenities);

  const sortAmenityTypeAlphabetically = (
    a: AvailableAmenity,
    b: AvailableAmenity,
  ) =>
    t(`amenities.types.${a.amenityType}`).localeCompare(
      t(`amenities.types.${b.amenityType}`),
    );

  Object.keys(categorizedAmenities).forEach((categoryKey) => {
    const category = categoryKey as AmenityCategory;
    const subCategories = categorizedAmenities[category];

    if (subCategories) {
      const sortedSubCategoryKeys = (
        Object.keys(subCategories) as AmenitySubCategory[]
      ).sort((a, b) => {
        // ensure "OTHER" is always the last subcategory
        if (a === AmenitySubCategory.OTHER) return 1;
        if (b === AmenitySubCategory.OTHER) return -1;

        return t(`amenities.subCategories.${a}`).localeCompare(
          t(`amenities.subCategories.${b}`),
        );
      }) as AmenitySubCategory[];

      const sortedSubCategories: {
        [subCategory in AmenitySubCategory]?: AvailableAmenity[];
      } = {};

      sortedSubCategoryKeys.forEach((subCategoryKey) => {
        sortedSubCategories[subCategoryKey] = subCategories[
          subCategoryKey
        ]!.sort(sortAmenityTypeAlphabetically);
      });

      // assign the sorted subCategories back
      categorizedAmenities[category] = sortedSubCategories;
    }
  });

  const popularAmenities = POPULAR_AMENITIES.map(
    (amenityType) => amenityMap.get(amenityType)!,
  )
    .filter((amenity) => amenity !== undefined && !amenity?.disabled)
    .toSorted(sortAmenityTypeAlphabetically);

  return { categorizedAmenities, popularAmenities };
};

export default useAmenitiesDataMap;
