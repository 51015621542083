import { useTranslation } from 'react-i18next';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import useBulkUpdatePropertyAmenities from 'components/domain/property/amenities/useBulkUpdatePropertyAmenities';
import useAppEvent from 'hooks/useAppEvent';
import useAppModal from 'hooks/useAppModal';
import useNotify from 'hooks/useNotify';
import { BaseErrorResponse } from 'models/_base';
import { AppEventType } from 'models/Events';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import {
  PropertySettingsTab,
  PropertySettingsTabType,
} from '../PropertySettingsTabs.constants';
import usePropertyMainSettingsUpdatePayload from '../mainSettings/usePropertyMainSettingsUpdatePayload';
import {
  PropertySettingsMainTabFormDirtyFields,
  PropertySettingsMainTabFormValues,
} from '../mainSettings/PropertySettingsMainTab.schema';
import useCategorizedAmenitiesUpdatePayload from '../amenities/sections/categorizedAmenities/useCategorizedAmenitiesUpdatePayload';
import {
  CategorizedAmenitiesFormDirtyFields,
  CategorizedAmenitiesFormValues,
} from '../amenities/sections/categorizedAmenities/CategorizedAmenities.schema';
import useBulkUpdatePropertyMainSettings from './useBulkUpdatePropertyMainSettings';
import { PropertySettingsBulkSaveModalParams } from './PropertySettingsBulkSaveModal.types';
import { PropertySettingsBulkSaveFormValues } from './PropertySettingsBulkSaveModal.schema';

const usePropertySettingsBulkSaveFormSubmit = ({
  dirtyFields,
  formValues,
  modalId,
  propertyUid,
  tabName,
}: {
  dirtyFields: PropertySettingsBulkSaveModalParams['dirtyFields'];
  formValues: PropertySettingsBulkSaveModalParams['formValues'];
  modalId: string;
  propertyUid: string;
  tabName: PropertySettingsTabType;
}) => {
  const { publish } = useAppEvent();
  const { closeModal } = useAppModal();
  const {
    isLoading: isUpdatingAmenities,
    mutateAsync: bulkUpdatePropertyAmenities,
  } = useBulkUpdatePropertyAmenities();
  const { isUpdating: isUpdatingMainSettings, bulkUpdatePropertyMainSettings } =
    useBulkUpdatePropertyMainSettings();
  const { notifyError } = useNotify();
  const getAmenitiesUpdatePayload = useCategorizedAmenitiesUpdatePayload();
  const getMainSettingsUpdatePayload = usePropertyMainSettingsUpdatePayload();
  const { t } = useTranslation();

  const handleUpdateError = ({
    apiErrorCode,
    apiErrorMessage,
  }: {
    apiErrorCode?: string;
    apiErrorMessage: string;
  }) => {
    notifyError(
      getErrorMessage({
        apiErrorCode,
        apiErrorMessage,
        baseKey: 'pageProperty.apiErrorCodes.',
        t,
      }),
    );
  };

  const handleUpdateResponse = ({ response }: BaseErrorResponse) => {
    const apiErrorCode = response?.data?.apiErrorCode;
    const apiErrorMessage = response?.data?.apiErrorMessage;

    if (apiErrorCode || apiErrorMessage) {
      handleUpdateError({
        apiErrorCode,
        apiErrorMessage,
      });
      return;
    }

    publish(AppEventType.PROPERTY_SETTINGS_UPDATED, { propertyUid });

    closeModal(modalId);
  };

  if (tabName === PropertySettingsTab.mainSettings) {
    const onSubmit = async (
      bulkSaveFormValues: PropertySettingsBulkSaveFormValues,
    ) => {
      const { appliesTo, appliesToProperties } = bulkSaveFormValues;

      const applyToAllProperties =
        appliesTo === PROPERTIES_SELECTION.ALL_PROPERTIES;

      const otherPropertiesUids = applyToAllProperties
        ? undefined
        : appliesToProperties
            .filter(({ value }) => value !== propertyUid)
            .map(({ value }) => value);

      const updateData = getMainSettingsUpdatePayload({
        dirtyFields: dirtyFields as PropertySettingsMainTabFormDirtyFields,
        formValues: formValues as PropertySettingsMainTabFormValues,
      });

      await bulkUpdatePropertyMainSettings({
        applyToAllProperties,
        mainPropertyUid: propertyUid,
        otherPropertiesUids,
        updateData,
      })
        .then(handleUpdateResponse)
        .catch(({ response: { data } }) => handleUpdateError({ ...data }));
    };

    return { onSubmit, isUpdating: isUpdatingMainSettings };
  }

  if (tabName === PropertySettingsTab.amenities) {
    const onSubmit = async (
      bulkSaveFormValues: PropertySettingsBulkSaveFormValues,
    ) => {
      const { appliesTo, appliesToProperties } = bulkSaveFormValues;

      const applyToAll = appliesTo === PROPERTIES_SELECTION.ALL_PROPERTIES;

      const propertyUids = applyToAll
        ? undefined
        : appliesToProperties.map(({ value }) => value);

      const amenities = getAmenitiesUpdatePayload({
        dirtyFields: dirtyFields as CategorizedAmenitiesFormDirtyFields,
        formValues: formValues as CategorizedAmenitiesFormValues,
      });

      const payload = {
        amenities,
        applyToAll,
        propertyUids,
      };

      await bulkUpdatePropertyAmenities({ payload })
        .then(handleUpdateResponse)
        .catch(({ response: { data } }) => handleUpdateError({ ...data }));
    };

    return {
      onSubmit,
      isUpdating: isUpdatingAmenities,
    };
  }

  // TODO implement other required tabs

  throw new Error(`Not implemented tab: ${tabName}`);
};

export default usePropertySettingsBulkSaveFormSubmit;
