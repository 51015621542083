import { format } from 'date-fns';
import {
  GetPropertiesEntriesResponseTO,
  PropertiesEntry,
} from 'models/PropertiesEntries';
import useAppQuery from '../../../hooks/useAppQuery';
import API from '../../../services/API';

const useFetchPropertiesCalendar = ({
  propertiesUids,
  startDate,
  endDate,
  enabled,
}: {
  propertiesUids: string[];
  startDate: Date;
  endDate: Date;
  enabled: boolean;
}) => {
  const { data: propertiesCalendar, isLoading } = useAppQuery<
    PropertiesEntry[]
  >(
    ['property-calendar', propertiesUids, startDate],
    async () => {
      const response = await API.get<GetPropertiesEntriesResponseTO>(
        `/v3/property-calendar?propertiesUids=${propertiesUids.join(
          ',',
        )}&from=${format(startDate, 'yyyy-MM-dd')}&to=${format(
          endDate,
          'yyyy-MM-dd',
        )}`,
      );
      return response.data.propertyCalendars;
    },
    {
      enabled: propertiesUids.length > 0 && !!startDate && !!endDate && enabled,
    },
  );

  return { propertiesCalendar, isLoading };
};

export default useFetchPropertiesCalendar;
