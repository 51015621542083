import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import useOrderViewFetchedData from '../../useOrderViewFetchedData';
import useAddManualTransactionModal from './useAddManualTransactionModal';

const AddManualTransactionButton = () => {
  const { openAddManualTransactionModal } = useAddManualTransactionModal();
  const { lead } = useOrderViewFetchedData();
  const { t } = useTranslation();

  const onClick = () => {
    openAddManualTransactionModal({ lead });
  };

  return (
    <Button
      bsStyle="link"
      data-testid="add-manual-transaction-button"
      onClick={onClick}
    >
      {t('pageOrder.transactions.actions.addManualTransaction.buttonLabel')}
    </Button>
  );
};

export default AddManualTransactionButton;
