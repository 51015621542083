import { useTranslation } from 'react-i18next';
import useOpenOrderAdjustmentModal from 'components/domain/order/useOpenOrderAdjustmentModal';
import Button from '../../../../components/button/Button';

const AddOrderAdjustmentButton = ({
  orderUid,
  leadUid,
  currency,
}: {
  orderUid: string;
  leadUid: string;
  currency: string;
}) => {
  const { t } = useTranslation();
  const { createOrderAdjustmentModal } = useOpenOrderAdjustmentModal();

  const addNewOrderAdjustment = () => {
    createOrderAdjustmentModal({
      orderUid,
      leadUid,
      currency,
    });
  };

  return (
    <Button
      onClick={addNewOrderAdjustment}
      data-testid="add-new-order-adjustment-button"
      bsStyle="link"
    >
      {t('componentOrder.addOrderAdjustment')}
    </Button>
  );
};

export default AddOrderAdjustmentButton;
