import useAppMutation from 'hooks/useAppMutation';
import { isUidString } from 'utils/stringUtils';
import API from 'services/API';
import { GetSearchFieldPropertiesResponseTO } from './PropertySearchField.types';

const searchProperties = async ({ query }: { query: string }) => {
  const isUidQuery = isUidString(query);

  const response = await API.post<GetSearchFieldPropertiesResponseTO>(
    '/v3/graphql',
    {
      operationName: 'SearchFieldProperties',
      query: `{
        properties(
          topLevelOnly:false,
          sortStrategy:"SORT_BY_NAME",
          offset:"0",
          limit:"5",
          name:"${isUidQuery ? '' : query}",
          propertyUids:"${isUidQuery ? query : ''}"
        ){
            uid,
            name,
            businessType,
        }
      }`,
      variables: {},
    },
  );
  return response.data.data.properties ?? [];
};

const usePropertySearchMutation = () => {
  return useAppMutation({
    mutationFn: searchProperties,
  });
};

export default usePropertySearchMutation;
