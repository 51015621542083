import { ButtonProps } from 'react-bootstrap';
import { ColorScheme, StyledButton } from './Button.styles';

/* eslint-disable react/button-has-type */
export interface Props extends ButtonProps {
  children: React.ReactNode;
  $colorScheme?: ColorScheme;
  $noBorder?: boolean;
  bigRounded?: boolean;
  $noOutlineWhenFocus?: boolean;
}

const Button = (props: Props) => {
  const { children, bigRounded, ...rest } = props;
  return (
    // @ts-ignore
    <StyledButton $bigRounded={bigRounded} {...rest}>
      {children}
    </StyledButton>
  );
};

Button.defaultProps = {
  $colorScheme: null,
  $noBorder: false,
  bigRounded: false,
  $noOutlineWhenFocus: false,
};

export default Button;
