import { ModalBody } from 'react-bootstrap';
import FormWithProvider from 'components/forms/form/Form';
import useFetchPropertyRooms from 'components/domain/property/rooms/useFetchPropertyRooms';
import Loader from 'components/loader/Loader';
import useAppModal from 'hooks/useAppModal';
import { BedType, PropertyRoom, RoomType } from 'models/PropertyRooms';
import RoomsAndBedTypesModalFooter from './RoomsAndBedTypesModalFooter';
import { CapacityDetailsRoomsFormValues } from './PropertyCapacityDetails.types';
import RoomsAndBedTypesFormElements from './RoomsAndBedTypesFormElements';
import {
  roomsAndBedTypesFormSchema,
  RoomsAndBedTypesFormValues,
} from './RoomsAndBedTypesModal.schema';

function prepareDefaultValues(
  initialFormValues: CapacityDetailsRoomsFormValues | undefined,
  fetchedRooms: PropertyRoom[],
): RoomsAndBedTypesFormValues {
  if (initialFormValues) {
    return {
      rooms: initialFormValues.map(({ roomType, ...rest }) => ({
        bedTypes: '',
        roomType:
          roomType === RoomType.COMMON_SPACES ? RoomType.LIVING_ROOM : roomType,
        sharedRoom: roomType === RoomType.COMMON_SPACES,
        ...rest,
      })),
    };
  }

  return {
    rooms: (fetchedRooms || []).map(
      ({ bedCounts, hasPrivateBathroom, roomType, uid: _, ...rest }) => ({
        beds: Object.entries(bedCounts || {}).map(([bedType, count]) => ({
          bedType: bedType as BedType,
          count,
        })),
        bedTypes: '',
        hasPrivateBathroom: !!hasPrivateBathroom,
        roomType:
          roomType === RoomType.COMMON_SPACES ? RoomType.LIVING_ROOM : roomType,
        sharedRoom: roomType === RoomType.COMMON_SPACES,
        ...rest,
      }),
    ),
  };
}

const RoomsAndBedTypesModal = ({
  modalId,
  params: { applyCallback, initialFormValues, propertyUid },
}: {
  modalId: string;
  params: {
    applyCallback: (formValues: CapacityDetailsRoomsFormValues) => void;
    initialFormValues: CapacityDetailsRoomsFormValues | undefined;
    propertyUid: string;
  };
}) => {
  const { closeModal } = useAppModal();
  const { data: fetchedRooms, isInitialLoading: isFetchingRooms } =
    useFetchPropertyRooms({
      enabled: !initialFormValues,
      propertyUid,
    });

  if (isFetchingRooms) {
    return <Loader />;
  }

  const defaultValues = prepareDefaultValues(initialFormValues, fetchedRooms);

  const onSubmit = (formValues: RoomsAndBedTypesFormValues) => {
    const rooms = formValues.rooms.map(
      ({ bedTypes: _, roomType, sharedRoom, ...rest }) => ({
        roomType: sharedRoom ? RoomType.COMMON_SPACES : roomType,
        ...rest,
      }),
    );

    applyCallback(rooms);
    closeModal(modalId);
  };

  return (
    <FormWithProvider
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      schema={roomsAndBedTypesFormSchema()}
      noValidate
    >
      <ModalBody>
        <RoomsAndBedTypesFormElements />
      </ModalBody>
      <RoomsAndBedTypesModalFooter modalId={modalId} />
    </FormWithProvider>
  );
};

export default RoomsAndBedTypesModal;
