import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';
import Button from '../../../components/button/Button';

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  ${mediaQuery.xs} {
    flex-direction: column;

    & > .btn-group {
      margin-right: 0;
    }
  }
`;

export const ButtonLeftRight = styled(Button)<{ $hover: boolean }>`
  height: 21px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
  border: 0;
  border-radius: 0;
  padding: 0;
`;

export const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 250px;
  justify-content: space-between;

  border: 0.5px solid rgb(221, 221, 221);

  ${mediaQuery.xs} {
    margin-bottom: 8px;
  }
`;

export const Divider = styled.span`
  margin: 0 10px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
