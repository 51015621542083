import { useContext } from 'react';
import useAppModal from 'hooks/useAppModal';
import { EmailModalOrigin } from 'models/SendEmail';
import CalendarContext from 'pages/stackedCalendar/CalendarContext';
import useStackedCalendarVisibleProperties from 'pages/stackedCalendar/useStackedCalendarVisibleProperties';
import { getPropertyUidFromStringType } from 'utils/property/propertyUtils';
import EmailPropertiesListModal from './EmailPropertiesListModal';

const useOpenEmailModal = (type: EmailModalOrigin) => {
  const { openModal } = useAppModal();
  const { filter } = useContext(CalendarContext);
  const visibleProperties = useStackedCalendarVisibleProperties();

  const modalId = 'send-email-modal';

  const propertiesQuery = visibleProperties
    .map((uid) => getPropertyUidFromStringType(uid))
    .filter(({ isUnit, propertyUid }) => !isUnit && propertyUid)
    .map(({ propertyUid }) => propertyUid)
    .join(',');

  const fetchEmailPropertiesParams = {
    availableFrom: filter.availableFrom,
    availableTo: filter.availableTo,
    propertiesQuery,
  };

  const sendEmailModal = () => {
    // TODO this modal change the template depending from where it will be trigged like: calendar filter, owners page, property owner tab.
    if (type === EmailModalOrigin.CALENDAR_FILTER) {
      openModal({
        customContent: (
          <EmailPropertiesListModal
            fetchEmailPropertiesParams={fetchEmailPropertiesParams}
            modalId={modalId}
          />
        ),
        id: modalId,
      });
    }
  };

  return { sendEmailModal };
};

export default useOpenEmailModal;
