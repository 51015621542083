import { useContext } from 'react';
import {
  GetThreadRelatedLeadsResponseTO,
  GetThreadRelatedObjectsResponseDto,
  ThreadRelatedObjectType,
  ThreadRelatedPropertyResponse,
} from '../../../models/inbox/Threads';
import useAppQuery from '../../../hooks/useAppQuery';
import API from '../../../services/API';
import InboxLeadsContext from '../lead/InboxLeadsContext';

export interface UseThreadRelatedPropertyResult {
  isLoading: boolean;
  property?: ThreadRelatedPropertyResponse;
  isError: boolean;
  leads?: GetThreadRelatedLeadsResponseTO[]; // not used now
}

export function useFetchInboxThreadRelatedObjects(
  threadUid: string,
): UseThreadRelatedPropertyResult {
  const { addThreadRelatedProperty } = useContext(InboxLeadsContext);

  const { isError, data, isLoading } = useAppQuery(
    ['thread-related-objects', threadUid],
    async () => {
      const propertiesResponse =
        await API.get<GetThreadRelatedObjectsResponseDto>(
          `/v3/thread-related-objects?threadUid=${threadUid}`,
        );
      return propertiesResponse.data.threadRelatedObjects;
    },
    {
      enabled: !!threadUid,
    },
  );
  const property = data?.find(
    (it) => it.type === ThreadRelatedObjectType.PROPERTY,
  ) as ThreadRelatedPropertyResponse;

  if (property) {
    addThreadRelatedProperty(threadUid, property);
  }

  return {
    isError,
    isLoading,
    property,
  };
}
