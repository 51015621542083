import styled from 'styled-components';

export const DropdownFieldStyled = styled.div`
  .dropdown,
  .dropdown-toggle {
    width: 100%;
  }

  .dropdown-toggle {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
`;
