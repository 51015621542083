import { OrderViewPriceDetailsListItemTitleWrapper } from './OrderViewPriceDetailsListItemTitle.styles';

const OrderViewPriceDetailsListItemTitle = ({ title }: { title }) => {
  return (
    <OrderViewPriceDetailsListItemTitleWrapper data-testid="price-details-list-item-main-title">
      {title}
    </OrderViewPriceDetailsListItemTitleWrapper>
  );
};

export default OrderViewPriceDetailsListItemTitle;
