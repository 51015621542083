import { useTranslation } from 'react-i18next';
import useFetchEmployees from 'components/domain/employee/useFetchEmployees';
import SelectField from 'components/forms/selectField/SelectField';
import FieldPlaceholder from 'pages/property/common/FieldPlaceholder';

const PrimaryContactField = () => {
  const { t } = useTranslation();

  const { employees, isFetching: isLoadingEmployees } = useFetchEmployees(true);

  if (isLoadingEmployees) {
    return (
      <FieldPlaceholder
        label={t(
          'pageProperty.mainSettings.bookingDotComSettings.primaryContact',
        )}
        name="bookingDotComSettings.primaryContactUid"
        colSmInput={7}
        colSmLabel={5}
        required
      />
    );
  }

  const primaryContactOptions = [
    { value: '', label: t('common.select') },
    ...(employees ?? []).map(({ uid, bestProfile }) => ({
      value: uid,
      label: bestProfile,
    })),
  ];

  return (
    <SelectField
      label={t(
        'pageProperty.mainSettings.bookingDotComSettings.primaryContact',
      )}
      name="bookingDotComSettings.primaryContactUid"
      options={primaryContactOptions}
      colSmInput={7}
      colSmLabel={5}
      required
    />
  );
};

export default PrimaryContactField;
