import { css } from 'styled-components';

const commonShadowStyles = css`
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 32px;
`;

export const ScrollableShadowStyles = css`
  .with-scrollable-shadow {
    --shadow-overflow-perimeter: #091e421f;
    --shadow-overflow-spread: #091e4229;

    position: relative;

    &.left-shadow::before {
      background: linear-gradient(
          to left,
          transparent 0,
          var(--shadow-overflow-spread, rgba(9, 30, 66, 0.13)) 140%
        ),
        linear-gradient(
          to right,
          var(--shadow-overflow-perimeter, transparent) 0px,
          transparent 1px
        );

      ${commonShadowStyles};
    }

    &.right-shadow::after {
      background: linear-gradient(
          to right,
          transparent 0,
          var(--shadow-overflow-spread, rgba(9, 30, 66, 0.13)) 140%
        ),
        linear-gradient(
          to left,
          var(--shadow-overflow-perimeter, transparent) 0px,
          transparent 1px
        );

      ${commonShadowStyles};
      right: 0;
    }
  }
`;
