import Button from 'components/button/Button';
import { Modal } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const PropertyTagsContainer = styled.div<{ $hasMore: boolean }>`
  margin-top: 4px;
  display: flex;
  align-items: center;

  .form-group {
    margin-bottom: 0;
  }

  .rbt-input-wrapper {
    flex-wrap: nowrap;
  }

  .form-field-container {
    // overflow-x: hidden; This doesn't work properly with the "add tag" dropdown;

    & > div {
      padding-left: 0;
      width: 100% !important;
    }
  }

  .rbt-input {
    border: none;
    box-shadow: none;
    height: 23px;
    padding: 0;
    background-color: transparent;

    .rbt-input-main {
      min-width: 60px;
      margin-bottom: 0;
    }
  }

  .rbt-token-label {
    white-space: nowrap;
  }

  ${({ $hasMore }) =>
    $hasMore &&
    css`
      .rbt-input-main {
        display: none;
      }
    `}
`;

export const PropertyTagsMoreButton = styled(Button).attrs({
  bsStyle: 'link',
})`
  font-size: 1rem;
  padding: 0;
  line-height: 23px;
  width: 48px;
  outline: none !important;
`;

export const PropertyTagsModalContainer = styled(Modal)`
  .modal-body {
    display: flex;
    flex-direction: column;
  }

  .rbt-input {
    border: none;
    box-shadow: none;
    padding: 0;
  }

  .modal-footer {
    display: flex;
    width: 100%;

    > button {
      margin-right: auto;
    }
    // second button
    > button + button {
      margin-right: 0;
    }
  }
`;

export const PropertyTagsModalTitle = styled.h1`
  line-height: 1.5em;
  margin-bottom: 1rem;
  margin-top: 0;
  font-size: 16px;
  margin-left: 15px;

  span {
    color: ${theme.colors.fontGreen};
  }
`;
