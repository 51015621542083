import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Thread } from 'models/inbox/Threads';
import InboxContext from '../InboxContext';
import {
  ThreadInfoContainer,
  ThreadInfoParticipantName,
  ThreadsListItem,
} from './InboxThreads.styles';
import useThreadParticipantBestProfile from './useThreadParticipantBestProfile';
import ThreadAvatar from './ThreadAvatar';
import useThreadReadStatus from './useThreadReadStatus';
import { getThreadLastUpdateDate } from './InboxThreads.utils';

const InboxThread = ({ thread }: { thread: Thread }) => {
  const { uid: threadUid } = thread;
  const { activeThread, updateActiveThreadData } = useContext(InboxContext);
  const bestProfile = useThreadParticipantBestProfile(threadUid);
  const { t } = useTranslation();
  const activeThreadUid = activeThread?.uid;
  const active = activeThreadUid === threadUid;
  const { unread, markAsRead } = useThreadReadStatus(thread);
  const { name } = bestProfile;

  const onClick = useCallback(() => {
    if (!active) {
      updateActiveThreadData({ activeThread: thread });
    }
  }, [active, thread]);

  useEffect(() => {
    if (active && unread && document.hasFocus()) {
      markAsRead();
    }
  }, [active, unread]);

  return (
    <ThreadsListItem
      active={active}
      onClick={onClick}
      data-testid={`thread-list-item-${threadUid}`}
      data-thread-unread={unread}
    >
      <ThreadAvatar bestProfile={bestProfile} />
      <ThreadInfoContainer unread={unread}>
        <ThreadInfoParticipantName>{name}</ThreadInfoParticipantName>
        <div>
          {t('pageInbox.threadsList.lastUpdateDate', {
            date: getThreadLastUpdateDate(thread),
          })}
        </div>
      </ThreadInfoContainer>
    </ThreadsListItem>
  );
};

export default InboxThread;
