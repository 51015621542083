import styled from 'styled-components';
import { FilterIconContainer } from 'components/filters/FilterIcon.styles';
import PageHeader from 'components/pageHeader/PageHeader';
import mediaQuery from 'styles/mediaQuery';

export const PageHeaderFilterWrapper = styled.div`
  align-items: center;
  column-gap: 15px;
  display: flex;
  justify-content: end;

  .inbox-lead-search {
    margin-bottom: 0;
    width: 200px;
  }

  ${FilterIconContainer} {
    align-self: end;
  }

  ${mediaQuery.xs} {
    margin-top: 15px;
    flex-direction: column;
    row-gap: 15px;
    width: 100%;

    .inbox-lead-search {
      width: 100%;

      div:first-child {
        padding: 0;
      }
    }
  }
`;

export const PageHeaderStyled = styled(PageHeader)`
  align-items: center;
  column-gap: 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  h1 {
    align-self: start;
  }

  .divider {
    order: 4;
    width: 100%;
  }
`;

export const Container = styled.div.attrs({ className: 'container' })``;
