import { useContext } from 'react';
import LeadModalContext from 'components/domain/lead/modal/LeadModalContext';
import { useTranslation } from 'react-i18next';
import useFormatCurrency from 'hooks/useFormatCurrency';
import { useFormContext } from 'react-hook-form';
import { TDWithoutBorder } from './Fields.styles';
import AmountField from './common/AmountField';

interface CleaningProps {
  isEditable: boolean;
}

const Cleaning = ({ isEditable }: CleaningProps) => {
  const { quote } = useContext(LeadModalContext);
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const { setValue } = useFormContext();

  return (
    <>
      <tr>
        <td>{t('componentOrder.fees')}:</td>
        <td>{t('componentOrder.cleaning')}</td>
        <td />
        <td>
          <AmountField
            value={quote.fees.cleaningFee.netPrice}
            isEditable={isEditable}
            currency={quote.currency}
            onItemUpdated={(val) => {
              setValue('overrides.fees.cleaningFee.netPrice', val);
            }}
          />
        </td>
      </tr>
      {!!quote.fees.cleaningFee.taxationRate && (
        <tr>
          <TDWithoutBorder />
          <td>
            {t('componentOrder.cleaningFeeTaxes', {
              value: quote.fees.cleaningFee.taxationRate,
            })}
          </td>
          <td />
          <td>
            {formatCurrency({
              currency: quote.currency,
              value: quote.fees.cleaningFee.taxAmount,
            })}
          </td>
        </tr>
      )}
    </>
  );
};

export default Cleaning;
