import styled, { css } from 'styled-components';
import { Typeahead } from 'react-bootstrap-typeahead';
import theme from '../../../styles/theme';

export const typeaheadTheme = css`
  .dropdown-menu {
    min-width: 100%;
  }

  a.dropdown-item {
    color: ${theme.colors.fontDark};
  }

  a.dropdown-item:hover {
    text-decoration: none;
  }

  a.dropdown-item.rbt-menu-custom-option {
    display: inline-block;
    width: 100%;

    :hover {
      background-color: #f5f5f5;
    }

    .typeahead-search-option {
      display: inline-block;
    }
  }
`;

export const TypeaheadStyled = styled(Typeahead)`
  ${typeaheadTheme}
`;

export const TypeAheadSearchOption = styled.div.attrs({
  className: 'typeahead-search-option',
})`
  padding: 0.4em;

  :hover {
    background-color: #f5f5f5;
  }
`;
