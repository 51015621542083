import API from 'services/API';
import { GetServicesResponseTO } from 'models/Service';
import useAppQuery from '../../hooks/useAppQuery';
import useAppUser from '../../hooks/useAppUser';

const useFetchServices = () => {
  const { agencyUid, isOwner } = useAppUser();

  const { data: services, isLoading: isServicesLoading } = useAppQuery(
    ['services'],
    async () => {
      const response = await API.get<GetServicesResponseTO>(
        `v3/services?agencyUid=${agencyUid}`,
      );
      return response.data.services;
    },
    {
      enabled: !isOwner,
    },
  );

  return { services, isServicesLoading };
};

export default useFetchServices;
