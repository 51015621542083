import { Col } from 'react-bootstrap';
import Form from 'components/forms/form/Form';
import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const BackProperties = styled(Col)`
  display: flex;
  align-items: center;

  ${mediaQuery.xs} {
    width: 100%;
    justify-content: space-between;
    margin: 12px 0 0 0;

    .form-field-container {
      & > div {
        padding: 0 12px;
      }
    }
  }
`;

export const ButtonContainer = styled(Form)`
  .form-group {
    margin: 0;
  }

  .btn {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
  }
`;
