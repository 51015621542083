import { Lead, LeadSource } from 'models/Leads';
import { MessagingChannel } from 'models/inbox/Messages';
import useAppQuery from '../../../hooks/useAppQuery';
import API from '../../../services/API';
import { GetThreadAvailableMessagingChannelsResponseTO } from '../../../models/inbox/Threads';
import useAppUser from '../../../hooks/useAppUser';

function transformFilteredChannels(channels: MessagingChannel[]) {
  if (!channels) {
    return [MessagingChannel.HOSTFULLY_PMP];
  }

  return [
    channels.includes(MessagingChannel.AIRBNB) ||
    channels.includes(MessagingChannel.BOOKING_COM) ||
    channels.includes(MessagingChannel.VRBO)
      ? MessagingChannel.AUTO
      : MessagingChannel.HOSTFULLY_PMP,
    ...channels.filter(
      (channel) =>
        channel !== MessagingChannel.AIRBNB &&
        channel !== MessagingChannel.BOOKING_COM &&
        channel !== MessagingChannel.VRBO &&
        channel !== MessagingChannel.HOSTFULLY_PMP,
    ),
  ];
}
function isChannelMatchingLeadSource(
  channel: MessagingChannel,
  leadSource: LeadSource | undefined,
) {
  if (!leadSource) {
    return true;
  }

  return (
    (channel !== MessagingChannel.BOOKING_COM ||
      leadSource === LeadSource.DIRECT_BOOKINGDOTCOM) &&
    (channel !== MessagingChannel.AIRBNB ||
      leadSource === LeadSource.DIRECT_AIRBNB) &&
    (channel !== MessagingChannel.VRBO ||
      leadSource === LeadSource.DIRECT_HOMEAWAY)
  );
}

function getDefaultMessagingChannel(channels: MessagingChannel[]) {
  if (channels.length === 1) {
    return channels[0];
  }

  if (channels.includes(MessagingChannel.AUTO)) {
    return MessagingChannel.AUTO;
  }
  return MessagingChannel.EMAIL;
}

const useAvailableMessagingChannels = ({
  threadUid,
  availableMessagingChannelsMap = {},
  lead,
}: {
  threadUid: string;
  availableMessagingChannelsMap?: Record<string, MessagingChannel[]>;
  lead: Lead | undefined;
}) => {
  const { isEmployee } = useAppUser();

  const availableMessagingChannels =
    threadUid && availableMessagingChannelsMap[threadUid];

  const {
    data: fetchedAvailableMessagingChannels,
    isInitialLoading: isLoadingMessagingChannels,
  } = useAppQuery(
    ['availableMessagingChannels', threadUid],
    async () => {
      const response =
        await API.get<GetThreadAvailableMessagingChannelsResponseTO>(
          '/v3/available-messaging-channels',
          {
            params: {
              threadUid,
            },
          },
        );

      return response.data.availableMessagingChannels;
    },
    { enabled: isEmployee && !availableMessagingChannels && !!threadUid },
  );

  const filteredChannels = (
    availableMessagingChannels || fetchedAvailableMessagingChannels
  )?.filter((channel) => isChannelMatchingLeadSource(channel, lead?.source));

  const channels = transformFilteredChannels(filteredChannels);

  const defaultMessagingChannel = getDefaultMessagingChannel(channels);

  return {
    fetchedAvailableMessagingChannels,
    channels,
    defaultMessagingChannel,
    isLoadingMessagingChannels,
  };
};

export default useAvailableMessagingChannels;
