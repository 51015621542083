import { useMemo, useState } from 'react';
import { Property } from 'models/Properties';
import { QuoteOverrides, Quote } from 'models/Quote';
import { PinCode } from 'models/PinCode';
import { Employee } from 'models/Employees';
import { Lead } from 'models/Leads';
import { ReviewByHost } from 'models/Reviews';
import { Tag } from 'models/Tags';
import { ChannelSettings } from 'models/Channels';
import BookingDotComAvailableActions from '../../../../models/ChannelActions';
import LeadModalContext from './LeadModalContext';

const LeadModalContextProvider = ({
  availableProperties: initialAvailableProperties,
  children,
  modalId,
}) => {
  const [apiError, setApiError] = useState<string>();
  const [availableEmployees, setAvailableEmployees] = useState<Employee[]>();
  const [availableProperties, setAvailableProperties] = useState<Property[]>(
    initialAvailableProperties,
  );
  const [availableTags, setAvailableTags] = useState<Tag[]>();
  const [quote, setQuote] = useState<Quote>();
  const [lead, setLead] = useState<Lead>();
  const [leadTags, setLeadTags] = useState<Tag[]>();
  const [propertyFees, setPropertyFees] =
    useState<QuoteOverrides['fees']['otherFees']>();
  const [pinCode, setPinCode] = useState<PinCode>();
  const [reviewByHost, setReviewByHost] = useState<ReviewByHost>();
  const [channelSettings, setChannelSettings] = useState<ChannelSettings[]>();
  const [overrides, setOverrides] = useState<QuoteOverrides>(null);
  const [availableBookingDotComActions, setAvailableBookingDotComActions] =
    useState<BookingDotComAvailableActions>();

  const contextValue = useMemo(
    () => ({
      apiError,
      availableEmployees,
      availableProperties,
      availableTags,
      lead,
      leadTags,
      modalId,
      quote,
      propertyFees,
      pinCode,
      reviewByHost,
      overrides,
      setApiError,
      channelSettings,
      availableBookingDotComActions,
      setAvailableEmployees,
      setAvailableProperties,
      setAvailableTags,
      setLead,
      setLeadTags,
      setPinCode,
      setReviewByHost,
      setChannelSettings,
      setQuote,
      setPropertyFees,
      setOverrides,
      setAvailableBookingDotComActions,
    }),
    [
      apiError,
      availableEmployees,
      availableProperties,
      availableTags,
      propertyFees,
      lead,
      leadTags,
      quote,
      modalId,
      pinCode,
      reviewByHost,
      overrides,
      availableBookingDotComActions,
    ],
  );

  return (
    <LeadModalContext.Provider value={contextValue}>
      {children}
    </LeadModalContext.Provider>
  );
};

export default LeadModalContextProvider;
