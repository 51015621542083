import SectionWrapper from '../../../common/SectionWrapper';
import { CustomAmenitiesSection } from './CustomAmenities.styles';
import CustomAmenitiesHeader from './CustomAmenitiesHeader';
import CustomAmenitiesTable from './CustomAmenitiesTable';

const CustomAmenities = () => {
  return (
    <SectionWrapper>
      <CustomAmenitiesSection data-testid="custom-amenities-section">
        <CustomAmenitiesHeader />
        <CustomAmenitiesTable />
      </CustomAmenitiesSection>
    </SectionWrapper>
  );
};

export default CustomAmenities;
