import { useContext } from 'react';
import { AxiosResponse } from 'axios';
import useAppMutation from '../../../hooks/useAppMutation';
import API from '../../../services/API';
import {
  MessageState,
  SendMessageResponseTO,
} from '../../../models/inbox/Messages';
import useInboxMessageMetadata from './useInboxMessageMetadata';
import InboxMessagesContext from './InboxMessagesContext';

const useResendInboxMessage = (messageUid: string) => {
  const { updateMessage } = useContext(InboxMessagesContext);
  const { updateMessageMetadata } = useInboxMessageMetadata(messageUid);

  const { mutate: resendMessageHandler } = useAppMutation(
    ['resendMessageHandler'],
    (uid: string) =>
      API.patch(`/v3/messages/${uid}`, {
        state: MessageState.SENT,
      }),
    {
      onSuccess: ({
        data: { message },
      }: AxiosResponse<SendMessageResponseTO>) => {
        const { state } = message;

        if (state === MessageState.SENT) {
          updateMessageMetadata({ isSending: false, isSendError: false });
          updateMessage(message);
        } else {
          updateMessageMetadata({ isSending: false, isSendError: true });
        }
      },
      onError: () => {
        updateMessageMetadata({ isSending: false, isSendError: true });
      },
    },
  );

  const resendMessage = () => {
    updateMessageMetadata({ isSending: true, isSendError: false });
    resendMessageHandler(messageUid);
  };

  return { resendMessage };
};

export default useResendInboxMessage;
