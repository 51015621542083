import { Row } from '@tanstack/react-table';
import Button from 'components/button/Button';
import { CustomAmenity } from 'models/Amenity';
import { useTranslation } from 'react-i18next';
import PropertyEditActionWrapper from '../../../../common/PropertyEditActionWrapper';
import useCustomAmenityModal from '../modals/useCustomAmenityModal';
import useDeleteModal from './useDeleteModal';

const ActionsCell = ({ row }: { row: Row<CustomAmenity> }) => {
  const { t } = useTranslation();
  const { openConfirmDeleteModal } = useDeleteModal({ row });
  const { openEditModal } = useCustomAmenityModal();

  return (
    <>
      <PropertyEditActionWrapper tooltipId="table-row-edit-button-tooltip">
        <Button
          bsStyle="link"
          data-testid="table-row-edit-button"
          onClick={() => openEditModal(row.original)}
        >
          {t('common.edit')}
        </Button>
      </PropertyEditActionWrapper>
      <PropertyEditActionWrapper tooltipId="table-row-delete-button-tooltip">
        <Button
          bsStyle="link"
          data-testid="table-row-delete-button"
          onClick={openConfirmDeleteModal}
        >
          {t('common.delete')}
        </Button>
      </PropertyEditActionWrapper>
    </>
  );
};

export default ActionsCell;
