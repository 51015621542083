import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { ThreadAvatarContainer } from '../threads/InboxThreads.styles';
import { InboxMessageEditorContainer } from './editor/InboxMessageEditor.styles';
import {
  InboxMessageAvatarContainer,
  InboxMessageContainer,
  InboxMessageContentContainer,
  InboxMessageSectionWrapper,
} from './InboxMessages.styles';

const InboxMessagesLoader = () => {
  return (
    <>
      <InboxMessageEditorContainer>
        <LoadingPlaceholder linesCount={16} />
      </InboxMessageEditorContainer>
      {[1, 2, 3, 4, 5].map((number) => (
        <InboxMessageContainer uid={`${number}`} key={number}>
          <InboxMessageSectionWrapper $alignRight={number % 2 === 0}>
            {number % 2 === 1 && (
              <InboxMessageAvatarContainer>
                <ThreadAvatarContainer>
                  <LoadingPlaceholder circle />
                </ThreadAvatarContainer>
              </InboxMessageAvatarContainer>
            )}
            <InboxMessageContentContainer>
              <LoadingPlaceholder linesCount={3} />
            </InboxMessageContentContainer>
          </InboxMessageSectionWrapper>
        </InboxMessageContainer>
      ))}
    </>
  );
};

export default InboxMessagesLoader;
