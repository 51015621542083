import { CollapseSectionStyled } from 'pages/property/common/Common.styles';
import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const AmenityCategoryCollapse = styled(CollapseSectionStyled)`
  .collapsible-body {
    padding: 20px 10px;
  }
`;

export const AmenitySubCategorySection = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const AmenitySubCategoryTitle = styled.h3`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 0;
`;

export const AmenitiesList = styled.ul<{ $amountOfRows: number }>`
  list-style: none;
  padding: 0;
  margin: 0;

  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: ${({ $amountOfRows }) =>
    `repeat(${$amountOfRows}, auto)`};

  ${mediaQuery.xs} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const AmenityItemStyled = styled.li<{
  $gridColumn?: number | null;
}>`
  .checkbox {
    margin: 0;
  }
  margin-bottom: 6px;
  height: 20px;
  display: flex;
  align-items: center;

  ${mediaQuery.xs} {
    height: auto;
  }

  ${({ $gridColumn }) => $gridColumn && `grid-column: ${$gridColumn};`}
`;

export const AmenityItemChildStyled = styled(AmenityItemStyled)`
  margin-left: 20px;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    left: -15px;
    top: 0;
    width: 8px;
    height: 12px;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
`;
