import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import API from 'services/API';
import { CancellationPolicyValue } from 'models/CancellationPolicy';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import { useQueryClient } from '@tanstack/react-query';
import { Property } from 'models/Properties';
import { LanguageCodeUnderscored } from 'i18n/internationalization.types';
import { CANCELLATION_POLICY_BASE_QUERY_KEY } from '../useCancellationPolicyData';
import AddCancellationPolicyModal, {
  addModalId,
  CancellationPolicyAddFormSchemaType,
} from './AddCancellationPolicyModal';

const ADD_CANCELLATION_POLICY_BASE_MUTATION_KEY = 'add-cancellation-policy';

const useAddCancellationPolicy = () => {
  const { property } = usePropertySettingsInitData();
  const { openModal, closeModal } = useAppModal();
  const queryClient = useQueryClient();

  const mutationKey = [ADD_CANCELLATION_POLICY_BASE_MUTATION_KEY];

  const { mutate: createCancellationPolicy } = useAppMutation(
    mutationKey,
    (payload: {
      cancellationPolicy: {
        values: CancellationPolicyValue[];
      };
      applyToAll: boolean;
      propertyUids: Property['uid'][];
    }) => {
      return API.post(
        '/api/internal/cancellation-policies/bulk-update',
        payload,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CANCELLATION_POLICY_BASE_QUERY_KEY]);
        closeModal(addModalId);
      },
    },
  );

  const handleSubmit = (values: CancellationPolicyAddFormSchemaType) => {
    const applyToAllProperties =
      values.appliesTo === PROPERTIES_SELECTION.ALL_PROPERTIES;

    const payload = {
      cancellationPolicy: {
        values: [
          {
            text: values.text,
            locale: LanguageCodeUnderscored.EN_US,
          },
        ],
      },
      applyToAll: applyToAllProperties,
      propertyUids: applyToAllProperties
        ? []
        : values.appliesToProperties.map((item) => item.value),
    };

    createCancellationPolicy(payload);
  };

  const openAddModal = () => {
    openModal({
      id: addModalId,
      customContent: (
        <AddCancellationPolicyModal
          property={property}
          onSubmit={handleSubmit}
          mutationKey={mutationKey}
        />
      ),
    });
  };

  return { openAddModal };
};

export default useAddCancellationPolicy;
