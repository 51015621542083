import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FormWithProvider from 'components/forms/form/Form';
import CommonModalFooter from '../common/CommonModalFooter';
import { ModalDescriptionStyled } from '../common/CommonModal.styles';
import { linkSubUnitsModalId } from './LinkSubUnitsModal.constants';
import { LinkSubUnitsModalProps } from './LinkSubUnitsModal.types';
import LinkSubUnitsModalFormFields from './LinkSubUnitsModalFormFields';
import linkSubUnitsModalSchema from './LinkSubUnitsModal.schema';
import useLinkSubUnitsModalForm from './useLinkSubUnitsModalForm';

const LinkSubUnitsModal = ({ propertyUid }: LinkSubUnitsModalProps) => {
  const { t } = useTranslation();
  const { isUpdating, handleSubmit } = useLinkSubUnitsModalForm(propertyUid);
  const isDisabled = isUpdating;

  return (
    <div data-testid="link-sub-units-modal">
      <Modal.Header data-testid="link-sub-units-modal-header" closeButton>
        {t('pageProperties.actions.linkSubUnitsModal.header')}
      </Modal.Header>

      <FormWithProvider
        defaultValues={{ properties: [] }}
        onSubmit={handleSubmit}
        schema={linkSubUnitsModalSchema()}
        disabled={isDisabled}
        noValidate
      >
        <fieldset disabled={isDisabled}>
          <Modal.Body>
            <ModalDescriptionStyled>
              {t('pageProperties.actions.linkSubUnitsModal.description')}{' '}
              <a
                href="https://help.hostfully.com/en/articles/2640923-managing-calendars-for-a-house-and-its-sub-units"
                rel="noreferrer"
                target="_blank"
              >
                {t('pageProperties.actions.common.seeDocumentation')}
              </a>
            </ModalDescriptionStyled>
            <LinkSubUnitsModalFormFields propertyUid={propertyUid} />
          </Modal.Body>

          <Modal.Footer>
            <CommonModalFooter
              isUpdating={isUpdating}
              modalId={linkSubUnitsModalId}
              submitLabel={t(
                'pageProperties.actions.linkSubUnitsModal.linkSubUnits',
              )}
            />
          </Modal.Footer>
        </fieldset>
      </FormWithProvider>
    </div>
  );
};

export default LinkSubUnitsModal;
