import useAppSelector from 'hooks/useAppSelector';
import { Unread, UnreadType } from '../../Header.types';
import { BadgeContainer } from './Badge.styles';

interface Props {
  type: UnreadType;
}

const Badge = ({ type }: Props) => {
  const lead = useAppSelector((state) => state.lead);
  const inbox = useAppSelector((state) => state.inbox);

  const getDisplayNumber = (notificationType: UnreadType) => {
    if (notificationType === Unread.lead) {
      return lead?.newLeadsCount > 0 ? lead?.newLeadsCount : null;
    }

    if (notificationType === Unread.message) {
      return inbox?.unreadThreads > 0 ? inbox.unreadThreads : null;
    }

    return null;
  };

  return <BadgeContainer type={type}>{getDisplayNumber(type)}</BadgeContainer>;
};

export default Badge;
