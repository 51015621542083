import useAppModal from 'hooks/useAppModal';
import AddSubUnitsModal from './AddSubUnitsModal';
import { AddSubUnitsModalProps } from './AddSubUnitsModal.types';
import { addSubUnitsModalId } from './AddSubUnitsModal.constants';

const useAddSubUnitsModal = () => {
  const { openModal } = useAppModal();

  const openAddSubUnitsModal = ({ propertyUid }: AddSubUnitsModalProps) => {
    openModal({
      customContent: <AddSubUnitsModal propertyUid={propertyUid} />,
      id: addSubUnitsModalId,
      size: 'medium',
    });
  };

  return {
    openAddSubUnitsModal,
  };
};

export default useAddSubUnitsModal;
