import { FormFooterStyled } from './Common.styles';
import FormFooterButtons from './FormFooterButtons';
import FormFooterSticky from './FormFooterSticky';

interface FormFooterProps {
  saveButtonLabel: string;
}

const FormFooter = ({ saveButtonLabel }: FormFooterProps) => {
  const buttons = <FormFooterButtons saveButtonLabel={saveButtonLabel} />;

  return (
    <>
      <FormFooterStyled data-testid="form-footer" id="form-footer">
        {buttons}
      </FormFooterStyled>
      <FormFooterSticky>{buttons}</FormFooterSticky>
    </>
  );
};

export default FormFooter;
