import { format } from 'date-fns';
import {
  PROPERTIES_SORTING_STRATEGY,
  PROPERTY_STATUS,
  PropertiesSortingStrategy,
  PropertyStatus,
} from 'models/Properties';

export interface MultiSelectOption {
  label?: string;
  value?: string;
}

export interface MultiSelectAmenity extends MultiSelectOption {
  isCustom?: boolean;
}

export interface PropertiesQueryFilter {
  agencyUid?: string;
  agentUid?: string;
  amenities?: MultiSelectAmenity[];
  availableFrom?: Date;
  availableTo?: Date;
  bedrooms?: [number?, number?];
  dailyRate?: [number?, number?];
  excludeMultiUnit?: boolean;
  guests?: [number?, number?];
  limit?: number;
  location?: string;
  name?: string;
  offset?: number;
  propertyUids?: string[];
  sortBy?: PropertiesSortingStrategy;
  status?: PropertyStatus | string;
  tags?: MultiSelectOption[];
  topLevelOnly?: boolean;
  type?: string;
}

const getPropertiesQuery = ({
  filters,
}: {
  filters: PropertiesQueryFilter;
}): string => {
  const propertyFilter = [];
  const {
    agencyUid,
    status,
    bedrooms,
    guests,
    dailyRate,
    location,
    tags,
    availableTo,
    availableFrom,
    amenities,
    agentUid,
    type,
    limit,
    offset,
    sortBy,
    excludeMultiUnit,
    name,
    propertyUids,
    topLevelOnly,
  } = filters;

  if (agencyUid) propertyFilter.push(`agencyUid: "${agencyUid}"`);
  if (type) propertyFilter.push(`type: "${type}"`);
  if (agentUid) propertyFilter.push(`userUids: "${agentUid}"`);
  if (status)
    propertyFilter.push(`isActive: ${status === PROPERTY_STATUS.ACTIVE}`);
  if (location) propertyFilter.push(`location: "${location}"`);

  if (bedrooms?.[0]) propertyFilter.push(`minimumBedrooms: "${bedrooms[0]}"`);
  if (bedrooms?.[1]) propertyFilter.push(`maximumBedrooms: "${bedrooms[1]}"`);

  if (guests?.[0]) propertyFilter.push(`minimumGuests: "${guests[0]}"`);
  if (guests?.[1]) propertyFilter.push(`maximumGuests: "${guests[1]}"`);

  if (dailyRate?.[0])
    propertyFilter.push(`minimumBaseDailyRate: "${dailyRate[0]}"`);
  if (dailyRate?.[1])
    propertyFilter.push(`maximumBaseDailyRate: "${dailyRate[1]}"`);

  if (tags)
    propertyFilter.push(
      `tagsConjunction: "${tags.map((tag) => tag.value).join(',')}"`,
    );
  if (amenities) {
    const onlyCustom = amenities.filter((a) => a.isCustom);
    const onlyAmenities = amenities.filter((a) => !a.isCustom);
    if (onlyCustom.length)
      propertyFilter.push(
        `customAmenitiesConjunction: "${onlyCustom
          .map((a) => a.value)
          .join(',')}"`,
      );
    if (onlyAmenities.length)
      propertyFilter.push(
        `amenitiesConjunction: "${onlyAmenities
          .map((a) => a.value)
          .join(',')}"`,
      );
  }
  if (availableTo)
    propertyFilter.push(
      `checkOutDate: "${format(new Date(availableTo), 'yyyy-MM-dd')}"`,
    );
  if (availableFrom)
    propertyFilter.push(
      `checkInDate: "${format(new Date(availableFrom), 'yyyy-MM-dd')}"`,
    );

  if (limit) {
    propertyFilter.push(`limit: "${limit}"`);
  }

  if (offset) {
    propertyFilter.push(`offset: "${offset}"`);
  }

  if (excludeMultiUnit !== undefined) {
    propertyFilter.push(`excludeMultiUnit: ${excludeMultiUnit}`);
  }

  if (name) {
    propertyFilter.push(`name: "${name}"`);
  }

  if (propertyUids) {
    propertyFilter.push(`propertyUids: "${propertyUids.join(',')}"`);
  }

  propertyFilter.push(
    `topLevelOnly: ${topLevelOnly === undefined ? 'true' : topLevelOnly}`,
  );
  propertyFilter.push(`returnTopLevelUnitsWithMatchingSubLevels: true`);

  propertyFilter.push(
    `sortStrategy: "${sortBy ?? PROPERTIES_SORTING_STRATEGY.SORT_BY_NAME}"`,
  );

  return propertyFilter.join(',');
};

export default getPropertiesQuery;
