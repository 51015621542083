import { ChannelLegendWrapper } from './CustomPieChart.styles';

interface ChannelLegendProps {
  label: string | React.ReactNode;
  color: string;
}

const ChannelLegend = ({ label, color }: ChannelLegendProps) => {
  return (
    <ChannelLegendWrapper data-testid="channel-legend-wrapper" $color={color}>
      {label}
    </ChannelLegendWrapper>
  );
};

export default ChannelLegend;
