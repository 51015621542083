import { ReactNode } from 'react';
import { Row, createColumnHelper } from '@tanstack/react-table';
import { TableProps } from 'components/table/Table.types';
import useTable from 'components/table/hooks/useTable';
import Table from 'components/table/Table';
import useFormatCurrency from 'hooks/useFormatCurrency';
import { PriceBadgeStyled } from './UpgradePlanModal.styles';

interface PriceRow {
  description: string;
  price: number;
  highlight?: boolean;
}

interface UpgradePlanModalTableProps
  extends Omit<TableProps<PriceRow>, 'data' | 'tableInstance'> {
  caption?: ReactNode;
  data: PriceRow[];
  footer?: ReactNode;
}

interface PriceCellProps {
  row: Row<PriceRow>;
  getValue: () => number;
}

const PriceCell = ({ row, getValue }: PriceCellProps) => {
  const format = useFormatCurrency();
  const value = format({ currency: 'USD', value: getValue() });

  return row.original.highlight ? (
    <PriceBadgeStyled>{value}</PriceBadgeStyled>
  ) : (
    value
  );
};

const UpgradePlanModalTable = ({
  caption,
  data,
  footer,
  ...tableProps
}: UpgradePlanModalTableProps) => {
  const columnHelper = createColumnHelper<PriceRow>();

  const columns = [
    columnHelper.accessor('description', {
      header: null,
      enableSorting: false,
    }),
    columnHelper.accessor('price', {
      header: null,
      cell: PriceCell,
      enableSorting: false,
    }),
  ];

  const tableInstance = useTable({ data, columns });

  return (
    <Table tableInstance={tableInstance} {...tableProps}>
      {caption && <Table.Caption>{caption}</Table.Caption>}
      <Table.ColGroup />
      <Table.Body />
      {footer && <Table.SingleCellFooter>{footer}</Table.SingleCellFooter>}
    </Table>
  );
};

UpgradePlanModalTable.defaultProps = {
  caption: undefined,
  footer: undefined,
};

export default UpgradePlanModalTable;
