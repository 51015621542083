import useAppUser from '../../../hooks/useAppUser';
import ThreadFilterFormWatcher from './ThreadFilterFormWatcher';
import ThreadFilterForm from './ThreadFilterForm';
import { InboxFilterFormContainer } from './InboxFilter.styles';

interface InboxFilterProps {
  isFilterOpen: boolean;
}

const InboxFilter = ({ isFilterOpen }: InboxFilterProps) => {
  const { isEmployee } = useAppUser();

  if (!isEmployee) {
    return null;
  }

  return (
    <InboxFilterFormContainer isCollapsed={!isFilterOpen}>
      <ThreadFilterFormWatcher />
      <ThreadFilterForm />
    </InboxFilterFormContainer>
  );
};

export default InboxFilter;
