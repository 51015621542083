import { differenceInHours, isAfter, isBefore } from 'date-fns';
import { stackedCalendarStyleConstants } from 'pages/stackedCalendar/Calendar.constants';
import { StackedCalendarMode } from 'pages/stackedCalendar/Calendar.types';
import { getFirstDateWithinViewport } from 'pages/stackedCalendar/navigation/StackedCalendarNavigation.utils';

export function getLeadLabelLeftPosition({
  mode,
  scrollPosition,
  startDate,
  renderedCheckInDay,
  renderedCheckOutDay,
  leadWidth,
  labelWidth,
}: {
  mode: StackedCalendarMode;
  scrollPosition: number;
  startDate: Date;
  renderedCheckInDay: Date;
  renderedCheckOutDay: Date;
  leadWidth: number;
  labelWidth: number;
}): number {
  const cellWidth = stackedCalendarStyleConstants.daysCellWidth[mode];
  const firstDateWithinViewport: Date = getFirstDateWithinViewport({
    mode,
    scrollPosition,
    startDate,
  });

  if (
    isBefore(renderedCheckInDay, firstDateWithinViewport) &&
    isAfter(renderedCheckOutDay, firstDateWithinViewport)
  ) {
    const maxLeftPosition = leadWidth - labelWidth;

    const diffInHours = differenceInHours(
      firstDateWithinViewport,
      renderedCheckInDay,
    );

    const leftPosition = diffInHours * (cellWidth / 24);

    if (leftPosition > maxLeftPosition) {
      return maxLeftPosition;
    }

    return leftPosition;
  }

  return 0;
}
