import { BaseResponse } from '../_base';

export interface Message {
  uid: string;
  threadUid: string;
  created: string;
  state: MessageState;
  channel: MessagingChannel;
  content?: MessageContent;
  messageCreator?: MessageCreator;
  senderDetails?: MessageSenderDetails;
  airbnbData?: MessageAirbnbData;
  bcomData?: MessageBComData;
  settings: MessageSettings;
  attachments?: MessageAttachment[];
}

export type MessageToSend = Required<
  Pick<Message, 'channel' | 'content' | 'senderDetails' | 'threadUid'>
> &
  Pick<Message, 'attachments'> & {
    creator: MessageCreator;
  };

export enum MessageCreatorType {
  AGENCY = 'AGENCY',
  GUEST = 'GUEST',
  OWNER = 'OWNER',
  PM = 'PM',
  SYSTEM = 'SYSTEM',
  TRIGGER = 'TRIGGER',
  UNKNOWN_AIRBNB_USER = 'UNKNOWN_AIRBNB_USER',
}

export enum MessageSenderType {
  AGENCY = 'AGENCY',
  GUEST = 'GUEST',
  OWNER = 'OWNER',
  SYSTEM = 'SYSTEM',
  UNKNOWN_AIRBNB_USER = 'UNKNOWN_AIRBNB_USER',
}

export enum MessageState {
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SENT = 'SENT',
}

export enum MessagingChannel {
  AIRBNB = 'AIRBNB',
  AUTO = 'AUTO',
  BOOKING_COM = 'BOOKING_COM',
  EMAIL = 'EMAIL',
  HOSTFULLY_PMP = 'HOSTFULLY_PMP',
  SMS = 'SMS',
  VRBO = 'VRBO',
  WHATSAPP = 'WHATSAPP',
}

export enum AgencyMessagingRecipientType {
  JOB = 'JOB',
}

export enum MessageAttachmentType {
  CSV = 'CSV',
  IMAGE_GIF = 'IMAGE_GIF',
  IMAGE_JPEG = 'IMAGE_JPEG',
  IMAGE_PNG = 'IMAGE_PNG',
  PDF = 'PDF',
  ZIP = 'ZIP',
}

export interface MessageAttachment {
  uid?: string;
  uri?: string;
  fileName?: string;
  sizeInBytes?: number;
  type?: MessageAttachmentType;
}

export interface MessageTemporaryAttachment extends MessageAttachment {
  file?: File;
  isUploading: boolean;
  errorMessage?: string;
  uploadItemName?: string;
}

export interface MessageContent {
  subject?: string;
  text?: string;
}

export interface MessageCreator {
  creatorUid: string;
  creatorType: MessageCreatorType;
}

export interface MessageSenderDetails {
  senderType: MessageSenderType;
  senderUid: string;
  senderName?: string;
  replyTo?: string;
}

export interface MessageAirbnbData {}

export interface MessageBComData {}

export interface MessageSettings {
  shouldCopyAgency: boolean;
}

export interface GetMessagesResponseTO extends BaseResponse {
  messages: Message[];
}

export interface SendMessageResponseTO extends BaseResponse {
  message: Message;
}
