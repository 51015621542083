import {
  $isTextNode,
  DOMConversion,
  DOMConversionMap,
  DOMConversionOutput,
  TextNode,
  SerializedTextNode,
  ParagraphNode,
  EditorConfig,
} from 'lexical';

export class ExtendedTextNode extends TextNode {
  static getType(): string {
    return 'extended-text';
  }

  static clone(node: ExtendedTextNode): ExtendedTextNode {
    // eslint-disable-next-line no-underscore-dangle
    return new ExtendedTextNode(node.__text, node.__key);
  }

  static importDOM(): DOMConversionMap | null {
    const importersParagraphNode = ParagraphNode.importDOM();
    const importersTextNode = TextNode.importDOM();
    return {
      ...importersParagraphNode,
      ...importersTextNode,
      p: () => ({
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        conversion: patchStyleConversion(importersParagraphNode?.p),
        priority: 1,
      }),
      span: () => ({
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        conversion: patchStyleConversion(importersTextNode?.span),
        priority: 1,
      }),
    };
  }

  static importJSON(serializedNode: SerializedTextNode): TextNode {
    return TextNode.importJSON(serializedNode);
  }

  exportJSON(): SerializedTextNode {
    return super.exportJSON();
  }
}

function patchStyleConversion(
  originalDOMConverter?: (node: HTMLElement) => DOMConversion | null,
): (node: HTMLElement) => DOMConversionOutput | null {
  return (node) => {
    const original = originalDOMConverter?.(node);
    if (!original) {
      return null;
    }
    const originalOutput = original.conversion(node);

    if (!originalOutput) {
      return originalOutput;
    }

    const { style } = node;

    if (originalOutput.node instanceof ParagraphNode) {
      const origCreateDom = originalOutput.node.createDOM;

      originalOutput.node.createDOM = (config: EditorConfig) => {
        const dom = origCreateDom(config);

        if (style.length) {
          dom.setAttribute('style', style.cssText);
        }

        return dom;
      };
    }

    return {
      ...originalOutput,
      forChild: (lexicalNode, parent) => {
        const originalForChild = originalOutput?.forChild ?? ((x) => x);
        const result = originalForChild(lexicalNode, parent);

        if (style.length && $isTextNode(result)) {
          return result.setStyle(style.cssText);
        }
        return result;
      },
    };
  };
}
