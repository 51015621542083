import * as Yup from 'yup';

// Should be https and do not contain any parameter
const exchangeTokenSchema = (t) => ({
  redirectUri: Yup.string()
    .url()
    .required()
    .matches(/^https:\/\/[^?]+\/*$/, t('pageExchangeToken.invalidURI')),
});

export default exchangeTokenSchema;
