import { Glyphicon } from 'react-bootstrap';
import Tooltip from 'components/tooltip/Tooltip';

const InfoIcon = ({
  id,
  tooltipPlacement,
  tooltipText,
}: {
  id: string;
  tooltipPlacement?: 'top' | 'right' | 'bottom' | 'left';
  tooltipText: string;
}) => {
  return (
    <Tooltip
      id={`${id}-tooltip`}
      text={tooltipText}
      placement={tooltipPlacement}
    >
      <Glyphicon glyph="info-sign" id={id} data-testid={id} />
    </Tooltip>
  );
};

InfoIcon.defaultProps = {
  tooltipPlacement: undefined,
};

export default InfoIcon;
