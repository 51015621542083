import { useTranslation } from 'react-i18next';
import { Employee } from 'models/Employees';
import { PropertyItemPermissionsEmployeeInfoPropsStyled } from './PropertyItemPermissions.styles';

interface PropertyItemPermissionsEmployeeInfoProps {
  isAutoAssignee?: boolean;
  employee: Employee;
}

const PropertyItemPermissionsEmployeeInfo = ({
  isAutoAssignee,
  employee,
}: PropertyItemPermissionsEmployeeInfoProps) => {
  const { t } = useTranslation();
  const tooltipParts = [
    employee.bestProfile,
    t(`common.userRole.${employee.role}`),
    isAutoAssignee && t('pageProperties.permissions.autoAssignee'),
  ].filter(Boolean);

  return (
    <PropertyItemPermissionsEmployeeInfoPropsStyled>
      {tooltipParts.map((text) => (
        <div key="text">{text}</div>
      ))}
    </PropertyItemPermissionsEmployeeInfoPropsStyled>
  );
};

PropertyItemPermissionsEmployeeInfo.defaultProps = {
  isAutoAssignee: undefined,
};

export default PropertyItemPermissionsEmployeeInfo;
