import Loader from 'components/loader/Loader';
import useAppSelector from 'hooks/useAppSelector';
import { IframeLoaderWrapper } from './LegacyPageLoader.styles';

const LegacyPageLoader = () => {
  const isLoading = useAppSelector(
    (state) => state.legacyPage.isLegacyPageLoading,
  );

  if (isLoading) {
    return (
      <IframeLoaderWrapper>
        <Loader />
      </IframeLoaderWrapper>
    );
  }

  return null;
};

export default LegacyPageLoader;
