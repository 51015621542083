import { queryClient } from 'App';
import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import {
  RentalConditionResourceType,
  RentalConditions,
} from 'models/RentalCondition';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import API from 'services/API';
import { RENTAL_CONDITIONS_BASE_QUERY_KEY } from '../useRentalConditionsData';
import EditRentalConditionsModal from './EditRentalConditionsModal';
import { EditRentalConditionsFormSchemaType } from './EditRentalConditionsModal.schema';

export const modalId = 'edit-rental-conditions-modal';

export const EDIT_RENTAL_CONDITIONS_BASE_MUTATION_KEY =
  'edit-rental-conditions';

type Props = {
  rentalConditions: RentalConditions;
};

const useEditRentalConditions = ({ rentalConditions }: Props) => {
  const { property } = usePropertySettingsInitData();
  const { openModal, closeModal } = useAppModal();

  const mutationKey = [EDIT_RENTAL_CONDITIONS_BASE_MUTATION_KEY, property.uid];

  const { mutate: editRentalCondition } = useAppMutation(
    mutationKey,
    ({
      rentalConditions: newRentalConditions,
    }: EditRentalConditionsFormSchemaType) => {
      return API.post('/v3/rental-conditions/', {
        propertyUid: property.uid,
        rentalConditions: newRentalConditions,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          RENTAL_CONDITIONS_BASE_QUERY_KEY,
          RentalConditionResourceType.PROPERTY,
          property.uid,
        ]);

        closeModal(modalId);
      },
    },
  );

  const handleSubmit = (values: EditRentalConditionsFormSchemaType) => {
    // TODO: change to new API endpoint in PMP-10867
    editRentalCondition(values);
  };

  const openEditModal = () => {
    openModal({
      id: modalId,
      customContent: (
        <EditRentalConditionsModal
          property={property}
          onSubmit={handleSubmit}
          rentalConditions={rentalConditions}
          modalId={modalId}
          mutationKey={mutationKey}
        />
      ),
    });
  };

  return { openEditModal };
};

export default useEditRentalConditions;
