import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, PropertyInfoWrapper } from './PropertyCard.styles';

interface PropertyCardProps {
  name: string;
  address: string;
  aditionalInformation: string;
  imageUrl: string;
  rentalUrl: string;
}

const PropertyCard: React.FC<PropertyCardProps> = ({
  name,
  address,
  imageUrl,
  aditionalInformation,
  rentalUrl,
}) => {
  const { t } = useTranslation();

  const handleCardClick = () => {
    if (!rentalUrl) return;

    window.open(rentalUrl, '_blank');
  };

  return (
    <Container onClick={handleCardClick}>
      <PropertyInfoWrapper>
        <img src={imageUrl} alt={`${name} thumbnail`} />

        <div>
          <h3>{name}</h3>
          <p>{address}</p>
          <p>{aditionalInformation}</p>
        </div>
      </PropertyInfoWrapper>

      {!!rentalUrl && (
        <a href={rentalUrl} target="__blank">
          {t('pageProperties.viewListing')}
        </a>
      )}
    </Container>
  );
};

export default PropertyCard;
