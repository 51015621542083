import { useQueryClient } from '@tanstack/react-query';
import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import {
  CancellationPolicy,
  CancellationPolicyValue,
} from 'models/CancellationPolicy';
import API from 'services/API';
import { LanguageCodeUnderscored } from 'i18n/internationalization.types';
import { CANCELLATION_POLICY_BASE_QUERY_KEY } from '../useCancellationPolicyData';
import EditCancellationPolicyModal, {
  CancellationPolicyEditFormSchemaType,
  editModalId,
} from './EditCancellationPolicyModal';

const EDIT_CANCELLATION_POLICY_BASE_MUTATION_KEY = 'edit-cancellation-policy';

const useEditCancellationPolicy = ({
  cancellationPolicyUid,
}: {
  cancellationPolicyUid: CancellationPolicy['uid'];
}) => {
  const { openModal, closeModal } = useAppModal();
  const queryClient = useQueryClient();

  const mutationKey = [EDIT_CANCELLATION_POLICY_BASE_MUTATION_KEY];

  const { mutate: updateCancellationPolicy } = useAppMutation(
    mutationKey,
    (payload: { values: CancellationPolicyValue[] }) => {
      return API.put(
        `/api/internal/cancellation-policies/${cancellationPolicyUid}`,
        payload,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CANCELLATION_POLICY_BASE_QUERY_KEY]);
        closeModal(editModalId);
      },
    },
  );

  const handleSubmit = (values: CancellationPolicyEditFormSchemaType) => {
    const payload = {
      values: [
        {
          text: values.text,
          locale: LanguageCodeUnderscored.EN_US,
        },
      ],
    };

    updateCancellationPolicy(payload);
  };

  const openEditModal = (cancellationPolicyValue: CancellationPolicyValue) => {
    openModal({
      id: editModalId,
      customContent: (
        <EditCancellationPolicyModal
          cancellationPolicyValue={cancellationPolicyValue}
          onSubmit={handleSubmit}
          mutationKey={mutationKey}
        />
      ),
    });
  };

  return { openEditModal };
};

export default useEditCancellationPolicy;
