import { useMemo } from 'react';
import InboxLeadBody from './InboxLeadBody';
import InboxLeadsContextProvider from './InboxLeadsContextProvider';

const InboxLead = () => {
  const inboxLead = useMemo(() => <InboxLeadBody />, []);
  return <InboxLeadsContextProvider>{inboxLead}</InboxLeadsContextProvider>;
};

export default InboxLead;
