import styled from 'styled-components';

export const Container = styled.div.attrs({
  className: 'container',
  'data-testid': 'employee-account-container',
})``;

export const FormFieldsContainer = styled.div`
  max-width: 600px;

  & .form-group label:not(.label-on-top) {
    text-align: left !important;
    margin-left: 0 !important;
  }

  .phone-number-field-wrapper {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  & input#upload-profile-picture {
    display: none;
  }
`;

export const ProfilePicture = styled.div`
  & .profile-placeholder {
    text-transform: uppercase;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e0e0e0;
    font-size: 24px;
    color: #fff;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #ccc;
  }
`;

export const ProfileUpload = styled.div.attrs({
  className: 'col-xs-10 col-sm-9',
})`
  padding-left: 15px;
  display: flex;

  & button {
    display: flex;
    align-items: center;

    .glyphicon {
      font-size: 14px;
      margin-right: 8px;
    }

    margin-left: 10px;
  }
`;

export const LabelText = styled.label.attrs({
  className: 'control-label col-xs-2 col-sm-3',
})``;

export const RoleStaticValue = styled.div.attrs({
  className: 'col-xs-10 col-sm-9',
  'data-testid': 'role-static-value',
})`
  padding-top: 7px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & button {
    margin-left: 16px;
  }
`;

export const PhoneAuthModalContainer = styled.div`
  & h2 {
    text-align: center;
  }
`;

export const ErrorMessage = styled.div.attrs({
  className: 'alert alert-danger',
  'data-testid': 'error-message-api',
})`
  margin-top: 10px;
  padding: 6px 12px;
`;
