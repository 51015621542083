/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import { Row, Col } from 'react-bootstrap';
import {
  canLeadViewPaymentLink,
  getPaymentLink,
  shouldHandleSecurityDeposit,
} from 'utils/lead/leadUtils';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import LeadModalContext from '../../LeadModalContext';
import {
  ColWithMargin,
  PaymentTimelineRow,
  CopyLinkButton,
} from './LeadModalPaymentTimelineTab.styles';

const PaymentTimelineTab = () => {
  const { t } = useTranslation();
  const { lead } = useContext(LeadModalContext);
  const { copyToClipBoard } = useCopyToClipboard();

  if (!lead) return null;

  const wasSecurityDepositReceivedAndReturned =
    lead.wasSecurityDepositReceived && lead.wasSecurityDepositReturned;

  const handleCopyLink = () => {
    copyToClipBoard(
      getPaymentLink(lead),
      t('componentLead.modal.paymentTimelineTab.paymentSuccessfullyCopied'),
    );
  };

  return (
    <Row style={{ marginTop: '10px' }}>
      <>
        {canLeadViewPaymentLink(lead) && (
          <PaymentTimelineRow xs={12}>
            <ColWithMargin sm={4} textLeft>
              <b>{t('componentLead.modal.paymentTimelineTab.paymentLink')}</b>
            </ColWithMargin>
            <Col sm={8}>
              <a target="_blank" href={getPaymentLink(lead)} rel="noreferrer">
                {t('componentLead.modal.paymentTimelineTab.paymentLinkURL')}
              </a>

              <CopyLinkButton onClick={handleCopyLink}>
                {t('componentLead.modal.paymentTimelineTab.copyLink')}
              </CopyLinkButton>
            </Col>
          </PaymentTimelineRow>
        )}
        {shouldHandleSecurityDeposit(lead) && (
          <Col xs={12}>
            {wasSecurityDepositReceivedAndReturned ? (
              <>
                <ColWithMargin sm={4} textLeft>
                  {t(
                    'componentLead.modal.paymentTimelineTab.securityDepositReturn',
                  )}
                </ColWithMargin>
                <ColWithMargin sm={8}>
                  {t('componentLead.modal.paymentTimelineTab.returned')}
                </ColWithMargin>
              </>
            ) : (
              <>
                <ColWithMargin sm={4} textLeft>
                  {t(
                    'componentLead.modal.paymentTimelineTab.securityDepositReturn',
                  )}
                </ColWithMargin>
                <Col sm={8}>
                  <CheckboxField name="securityDeposit">
                    {t('componentLead.modal.paymentTimelineTab.markAs')}{' '}
                    {lead.wasSecurityDepositReceived
                      ? t('componentLead.modal.paymentTimelineTab.returned')
                      : t('componentLead.modal.paymentTimelineTab.received')}
                  </CheckboxField>
                </Col>
              </>
            )}
          </Col>
        )}
      </>
    </Row>
  );
};

export default PaymentTimelineTab;
