import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { leadActionsPopoverContainerClassName } from 'components/domain/lead/actions/LeadActionsPopover.constants';
import FormWithProvider from 'components/forms/form/Form';
import useScreenBreakpoints from 'hooks/useScreenBreakpoints';
import useFilterIcon from 'hooks/useFilterIcon';
import PageHeader from 'components/pageHeader/PageHeader';
import * as Yup from 'yup';
import PipelineLeadFilter from './filter/PipelineLeadFilter';
import PipelineFilters from './filter/PipelineFilters';
import usePipelineSubmit from './usePipelineSubmit';
import PipelineLeadsList from './PipelineLeadsList';
import usePipelineForm from './usePipelineForm';
import { pipelineFilterFormExpandedStorageKey } from './Pipeline.constants';

const PipelineWrapper = () => {
  const { t } = useTranslation();
  const { isXS } = useScreenBreakpoints();
  const { filterIconWithCounterFromForm: FilterIconRenderer, isFilterOpen } =
    useFilterIcon({
      stateStorageKey: pipelineFilterFormExpandedStorageKey,
    });
  const { getDefaultValues } = usePipelineForm();
  const { handleSubmit } = usePipelineSubmit();
  const defaultValues = getDefaultValues();

  const pipelineFormSchema = () => ({
    sortStrategy: Yup.string(),
    range: {
      checkInFrom: Yup.date(),
      checkInTo: Yup.date(),
    },
    leadStatus: Yup.string(),
    lead: Yup.array().of(Yup.object()),
    propertyUid: Yup.string(),
    channel: Yup.string(),
    userUid: Yup.string(),
  });

  return (
    <FormWithProvider
      horizontal
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      schema={pipelineFormSchema}
    >
      <PageHeader
        title={t('pagePipeline.pipelineHeader')}
        help={
          <>
            <a data-testid="pipeline-see-customers-link" href="#/clients">
              {t('pagePipeline.seeAllCustomers')}
            </a>
            {!isXS && <PipelineLeadFilter />}
            <FilterIconRenderer />
          </>
        }
      />

      <Row>
        <Col xsOffset={2} xs={8}>
          {isXS && <PipelineLeadFilter />}
        </Col>
      </Row>

      <PipelineFilters isFilterOpen={isFilterOpen} />

      <Row>
        <Col
          className={leadActionsPopoverContainerClassName}
          data-testid="pipeline-leads-list-wrapper"
          xs={12}
        >
          <PipelineLeadsList />
        </Col>
      </Row>
    </FormWithProvider>
  );
};

export default PipelineWrapper;
