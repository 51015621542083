import { useTranslation } from 'react-i18next';
import { isBefore } from 'date-fns';
import useScreenBreakpoints from 'hooks/useScreenBreakpoints';
import useWithScrollableShadow from 'hooks/useWithScrollableShadow';
import { Transaction } from 'models/Transactions';
import { getTransactionCreatedDate } from 'utils/tests/transaction/transactionUtils';
import useOrderViewFetchedData from '../useOrderViewFetchedData';
import {
  TransactionList,
  TransactionListEmptyMessage,
  TransactionListWrapper,
} from './OrderViewTransactionsList.styles';
import OrderViewTransactionsListItem from './OrderViewTransactionsListItem';

function transactionsSorter(
  transaction1: Transaction,
  transaction2: Transaction,
) {
  const createdDate1 = getTransactionCreatedDate(transaction1);
  const createdDate2 = getTransactionCreatedDate(transaction2);
  return isBefore(createdDate1, createdDate2) ? 1 : -1;
}

const OrderViewTransactionsList = () => {
  const { lead } = useOrderViewFetchedData();
  const { isSM, isXS } = useScreenBreakpoints();
  const { t } = useTranslation();

  const withScrollableShadowRef = useWithScrollableShadow(isSM || isXS);

  const transactions = lead?.order?.transactions || [];

  return (
    <div ref={withScrollableShadowRef}>
      <TransactionListWrapper data-testid="transactions-list">
        {!transactions.length && (
          <TransactionListEmptyMessage data-testid="transactions-list-empty-message">
            {t('pageOrder.transactions.emptyMessage')}
          </TransactionListEmptyMessage>
        )}
        {!!transactions.length && (
          <TransactionList>
            {transactions.toSorted(transactionsSorter).map((transaction) => (
              <OrderViewTransactionsListItem
                key={transaction.uid}
                transaction={transaction}
              />
            ))}
          </TransactionList>
        )}
      </TransactionListWrapper>
    </div>
  );
};

export default OrderViewTransactionsList;
