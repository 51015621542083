import styled, { css } from 'styled-components';
import mediaQuery from 'styles/mediaQuery';
import theme from 'styles/theme';

interface ContainerProps {
  isLowOpacity: boolean;
}

export const Container = styled.div<ContainerProps>`
  min-height: 160px;
  padding: 10px;
  border-bottom: 1px solid rgba(173, 181, 189, 1);
  transition: 0.3s;
  cursor: pointer;
  opacity: ${(props) => (props.isLowOpacity ? '.6' : '1')};

  .show-on-hover {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;
    margin-top: 10px;
  }

  .show-not-on-hover {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    opacity: 1;
    transition: 0.3s;
    pointer-events: none;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &:hover {
    background: ${theme.colors.bgHover};

    .show-on-hover {
      opacity: 1;
      pointer-events: all;
    }

    .show-not-on-hover {
      opacity: 0;
    }
  }

  ${mediaQuery.sm} {
    &:hover {
      .show-on-hover {
        display: none;
      }

      .show-not-on-hover {
        opacity: 1;
      }
    }
  }
`;

export const LeftSide = styled.div`
  color: ${theme.colors.gray600};
  width: 100%;

  .checkin-header {
    font-weight: 700;
  }

  .less-opacity {
    opacity: 0.7;
  }
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  min-width: fit-content;
`;

export const TagsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: white;
    border-radius: 5px;
  }

  ${mediaQuery.sm} {
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;

    > div {
      font-size: 12px;
    }
  }
`;

export const StatusIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

interface StatusTagProps {
  backgroundColor: string;
}

export const StatusTag = styled.div<StatusTagProps>`
  background: ${(props: ButtonTagProps) => props.backgroundColor};
`;

interface ButtonTagProps {
  backgroundColor: string;
}

const buttonAndAnchorBaseStyles = css`
  min-width: 95px;
  align-self: flex-end;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: white;
  transition: 0.3s;
  opacity: 1;

  background: ${(props: ButtonTagProps) => props.backgroundColor};

  &:hover {
    opacity: 0.8;
    text-decoration: none;
    color: white;
  }

  ${mediaQuery.sm} {
    font-size: 12px;
  }
`;

export const ButtonTag = styled.button<ButtonTagProps>`
  ${buttonAndAnchorBaseStyles}
`;

export const AnchorTag = styled.a<ButtonTagProps>`
  ${buttonAndAnchorBaseStyles}
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActionsWrapper = styled.div`
  position: relative;
  min-width: 105px;
  height: 100%;
  display: flex;

  ${mediaQuery.sm} {
    display: none;
  }

  .dashboard-lead-action-container-img-wrapper {
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    padding-top: 15px;
  }
`;

interface ChannelWrapperProps {
  channelColor: string;
}

export const ChannelWrapper = styled.span<ChannelWrapperProps>`
  color: ${(props) => props.channelColor};

  svg {
    width: 16px;
    height: 16px;
    margin: 0 4px -2px 2px;
  }
`;

export const TopSide = styled.div`
  width: 100%;
  max-height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(108, 117, 125, 1);

  .checkin-header {
    font-weight: 700;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LeadHeader = styled.div``;
