import { HelpBlock } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface ValidationError {
  /** key into translation file */
  key: string;

  /** token/params values */
  values?: { [key: string]: any };
}

interface ErrorMessageProps {
  error?: ValidationError | string;
}

const ErrorMessage = ({ error }: ErrorMessageProps) => {
  const { t } = useTranslation();

  if (error === undefined) {
    return null;
  }
  if (typeof error === 'string') {
    return <HelpBlock>{error}</HelpBlock>;
  }
  const { key, values } = error;
  // @ts-ignore
  return <HelpBlock>{t(key, values)}</HelpBlock>;
};

ErrorMessage.defaultProps = {
  error: undefined,
};

export default ErrorMessage;
