import { Badge } from 'react-bootstrap';
import styled from 'styled-components';
import theme from 'styles/theme';
import { TransactionType } from 'models/Transactions';

const typeToBgColor = {
  [TransactionType.REFUND]: theme.colors.hostfullyPurple,
  [TransactionType.SALE]: theme.colors.primary,
  [TransactionType.SECURITY_DEPOSIT_CHARGE]: theme.colors.primary,
};

export const TransactionTypeBadge = styled(Badge)<{ $type: TransactionType }>`
  background-color: ${({ $type }) => typeToBgColor[$type]};
  border-radius: 5px;
`;

export const TransactionManualBadge = styled(Badge)`
  background-color: ${theme.colors.hostfullyYellow};
  border-radius: 5px;
  margin-left: 0.5rem;
`;
