import { User, UserEmployeeRole, UserType } from '../../models/Users';

export function convertUserToAppUser(user: User) {
  const { agency, type: userType, uid: userUid, employeeRole } = user;
  const agencyUid = agency?.uid;
  const isAgent = userType === UserType.Agent;
  const isEmployee = userType === UserType.Employee;
  const isEmployeeAssociate =
    isEmployee && employeeRole === UserEmployeeRole.ASSOCIATE;
  const isEmployeeBookingAgent =
    isEmployee && employeeRole === UserEmployeeRole.BOOKING_AGENT;
  const isEmployeeManager =
    isEmployee && employeeRole === UserEmployeeRole.MANAGER;
  const isEmployeePropertySalesManager =
    isEmployee && employeeRole === UserEmployeeRole.PROPERTY_SALES_MANAGER;
  const isGuest = userType === UserType.Guest;
  const isOwner = userType === UserType.Owner;
  const isServiceProvider = userType === UserType.ServiceProviderUser;

  return {
    agency,
    agencyUid,
    isAgent,
    employeeRole,
    isEmployee,
    isEmployeeAssociate,
    isEmployeeBookingAgent,
    isEmployeeManager,
    isEmployeePropertySalesManager,
    isGuest,
    isOwner,
    isServiceProvider,
    user,
    userType,
    userUid,
  };
}
