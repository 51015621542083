import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useFormatNumber = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return useMemo(
    () =>
      ({
        value,
        options = {
          notation: 'standard',
          maximumFractionDigits: 2,
        },
      }: {
        value: number;
        options?: {
          notation?: 'standard' | 'scientific' | 'engineering' | 'compact';
          maximumFractionDigits?: number;
        };
      }) => {
        const { notation, maximumFractionDigits } = options;
        const formatter = Intl.NumberFormat(language, {
          notation,
          maximumFractionDigits,
        });

        return formatter.format(value);
      },
    [language],
  );
};

export default useFormatNumber;
