import { useTranslation } from 'react-i18next';
import useInvalidateFetchAllProperties from 'components/domain/property/useInvalidateFetchAllProperties';
import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import API from 'services/API';
import { modalIds } from 'components/modal/Modal.constants';
import useNotify from 'hooks/useNotify';
import { useQueryClient } from '@tanstack/react-query';
import { PROPERTIES_FOR_PROPERTIES_BODY_BASE_QUERY_KEY } from 'pages/agencyProperties/body/useFetchPropertiesForPropertiesBody';
import { ConfirmationModalProps } from './Confirmation.types';
import { PropertyNameStyled } from './Confirmation.styles';

interface DeleteModalProps extends ConfirmationModalProps {
  isHotel: boolean;
  isMasterUnit: boolean;
}

const useDeleteModal = () => {
  const queryClient = useQueryClient();
  const { notifySuccess } = useNotify();
  const { t } = useTranslation();
  const { openConfirmModal, closeModal, setErrorConfirmModal } = useAppModal();
  const { invalidateFetchAllProperties } = useInvalidateFetchAllProperties();

  const closeConfirmationModal = () => {
    closeModal(modalIds.confirm);
  };

  const { mutateAsync } = useAppMutation(
    ['deleteProperty'],
    async ({
      uid,
      isHotel,
    }: {
      uid: string;
      name: string;
      isHotel: boolean;
    }) => {
      const url = isHotel
        ? `/api/internal/hotels/${uid}`
        : `/api/v3/properties/${uid}`;
      const response = await API.delete(url);
      return response.data;
    },
    {
      onSuccess: (_, { name }) => {
        notifySuccess(
          t('pageProperties.actions.deletionSuccess', {
            name: name.length > 30 ? `${name.slice(0, 30)}...` : name,
          }),
        );
        closeConfirmationModal();
        queryClient.invalidateQueries(
          [PROPERTIES_FOR_PROPERTIES_BODY_BASE_QUERY_KEY],
          { exact: false },
        );
        invalidateFetchAllProperties();
      },
      onError: (error: any) => {
        setErrorConfirmModal(error.response.data.apiErrorMessage);
      },
    },
  );

  const handleDelete = (uid: string, name: string, isHotel: boolean) =>
    new Promise<void>((resolve) => {
      mutateAsync({ uid, name, isHotel }).then(resolve);
    });

  const openDeleteModal = ({
    uid,
    name,
    isHotel,
    isMasterUnit,
  }: DeleteModalProps) => {
    openConfirmModal({
      body: (
        <>
          {t('pageProperties.actions.deletePropertyConfirmation')}{' '}
          <PropertyNameStyled>{name}</PropertyNameStyled>?
          {isHotel && <p>{t('pageProperties.actions.deleteHotelUnits')}</p>}
          {isMasterUnit && (
            <p>{t('pageProperties.actions.deleteMasterSubUnits')}</p>
          )}
        </>
      ),
      onConfirmAsync: () => handleDelete(uid, name, isHotel),
      title: t('pageProperties.actions.common.areYouSure'),
      confirmLabel: t('pageProperties.actions.delete'),
      confirmColor: 'danger',
    });
  };

  return {
    openDeleteModal,
  };
};

export default useDeleteModal;
