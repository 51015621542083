import styled from 'styled-components';

export const RadioGroupContainer = styled.div<{ $horizontal?: boolean }>`
  display: flex;
  flex-direction: ${({ $horizontal }) => ($horizontal ? 'row' : 'column')};
  gap: ${({ $horizontal }) => ($horizontal ? '16px' : '5px')};
  margin: 10px 0;

  .radio {
    margin: 0;
  }
`;
