import { Currency } from 'models/Currency';
import { BaseResponse } from 'models/_base';

export const OWNER_ADJUSTMENT_STATUS = {
  ACTIVE: 'ACTIVE',
  UPCOMING: 'UPCOMING',
  ENDED: 'ENDED',
};

export type OwnerAdjustmentStatus =
  (typeof OWNER_ADJUSTMENT_STATUS)[keyof typeof OWNER_ADJUSTMENT_STATUS];

export const OWNER_ADJUSTMENT_TYPE = {
  CLEANING_MAINTENANCE: 'CLEANING_MAINTENANCE',
  INSURANCE: 'INSURANCE',
  MANAGEMENT_FEES: 'MANAGEMENT_FEES',
  REPAIRS: 'REPAIRS',
  SUPPLIES: 'SUPPLIES',
  UTILITIES: 'UTILITIES',
  OTHER: 'OTHER',
};

export type OwnerAdjustmentType =
  (typeof OWNER_ADJUSTMENT_TYPE)[keyof typeof OWNER_ADJUSTMENT_TYPE];

export interface OwnerAdjustmentResponse {
  uid: string;
  propertyUid: string;
  ownerAdjustmentScheduleUid: string | null;
  amount: number;
  attachedImageURL: string | null;
  attachedImageName: string | null;
  created: string;
  currency: Currency;
  currencySymbol: string;
  dateAccountedFor: string;
  name: string;
  notes: string;
  taxationRate: number;
  type: OwnerAdjustmentType | null;
}

export interface OwnerAdjustment
  extends Omit<OwnerAdjustmentResponse, 'created' | 'dateAccountedFor'> {
  created: Date;
  dateAccountedFor: Date;
}

export interface GetOwnerAdjustmentsResponseTO extends BaseResponse {
  ownerAdjustments: OwnerAdjustmentResponse[];
}
