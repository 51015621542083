import { useContext } from 'react';
import LeadModalContext from 'components/domain/lead/modal/LeadModalContext';
import { useTranslation } from 'react-i18next';
import useFormatCurrency from 'hooks/useFormatCurrency';
import { PreWarning } from './Fields.styles';

interface TotalProps {
  isAPayout: boolean;
}

const Total = ({ isAPayout }: TotalProps) => {
  const { quote } = useContext(LeadModalContext);
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();

  return (
    <tr>
      <PreWarning>
        <b>
          {isAPayout ? t('componentOrder.payout') : t('componentOrder.total')}
        </b>
      </PreWarning>
      <PreWarning colSpan={100}>
        {quote.totalPrice === 0
          ? t('componentOrder.toBeDefinedShort')
          : formatCurrency({
              currency: quote.currency,
              value: quote.totalPrice,
            })}
      </PreWarning>
    </tr>
  );
};

export default Total;
