import { useTranslation } from 'react-i18next';
import { PresetDateRange } from 'utils/dateTimeUtils';
import SelectField from '../selectField/SelectField';

interface Props {
  name: string;
  [key: string]: any;
}

const RangeDropdownField = ({ ...rest }: Props) => {
  const { t } = useTranslation();

  const presetDateRangeOptions = Object.values(PresetDateRange).map(
    (range: PresetDateRange) => ({
      label: t(`form.presetDateRange.${range}`),
      value: range,
    }),
  );

  return (
    <SelectField
      colSmInput={12}
      colSmLabel={0}
      options={presetDateRangeOptions}
      {...rest}
    />
  );
};

export default RangeDropdownField;
