import { AmenityChannel } from 'models/Amenity';

import HostfullyIcon from 'assets/images/icons/channels/hostfully.svg?react';
import AirbnbIcon from 'assets/images/icons/channels/airbnb.svg?react';
import HvmiIcon from 'assets/images/icons/channels/hvmi.svg?react';
import VrboIcon from 'assets/images/icons/channels/vrbo.svg?react';
import BookingIcon from 'assets/images/icons/channels/booking.svg?react';
import GoogleIcon from 'assets/images/icons/channels/google.svg?react';

import { ChannelItemButtonStyled } from './ChannelFilter.styles';

const channelToIconMapping = {
  [AmenityChannel.HOSTFULLY]: <HostfullyIcon />,
  [AmenityChannel.AIRBNB]: <AirbnbIcon />,
  [AmenityChannel.VRBO]: <VrboIcon />,
  [AmenityChannel.BOOKINGDOTCOM]: <BookingIcon />,
  [AmenityChannel.GOOGLE]: <GoogleIcon />,
  [AmenityChannel.HVMI]: <HvmiIcon />,
};

const channelToLabelMapping = {
  [AmenityChannel.HOSTFULLY]: 'Hostfully',
  [AmenityChannel.AIRBNB]: 'Airbnb',
  [AmenityChannel.VRBO]: 'Vrbo',
  [AmenityChannel.BOOKINGDOTCOM]: 'Booking.com',
  [AmenityChannel.GOOGLE]: 'Google',
  [AmenityChannel.HVMI]: 'HVMI',
};

const ChannelItemButton = ({
  channel,
  isActive,
  onClick,
}: {
  channel: AmenityChannel;
  isActive: boolean;
  onClick: () => void;
}) => {
  return (
    <ChannelItemButtonStyled
      $isActive={isActive}
      onClick={onClick}
      data-testid={`amenities-channel-item-button-${channel}`}
    >
      {channelToIconMapping[channel]}
      <span>{channelToLabelMapping[channel]}</span>
    </ChannelItemButtonStyled>
  );
};

export default ChannelItemButton;
