import useAppMutation from 'hooks/useAppMutation';
import { BaseErrorResponse } from 'models/_base';
import API from 'services/API';
import { PropertyMainSettingsUpdateResponse } from './PropertySettingsMainTab.types';
import { PropertyMainSettingsUpdatePayload } from './PropertySettingsMainTab.schema';

const useUpdatePropertyMainSettings = () => {
  const { mutateAsync: updatePropertyMainSettings, isLoading } = useAppMutation<
    PropertyMainSettingsUpdateResponse,
    BaseErrorResponse,
    { propertyUid: string; payload: PropertyMainSettingsUpdatePayload }
  >(async ({ propertyUid, payload }) => {
    return API.patch<PropertyMainSettingsUpdateResponse, BaseErrorResponse>(
      `api/internal/properties/${propertyUid}/main-settings`,
      payload,
    );
  });

  return { isLoading, updatePropertyMainSettings };
};

export default useUpdatePropertyMainSettings;
