import { useMemo } from 'react';
import PipelineContextProvider from './PipelineContextProvider';
import PipelineWrapper from './PipelineWrapper';
import { Container, GlobalPipelineStyles } from './Pipeline.styles';
import usePipelineSearchParams from './usePipelineSearchParams';

const Pipeline = () => {
  usePipelineSearchParams();

  const pipelineWrapper = useMemo(() => <PipelineWrapper />, []);

  return (
    <PipelineContextProvider>
      <GlobalPipelineStyles />
      <Container>{pipelineWrapper}</Container>
    </PipelineContextProvider>
  );
};

export default Pipeline;
