import { useContext, useEffect, useState } from 'react';
import { queryClient } from 'App';
import CalendarBodySelectionService from 'pages/calendar/common/body/selection/CalendarBodySelectionService';
import CalendarContext from 'pages/stackedCalendar/CalendarContext';
import API from 'services/API';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppQuery from 'hooks/useAppQuery';
import useAppSelector from 'hooks/useAppSelector';
import useAppUser from 'hooks/useAppUser';
import { GetPropertiesEntriesResponseTO } from 'models/PropertiesEntries';
import { PropertyBusinessType } from 'models/Properties';
import { getPropertyUidFromStringType } from 'utils/property/propertyUtils';
import { addDays, format, isAfter, parseISO } from 'date-fns';
import { updatePropertiesEntries } from 'store/slices/stackedCalendar';
import { GetLeadsGraphQLResponseTO } from '../../models/Leads';
import { GetJobsResponseTO } from '../../models/Jobs';
import { getDayCellDataKey } from './StackedCalendar.utils';
import useGetCalendarDataProcess from './useGetCalendarDataProcess';
import { CalendarDayCellsData } from './Calendar.types';
import useStackedCalendarPropertiesEntries from './useStackedCalendarPropertiesEntries';
import useStackedCalendarVisibleProperties from './useStackedCalendarVisibleProperties';
import { DragAndDropLeadsUnitCleanUpData } from './body/useDragAndDropLeadsUnit';
import useCalendarBodyRefreshRequest from './useCalendarBodyRefreshRequest';

interface FetchParams {
  properties: string[];
  fromDate: string;
  toDate: string;
  requestId?: number;
}

function isAnyDayCellDataMissing({ dayCellDataMap, from, to }) {
  if (!dayCellDataMap) {
    return true;
  }

  for (let date = from; !isAfter(date, to); date = addDays(date, 1)) {
    const dayCellDataKey = getDayCellDataKey(date);
    const dayCellData = dayCellDataMap[dayCellDataKey];

    if (!dayCellData || dayCellData.isLoading) {
      return true;
    }
  }

  return false;
}

async function getPropertyCalendars({ properties, fromDate, toDate }) {
  const response = await API.get<GetPropertiesEntriesResponseTO>(
    `v3/property-calendar?propertiesUids=${properties}&from=${fromDate}&to=${toDate}`,
  );
  return response.data.propertyCalendars;
}

async function getLeads({
  agencyUid,
  isOwner,
  properties,
  fromDate,
  toDate,
}: FetchParams & { agencyUid: string; isOwner: boolean }) {
  const bookingValueField = isOwner
    ? 'ownerCommission{amount,currency}'
    : 'order{currency,totalAmount}';
  const response = await API.post<GetLeadsGraphQLResponseTO>(`v3/graphql`, {
    operationName: '',
    // prettier-ignore
    query: `{leads (
        agencyUid:"${agencyUid}", 
        propertyUids:[${properties.map(u => `"${u}"`).join(',')}], 
        checkin_to:"${toDate}", 
        checkout_from:"${fromDate}", 
        limit:"2000", 
        statuses:[BLOCKED, PENDING, ON_HOLD, BOOKED_EXTERNALLY, BOOKED_BY_AGENT, BOOKED_BY_CUSTOMER, BOOKED_BY_OWNER, STAY, ARCHIVED, PAID_IN_FULL, SAMPLE]
      ){
        uid,
        firstName,
        lastName, 
        email, 
        source, 
        checkInLocalDateTime, 
        checkOutLocalDateTime, 
        childrenCount, 
        adultCount, 
        petCount, 
        infantCount, 
        status, 
        ${bookingValueField}, 
        property{uid, mainPicture{tinyThumbnail}},
        unitUid,
        creator{type}
      }}`,
    variables: {},
  });
  return response.data.data.leads;
}

async function getJobs({ properties, fromDate, toDate }: FetchParams) {
  const response = await API.get<GetJobsResponseTO>(
    `/api/v3.1/jobs?propertiesUids=${properties}&from=${fromDate}&to=${toDate}`,
    {
      headers: {
        'X-VERSION': '3.1',
      },
    },
  );
  return response.data.jobs.filter((job) => job.status !== 'CANCELLED');
}

const fetchParamsToDoubleCheck: FetchParams[] = [];

const removeChangedUnitPlaceholder = () => {
  // running querySelector is expensive for high number of properties, so it should be done only if actually required
  if (DragAndDropLeadsUnitCleanUpData.requiresCleanUp) {
    const placeholder = document.querySelector('.cloned-lead-view');
    if (placeholder) {
      setTimeout(() => {
        placeholder.remove();

        document
          .querySelectorAll(
            `[data-lead-uid="${
              CalendarBodySelectionService.getSelectionContainer().dragging
                ?.leadUid
            }"]`,
          )
          ?.forEach((lead) => lead?.setAttribute('style', `display: block`));

        CalendarBodySelectionService.resetDraggingLead();

        DragAndDropLeadsUnitCleanUpData.requiresCleanUp = false;
      }, 0);
    }
  }
};

/*
 * This hook should only be used in a component wrapper by calendar provider
 */
const useGetCalendarData = () => {
  const dispatch = useAppDispatch();
  const { propertiesMap } = useContext(CalendarContext);
  const bodyRefreshRequest = useCalendarBodyRefreshRequest();
  const loadedDateRange = useAppSelector(
    (state) => state.stackedCalendar.loadedDateRange,
  );
  const visibleDates = useAppSelector(
    (state) => state.stackedCalendar.visibleDates,
  );
  const propertiesEntries = useStackedCalendarPropertiesEntries();
  const visibleProperties = useStackedCalendarVisibleProperties();
  const { agencyUid, isOwner } = useAppUser();
  const [fetchParams, setFetchParams] = useState<FetchParams>();
  const processFetchedData = useGetCalendarDataProcess();

  useAppQuery(
    ['calendarData', fetchParams],
    async () => {
      fetchParamsToDoubleCheck.push(fetchParams);

      const propertyUidsToFetch = Object.keys(propertiesMap).filter(
        (propertyUid) =>
          propertiesMap[propertyUid].businessType !==
            PropertyBusinessType.UNIT &&
          propertiesMap[propertyUid].businessType !==
            PropertyBusinessType.HOTEL,
      );

      return Promise.all([
        getPropertyCalendars({
          ...fetchParams,
          properties: propertyUidsToFetch,
        }),
        getLeads({
          agencyUid,
          isOwner,
          ...fetchParams,
          properties: propertyUidsToFetch,
        }),
        getJobs({
          ...fetchParams,
          properties: propertyUidsToFetch,
        }),
      ]);
    },
    {
      enabled: !!fetchParams,
      onSuccess: (data) => {
        processFetchedData(data);
        removeChangedUnitPlaceholder();
      },
      keepPreviousData: true,
      cacheTime: 5000,
      staleTime: 5000,
    },
  );

  useEffect(() => {
    if (!propertiesEntries || !Object.keys(propertiesEntries).length) {
      return;
    }

    while (fetchParamsToDoubleCheck.length) {
      const fetchParamsToCheck = fetchParamsToDoubleCheck.shift();
      const { properties, fromDate, toDate } = fetchParamsToCheck;

      const propertiesWithoutEntries = properties.filter((propertyUid) =>
        isAnyDayCellDataMissing({
          dayCellDataMap: propertiesEntries[propertyUid],
          from: parseISO(fromDate),
          to: parseISO(toDate),
        }),
      );

      if (propertiesWithoutEntries.length) {
        const existingQueryData =
          queryClient.getQueryData<CalendarDayCellsData>([
            'calendarData',
            fetchParamsToCheck,
          ]);

        if (existingQueryData) {
          processFetchedData(existingQueryData);
        } else {
          setTimeout(() => {
            setFetchParams({
              properties: propertiesWithoutEntries,
              fromDate,
              toDate,
              requestId: new Date().getTime(),
            });
          });
        }
        break;
      }
    }
  }, [propertiesEntries]);

  useEffect(() => {
    if (loadedDateRange) {
      const { from, to } = loadedDateRange;

      const propertiesWithoutEntries = visibleProperties.filter((uid) => {
        const { propertyUid } = getPropertyUidFromStringType(uid);

        return isAnyDayCellDataMissing({
          dayCellDataMap: propertiesEntries[propertyUid],
          from,
          to,
        });
      });

      if (propertiesWithoutEntries.length) {
        setFetchParams({
          properties: propertiesWithoutEntries,
          fromDate: format(from, 'yyyy-MM-dd'),
          toDate: format(to, 'yyyy-MM-dd'),
        });
      }
    }
  }, [loadedDateRange]);

  useEffect(() => {
    if (!visibleDates.length) return;

    const propertiesWithoutEntries = visibleProperties.filter((uid) => {
      const { propertyUid } = getPropertyUidFromStringType(uid);
      return !propertiesEntries[propertyUid];
    });

    if (propertiesWithoutEntries.length) {
      setFetchParams({
        properties: propertiesWithoutEntries,
        fromDate: format(visibleDates[1], 'yyyy-MM-dd'), // edge days should be left without data to act as loading indicators
        toDate: format(visibleDates[visibleDates.length - 2], 'yyyy-MM-dd'),
      });
    }
  }, [visibleProperties, visibleDates]);

  useEffect(() => {
    if (bodyRefreshRequest) {
      const { properties, fromDate, toDate } = bodyRefreshRequest;
      const propertiesEntriesToUpdate = {};

      properties.forEach((propertyUid) => {
        const currentDayMap = propertiesEntries[propertyUid];

        for (
          let date = fromDate;
          !isAfter(date, toDate);
          date = addDays(date, 1)
        ) {
          const dayCellDataKey = getDayCellDataKey(date);
          const dayCellData = currentDayMap[dayCellDataKey];

          if (dayCellData) {
            if (!propertiesEntriesToUpdate[propertyUid]) {
              propertiesEntriesToUpdate[propertyUid] = {};
            }

            propertiesEntriesToUpdate[propertyUid].isLoading = true;
          }
        }
      });

      dispatch(updatePropertiesEntries(propertiesEntriesToUpdate));

      setFetchParams({
        properties,
        fromDate: format(fromDate, 'yyyy-MM-dd'),
        toDate: format(toDate, 'yyyy-MM-dd'),
        requestId: new Date().getTime(), // there can be multiple valid refresh requests with the same params (eg when a newly added lead was edited) so this is required to trigger fetching again
      });
    }
  }, [bodyRefreshRequest]);

  useEffect(() => {
    const handleFilterEvent = (event) => {
      const { fromDate, toDate } = event.detail;

      if (!fromDate && !toDate) return;

      const propertiesWithoutEntries = visibleProperties.filter((uid) => {
        const { propertyUid } = getPropertyUidFromStringType(uid);
        return isAnyDayCellDataMissing({
          dayCellDataMap: propertiesEntries[propertyUid],
          from: fromDate,
          to: toDate,
        });
      });

      if (propertiesWithoutEntries.length) {
        setFetchParams({
          properties: propertiesWithoutEntries,
          fromDate: format(fromDate, 'yyyy-MM-dd'),
          toDate: format(toDate, 'yyyy-MM-dd'),
        });
      }
    };

    window.addEventListener('loadLeadsFromFilterDateEvent', handleFilterEvent);

    return () => {
      window.removeEventListener(
        'loadLeadsFromFilterDateEvent',
        handleFilterEvent,
      );
    };
  }, [visibleProperties]);
};

export default useGetCalendarData;
