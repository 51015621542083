import useInboxDisplayLead from '../messages/useInboxDisplayLead';
import { InboxLeadDisplayMode } from './InboxLead.types';
import InboxLead from './InboxLead';
import { LeadStandalonePanelWrapper } from './InboxLead.styles';

const InboxLeadStandalone = () => {
  const displayLead = useInboxDisplayLead(InboxLeadDisplayMode.STANDALONE);

  if (displayLead) {
    return (
      <LeadStandalonePanelWrapper>
        <InboxLead />
      </LeadStandalonePanelWrapper>
    );
  }

  return null;
};

export default InboxLeadStandalone;
