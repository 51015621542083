import { useTranslation } from 'react-i18next';
import { MessageTemporaryAttachment } from '../../../../models/inbox/Messages';
import {
  InboxMessageAttachmentIcon,
  InboxMessageAttachmentLink,
} from '../InboxMessages.styles';
import {
  InboxMessageEditorAttachmentRow,
  InboxMessageEditorAttachmentsContainer,
  InboxMessageEditorAttachmentText,
  InboxMessageEditorDeleteAttachmentIcon,
} from './InboxMessageEditor.styles';

function renderAttachmentItem(attachment, deleteAttachments, t) {
  const { errorMessage, fileName, isUploading, uid, uri } = attachment;

  if (errorMessage) {
    return (
      <InboxMessageEditorAttachmentText>
        {fileName} - {errorMessage}
      </InboxMessageEditorAttachmentText>
    );
  }

  if (isUploading) {
    return (
      <InboxMessageEditorAttachmentText>
        {t('pageInbox.messages.editor.uploadingAttachment', { fileName })}
      </InboxMessageEditorAttachmentText>
    );
  }

  return (
    <>
      <InboxMessageAttachmentIcon />
      <InboxMessageAttachmentLink href={uri}>
        {fileName}
      </InboxMessageAttachmentLink>
      <InboxMessageEditorDeleteAttachmentIcon
        onClick={() => deleteAttachments([uid])}
      />
    </>
  );
}

const InboxMessageEditorAttachments = ({
  attachments,
  deleteAttachments,
}: {
  attachments: MessageTemporaryAttachment[];
  deleteAttachments: (_uids: string[]) => void;
}) => {
  const { t } = useTranslation();

  if (!attachments.length) {
    return null;
  }

  return (
    <InboxMessageEditorAttachmentsContainer>
      {attachments.map((attachment) => (
        <InboxMessageEditorAttachmentRow
          $hasError={!!attachment.errorMessage}
          key={attachment.uid}
        >
          {renderAttachmentItem(attachment, deleteAttachments, t)}
        </InboxMessageEditorAttachmentRow>
      ))}
    </InboxMessageEditorAttachmentsContainer>
  );
};

export default InboxMessageEditorAttachments;
