export function trimAndLimitCharactersNumber({
  text,
  limit,
}: {
  text?: string;
  limit: number;
}): string {
  if (!text) {
    return '';
  }

  const textTrimmed = text.toString().trim();

  if (limit && textTrimmed.length > limit) {
    return `${textTrimmed.substring(0, limit - 3)}...`;
  }

  return textTrimmed;
}

export function camelCaseToKebabCase(camelCaseText: string) {
  return camelCaseText?.replace(/[A-Z]/g, (str) => `-${str.toLowerCase()}`);
}

const UID_REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

export function isUidString(text: string) {
  return UID_REGEX.test(text);
}

export function kebabCaseToCamelCase(kebabCaseText: string) {
  return kebabCaseText?.replace(/-./g, (str) => str[1].toUpperCase());
}

export function toUpperFirst(text: string) {
  return text.replace(/^(.)(.*)/, (_, firstLetter, remainingText) => {
    return `${firstLetter.toUpperCase()}${remainingText.toLowerCase()}`;
  });
}

export function toStartCase(text: string) {
  return text
    .split(/[\s_-]/)
    .map(toUpperFirst)
    .join(' ');
}
