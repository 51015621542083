import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import usePipelineForm from '../usePipelineForm';
import usePipelineFiltersFormStorage from './usePipelineFiltersFormStorage';

const usePipelineFiltersFormApplyStoredValues = () => {
  const { getDefaultValues } = usePipelineForm();
  const { getStoredFilterFormValues } = usePipelineFiltersFormStorage();
  const { setValue } = useFormContext();

  useEffect(() => {
    const storedFormValues = getStoredFilterFormValues();

    if (!storedFormValues) {
      return;
    }

    const { range: storedRange, ...storedValues } = storedFormValues;
    const { range: defaultRange, ...defaultValues } = getDefaultValues();

    if (
      storedRange?.checkInFrom !== defaultRange?.checkInFrom ||
      storedRange?.checkInTo !== defaultRange?.checkInTo
    ) {
      setValue('range', storedRange, { shouldDirty: true });
    }

    Object.keys(storedValues)
      .filter((key) => key !== 'lead')
      .filter((key) => storedValues[key] !== defaultValues[key])
      .forEach((key) => {
        setValue(key, storedValues[key], {
          shouldDirty: true,
        });
      });
  }, []);
};

export default usePipelineFiltersFormApplyStoredValues;
