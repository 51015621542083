export const getAnalyticsFilterLocalStorageKey = (userUid: string) =>
  `analytics-user:${userUid}`;

export const getChartsFilterLocalStorageKey = (userUid: string) =>
  `charts-user:${userUid}`;

export const getDashboardAnalyticsFilterLocalStorageKey = (userUid: string) =>
  `dashboard-analytics-user:${userUid}`;

export const getDashboardChartsFilterLocalStorageKey = (userUid: string) =>
  `dashboard-charts-user:${userUid}`;
