import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import API from 'services/API';
import { ONE_TIME_ADJUSTMENTS_BASE_QUERY_KEY } from 'pages/ownerAdjustments/tables/OneTimeAdjustmentsTable.utils';
import { RECURRING_ADJUSTMENTS_BASE_QUERY_KEY } from 'pages/ownerAdjustments/tables/RecurringAdjustmentsTable.utils';
import { OwnerAdjustment } from 'models/ownerAdjustments/OwnerAdjustment';
import { OwnerAdjustmentSchedule } from 'models/ownerAdjustments/OwnerAdjustmentSchedule';
import { getAdjustmentType } from 'pages/ownerAdjustments/OwnerAdjustments.utils';
import { ADJUSTMENT_TYPES } from '../OwnerAdjustmentModal.types';

const deleteOwnerAdjustment = async (
  ownerAdjustment: OwnerAdjustment | OwnerAdjustmentSchedule,
) => {
  const url =
    getAdjustmentType(ownerAdjustment) === ADJUSTMENT_TYPES.ONE_TIME
      ? '/api/internal/owner-adjustments'
      : `/api/internal/owner-adjustments/schedules`;

  return API.delete(`${url}/${ownerAdjustment.uid}`);
};

const useDeleteOwnerAdjustmentMutation = () => {
  const { notifyError } = useNotify();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useAppMutation({
    mutationFn: deleteOwnerAdjustment,
    onSettled: (savedOwnerAdjustment, error, ownerAdjustment) => {
      if (getAdjustmentType(ownerAdjustment) === ADJUSTMENT_TYPES.ONE_TIME) {
        queryClient.invalidateQueries([ONE_TIME_ADJUSTMENTS_BASE_QUERY_KEY]);
      } else {
        queryClient.invalidateQueries([RECURRING_ADJUSTMENTS_BASE_QUERY_KEY]);
      }
    },
    onError: () => {
      notifyError(t('pageOwnerAdjustments.modal.errorOnDeletion'));
    },
  });
};

export default useDeleteOwnerAdjustmentMutation;
