import styled from 'styled-components';

export const BodyModalConnectionWithoutPropertyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    flex: 0 1 70%;
  }
`;
