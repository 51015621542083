import { useTranslation } from 'react-i18next';
import useAppModal from 'hooks/useAppModal';

const useThankYouModal = () => {
  const { t } = useTranslation();
  const { openConfirmModal } = useAppModal();

  const openThankYouModal = () => {
    openConfirmModal({
      confirmLabel: t('common.close'),
      hideCancel: true,
      body: (
        <p>
          {t('componentProperty.upgradePlanModal.yourAccountHasBeenUpgraded')}
          <br />
          {t('componentProperty.upgradePlanModal.youCanNowCloseThisWindow')}
        </p>
      ),
      onConfirm: () => {},
      title: t('componentProperty.upgradePlanModal.thankYou'),
    });
  };

  return {
    openThankYouModal,
  };
};

export default useThankYouModal;
