import Tooltip from 'components/tooltip/Tooltip';
import { AssigneeWithEmployee } from 'components/domain/property/useFetchAssignmentSettingsWithEmployees';
import { PropertyItemPermissionsEmployeeListItemExtraStyled } from './PropertyItemPermissions.styles';
import PropertyItemPermissionsEmployeeInfo from './PropertyItemPermissionsEmployeeInfo';

interface PropertyItemPermissionsExtraAssigneesProps {
  assignees: AssigneeWithEmployee[];
  propertyUid: string;
}

const PropertyItemPermissionsExtraAssignees = ({
  assignees,
  propertyUid,
}: PropertyItemPermissionsExtraAssigneesProps) => {
  const id = `tooltip-${propertyUid}-extra-assignees`;

  return (
    <Tooltip
      id={id}
      text={
        <>
          {assignees.map(({ employee, employeeUid }) => (
            <PropertyItemPermissionsEmployeeInfo
              key={employeeUid}
              employee={employee}
            />
          ))}
        </>
      }
    >
      <PropertyItemPermissionsEmployeeListItemExtraStyled data-testid="extra-employees">
        +{assignees.length}
      </PropertyItemPermissionsEmployeeListItemExtraStyled>
    </Tooltip>
  );
};

export default PropertyItemPermissionsExtraAssignees;
