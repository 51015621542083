import styled from 'styled-components';

export const Container = styled.div.attrs({ className: 'container' })`
  padding-bottom: 40px;

  .form-actions {
    display: flex;
    margin-top: 16px;
    justify-content: flex-start;

    & > * {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .profile-picture {
    cursor: pointer;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
    flex-shrink: 0;
    margin-bottom: 16px;

    img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
    }
  }

  .col-align {
    padding-left: 4px;
  }

  .title {
    font-size: 1rem;
    margin: 0 0 20px;

    strong {
      font-weight: 600;
    }
    span:first-of-type {
      margin-right: 10px;
    }
  }
`;

export const Subtitle = styled.div`
  & > span {
    margin-right: 16px;
  }
`;
