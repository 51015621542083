import { Trans, useTranslation } from 'react-i18next';
import { queryClient } from 'App';
import Button from 'components/button/Button';
import useDeleteTransaction from 'components/domain/transaction/useDeleteTransaction';
import useAppModal from 'hooks/useAppModal';
import useFormatCurrency from 'hooks/useFormatCurrency';
import { Transaction } from 'models/Transactions';
import { LEAD_GQL_QUERY_BASE } from 'components/domain/lead/useFetchLead';
import useOrderViewFetchedData from '../../useOrderViewFetchedData';

const DeleteManualTransactionButton = ({
  transaction,
}: {
  transaction: Transaction;
}) => {
  const {
    lead: {
      order: { currency },
      uid: leadUid,
    },
  } = useOrderViewFetchedData();
  const formatCurrency = useFormatCurrency();
  const { openConfirmModal } = useAppModal();
  const { deleteTransaction, isLoading } = useDeleteTransaction();
  const { t } = useTranslation();
  const { amount, uid: transactionUid } = transaction;

  if (isLoading) {
    return t('common.deleting');
  }

  const formattedAmount = formatCurrency({
    currency,
    value: amount,
    options: {
      round: false,
      removeDecimals: amount % Math.floor(amount) === 0,
    },
  });

  const onClick = () => {
    openConfirmModal({
      body: (
        <Trans
          i18nKey="pageOrder.transactions.actions.confirmDeleteMessage"
          components={{ lineBreak: <br /> }}
          values={{ amount: formattedAmount }}
        />
      ),
      confirmColor: 'danger',
      confirmLabel: t('common.delete'),
      title: t('pageOrder.transactions.actions.confirmDeleteTitle'),
      onConfirm: () => {
        deleteTransaction({
          transactionUid,
          successCallback: () => {
            queryClient.invalidateQueries([LEAD_GQL_QUERY_BASE, leadUid]);
          },
        });
      },
    });
  };

  return (
    <Button
      bsStyle="link"
      data-testid="delete-transaction-button"
      onClick={onClick}
    >
      {t('common.delete')}
    </Button>
  );
};

export default DeleteManualTransactionButton;
