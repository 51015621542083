import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  pointer-events: none;

  a {
    font-size: 16px;
    pointer-events: all;
  }

  ${mediaQuery.xs} {
    cursor: pointer;
    pointer-events: all;

    a {
      display: none;
    }
  }
`;

export const PropertyInfoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  img {
    width: 90px;
    height: 90px;
    margin-right: 10px;
    border-radius: 50%;

    border: 1px solid #e6e6e6;
    padding: 4px;
  }

  h3,
  p {
    margin: 0;
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #212529;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: auto;
    height: 82px;
  }
`;
