import { memo } from 'react';
import { classNames } from 'utils/classNameUtils';

const GrayedOutDayCell = memo(
  ({
    className,
    dayTimestamp,
    isWeekend,
    width,
  }: {
    className: string;
    dayTimestamp: number;
    isWeekend: boolean;
    width: number;
  }) => {
    return (
      <div
        className={classNames({
          'body-day-cell body-inactive-day-cell greyed-out': true,
          [className]: true,
          [`width-${width}`]: true,
          weekend: isWeekend,
        })}
        data-day-date={dayTimestamp}
      />
    );
  },
);

export default GrayedOutDayCell;
