import PropertyMultiSelectField from 'components/forms/domain/property/PropertyMultiSelectField/PropertyMultiSelectField';
import { MultiSelectFieldProperty } from 'components/forms/domain/property/PropertyMultiSelectField/PropertyMultiSelectField.types';
import { PROPERTY_STATUS, PropertyBusinessType } from 'models/Properties';
import { FormFieldsStyled } from '../common/CommonModal.styles';

interface LinkSubUnitsModalFormFieldsProps {
  propertyUid: string;
}

const LinkSubUnitsModalFormFields = ({
  propertyUid,
}: LinkSubUnitsModalFormFieldsProps) => {
  const isStandalonePropertyAndNotSameProperty = (
    property: MultiSelectFieldProperty,
  ) => {
    const isSameProperty = propertyUid === property.uid;
    const isStandaloneProperty =
      property.businessType === PropertyBusinessType.STANDALONE_PROPERTY &&
      !property.numberOfSubUnits;

    return isStandaloneProperty && !isSameProperty;
  };

  return (
    <FormFieldsStyled>
      <PropertyMultiSelectField
        name="properties"
        graphQlFilters={{
          excludeMultiUnit: true,
          status: PROPERTY_STATUS.ACTIVE,
        }}
        filterPropertyBy={isStandalonePropertyAndNotSameProperty}
        colSmInput={12}
      />
    </FormFieldsStyled>
  );
};

export default LinkSubUnitsModalFormFields;
