import { useContext } from 'react';
import TableContext from '../TableContext';
import TableHeaderCell from './TableHeaderCell';
import { TableHeaderStyled } from './TableHeader.styles';

interface TableHeaderProps {
  className?: string;
}

const TableHeader = ({ className }: TableHeaderProps) => {
  const { tableInstance } = useContext(TableContext);
  const headerGroups = tableInstance.getHeaderGroups();

  return (
    <TableHeaderStyled className={className}>
      {headerGroups.map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <TableHeaderCell key={header.id} header={header} />
          ))}
        </tr>
      ))}
    </TableHeaderStyled>
  );
};

TableHeader.defaultProps = {
  className: '',
};

export default TableHeader;
