import styled from 'styled-components';

export const PropertiesBodyListStyled = styled.ul.attrs({
  'data-testid': 'properties-body',
})<{ $isChildPropertiesContainer?: boolean }>`
  background-color: white;
  flex: 1;
  list-style-type: none;
  margin: ${({ $isChildPropertiesContainer }) =>
    $isChildPropertiesContainer ? '0 -15px -15px' : '0'};
  padding: 0;

  .infinite-scroll-component__outerdiv {
    height: 100%;

    .infinite-scroll-component {
      height: 100% !important;
    }
  }
`;
