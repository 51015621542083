import { GetOrderViewInitDataResponseTO } from 'models/Orders';
import useAppQuery from '../../../hooks/useAppQuery';
import API from '../../../services/API';

const useFetchOrderViewInitData = ({ orderUid }: { orderUid: string }) => {
  const {
    data: orderViewInitData,
    error,
    isInitialLoading: isFetching,
  } = useAppQuery(
    ['orderViewInitData', orderUid],
    async () => {
      const orderResponse = await API.get<GetOrderViewInitDataResponseTO>(
        `/api/internal/orders/${orderUid}/view-init-data`,
      );
      return orderResponse?.data?.initData;
    },
    {
      enabled: !!orderUid,
    },
  );

  return { error, isFetching, orderViewInitData };
};

export default useFetchOrderViewInitData;
