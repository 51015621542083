import styled from 'styled-components';

export const LoadingSectionPlaceholderContainer = styled.div<{
  $lines: number;
  $columns: number;
}>`
  display: grid;
  grid-template-columns: repeat(${({ $columns }) => $columns}, 1fr);
  grid-template-rows: repeat(${({ $lines }) => $lines}, auto);
  grid-column-gap: 32px;
  grid-row-gap: 8px;
`;
