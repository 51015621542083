import { useTranslation } from 'react-i18next';
import useAppModal from 'hooks/useAppModal';
import { PropertyExpectation } from 'models/PropertyExpectations';
import usePropertySettingsInitData from '../../../../usePropertySettingsInitData';
import PropertyExpectationAddEditModal from './PropertyExpectationAddEditModal';

const modalId = 'property-expectation-add-edit-modal';

const usePropertyExpectationAddEditModal = () => {
  const { openModal } = useAppModal();
  const { property } = usePropertySettingsInitData();
  const { t } = useTranslation();

  const openAddEditModal = (propertyExpectation?: PropertyExpectation) => {
    const params = {
      property,
      propertyExpectation,
    };

    openModal({
      customContent: (
        <PropertyExpectationAddEditModal modalId={modalId} params={params} />
      ),
      id: modalId,
      title: t(
        `pageProperty.feesTaxesAndPolicies.propertyExpectations.modal.${
          propertyExpectation ? 'edit' : 'add'
        }Title`,
      ),
    });
  };

  const openAddPropertyExpectationModal = () => {
    openAddEditModal();
  };
  const openEditPropertyExpectationModal = ({ propertyExpectation }) => {
    openAddEditModal(propertyExpectation);
  };

  return { openAddPropertyExpectationModal, openEditPropertyExpectationModal };
};

export default usePropertyExpectationAddEditModal;
