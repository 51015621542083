import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import useOrderViewFetchedData from '../../useOrderViewFetchedData';
import useAdditionalChargeModal from './useAdditionalChargeModal';

const AdditionalChargeButton = () => {
  const { openAdditionalChargeModal } = useAdditionalChargeModal();
  const {
    lead,
    initData: { additionalChargeAllowed },
  } = useOrderViewFetchedData();
  const { t } = useTranslation();

  if (!additionalChargeAllowed) {
    return null;
  }

  const onClick = () => {
    openAdditionalChargeModal({ lead });
  };

  return (
    <Button
      bsStyle="link"
      data-testid="additional-charge-button"
      onClick={onClick}
    >
      {t('pageOrder.transactions.actions.additionalCharge.buttonLabel')}
    </Button>
  );
};

export default AdditionalChargeButton;
