import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getPropertyPieLegends,
  isCardDataApplicable,
} from '../Analytics.utils';
import usePieChartsData from '../usePieChartsData';
import AnalyticsContext from '../AnalyticsContext';
import { AnalyticsKeyMetric } from '../types/AnalyticsKeyMetrics.types';
import CustomPieChart from './types/pieCharts/CustomPieChart';
import { PieLegend } from './Pies.types';
import { PieChartMetric } from './Charts.types';

const RevParPieChart = () => {
  const {
    revParPieData,
    isRevParPieLoading,
    revParPieError,
    isInitialRevParPieLoading,
  } = usePieChartsData();
  const { t } = useTranslation();
  const { filter } = useContext(AnalyticsContext);

  const nameKey = 'propertyName';

  if (!isCardDataApplicable(filter, AnalyticsKeyMetric.REV_PAR)) {
    return null;
  }

  const revByPropertyLegends: PieLegend[] =
    getPropertyPieLegends(revParPieData);

  return (
    <div data-testid="revParPieChart-container">
      <CustomPieChart
        metric={PieChartMetric.REV_PAR}
        title={t('pageAnalytics.pie.revParByProperty')}
        nameKey={nameKey}
        dataKey="value"
        pieData={revParPieData}
        isLoading={isRevParPieLoading}
        height={250}
        legends={revByPropertyLegends}
        error={revParPieError}
        isInitialLoading={isInitialRevParPieLoading}
      />
    </div>
  );
};

export default RevParPieChart;
