import { createColumnHelper } from '@tanstack/react-table';
import CommonTable from 'components/table/CommonTable';
import useTable from 'components/table/hooks/useTable';
import { RentalConditions } from 'models/RentalCondition';
import { useTranslation } from 'react-i18next';
import ActionsCell from './cells/ActionsCell';
import AppliesToCell from './cells/AppliesToCell';
import useRentalConditionsData from './useRentalConditionsData';

const RentalConditionsTable = () => {
  const { t } = useTranslation();
  const { isLoadingRentalConditions, rentalConditionData } =
    useRentalConditionsData();

  const columnHelper = createColumnHelper<RentalConditions>();

  const columns = [
    columnHelper.accessor('rentalConditions', {
      id: 'rentalConditions',
      header: t(
        'pageProperty.feesTaxesAndPolicies.rentalConditions.definition',
      ),
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'appliesTo',
      header: t('pageProperty.feesTaxesAndPolicies.rentalConditions.appliesTo'),
      cell: AppliesToCell,
    }),
    columnHelper.display({
      id: 'actions',
      header: t('pageProperty.feesTaxesAndPolicies.rentalConditions.actions'),
      cell: ActionsCell,
    }),
  ];

  const tableInstance = useTable({
    // We always have only one rental condition
    data: [rentalConditionData],
    columns,
    manualPagination: true,
    pageCount: 1,
  });

  return (
    <CommonTable
      tableInstance={tableInstance}
      isLoading={isLoadingRentalConditions}
      data-testid="rental-conditions-table"
    />
  );
};

export default RentalConditionsTable;
