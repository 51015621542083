import styled from 'styled-components';

export const SectionHeaderContainer = styled.div`
  h3 {
    line-height: 1;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    margin-right: 10px;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  min-height: 34px;

  & > div {
    display: flex;
  }

  button {
    span.glyphicon {
      margin-right: 5px;
      font-size: 12px;
    }
  }
`;
