/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import Button from 'components/button/Button';
import PinCodeField from 'components/forms/pinCodeField/PinCodeField';
import {
  HelperTextMuted,
  TypeStartingEnding,
  ResendCode,
} from './MFACodeForm.styles';
import { MFAType } from './MFA.types';

interface Props {
  errorMessage?: string;
  clearErrors?: () => void;
  codeSentTo: string;
  resendCode: () => void;
  type?: MFAType;
}

const MFACodeForm = ({
  errorMessage,
  clearErrors,
  codeSentTo,
  resendCode,
  type,
}: Props) => {
  const { pinCode } = useWatch();
  const { t } = useTranslation();

  useEffect(() => {
    clearErrors();
  }, [pinCode]);

  return (
    <>
      <HelperTextMuted>{t('pageLogin.pleaseEnterCode')}</HelperTextMuted>
      <TypeStartingEnding>
        {type === MFAType.EMAIL
          ? t('pageLogin.emailStartingWith')
          : t('pageLogin.phoneEndingIn')}{' '}
        {codeSentTo}
      </TypeStartingEnding>
      <PinCodeField name="pinCode" length={6} submitWhenPasted />
      <Button
        data-testid="submit-button"
        disabled={!!errorMessage}
        type="submit"
        block
        bsSize="large"
      >
        {t('pageLogin.continue')}
      </Button>
      <ResendCode>
        <Trans
          i18nKey="pageLogin.resendCodeLink"
          components={{
            wrap: <span onClick={() => resendCode()} />,
          }}
        />
      </ResendCode>
      {!!errorMessage && (
        <p className="error-message" data-testid="error-message">
          {errorMessage}
        </p>
      )}
    </>
  );
};

MFACodeForm.defaultProps = {
  errorMessage: '',
  clearErrors: () => {},
  type: undefined,
};

export default MFACodeForm;
