const FunnelIcon = ({ isActive }: { isActive: boolean }) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="36" height="36" rx="5" fill={isActive ? '#337ab7' : 'white'} />
    <path
      d="M15 21.75C15 21.5511 15.079 21.3603 15.2197 21.2197C15.3603 21.079 15.5511 21 15.75 21H20.25C20.4489 21 20.6397 21.079 20.7803 21.2197C20.921 21.3603 21 21.5511 21 21.75C21 21.9489 20.921 22.1397 20.7803 22.2803C20.6397 22.421 20.4489 22.5 20.25 22.5H15.75C15.5511 22.5 15.3603 22.421 15.2197 22.2803C15.079 22.1397 15 21.9489 15 21.75ZM12 17.25C12 17.0511 12.079 16.8603 12.2197 16.7197C12.3603 16.579 12.5511 16.5 12.75 16.5H23.25C23.4489 16.5 23.6397 16.579 23.7803 16.7197C23.921 16.8603 24 17.0511 24 17.25C24 17.4489 23.921 17.6397 23.7803 17.7803C23.6397 17.921 23.4489 18 23.25 18H12.75C12.5511 18 12.3603 17.921 12.2197 17.7803C12.079 17.6397 12 17.4489 12 17.25ZM9 12.75C9 12.5511 9.07902 12.3603 9.21967 12.2197C9.36032 12.079 9.55109 12 9.75 12H26.25C26.4489 12 26.6397 12.079 26.7803 12.2197C26.921 12.3603 27 12.5511 27 12.75C27 12.9489 26.921 13.1397 26.7803 13.2803C26.6397 13.421 26.4489 13.5 26.25 13.5H9.75C9.55109 13.5 9.36032 13.421 9.21967 13.2803C9.07902 13.1397 9 12.9489 9 12.75Z"
      fill={isActive ? 'white' : '#337ab7'}
    />
  </svg>
);

export default FunnelIcon;
