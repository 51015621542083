import CommonTable from 'components/table/CommonTable';
import { OwnerAdjustmentSchedule } from 'models/ownerAdjustments/OwnerAdjustmentSchedule';
import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const RecurringAdjustmentsTableStyled = styled(
  CommonTable<OwnerAdjustmentSchedule>,
)`
  .column-created {
    width: 120px;
  }

  .column-nextScheduled {
    width: 135px;
  }

  .column-recurringExpensePeriod {
    width: 90px;
  }

  .column-status {
    width: 90px;
  }

  .column-amount {
    width: 120px;
  }

  ${mediaQuery.md} {
    .column-created {
      width: 100px;
    }

    .column-nextScheduled {
      width: 100px;
    }

    .column-recurringExpensePeriod {
      width: 90px;
    }

    .column-status {
      width: 90px;
    }

    .column-amount {
      width: 100px;
    }
  }
`;
