import { CSSProperties } from 'react';
import type {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalEditor,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from 'lexical';
import { $applyNodeReplacement, DecoratorNode } from 'lexical';
import {
  convertCssPropertiesToCssText,
  convertStyleToCssProperties,
} from './lexicalNodes.utils';

export interface ImagePayload {
  altText: string;
  caption?: LexicalEditor;
  height?: number;
  key?: NodeKey;
  src: string;
  style?: CSSProperties;
  width?: number;
}

export type SerializedImageNode = Spread<
  {
    altText: string;
    height?: number;
    src: string;
    width?: number;
    style?: CSSProperties;
  },
  SerializedLexicalNode
>;

export class ImageNode extends DecoratorNode<JSX.Element> {
  src: string;

  altText: string;

  width: 'inherit' | number;

  height: 'inherit' | number;

  style: CSSProperties;

  constructor(
    src: string,
    altText: string,
    width?: 'inherit' | number,
    height?: 'inherit' | number,
    style?: CSSProperties,
    key?: NodeKey,
  ) {
    super(key);
    this.src = src;
    this.altText = altText;
    this.width = width || 'inherit';
    this.height = height || 'inherit';
    this.style = style;
  }

  static getType(): string {
    return 'image';
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(
      node.src,
      node.altText,
      node.width,
      node.height,
      node.style,
    );
  }

  static importDOM(): DOMConversionMap | null {
    return {
      img: (_node: Node) => ({
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        conversion: convertImageElement,
        priority: 0,
      }),
    };
  }

  static importJSON(serializedNode: SerializedImageNode): ImageNode {
    const { altText, height, width, src, style } = serializedNode;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return $createImageNode({
      altText,
      height,
      src,
      style,
      width,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  createDOM(config: EditorConfig): HTMLElement {
    const span = document.createElement('span');
    const { theme } = config;
    const className = theme.image;
    if (className !== undefined) {
      span.className = className;
    }
    return span;
  }

  decorate(): JSX.Element {
    return (
      <img
        src={this.src}
        alt={this.altText}
        width={this.width}
        height={this.height}
        style={this.style}
        key={this.getKey()}
      />
    );
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('img');
    element.setAttribute('src', this.src);
    element.setAttribute('alt', this.altText);
    element.setAttribute('width', this.width.toString());
    element.setAttribute('height', this.height.toString());
    element.setAttribute('style', convertCssPropertiesToCssText(this.style));
    return { element };
  }

  exportJSON(): SerializedImageNode {
    return {
      altText: this.altText,
      height: this.height === 'inherit' ? 0 : this.height,
      src: this.src,
      type: 'image',
      version: 1,
      width: this.width === 'inherit' ? 0 : this.width,
      style: this.style,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  updateDOM(): false {
    return false;
  }
}

export function $createImageNode({
  altText,
  height,
  src,
  style,
  width,
  key,
}: ImagePayload): ImageNode {
  return $applyNodeReplacement(
    new ImageNode(src, altText, width, height, style, key),
  );
}

function convertImageElement(domNode: Node): null | DOMConversionOutput {
  if (domNode instanceof HTMLImageElement) {
    const { alt: altText, src, width, height, style } = domNode;
    const node = $createImageNode({
      altText,
      height,
      src,
      style: convertStyleToCssProperties(style),
      width,
    });
    return { node };
  }
  return null;
}
