import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import useActivatePropertyMutation from 'components/domain/property/useActivatePropertyMutation';
import useInvalidateFetchAllProperties from 'components/domain/property/useInvalidateFetchAllProperties';
import useAppModal from 'hooks/useAppModal';
import useNotify from 'hooks/useNotify';
import { modalIds } from 'components/modal/Modal.constants';
import { BaseErrorResponse } from 'models/_base';
import { PROPERTIES_FOR_PROPERTIES_BODY_BASE_QUERY_KEY } from 'pages/agencyProperties/body/useFetchPropertiesForPropertiesBody';
import { ConfirmationModalProps } from './Confirmation.types';
import { PropertyNameStyled } from './Confirmation.styles';

const useActivateModal = () => {
  const queryClient = useQueryClient();
  const { notifySuccess } = useNotify();
  const { t } = useTranslation();
  const { openConfirmModal, closeModal, setErrorConfirmModal } = useAppModal();
  const { invalidateFetchAllProperties } = useInvalidateFetchAllProperties();

  const closeConfirmationModal = () => {
    closeModal(modalIds.confirm);
  };

  const { mutateAsync } = useActivatePropertyMutation({
    onSuccess: (_, { name }) => {
      notifySuccess(
        t('pageProperties.actions.activationSuccess', {
          name: name?.length > 30 ? `${name.slice(0, 30)}...` : name,
        }),
      );
      closeConfirmationModal();
      queryClient.invalidateQueries(
        [PROPERTIES_FOR_PROPERTIES_BODY_BASE_QUERY_KEY],
        { exact: false },
      );
      invalidateFetchAllProperties();
    },
    onError: (error: BaseErrorResponse) => {
      setErrorConfirmModal(error?.response?.data?.apiErrorMessage);
    },
  });

  const handleActivate = async (uid: string, name: string) => {
    await mutateAsync({ uid, name });
  };

  const openActivateModal = ({ uid, name }: ConfirmationModalProps) => {
    openConfirmModal({
      body: (
        <>
          {t('pageProperties.actions.activatePropertyConfirmation')}{' '}
          <PropertyNameStyled>{name}</PropertyNameStyled>?
        </>
      ),
      onConfirmAsync: () => handleActivate(uid, name),
      title: t('pageProperties.actions.common.areYouSure'),
      confirmLabel: t('pageProperties.actions.reactivate'),
    });
  };

  return {
    openActivateModal,
  };
};

export default useActivateModal;
