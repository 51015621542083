import API from 'services/API';
import useAppMutation from 'hooks/useAppMutation';
import { useQueryClient } from '@tanstack/react-query';
import { DISCOUNTS_BASE_QUERY_KEY } from './useFetchDiscounts';

export const useDeleteDiscountMutation = () => {
  const queryClient = useQueryClient();

  return useAppMutation(
    (id: string) => API.delete(`/v3/discount-codes/${id}`),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([DISCOUNTS_BASE_QUERY_KEY]),
    },
  );
};
