import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Button from 'components/button/Button';
import FormWithProvider from 'components/forms/form/Form';
import TextField from 'components/forms/textField/TextField';
import { ALL_WEEK_DAYS } from 'models/DayOfWeek';
import {
  PropertySettingsMainTabFormValues,
  propertySettingsMainTabSchema,
} from '../../PropertySettingsMainTab.schema';
import {
  SetMinSayModalFieldsetStyled,
  SetMinSayModalFooterStyled,
} from './BookingSettings.styles';

interface SetMinStayByWeekDayModalProps {
  defaultValues: PropertySettingsMainTabFormValues['bookingSettings']['dayOfWeekMinimumStay'];
  onCancel: () => void;
  onSubmit: (
    values: PropertySettingsMainTabFormValues['bookingSettings']['dayOfWeekMinimumStay'],
  ) => void;
}

const SetMinStayByWeekDayModal = ({
  defaultValues,
  onCancel,
  onSubmit,
}: SetMinStayByWeekDayModalProps) => {
  const { t } = useTranslation();
  const schema = Yup.reach(
    propertySettingsMainTabSchema(t),
    'bookingSettings.dayOfWeekMinimumStay',
  );

  return (
    <div data-testid="set-min-stay-modal">
      <Modal.Header data-testid="modal-header" closeButton>
        <Modal.Title>
          {t(
            'pageProperty.mainSettings.bookingSettings.setMinStayByDayOfWeekModalTitle',
          )}
        </Modal.Title>
      </Modal.Header>
      <FormWithProvider
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        resolver={schema}
        noValidate
      >
        <Modal.Body data-testid="modal-body">
          <p>
            {t(
              'pageProperty.mainSettings.bookingSettings.setMinStayByDayOfWeekModalDescription',
            )}
          </p>
          <SetMinSayModalFieldsetStyled>
            {ALL_WEEK_DAYS.map((weekDay) => (
              <TextField
                key={weekDay}
                label={t(
                  `pageProperty.mainSettings.bookingSettings.setMinStayByDayOfWeekInputs.${weekDay}`,
                )}
                name={weekDay}
                colSmInput={8}
                colSmLabel={4}
                type="number"
                min={0}
                rightAddons={[
                  t('pageProperty.mainSettings.bookingSettings.nights'),
                ]}
              />
            ))}
          </SetMinSayModalFieldsetStyled>
        </Modal.Body>

        <Modal.Footer>
          <SetMinSayModalFooterStyled>
            <Button data-testid="modal-cancel-button" onClick={onCancel}>
              {t('common.cancel')}
            </Button>
            <Button
              data-testid="modal-save-button"
              bsStyle="primary"
              type="submit"
            >
              {t('common.apply')}
            </Button>
          </SetMinSayModalFooterStyled>
        </Modal.Footer>
      </FormWithProvider>
    </div>
  );
};

export default SetMinStayByWeekDayModal;
