import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import useServerFilteredTable from 'components/table/hooks/useServerFilteredTable';
import FormattedDateCell from 'components/table/cells/common/FormattedDateCell';
import ToUpperFirstCell from 'components/table/cells/common/ToUpperFirstCell';
import { OwnerAdjustmentSchedule } from 'models/ownerAdjustments/OwnerAdjustmentSchedule';
import { OwnerAdjustmentsPathParams } from '../OwnerAdjustments.types';
import { recurringAdjustmentsQuery } from './RecurringAdjustmentsTable.utils';
import { RecurringAdjustmentsTableStyled } from './RecurringAdjustmentsTable.styles';
import AmountCell from './cells/AmountCell';
import ActionsCell from './cells/ActionsCell';

const RecurringAdjustmentsTable = () => {
  const { t } = useTranslation();
  const { propertyUid } = useParams<OwnerAdjustmentsPathParams>();
  const columnHelper = createColumnHelper<OwnerAdjustmentSchedule>();
  const columns = [
    columnHelper.accessor('created', {
      header: t('pageOwnerAdjustments.columns.createdOn'),
      cell: FormattedDateCell,
    }),
    columnHelper.accessor('nextScheduled', {
      header: t('pageOwnerAdjustments.columns.nextScheduled'),
      cell: FormattedDateCell,
    }),
    columnHelper.accessor('recurringExpensePeriod', {
      header: t('pageOwnerAdjustments.columns.period'),
      cell: ToUpperFirstCell,
      sortDescFirst: true,
    }),
    columnHelper.accessor('status', {
      header: t('pageOwnerAdjustments.columns.status'),
      cell: ToUpperFirstCell,
      sortDescFirst: true,
    }),
    columnHelper.accessor('name', {
      header: t('pageOwnerAdjustments.columns.name'),
      sortDescFirst: true,
    }),
    columnHelper.accessor('amount', {
      header: t('pageOwnerAdjustments.columns.amount'),
      cell: AmountCell,
    }),
    columnHelper.display({
      id: 'actions',
      header: t('pageOwnerAdjustments.columns.actions'),
      cell: ActionsCell,
    }),
  ];

  const { isLoading, tableInstance } = useServerFilteredTable({
    columns,
    getQuery: (options) =>
      recurringAdjustmentsQuery({ propertyUid, ...options }),
  });

  return (
    <RecurringAdjustmentsTableStyled
      caption={t('pageOwnerAdjustments.recurringAdjustments')}
      data-testid="recurring-adjustments"
      tableInstance={tableInstance}
      emptyState={t('pageOwnerAdjustments.recurringAdjustmentsEmptyMessage')}
      isLoading={isLoading}
    />
  );
};

export default RecurringAdjustmentsTable;
