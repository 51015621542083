import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import useAppModal from 'hooks/useAppModal';
import useNotify from 'hooks/useNotify';
import API from 'services/API';
import useAppMutation from 'hooks/useAppMutation';
import { PropertyByUid } from 'models/Properties';
import { PROPERTIES_FOR_PROPERTIES_BODY_BASE_QUERY_KEY } from 'pages/agencyProperties/body/useFetchPropertiesForPropertiesBody';
import useInvalidateFetchAllProperties from '../../useInvalidateFetchAllProperties';
import DeactivationModal from './deactivation/DeactivationModal';

interface UseDeactivateModalArgs {
  onSuccess?: () => void;
}

interface ConfirmationModalProps {
  uid: string;
  name: string;
}

const deactivationModalId = 'deactivation-modal';

type DeactivationMutationArgs = PropertyByUid;

const useDeactivateModal = ({ onSuccess }: UseDeactivateModalArgs = {}) => {
  const queryClient = useQueryClient();
  const { notifySuccess } = useNotify();
  const { t } = useTranslation();
  const { openModal, closeModal, setErrorConfirmModal } = useAppModal();
  const { invalidateFetchAllProperties } = useInvalidateFetchAllProperties();

  const { mutateAsync, isLoading } = useAppMutation(
    ['deactivateProperty'],
    async ({ uid, ...property }: DeactivationMutationArgs) => {
      // TODO once deactivate endpoint starts accepting deactivation reason,
      // start using that endpoint instead of this one
      if (property?.airbnbData?.active) {
        // Ignores fields that the API throws errors when sent.
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { address, units, showPropertyExactLocation, ...otherFields } =
          property;

        const response = await API.put(`/v3/properties/${uid}`, {
          ...otherFields,
          isActive: false,
        });

        return response.data;
      }

      const response = await API.post(
        `/api/internal/properties/${uid}/deactivate`,
      );
      return response.data;
    },
    {
      onSuccess: (_, { name }) => {
        notifySuccess(
          t('pageProperties.actions.deactivationSuccess', {
            name: name.length > 30 ? `${name.slice(0, 30)}...` : name,
          }),
        );
        closeModal(deactivationModalId);
        queryClient.invalidateQueries(
          [PROPERTIES_FOR_PROPERTIES_BODY_BASE_QUERY_KEY],
          { exact: false },
        );
        invalidateFetchAllProperties();
        onSuccess?.();
      },
      onError: (error: any) => {
        setErrorConfirmModal(error.response.data.apiErrorMessage);
      },
    },
  );

  const openDeactivateModal = ({ uid, name }: ConfirmationModalProps) => {
    openModal({
      customContent: (
        <DeactivationModal
          isUpdating={isLoading}
          modalId={deactivationModalId}
          name={name}
          propertyUid={uid}
          onSubmit={mutateAsync}
        />
      ),
      id: deactivationModalId,
      size: 'medium',
    });
  };

  return {
    openDeactivateModal,
    isDeactivatingProperty: isLoading,
  };
};

export default useDeactivateModal;
