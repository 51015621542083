import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { LoadingSectionPlaceholderContainer } from './LoadingSectionPlaceholder.styles';

const LoadingSectionPlaceholder = ({
  columns,
  lines,
  linesCount,
}: {
  columns: number;
  lines: number;
  linesCount?: number;
}) => {
  return (
    <LoadingSectionPlaceholderContainer $columns={columns} $lines={lines}>
      {Array.from({ length: columns * lines }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <LoadingPlaceholder key={index} linesCount={linesCount} />
      ))}
    </LoadingSectionPlaceholderContainer>
  );
};

LoadingSectionPlaceholder.defaultProps = {
  linesCount: 1,
};

export default LoadingSectionPlaceholder;
