import {
  format as formatDate,
  formatDistance,
  formatDistanceToNow,
  isDate,
} from 'date-fns';
import { enGB, enUS, fr, pt, es, pl, de } from 'date-fns/locale';
import { getUTCDate } from 'utils/dateTimeUtils';
import { LanguageCode } from './internationalization.types';
import { getLanguageCode } from './i18n.utils';

const dateTimeLocales = {
  [LanguageCode.DE]: de,
  [LanguageCode.EN]: enUS,
  [LanguageCode.EN_GB]: enGB,
  [LanguageCode.EN_US]: enUS,
  [LanguageCode.FR]: fr,
  [LanguageCode.PT]: pt,
  [LanguageCode.PL]: pl,
  [LanguageCode.ES]: es,
};

export function getDateTimeLocale(lang?: string): Locale {
  if (lang && dateTimeLocales[lang]) {
    return dateTimeLocales[lang];
  }

  if (dateTimeLocales[getLanguageCode(lang)]) {
    return dateTimeLocales[getLanguageCode(lang)];
  }

  return dateTimeLocales[LanguageCode.EN];
}

export function tryToFormatDateTime(value, format, lang) {
  if (isDate(value)) {
    const locale = getDateTimeLocale(lang);

    if (format === 'shortDate') {
      return formatDate(value, 'P', { locale });
    }

    if (format === 'mediumDate') {
      return formatDate(value, 'PP', { locale });
    }

    if (format === 'longDate') {
      return formatDate(value, 'PPPP', { locale });
    }

    if (format === 'longDateTime') {
      return formatDate(value, 'PPPPp', { locale });
    }

    if (format === 'time') {
      return formatDate(value, 'p', { locale });
    }

    if (format === 'ago') {
      return formatDistanceToNow(value, {
        locale,
        addSuffix: true,
      });
    }

    if (format === 'agoInUTC') {
      const nowInUTC = getUTCDate(new Date());
      return formatDistance(value, nowInUTC, {
        locale,
        addSuffix: true,
      });
    }

    // more options docs: https://date-fns.org/v2.29.3/docs/format

    return formatDate(value, format || '', { locale });
  }

  return null;
}
