import useAppToast from 'hooks/useAppToast';

const useNotify = () => {
  const { toastSuccess, toastError } = useAppToast();

  const notifySuccess = (message: string) => toastSuccess(message);

  const notifyError = (message: string) => toastError(message);

  return { notifySuccess, notifyError };
};

export default useNotify;
