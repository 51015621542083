import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import useAppEvent from 'hooks/useAppEvent';
import { AppEventType } from 'models/Events';
import { Lead, LeadAvailablePipelineAction } from 'models/Leads';
import Button from '../../../button/Button';
import PopoverStickyOnHover from '../../../popover/PopoverStickyOnHover';
import {
  leadActionsPopoverContainerClassName,
  LeadAvailablePipelineActionPriorities,
  leadMessagingActions,
  leadPendingActions,
} from './LeadActionsPopover.constants';
import useLeadPipelineAction from './useLeadPipelineAction';
import LeadActionsPopoverTitle from './LeadActionsPopoverTitle';
import { LeadPopoverActionsList } from './LeadActionsPopover.styles';

interface Props {
  children: ReactElement;
  lead: Lead;
  messagingActionCallback: ({
    action,
    lead,
  }: {
    action: LeadAvailablePipelineAction;
    lead: Lead | undefined;
  }) => void;
  updateLeadCallback: () => void;
}

const LeadActionsPopover = ({
  children,
  lead,
  messagingActionCallback,
  updateLeadCallback,
}: Props) => {
  const { publish } = useAppEvent();
  const { takeAction } = useLeadPipelineAction();
  const { t } = useTranslation();

  if (!lead) {
    return children;
  }

  const { availablePipelineActions } = lead;

  if (!availablePipelineActions?.length) {
    return children;
  }

  // Sort actions by priority and alphabetically
  const sortAlphabetically = (
    actionA: LeadAvailablePipelineAction,
    actionB: LeadAvailablePipelineAction,
  ) => {
    return t(`componentLead.actions.${actionA}`).localeCompare(
      t(`componentLead.actions.${actionB}`),
    );
  };

  const sortByPriority = (
    actionA: LeadAvailablePipelineAction,
    actionB: LeadAvailablePipelineAction,
  ) => {
    return (
      LeadAvailablePipelineActionPriorities[actionB] -
      LeadAvailablePipelineActionPriorities[actionA]
    );
  };

  const popoverId = `lead-actions-popover-${lead.uid}`;

  const replaceActionsInFooterWithReviewAction = (
    pipelineActions: LeadAvailablePipelineAction[],
  ) => {
    if (pipelineActions.find((action) => leadPendingActions.includes(action))) {
      return pipelineActions
        .filter((action) => !leadPendingActions.includes(action))
        .concat(LeadAvailablePipelineAction.REVIEW)
        .sort(sortAlphabetically)
        .sort(sortByPriority);
    }

    return pipelineActions
      .slice() // the array might be frozen so a copy needs to be created
      .sort(sortAlphabetically)
      .sort(sortByPriority);
  };

  const handleAction = (action: LeadAvailablePipelineAction) => {
    if (leadMessagingActions.includes(action)) {
      messagingActionCallback({ action, lead });
    } else {
      takeAction({ action, lead, updateLeadCallback });
    }

    publish(AppEventType.POPOVER_TOGGLE, { popoverId });
  };

  const content = (
    <LeadPopoverActionsList>
      {replaceActionsInFooterWithReviewAction(availablePipelineActions).map(
        (action) => (
          <li key={action}>
            <Button
              bsStyle="link"
              data-testid={`lead-action-button-${action}`}
              onClick={() => handleAction(action)}
            >
              {t(`componentLead.actions.${action}`)}
            </Button>
          </li>
        ),
      )}
    </LeadPopoverActionsList>
  );

  return (
    <PopoverStickyOnHover
      className="lead-actions-popover"
      containerSelector={`.${leadActionsPopoverContainerClassName}`}
      content={content}
      data-testid="lead-actions-popover"
      id={popoverId}
      placement="left"
      title={<LeadActionsPopoverTitle lead={lead} />}
    >
      <div>{children}</div>
    </PopoverStickyOnHover>
  );
};

export default LeadActionsPopover;
