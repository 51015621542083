import { CSSProperties } from 'react';
import {
  $isTableCellNode,
  SerializedTableCellNode,
  TableCellHeaderStates,
  TableCellNode,
} from '@lexical/table';
import {
  $applyNodeReplacement,
  $createParagraphNode,
  $isElementNode,
  $isLineBreakNode,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalEditor,
  NodeKey,
  Spread,
} from 'lexical';
import {
  convertCssPropertiesToCssText,
  convertStyleToCssProperties,
  SerializedStyledNode,
} from './lexicalNodes.utils';

export type SerializedTableCellStyledNode = Spread<
  SerializedStyledNode,
  SerializedTableCellNode
>;

const PIXEL_VALUE_REG_EXP = /^(\d+(?:\.\d+)?)px$/;

export class TableCellStyledNode extends TableCellNode {
  style: CSSProperties;

  constructor(
    headerState = TableCellHeaderStates.NO_STATUS,
    colSpan = 1,
    width?: number,
    style?: CSSProperties,
    key?: NodeKey,
  ) {
    super(headerState, colSpan, width, key);
    this.style = style;
  }

  static clone(node: TableCellStyledNode): TableCellStyledNode {
    /* eslint-disable no-underscore-dangle */
    const cellNode = new TableCellStyledNode(
      node.__headerState,
      node.__colSpan,
      node.__width,
      node.style,
      node.__key,
    );
    cellNode.__rowSpan = node.__rowSpan;
    cellNode.__backgroundColor = node.__backgroundColor;
    /* eslint-enable no-underscore-dangle */
    return cellNode;
  }

  static getType(): string {
    return 'table-cell-styled';
  }

  static importDOM(): DOMConversionMap | null {
    return {
      td: (_node: Node) => ({
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        conversion: convertTableCellStyledElement,
        priority: 0,
      }),
      th: (_node: Node) => ({
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        conversion: convertTableCellStyledElement,
        priority: 0,
      }),
    };
  }

  static importJSON(
    serializedNode: SerializedTableCellStyledNode,
  ): TableCellStyledNode {
    const { headerState, colSpan, rowSpan, width, style } = serializedNode;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const cellNode = $createTableCellStyledNode({
      headerState,
      colSpan: colSpan || 1,
      style,
      width,
    });
    /* eslint-disable no-underscore-dangle */
    cellNode.__rowSpan = rowSpan || 1;
    cellNode.__backgroundColor = serializedNode.backgroundColor || null;
    /* eslint-enable no-underscore-dangle */
    return cellNode;
  }

  createDOM(config: EditorConfig): HTMLElement {
    const tableElement = super.createDOM(config);

    if (this.style) {
      tableElement.setAttribute(
        'style',
        convertCssPropertiesToCssText(this.style),
      );
    }

    return tableElement;
  }

  exportJSON(): SerializedTableCellStyledNode {
    return {
      ...super.exportJSON(),
      style: this.style,
      type: 'table-cell-styled',
    };
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const domExportOutput = super.exportDOM(editor);
    domExportOutput.element.setAttribute(
      'style',
      convertCssPropertiesToCssText(this.style),
    );
    return domExportOutput;
  }
}

interface TableStyledPayload {
  headerState: (typeof TableCellHeaderStates)[keyof typeof TableCellHeaderStates];
  colSpan?: number;
  width?: number;
  style?: CSSProperties;
  key?: NodeKey;
}

export function $createTableCellStyledNode({
  headerState,
  colSpan = 1,
  width,
  key,
  style,
}: TableStyledPayload): TableCellStyledNode {
  return $applyNodeReplacement(
    new TableCellStyledNode(headerState, colSpan, width, style, key),
  );
}

export function convertTableCellStyledElement(
  domNode: Node,
): DOMConversionOutput {
  const domNodeElem = domNode as HTMLTableCellElement;
  const nodeName = domNode.nodeName.toLowerCase();

  let width: number | undefined;

  if (PIXEL_VALUE_REG_EXP.test(domNodeElem.style.width)) {
    width = parseFloat(domNodeElem.style.width);
  }

  const tableCellNode = $createTableCellStyledNode({
    headerState:
      nodeName === 'th'
        ? TableCellHeaderStates.ROW
        : TableCellHeaderStates.NO_STATUS,
    colSpan: domNodeElem.colSpan,
    width,
    style: convertStyleToCssProperties(domNodeElem.style),
  });

  // eslint-disable-next-line no-underscore-dangle
  tableCellNode.__rowSpan = domNodeElem.rowSpan;

  return {
    forChild: (lexicalNode, parentLexicalNode) => {
      if ($isTableCellNode(parentLexicalNode) && !$isElementNode(lexicalNode)) {
        const paragraphNode = $createParagraphNode();
        if (
          $isLineBreakNode(lexicalNode) &&
          lexicalNode.getTextContent() === '\n'
        ) {
          return null;
        }
        paragraphNode.append(lexicalNode);
        return paragraphNode;
      }

      return lexicalNode;
    },
    node: tableCellNode,
  };
}
