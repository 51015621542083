import { RouteRequirements } from './Router.types';

export const restrictedRoutes: Record<string, RouteRequirements> = {
  '/dashboard': {
    permissions: ['Leads:Get'],
  },
  '/inbox': {
    permissions: ['Messages:Get'],
  },
  '/pipeline': {
    permissions: ['Leads:Get'],
  },
  '/calendar': {
    permissions: ['Properties:Get'],
  },
  '/reports': {
    permissions: ['Reports:Get', 'CustomReports:Get', 'Analytics:Get'],
  },
  '/properties': {
    permissions: ['Properties:Get'],
  },
  '/publishing': {
    permissions: ['AgencySettings:Get'],
  },
  '/third-party-agency': {
    permissions: ['AgencySettings:Get'],
  },
  '/calendar-import': {
    permissions: ['AgencySettings:Get'],
  },
  '/integrations': {
    permissions: ['AgencySettings:Get'],
  },
  '/channels': {
    permissions: ['AgencySettings:Get'],
  },
  '/my-templates': {
    permissions: ['AgencySettings:Get'],
  },
  '/owners': {
    permissions: ['AgencySettings:Get'],
  },
  '/discount-codes': {
    permissions: ['AgencySettings:Get'],
  },
  '/agency-settings': {
    permissions: ['AgencySettings:Get'],
  },
  '/turnover-management': {
    permissions: ['AgencySettings:Get'],
  },
  '/refer': {
    permissions: ['AgencySettings:Get'],
  },
  '/analytics': {
    permissions: ['Analytics:Get'],
  },
};

export const onlyShowContentRoutes = ['/oauth/authorize'];
