import useFormatCurrency from 'hooks/useFormatCurrency';
import { EditableField } from '../Fields.styles';

interface DisplayableValueProps {
  value: number | string;
  currency?: string;
  type: 'currency' | 'text' | 'percentage';
  onClick: () => void;
}

const DisplayableValue = ({
  value,
  currency,
  type,
  onClick,
}: DisplayableValueProps) => {
  const formatCurrency = useFormatCurrency();

  const valueToDisplay = {
    currency:
      currency && typeof value === 'number'
        ? formatCurrency({ currency, value })
        : value,
    text: value,
    percentage: `${value}%`,
  };

  return (
    <EditableField onClick={onClick}>
      {valueToDisplay[type] || valueToDisplay.text}
    </EditableField>
  );
};

DisplayableValue.defaultProps = {
  currency: '',
};

export default DisplayableValue;
