export const propertyForBodyQueryFields = `
  uid,
  name,
  isActive,
  mainPicture{tinyThumbnail, largeThumbnail},
  picture,
  businessType,
  propertyType,
  masterUnitUid,
  availability{maxGuests},
  bathroomsNumber,
  bedroomsNumber,
  address{
    address,
    address2,
    zipCode,
    city,
    state
  },
  subUnits{
    uid,
    bathroomsNumber,
    bedroomsNumber,
    isActive,
    name,
    mainPicture{tinyThumbnail, largeThumbnail},
    availability{maxGuests}, 
    businessType,
    propertyType,
    masterUnitUid,
    tags,
  },
  numberOfSubUnits,
  unitTypes{
    uid,
    name,
    bathroomsNumber,
    bedroomsNumber,
    mainPicture{tinyThumbnail},
    availability{maxGuests},
    businessType,
    hotelUid,
    units{uid, name},
    isActive,
    tags,
  },
  pricing{currency},
  tags,
`;
