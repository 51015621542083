import styled from 'styled-components';
import { DropdownButton, MenuItem } from 'react-bootstrap';

export const LeadGuestName = styled.h3.attrs({
  'data-testid': 'lead-guest-name',
})`
  margin-top: 0px;
`;

export const LeadContainer = styled.div`
  padding: 0.9em;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

export const LeadHeader = styled.div`
  border-bottom: 1px solid #ddd;
  padding-bottom: 1em;
`;

export const LeadHeaderFirstRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const LeadHeaderStatusContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 1em;
`;

export const LeadBody = styled.div`
  margin-top: 16px;
`;

export const LeadButtonsContainer = styled.div`
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 0.5em;
  padding-top: 1em;

  button {
    max-width: 100px;
    white-space: normal;
  }

  button.lead-action-button-cancel_booking {
    max-width: 90%;
  }
`;

export const LeadButtonsAdditionalInfo = styled.div.attrs({
  className: 'text-center',
  'data-testid': 'lead-action-info',
})``;

export const LeadPropertyName = styled.div.attrs({
  className: 'text-muted',
  'data-testid': 'lead-property-name',
})`
  font-size: 1.2em;
  font-weight: 400;
`;
export const LeadDates = styled.div.attrs({
  className: 'text-muted',
  'data-testid': 'lead-dates',
})`
  font-size: 1em;
  font-weight: 300;

  span {
    white-space: nowrap;
  }
`;

export const LeadDetailLabel = styled.div.attrs(
  ({ $id }: { $id?: string }) => ({
    className: 'text-muted',
    'data-testid': $id ? `lead-detail-label-${$id}` : '',
  }),
)<{ $id?: string }>``;

export const LeadDetailValue = styled.div.attrs(
  ({ $id }: { $id?: string }) => ({
    'data-testid': $id ? `lead-detail-value-${$id}` : '',
  }),
)<{ $id?: string }>`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1em;
  word-break: break-all;
`;

export const LeadDetailChannelValue = styled(LeadDetailValue).attrs({
  'data-testid': 'lead-detail-value-channel',
})`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const LeadSelectorContainer = styled.div<{ $width: number }>`
  margin-bottom: 0.8em;

  .dropdown.btn-group {
    width: 100%;

    ${(props) =>
      props.$width &&
      `ul {
        max-height: 300px;
        overflow-y: auto;
        width: ${props.$width}px;
      }
    `}
  }
`;

export const LeadSelectorButton = styled(DropdownButton).attrs({
  block: true,
  bsStyle: 'default',
})`
  width: 100%;
`;

export const LeadSelectorItem = styled(MenuItem)``;

export const LeadSelectorItemPropertyName = styled.div.attrs({
  'data-testid': 'item-property-name',
})``;

export const LeadSelectorItemDates = styled.div.attrs({
  className: 'text-muted',
  'data-testid': 'item-dates',
})``;

export const LeadStandalonePanelWrapper = styled.div`
  margin: 0 auto 20px;
  max-width: 295px;
`;
