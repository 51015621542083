import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';

const LoginDesktopSignUpLink = () => {
  const { t } = useTranslation();
  const { registrationToken } = useWatch();

  if (registrationToken) {
    return null;
  }

  return (
    <Link
      className="action-link"
      data-testid="signup-link"
      to="/signup?pl=YEARLY_STARTER"
    >
      {t('pageLogin.notSignedUp')}
    </Link>
  );
};

export default LoginDesktopSignUpLink;
