import {
  convertLeadSourceFromLeadChannel,
  getColorByLeadSource,
} from 'utils/lead/leadUtils';
import { LeadChannel } from 'models/Leads';
import theme from 'styles/theme';
import { PropertyBusinessType } from 'models/Properties';
import { chartConstants } from './charts/Chart.constants';
import { PieLegend } from './charts/Pies.types';
import { AnalyticsPropertyMetricValue } from './types/AnalyticsProperty.types';
import { AnalyticsChannelMetricValue } from './types/AnalyticsChannel.types';
import {
  AnalyticsBasicProperty,
  AnalyticsFilter,
  DateType,
} from './types/Analytics.types';
import { AnalyticsKeyMetric } from './types/AnalyticsKeyMetrics.types';

export const LegendsColor = [
  theme.colors.hostfullyBlue,
  theme.colors.bgGreen,
  theme.colors.hostfullyRed,
  theme.colors.hostfullyYellow,
  theme.colors.hostfullyPurple,
];

export const propertyAnalyticsGraphQLQuery = `
    uid,
    name,
    businessType,
    subUnits{
      uid,
      name,
      businessType,
    },
    unitTypes{
      uid,
      name,
      businessType,
    },
    pricing{currency},
  `;

export const propertyAnalyticsConditionGraphQLQuery = `isActive:true, limit:"9999", topLevelOnly:true,returnTopLevelUnitsWithMatchingSubLevels:true, sortStrategy:"SORT_BY_NAME"`;

export const getBarChartHeight = (numberOfItems): number => {
  return numberOfItems * (chartConstants.BAR_WIDTH * 2) + 100;
};

export const getChannelPieLegends = (
  metricValues?: AnalyticsChannelMetricValue[],
): PieLegend[] => {
  if (!metricValues) return [];

  return (
    metricValues.reduce((acc, curr) => {
      if (!acc.includes(curr.channel)) {
        acc.push({
          name: curr.channel,
          color: getColorByLeadSource(
            convertLeadSourceFromLeadChannel(curr.channel as LeadChannel),
          ),
        });
      }
      return acc;
    }, []) || []
  );
};

export const getPropertyPieLegends = (
  metricValues?: AnalyticsPropertyMetricValue[],
): PieLegend[] => {
  if (!metricValues) return [];

  return (
    metricValues.reduce((acc, curr, currentIndex) => {
      if (!acc.includes(curr.propertyName)) {
        acc.push({
          name: curr.propertyName,
          color: LegendsColor[currentIndex],
        });
      }
      return acc;
    }, []) || []
  );
};
export const isCardDataApplicable = (
  filter: AnalyticsFilter,
  metric: AnalyticsKeyMetric,
) => {
  if (
    metric === AnalyticsKeyMetric.OCCUPANCY_RATE ||
    metric === AnalyticsKeyMetric.REV_PAR
  ) {
    return !(
      !!filter?.channel ||
      filter?.dateType === DateType.CHECK_IN ||
      filter?.dateType === DateType.CHECK_OUT ||
      filter?.dateType === DateType.LEAD_CREATION ||
      filter?.dateType === DateType.RESERVATION
    );
  }

  return true;
};

export function getPropertiesFilterConditions(
  propertyUid: string,
  properties: AnalyticsBasicProperty[],
) {
  if (
    propertyUid &&
    properties.find(
      ({ businessType, uid }) =>
        businessType === PropertyBusinessType.HOTEL &&
        propertyUid.includes(uid),
    )
  ) {
    const unitTypesUids = properties
      .filter(
        ({ businessType, uid }) =>
          businessType === PropertyBusinessType.HOTEL &&
          propertyUid.includes(uid),
      )
      .reduce((acc, { unitTypes }) => {
        return acc.concat(unitTypes.map(({ uid }) => uid));
      }, []);
    return propertyUid
      ? Array.from(unitTypesUids).map((unitTypesUid) => unitTypesUid)
      : '';
  }
  return propertyUid ? [propertyUid] : '';
}
