import { useMemo } from 'react';
import { differenceInCalendarDays, isSameDay } from 'date-fns';
import { isBetween } from 'utils/dateTimeUtils';
import { StackedCalendarMode } from 'pages/stackedCalendar/Calendar.types';
import { propertiesBusinessType } from 'pages/stackedCalendar/Calendar.constants';
import { PropertyBusinessType } from 'models/Properties';
import {
  CalendarSelectionMouseDownHandlerPayload,
  CalendarSelectionMouseEnterHandlerPayload,
  CalendarSelectionMouseUpHandlerPayload,
} from './CalendaBodySelection.types';
import CalendarBodySelectionService from './CalendarBodySelectionService';

function resetSelectionHandler() {
  CalendarBodySelectionService.reset();
  CalendarBodySelectionService.notify();
}

function mouseDownHandler({
  min,
  max,
  limit,
  propertyUid,
  mode,
}: CalendarSelectionMouseDownHandlerPayload) {
  const selectionContainer =
    CalendarBodySelectionService.getSelectionContainer();

  if (!selectionContainer.selection) {
    selectionContainer.selection = {
      limit,
      mouseUpCount: 0,
      min,
      max,
      pivot: min,
      propertyUidPivot: propertyUid,
      propertyUids: [propertyUid],
      mode,
    };

    selectionContainer.visibleProperties = [];

    if (mode === StackedCalendarMode.PRICING) {
      const elements = document.getElementsByClassName(
        'property-list-item',
      ) as HTMLCollectionOf<HTMLElement>;

      if (!elements.length) {
        // Standalone calendar
        selectionContainer.visibleProperties.push(propertyUid);
      } else {
        for (let i = 0; i < elements.length; i += 1) {
          selectionContainer.visibleProperties.push(
            elements[i].dataset.propertyUid,
          );
        }
      }
    }

    CalendarBodySelectionService.notify();
  }
}

function getPropertiesListRange(
  propertyUidPivot,
  propertyUid,
  visibleProperties,
): string[] {
  const propertyUidPivotSerialized = propertyUidPivot.split('[')[0];
  const propertyUidSerialized = propertyUid.split('[')[0];

  const pivotIndex = visibleProperties.indexOf(propertyUidPivotSerialized);
  const limitIndex = visibleProperties.indexOf(propertyUidSerialized);

  return pivotIndex < limitIndex
    ? visibleProperties.slice(pivotIndex, limitIndex + 1)
    : visibleProperties.slice(limitIndex, pivotIndex + 1);
}

function mouseEnterHandler({
  date,
  propertyUid,
}: CalendarSelectionMouseEnterHandlerPayload) {
  const { selection, visibleProperties } =
    CalendarBodySelectionService.getSelectionContainer();

  if (!selection) {
    return;
  }

  const {
    limit: { start, end },
    pivot,
    propertyUidPivot,
    propertyUids: selectedPropertyUids,
  } = selection;

  if (selection.mode === StackedCalendarMode.PRICING) {
    if (!selectedPropertyUids.includes(propertyUid)) {
      selection.propertyUids.push(propertyUid);
    }

    const newPropertyUids = getPropertiesListRange(
      propertyUidPivot,
      propertyUid,
      visibleProperties,
    );

    selection.propertyUids = newPropertyUids.filter(
      (uid) =>
        propertiesBusinessType[uid]?.type !== PropertyBusinessType.UNIT &&
        propertiesBusinessType[uid]?.type !== PropertyBusinessType.HOTEL,
    );

    CalendarBodySelectionService.notify();
  }

  if (
    !selectedPropertyUids.includes(propertyUid) ||
    !isBetween(date, start, end)
  ) {
    return;
  }

  const pivotDiff = differenceInCalendarDays(pivot, date);

  if (pivotDiff === 0) {
    selection.min = date;
    selection.max = date;
    CalendarBodySelectionService.notify();
  } else if (pivotDiff > 0) {
    selection.min = date;
    selection.max = pivot;
    CalendarBodySelectionService.notify();
  } else if (pivotDiff < 0) {
    selection.min = pivot;
    selection.max = date;
    CalendarBodySelectionService.notify();
  }
}

function mouseUpHandler({
  actionCallback,
  date,
}: CalendarSelectionMouseUpHandlerPayload) {
  const { selection } = CalendarBodySelectionService.getSelectionContainer();

  if (!selection) {
    return;
  }

  const { mouseUpCount, min, max, pivot, propertyUids } = selection;

  if (mouseUpCount > 0 || !isSameDay(date, pivot)) {
    actionCallback({
      from: min,
      to: max,
      propertyUids,
    });
  } else {
    selection.mouseUpCount = mouseUpCount + 1;
  }
}

const useCalendarBodySelection = () => {
  return useMemo(
    () => ({
      resetSelection: () => {
        resetSelectionHandler();
      },
      handleMouseDown: (payload: CalendarSelectionMouseDownHandlerPayload) => {
        mouseDownHandler(payload);
      },
      handleMouseEnter: (
        payload: CalendarSelectionMouseEnterHandlerPayload,
      ) => {
        mouseEnterHandler(payload);
      },
      handleMouseUp: (payload: CalendarSelectionMouseUpHandlerPayload) => {
        mouseUpHandler(payload);
      },
      updateSelectionDates: (min: Date, max: Date) => {
        CalendarBodySelectionService.updateSelectionMinMaxDates(min, max);
      },
    }),
    [],
  );
};

export default useCalendarBodySelection;
