import { useContext, useEffect } from 'react';
import useAppUser from 'hooks/useAppUser';
import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';
import useFetchEmployees from 'components/domain/employee/useFetchEmployees';
import { GetAllLeadChannelsResponseTO } from 'models/Leads';
import useFetchAllProperties from 'components/domain/property/useFetchAllProperties';
import {
  propertyCalendarConditionGraphQLQuery,
  propertyCalendarGraphQLQuery,
} from 'pages/stackedCalendar/StackedCalendar.utils';
import PipelineContext from './PipelineContext';

const usePipelineInit = () => {
  const { isEmployee } = useAppUser();
  const { setEmployees, setLeadChannels, setProperties } =
    useContext(PipelineContext);
  const { employees, isFetching: isEmployeesLoading } = useFetchEmployees();
  const { allProperties: properties, isFetching: isPropertiesLoading } =
    useFetchAllProperties({
      enabled: isEmployee,
      conditions: propertyCalendarConditionGraphQLQuery,
      fields: propertyCalendarGraphQLQuery,
    });

  const { data: leadChannels, isLoading: isLeadChannelsLoading } = useAppQuery(
    ['leadChannels'],
    async () => {
      const response = await API.get<GetAllLeadChannelsResponseTO>(
        '/v3/leads-channels',
      );
      return response.data.leadChannels;
    },
    { enabled: isEmployee },
  );

  useEffect(() => {
    if (employees) {
      setEmployees(employees);
    }

    if (leadChannels) {
      setLeadChannels(leadChannels);
    }

    if (properties) {
      setProperties(properties);
    }
  }, [employees, leadChannels, properties]);

  return {
    isLoading:
      isEmployee &&
      (isEmployeesLoading || isLeadChannelsLoading || isPropertiesLoading),
  };
};

export default usePipelineInit;
