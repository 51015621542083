import styled from 'styled-components';

export const Container = styled.div.attrs({ className: 'container' })`
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    vertical-align: middle;
  }

  .status-col {
    padding-left: 8px;
    margin: 14px 0;
  }

  .loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;

  & > *:first-child {
    margin-right: 16px;
  }

  #status {
    max-width: 300px;
  }
`;
