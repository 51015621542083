import useAppModal from 'hooks/useAppModal';
import { AvailableAmenity } from 'models/Amenity';
import usePropertyAmenities from 'pages/property/amenities/usePropertyAmenities';
import { useFormContext } from 'react-hook-form';
import AmenityDescriptionModal, {
  amenityDescriptionModalId,
} from './AmenityDescriptionModal';

const useEditAmenityDescriptionModal = (amenity: AvailableAmenity) => {
  const { setValue, getValues } = useFormContext();
  const { propertyAmenities } = usePropertyAmenities();
  const { openModal, closeModal } = useAppModal();

  const amenityDescriptionName = `amenities.${amenity.amenityType}.description`;

  const updateDescription = ({ description }) => {
    setValue(amenityDescriptionName, description, { shouldDirty: true });
    closeModal(amenityDescriptionModalId);
  };

  const openEditDescriptionModal = () => {
    const defaultAmenity = propertyAmenities[amenity.amenityType];
    const defaultDescription = defaultAmenity?.description;

    const updatedDescription = getValues(amenityDescriptionName);

    openModal({
      id: amenityDescriptionModalId,
      customContent: (
        <AmenityDescriptionModal
          amenity={amenity}
          updateDescription={updateDescription}
          defaultDescription={updatedDescription || defaultDescription}
        />
      ),
    });
  };

  return {
    openEditDescriptionModal,
  };
};

export default useEditAmenityDescriptionModal;
