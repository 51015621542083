import PropertyListItemPlaceholder from '../item/PropertyListItemPlaceholder';
import { PropertiesBodyListStyled } from './PropertiesBody.styles';

const PLACEHOLDER_ITEMS = Array.from({ length: 5 }).map((_, index) => index);

const PropertiesBodyListPlaceholder = () => {
  return (
    <PropertiesBodyListStyled>
      {PLACEHOLDER_ITEMS.map((id) => (
        <PropertyListItemPlaceholder key={id} />
      ))}
    </PropertiesBodyListStyled>
  );
};

export default PropertiesBodyListPlaceholder;
