import { useTranslation } from 'react-i18next';
import {
  ADJUSTMENT_TYPES,
  AdjustmentType,
} from '../OwnerAdjustmentModal.types';

type OptionType = { label: string; value: AdjustmentType };

const useAdjustmentTypeOptions = (): OptionType[] => {
  const { t } = useTranslation();

  return [
    {
      label: t('pageOwnerAdjustments.modal.form.adjustmentTypeOptions.oneTime'),
      value: ADJUSTMENT_TYPES.ONE_TIME,
    },
    {
      label: t(
        'pageOwnerAdjustments.modal.form.adjustmentTypeOptions.recurring',
      ),
      value: ADJUSTMENT_TYPES.RECURRING,
    },
  ];
};

export default useAdjustmentTypeOptions;
