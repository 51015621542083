import useAppMutation from 'hooks/useAppMutation';
import {
  CreateSecurityDepositChargeResponseTO,
  SecurityDepositChargeCreationPayload,
} from 'models/Transactions';
import API from 'services/API';

const useCreateSecurityDepositCharge = () => {
  const { mutateAsync, isLoading } = useAppMutation(
    ({
      payload,
    }: {
      errorCallback: (response: unknown) => void;
      successCallback: (
        response: CreateSecurityDepositChargeResponseTO,
      ) => void;
      payload: SecurityDepositChargeCreationPayload;
    }) =>
      API.post<CreateSecurityDepositChargeResponseTO>(
        '/api/internal/transactions/security-deposit-charge',
        payload,
      ),
    {
      onSuccess: (response, { successCallback }) => {
        successCallback?.(response.data);
      },
      onError: (response, { errorCallback }) => {
        errorCallback?.(response);
      },
    },
  );

  return { createSecurityDepositCharge: mutateAsync, isLoading };
};

export default useCreateSecurityDepositCharge;
