import styled from 'styled-components';
import SelectField from 'components/forms/selectField/SelectField';
import TextField from 'components/forms/textField/TextField';

export const TextFieldStyled = styled(TextField)``;

export const SelectFieldStyled = styled(SelectField)`
  background-color: inherit;
  border: none;
  height: 32px;
  min-width: 70px;

  margin-bottom: 0;
`;

export const SelectTextWrapper = styled.div`
  margin-bottom: 15px;

  .input-group-addon {
    padding: 0;

    .form-field-container > div {
      padding: 0;
    }

    .form-field-container {
      margin: 0;
    }
  }
`;
