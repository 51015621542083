import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import SelectField from '../selectField/SelectField';
import TextField from '../textField/TextField';
import usePrevious from '../../../hooks/usePrevious';
import { usStateOptions } from '../../../utils/constants/states';
import { StateSelectFieldProps } from './StateSelectField.types';

const StateSelectField: FC<StateSelectFieldProps> = ({
  countryCode,
  defaultUsState,
  name,
  ...rest
}) => {
  const { setValue, watch } = useFormContext();
  const prevCountryCode = usePrevious(countryCode);
  const currentValue = watch(name);

  useEffect(() => {
    if (prevCountryCode === 'US' && countryCode !== 'US') {
      setValue(name, '');
    }

    if (
      defaultUsState &&
      !currentValue &&
      prevCountryCode !== 'US' &&
      countryCode === 'US'
    ) {
      setValue(name, defaultUsState);
    }
  }, [countryCode]);

  if (countryCode === 'US') {
    return <SelectField name={name} options={usStateOptions} {...rest} />;
  }

  return <TextField name={name} {...rest} />;
};

export default StateSelectField;
