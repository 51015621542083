import { BaseResponse } from './_base';

export enum JobStatus {
  NEW = 'NEW',
  SCHEDULED = 'SCHEDULED',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
}

export interface Job {
  uid: string;
  status: JobStatus;
  propertyUid: string;
  serviceUid: string;
  notes: string;
  startLocalDateTime: string;
  startDateObj?: Date;
  endLocalDateTime: string;
  endDateObj?: Date;
  leadUid?: string;
  unitUid?: string;
}

export interface JobLeadParams {
  propertyUid: string;
  jobDate: Date;
}

export interface GetJobsResponseTO extends BaseResponse {
  jobs: Job[];
}
