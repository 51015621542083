import styled from 'styled-components';
import { Glyphicon } from 'react-bootstrap';

export const OkIcon = styled(Glyphicon).attrs({
  glyph: 'ok',
})``;

export const WarningIcon = styled(Glyphicon).attrs({
  glyph: 'time',
})``;

export const DangerIcon = styled(Glyphicon).attrs({
  glyph: 'exclamation-sign',
})``;

export const TotalSubtitleWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-right: 5px;
  }
`;
