import styled from 'styled-components';
import HeaderLoginImg from 'assets/images/header-login.png';
import FooterLoginImg from 'assets/images/footer-login.svg';
import theme from 'styles/theme';

export const LayoutDesktopContainer = styled.div`
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
`;

export const LayoutDesktopContent = styled.main`
  .card {
    padding: 58px;
    background-color: #fff;
    box-shadow: 0 0 30px 0 rgb(0 0 0 / 15%);
    max-width: 970px;
    min-width: 60%;
    margin: 58px auto;
    border-radius: 8px;
  }

  flex: 1;
  display: flex;
  align-items: center;

  h2 {
    font-weight: 300;
    margin-bottom: 24px !important;
  }

  .checkbox {
    padding: 0;
    min-height: unset;

    & > label {
      font-weight: 300 !important;
    }
  }

  .checkbox,
  button {
    margin-bottom: 16px !important;
  }

  .error-message {
    color: #f44336;
    text-align: center;
  }

  .action-link {
    display: block;
    color: ${theme.colors.fontGreen};
    text-align: right;
    font-weight: 300;
    cursor: pointer;
    transition: all ease-in 150ms;
    margin-bottom: 10px;
    font-size: 15px;
  }

  // Styles based in the old pmp-core login page (should be temporary):

  input:not([type='checkbox']) {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    margin: 0;
    font: inherit;
    font-family: inherit;
    display: block;
    width: 100%;
    padding: 6px 12px;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    height: 50px;
    border: solid 2px #f2f2f2;
    padding-left: 28px;
    padding-top: 12.5px;
    padding-bottom: 12.5px;
    line-height: 1.92;
    box-shadow: unset;
    background: none;
    border-radius: 0;
    font-size: 16px;
    font-weight: 300;
    outline: none;

    &:focus {
      border-color: #66afe9 !important;
      outline: 0;
      box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
        0 0 8px rgb(102 175 233 / 60%);
    }
  }

  button {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    margin: 0;
    font: inherit;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    font-family: inherit;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    font-size: 18px;
    line-height: 1.3333333;
    display: block;
    width: 100%;
    border-radius: 0;
    color: white;
    background-color: ${theme.colors.bgGreen};
    padding: 12px 40px;
    margin-top: 8px;
    border-color: transparent;

    font-weight: 300;

    &:hover {
      background-color: #5fe7a9;
      color: white;
      border: 1px solid transparent;
    }

    &:focus,
    &:active,
    &:focus:active {
      font-weight: 500;
      outline: 0;
      box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
      background-color: #5fe7a9;
      border: 1px solid transparent;
      color: #fff;
    }

    &:disabled {
      background-color: #e5e5e5;
      color: #fff;

      &:hover {
        background-color: #e5e5e5;
        color: #fff;
        border-color: transparent;
      }
    }
  }
`;

export const LayoutDesktopHeaderStyled = styled.header`
  height: 80px;
  background-image: url(${HeaderLoginImg});
  background-repeat: no-repeat;
  background-position: top right;
  background-color: #3a33af;

  img {
    height: 30px;
  }

  .container {
    height: 80px;

    .inner-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
    }
  }
`;

export const HelpLink = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    height: 28px;
    width: 28px;
    color: #fff;
    margin-right: 10px;
  }
  span {
    color: #fff !important;
    font-weight: 300;
    font-size: 16px;
  }
`;

export const LayoutDesktopFooterStyled = styled.footer`
  background-image: url(${FooterLoginImg});
  background-size: cover;

  .container {
    padding-top: 64px;
    padding-bottom: 64px;

    display: flex;
    align-items: center;
  }

  img {
    margin-right: 40px;
  }

  p {
    margin: 0;
    color: #fff !important;
    font-weight: 300;
    font-size: 18px;
  }
`;

export const FullIframeLayoutDesktopContent = styled.main`
  height: 100vh;
`;

export const LayoutDesktopCustomFooterStyled = styled.footer.attrs({
  id: 'footer',
})`
  font-size: 14px;
  color: #000;
  margin-top: 40px;
`;

export const LayoutDesktopCustomHeaderStyled = styled.header.attrs({
  id: 'header',
})`
  min-height: 80px;
  width: 100vw;
`;

export const VersionNumber = styled.span`
  color: #333;
`;
