import ZonedDateTime from 'ts-time/ZonedDateTime';
import LocalDateTime from 'ts-time/LocalDateTime';
import { ZoneId } from 'ts-time/Zone';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function toZoneDateTime(dateTime: string): ZonedDateTime {
  const date: LocalDateTime = LocalDateTime.parse(dateTime);
  return ZonedDateTime.ofDateTime(date, ZoneId.of(timezone));
}

export function toZoneDateTimeBasedOnTimezone(
  dateTime: string,
  customTimeZone: string,
): ZonedDateTime {
  const date: LocalDateTime = LocalDateTime.parse(dateTime);
  return ZonedDateTime.ofDateTime(date, ZoneId.of(customTimeZone || timezone));
}

export function fromZoneDateTime(
  zonedDateTime: ZonedDateTime | undefined,
): string {
  if (!zonedDateTime) return '';
  return zonedDateTime.date.toString();
}

export function timeZoneDateTimeReplacer(key: any, value: any) {
  // Filtering out properties
  if (value instanceof ZonedDateTime) {
    return value.toString();
  }
  return value;
}
