import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import {
  PostImportablePropertiesRequestTO,
  PostImportablePropertiesResponseTO,
} from 'models/bookingDotCom/ImportableProperties';
import { useTranslation } from 'react-i18next';
import API from 'services/API';

const useBookingDotComOperationsImportPropertyMutation = () => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  const { mutate: importProperties, isLoading } = useAppMutation(
    (data: PostImportablePropertiesRequestTO) => {
      return API.post<PostImportablePropertiesResponseTO>(
        '/api/internal/bookingdotcom-operations/import-property/batch-process',
        data,
      );
    },
    {
      onSuccess: (response, { successCallback }) => {
        successCallback?.();
      },
      onError: () =>
        notifyError(
          t(
            'pageProperties.importBookingDotComPropertiesModal.errorImportingProperties',
          ),
        ),
    },
  );

  return { isLoading, importProperties };
};

export default useBookingDotComOperationsImportPropertyMutation;
