import styled from 'styled-components';
import theme from '../../styles/theme';

export const LeadListStatusesContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const LeadListStatusesDivider = styled.div`
  background-color: ${theme.colors.gray300};
  display: flex;
  height: 1px;
  margin: 0 5px;
  width: 15px;
`;
