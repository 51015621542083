import { useTranslation } from 'react-i18next';
import Button from '../../../../components/button/Button';

const EditOrderAdjustmentButton = ({ onEdit }: { onEdit: () => void }) => {
  const { t } = useTranslation();

  return (
    <Button
      type="button"
      className="btn btn-xs btn-link"
      onClick={onEdit}
      data-testid="price-details-list-item-edit-button"
    >
      {t('common.edit')}
    </Button>
  );
};

export default EditOrderAdjustmentButton;
