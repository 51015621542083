import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.section`
  position: relative;
  z-index: 100;
  padding: 40px;
  width: 55%;

  h1 {
    font-size: 53px;
    font-weight: 700;
    color: #2d2aa5;
    text-align: center;
  }

  p {
    font-size: 32px;
    font-weight: 500;
    color: #212529;
  }

  h1,
  p {
    text-align: left;
  }

  div {
    max-width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  button {
    align-self: self-start;
  }

  ${mediaQuery.custom(1920)} {
    width: 65%;
  }

  ${mediaQuery.custom(1024)} {
    padding: 10px;
    width: 100%;

    div {
      max-width: 100vw;
    }

    h1,
    p {
      text-align: center;
    }

    button {
      align-self: center;
    }
  }
`;
