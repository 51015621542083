import { useContext } from 'react';
import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';
import {
  enableQuery,
  getTimezoneDateString,
  prepareAllPropertyUids,
  prepareChannelPieChartsData,
  preparePropertyPieChartsData,
} from 'utils/analytics/analyticsUtils';
import { useTranslation } from 'react-i18next';
import useAppUser from '../../hooks/useAppUser';
import AnalyticsContext from './AnalyticsContext';
import { AnalyticsChannelResponse } from './types/AnalyticsChannel.types';
import { AnalyticsPropertyResponse } from './types/AnalyticsProperty.types';
import { AnalyticsKeyMetric } from './types/AnalyticsKeyMetrics.types';

const usePieChartsData = () => {
  const { filter, allProperties } = useContext(AnalyticsContext);
  const { agencyUid, agency } = useAppUser();
  const { t } = useTranslation();

  const {
    isFetching: isChannelPieLoading,
    error: channelPieError,
    data: channelPieData,
  } = useAppQuery(
    [
      'getChannelPieDataForPieChart',
      filter?.dateType,
      filter?.currency,
      filter?.propertyUid,
      filter?.channel,
      filter?.range,
      agencyUid,
    ],
    async () => {
      const { dateType, currency, propertyUid, channel, range } = filter;
      const response = await API.post<AnalyticsChannelResponse>(
        `api/internal/analytics/channel`,
        {
          agencyUid,
          dateType,
          from: getTimezoneDateString(range.startDate, agency?.timeZone),
          to: getTimezoneDateString(range.endDate, agency?.timeZone),
          currency,
          propertyUids: prepareAllPropertyUids({
            allProperties,
            currency,
            propertyUid,
          }),
          channel,
          metric: AnalyticsKeyMetric.RENTAL_REVENUE,
        },
      );

      return prepareChannelPieChartsData({
        metricValues: response.data.metricValues,
        exceedString: t('pageAnalytics.pie.others'),
        nameKey: 'channel',
      });
    },
    {
      enabled: enableQuery({
        agencyUid,
        filter,
        allProperties,
      }),
      keepPreviousData: true,
    },
  );

  const {
    isFetching: isRevParPieLoading,
    error: revParPieError,
    data: revParPieData,
  } = useAppQuery(
    [
      'getRevParPieDataForPieChart',
      filter?.dateType,
      filter?.currency,
      filter?.propertyUid,
      filter?.channel,
      filter?.range,
      agencyUid,
    ],
    async () => {
      const { dateType, currency, propertyUid, channel, range } = filter;
      const response = await API.post<AnalyticsPropertyResponse>(
        `api/internal/analytics/property`,
        {
          agencyUid,
          dateType,
          from: getTimezoneDateString(range.startDate, agency?.timeZone),
          to: getTimezoneDateString(range.endDate, agency?.timeZone),
          currency,
          propertyUids: prepareAllPropertyUids({
            allProperties,
            currency,
            propertyUid,
          }),
          channel,
          metric: AnalyticsKeyMetric.REV_PAR,
        },
      );
      return preparePropertyPieChartsData({
        metricValues: response.data.metricValues,
        allProperties,
        exceedString: t('pageAnalytics.pie.others'),
        nameKey: 'propertyName',
      });
    },
    {
      enabled: enableQuery({
        agencyUid,
        filter,
        allProperties,
      }),
      keepPreviousData: true,
    },
  );

  return {
    isChannelPieLoading,
    channelPieData,
    channelPieError,
    revParPieData,
    isRevParPieLoading,
    revParPieError,
    isInitialChannelPieLoading: channelPieData === undefined,
    isInitialRevParPieLoading: revParPieData === undefined,
  };
};

export default usePieChartsData;
