import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const OpenSetRoomsModalContainer = styled(Col)`
  margin-bottom: 10px;
  margin-top: -15px;

  button {
    padding-left: 0;
    padding-right: 0;
  }
`;
