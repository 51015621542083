import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const AppliesToFieldContainer = styled(Row)`
  .radio {
    margin-bottom: 10px;
  }

  .radio + .radio {
    margin-top: -5px;
    padding-top: 0px;
  }

  .property-multi-select-filter {
    > .row {
      margin: 0;
    }
  }
`;
