import { useTranslation } from 'react-i18next';
import API from '../../../services/API';
import useAppMutation from '../../../hooks/useAppMutation';
import useNotify from '../../../hooks/useNotify';
import { ReviewByHost } from '../../../models/Reviews';

const useReviewByHostMutations = () => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  const { mutate: updateReviewByHost, isLoading: isUpdating } = useAppMutation(
    ({
      reviewByHost,
    }: {
      reviewByHost: ReviewByHost;
      successCallback?: Function;
    }) => {
      return API.put(`/v3/reviews-by-host/${reviewByHost.uid}`, reviewByHost);
    },
    {
      onSuccess: (response, { successCallback }) => {
        successCallback?.();
      },
      onError: () =>
        notifyError(
          t('componentLead.modal.form.error.reviewByHostUpdateError'),
        ),
    },
  );

  return { isUpdating, updateReviewByHost };
};

export default useReviewByHostMutations;
