import useIsMobileScreen from 'hooks/useIsMobileScreen';
import LoginDesktop from './loginDesktop/LoginDesktop';
import LoginMobile from './loginMobile/LoginMobile';

const Login = () => {
  const isMobile = useIsMobileScreen();

  return isMobile ? <LoginMobile /> : <LoginDesktop />;
};

export default Login;
