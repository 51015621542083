import * as Yup from 'yup';

const templateFormSchema = () => ({
  to: Yup.string(),
  content: Yup.string().required(),
  subject: Yup.string(),
  propertyUid: Yup.string(),
  uid: Yup.string(),
  channel: Yup.string().required(),
});

export default templateFormSchema;
