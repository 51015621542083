import { useTranslation } from 'react-i18next';
import { get, useFormState } from 'react-hook-form';
import CheckboxGroupField from 'components/forms/checkboxGroupField/CheckboxGroupField';

const AllowBookingRequestField = () => {
  const { t } = useTranslation();
  const { dirtyFields, errors } = useFormState();

  const isValidMaximumStay =
    !!get(dirtyFields, 'airbnbSettings.allowBookingRequestAboveMaximumStay') &&
    !get(errors, 'airbnbSettings.allowBookingRequestAboveMaximumStay');
  const isValidBookingLeadTime =
    !!get(
      dirtyFields,
      'airbnbSettings.allowBookingRequestWithinBookingLeadTime',
    ) &&
    !get(errors, 'airbnbSettings.allowBookingRequestWithinBookingLeadTime');

  return (
    <CheckboxGroupField
      name="airbnbSettings.allowBookingRequest"
      label={t('pageProperty.mainSettings.airbnbSettings.allowBookingRequest')}
      colSmInput={7}
      colSmLabel={5}
      hideParentCheckbox
      isValid={isValidMaximumStay || isValidBookingLeadTime}
      options={[
        {
          name: 'airbnbSettings.allowBookingRequestAboveMaximumStay',
          label: t('pageProperty.mainSettings.airbnbSettings.aboveMaximumStay'),
        },
        {
          name: 'airbnbSettings.allowBookingRequestWithinBookingLeadTime',
          label: t(
            'pageProperty.mainSettings.airbnbSettings.withinBookingLeadTime',
          ),
        },
      ]}
    />
  );
};

export default AllowBookingRequestField;
