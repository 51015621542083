import styled from 'styled-components';

export const EmployeeLabelAvatar = styled.img.attrs({
  className: 'img-circle',
  'data-testid': 'employee-select-label-avatar',
})`
  border: 1px solid #aeaca8;
  height: 40px;
  position: relative;
  width: 40px;
  top: -10px;
`;
