import { Row } from '@tanstack/react-table';
import { PropertyInternalFee } from 'models/PropertyFees';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import { useTranslation } from 'react-i18next';

const AppliesToCell = ({ row }: { row: Row<PropertyInternalFee> }) => {
  const { t } = useTranslation();
  const { property } = usePropertySettingsInitData();
  const { metadata } = row.original;

  if (metadata.appliesToAllProperties) {
    return t(
      'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.allProperties',
    );
  }

  if (metadata.propertiesThatShareFee.length > 0) {
    return t(
      'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.amountProperties',
      // Sum the count of properties that share the fee and the current property
      { count: metadata.propertiesThatShareFee.length + 1 },
    );
  }

  return property?.name;
};

export default AppliesToCell;
