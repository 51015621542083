import { useTranslation } from 'react-i18next';
import { normalizeKey } from 'utils/localeUtils';
import { AmenityCategory, AvailableAmenity } from 'models/Amenity';
import useScreenBreakpoints from 'hooks/useScreenBreakpoints';
import {
  AmenitiesList,
  AmenityCategoryCollapse,
  AmenitySubCategorySection,
  AmenitySubCategoryTitle,
} from './CategorizedAmenities.styles';
import AmenityItem from './AmenityItem';

const CategoryCollapseSection = ({
  category,
  type,
}: {
  type: AmenityCategory;
  category: Record<string, AvailableAmenity[]>;
}) => {
  const { isXS } = useScreenBreakpoints();
  const { t } = useTranslation();

  const amountOfColumns = isXS ? 2 : 3;

  if (!category) {
    return null;
  }

  return (
    <AmenityCategoryCollapse
      variant="card"
      label={t(`amenities.categories.${type}`)}
      defaultExpanded
      data-testid={`amenities-collapse-${type}`}
    >
      {Object.entries(category).map(([subCategory, amenities]) => {
        const totalAmenities = amenities.reduce((acc, amenity) => {
          return acc + 1 + (amenity.children?.length || 0);
        }, 0);

        const amountOfRows = Math.ceil(totalAmenities / amountOfColumns);

        return (
          <AmenitySubCategorySection
            key={subCategory}
            data-testid={`amenities-${type}-subCategory-${subCategory}`}
          >
            <AmenitySubCategoryTitle>
              {t(normalizeKey(`amenities.subCategories.${subCategory}`))}
            </AmenitySubCategoryTitle>
            <AmenitiesList $amountOfRows={amountOfRows}>
              {amenities.map((amenity, index) => {
                const isTheLastInItsRow = (index + 1) % 3 === 0;
                const columnPosition = index % 3;

                return (
                  <AmenityItem
                    columnPosition={columnPosition}
                    isTheLastInItsRow={isTheLastInItsRow}
                    key={amenity.amenityType}
                    amenity={amenity}
                  />
                );
              })}
            </AmenitiesList>
          </AmenitySubCategorySection>
        );
      })}
    </AmenityCategoryCollapse>
  );
};

export default CategoryCollapseSection;
