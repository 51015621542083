import { useContext } from 'react';
import { useWatch } from 'react-hook-form';
import useAppUser from '../../../../hooks/useAppUser';
import TagsSelectField from '../../../forms/domain/tag/TagsSelectField';
import { TagType } from '../../../../models/Tags';
import LeadModalContext from './LeadModalContext';

const LeadModalHeaderTags = () => {
  const { isEmployee } = useAppUser();
  const { availableTags } = useContext(LeadModalContext);
  const { uid: leadUid } = useWatch();

  if (!isEmployee || !leadUid) {
    return null;
  }

  const saveOnChangeParams = {
    entityUid: leadUid,
    tagType: TagType.LEAD,
  };

  return (
    <TagsSelectField
      className="tags-selector"
      colSmInput={12}
      name="tags"
      options={availableTags}
      saveOnChange
      saveOnChangeParams={saveOnChangeParams}
    />
  );
};

export default LeadModalHeaderTags;
