import { useContext } from 'react';
import LeadModalContext from 'components/domain/lead/modal/LeadModalContext';
import useFormatCurrency from 'hooks/useFormatCurrency';
import { useTranslation } from 'react-i18next';

const Services = () => {
  const { quote } = useContext(LeadModalContext);
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation();

  return (
    <>
      {quote.services.map((orderService, i) => {
        return (
          <tr key={orderService.title}>
            <td>{i === 0 && t('componentOrder.services')}:</td>
            <td>{orderService.title}</td>
            <td />
            <td>
              {formatCurrency({
                value: orderService.grossPrice,
                currency: quote.currency,
              })}
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default Services;
