import styled from 'styled-components';
import leftArrowDark from 'assets/images/calendar/left-arrow-dark.svg';
import leftArrowLight from 'assets/images/calendar/left-arrow-light.svg';
import rightArrowDark from 'assets/images/calendar/right-arrow-dark.svg';
import rightArrowLight from 'assets/images/calendar/right-arrow-light.svg';
import theme from 'styles/theme';
import { stackedCalendarStyleConstants } from '../Calendar.constants';
import mediaQuery from '../../../styles/mediaQuery';

export const NavigationContainer = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: start;
  position: sticky;
  top: -20px;
  z-index: 990;
`;

export const NavigationPropertyCellStyled = styled.div.attrs({
  'data-testid': 'navigation-property-cell',
})`
  align-items: end;
  background-color: #fff;
  border-bottom: ${stackedCalendarStyleConstants.border};
  display: flex;
  flex: 1 0 ${stackedCalendarStyleConstants.leftPanelWidth}px;
  font-weight: bold;
  height: 100px;
  padding-left: 38px;
  padding-bottom: 11px;

  ${mediaQuery.xs} {
    flex: 1 0 ${stackedCalendarStyleConstants.leftPanelWidthMobile}px;
    justify-content: center;
    padding-left: 0;
    padding-right: 30px;
  }
`;

export const NavigationDatesWrapper = styled.div`
  border-top: ${stackedCalendarStyleConstants.border};
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const NavigationDatesWrapperScrollable = styled.div.attrs({
  id: 'navigation-dates-scrollable',
})`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  transform: translate3d(0, 0, 0);
`;

export const NavigationDatesInnerWrapper = styled.div<{ $width: number }>`
  display: flex;
  flex: 0 0 ${({ $width }) => $width}px;
  flex-direction: column;
  overflow: hidden;
`;

export const NavigationMonthsContainer = styled.div`
  display: flex;
  height: 50px;
`;

export const NavigationDaysContainer = styled.div`
  border-bottom: ${stackedCalendarStyleConstants.border};
  display: flex;
  height: 49px;
`;

export const NavigationButtonLeft = styled.span`
  flex: 0 0 25px;
  width: 25px;
  background: #f0f0f0 url(${leftArrowDark}) no-repeat center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  left: 195px;
  top: 0;
  height: 100px;
  border-radius: 5px 0px 0px 5px;
  z-index: 1010;

  :hover {
    background: #337ab7 url(${leftArrowLight}) no-repeat center;
  }

  ${mediaQuery.xs} {
    left: 135px;
  }
`;

export const NavigationButtonRight = styled.span`
  flex: 0 0 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: -25px;
  height: 100px;
  border-radius: 0px 5px 5px 0px;
  background: #f0f0f0 url(${rightArrowDark}) no-repeat center;
  background-position: center;

  :hover {
    background: #337ab7 url(${rightArrowLight}) no-repeat center;
  }

  ${mediaQuery.xs} {
    right: 0;
  }
`;

export const NavigationMonthStyled = styled.span`
  font-weight: 700;
  font-size: 16px;
  color: #555;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0px;
  height: 100px;
  padding-left: 15px;
  padding-top: 15px;
  font-family: SourceSansPro_regular, sans-serif;
  white-space: nowrap;
  text-overflow: clip;
`;

export const NavigationDayMonthStyled = styled(NavigationMonthStyled)`
  border-left: 1px solid #e9e9e9;
  left: -5px;
  top: -50px;
`;

export const NavigationDayCellStyled = styled.div<{
  $isToday: boolean;
  $width: number;
}>`
  flex: 0 1 ${({ $width }) => $width}px;
  background-color: ${({ $isToday }) =>
    $isToday ? theme.colors.bgGreen : 'transparent'};
  padding-bottom: ${({ $isToday }) => ($isToday ? 0 : 10)}px;
  margin-bottom: ${({ $isToday }) => ($isToday ? 10 : 0)}px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
`;

export const NavigationDayCellDayName = styled.span<{ $isToday: boolean }>`
  color: ${({ $isToday }) => ($isToday ? '#fff' : '#999')};
  font-family: SourceSansPro_regular, sans-serif;
  text-transform: uppercase;
`;

export const NavigationDayCellDayNumber = styled.span<{ $isToday: boolean }>`
  color: ${({ $isToday }) => ($isToday ? '#fff' : '#000')};
  font-family: SourceSansPro_light, sans-serif;
`;

export const NavigationPropertyPlaceholder = styled.div`
  min-width: ${stackedCalendarStyleConstants.leftPanelWidth}px;
  height: 100px;
  display: flex;
  align-items: flex-end;
  padding-left: 38px;
  padding-bottom: 11px;
  border-bottom: 0.5px solid rgb(221, 221, 221);
`;
