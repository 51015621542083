import { PipelineLeadsListContainer } from '../PipelineLeadsList.styles';
import {
  PipelineLeadListItemStyled,
  PipelineLeadListItemContentWrapper,
  PipelineLeadListItemDetailsColumn,
  PipelineLeadListItemTagsColumn,
} from '../PipelineLeadListItem.styles';
import LoadingPlaceholder from '../../../components/loader/LoadingPlaceholder';
import LeadListItemBasicInfoLoadingPlaceholder from '../../../components/domain/lead/list/LeadListItemBasicInfoLoadingPlaceholder';
import useScreenBreakpoints from '../../../hooks/useScreenBreakpoints';
import { PipelineLeadListItemStatusesLoadingPlaceholderColumn } from './PipelineLeadsListLoader.styles';

const PipelineLeadsListLoader = () => {
  const { isSM, isXS } = useScreenBreakpoints();

  const isSmallScreen = isXS || isSM;

  return (
    <PipelineLeadsListContainer>
      {Array.from({ length: 5 }).map((_, index) => (
        <PipelineLeadListItemStyled
          // eslint-disable-next-line react/no-array-index-key
          key={`leads-list-item-loading-placeholder-${index}`}
        >
          <PipelineLeadListItemContentWrapper>
            <PipelineLeadListItemDetailsColumn xs={9} md={5}>
              <div>
                <LoadingPlaceholder width="40%" />
              </div>
              <LeadListItemBasicInfoLoadingPlaceholder />
            </PipelineLeadListItemDetailsColumn>
            {!isSmallScreen && (
              <PipelineLeadListItemStatusesLoadingPlaceholderColumn md={5}>
                <LoadingPlaceholder circle width="70px" />
                <LoadingPlaceholder circle width="70px" />
                <LoadingPlaceholder circle width="70px" />
                <LoadingPlaceholder circle width="70px" />
              </PipelineLeadListItemStatusesLoadingPlaceholderColumn>
            )}
            <PipelineLeadListItemTagsColumn xs={3} md={2}>
              <LoadingPlaceholder
                linesCount={2}
                width={isSmallScreen ? '80%' : '40%'}
              />
              {isSmallScreen && <LoadingPlaceholder circle width="70px" />}
            </PipelineLeadListItemTagsColumn>
          </PipelineLeadListItemContentWrapper>
        </PipelineLeadListItemStyled>
      ))}
    </PipelineLeadsListContainer>
  );
};

export default PipelineLeadsListLoader;
