import useAppModal from '../../../../hooks/useAppModal';
import TemplateModal from './TemplateModal';
import { TemplateModalParams } from './TemplateModal.types';

const templateModalId = 'template-modal';

const useOpenTemplateModal = () => {
  const { openModal } = useAppModal();

  const openTemplateModal = (params) => {
    openModal({
      customContent: (
        <TemplateModal modalId={templateModalId} params={params} />
      ),
      id: templateModalId,
      size: 'lg',
    });
  };

  return {
    createTemplatedModal: (params: TemplateModalParams) => {
      openTemplateModal(params);
    },
    templateModalId,
  };
};

export default useOpenTemplateModal;
