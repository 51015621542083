import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SwitchRegularField from 'components/forms/switchField/SwitchRegularField';
import { RoomType } from 'models/PropertyRooms';

const RoomsAndBedTypesFormPrivateBathroomField = ({
  roomIndex,
}: {
  roomIndex: number;
}) => {
  const { t } = useTranslation();
  const {
    formState: { defaultValues },
  } = useFormContext();
  const roomType = useWatch({ name: `rooms.${roomIndex}.roomType` });

  const initialValue = defaultValues.rooms?.[roomIndex]?.hasPrivateBathroom;

  // for living room the switch should be only displayed if it was set to true in the legacy UI
  if (roomType === RoomType.LIVING_ROOM && !initialValue) {
    return null;
  }

  return (
    <SwitchRegularField
      name={`rooms.${roomIndex}.hasPrivateBathroom`}
      label={t(
        'pageProperty.mainSettings.capacityDetails.setRoomsAndBedTypesModal.hasPrivateBathroom',
      )}
      rightTooltip={t(
        'pageProperty.mainSettings.capacityDetails.setRoomsAndBedTypesModal.hasPrivateBathroomTooltip',
      )}
      colSmLabel={5}
      colSmInput={7}
    />
  );
};

export default RoomsAndBedTypesFormPrivateBathroomField;
