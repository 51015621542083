import { useTranslation } from 'react-i18next';
import {
  RECURRING_EXPENSE_PERIOD,
  RecurringExpensePeriod,
} from 'models/ownerAdjustments/OwnerAdjustmentSchedule';

type OptionType = { label: string; value: RecurringExpensePeriod };

const useRecurringPeriodOptions = (): OptionType[] => {
  const { t } = useTranslation();

  return [
    {
      label: t('pageOwnerAdjustments.modal.form.recurringPeriodOptions.weekly'),
      value: RECURRING_EXPENSE_PERIOD.WEEKLY,
    },
    {
      label: t(
        'pageOwnerAdjustments.modal.form.recurringPeriodOptions.monthly',
      ),
      value: RECURRING_EXPENSE_PERIOD.MONTHLY,
    },
    {
      label: t(
        'pageOwnerAdjustments.modal.form.recurringPeriodOptions.quarterly',
      ),
      value: RECURRING_EXPENSE_PERIOD.QUARTERLY,
    },
    {
      label: t('pageOwnerAdjustments.modal.form.recurringPeriodOptions.yearly'),
      value: RECURRING_EXPENSE_PERIOD.YEARLY,
    },
  ];
};

export default useRecurringPeriodOptions;
