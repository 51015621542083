import styled from 'styled-components';
import FormWithProvider from 'components/forms/form/Form';

export const Container = styled(FormWithProvider)`
  .modal-body {
    display: flex;
    justify-content: center;
  }

  label {
    white-space: nowrap;
    margin-left: -40px;
  }

  select {
    max-width: 270px;
    margin-left: 80px;
  }
`;
