import { ReactNode, useMemo } from 'react';
import { Table } from '@tanstack/react-table';
import { TableContextProps } from './Table.types';
import TableContext from './TableContext';

interface TableContextProviderProps<T> {
  tableInstance: Table<T>;
  children?: ReactNode;
  emptyState?: ReactNode;
  isLoading?: boolean;
}

const TableContextProvider = <T,>({
  tableInstance,
  children,
  emptyState,
  isLoading,
}: TableContextProviderProps<T>) => {
  const contextValue: TableContextProps<T> = useMemo(
    () => ({
      tableInstance,
      emptyState,
      isLoading,
    }),
    [tableInstance, emptyState, isLoading],
  );

  return (
    <TableContext.Provider value={contextValue}>
      {children}
    </TableContext.Provider>
  );
};

TableContextProvider.defaultProps = {
  children: null,
  emptyState: null,
  isLoading: false,
};

export default TableContextProvider;
