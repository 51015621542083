import { useCallback } from 'react';
import { startOfToday, startOfTomorrow } from 'date-fns';
import useAppEvent from 'hooks/useAppEvent';
import {
  AppEventType,
  OpenCreateLeadModalEventPayload,
  OpenUpdateLeadModalEventPayload,
} from 'models/Events';
import { Lead, LeadAvailablePipelineAction } from 'models/Leads';

type EventPayload =
  | OpenCreateLeadModalEventPayload
  | OpenUpdateLeadModalEventPayload; // add "| OtherPayloadType" here if extended with other actions

const useLeadPipelineActionEventPublisher = () => {
  const { publish } = useAppEvent<EventPayload>();

  return useCallback(
    ({ action, lead }: { action: LeadAvailablePipelineAction; lead: Lead }) => {
      if (action === LeadAvailablePipelineAction.REBOOK_AS_NEW_LEAD) {
        const { uid: leadUid } = lead;

        const params = {
          dateFrom: startOfToday(),
          dateTo: startOfTomorrow(),
          leadForRebookingUid: leadUid,
          jobCreationNotAllowed: true,
        };

        publish(AppEventType.OPEN_CREATE_LEAD_MODAL, {
          params,
        });

        return;
      }

      if (action === LeadAvailablePipelineAction.REVIEW) {
        const { uid: leadUid } = lead;

        const params = {
          leadUid,
        };

        publish(AppEventType.OPEN_UPDATE_LEAD_MODAL, {
          params,
        });

        return;
      }

      throw new Error(`Not implemented lead action: ${action}`);
    },
    [publish],
  );
};

export default useLeadPipelineActionEventPublisher;
