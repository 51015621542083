import { useFormContext } from 'react-hook-form';
import GenericFormField from '../genericFormField/GenericFormField';
import { GenericFormFieldBaseProps } from '../genericFormField/GenericFormField.types';
import {
  RangeFieldStyled,
  RangeFieldTrack,
  RangeFieldThumb,
  RangeFieldContainer,
  RangeFieldValues,
} from './RangeField.styles';

export interface RangeFieldProps
  extends Omit<GenericFormFieldBaseProps, 'fieldRenderer'> {
  name: string;
  min: number;
  max: number;
  onChange?: (values: number[]) => void;
  formatMin?: (value: number) => string;
  formatMax?: (value: number) => string;
  disabled?: boolean;
}

const RangeField = ({
  name,
  min,
  max,
  onChange,
  formatMin,
  formatMax,
  disabled,
  ...propsGenericField
}: RangeFieldProps) => {
  const { watch } = useFormContext();

  const handleChange = (values: number[]) => {
    onChange?.(values);
  };

  const minValue = watch(name)[0];
  const maxValue = watch(name)[1];

  const fieldRenderer = ({
    field: { onChange: onChangeField, value },
  }: any) => (
    <RangeFieldContainer data-testid={`range-field-container-${name}`}>
      <RangeFieldStyled
        className="horizontal-slide"
        min={min}
        max={max}
        value={value}
        onChange={(values: number[]) => {
          onChangeField(values);
          handleChange(values);
        }}
        renderThumb={(props, state) => (
          // @ts-ignore
          <RangeFieldThumb {...props} index={state.index} />
        )}
        renderTrack={(props, state) => (
          // @ts-ignore
          <RangeFieldTrack {...props} index={state.index} />
        )}
        disabled={disabled}
      />
      <RangeFieldValues>
        <span data-testid="range-field-min">
          {formatMin ? formatMin(minValue) : minValue}
        </span>
        <span data-testid="range-field-max">
          {formatMax ? formatMax(maxValue) : maxValue}
        </span>
      </RangeFieldValues>
    </RangeFieldContainer>
  );

  return (
    <GenericFormField
      name={name}
      fieldRenderer={fieldRenderer}
      {...propsGenericField}
    />
  );
};

RangeField.defaultProps = {
  onChange: undefined,
  formatMin: undefined,
  formatMax: undefined,
  disabled: undefined,
};

export default RangeField;
