import styled from 'styled-components';
import theme from 'styles/theme';

export const Container = styled.div.attrs({ className: 'container' })`
  .agency-card-container {
    display: flex;
    align-items: center;
    padding: 20px;

    border-bottom: 1px solid #eee;

    &:last-of-type {
      border: none;
    }

    .image {
      cursor: pointer;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      border: 1px solid #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 24px;
      flex-shrink: 0;

      img {
        width: 64px;
        height: 64px;
        border-radius: 50%;
      }
    }

    .info {
      cursor: pointer;
      h2 {
        font-size: 1.6rem;
        color: ${theme.colors.primary};
        margin-bottom: 4px;
      }
      p {
        color: #3333338c;
        font-size: 1rem;
      }
    }
  }
`;
