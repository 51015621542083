import PrivateLayout from 'pages/_layouts/privateLayout/PrivateLayout';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import useAppDispatch from '../hooks/useAppDispatch';
import Forbidden from '../pages/error/Forbidden';
import { setRequestedPath } from '../store/slices/login';
import useHasRouteAccess from './useHasRouteAccess';
import { onlyShowContentRoutes } from './router.constants';

const PrivateRoute = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const hasRouteAccess = useHasRouteAccess()(location.pathname);
  const shouldShowOnlyContent = onlyShowContentRoutes.includes(
    location.pathname,
  );

  // check if this page is being rendered inside an iframe
  if (window.frameElement) {
    window.parent.location.href = '/app/#/logout';
    return null;
  }

  if (!hasRouteAccess) {
    return (
      <PrivateLayout>
        <Forbidden />
      </PrivateLayout>
    );
  }

  // only token presence must be checked as logout might be done in a different tab
  if (localStorage.getItem('access_token')) {
    return (
      <PrivateLayout showOnlyContent={shouldShowOnlyContent}>
        <Outlet />
      </PrivateLayout>
    );
  }

  dispatch(setRequestedPath({ requestedPath: location }));

  return <Navigate to="/login" />;
};

export default PrivateRoute;
