import { Breadcrumb, Col } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import Collapse from 'components/collapse/Collapse';
import theme from 'styles/theme';

export const CollapseSectionStyled = styled(Collapse)`
  &:not(:first-child) {
    margin-top: 20px;
  }
`;

export const FormFooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
  padding: 20px 10px;
`;

export const FormSectionColumn = styled(Col).attrs({
  md: 6,
  sm: 12,
  xs: 12,
})`
  display: flex;
  flex-direction: column;
`;

export const PropertyPageBreadcrumb = styled(Breadcrumb)`
  align-items: center;
  background-color: #fff;
  display: flex;
  padding: 0;

  & > li {
    position: relative;

    &.active > span:last-child {
      color: ${theme.colors.fontGreen};
    }
  }

  & > li + li {
    margin-left: 25px;
  }

  & > li + li:before {
    content: '>';
    position: absolute;
    top: 50%;
    left: -12px;
    transform: translate(-50%, -50%);
  }

  .form-field-container {
    display: inline-block;
    margin: 2px 0 0 0;
  }

  .hotel-link {
    margin: 0;
    padding: 0;
  }
`;

export const SectionTitleContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
`;

export const AppliesToLabel = styled.label.attrs({
  className: 'control-label',
})<{ $required?: boolean }>`
  text-align: right;
  position: relative;
  float: right;

  ${({ $required }) =>
    $required &&
    css`
      &::after {
        content: '*';
        color: #a94442;
        margin-left: 4px;
        position: absolute;
      }
    `}
`;
