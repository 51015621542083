import useAppMutation from 'hooks/useAppMutation';
import { BaseErrorResponse } from 'models/_base';
import API from 'services/API';
import { SinglePropertyCreationResponse } from './AddSingleProperty.types';
import { SinglePropertyCreationPayload } from './AddSingleProperty.schema';

const useCreateSingleProperty = () => {
  const { mutateAsync: createSingleProperty, isLoading } = useAppMutation<
    SinglePropertyCreationResponse,
    BaseErrorResponse,
    SinglePropertyCreationPayload
  >(async (payload: SinglePropertyCreationPayload) => {
    return API.post<SinglePropertyCreationResponse, BaseErrorResponse>(
      'api/internal/properties/single',
      payload,
    );
  });

  return { isLoading, createSingleProperty };
};

export default useCreateSingleProperty;
