import HostfullyLogo from 'assets/images/hf-logo.svg?react';
import QuestionCircleHelp from 'assets/images/question-circle.svg?react';
import useAppConfig from 'hooks/useAppConfig';
import {
  CustomLogoImage,
  LayoutMobileCustomHeaderStyled,
  LayoutMobileHeaderStyled,
} from './LayoutMobile.styles';

const LayoutMobileHeader = () => {
  const {
    customSettings: { customHeaderContent, logoUrl },
  } = useAppConfig();

  if (customHeaderContent) {
    return (
      <LayoutMobileCustomHeaderStyled
        dangerouslySetInnerHTML={{ __html: customHeaderContent }}
      />
    );
  }

  return (
    <LayoutMobileHeaderStyled>
      {logoUrl ? (
        <CustomLogoImage src={logoUrl} alt="agency logo" />
      ) : (
        <HostfullyLogo />
      )}
      <QuestionCircleHelp className="help-icon" />
    </LayoutMobileHeaderStyled>
  );
};

export default LayoutMobileHeader;
