import styled from 'styled-components';
import { DayCellCommonStyles } from 'pages/calendar/common/cells/DayCellCommon.styles';
import { StackedCalendarMode } from 'pages/stackedCalendar/Calendar.types';

export const Container = styled.div.attrs({ className: 'container' })<{
  $mode: string;
}>`
  ${({ $mode }) =>
    $mode === StackedCalendarMode.PRICING &&
    `
      .day-cell-job,
      .day-cell-lead-container,
      .lead-view-body-standalone {
        opacity: 0.5;
      }
  `}

  ${DayCellCommonStyles()}
`;

export const CalendarHeaderWrapper = styled.div.attrs({
  className: 'container my-header-wrapper',
})`
  > div {
    padding: 16px 0;

    > div {
      padding: 0;
    }
  }

  form {
    div {
      width: 100%;
    }
  }
`;
