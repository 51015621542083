import { Badge } from 'react-bootstrap';
import styled from 'styled-components';
import theme from 'styles/theme';

export const BadgeStyled = styled(Badge)<{
  $backgroundColor?: string;
  $fontColor?: string;
  $outline?: boolean;
}>`
  align-items: center;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ?? theme.colors.gray600};

  border: ${({ $fontColor, $outline }) =>
    $outline ? `1px solid ${$fontColor}` : 'transparent'};

  border-radius: 5px;

  color: ${({ $fontColor }) => $fontColor ?? 'white'};

  display: inline-flex;
  font-size: 1rem;
  font-weight: normal;
  gap: 5px;
  min-width: min-content;
  padding: 5px 10px;

  svg {
    --size: 15px;
    height: var(--size);
    width: var(--size);
  }
`;
