import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const ImageCircle = styled.div<{
  $ringColor?: string;
  $size?: string;
}>`
  --size: ${({ $size }) => $size ?? '60px'};
  min-width: calc(var(--size) + 16px);
  min-height: calc(var(--size) + 16px);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ $ringColor }) => {
    return $ringColor ?? 'transparent';
  }};

  div {
    width: calc(var(--size) + 8px);
    height: calc(var(--size) + 8px);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    background: white;
  }

  img {
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    object-fit: cover;
  }

  ${mediaQuery.sm} {
    margin-top: 0.5em;
    min-width: calc(var(--size) + 5px);
    min-height: calc(var(--size) + 5px);

    div {
      width: var(--size);
      height: var(--size);

      background: white;
    }

    img {
      width: calc(var(--size) - 5px);
      height: calc(var(--size) - 5px);
    }
  }
`;
