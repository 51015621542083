import styled from 'styled-components';

export const Container = styled.div`
  .checkbox {
    text-align: start;
    margin-bottom: 20px;
  }

  .modal-footer {
    border-top: none;
  }
`;

export const EmailsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    width: 100%;
    padding: 0 0 0 12px;
  }

  > label {
    display: flex;
    justify-content: flex-end;
  }

  .dropdown-menu,
  .help-block {
    display: none !important;
  }
`;
