import { UploadFieldListItemProps } from '../UploadField.types';

const LinkListItem = <T,>({
  file,
  isDisabled,
  getName,
  getUrl,
}: UploadFieldListItemProps<T>) => {
  return (
    <>
      {isDisabled && <span>{getName(file)}</span>}
      {!isDisabled && (
        <a href={getUrl(file)} target="_blank" rel="noreferrer">
          {getName(file)}
        </a>
      )}
    </>
  );
};

export default LinkListItem;
