import styled, { css } from 'styled-components';

const ICS_COLOR = '#FFD458';

export const LeadViewContainer = styled.div<{
  width: number;
  isFromICS: boolean;
  $zIndex: number;
}>`
  position: absolute;
  top: 5px;
  width: ${({ width }) => width}px;
  color: ${({ isFromICS }) => (isFromICS ? ICS_COLOR : '#fff')};
  height: 30px;
  left: 63%;
  z-index: ${({ $zIndex }) => $zIndex};
  overflow: hidden;

  & .ghost-drag-lead {
    top: -2000px;
    border-radius: 5px;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
  }
`;

export const LeadViewStyles = css`
  .lead-view-body {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 5px;
    position: absolute;
  }

  .lead-view-name {
    font-style: normal;
    font-family: SourceSansPro_light, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }
`;
