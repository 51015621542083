import { Toast } from 'components/toast/Toast.styles';
import { Glyphicon } from 'react-bootstrap';
import toast from 'react-hot-toast';

const useAppToast = () => {
  const toastSuccess = (message: string) => {
    toast.custom((currentToast) => (
      <Toast toastType="success" data-testid="toast-success">
        {message}
        <Glyphicon
          glyph="remove"
          onClick={() => toast.dismiss(currentToast.id)}
        />
      </Toast>
    ));
  };

  const toastError = (message: string) => {
    toast.custom((currentToast) => (
      <Toast toastType="error" data-testid="toast-error">
        {message}
        <Glyphicon
          glyph="remove"
          onClick={() => toast.dismiss(currentToast.id)}
        />
      </Toast>
    ));
  };

  return { toastSuccess, toastError };
};

export default useAppToast;
