/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { requestedPathKey } from './API.constants';

const baseURL =
  typeof Cypress === 'undefined'
    ? `${window.location.protocol}//${window.location.host}/`
    : Cypress.env('API_URL');

const API = axios.create({
  baseURL,
});

const NO_ACCESS_TOKEN_ENDPOINTS = [
  'auth',
  'auth-exchange',
  'auth-refresh',
  'reset-password',
];

const includeAccessToken = (url: string) => {
  return (
    NO_ACCESS_TOKEN_ENDPOINTS.filter(
      (endpoint) => `${baseURL}/${endpoint}` === url,
    ).length === 0
  );
};

const loginHash = '#/login';
const logoutHash = '#/logout';

function logout() {
  const currentHash = window.location.hash;

  // startsWith because it can handle the redirectUri param
  if (currentHash.startsWith(loginHash)) {
    return; // 401 response on the login page is a failed login attempt and should keep a user on that page
  }

  if (currentHash !== logoutHash) {
    sessionStorage.setItem(requestedPathKey, currentHash);
  }

  window.location.hash = logoutHash; // tokens must be only removed in the Logout component as it also handles other actions that need to be done consistently with that
}

API.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('access_token');
    if (
      accessToken &&
      config.headers &&
      config.url &&
      includeAccessToken(config.url)
    ) {
      config.headers.Authorization = accessToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      (error.response.status === 404 || error.response.status === 403) && // 403 is returned for AuthorizationServiceBadRequestException in core;
      originalRequest.url === `auth-refresh`
    ) {
      logout();
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      if (originalRequest._retry) {
        logout();
      } else {
        originalRequest._retry = true;
        const refreshToken = localStorage.getItem('refresh_token');

        if (refreshToken) {
          const response = await API.post('auth-refresh', {
            grantType: 'REFRESH_TOKEN',
            refreshToken,
          });
          if (response.status === 202) {
            localStorage.setItem('access_token', response.data.accessToken);
            localStorage.setItem('refresh_token', response.data.refreshToken);
            return API(originalRequest);
          }
        }

        logout();
      }
    }

    return Promise.reject(error);
  },
);

export default API;
