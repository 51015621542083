import { useState } from 'react';
import useFormatCurrency from 'hooks/useFormatCurrency';
import ToggleButton from 'components/button/ToggleButton';
import InfoIcon from 'components/icons/InfoIcon';
import AmountField from 'components/domain/lead/modal/tabs/details/order/fields/common/AmountField';
import { FeeAmountType } from 'models/Quote';
import {
  OrderViewPriceDetailsButtonWrapper,
  OrderViewPriceDetailsListItemRowWrapper,
} from './OrderViewPriceDetailsListItemRow.styles';
import EditOrderAdjustmentButton from './actions/EditOrderAdjustmentButton';
import DeleteOrderAdjustmentButton from './actions/DeleteOrderAdjustmentButton';

export interface PriceDetailsItem {
  currency: string;
  label: string | React.ReactNode;
  tooltip?: string;
  amount: number;
  isEditable?: boolean;
  amountType?: FeeAmountType;
  isTotalAmount?: boolean;
  breakdowns?: Pick<PriceDetailsItem, 'label' | 'amount'>[];
  onEdit?: () => void;
  onDelete?: () => void;
  onItemUpdated?: (val: number) => void;
}

const OrderViewPriceDetailsListItemRow = ({
  currency,
  label,
  tooltip,
  amount,
  amountType,
  isTotalAmount,
  breakdowns,
  isEditable,
  onEdit,
  onDelete,
  onItemUpdated,
  ...props
}: PriceDetailsItem) => {
  const formatCurrency = useFormatCurrency();
  const [showBreakdown, setShowBreakdown] = useState(false);

  return (
    <div {...props}>
      <OrderViewPriceDetailsListItemRowWrapper
        $hasBorder={!isTotalAmount}
        $isCapitalized={isTotalAmount}
        $isBold={isTotalAmount}
      >
        <div data-testid="price-details-list-item-title">
          <span>{label}</span>
          {tooltip && (
            <InfoIcon
              id="property-channel-widget-info"
              tooltipPlacement="bottom"
              tooltipText={tooltip}
            />
          )}
          <span>
            {breakdowns?.length > 0 && (
              <ToggleButton
                data-testid="price-details-list-item-toggle-button"
                isExpanded={showBreakdown}
                toggleVertically
                onClick={() => setShowBreakdown(!showBreakdown)}
              />
            )}
          </span>
          <OrderViewPriceDetailsButtonWrapper>
            {onEdit && <EditOrderAdjustmentButton onEdit={onEdit} />}
            {onDelete && <DeleteOrderAdjustmentButton onDelete={onDelete} />}
          </OrderViewPriceDetailsButtonWrapper>
        </div>
        <div
          className="text-right"
          data-testid="price-details-list-item-amount"
        >
          {amountType === FeeAmountType.AMOUNT ? (
            <AmountField
              value={amount}
              isEditable={isEditable}
              currency={currency}
              onItemUpdated={(val) => {
                onItemUpdated(val);
              }}
            />
          ) : (
            `${amount}%`
          )}
        </div>
      </OrderViewPriceDetailsListItemRowWrapper>
      {showBreakdown &&
        breakdowns?.map((breakdown) => (
          <OrderViewPriceDetailsListItemRowWrapper
            key={breakdown.label as string}
            $isChildren
            $hasBorder
            $isBold={false}
          >
            <div>{breakdown.label} </div>
            <div className="text-right">
              {formatCurrency({
                value: breakdown.amount,
                currency,
              })}
            </div>
          </OrderViewPriceDetailsListItemRowWrapper>
        ))}
    </div>
  );
};

OrderViewPriceDetailsListItemRow.defaultProps = {
  isTotalAmount: false,
  breakdowns: [],
  amountType: FeeAmountType.AMOUNT,
  tooltip: null,
  onEdit: null,
  onDelete: null,
  isEditable: false,
  onItemUpdated: () => {},
};

export default OrderViewPriceDetailsListItemRow;
