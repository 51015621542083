import styled from 'styled-components';
import theme from 'styles/theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: 400px;
  border: 1px solid #ccc;
  padding: 36px 24px;
  border-radius: 8px;

  .secondary-info {
    color: #525151;
  }

  h1,
  h2,
  h3,
  ul {
    margin: 0;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 16px;
    line-height: 20px;
  }

  ul {
    text-align: left;
    font-size: 14px;

    margin: 14px 0;
  }

  .partner-picture {
    width: 120px;
    margin-bottom: 8px;
  }

  .user-email {
    display: flex;
    align-items: center;
    margin: 14px 0;

    img {
      margin-right: 16px;
      width: 28px;
    }

    span {
      color: ${theme.colors.primary};
    }
  }

  .trustable-terms {
    h3 {
      font-size: 18px;
      font-weight: 600;
    }

    p {
      line-height: 18px;
      margin: 14px 0;
    }
  }

  .actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
  }
`;
