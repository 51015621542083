import * as React from "react";
const SvgHostfullyWhite = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 2134 2134", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("clipPath", { id: "_clip1" }, /* @__PURE__ */ React.createElement("path", { d: "M1344.42,860.049l268.934,165.253c5.92,3.641 9.531,10.092 9.531,17.036l0,993.156c0,11.046 -8.974,19.999 -20.037,19.999l-268.934,0c-11.062,0 -20.037,-8.953 -20.037,-19.999l-0,-346.815c-0,-11.047 -8.975,-20 -20.037,-20l-454.337,-0c-11.068,-0 -20.04,8.953 -20.04,20l0,346.815c0,11.046 -8.972,19.999 -20.04,19.999l-268.928,0c-11.068,0 -20.04,-8.953 -20.04,-19.999l-0,-993.156c-0,-6.944 3.608,-13.393 9.533,-17.036l268.932,-165.253c9.426,-5.787 21.77,-2.859 27.571,6.551c1.946,3.155 2.972,6.785 2.972,10.485l0,477.12c0,11.046 8.972,20.005 20.04,20.005l454.337,0c11.062,0 20.037,-8.959 20.037,-20.005l-0,-477.12c-0,-11.046 8.975,-19.999 20.037,-19.999c3.716,-0.003 7.346,1.021 10.506,2.963Z" })), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#_clip1)" }, /* @__PURE__ */ React.createElement("rect", { x: 483.582, y: 830.207, width: 1166.17, height: 1252.16, style: {
  fill: "#ffffff"
} }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("clipPath", { id: "_clip2" }, /* @__PURE__ */ React.createElement("path", { d: "M1737.85,822.074l-661.042,-388.54c-6.255,-3.678 -14.023,-3.678 -20.282,0l-661.036,388.54c-9.515,5.589 -21.77,2.423 -27.373,-7.075c-1.811,-3.066 -2.765,-6.565 -2.765,-10.125l0,-306.62c0,-7.064 3.749,-13.607 9.848,-17.191l681.326,-400.462c6.259,-3.679 14.027,-3.679 20.282,-0l681.332,400.462c6.099,3.584 9.845,10.13 9.845,17.191l-0,306.62c-0,11.022 -8.953,19.954 -19.994,19.954c-3.561,-0 -7.064,-0.949 -10.141,-2.754Z" })), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#_clip2)" }, /* @__PURE__ */ React.createElement("rect", { x: 338.483, y: 50.968, width: 1456.37, height: 800.735, style: {
  fill: "#ffffff"
} }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("clipPath", { id: "_clip3" }, /* @__PURE__ */ React.createElement("path", { d: "M1063.98,430.837l-0,-352.934c4.039,-0.32 8.144,0.578 11.766,2.698l682.369,400.462c6.119,3.589 9.867,10.13 9.867,17.197l-0,306.614c-0,11.027 -8.964,19.959 -20.026,19.959c-3.569,0 -7.075,-0.954 -10.149,-2.759l-662.061,-388.54c-3.622,-2.12 -7.727,-3.017 -11.766,-2.697Z" })), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#_clip3)" }, /* @__PURE__ */ React.createElement("rect", { x: 1037.11, y: 50.968, width: 757.743, height: 800.735, style: {
  fill: "#ffffff"
} })))));
export default SvgHostfullyWhite;
