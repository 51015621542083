export const BOOKING_WINDOWS = {
  UNLIMITED: -1,
  '1_MONTH': 30,
  '3_MONTHS': 90,
  '6_MONTHS': 180,
  '9_MONTHS': 270,
  '12_MONTHS': 365,
  '18_MONTHS': 547,
  '24_MONTHS': 730,
} as const;

export type BookingWindow =
  (typeof BOOKING_WINDOWS)[keyof typeof BOOKING_WINDOWS];
