const ArrowRight = () => {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="rgb(58, 132, 215);"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.999917 0.999024L1.00036 0.999471L7.00036 6.99947L7.00081 6.99992L7.00036 7.00036L1.00036 13.0004L0.999917 13.0005L0.99947 13.0004L0.999285 12.9999L0.99947 12.9995L6.64644 7.3535L7.00009 6.99992L6.64644 6.64633L0.999439 1.00033L0.999023 0.999917L0.645917 0.645917L0.999917 0.999024Z"
        fill="#212529"
        stroke="#3A84D7"
      />
    </svg>
  );
};

export default ArrowRight;
