import { isNotSelectAll } from 'components/forms/multiSelectField/MultiSelectField.constants';
import { BaseOption } from 'components/forms/multiSelectField/MultiSelectField.types';
import { PROPERTIES_SELECTION } from './PropertyMultiSelectFilterField.constants';

export const extractPropertiesMultiSelectFields = (
  fieldName: string,
  formValues: Record<string, unknown>,
) => {
  const propertyRadioButton = formValues[fieldName] as string | undefined;
  const propertiesMultiSelect = formValues[`${fieldName}Properties`] as
    | BaseOption[]
    | undefined;

  const updateAllProperties =
    propertyRadioButton === PROPERTIES_SELECTION.ALL_PROPERTIES;

  const propertiesUids = propertiesMultiSelect
    ?.filter(isNotSelectAll)
    .map((o) => o.value);

  return { updateAllProperties, propertiesUids };
};
