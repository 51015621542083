import { classNames } from 'utils/classNameUtils';

const lineBreaksBetweenHtmlNodesMatchesCache = {};
const lineBreaksBetweenHtmlNodesRegex = />(\s+)</g;

function getLineBreaksMatch({ messageContent, uid }) {
  if (lineBreaksBetweenHtmlNodesMatchesCache[uid] !== undefined) {
    return lineBreaksBetweenHtmlNodesMatchesCache[uid];
  }

  const match = lineBreaksBetweenHtmlNodesRegex.test(messageContent);

  lineBreaksBetweenHtmlNodesMatchesCache[uid] = match;

  return match;
}

// such dynamic class name generation based on a message content is required as messages from channels (e.g. Airbnb)
// require `white-space: pre-wrap;` in order to display line breaks properly, whereas messages created with PMP templates
// must not have that CSS property as they may contain line breaks between HTML nodes that must be collapsed
export function getInboxMessageContentClassName(params: {
  messageContent: string;
  uid: string;
}) {
  const hasLineBreaks = getLineBreaksMatch(params);

  return classNames({
    'collapse-line-breaks': hasLineBreaks,
  });
}

export function cleanUpMessageContentClassNamesCache() {
  // just in order to avoid memory leaks when not reloading the page for a long time
  if (Object.keys(lineBreaksBetweenHtmlNodesMatchesCache).length > 2000) {
    Object.keys(lineBreaksBetweenHtmlNodesMatchesCache).forEach((key) => {
      delete lineBreaksBetweenHtmlNodesMatchesCache[key];
    });
  }
}
