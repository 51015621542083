import { LoadingPlaceholderStyled } from './LoadingPlaceholder.styles';

const LoadingPlaceholder = ({
  circle,
  linesCount,
  margin,
  noAnimation,
  width,
}: {
  circle?: boolean;
  linesCount?: number;
  margin?: string;
  noAnimation?: boolean;
  width?: string;
}) => {
  return (
    <LoadingPlaceholderStyled
      $circle={circle}
      $linesCount={linesCount}
      $margin={margin}
      $noAnimation={noAnimation}
      $width={width}
    >
      {'\u200c'}
    </LoadingPlaceholderStyled>
  );
};

LoadingPlaceholder.defaultProps = {
  circle: false,
  linesCount: 1,
  margin: '',
  noAnimation: false,
  width: '',
};

export default LoadingPlaceholder;
