export enum Channel {
  'airbnb' = 'airbnb',
  'booking_com' = 'booking_com',
  'golightly' = 'golightly',
  'google' = 'google',
  'homads' = 'homads',
  'homeaway' = 'homeaway',
  'hometogo' = 'hometogo',
  'hvmi' = 'hvmi',
  'orbirental' = 'orbirental',
  'tripadvisor' = 'tripadvisor',
  'vacaymyway' = 'vacaymyway',
}

export interface ChannelSettings {
  enumId: Channel;
  externalLink?: string;
  isActive: boolean;
  name: string;
}

export type ChannelSettingsResponseTO = ChannelSettings[];

export interface ChannelSettingsUpdatePayload {
  channelName: string;
  link: string;
  propertyUid: string;
  successCallback?: Function;
}

export enum VrboCancellationPolicy {
  FIRM = 'FIRM',
  MODERATE = 'MODERATE',
  NO_REFUND = 'NO_REFUND',
  RELAXED = 'RELAXED',
  STRICT = 'STRICT',
}
