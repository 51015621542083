import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';

interface FormattedDateCellProps {
  getValue: () => Date | string | null;
}

const FormattedDateCell = ({ getValue }: FormattedDateCellProps) => {
  const { t } = useTranslation();
  const value = getValue();
  const date = typeof value === 'string' ? parseISO(value) : value;

  return date ? format(date, 'yyyy-MM-dd') : t('common.notApplicable');
};

export default FormattedDateCell;
