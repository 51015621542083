import { useTranslation } from 'react-i18next';
import useFormatCurrency from 'hooks/useFormatCurrency';
import { UpgradePlanModalPricePerPropertyStyled } from './UpgradePlanModal.styles';

interface UpgradePlanModalPricePerPropertyProps {
  planPrice: number;
  propertyCount: number;
}

const UpgradePlanModalPricePerProperty = ({
  planPrice,
  propertyCount,
}: UpgradePlanModalPricePerPropertyProps) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();

  return (
    <UpgradePlanModalPricePerPropertyStyled data-testid="per-property-price">
      <p>{t('componentProperty.upgradePlanModal.rateWillAdjust')}</p>
      {propertyCount >= 20 && (
        <p>
          {t('componentProperty.upgradePlanModal.pricePerProperty', {
            propertyCount,
            pricePerProperty: formatCurrency({
              currency: 'USD',
              value: planPrice / propertyCount,
            }),
          })}
        </p>
      )}
    </UpgradePlanModalPricePerPropertyStyled>
  );
};

export default UpgradePlanModalPricePerProperty;
