import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import useAppUser from 'hooks/useAppUser';
import useAppConfig from 'hooks/useAppConfig';

const userVoiceProperties = {
  // eslint-disable-next-line no-script-url
  href: 'javascript:void(0)',
  'data-uv-lightbox': 'classic_widget',
  'data-uv-mode': 'feedback',
  'data-uv-primary-color': '#2c2ca8',
  'data-uv-link-color': '#3fc59f',
  'data-uv-forum-id': '271343',
};

const useIntercomUserVoice = () => {
  const { user } = useAppUser();
  const { showIntercom } = useAppConfig();
  const { boot } = useIntercom();

  useEffect(() => {
    // loads Intercom and UserVoice

    // Intercom
    if (showIntercom) {
      boot({
        name: user.firstName,
        customAttributes: {
          name: user.firstName,
          email: user.email,
          created_at: user.created,
          plan: user.agency.pricingPlanType,
          agency: user.agency.name,
          website: user.agency.website,
          agency_id: user.agency.uid,
          source: 'cluster',
        },
      });
    }

    // UserVoice
    const uv = document.createElement('script');
    uv.type = 'text/javascript';
    uv.async = true;
    uv.src =
      'https://hostfully.uservoice.com/widget_environment/gyzodBYiFk7jcbHcPKLf7A.js';
    document.body.appendChild(uv);

    return () => {
      document.body.removeChild(uv);
    };
  }, []);

  return { userVoiceProperties };
};

export default useIntercomUserVoice;
