import { formatISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import API from 'services/API';
import { OWNER_ADJUSTMENT_TYPE } from 'models/ownerAdjustments/OwnerAdjustment';
import { ONE_TIME_ADJUSTMENTS_BASE_QUERY_KEY } from 'pages/ownerAdjustments/tables/OneTimeAdjustmentsTable.utils';
import { RECURRING_ADJUSTMENTS_BASE_QUERY_KEY } from 'pages/ownerAdjustments/tables/RecurringAdjustmentsTable.utils';
import {
  ADJUSTMENT_TYPES,
  OwnerAdjustmentFormData,
} from '../OwnerAdjustmentModal.types';

const getRequestBody = (ownerAdjustment: OwnerAdjustmentFormData) => {
  const commonData = {
    ...(ownerAdjustment.uid ? { uid: ownerAdjustment.uid } : {}),
    propertyUid: ownerAdjustment.propertyUid,
    type: OWNER_ADJUSTMENT_TYPE.OTHER,
    name: ownerAdjustment.name,
    amount: ownerAdjustment.amount,
    currency: ownerAdjustment.currency,
    notes: ownerAdjustment.notes,
    taxationRate: ownerAdjustment.taxationRate,
    attachedImageName: ownerAdjustment.attachedImageName,
    attachedImageURL: ownerAdjustment.attachedImageURL,
  };

  if (ownerAdjustment.adjustmentType === ADJUSTMENT_TYPES.ONE_TIME) {
    return {
      ...commonData,
      dateAccountedFor: formatISO(ownerAdjustment.dateAccountedFor, {
        representation: 'date',
      }),
    };
  }

  return {
    ...commonData,
    recurringExpensePeriod: ownerAdjustment.recurringExpensePeriod,
    nextScheduled: formatISO(ownerAdjustment.nextScheduled, {
      representation: 'date',
    }),
    endRecurringSchedule: ownerAdjustment.endRecurringSchedule
      ? formatISO(ownerAdjustment.endRecurringSchedule, {
          representation: 'date',
        })
      : null,
  };
};

const saveOwnerAdjustment = async (
  ownerAdjustment: OwnerAdjustmentFormData,
) => {
  const body = getRequestBody(ownerAdjustment);
  const url =
    ownerAdjustment.adjustmentType === ADJUSTMENT_TYPES.RECURRING
      ? '/api/internal/owner-adjustments/schedules'
      : '/api/internal/owner-adjustments';

  if (body.uid) {
    return API.put(`${url}/${body.uid}`, body);
  }

  return API.post(url, body);
};

const useSaveOwnerAdjustmentMutation = ({
  onSuccess,
}: {
  onSuccess: (data: unknown, variables: OwnerAdjustmentFormData) => void;
}) => {
  const { notifyError } = useNotify();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useAppMutation({
    mutationFn: saveOwnerAdjustment,
    onSuccess,
    onSettled: (savedOwnerAdjustment, error, ownerAdjustmentFormData) => {
      if (
        ownerAdjustmentFormData.adjustmentType === ADJUSTMENT_TYPES.ONE_TIME
      ) {
        queryClient.invalidateQueries([ONE_TIME_ADJUSTMENTS_BASE_QUERY_KEY]);
      } else {
        queryClient.invalidateQueries([RECURRING_ADJUSTMENTS_BASE_QUERY_KEY]);
      }
    },
    onError: () => {
      notifyError(t('pageOwnerAdjustments.modal.errorOnCreation'));
    },
  });
};

export default useSaveOwnerAdjustmentMutation;
