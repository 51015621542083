import styled from 'styled-components';
import { Alert } from 'react-bootstrap';

export const InboxMessageTemplatesContainer = styled.div.attrs({
  'data-testid': 'inbox-message-templates-selector',
})`
  margin-bottom: 2em;
  position: relative;
`;

export const InboxMessageTemplatesListTitle = styled.h3.attrs({
  'data-testid': 'inbox-message-templates-list-title',
})``;

export const InboxMessageTemplatesListWrapper = styled.ul.attrs({
  'data-testid': 'inbox-message-templates-list-wrapper',
})`
  padding-left: 1.5em;
`;

export const InboxMessageTemplateName = styled.li`
  button {
    text-align: start;
    white-space: normal;
  }
`;

export const InboxNoMessageTemplatesInfo = styled(Alert).attrs({
  className: 'text-center',
  'data-testid': 'inbox-message-templates-no-results',
})``;
