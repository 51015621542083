import useAppQuery from '../../../hooks/useAppQuery';
import { Lead } from '../../../models/Leads';
import API from '../../../services/API';
import useAppUser from '../../../hooks/useAppUser';

const useFetchLeads = ({
  conditions,
  fields,
}: {
  conditions: string;
  fields: string;
}) => {
  const { agencyUid } = useAppUser();

  const { data: leads, isInitialLoading: isFetching } = useAppQuery(
    ['leads', conditions, fields],
    async (): Promise<Lead[]> => {
      const leadsResponse = await API.post('/v3/graphql', {
        query: `{leads (agencyUid:"${agencyUid}",${conditions}){
            ${fields}
          }}`,
        variables: {},
      });
      return leadsResponse?.data?.data?.leads?.length
        ? leadsResponse.data.data.leads
        : null;
    },
    {
      enabled: !!agencyUid && !!conditions && !!fields,
    },
  );

  return {
    isFetching,
    leads,
  };
};

export default useFetchLeads;
