import useAppQuery from 'hooks/useAppQuery';
import useAppUser from 'hooks/useAppUser';
import { GetContractsResponseTO } from 'models/bookingDotCom/Contract';
import API from 'services/API';

const useBookingDotComOperationsContracts = () => {
  const { agencyUid } = useAppUser();

  const { data: contracts, isLoading } = useAppQuery(
    [`bookingdotcom-operations-contracts`, agencyUid],
    async () => {
      const response = await API.get<GetContractsResponseTO>(
        `/api/internal/bookingdotcom-operations/contracts`,
      );
      return response.data;
    },
    { enabled: !!agencyUid },
  );

  return { contracts, isLoading };
};

export default useBookingDotComOperationsContracts;
