import { useTranslation } from 'react-i18next';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import { ChannelSettingsUpdatePayload } from 'models/Channels';
import API from 'services/API';

const useChannelSettingsMutations = () => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  const { mutate: updateChannelSettings, isLoading: isUpdating } =
    useAppMutation(
      ({ channelName, link, propertyUid }: ChannelSettingsUpdatePayload) => {
        return API.get('inner-api/channelSetting_put.jsp', {
          params: {
            channelName,
            link,
            propertyUid,
          },
        });
      },
      {
        onSuccess: (response, { successCallback }) => {
          successCallback?.();
        },
        onError: () =>
          notifyError(
            t(
              'componentPropertyChannels.editPropertyUrlModal.errorUpdatePropertyUrl',
            ),
          ),
      },
    );

  return { isUpdating, updateChannelSettings };
};

export default useChannelSettingsMutations;
