import { useTranslation } from 'react-i18next';
import {
  FieldDescriptionsStyled,
  FieldDescriptionStyled,
} from './BulkSavePropertiesModalFieldsDescriptions.styles';

const BulkSavePropertiesModalFieldsDescriptions = ({
  fieldsDescriptions,
}: {
  fieldsDescriptions: Record<string, string>;
}) => {
  const { t } = useTranslation();

  return (
    <FieldDescriptionsStyled data-testid="bulk-save-fields">
      <span data-testid="list-description">
        {t('componentProperty.bulkSaveModal.followingFields')}
      </span>
      <ul>
        {Object.entries(fieldsDescriptions).map(([field, description]) => (
          <li data-testid="field-description" key={field}>
            <span>{field}: </span>
            <FieldDescriptionStyled>{description}</FieldDescriptionStyled>
          </li>
        ))}
      </ul>
    </FieldDescriptionsStyled>
  );
};

export default BulkSavePropertiesModalFieldsDescriptions;
