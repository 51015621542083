import { useContext } from 'react';
import { Glyphicon } from 'react-bootstrap';
import { Header, flexRender } from '@tanstack/react-table';
import TableContext from '../TableContext';
import {
  TableHeaderCellStyled,
  SortingButton,
  TableHeaderCellWrapper,
  TableHeaderCellContent,
} from './TableHeaderCell.styles';

const getAriaSort = (sorting: 'asc' | 'desc' | false) => {
  switch (sorting) {
    case 'asc':
      return 'ascending';
    case 'desc':
      return 'descending';
    default:
      return 'none';
  }
};

interface TableHeaderCellProps {
  header: Header<unknown, unknown>;
}

const TableHeaderCell = ({ header }: TableHeaderCellProps) => {
  const { tableInstance, isLoading } = useContext(TableContext);
  const isEmpty = tableInstance.getRowModel().rows.length === 0;

  return (
    <TableHeaderCellStyled
      aria-sort={getAriaSort(header.column.getIsSorted())}
      className={`column-header column-header-${header.id}`}
      colSpan={header.colSpan}
    >
      <TableHeaderCellWrapper>
        {header.isPlaceholder ? null : (
          <TableHeaderCellContent className="column-header-text">
            {flexRender(header.column.columnDef.header, header.getContext())}
          </TableHeaderCellContent>
        )}
        {header.column.getCanSort() && (
          <SortingButton
            data-testid="sort-button"
            disabled={isEmpty || isLoading}
            onClick={header.column.getToggleSortingHandler()}
          >
            <Glyphicon glyph="triangle-top" />
            <Glyphicon glyph="triangle-bottom" />
          </SortingButton>
        )}
      </TableHeaderCellWrapper>
    </TableHeaderCellStyled>
  );
};

export default TableHeaderCell;
