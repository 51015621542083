import { useState } from 'react';
import useAppMutation from '../../../hooks/useAppMutation';
import API from '../../../services/API';
import { AppEventType } from '../../../models/Events';
import useAppEvent from '../../../hooks/useAppEvent';

const useDeleteLead = (leadUid: string, successCallback?: Function) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const { publish } = useAppEvent();

  const { mutate: deleteLead, isLoading: isDeleting } = useAppMutation(
    () => {
      return API.delete(`/api/v3/leads/${leadUid}`);
    },
    {
      onSuccess: () => {
        publish(AppEventType.LEAD_DELETED, { leadUid });
        successCallback?.();
      },
      onError: ({
        response: {
          data: { apiErrorMessage },
        },
      }) => setErrorMessage(apiErrorMessage),
    },
  );

  return { deleteLead, errorMessage, isDeleting };
};

export default useDeleteLead;
