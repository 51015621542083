import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Rating } from 'react-simple-star-rating';
import { FieldRendererProps } from '../genericFormField/GenericFormField.types';
import GenericFormField from '../genericFormField/GenericFormField';
import { StarRatingFieldProps } from './StarRatingField.types';

/* For properties extensions see the underlying library docs: https://github.com/awran5/react-simple-star-rating#readme */
const StarRatingField: FC<StarRatingFieldProps> = ({
  allowFraction,
  name,
  readonly,
  size,
  ...rest
}) => {
  const { setValue } = useFormContext();

  const changeHandler = (value) => {
    setValue(name, value);
  };

  const fieldRenderer = ({ field: { value } }: FieldRendererProps) => (
    <Rating
      allowFraction={allowFraction}
      allowTitleTag={false}
      className="star-rating"
      emptyClassName="star-rating-empty"
      fillClassName="star-rating-filled"
      initialValue={value}
      onClick={changeHandler}
      readonly={readonly}
      size={size || 30}
    />
  );

  return (
    <GenericFormField name={name} fieldRenderer={fieldRenderer} {...rest} />
  );
};

export default StarRatingField;
