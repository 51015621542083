import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import { AmenityType, AvailableAmenity } from 'models/Amenity';
import { normalizeKey } from 'utils/localeUtils';
import { AmenityItemChildStyled } from './CategorizedAmenities.styles';
import AmenityDescriptionIcon from './description/AmenityDescriptionIcon';

const AmenityItemChild = ({
  child,
  gridColumn,
  parentAmenityType,
}: {
  child: AvailableAmenity;
  parentAmenityType: AmenityType;
  gridColumn: number | null;
}) => {
  const name = `amenities.${child.amenityType}.value`;
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();
  const amenityChildValue = watch(name);

  useEffect(() => {
    if (!amenityChildValue && child.supportsDescription) {
      setValue(`amenities.${child.amenityType}.description`, null);
    }
  }, [amenityChildValue]);

  return (
    <AmenityItemChildStyled
      $gridColumn={gridColumn}
      key={child.amenityType}
      data-testid={`amenity-type-${parentAmenityType}-child-${child.amenityType}`}
    >
      <CheckboxField name={name}>
        {t(normalizeKey(`amenities.types.${child.amenityType}`))}
      </CheckboxField>

      {child.supportsDescription && amenityChildValue && (
        <AmenityDescriptionIcon amenity={child} />
      )}
    </AmenityItemChildStyled>
  );
};

export default AmenityItemChild;
