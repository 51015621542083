import styled from 'styled-components';

export const PhoneNumberFieldWrapper = styled.div.attrs({
  className: 'phone-number-field-wrapper form-group',
})`
  .menu-container {
    // width of the menu container
    width: 100%;
    min-width: 240px;
    text-align: left;
    z-index: 999;
  }

  .control-container {
    border: none;
    background: none;
    min-height: 32px;
    flex-wrap: nowrap;
  }

  .input-group-addon {
    padding: 0;
  }

  .indicators-container {
    // class finish with indicatorContainer
    [class$='indicatorContainer'] {
      padding: 0;
    }
  }

  .single-value-container {
    overflow: visible;
  }
`;
