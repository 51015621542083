import { FC, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import ReactSelect from 'react-select';
import TextField from '../textField/TextField';
import {
  countryPhoneCodes,
  countryPhoneCodesWithFlagAndName,
} from '../../../utils/constants/countryPhoneCodes';
import { PhoneNumberFieldProps } from './PhoneNumberField.types';
import { PhoneNumberFieldWrapper } from './PhoneNumberField.styles';

const countryPhoneCodesWithFlagAndNameUSFirst = [
  countryPhoneCodesWithFlagAndName.find(({ id }) => id === 'United States'),
  ...countryPhoneCodesWithFlagAndName.filter(
    ({ id }) => id !== 'United States',
  ),
];

const PhoneNumberField: FC<PhoneNumberFieldProps> = ({
  name,
  placeholder,
  ...rest
}) => {
  const { setValue } = useFormContext();
  const currentValue = useWatch({ name });
  const currentAreaCode = useWatch({ name: `${name}_areaCode` });
  const currentBaseNumber = useWatch({ name: `${name}_base` });

  useEffect(() => {
    if (currentValue) {
      const areaCode = countryPhoneCodes.find(({ dial_code }) =>
        currentValue.startsWith(dial_code),
      )?.dial_code;

      setValue(`${name}_areaCode`, areaCode);
      setValue(`${name}_base`, currentValue.replace(areaCode, ''));
    } else {
      setValue(`${name}_areaCode`, '+1');
    }
  }, []);

  useEffect(() => {
    if (currentAreaCode && currentBaseNumber) {
      setValue(name, `${currentAreaCode}${currentBaseNumber}`);
    } else {
      setValue(name, null);
    }
  }, [currentAreaCode, currentBaseNumber]);

  const onChangeAreaCode = (value: {
    value: string;
    label: string;
    id: string;
  }) => {
    setValue(`${name}_areaCode`, value.value);
  };

  const defaultValueAreaCode = countryPhoneCodesWithFlagAndNameUSFirst.find(
    ({ id }) => id === 'United States',
  );

  const valueAreaCode = currentAreaCode
    ? countryPhoneCodesWithFlagAndNameUSFirst.find(({ value }) => {
        return value === currentAreaCode;
      }) || defaultValueAreaCode
    : defaultValueAreaCode;

  const formattedValueAreaCode = {
    ...valueAreaCode,
    label: valueAreaCode.label?.slice(0, valueAreaCode.label.indexOf('(') - 1),
  };

  return (
    <PhoneNumberFieldWrapper>
      <TextField
        groupName={name}
        name={`${name}_base`}
        placeholder={placeholder || '512 345 678'}
        leftAddon={
          <ReactSelect
            isSearchable
            id={`${name}_areaCode`}
            value={formattedValueAreaCode}
            onChange={onChangeAreaCode}
            options={countryPhoneCodesWithFlagAndNameUSFirst}
            classNames={{
              indicatorsContainer: () => 'indicators-container',
              control: () => 'control-container',
              menu: () => 'menu-container',
              singleValue: () => 'single-value-container',
            }}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        }
        {...rest}
      />
    </PhoneNumberFieldWrapper>
  );
};

export default PhoneNumberField;
