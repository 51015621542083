import PageHeader from 'components/pageHeader/PageHeader';
import { useTranslation } from 'react-i18next';
import PropertyCard from 'components/propertyCard/PropertyCard';
import useAppUser from 'hooks/useAppUser';
import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';
import { Container, PropertiesCardWrapper } from './Properties.styles';
import { GetAllOwnerPropertiesResponseTO } from './Properties.types';

const Properties = () => {
  const { t } = useTranslation();
  const { isOwner, userUid } = useAppUser();

  const { data: properties } = useAppQuery(
    ['properties', userUid],
    async () => {
      const response = await API.post<GetAllOwnerPropertiesResponseTO>(
        '/v3/graphql',
        {
          query: `{
            properties(offset:"0", limit:"500", includeDeleted:true){
                uid,
                name,
                numberOfSubUnits,
                tags,
                isActive,
                isDeleted,
                businessType,
                ownerNotes,
                directBookingSiteUrl,
                webLink,
                address{
                    address,
                    address2,
                    zipCode,
                    city,
                    state
                },
                availability{
                  maxGuests
                }
                bathroomsNumber,
                bedroomsNumber,
                directBookingSiteUrl,
                mainPicture{
                    __typename,
                    largeThumbnail
                }
            }
        }`,
          variables: {},
        },
      );
      return response.data.data.properties;
    },
    { enabled: isOwner },
  );

  return (
    <Container>
      <PageHeader
        title={`${t('common.properties')} ${
          properties ? `(${properties?.length})` : ''
        }`}
      />

      <PropertiesCardWrapper>
        {properties?.map(
          ({
            address,
            availability,
            bathroomsNumber,
            bedroomsNumber,
            webLink,
            mainPicture,
            name,
          }) => (
            <PropertyCard
              key={name}
              name={name}
              imageUrl={mainPicture?.largeThumbnail}
              address={`${address.address}, ${address.city}, ${address.state} ${address.zipCode}`}
              aditionalInformation={`${bedroomsNumber} ${t(
                'pageOwnerAccount.acronymBedrooms',
              )} - ${bathroomsNumber} ${t(
                'pageOwnerAccount.acronymBathrooms',
              )} | ${availability.maxGuests} ${t('pageOwnerAccount.guestMax')}`}
              rentalUrl={webLink}
            />
          ),
        )}

        {properties?.length === 0 && (
          <p>{t('pageOwnerAccount.noPropertiesWereFound')}</p>
        )}
      </PropertiesCardWrapper>
    </Container>
  );
};

export default Properties;
