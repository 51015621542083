import API from 'services/API';
import useAppQuery from 'hooks/useAppQuery';
import { GetPinCodesResponseTO, PinCode } from 'models/PinCode';

const useFetchPinCodes = (leadUid: string) => {
  const { data: pinCodes, isInitialLoading: isFetching } = useAppQuery(
    ['pin-code-lead', leadUid],
    async (): Promise<PinCode[]> => {
      const response = await API.get<GetPinCodesResponseTO>(
        `api/v3.1/pin-codes?leadUid=${leadUid}`,
        {
          headers: {
            'X-VERSION': '3.1',
          },
        },
      );
      return response.data?.pincodes;
    },
    {
      enabled: !!leadUid,
    },
  );

  return { isFetching, pinCodes };
};

export default useFetchPinCodes;
