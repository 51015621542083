import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import TextField from 'components/forms/textField/TextField';
import { FormFieldsStyled } from '../common/CommonModal.styles';

const AddSubUnitsModalFormFields = () => {
  const { t } = useTranslation();

  return (
    <FormFieldsStyled>
      <TextField
        name="units"
        type="number"
        label={t('pageProperties.actions.addSubUnitsModal.inputUnitsLabel')}
        rightAddons={[
          t('pageProperties.actions.addSubUnitsModal.inputUnitsRightAddon'),
        ]}
        required
      />
      <TextField
        name="tag"
        type="text"
        label={t('pageProperties.actions.addSubUnitsModal.inputTagLabel')}
        maxLength={255}
      />

      <Row>
        <Col sm={10} smOffset={2}>
          <CheckboxField name="isNonBookable">
            {t(
              'pageProperties.actions.addSubUnitsModal.inputIsNonBookableLabel',
            )}
          </CheckboxField>
        </Col>
      </Row>
    </FormFieldsStyled>
  );
};

export default AddSubUnitsModalFormFields;
