import { useTranslation } from 'react-i18next';
import FormFooter from 'pages/property/common/FormFooter';

const FormFooterActions = () => {
  const { t } = useTranslation();

  return (
    <FormFooter
      saveButtonLabel={t('pageProperty.amenities.footerActions.saveAmenities')}
    />
  );
};

export default FormFooterActions;
