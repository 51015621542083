import styled from 'styled-components';

export const LoadingPlaceholderWrapper = styled.div`
  text-align: center;
`;

export const CustomTooltipWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  padding: 10px 30px;

  .recharts-tooltip-wrapper {
    z-index: 99;
  }
`;

export const CustomTooltipTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 15px;
`;

export const CustomTooltipContent = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;

  span {
    margin-right: 25px;
  }
`;
