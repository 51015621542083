import { useTranslation } from 'react-i18next';
import { AvailableAmenity } from 'models/Amenity';
import useScreenBreakpoints from 'hooks/useScreenBreakpoints';
import {
  AmenitiesList,
  AmenityCategoryCollapse,
} from '../CategorizedAmenities.styles';
import PopularAmenityItem from './PopularAmenityItem';

const PopularAmenitiesSection = ({
  popularAmenities = [],
}: {
  popularAmenities: AvailableAmenity[];
}) => {
  const { isXS } = useScreenBreakpoints();
  const { t } = useTranslation();

  const amountOfColumns = isXS ? 2 : 3;
  const amountOfRows = Math.ceil(popularAmenities.length / amountOfColumns);

  return (
    <AmenityCategoryCollapse
      variant="card"
      label={t(`amenities.categories.POPULAR`)}
      defaultExpanded
      data-testid="amenities-collapse-POPULAR"
    >
      <AmenitiesList $amountOfRows={amountOfRows}>
        {popularAmenities?.map((amenity) => (
          <PopularAmenityItem key={amenity.amenityType} amenity={amenity} />
        ))}
      </AmenitiesList>
    </AmenityCategoryCollapse>
  );
};

export default PopularAmenitiesSection;
