import Switch from 'components/switch/Switch';
import GenericFormField from '../genericFormField/GenericFormField';
import { GenericFormFieldProps } from '../genericFormField/GenericFormField.types';

interface Props extends Omit<GenericFormFieldProps, 'fieldRenderer'> {}

const SwitchRegularField = ({ className, name, label, ...props }: Props) => {
  const fieldRenderer = ({ field: { onBlur, onChange, value, ref } }) => (
    <Switch
      id={name}
      className={`small${className ? ` ${className}` : ''}`}
      data-testid={name}
      onBlur={onBlur}
      checked={value}
      onChange={onChange}
      ref={ref}
      {...props}
    />
  );

  return (
    <GenericFormField
      label={label}
      name={name}
      fieldRenderer={fieldRenderer}
      {...props}
    />
  );
};

export default SwitchRegularField;
