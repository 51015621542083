import { Row } from '@tanstack/react-table';
import { RentalConditions } from 'models/RentalCondition';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';

const AppliesToCell = ({ row }: { row: Row<RentalConditions> }) => {
  const { property } = usePropertySettingsInitData();

  // Return the correct value in PMP-10866

  return property?.name;
};

export default AppliesToCell;
