import { PayloadAction } from '@reduxjs/toolkit';
import { StackedCalendarState } from '../types';
import { PropertiesDayCellDataMap } from '../../../../pages/stackedCalendar/Calendar.types';

export default function updatePropertiesEntriesReducer(
  state: StackedCalendarState,
  { payload: propertiesEntries }: PayloadAction<PropertiesDayCellDataMap>,
) {
  Object.entries(propertiesEntries).forEach(([propertyUid, dayCellData]) => {
    if (propertyUid in state.propertiesEntries) {
      Object.assign(state.propertiesEntries[propertyUid], dayCellData);
    } else {
      state.propertiesEntries[propertyUid] = dayCellData;
    }
  });
}
