import { ChannelSettings, ChannelSettingsResponseTO } from 'models/Channels';
import useAppUser from '../../../hooks/useAppUser';
import useAppQuery from '../../../hooks/useAppQuery';
import API from '../../../services/API';

const useFetchAgencyChannelSettings = (enabled: boolean = true) => {
  const { isEmployee } = useAppUser();

  const { data: channelSettings, isInitialLoading: isFetching } = useAppQuery(
    ['agency-channel-settings'],
    async (): Promise<ChannelSettings[]> => {
      const response = await API.get<ChannelSettingsResponseTO>(
        '/inner-api/channelSetting_get.jsp',
      );
      return response.data;
    },
    {
      enabled: enabled && isEmployee,
    },
  );

  return { isFetching, channelSettings };
};

export default useFetchAgencyChannelSettings;
