import { call, put, select, takeEvery } from 'redux-saga/effects';
import API from 'services/API';
import { BaseResponse } from 'models/_base';
import { setNewLeadsData } from '../../lead';
import { setUnreadThreads } from '../../inbox';
import {
  GetThreadsResponseTO,
  ThreadReadStatus,
} from '../../../../models/inbox/Threads';
import { ThreadFilterSortOrder } from '../../../../pages/inbox/Inbox.types';
import { getThreadParticipantReadStatus } from '../../../../pages/inbox/threads/InboxThreads.utils';
import { RunSyncCheckAction, SyncCheckActionType } from './syncCheckActions';

interface GetSyncCheckDataResponseTO extends BaseResponse {
  synchronousChecks: {
    inboxSynchronousCheck: {
      unreadThreads: number;
    };
    lastCheckDateTime: string;
    lastCheckTimestamp: number;
    leadsSynchronousCheck: {
      newLeadsCount: number;
      newLeadsUids: string[];
    };
  }[];
}

function isInboxPage() {
  return window.location.href.includes('/inbox');
}

function* runSyncCheck({ payload: { force } }: RunSyncCheckAction) {
  const { isEmployee, isGuest, isOwner, userType } = yield select(
    (state) => state.user.appUser,
  );

  // agency
  if (isEmployee) {
    const syncCheckResponse = yield call(() =>
      API.get<GetSyncCheckDataResponseTO>(
        `/v3/sync-check?timestamp=${new Date().getTime()}`,
        // eslint-disable-next-line no-console
      ).catch((err) => console.error(err)),
    );

    if (syncCheckResponse?.data?.synchronousChecks?.length) {
      const syncCheckData = syncCheckResponse?.data?.synchronousChecks[0];
      const {
        inboxSynchronousCheck: { unreadThreads },
        leadsSynchronousCheck: { newLeadsCount, newLeadsUids },
      } = syncCheckData;

      yield put(setUnreadThreads({ unreadThreads }));
      yield put(setNewLeadsData({ newLeadsCount, newLeadsUids }));
    }
  }

  // guest or owner
  if ((isGuest || isOwner) && (force || !isInboxPage())) {
    const unreadThreadsResponse = yield call(() =>
      API.get<GetThreadsResponseTO>('/v3/threads', {
        params: {
          guestAndAgencyCommunicationOnly: true,
          limit: 9999,
          offset: 0,
          showOnlyUnread: true,
          sortBy: ThreadFilterSortOrder.SORT_BY_RECENT_UPDATE_DATE,
        },
        // eslint-disable-next-line no-console
      }).catch((err) => console.error(err)),
    );

    if (unreadThreadsResponse?.data?.threads) {
      const currentUserUnreadThreads =
        unreadThreadsResponse.data.threads.filter(
          (thread) =>
            getThreadParticipantReadStatus(thread, userType)?.readStatus ===
            ThreadReadStatus.UNREAD,
        );

      yield put(
        setUnreadThreads({ unreadThreads: currentUserUnreadThreads.length }),
      );
    }
  }
}

export default function* runSyncCheckSaga() {
  yield takeEvery(SyncCheckActionType.RUN_SYNC_CHECK, runSyncCheck);
}
