import { Row } from '@tanstack/react-table';
import useFormatCurrency from 'hooks/useFormatCurrency';

interface AmountCellProps<T> {
  row: Row<T>;
  getValue: () => number;
}

const AmountCell = <T extends { currency: string }>({
  row,
  getValue,
}: AmountCellProps<T>) => {
  const formatCurrency = useFormatCurrency();

  return formatCurrency({ currency: row.original.currency, value: getValue() });
};

export default AmountCell;
