import { useMemo, useState } from 'react';
import { Lead } from '../../../../models/Leads';
import TemplateModalContext from './TemplateModalContext';

const TemplateModalContextProvider = ({ children, modalId }) => {
  const [lead, setLead] = useState<Lead>();
  const [messageContent, setMessageContent] = useState<HTMLElement>();

  const contextValue = useMemo(
    () => ({
      lead,
      modalId,
      messageContent,
      setLead,
      setMessageContent,
    }),
    [lead, modalId, messageContent],
  );

  return (
    <TemplateModalContext.Provider value={contextValue}>
      {children}
    </TemplateModalContext.Provider>
  );
};

export default TemplateModalContextProvider;
