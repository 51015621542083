import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import useDeletePropertyExpectation from 'components/domain/property/expectation/useDeletePropertyExpectation';
import { PROPERTIES_EXPECTATIONS_BASE_QUERY_KEY } from 'components/domain/property/expectation/propertyExpectationQuery';
import TableContext from 'components/table/TableContext';
import useAppModal from 'hooks/useAppModal';
import { PropertyExpectation } from 'models/PropertyExpectations';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import { handlePaginationAfterDelete } from 'utils/pagination';

interface UseDeletePropertyExpectationModalArgs {
  propertyExpectation: PropertyExpectation;
}

const useDeletePropertyExpectationModal = ({
  propertyExpectation,
}: UseDeletePropertyExpectationModalArgs) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { tableInstance } = useContext(TableContext);
  const { uid: propertyUid } = useParams();
  const { openConfirmModal, setErrorConfirmModal } = useAppModal();

  const { mutateAsync: deletePropertyExpectation } =
    useDeletePropertyExpectation({
      onSuccess: () => {
        queryClient.invalidateQueries([
          PROPERTIES_EXPECTATIONS_BASE_QUERY_KEY,
          'list',
          propertyUid,
        ]);

        handlePaginationAfterDelete(tableInstance);
      },
      onError: (error: any) => {
        setErrorConfirmModal(
          getErrorMessage({
            apiErrorCode: error.response.data?.apiErrorCode,
            apiErrorMessage: error.response.data?.apiErrorMessage,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      },
    });

  const expectationName = t(
    `pageProperty.feesTaxesAndPolicies.propertyExpectations.expectationType.${propertyExpectation.expectationType}`,
  );

  const openPropertyExpectationDeleteModal = () => {
    openConfirmModal({
      body: (
        <>
          {t(
            'pageProperty.feesTaxesAndPolicies.propertyExpectations.deleteModal.question',
            {
              name: expectationName,
            },
          )}
          {/* TODO implement "applies to" message */}
        </>
      ),
      onConfirmAsync: () =>
        deletePropertyExpectation({
          uid: propertyExpectation.uid,
        }),
      title: t(
        'pageProperty.feesTaxesAndPolicies.propertyExpectations.deleteModal.title',
      ),
      confirmLabel: t('common.delete'),
      confirmColor: 'danger',
    });
  };

  return {
    openPropertyExpectationDeleteModal,
  };
};

export default useDeletePropertyExpectationModal;
