import { useContext, useEffect, useState } from 'react';
import useAppQuery from 'hooks/useAppQuery';
import CalendarContext from 'pages/stackedCalendar/CalendarContext';
import API from 'services/API';
import useAppUser from 'hooks/useAppUser';
import { getJobStartDate } from 'utils/job/jobUtils';
import { PropertyBusinessType } from 'models/Properties';
import { format, isSameDay } from 'date-fns';
import CalendarBodyContext from 'pages/calendar/body/CalendarBodyContext';
import StandaloneCalendarContext from 'pages/calendar/CalendarContext';
import { isBetween } from 'utils/dateTimeUtils';
import useCalendarBodyRefreshRequest from '../stackedCalendar/useCalendarBodyRefreshRequest';
import { StandaloneCalendarDataFetchParams } from './Calendar.types';
import { getUnloadedDatesForJobs } from './Calendar.utils';

const useJobsData = () => {
  const { daysCalendar, setDaysCalendar } = useContext(CalendarBodyContext);
  const { calendarFilter } = useContext(StandaloneCalendarContext);
  const { selectedProperty, jobsMap, propertiesMap } =
    useContext(CalendarContext);
  const bodyRefreshRequest = useCalendarBodyRefreshRequest();
  const { agencyUid } = useAppUser();
  const [fetchParams, setFetchParams] =
    useState<StandaloneCalendarDataFetchParams>();

  const { data: jobs, isLoading: isJobsLoading } = useAppQuery(
    [
      'getJobs',
      selectedProperty,
      daysCalendar[selectedProperty]?.daysCalendar?.length,
      agencyUid,
      fetchParams,
    ],
    async () => {
      const { start, end } = fetchParams;

      const property = propertiesMap[selectedProperty];
      const isUnit = property.businessType === PropertyBusinessType.UNIT;
      const propertyToQuery = isUnit ? property.unitTypeUid : selectedProperty;

      if (start && end) {
        const response = await API.get<any>(
          `/api/v3.1/jobs?propertiesUids=${propertyToQuery}&from=${format(
            start,
            'yyyy-MM-dd',
          )}&to=${format(end, 'yyyy-MM-dd')}`,
          {
            headers: {
              'X-VERSION': '3.1',
            },
          },
        );
        return response.data.jobs.filter((job) => job.status !== 'CANCELLED');
      }

      return null;
    },
    { enabled: !!fetchParams },
  );

  useEffect(() => {
    if (!jobs) return;

    // Jobs are loaded, let's prepare lead data in Calendar day
    daysCalendar[selectedProperty]?.daysCalendar.map((day) => {
      if (isBetween(day.date, calendarFilter.start, calendarFilter.end)) {
        day.jobs = [];
      }

      return day;
    });

    for (let j = 0; j < jobs.length; j += 1) {
      const job = jobs[j];

      if (!jobsMap[job.uid]) {
        jobsMap[job.uid] = job;
      }

      if (
        selectedProperty === job.propertyUid ||
        job.unitUid === selectedProperty
      ) {
        daysCalendar[selectedProperty]?.daysCalendar.map((day) => {
          if (isSameDay(getJobStartDate(job), day.date)) {
            day.jobs.push(job);
          }
          return day;
        });
      }
    }
    setDaysCalendar((prevState) => ({
      ...prevState,
      [selectedProperty]: {
        daysCalendar: daysCalendar[selectedProperty]?.daysCalendar,
      },
    }));
  }, [jobs]);

  useEffect(() => {
    if (bodyRefreshRequest) {
      const { properties } = bodyRefreshRequest;

      const unitTypeFromUnit =
        propertiesMap[selectedProperty].unitTypeUid === properties[0];
      if (!properties.includes(selectedProperty) && !unitTypeFromUnit) {
        return;
      }

      setFetchParams({
        ...getUnloadedDatesForJobs(
          daysCalendar[selectedProperty]?.daysCalendar,
        ),
        requestId: new Date().getTime(),
      });
    }
  }, [bodyRefreshRequest]);

  useEffect(() => {
    setFetchParams(
      getUnloadedDatesForJobs(daysCalendar[selectedProperty]?.daysCalendar),
    );
  }, [daysCalendar[selectedProperty]?.daysCalendar?.length]);

  return {
    isLoading:
      isJobsLoading && !daysCalendar[selectedProperty]?.daysCalendar.length,
  };
};

export default useJobsData;
