import { useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { InboxLeadDisplayMode } from '../lead/InboxLead.types';
import InboxMessagesContextProvider from './InboxMessagesContextProvider';
import InboxMessagesBody from './InboxMessagesBody';
import useInboxDisplayLead from './useInboxDisplayLead';

const InboxMessages = () => {
  const displayLead = useInboxDisplayLead(InboxLeadDisplayMode.RIGHT_PANEL);
  const messagesBody = useMemo(() => <InboxMessagesBody />, []);

  return (
    <Col xs={7} sm={8} md={displayLead ? 6 : 9}>
      <InboxMessagesContextProvider>
        {messagesBody}
      </InboxMessagesContextProvider>
    </Col>
  );
};

export default InboxMessages;
