import { useMemo } from 'react';
import InboxContextProvider from './InboxContextProvider';
import InboxBody from './InboxBody';

const Inbox = () => {
  const inboxBody = useMemo(() => <InboxBody />, []);

  return <InboxContextProvider>{inboxBody}</InboxContextProvider>;
};

export default Inbox;
