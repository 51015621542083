import { useContext } from 'react';
import { format } from 'date-fns';
import useAppUser from 'hooks/useAppUser';
import { getUnitType } from 'utils/lead/leadUtils';
import useFeatures from 'hooks/useFeatures';
import { convertQuoteOverrideToLeadQuoteOverride } from 'utils/quote/quoteUtils';
import {
  LeadGuestInformation,
  LeadSavingPayload,
  LeadStatus,
  LeadType,
  LeadUpdateInternalPayload,
} from '../../../../models/Leads';
import LeadModalContext from './LeadModalContext';

function getLeadType(status: LeadStatus) {
  if (status === LeadStatus.BLOCKED) {
    return LeadType.BLOCK;
  }

  if (status === LeadStatus.NEW || status === LeadStatus.ON_HOLD) {
    return LeadType.INQUIRY;
  }

  return LeadType.BOOKING;
}

const createGuestInformation = (leadSavingPayload: LeadSavingPayload) => {
  const keys = [
    'firstName',
    'lastName',
    'address1',
    'address2',
    'zipCode',
    'city',
    'state',
    'countryCode',
    'passportId',
    'passportCountryCode',
    'adultCount',
    'childrenCount',
    'petCount',
    'infantCount',
    'riskScore',
    'email',
    'secondaryEmail',
    'phoneNumber',
    'cellPhoneNumber',
    'flightNumber',
    'reasonForTrip',
    'willHaveParty',
    'willHaveVisit',
    'preferredCurrency',
    'preferredLocale',
  ];

  const guestInformation = {} as LeadGuestInformation;

  keys.forEach((key) => {
    if (key === 'address1') {
      guestInformation.address1 = leadSavingPayload.address;
      delete leadSavingPayload.address;
    }

    if (leadSavingPayload[key] !== undefined) {
      guestInformation[key] = leadSavingPayload[key];
      delete leadSavingPayload[key];
    }
  });

  return guestInformation;
};

const useLeadSavingPayload = () => {
  const { agencyUid } = useAppUser();
  const { allowNewLeadModalWebapp } = useFeatures();
  const { availableProperties, overrides, setOverrides } =
    useContext(LeadModalContext);

  const getLeadCreateSavePayload = (leadFormValues): LeadSavingPayload => {
    const leadSavingPayload = { ...leadFormValues };
    delete leadSavingPayload.cellPhoneNumber_areaCode;
    delete leadSavingPayload.cellPhoneNumber_base;
    delete leadSavingPayload.phoneNumber_areaCode;
    delete leadSavingPayload.phoneNumber_base;
    delete leadSavingPayload.pinCode;
    delete leadSavingPayload.reviewByHost;
    delete leadSavingPayload.tags;
    delete leadSavingPayload.uid;
    delete leadSavingPayload.guest;
    delete leadSavingPayload.creationMetadata;
    delete leadSavingPayload.fees;

    const unitType = getUnitType(
      availableProperties,
      leadSavingPayload.propertyUid,
    );

    if (leadSavingPayload.overrides || (allowNewLeadModalWebapp && overrides)) {
      if (allowNewLeadModalWebapp) {
        leadSavingPayload.quoteOverrides = { ...overrides };
        setOverrides(null);
      } else {
        leadSavingPayload.quoteOverrides = leadSavingPayload.overrides;
      }

      delete leadSavingPayload.overrides;
    }

    delete leadSavingPayload.force100PercentPaymentAtReservation;

    if (!leadSavingPayload.leadType) {
      leadSavingPayload.leadType = getLeadType(leadSavingPayload.status);
    }

    // legacy logic for handling deprecated status
    if (leadSavingPayload.status === LeadStatus.PAID_IN_FULL) {
      delete leadSavingPayload.status;
    }

    if (
      Object.keys(leadSavingPayload).includes('assigneeUid') &&
      !leadSavingPayload.assigneeUid
    ) {
      leadSavingPayload.assigneeUid = null; // do not send over an empty string
      leadSavingPayload.fieldsToClear = ['assigneeUid'];
    }

    leadSavingPayload.guestInformation =
      createGuestInformation(leadSavingPayload);

    if (leadSavingPayload.checkInDate) {
      leadSavingPayload.checkInLocalDate = format(
        leadSavingPayload.checkInDate,
        'yyyy-MM-dd',
      );

      delete leadSavingPayload.checkInDate;
    }

    if (leadSavingPayload.checkOutDate) {
      leadSavingPayload.checkOutLocalDate = format(
        leadSavingPayload.checkOutDate,
        'yyyy-MM-dd',
      );
      delete leadSavingPayload.checkOutDate;
    }

    if (leadSavingPayload.arrivalDate) {
      leadSavingPayload.guestInformation.arrivalLocalDateTime = format(
        leadSavingPayload.arrivalDate,
        "yyyy-MM-dd'T'HH:mm:ss",
      );
      delete leadSavingPayload.arrivalDate;
    }

    if (leadSavingPayload.departureDate) {
      leadSavingPayload.guestInformation.departureLocalDateTime = format(
        leadSavingPayload.departureDate,
        "yyyy-MM-dd'T'HH:mm:ss",
      );
      delete leadSavingPayload.departureDate;
    }

    leadSavingPayload.agencyUid = agencyUid;

    if (unitType) {
      leadSavingPayload.unitUid = leadSavingPayload.propertyUid;
      leadSavingPayload.propertyUid = unitType.uid;
    }

    return leadSavingPayload;
  };

  const getLeadUpdateSavePayload = (
    leadFormValues,
  ): LeadUpdateInternalPayload => {
    const leadSavingPayload = { ...leadFormValues };

    const unitType = getUnitType(
      availableProperties,
      leadSavingPayload.propertyUid,
    );

    const payload = {} as LeadUpdateInternalPayload;

    if (unitType) {
      payload.unitUid = leadSavingPayload.propertyUid;
      payload.propertyUid = unitType.uid;
    } else {
      payload.propertyUid = leadSavingPayload.propertyUid;
    }

    payload.guestInformation = createGuestInformation(leadSavingPayload);

    if (leadSavingPayload.checkInDate) {
      payload.checkInLocalDate = format(
        leadSavingPayload.checkInDate,
        'yyyy-MM-dd',
      );
    }

    if (leadSavingPayload.checkOutDate) {
      payload.checkOutLocalDate = format(
        leadSavingPayload.checkOutDate,
        'yyyy-MM-dd',
      );
    }

    if (leadSavingPayload.arrivalDate) {
      payload.guestInformation.arrivalLocalDateTime = format(
        leadSavingPayload.arrivalDate,
        "yyyy-MM-dd'T'HH:mm:ss",
      );
    }

    if (leadSavingPayload.departureDate) {
      payload.guestInformation.departureLocalDateTime = format(
        leadSavingPayload.departureDate,
        "yyyy-MM-dd'T'HH:mm:ss",
      );
    }

    if (
      Object.keys(leadSavingPayload).includes('assigneeUid') &&
      !leadSavingPayload.assigneeUid
    ) {
      payload.assigneeUid = null; // do not send over an empty string
    }

    payload.status = leadSavingPayload.status;
    payload.assigneeUid = leadSavingPayload.assigneeUid || null;
    payload.notes = leadSavingPayload.notes;
    payload.extraNotes = leadSavingPayload.extraNotes;
    payload.attributeToVrbo = leadSavingPayload.attributeToVrbo;

    if (leadSavingPayload.overrides || (allowNewLeadModalWebapp && overrides)) {
      if (allowNewLeadModalWebapp) {
        payload.quoteOverrides = convertQuoteOverrideToLeadQuoteOverride({
          ...overrides,
        });
        setOverrides(null);
      } else {
        payload.quoteOverrides = leadSavingPayload.overrides;
      }
    }

    return payload;
  };

  // The API now expect different payloads for creating and updating leads.
  return {
    getLeadCreateSavePayload,
    getLeadUpdateSavePayload,
  };
};

export default useLeadSavingPayload;
