import { useLayoutEffect, useRef } from 'react';
import { NavDropdown, NavItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useTurno from 'hooks/useTurno';
import type { NavigationProps } from '../Header.types';
import ProfileAvatar from '../profileAvatar/ProfileAvatar';
import useNavigationDropdownTitle from '../useNavigationDropdownTitle';
import useNavigationMethods from '../useNavigationMethods';
import * as S from './Navigation.styles';
import Badge from './badge/Badge';
import AddLeadButton from './AddLeadButton';
import NavigationLogo from './NavigationLogo';

const Navigation = ({
  navItems,
  dropdownItems,
  dropdownSecondaryItems,
}: NavigationProps) => {
  const { t } = useTranslation();
  const turno = useTurno();
  const { isDropdownActive, isNavItemActive } = useNavigationMethods();
  const navDropdownTitle = useNavigationDropdownTitle();

  const dropdownSecondaryItemsToRender = turno
    ? dropdownSecondaryItems
    : dropdownSecondaryItems?.filter(
        (item) => item.labelKey !== 'turnoverManagement',
      );

  const dropdownHiddenElement = useRef(null);

  useLayoutEffect(() => {
    const handleIframeClick = () => {
      if (dropdownHiddenElement.current) {
        dropdownHiddenElement.current.click();
      }
    };

    if (dropdownHiddenElement.current)
      window.addEventListener('iframeClick', handleIframeClick);

    return () => {
      window.removeEventListener('iframeClick', handleIframeClick);
    };
  }, [dropdownHiddenElement.current]);

  return (
    <S.Container id="webapp-header">
      <S.Navbar>
        <NavigationLogo />
        <S.Nav pullRight className="flex-grow-1 justify-content-end">
          <AddLeadButton />

          <div
            ref={dropdownHiddenElement}
            className="hidden-dropdown-close-element"
          />

          {navItems.map((item) => (
            <S.NavItem
              active={isNavItemActive(item.path)}
              href={item.path}
              hasBadge={!!item.unreadType}
              data-testid={`nav-item-${item.labelKey}`}
              key={item.path}
            >
              <>
                {t(item.labelKey as any)}
                {!!item.unreadType && <Badge type={item.unreadType} />}
              </>
            </S.NavItem>
          ))}

          <NavDropdown
            active={isDropdownActive({ dropdownItems, dropdownSecondaryItems })}
            title={navDropdownTitle}
            id="basic-nav-dropdown"
          >
            {dropdownItems.map((item) => (
              <NavItem href={item.path} key={item.path}>
                {t(item.labelKey as any)}
              </NavItem>
            ))}

            {/* Fragments can't be used here since `NavDropdown` expects its
                immediate children to be able to receive certain props */}
            {dropdownSecondaryItems && <li className="divider" />}
            {dropdownSecondaryItems &&
              dropdownSecondaryItemsToRender.map((item) => (
                <NavItem href={item.path} key={item.path}>
                  {t(item.labelKey as any)}
                </NavItem>
              ))}
          </NavDropdown>

          <S.NavItem href="https://help.hostfully.com" target="_blank">
            <span className="glyphicon glyphicon-question-sign" />
          </S.NavItem>

          <ProfileAvatar />
        </S.Nav>
      </S.Navbar>
    </S.Container>
  );
};

export default Navigation;
