import styled from 'styled-components';
import theme from 'styles/theme';
import { CollapseSectionStyled } from 'pages/property/common/Common.styles';

export const PropertyDetailsCollapseStyled = styled(CollapseSectionStyled)`
  .upload-field-button-wrapper {
    display: flex;
    flex-direction: column-reverse;
    gap: 8px;

    .attach-thumbnail-button {
      width: max-content;
    }

    ul {
      border: 1px solid ${theme.colors.gray300};
      border-radius: 2px;
      padding: 9px 8px;
      width: max-content;

      li {
        justify-content: space-between;
        width: 188px;
      }
    }
  }
`;
