import { ReactNode } from 'react';
import { TableCaptionStyled } from './TableCaption.styles';

interface TableCaptionProps {
  className?: string;
  children?: ReactNode;
}

const TableCaption = ({ className, children }: TableCaptionProps) => {
  return (
    <TableCaptionStyled className={className}>{children}</TableCaptionStyled>
  );
};

TableCaption.defaultProps = {
  className: '',
  children: null,
};

export default TableCaption;
