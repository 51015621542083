import { toUpperFirst } from 'utils/stringUtils';

interface ToUpperFirstCellProps {
  getValue: () => string;
}

const ToUpperFirstCell = ({ getValue }: ToUpperFirstCellProps) => {
  return toUpperFirst(getValue());
};

export default ToUpperFirstCell;
