import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import useInvalidateFetchAllProperties from 'components/domain/property/useInvalidateFetchAllProperties';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import API from 'services/API';
import { PROPERTIES_FOR_PROPERTIES_BODY_BASE_QUERY_KEY } from 'pages/agencyProperties/body/useFetchPropertiesForPropertiesBody';

interface ConvertToUnitTypeArgs {
  propertyUid: string;
  units: number;
  multiUnitProperty: string;
}

const convertToUnitType = async ({
  propertyUid,
  units,
  multiUnitProperty,
}: ConvertToUnitTypeArgs) => {
  return API.post(
    `/api/internal/properties/${propertyUid}/convert-to-unit-type`,
    {
      hotelUid: multiUnitProperty,
      numberOfUnitsToCreate: units,
    },
  );
};

const useConvertToUnitTypeMutation = ({
  onSuccess,
}: {
  onSuccess: (data: unknown, variables: ConvertToUnitTypeArgs) => void;
}) => {
  const { notifyError, notifySuccess } = useNotify();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { invalidateFetchAllProperties } = useInvalidateFetchAllProperties();

  return useAppMutation({
    mutationFn: convertToUnitType,
    onSuccess: (data, variables) => {
      onSuccess(data, variables);
      notifySuccess(
        t('pageProperties.actions.convertToUnitTypeModal.onSubmitSuccess'),
      );
      invalidateFetchAllProperties();
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        PROPERTIES_FOR_PROPERTIES_BODY_BASE_QUERY_KEY,
      ]);
    },
    onError: () => {
      notifyError(
        t('pageProperties.actions.convertToUnitTypeModal.onSubmitError'),
      );
    },
  });
};

export default useConvertToUnitTypeMutation;
