import { useFormContext, useWatch } from 'react-hook-form';
import { PropertySettingsMainTabFormValues } from '../../PropertySettingsMainTab.schema';
import { PropertyPhotoFile } from './PropertyDetails.types';

type SelectedFile = PropertyPhotoFile | { uri: null };

const useCurrentSelectedFile = () => {
  const { setValue } = useFormContext();

  const fileUrl = useWatch<PropertySettingsMainTabFormValues>({
    name: 'propertyDetails.internalThumbnailUrl',
  });

  const currentFile: SelectedFile =
    typeof fileUrl === 'string'
      ? { uri: fileUrl, contentType: '', sizeInBytes: 0, uploadItemName: '' }
      : null;

  const setCurrentFile = (file: SelectedFile) => {
    setValue('propertyDetails.internalThumbnailUrl', file.uri, {
      shouldDirty: true,
    });
  };

  return { currentFile, setCurrentFile };
};

export default useCurrentSelectedFile;
