import { useFormContext } from 'react-hook-form';
import Button from 'components/button/Button';
import { useTranslation } from 'react-i18next';
import useAppModal from 'hooks/useAppModal';
import { bulkSavePropertiesModalId } from './BulkSavePropertiesModal.constants';
import { BulkSavePropertiesModalFooterStyled } from './BulkSavePropertiesModal.styles';

const BookSavePropertiesModalFooter = () => {
  const { t } = useTranslation();
  const { closeModal } = useAppModal();
  const {
    formState: { isSubmitting },
  } = useFormContext();

  const handleCancel = () => {
    closeModal(bulkSavePropertiesModalId);
  };

  return (
    <BulkSavePropertiesModalFooterStyled>
      <Button
        data-testid="bulk-save-properties-modal-cancel-button"
        disabled={isSubmitting}
        onClick={handleCancel}
      >
        {t('common.cancel')}
      </Button>
      <Button
        data-testid="bulk-save-properties-modal-save-button"
        bsStyle="primary"
        disabled={isSubmitting}
        type="submit"
      >
        {isSubmitting ? t('common.saving') : t('common.save')}
      </Button>
    </BulkSavePropertiesModalFooterStyled>
  );
};

export default BookSavePropertiesModalFooter;
