import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { PageHeaderContainer } from './PageHeader.styles';

interface Props {
  className?: string;
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  buttons?: React.ReactNode;
  help?: string | React.ReactNode;
  children?: React.ReactNode;
  noDivider?: boolean;
  isLoading?: boolean;
}

const PageHeader: React.FC<Props> = ({
  className,
  title,
  subtitle,
  children,
  buttons,
  help,
  noDivider,
  isLoading,
}: Props) => {
  if (isLoading) {
    return (
      <PageHeaderContainer className={className}>
        <LoadingPlaceholder linesCount={2} width="65%" />
        {!noDivider && <div className="divider" />}
      </PageHeaderContainer>
    );
  }

  return (
    <PageHeaderContainer className={className}>
      <h1>
        <span data-testid="page-header-title">{title}</span>
        {buttons && <div className="page-header-buttons">{buttons}</div>}
        {help && <div className="help-block">{help}</div>}
      </h1>
      {subtitle && <h2 data-testid="page-header-subtitle">{subtitle}</h2>}
      {!noDivider && <div className="divider" />}
      {children}
    </PageHeaderContainer>
  );
};

PageHeader.defaultProps = {
  className: '',
  title: '',
  subtitle: '',
  buttons: null,
  help: null,
  children: null,
  noDivider: false,
  isLoading: false,
};

export default PageHeader;
