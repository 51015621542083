import { UserType } from 'models/Users';
import type { NavigationProps } from './Header.types';

export const agencyNavigationItems: NavigationProps = {
  navItems: [
    {
      path: '#/dashboard',
      labelKey: 'common.dashboard',
      unreadType: 'lead',
    },
    {
      path: '#/inbox',
      labelKey: 'common.inbox',
      unreadType: 'message',
    },
    {
      path: '#/pipeline',
      labelKey: 'common.pipeline',
    },
    {
      path: '#/calendar',
      labelKey: 'common.calendar',
    },
    {
      path: '#/reports',
      labelKey: 'common.reports',
      allowedToRender: ({ allowAnalyticsWebapp }) => !allowAnalyticsWebapp,
    },
    {
      path: '#/analytics',
      labelKey: 'common.analytics',
      allowedToRender: ({ allowAnalyticsWebapp }) => allowAnalyticsWebapp,
    },
  ],
  dropdownItems: [
    {
      path: '#/properties',
      labelKey: 'common.properties',
    },
    {
      path: '#/publishing',
      labelKey: 'common.publishingTools',
    },
    {
      path: '#/third-party-agency',
      labelKey: 'common.thirdPartyAgency',
    },
    {
      path: '#/calendar-import',
      labelKey: 'common.calendarTools',
    },
    {
      path: '#/integrations',
      labelKey: 'common.integrations',
    },
    {
      path: '#/channels',
      labelKey: 'common.channels',
    },
    {
      path: '#/reports',
      labelKey: 'common.reports',
      allowedToRender: ({ allowAnalyticsWebapp }) => allowAnalyticsWebapp,
    },
    {
      path: '#/my-templates',
      labelKey: 'common.templatesAndTriggers',
    },
    {
      path: '#/owners',
      labelKey: 'common.owners',
    },
    {
      path: '#/discount-codes',
      labelKey: 'common.discountCodes',
    },
    {
      path: '#/agency-settings',
      labelKey: 'common.agencySettings',
    },
  ],
  dropdownSecondaryItems: [
    {
      path: '#/turnover-management',
      labelKey: 'common.turnoverManagement',
    },
    {
      path: '#/refer',
      labelKey: 'common.referFriend',
    },
    {
      path: '#/logout',
      labelKey: 'common.signOut',
    },
  ],
};

export const guestNavigationItems: NavigationProps = {
  navItems: [
    {
      path: '#/guest/dashboard',
      labelKey: 'common.myBookings',
    },
    {
      path: '#/guest/account',
      labelKey: 'common.accountAndPreferences',
    },
  ],
  dropdownItems: [
    {
      path: '#/logout',
      labelKey: 'common.signOut',
    },
  ],
};

export const serviceProviderNavigationItems: NavigationProps = {
  navItems: [
    {
      path: '#/service-provider/dashboard',
      labelKey: 'common.jobs',
    },
    {
      path: '#/service-provider/properties',
      labelKey: 'common.properties',
    },
    {
      path: '#/service-provider/account',
      labelKey: 'common.myAccount',
    },
  ],
  dropdownItems: [
    {
      path: '#/logout',
      labelKey: 'common.signOut',
    },
  ],
};

export const ownerNavigationItems: NavigationProps = {
  navItems: [
    {
      path: '#/owner/calendar',
      labelKey: 'common.calendar',
    },
    {
      path: '#/owner/inbox',
      labelKey: 'common.inbox',
      unreadType: 'message',
    },
    {
      path: '#/owner/properties',
      labelKey: 'common.properties',
    },
    {
      path: '#/owner/statements',
      labelKey: 'common.statements',
    },
  ],
  dropdownItems: [
    {
      path: '#/owner/account',
      labelKey: 'common.settings',
    },
    {
      path: '#/logout',
      labelKey: 'common.signOut',
    },
  ],
};

export const itemsByRole: Record<UserType, NavigationProps> = {
  [UserType.Employee]: agencyNavigationItems,
  [UserType.Owner]: ownerNavigationItems,
  [UserType.ServiceProviderUser]: serviceProviderNavigationItems,
  [UserType.Guest]: guestNavigationItems,
  [UserType.Agent]: guestNavigationItems,
};
