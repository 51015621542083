/* eslint-disable react/require-default-props */
import React from 'react';
import useAppModal from 'hooks/useAppModal';
import useAppUser from 'hooks/useAppUser';
import { useTranslation } from 'react-i18next';
import DateTimePickerField from 'components/forms/dateTimePickerField/DateTimePickerField';
import { Modal } from 'react-bootstrap';
import SelectField from 'components/forms/selectField/SelectField';
import TextField from 'components/forms/textField/TextField';
import Button from 'components/button/Button';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { BlockLeadForm } from 'models/BlockLead';
import { formatISO } from 'date-fns';
import { getUnitType } from 'utils/lead/leadUtils';
import { LeadStatus } from 'models/Leads';
import { Property } from 'models/Properties';
import { getPropertyOptions } from 'utils/property/propertyUtils';
import {
  propertyCalendarConditionGraphQLQuery,
  propertyCalendarGraphQLQuery,
} from 'pages/stackedCalendar/StackedCalendar.utils';
import useFetchAllProperties from '../../domain/property/useFetchAllProperties';
import {
  Container,
  PropertyWrapper,
  FooterWrapper,
  DatePickerWrapper,
  ContentWrapper,
  DatesWrapper,
  NotesWrapper,
} from './BlockModal.styles';
import blockSchema from './BlockModal.schemas';
import useBlockActions from './useBlockActions';
import useFetchBlock from './useFetchBlock';
import BlockModalPropertyImage from './BlockModalPropertyImage';

interface BlockModalProps {
  modalId: string;
  propertyUid?: string;
  allProperties?: Property[];
  blockUid?: string;
  dateFrom?: Date;
  dateTo?: Date;
}

const BlockModal: React.FC<BlockModalProps> = ({
  modalId,
  propertyUid,
  allProperties: providedAllProperties,
  dateFrom,
  dateTo,
  blockUid,
}) => {
  const { t } = useTranslation();
  const { isOwner, userUid } = useAppUser();
  const { closeModal } = useAppModal();
  const { agencyUid } = useAppUser();
  const { currentBlock, isCurrentBlockLoading } = useFetchBlock({ blockUid });

  const {
    allProperties: fetchedAllProperties,
    isFetching: isFetchingAllProperties,
  } = useFetchAllProperties({
    enabled: !providedAllProperties,
    conditions: propertyCalendarConditionGraphQLQuery,
    fields: propertyCalendarGraphQLQuery,
  });
  const { createBlock, deleteBlock, updateBlock, isAnyBlockRequestLoading } =
    useBlockActions({
      modalId,
      blockUid,
    });

  const allProperties = providedAllProperties || fetchedAllProperties;
  const isUpdateType = !!blockUid;

  const defaultPropertyUid = isUpdateType
    ? currentBlock?.unitUid || currentBlock?.property.uid
    : propertyUid;

  const dateFormater = (date: Date) =>
    formatISO(new Date(date), { representation: 'date' });

  const defaultCheckInDate =
    isUpdateType && currentBlock?.checkInLocalDateTime
      ? new Date(currentBlock.checkInLocalDateTime)
      : dateFrom;

  const defaultCheckOutDate =
    isUpdateType && currentBlock?.checkOutLocalDateTime
      ? new Date(currentBlock.checkOutLocalDateTime)
      : dateTo;

  const isUserDisabledToModifyThisBlock =
    isOwner && isUpdateType && userUid !== currentBlock?.assignee.uid;

  const handleSubmit = (formValues: BlockLeadForm) => {
    const { from, to, notes, currentPropertyUid } = formValues;

    const unitType = getUnitType(allProperties, currentPropertyUid);

    const blockPayload: any = {
      agencyUid,
      status: LeadStatus.BLOCKED,
      checkInLocalDate: dateFormater(from),
      checkOutLocalDate: dateFormater(to),
      propertyUid: unitType?.uid || currentPropertyUid,
      ...(unitType && { unitUid: currentPropertyUid }),
      notes,
    };

    if (isUpdateType) {
      delete blockPayload.agencyUid;
      updateBlock(blockPayload);
    } else {
      createBlock(blockPayload);
    }
  };

  const formKey = `${
    allProperties ? 'properties-ready' : 'properties-not-ready'
  }_${
    currentBlock ? 'current-block-ready' : 'current-block-not-ready'
  }-propertyUid${propertyUid}`;

  return (
    <Container
      data-testid="create-update-block-test-id"
      id="create-update-block-form"
      key={formKey}
      schema={blockSchema()}
      horizontal
      onSubmit={handleSubmit}
      defaultValues={{
        from: defaultCheckInDate,
        to: defaultCheckOutDate,
        notes: currentBlock?.notes || '',
        currentPropertyUid: defaultPropertyUid,
      }}
    >
      <Modal.Header>
        <Modal.Title>
          {isUpdateType
            ? t('pageCalendar.block.updateBlock')
            : t('pageCalendar.block.addBlock')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(!isCurrentBlockLoading || !isUpdateType) &&
        !isFetchingAllProperties ? (
          <ContentWrapper>
            <div>
              <DatesWrapper>
                <DatePickerWrapper>
                  <DateTimePickerField
                    name="from"
                    label={t('pageCalendar.block.from')}
                    disabled={isUserDisabledToModifyThisBlock}
                  />
                </DatePickerWrapper>
                <DatePickerWrapper>
                  <DateTimePickerField
                    name="to"
                    label={t('pageCalendar.block.to')}
                    disabled={isUserDisabledToModifyThisBlock}
                  />
                </DatePickerWrapper>
              </DatesWrapper>
              <PropertyWrapper>
                <BlockModalPropertyImage properties={allProperties} />

                <SelectField
                  name="currentPropertyUid"
                  label=""
                  options={
                    allProperties?.reduce(
                      (options, property) =>
                        options.concat(getPropertyOptions({ property })),
                      [],
                    ) || []
                  }
                  labelOnTop
                  disabled={isUserDisabledToModifyThisBlock}
                />
              </PropertyWrapper>
            </div>
            <NotesWrapper>
              <TextField
                name="notes"
                label={t('pageCalendar.block.notes')}
                componentClass="textarea"
                rows="5"
                className="notes"
                disabled={isUserDisabledToModifyThisBlock}
              />
            </NotesWrapper>
          </ContentWrapper>
        ) : (
          <ContentWrapper>
            <div>
              <DatesWrapper>
                <DatePickerWrapper>
                  <LoadingPlaceholder linesCount={2} width="120px" />
                </DatePickerWrapper>
                <DatePickerWrapper>
                  <LoadingPlaceholder linesCount={2} width="120px" />
                </DatePickerWrapper>
              </DatesWrapper>
              <PropertyWrapper>
                <LoadingPlaceholder linesCount={4} width="90px" />

                <LoadingPlaceholder linesCount={2} width="200px" />
              </PropertyWrapper>
            </div>
            <NotesWrapper>
              <LoadingPlaceholder linesCount={9} width="80%" />
            </NotesWrapper>
          </ContentWrapper>
        )}
      </Modal.Body>
      <Modal.Footer>
        <FooterWrapper>
          <div>
            {!isUserDisabledToModifyThisBlock && isUpdateType && (
              <Button
                onClick={() => deleteBlock()}
                bsStyle="danger"
                id="delete-button"
                disabled={isAnyBlockRequestLoading}
              >
                {t('common.delete')}
              </Button>
            )}
          </div>
          <div>
            <Button onClick={() => closeModal(modalId)}>
              {t('pageCalendar.block.close')}
            </Button>
            {!isUserDisabledToModifyThisBlock && (
              <Button
                bsStyle="primary"
                type="submit"
                disabled={isAnyBlockRequestLoading}
              >
                {t('common.save')}
              </Button>
            )}
          </div>
        </FooterWrapper>
      </Modal.Footer>
    </Container>
  );
};

export default BlockModal;
