import { useCallback, useContext, useEffect, useRef } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import { startOfDay, subDays } from 'date-fns';
import useAppSelector from 'hooks/useAppSelector';
import { getPropertyUidFromStringType } from 'utils/property/propertyUtils';
import CalendarContext from '../CalendarContext';
import useCalendarBodyMouseEvents from '../../calendar/common/body/selection/useCalendarBodyMouseEvents';
import useGetCalendarData from '../useGetCalendarData';
import useStackedCalendarPropertiesEntries from '../useStackedCalendarPropertiesEntries';
import useStackedCalendarVisibleProperties from '../useStackedCalendarVisibleProperties';
import { StackedCalendarBodyStyled } from './StackedCalendarBody.styles';
import DayCellRenderer from './days/DayCellRenderer';
import StackedCalendarBodySelectionHandler from './StackedCalendarBodySelectionHandler';
import { getSelectionLimit } from './StackedCalendarBodySelection.utils';
import useStackedCalendarBodyUpdateEvents from './useStackedCalendarBodyUpdateEvents';
import useDragAndDropLeadsUnit from './useDragAndDropLeadsUnit';
import useHighlightUpdatedPricing from './days/useHighlightUpdatedPricing';

const StackedCalendarBody = () => {
  const { mode, filter } = useContext(CalendarContext);
  const visibleDates = useAppSelector(
    (state) => state.stackedCalendar.visibleDates,
  );
  const propertiesEntries = useStackedCalendarPropertiesEntries();
  const visibleProperties = useStackedCalendarVisibleProperties();
  const [dragDropEventHandlers, highlightEventHandlersRow] =
    useDragAndDropLeadsUnit();
  const lastElementHighlighted = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>();
  useGetCalendarData();

  const selectionLimitProvider = useCallback(
    (dayDate, propertyUid) =>
      getSelectionLimit(dayDate, propertiesEntries[propertyUid], mode),
    [propertiesEntries, mode],
  );
  const { onMouseDown, onMouseUp } = useCalendarBodyMouseEvents(
    selectionLimitProvider,
  );

  useStackedCalendarBodyUpdateEvents();
  useHighlightUpdatedPricing();

  const initialScrollPositionAdjusted = useRef(false);

  const scrollInToDate = (date: Date, delay: number = 1) => {
    const dateToScroll = subDays(startOfDay(date), 1);

    // timeout assures that the day cells are rendered before scrolling
    setTimeout(() => {
      initialScrollPositionAdjusted.current = true;

      bodyRef.current
        ?.querySelector(`[data-day-date='${dateToScroll.getTime()}']`)
        ?.scrollIntoView({ block: 'end', inline: 'start' });
    }, delay);
  };

  useEffect(() => {
    if (
      !initialScrollPositionAdjusted.current &&
      bodyRef.current &&
      visibleProperties.length
    ) {
      const initialDateToScroll = filter?.availableFrom
        ? new Date(filter?.availableFrom)
        : new Date();

      scrollInToDate(initialDateToScroll);
    }

    const handleFilterEvent = (event) => {
      const { date } = event.detail;
      if (!date) return;

      scrollInToDate(date, 100);
    };

    window.addEventListener('scrollToDateEvent', handleFilterEvent);

    return () => {
      window.removeEventListener('scrollToDateEvent', handleFilterEvent);
    };
  }, [bodyRef.current, visibleProperties]);

  return (
    <StackedCalendarBodyStyled $mode={mode}>
      <StackedCalendarBodySelectionHandler />
      {/* This container captures bubbled events - this prevents having multiple listeners for each child */}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className="stacked-calendar-body-container"
        ref={bodyRef}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        {...dragDropEventHandlers}
      >
        <ScrollSyncPane>
          <div className="stacked-calendar-body-container-scrollable">
            {visibleProperties.map((uid) => {
              const { propertyUid, isUnitType, isUnit } =
                getPropertyUidFromStringType(uid);
              return (
                <div
                  className="stacked-calendar-body-row"
                  key={`${propertyUid}-row`}
                  data-row-property-uid={propertyUid}
                  onDrop={() =>
                    highlightEventHandlersRow.onDrop(lastElementHighlighted)
                  }
                  onDragEnter={(e) =>
                    highlightEventHandlersRow.onDragEnter(
                      e,
                      propertyUid,
                      lastElementHighlighted,
                    )
                  }
                >
                  {visibleDates.map((dayDate) => (
                    <DayCellRenderer
                      isUnit={isUnit}
                      isUnitType={isUnitType}
                      propertyUid={propertyUid}
                      dayDate={dayDate}
                      mode={mode}
                      key={`${propertyUid}-row-cell-${dayDate.getTime()}`}
                    />
                  ))}
                </div>
              );
            })}
          </div>
        </ScrollSyncPane>
      </div>
    </StackedCalendarBodyStyled>
  );
};

export default StackedCalendarBody;
