import { useTranslation } from 'react-i18next';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import API from 'services/API';

const useDeleteTransaction = () => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  const { mutate, isLoading } = useAppMutation(
    ({
      transactionUid,
    }: {
      successCallback: () => void;
      transactionUid: string;
    }) => API.delete<void>(`/api/internal/transactions/${transactionUid}`),
    {
      onSuccess: (response, { successCallback }) => {
        if (response.status === 204) {
          successCallback?.();
        }
      },
      onError: () => {
        notifyError(t('componentTransaction.error.couldNotDeleteTransaction'));
      },
    },
  );

  return { deleteTransaction: mutate, isLoading };
};

export default useDeleteTransaction;
