import { useTranslation } from 'react-i18next';

import { normalizeKey } from '../../../../utils/localeUtils';
import {
  BookingColumn,
  DescriptionColumn,
  ResolutionColumn,
  ResolutionRow,
  StatusColumn,
} from './Resolutions.styles';
import { ResolutionData } from './Resolutions.types';
import BookingSummary from './BookingSummary';
import ResolutionSummary from './ResolutionSummary';

const ResolutionsListItem = ({
  resolutionData,
}: {
  resolutionData: ResolutionData;
}) => {
  const { t } = useTranslation();
  const {
    lead,
    resolution: { description, status },
  } = resolutionData;

  return (
    <ResolutionRow className="resolution">
      <ResolutionColumn>
        <ResolutionSummary resolution={resolutionData.resolution} />
      </ResolutionColumn>
      <BookingColumn>{lead && <BookingSummary lead={lead} />}</BookingColumn>
      <StatusColumn>
        {t(normalizeKey(`pageAirbnbResolutions.resolutionStatus.${status}`))}
      </StatusColumn>
      <DescriptionColumn>{description}</DescriptionColumn>
    </ResolutionRow>
  );
};

export default ResolutionsListItem;
