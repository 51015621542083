import { addHours } from 'date-fns';
import { StackedCalendarMode } from '../Calendar.types';
import { stackedCalendarStyleConstants } from '../Calendar.constants';

export function getDaysNumberWithinViewport({
  bodyWidth,
  mode,
}: {
  bodyWidth: number;
  mode: StackedCalendarMode;
}): number {
  const cellWidth = stackedCalendarStyleConstants.daysCellWidth[mode];
  return Math.ceil(bodyWidth / cellWidth);
}

function getHoursOffset(mode, scrollPosition) {
  const cellWidth = stackedCalendarStyleConstants.daysCellWidth[mode];
  const pixelsPerHour = cellWidth / 24;
  return Math.ceil(scrollPosition / pixelsPerHour);
}

export function getFirstDateWithinViewport({
  mode,
  scrollPosition,
  startDate,
}: {
  mode: StackedCalendarMode;
  scrollPosition: number;
  startDate: Date;
}): Date {
  const hoursOffset = getHoursOffset(mode, scrollPosition);
  return addHours(startDate, hoursOffset);
}

export function getLastDateWithinViewport({
  bodyWidth,
  mode,
  scrollPosition,
  startDate,
}: {
  bodyWidth: number;
  mode: StackedCalendarMode;
  scrollPosition: number;
  startDate: Date;
}): Date {
  const hoursOffsetStart = getHoursOffset(mode, scrollPosition);
  const hoursOffset =
    hoursOffsetStart +
    (getDaysNumberWithinViewport({ bodyWidth, mode }) - 2) * 24;
  return addHours(startDate, hoursOffset);
}
