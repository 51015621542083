import { useContext } from 'react';
import TableContext from './TableContext';
import { PaginationStyled } from './TablePagination.styles';

interface TablePaginationProps {
  className?: string;
}

const MAX_VISIBLE_PAGES = 5;

const TablePagination = ({ className }: TablePaginationProps) => {
  const { tableInstance } = useContext(TableContext);
  const currentPageIndex = tableInstance.getState().pagination.pageIndex;
  const allPageIndexes = Array.from(
    { length: tableInstance.getPageCount() },
    (_, index) => index,
  );
  const visiblePageIndexes = allPageIndexes.filter((index) => {
    return Math.abs(index - currentPageIndex) < MAX_VISIBLE_PAGES / 2;
  });
  const isLeftEllipsisVisible = visiblePageIndexes[0] !== allPageIndexes[0];
  const isRightEllipsisVisible =
    visiblePageIndexes[visiblePageIndexes.length - 1] !==
    allPageIndexes[allPageIndexes.length - 1];

  return (
    <PaginationStyled data-testid="table-pagination" className={className}>
      <PaginationStyled.Prev
        data-testid="table-page-prev"
        onClick={() =>
          tableInstance.getCanPreviousPage() && tableInstance.previousPage()
        }
        disabled={!tableInstance.getCanPreviousPage()}
      />

      {isLeftEllipsisVisible && (
        <PaginationStyled.Ellipsis data-testid="table-page-ellipsis" />
      )}

      {visiblePageIndexes.map((index) => (
        <PaginationStyled.Item
          // eslint-disable-next-line react/no-array-index-key
          key={index + 1}
          active={currentPageIndex === index}
          data-testid={`table-page-${index + 1}`}
          onClick={() => tableInstance.setPageIndex(index)}
        >
          {index + 1}
        </PaginationStyled.Item>
      ))}

      {isRightEllipsisVisible && (
        <PaginationStyled.Ellipsis data-testid="table-page-ellipsis" />
      )}

      <PaginationStyled.Next
        data-testid="table-page-next"
        onClick={() =>
          tableInstance.getCanNextPage() && tableInstance.nextPage()
        }
        disabled={!tableInstance.getCanNextPage()}
      />
    </PaginationStyled>
  );
};

TablePagination.defaultProps = {
  className: '',
};

export default TablePagination;
