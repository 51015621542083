import styled from 'styled-components';
import PropertySelectField from 'components/forms/domain/property/PropertySelectField';
import FormWithProvider from 'components/forms/form/Form';
import PageHeader from 'components/pageHeader/PageHeader';
import mediaQuery from 'styles/mediaQuery';
import Button from 'components/button/Button';
import { LoadingPlaceholderStyled } from 'components/loader/LoadingPlaceholder.styles';

export const OwnerAdjustmentsHeaderWrapper = styled(PageHeader)`
  align-items: center;
  column-gap: 15px;
  display: flex;
  flex-wrap: wrap;

  h1 {
    align-self: start;
  }

  .divider {
    order: 4;
    width: 100%;
  }

  ${mediaQuery.xs} {
    flex-direction: column;
    row-gap: 15px;

    .divider {
      display: none;
    }
  }
`;

export const OwnerAdjustmentsHeaderForm = styled(FormWithProvider)`
  flex: 1;

  .form-field-container {
    margin-bottom: 0;

    > div:first-child {
      max-width: 300px;
    }
  }

  ${LoadingPlaceholderStyled} {
    width: 270px;
  }

  ${mediaQuery.xs} {
    width: 100%;

    .form-field-container {
      margin: 0;
      width: 100%;

      > div:first-child {
        max-width: none;
        padding: 0;
      }
    }

    ${LoadingPlaceholderStyled} {
      width: 100%;
    }
  }
`;

export const OwnerAdjustmentsPropertySelect = styled(PropertySelectField)`
  padding: 0 10px 0 0;
`;

export const OwnerAdjustmentsCreateNewButton = styled(Button)`
  align-self: end;
`;
