import {
  ScrollingPositionContainer,
  CalendarScrollingPosition,
  CalendarScrollService,
} from 'pages/calendar/common/body/selection/CalendarScrollingPosition.types';

const scrollingPositionContainer: ScrollingPositionContainer = {};
const subscribers: { [id: string]: (_sel: CalendarScrollingPosition) => void } =
  {};

const CalendarScrollPositionService: CalendarScrollService = {
  getScrollingContainer: () => scrollingPositionContainer,
  notify: () => {
    Object.values(subscribers).forEach((notifyCallback) => {
      notifyCallback(scrollingPositionContainer.calendarScrollingPosition);
    });
  },
  subscribe: (notifyCallback) => {
    const subscriptionId = Math.floor(Math.random() * Date.now()).toString(36);
    subscribers[subscriptionId] = notifyCallback;

    return {
      unsubscribe: () => {
        delete subscribers[subscriptionId];
      },
    };
  },
};

export default CalendarScrollPositionService;
