import { CSSProperties } from 'react';
import type {
  DOMConversionMap,
  DOMConversionOutput,
  EditorConfig,
  NodeKey,
  SerializedElementNode,
  Spread,
} from 'lexical';
import { $applyNodeReplacement, ParagraphNode } from 'lexical';
import {
  convertCssPropertiesToCssText,
  convertStyleToCssProperties,
} from './lexicalNodes.utils';

interface DivPayload {
  className?: string;
  style?: CSSProperties;
  key?: NodeKey;
}

export type SerializedDivNode = Spread<
  {
    className?: string;
    style?: CSSProperties;
  },
  SerializedElementNode
>;

export class DivNode extends ParagraphNode {
  className: string;

  style: CSSProperties;

  constructor(className?: string, style?: CSSProperties, key?: NodeKey) {
    super(key);
    this.className = className;
    this.style = style;
  }

  static getType(): string {
    return 'div';
  }

  static clone(node: DivNode): DivNode {
    return new DivNode(node.className, node.style);
  }

  static importDOM(): DOMConversionMap | null {
    return {
      div: (_node: Node) => ({
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        conversion: convertDivElement,
        priority: 0,
      }),
    };
  }

  static importJSON(serializedNode: SerializedDivNode): DivNode {
    const { className, style } = serializedNode;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return $createDivNode({
      className,
      style,
    });
  }

  createDOM(_config: EditorConfig): HTMLElement {
    const dom = document.createElement('div');

    if (this.className) {
      dom.setAttribute('class', this.className);
    }

    if (this.style) {
      dom.setAttribute('style', convertCssPropertiesToCssText(this.style));
    }
    return dom;
  }

  exportJSON(): SerializedDivNode {
    return {
      ...super.exportJSON(),
      className: this.className,
      style: this.style,
      type: 'div',
      version: 1,
    };
  }
}

export function $createDivNode({ className, key, style }: DivPayload): DivNode {
  return $applyNodeReplacement(new DivNode(className, style, key));
}

function convertDivElement(domNode: Node): null | DOMConversionOutput {
  if (domNode instanceof HTMLDivElement) {
    const { className, style } = domNode;
    const node = $createDivNode({
      className,
      style: convertStyleToCssProperties(style),
    });
    return { node };
  }
  return null;
}
