import { useEffect } from 'react';
import { AppEventType } from '../../../models/Events';
import useAppEvent from '../../../hooks/useAppEvent';

const useRefreshOnLeadEvents = ({ refreshFn }: { refreshFn: () => void }) => {
  const { subscribe, unsubscribe } = useAppEvent();

  useEffect(() => {
    const leadsRefresh: EventListenerOrEventListenerObject = () => refreshFn();
    subscribe(AppEventType.LEAD_CREATED, leadsRefresh);
    subscribe(AppEventType.LEAD_DELETED, leadsRefresh);
    subscribe(AppEventType.LEAD_UPDATED, leadsRefresh);

    return () => {
      unsubscribe(AppEventType.LEAD_CREATED, leadsRefresh);
      unsubscribe(AppEventType.LEAD_DELETED, leadsRefresh);
      unsubscribe(AppEventType.LEAD_UPDATED, leadsRefresh);
    };
  }, []);
};

export default useRefreshOnLeadEvents;
