import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface CurrencyFormattingOptions {
  round?: boolean;
  removeDecimals?: boolean;
  notation?: 'standard' | 'scientific' | 'engineering' | 'compact';
}

const useFormatCurrency = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return useMemo(
    () =>
      ({
        currency,
        value,
        options = {
          round: false,
          removeDecimals: false,
          notation: 'standard',
        },
      }: {
        currency: string;
        value: number | undefined;
        options?: CurrencyFormattingOptions;
      }) => {
        const { round, removeDecimals, notation } = options;

        if (typeof value === 'undefined') {
          return new Intl.NumberFormat(language, {
            style: 'currency',
            currency,
          })
            .format(0)
            .replaceAll('0', '')
            .replaceAll(',', '')
            .replaceAll('.', '');
        }

        const roundedValue = round ? Math.round(value) : value;

        const result = new Intl.NumberFormat(language, {
          style: 'currency',
          currency,
          notation,
          maximumFractionDigits: removeDecimals ? 0 : 2,
          minimumFractionDigits:
            removeDecimals || roundedValue % 1 === 0 ? 0 : 2,
        }).format(roundedValue);

        return result;
      },
    [language],
  );
};

export default useFormatCurrency;
