import { Employee } from 'models/Employees';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import { UserEmployeeRole } from 'models/Users';
import PropertyItemPermissionsEmployeeAvatar from '../PropertyItemPermissionsEmployeeAvatar';
import { PropertyPermissionsEmployee } from './PropertyPermissionsModal.styles';

const mapRoleToLabel = (role: UserEmployeeRole) => {
  switch (role) {
    case UserEmployeeRole.ASSOCIATE:
      return 'Associate';
    case UserEmployeeRole.BOOKING_AGENT:
      return 'Booking Agent';
    case UserEmployeeRole.MANAGER:
      return 'Manager';
    case UserEmployeeRole.PROPERTY_SALES_MANAGER:
      return 'Property Sales Manager';
    default:
      return 'Employee';
  }
};

const PropertyPermissionsEmployeeItem = ({
  employee,
  propertyUid,
  editable,
}: {
  editable: boolean;
  employee: Employee;
  propertyUid: string;
}) => {
  return (
    <PropertyPermissionsEmployee data-testid="permissions-assignee">
      <CheckboxField
        name={`employeeUid-${employee.uid}`}
        disabled={!editable}
      />
      <PropertyItemPermissionsEmployeeAvatar
        employee={employee}
        propertyUid={propertyUid}
      />
      <div className="employee-info">
        <p className="employee-name">{employee.bestProfile}</p>
        <p className="employee-role">{mapRoleToLabel(employee.role)}</p>
      </div>
    </PropertyPermissionsEmployee>
  );
};

export default PropertyPermissionsEmployeeItem;
