import { Lead, LeadAvailablePipelineAction, LeadSource } from 'models/Leads';
import useLeadPipelineAction from './useLeadPipelineAction';

const airbnbActionsWithButtons = [
  LeadAvailablePipelineAction.AIRBNB_PRE_APPROVE,
];

const standardActionsWithButtons = [
  LeadAvailablePipelineAction.DECLINE_PENDING,
  LeadAvailablePipelineAction.ACCEPT_PENDING,
  LeadAvailablePipelineAction.CANCEL_BOOKING,
];

export const buttonStyles = {
  [LeadAvailablePipelineAction.CANCEL_BOOKING]: 'danger',
  [LeadAvailablePipelineAction.DECLINE_PENDING]: 'danger',
};

function isActionAvailable({ action, lead }) {
  if (action === LeadAvailablePipelineAction.CANCEL_BOOKING) {
    return lead?.source === LeadSource.DIRECT_AIRBNB;
  }

  return true;
}

const useLeadActions = ({ lead }: { lead: Lead }) => {
  const { takeAction, isExecutingAction } = useLeadPipelineAction();
  const { availablePipelineActions } = lead || {};

  const airbnbActions =
    availablePipelineActions
      ?.filter((action) => airbnbActionsWithButtons.includes(action))
      .sort(
        (action1, action2) =>
          airbnbActionsWithButtons.indexOf(action1) -
          airbnbActionsWithButtons.indexOf(action2),
      ) || [];

  const standardActions =
    availablePipelineActions
      ?.filter(
        (action) =>
          standardActionsWithButtons.includes(action) &&
          isActionAvailable({ action, lead }),
      )
      .sort(
        (action1, action2) =>
          standardActionsWithButtons.indexOf(action1) -
          standardActionsWithButtons.indexOf(action2),
      ) || [];

  return {
    airbnbActions,
    standardActions,
    takeAction,
    isExecutingAction,
  };
};

export default useLeadActions;
