import * as Yup from 'yup';

const emailSchema = () => ({
  emailsTo: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().email(),
      }),
    )
    .min(1),
  sendCopyToAgency: Yup.boolean(),
  subject: Yup.string(),
});

export default emailSchema;
