import { SortingState } from '@tanstack/react-table';
import { ExpectedQueryData } from 'components/table/hooks/useServerFilteredTable';
import {
  GetPropertyExpectationsResponseTO,
  PropertyExpectation,
} from 'models/PropertyExpectations';
import API from 'services/API';

export const PROPERTIES_EXPECTATIONS_BASE_QUERY_KEY = 'property-expectations';

export const propertyExpectationsQuery = ({
  propertyUid,
  pageIndex,
  sorting,
}: {
  propertyUid: string;
  pageIndex: number;
  pageSize: number;
  sorting: SortingState;
}) => ({
  queryKey: [
    PROPERTIES_EXPECTATIONS_BASE_QUERY_KEY,
    'list',
    propertyUid,
    { pageIndex, sorting },
  ],
  queryFn: async (): Promise<ExpectedQueryData<PropertyExpectation>> => {
    const response = await API.get<GetPropertyExpectationsResponseTO>(
      '/api/internal/property-expectations',
      {
        params: {
          objectUid: propertyUid,
          _limit: 6,
          ...(pageIndex > 0
            ? { _cursor: btoa(`{"offset":${pageIndex * 6 + 1}}`) }
            : {}),
        },
      },
    );

    const { propertyExpectations, _metadata: metadata } = response.data;

    return {
      metadata,
      data: propertyExpectations,
    };
  },
  enabled: true,
});
