import styled from 'styled-components';

export const PropertyStatusStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

  input {
    scale: 0.7;
  }
`;
