import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import { OrderViewTableHeader } from '../OrderView.styles';
import OrderViewTransactionsList from './OrderViewTransactionsList';
import AddManualTransactionButton from './actions/AddManualTransactionButton';
import AdditionalChargeButton from './actions/AdditionalChargeButton';

const OrderViewTransactions = () => {
  const { t } = useTranslation();

  return (
    <section>
      <OrderViewTableHeader>
        <Col xs={5}>
          <h4 data-testid="transactions-list-title">
            {t('pageOrder.transactions.sectionTitle')}
          </h4>
        </Col>
        <Col xs={7} className="text-right">
          <AdditionalChargeButton />
          <AddManualTransactionButton />
        </Col>
      </OrderViewTableHeader>
      <OrderViewTransactionsList />
    </section>
  );
};

export default OrderViewTransactions;
