const PurplePaintSvg = () => {
  return (
    <svg
      width="84"
      height="79"
      viewBox="0 0 84 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.21807 23.599C1.9459 28.6563 0.293355 34.3206 0.536246 39.6466C0.642181 42.7034 1.13652 45.6496 2.00812 48.4185C2.28887 49.3876 2.59065 50.3205 2.91143 51.2343C3.86799 54.0479 5.02972 56.7505 6.38892 59.3241C8.13375 62.6446 10.2851 65.6791 12.8051 68.3744C16.163 71.9673 20.3181 74.7347 25.0578 76.5349C29.9364 78.3875 35.4249 79.0011 41.1207 78.3308C46.8166 77.6604 52.5755 75.7229 57.9753 72.6605C64.6845 68.9064 70.6451 63.5954 75.2319 57.2846C80.766 49.4739 83.6345 40.4978 83.2935 32.0584C82.7186 22.6062 78.7373 14.4052 72.0375 8.87269C69.1493 6.46457 65.8477 4.54859 62.2248 3.17809C55.7462 0.714543 48.4017 0.0260393 40.8133 1.17091C35.6253 1.98485 30.4176 3.70073 25.4299 6.23948C20.5117 8.67414 15.8806 11.8969 11.757 15.7546C9.25937 18.1049 7.05467 20.7497 5.21807 23.599Z"
        fill="#8A5BDA"
      />
    </svg>
  );
};

export default PurplePaintSvg;
