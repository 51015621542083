import useAppQuery from 'hooks/useAppQuery';
import {
  GetPropertyRentalConditionsResponse,
  RentalConditionResourceType,
} from 'models/RentalCondition';
import { useParams } from 'react-router-dom';
import API from 'services/API';

export const RENTAL_CONDITIONS_BASE_QUERY_KEY = 'rental-conditions';

const useRentalConditionsData = () => {
  const { uid: propertyUid } = useParams();

  const { data: rentalConditionData, isLoading: isLoadingRentalConditions } =
    useAppQuery(
      [
        RENTAL_CONDITIONS_BASE_QUERY_KEY,
        RentalConditionResourceType.PROPERTY,
        propertyUid,
      ],
      async () => {
        const response = await API.get<GetPropertyRentalConditionsResponse>(
          `v3/rental-conditions/${propertyUid}`,
          {
            params: {
              resourceType: RentalConditionResourceType.PROPERTY,
            },
          },
        );
        return response.data.rentalConditions;
      },
      {
        enabled: !!propertyUid,
      },
    );

  return {
    rentalConditionData,
    isLoadingRentalConditions,
  };
};

export default useRentalConditionsData;
