import { FormEventHandler } from 'react';
import UploadFieldFileList from 'components/forms/fileField/UploadField/UploadFieldFileList';
import { OwnerAdjustmentFileResponse } from 'models/ownerAdjustments/OwnerAdjustmentFile';
import { useFormContext } from 'react-hook-form';
import useUploadFileMutation from './hooks/useUploadFileMutation';
import { UploadFieldStyled } from './OwnerAdjustmentModal.styles';
import useDeleteFileMutation from './hooks/useDeleteFileMutation';
import useCurrentSelectedFile from './hooks/useCurrentSelectedFile';

const OwnerAdjustmentModalUploadField = () => {
  const { formState, trigger, setValue } = useFormContext();
  const { currentFile, setCurrentFile } = useCurrentSelectedFile();

  const { mutate: uploadFile, isLoading: isUploading } = useUploadFileMutation({
    onSuccess: (savedFile) => setCurrentFile(savedFile),
    onError: () => setCurrentFile({ url: null, fileName: null }),
  });

  const { mutate: deleteFile, isLoading: isDeleting } = useDeleteFileMutation({
    onSuccess: () => setCurrentFile({ url: null, fileName: null }),
  });

  const handleOnChange: FormEventHandler<HTMLInputElement> = async (event) => {
    const fileList = Array.from((event.target as HTMLInputElement).files);

    setValue('attachment', fileList);

    const isValid = await trigger('attachment');

    if (!fileList.length || !isValid) {
      setValue('attachment', null);
      return;
    }

    const [file] = fileList;
    setCurrentFile({ url: '', fileName: file.name });
    uploadFile(file);
  };

  const handleOnDelete = (fileToDelete: OwnerAdjustmentFileResponse) => {
    // initial file should only be deleted if the user saves, since the user can cancel edition in the modal
    if (fileToDelete.url === formState.defaultValues.attachedImageURL) {
      setCurrentFile({ url: null, fileName: null });
      return;
    }

    deleteFile(fileToDelete);
  };

  return (
    <UploadFieldStyled
      $isVisible={!currentFile}
      label="Attachment"
      name="attachment"
      accept="image/*"
      onChange={handleOnChange}
    >
      {currentFile && (
        <UploadFieldFileList
          files={[currentFile]}
          getName={(file) => file.fileName}
          getUrl={(file) => file.url}
          isDisabled={isUploading || isDeleting}
          onDelete={handleOnDelete}
        />
      )}
    </UploadFieldStyled>
  );
};

export default OwnerAdjustmentModalUploadField;
