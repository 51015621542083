import useAppModal from 'hooks/useAppModal';
import ImportBookingDotComPropertiesConfirmationModal from './modal/ImportBookingDotComPropertiesConfirmationModal';

const modalId = 'importBookingDotComPropertiesConfirmationModal';

const useImportBookingDotComPropertiesConfirmationModal = () => {
  const { openModal, closeModal } = useAppModal();

  const openBookingDotComPropertiesConfirmationModal = () => {
    openModal({
      id: modalId,
      customContent: (
        <ImportBookingDotComPropertiesConfirmationModal
          onClose={() => closeModal(modalId)}
        />
      ),
    });
  };

  return { openBookingDotComPropertiesConfirmationModal };
};

export default useImportBookingDotComPropertiesConfirmationModal;
