import React from 'react';
import {
  RichTextEditorControls,
  RichTextEditorControlsLoader,
  RichTextEditorControlsWrapper,
} from '../RichTextEditor.styles';
import { RichTextEditorControlsPluginProps } from '../RichTextEditor.types';
import Loader from '../../loader/Loader';
import ControlsPluginButtons from './ControlsPluginButtons';
import ControlsPluginAttachments from './ControlsPluginAttachments';
import ControlsPluginSuggestAnswer from './ControlsPluginSuggestAnswer';

const ControlsPlugin = ({
  controlsSettings: {
    attachmentsAvailable,
    attachmentIconDisabled,
    suggestAnswerAvailable,
    buttons,
    buttonsSwitcher,
    customIcons,
    isLoading,
    onAttachmentSelected,
    chatHistory,
    setSuggestion,
    suggestionButtonDisabled,
    globalContext,
    suggestionButtonLoading,
    getSuggestions,
    activeLead,
  },
}: RichTextEditorControlsPluginProps) => {
  if (isLoading) {
    return (
      <RichTextEditorControls>
        <Loader inline />
      </RichTextEditorControls>
    );
  }

  return (
    <RichTextEditorControlsWrapper>
      {suggestionButtonLoading && (
        <RichTextEditorControlsLoader>
          <Loader inline />
        </RichTextEditorControlsLoader>
      )}

      <RichTextEditorControls>
        {customIcons?.map(({ iconElement, id }) => (
          <React.Fragment key={id}>{iconElement}</React.Fragment>
        ))}
        {attachmentsAvailable && (
          <ControlsPluginAttachments
            disabled={attachmentIconDisabled}
            onAttachmentSelected={onAttachmentSelected}
          />
        )}
        {suggestAnswerAvailable && (
          <ControlsPluginSuggestAnswer
            suggestionButtonDisabled={suggestionButtonDisabled}
            globalContext={globalContext}
            suggestionButtonLoading={suggestionButtonLoading}
            getSuggestions={getSuggestions}
            chatHistory={chatHistory}
            setSuggestion={setSuggestion}
            activeLead={activeLead}
          />
        )}
        <ControlsPluginButtons
          buttons={buttons}
          buttonsSwitcher={buttonsSwitcher}
        />
      </RichTextEditorControls>
    </RichTextEditorControlsWrapper>
  );
};

export default ControlsPlugin;
