const ArrowDown = () => {
  return (
    <svg
      width="8"
      style={{ display: 'block', transform: 'rotate(-90deg)' }}
      height="14"
      viewBox="0 0 8 14"
      fill="rgb(58, 132, 215);"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00081 0.999917L7.0004 1.00033L1.35339 6.64633L0.999747 6.99992L1.35339 7.3535L7.00036 12.9995L7.00055 12.9999L7.00036 13.0004L6.99992 13.0005L6.99947 13.0004L0.999471 7.00036L0.999023 6.99992L0.99947 6.99947L6.99947 0.999471L6.99992 0.999023L7.35392 0.645917L7.00081 0.999917Z"
        fill="#212529"
        stroke="#3A84D7"
      />
    </svg>
  );
};

export default ArrowDown;
