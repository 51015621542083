import SectionHeader from 'pages/property/common/SectionHeader';
import { useTranslation } from 'react-i18next';
import HouseRulesTable from './HouseRulesTable';
import useHouseRulesModal from './modals/useHouseRulesModal';

const HouseRules = () => {
  const { t } = useTranslation();
  const { openBulkCreateModal } = useHouseRulesModal();

  return (
    <section>
      <SectionHeader
        id="house-rules-header"
        title={t('pageProperty.feesTaxesAndPolicies.houseRules.title')}
        button={{
          label: t('pageProperty.feesTaxesAndPolicies.houseRules.add'),
          onClick: openBulkCreateModal,
        }}
        tooltip={t('pageProperty.feesTaxesAndPolicies.houseRules.tooltip')}
      />

      <HouseRulesTable />
    </section>
  );
};

export default HouseRules;
