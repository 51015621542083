import useAppModal from 'hooks/useAppModal';
import { ConvertToUnitTypeModalProps } from './ConvertToUnitTypeModal.types';
import ConvertToUnitTypeModal from './ConvertToUnitTypeModal';
import { convertToUnitTypeModalId } from './ConvertToUnitTypeModal.constants';

const useConvertToUnitTypeModal = () => {
  const { openModal } = useAppModal();

  const openConvertToUnitTypeModal = ({
    propertyUid,
  }: ConvertToUnitTypeModalProps) => {
    openModal({
      customContent: <ConvertToUnitTypeModal propertyUid={propertyUid} />,
      id: convertToUnitTypeModalId,
      size: 'medium',
    });
  };

  return {
    openConvertToUnitTypeModal,
  };
};

export default useConvertToUnitTypeModal;
