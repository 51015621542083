import styled from 'styled-components';
import { Alert, Col, Glyphicon, Row } from 'react-bootstrap';
import Loader from '../../../components/loader/Loader';
import Button from '../../../components/button/Button';

export const InboxNoMessagesInfo = styled(Alert).attrs({
  className: 'text-center',
})``;

export const InboxMessageContainer = styled.div.attrs(
  (props: { uid: string }) => ({
    'data-testid': `inbox-message-item-${props.uid}`,
  }),
)<{ uid: string }>`
  padding: 0.5em 1.5em 0 0;
  margin-bottom: 1.2em;
`;

export const InboxMessageSectionWrapper = styled(Row)<{ $alignRight: boolean }>`
  display: flex;
  ${(props) =>
    props.$alignRight &&
    `
    justify-content: flex-end;
  `}
`;

export const InboxMessageAvatarContainer = styled(Col).attrs({
  xs: 3,
  sm: 2,
  md: 1,
})``;

export const InboxMessageContentContainer = styled(Col).attrs({
  xs: 9,
  sm: 10,
  md: 10,
})``;

export const InboxMessageContent = styled.div.attrs({
  'data-testid': 'inbox-message-content',
})<{ $hasError: boolean }>`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 15px 50px 0 rgb(82 63 105 / 15%);
  padding: 1em;

  ${(props) =>
    props.$hasError &&
    `
    border: 1px solid #F64E60;
  `}

  &:not(.collapse-line-breaks) > div {
    white-space: pre-wrap;
  }
`;

export const InboxMessageFooterContainer = styled(Col).attrs(
  (props: { $alignRight: boolean }) => ({
    className: props.$alignRight ? 'text-right' : '',
    xs: 9,
    sm: 10,
    md: 10,
    xsOffset: props.$alignRight ? undefined : 3,
    smOffset: props.$alignRight ? undefined : 2,
    mdOffset: props.$alignRight ? undefined : 1,
  }),
)<{ $alignRight: boolean }>`
  padding: 0 1em;
`;

export const InboxMessageAttachmentIcon = styled(Glyphicon).attrs({
  className: 'small',
  glyph: 'paperclip',
})`
  margin-right: 0.3em;
  top: 2px;
`;

export const InboxMessageAttachmentLink = styled.a.attrs({
  className: 'small',
  'data-testid': 'inbox-message-attachment',
  rel: 'noreferrer',
  target: '_blank',
})`
  cursor: pointer;
`;

export const InboxMessageDate = styled.div.attrs({
  className: 'small text-muted',
  'data-testid': 'inbox-message-date',
})``;

export const InboxMessageSendError = styled.div.attrs({
  className: 'small text-danger',
  'data-testid': 'inbox-message-error',
})``;

export const InboxMessageSendingLoader = styled(Loader).attrs({
  'data-testid': 'inbox-message-sending-loader',
  inline: true,
})``;

export const InboxMessageResendButton = styled(Button).attrs({
  'data-testid': 'inbox-message-resend-button',
  className: ' btn-link btn-xs',
})`
  margin-top: 0.5em;
`;
