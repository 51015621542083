import LeadListItemBasicInfo from 'components/domain/lead/list/LeadListItemBasicInfo';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useOpenLeadModal from 'components/domain/lead/modal/useOpenLeadModal';
import LeadListItemCreationDetails from 'components/domain/lead/list/LeadListItemCreationDetails';
import useOrderViewFetchedData from '../useOrderViewFetchedData';
import {
  OrderViewDetailsItem,
  OrderViewDetailsItemList,
  OrderViewDetailsWrapper,
  OrderViewDetailsLeadWrapper,
} from './OrderViewDetails.styles';

const OrderViewDetails = () => {
  const { t } = useTranslation();
  const { updateLeadModal } = useOpenLeadModal();
  const { lead, initData } = useOrderViewFetchedData();
  const onClick = () => {
    updateLeadModal({ jobCreationNotAllowed: true, leadUid: lead.uid });
  };

  return (
    <OrderViewDetailsWrapper>
      <div>
        <OrderViewDetailsLeadWrapper
          data-testid="order-view-details-lead"
          onClick={onClick}
        >
          <LeadListItemCreationDetails lead={lead} />
          <LeadListItemBasicInfo lead={lead} />
        </OrderViewDetailsLeadWrapper>
      </div>
      <div>
        <OrderViewDetailsItemList>
          {lead?.order?.uid && (
            <OrderViewDetailsItem data-testid="order-view-details-item-order-uid">
              <Col xs={4}>
                <b>{t('pageOrder.details.orderUID')}</b>
              </Col>
              <Col xs={8}>{lead.order.uid}</Col>
            </OrderViewDetailsItem>
          )}
          {!!initData.orderId && (
            <OrderViewDetailsItem data-testid="order-view-details-item-order-id">
              <Col xs={4}>
                <b>{t('pageOrder.details.orderID')}</b>
              </Col>
              <Col xs={8}>{initData.orderId}</Col>
            </OrderViewDetailsItem>
          )}
          {!!lead.externalBookingID && (
            <OrderViewDetailsItem data-testid="order-view-details-item-external-id">
              <Col xs={4}>
                <b>{t('pageOrder.details.externalBookingID')}</b>
              </Col>
              <Col xs={8}>{lead.externalBookingID}</Col>
            </OrderViewDetailsItem>
          )}
          {!!initData.quickbooksSalesReceiptId && (
            <OrderViewDetailsItem data-testid="order-view-details-item-qcb-id">
              <Col xs={4}>
                <b>{t('pageOrder.details.quickbooksSalesReceipt')}</b>
              </Col>
              <Col xs={8}>
                <a
                  target="_blank"
                  href={`/integrations/quickbooks/generate_salesreceipt.jsp?o=${lead.order?.uid}`}
                  rel="noreferrer"
                >
                  {initData.quickbooksSalesReceiptId}
                </a>
              </Col>
            </OrderViewDetailsItem>
          )}
        </OrderViewDetailsItemList>
      </div>
    </OrderViewDetailsWrapper>
  );
};

export default OrderViewDetails;
