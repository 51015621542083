import { AppEventPayload, AppEventType } from '../models/Events';

const useAppEvent = <T extends AppEventPayload>() => {
  const subscribe = (
    eventType: AppEventType,
    listener: EventListenerOrEventListenerObject,
  ) => {
    document.addEventListener(eventType, listener);
  };

  const unsubscribe = (
    eventType: AppEventType,
    listener: EventListenerOrEventListenerObject,
  ) => {
    document.removeEventListener(eventType, listener);
  };

  const publish = (eventType: AppEventType, payload: T) => {
    document.dispatchEvent(new CustomEvent(eventType, { detail: payload }));
  };

  return { subscribe, unsubscribe, publish };
};

export default useAppEvent;
