import styled from 'styled-components';
import { Col } from 'react-bootstrap';

export const LeadClientInfoFormColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  padding-top: 25px;

  .address2-container,
  .last-name-container {
    margin-bottom: 30px;
  }
`;
