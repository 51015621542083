import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FormWithProvider from 'components/forms/form/Form';
import useFetchHotels from 'components/domain/property/useFetchHotels';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { API_MAX_LIMIT } from 'services/API.constants';
import CommonModalFooter from '../common/CommonModalFooter';
import { ModalDescriptionStyled } from '../common/CommonModal.styles';
import { ConvertToUnitTypeModalProps } from './ConvertToUnitTypeModal.types';
import ConvertToUnitTypeModalFormFields from './ConvertToUnitTypeModalFormFields';
import convertToUnitTypeModalSchema from './ConvertToUnitTypeModal.schema';
import useConvertToUnitTypeModalForm from './useConvertToUnitTypeModalForm';
import { convertToUnitTypeModalId } from './ConvertToUnitTypeModal.constants';
import { ModalSubmitFeedback } from './ConvertToUnitTypeModal.styles';

const ConvertToUnitTypeModal = ({
  propertyUid,
}: ConvertToUnitTypeModalProps) => {
  const { t } = useTranslation();
  const { data: hotels, isLoading: isFetchingHotels } = useFetchHotels({
    limit: API_MAX_LIMIT,
  });
  const { isUpdating, handleSubmit } =
    useConvertToUnitTypeModalForm(propertyUid);
  const isDisabled = isUpdating || isFetchingHotels;

  return (
    <div data-testid="convert-to-unit-type-modal">
      <Modal.Header data-testid="convert-to-unit-type-modal-header" closeButton>
        {t('pageProperties.actions.convertToUnitType')}
      </Modal.Header>

      {isFetchingHotels && (
        <>
          <Modal.Body>
            <ModalDescriptionStyled>
              {t(
                'pageProperties.actions.common.descriptionAutomaticallyCreateProperties',
              )}
            </ModalDescriptionStyled>
            <LoadingPlaceholder linesCount={2.5} margin="0 0 15px" />
            <LoadingPlaceholder linesCount={2.5} margin="0 0 15px" />
          </Modal.Body>

          <Modal.Footer>
            <LoadingPlaceholder linesCount={2.5} width="250px" />
          </Modal.Footer>
        </>
      )}

      {!isFetchingHotels && (
        <FormWithProvider
          defaultValues={{ units: null, multiUnitProperty: hotels[0].uid }}
          onSubmit={handleSubmit}
          schema={convertToUnitTypeModalSchema()}
          disabled={isDisabled}
          noValidate
        >
          <fieldset disabled={isDisabled}>
            <Modal.Body>
              <ModalDescriptionStyled>
                {t(
                  'pageProperties.actions.common.descriptionAutomaticallyCreateProperties',
                )}
              </ModalDescriptionStyled>
              <ConvertToUnitTypeModalFormFields hotels={hotels} />
            </Modal.Body>

            <Modal.Footer data-testid="convert-to-unit-type-modal-footer">
              <CommonModalFooter
                isUpdating={isUpdating}
                modalId={convertToUnitTypeModalId}
                submitLabel={t('pageProperties.actions.convertToUnitType')}
              >
                {isUpdating && (
                  <ModalSubmitFeedback>
                    {t(
                      'pageProperties.actions.convertToUnitTypeModal.thisProcessMayTakeLonger',
                    )}
                  </ModalSubmitFeedback>
                )}
              </CommonModalFooter>
            </Modal.Footer>
          </fieldset>
        </FormWithProvider>
      )}
    </div>
  );
};

export default ConvertToUnitTypeModal;
