import { ImageCircle } from 'components/image/ImageCircle.styles';
import LoadingPlaceholder from '../../../loader/LoadingPlaceholder';
import { BasicInfoWrapper } from './LeadListItemBasicInfo.styles';
import { InfoLoadingPlaceholderWrapper } from './LeadListItemBasicInfoLoadingPlaceholder.styles';

const LeadListItemBasicInfoLoadingPlaceholder = () => {
  return (
    <BasicInfoWrapper>
      <ImageCircle>
        <LoadingPlaceholder circle width="60px" />
      </ImageCircle>
      <InfoLoadingPlaceholderWrapper>
        <LoadingPlaceholder linesCount={1.8} width="65%" />
        <LoadingPlaceholder linesCount={1.2} width="50%" />
        <LoadingPlaceholder linesCount={1.2} width="40%" />
      </InfoLoadingPlaceholderWrapper>
    </BasicInfoWrapper>
  );
};

export default LeadListItemBasicInfoLoadingPlaceholder;
