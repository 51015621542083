import { compare } from './sortUtils';

export function areArraysEqual(array1, array2): boolean {
  return (
    array1.every((val, idx) => val === array2[idx]) &&
    array2.every((val, idx) => val === array1[idx])
  );
}

export function sumAll(array: (number | undefined)[]): number {
  return array.reduce((result, current) => result + (current ?? 0), 0);
}

interface SortCriteria<T> {
  field: keyof T | ((item: T) => unknown);
  order: 'asc' | 'desc';
}

export function orderBy<T>(array: T[], criteria: SortCriteria<T>[]): T[] {
  return array.slice().sort((a, b) => {
    let result = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const { field, order } of criteria) {
      const valueA = typeof field === 'function' ? field(a) : a[field];
      const valueB = typeof field === 'function' ? field(b) : b[field];

      result = compare(valueA, valueB);

      if (result !== 0) {
        result = order === 'desc' ? result * -1 : result;
        break;
      }
    }

    return result;
  });
}

export function differenceWith<T1, T2>(
  array1: T1[],
  array2: T2[],
  comparator: (a: T1, b: T2) => boolean,
): T1[] {
  return array1.filter((a) => !array2.find((b) => comparator(a, b)));
}

/**
 * Returns an array containing numbers progressing from `start` up to, but not including, `end`, with an optional step
 * param as the last argument.
 * e.g:
 * ```js
 * range(-1, 2) // [-1, 1]
 * range(0, 10, 3) // [0, 3, 6, 9]
 * ```
 */
export function range(start: number, end: number, step?: number): number[] {
  if (step === 0) {
    return [];
  }

  let actualStep = step;

  if (step === undefined) {
    // If both start and end are negative and no step was provided, use -1 as step, otherwise +1.
    actualStep = start < 0 && end < 0 ? -1 : 1;
  }

  const length = Math.max(Math.ceil((end - start) / actualStep), 0);

  return Array.from({ length }, (_, i) => start + i * actualStep);
}
