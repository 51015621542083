const TickLeadIcon = ({ statusColor }: { statusColor: string }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 23.25C18.6875 23.25 23.75 18.1875 23.75 12C23.75 5.8125 18.6875 0.75 12.5 0.75C6.3125 0.75 1.25 5.8125 1.25 12C1.25 18.1875 6.3125 23.25 12.5 23.25Z"
      stroke={statusColor || '#999999'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.71875 11.9999L10.9025 15.1836L17.2813 8.8161"
      stroke={statusColor || '#999999'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TickLeadIcon;
