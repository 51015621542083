const isEqual = (a: unknown, b: unknown) => a === b;

export const isDeepEqual = <A, B>(valueA: A, valueB: B) => {
  if (valueA instanceof Date && valueB instanceof Date) {
    return valueA.getTime() === valueB.getTime();
  }

  if (Array.isArray(valueA) && Array.isArray(valueB)) {
    if (valueA.length !== valueB.length) return false;

    return valueA.every((a, index) => {
      const b = valueB[index];

      return isDeepEqual(a, b);
    });
  }

  if (valueA instanceof Object && valueB instanceof Object) {
    return isDeepEqual(Object.entries(valueA), Object.entries(valueB));
  }

  return isEqual(valueA, valueB);
};
