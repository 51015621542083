import { BaseResponse } from '../_base';
import { LeadChannel, LeadSource, LeadStatus } from '../Leads';
import { MessagingChannel } from './Messages';

export enum ThreadParticipantType {
  AGENCY = 'AGENCY',
  AGENT = 'AGENT',
  GUEST = 'GUEST',
  OWNER = 'OWNER',
  LEAD = 'LEAD',
  /* eslint-disable no-unused-vars */
  PMP = 'PMP',
  PM_EMPLOYEE = 'PM_EMPLOYEE',
  /* eslint-enable no-unused-vars */
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
}

export enum ThreadReadStatus {
  READ = 'READ',
  UNREAD = 'UNREAD',
}

export interface ThreadAirbnbData {
  threadUid: string;
  airbnbThreadId: string;
  airbnbUserAirbnbId: string;
  airbnbUserHostfullyUid: string;
}

export interface ThreadBookingComData {
  threadUid: string;
  bookingDotComConversationId: string;
  bookingDotComPropertyId: string;
  isTagged: boolean;
  guestParticipantId: string;
  pmpParticipantId: string;
}

export interface ThreadParticipant {
  participantType: ThreadParticipantType;
  participantUid: string;
  uid: string;
  bestProfile?: ThreadParticipantBestProfile;
}

export interface ThreadParticipantReadStatus {
  threadUid: string;
  participantUid: string;
  readStatus: ThreadReadStatus;
}

export interface Thread {
  agencyUid: string;
  airbnbData?: ThreadAirbnbData;
  bcomData?: ThreadBookingComData;
  creationDate: string;
  lastUpdateDate: string;
  lastUpdateDateObj?: Date;
  name: string;
  participants: ThreadParticipant[];
  participantsReadStatuses: ThreadParticipantReadStatus[];
  uid: string;
}

export interface LeadRelatedThread {
  leadUid: string;
  threadUid: string;
}

export interface ThreadRelatedLead {
  threadUid: string;
  leadUid: string;
  propertyUid: string;
  leadCheckInDate: string;
  leadCheckOutDate: string;
  leadCheckInDateObj?: Date;
  leadCheckOutDateObj?: Date;
  leadStatus: LeadStatus;
  leadSource: LeadSource;
  leadChannel: LeadChannel;
}

export interface ThreadRelatedObject {
  type: ThreadRelatedObjectType;
  objectUid: string;
}

export interface ThreadRelatedPropertyResponse extends ThreadRelatedObject {
  propertyName: string;
  propertyUid: string;
  channel?: LeadChannel;
}

export enum ThreadRelatedObjectType {
  LEAD = 'LEAD',
  PROPERTY = 'PROPERTY',
}

export interface ThreadParticipantBestProfile {
  name: string;
  email?: string;
  photoUrl?: string;
}

export interface GetThreadResponseTO extends BaseResponse {
  thread: Thread;
}

export interface GetThreadsResponseTO extends BaseResponse {
  threads: Thread[];
}

export interface GetLeadRelatedThreadsResponseTO extends BaseResponse {
  threadRelatedLeads: LeadRelatedThread[]; // not a typo - backend returns such a field name
}

export interface GetThreadRelatedLeadsResponseTO extends BaseResponse {
  threadRelatedLeads: ThreadRelatedLead[];
}

export interface GetThreadRelatedObjectsResponseDto {
  threadRelatedObjects?: ThreadRelatedObject[];
}

export interface GetThreadParticipantBestProfileResponseTO
  extends BaseResponse {
  bestProfile: ThreadParticipantBestProfile;
}

export interface GetThreadAvailableMessagingChannelsResponseTO
  extends BaseResponse {
  availableMessagingChannels: MessagingChannel[];
}
