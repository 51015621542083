import { Glyphicon } from 'react-bootstrap';
import styled, { css } from 'styled-components';

const sharedIconStyles = css`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  height: 34px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 0px 4px 4px 0px;
  border-left: none;
`;

export const DateTimePickerFieldWrapper = styled.div`
  position: relative;

  & input.form-control {
    width: calc(100% - 40px);
    border-top-right-radius: 0 !important; // important must be used in order to enforce proper border when right tooltip is present
    border-bottom-right-radius: 0 !important;
  }

  .date-time-picker-popper {
    z-index: 999;
  }
`;

export const CalendarIcon = styled(Glyphicon).attrs({
  glyph: 'calendar',
})`
  ${sharedIconStyles}
`;

export const ClockIcon = styled(Glyphicon).attrs({
  glyph: 'time',
})`
  ${sharedIconStyles}
`;
