import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  ${mediaQuery.xs} {
    margin-top: 40px;
  }
`;

export const Item = styled.li``;

export const EndMessageParagraph = styled.p`
  padding: 20px;
  text-align: center;
`;
