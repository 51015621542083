import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';
import theme from 'styles/theme';

export const Container = styled.section`
  width: 1440px;
  padding: 20px;
  margin: -80px 0 50px 0;
  position: relative;
  z-index: 100;

  p {
    text-align: start;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    color: #212529;
  }

  ${mediaQuery.custom(1024)} {
    margin: 50px 0;
    padding: 10px;

    p {
      text-align: center;
      font-size: 16px;
    }
  }

  ${mediaQuery.custom(1920)} {
    width: fit-content;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;

  a {
    font-size: 18px;
    display: flex;
    color: ${theme.colors.fontGreen};
    text-decoration: none;
  }

  ${mediaQuery.custom(1024)} {
    justify-content: center;
    align-items: center;
    flex-direction: column;

    a {
      font-size: 14px;
    }
  }
`;

export const Pipe = styled.span`
  display: block;
  margin-right: 10px;

  ${mediaQuery.custom(1024)} {
    display: none;
  }
`;
