import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import { LeadInternalResponse, LeadStatus } from 'models/Leads';
import { useTranslation } from 'react-i18next';
import API from 'services/API';
import useAppEvent from '../../../hooks/useAppEvent';
import { AppEventType } from '../../../models/Events';

interface UseBlockActionsProps {
  modalId: string;
  blockUid?: string;
}
interface CreateBlockPayload {
  checkInLocalDate: string;
  checkOutLocalDate: string;
  unitUid?: string;
  notes: string;
  propertyUid: string;
  status: LeadStatus;
  agencyUid: string;
}

const useBlockActions = ({ blockUid, modalId }: UseBlockActionsProps) => {
  const { notifySuccess, notifyError } = useNotify();
  const { closeModal } = useAppModal();
  const { publish } = useAppEvent();
  const { t } = useTranslation();

  const { mutate: createBlock, isLoading: isCreateBlockLoading } =
    useAppMutation(
      (payload: CreateBlockPayload) => {
        return API.post<LeadInternalResponse>('/api/internal/leads', payload);
      },
      {
        onSuccess: ({ data }) => {
          publish(AppEventType.LEAD_CREATED, { lead: data.lead });
          notifySuccess(t('pageCalendar.block.blockCreated'));
          closeModal(modalId);
        },
        onError: ({ response }) => {
          notifyError(response.data.apiErrorMessage);
        },
      },
    );

  const { mutate: updateBlock, isLoading: isUpdateBlockLoading } =
    useAppMutation(
      (payload: any) => {
        return API.patch<LeadInternalResponse>(
          `/api/internal/leads/${blockUid}`,
          payload,
        );
      },
      {
        onSuccess: ({ data }) => {
          publish(AppEventType.LEAD_UPDATED, { lead: data.lead });
          notifySuccess(t('pageCalendar.block.blockUpdated'));
          closeModal(modalId);
        },
        onError: ({ response }) => {
          notifyError(response.data.apiErrorMessage);
        },
      },
    );

  const { mutate: deleteBlock, isLoading: isDeleteBlockLoading } =
    useAppMutation(
      () => {
        return API.delete(`/api/v3/leads/${blockUid}`);
      },
      {
        onSuccess: () => {
          publish(AppEventType.LEAD_DELETED, { leadUid: blockUid });
          notifySuccess(t('pageCalendar.block.blockDeleted'));
          closeModal(modalId);
        },
        onError: () =>
          notifyError(t('pageCalendar.stacked.somethingWentWrong')),
      },
    );

  const isAnyBlockRequestLoading =
    isCreateBlockLoading || isUpdateBlockLoading || isDeleteBlockLoading;

  return {
    createBlock,
    updateBlock,
    deleteBlock,
    isAnyBlockRequestLoading,
  };
};
export default useBlockActions;
