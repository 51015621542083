import { useContext } from 'react';
import { startOfToday, startOfTomorrow } from 'date-fns';
import useAppEvent from 'hooks/useAppEvent';
import useAppModal from 'hooks/useAppModal';
import { AppEventType } from 'models/Events';
import { LeadAvailablePipelineAction } from 'models/Leads';
import LeadModalContext from './LeadModalContext';

const useLeadRebook = () => {
  const { publish } = useAppEvent();
  const { closeModal } = useAppModal();
  const { lead, modalId } = useContext(LeadModalContext);

  const isRebookActionAvailable = lead?.availablePipelineActions?.includes(
    LeadAvailablePipelineAction.REBOOK_AS_NEW_LEAD,
  );

  const prepareLeadRebookForm = () => {
    closeModal(modalId);

    publish(AppEventType.OPEN_CREATE_LEAD_MODAL, {
      params: {
        dateFrom: startOfToday(),
        dateTo: startOfTomorrow(),
        leadForRebookingUid: lead?.uid,
        jobCreationNotAllowed: true,
      },
    });
  };

  return { isRebookActionAvailable, prepareLeadRebookForm };
};
export default useLeadRebook;
