import { CSSProperties } from 'react';
import { SerializedTableNode, TableNode } from '@lexical/table';
import {
  $applyNodeReplacement,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalEditor,
  NodeKey,
  Spread,
} from 'lexical';
import {
  convertCssPropertiesToCssText,
  convertStyleToCssProperties,
  SerializedStyledNode,
} from './lexicalNodes.utils';

export type SerializedTableStyledNode = Spread<
  SerializedStyledNode,
  SerializedTableNode
>;

export class TableStyledNode extends TableNode {
  style: CSSProperties;

  constructor(style?: CSSProperties, key?: NodeKey) {
    super(key);
    this.style = style;
  }

  static clone(node: TableStyledNode): TableStyledNode {
    return new TableStyledNode(node.style);
  }

  static getType(): string {
    return 'table-styled';
  }

  static importDOM(): DOMConversionMap | null {
    return {
      table: (_node: Node) => ({
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        conversion: convertTableStyledElement,
        priority: 0,
      }),
    };
  }

  static importJSON(
    serializedNode: SerializedTableStyledNode,
  ): TableStyledNode {
    const { style } = serializedNode;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return $createTableStyledNode({ style });
  }

  createDOM(config: EditorConfig, editor?: LexicalEditor): HTMLElement {
    const tableElement = super.createDOM(config, editor);

    if (this.style) {
      tableElement.setAttribute(
        'style',
        convertCssPropertiesToCssText(this.style),
      );
    }

    return tableElement;
  }

  exportJSON(): SerializedTableStyledNode {
    return { ...super.exportJSON(), style: this.style, type: 'table-styled' };
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const domExportOutput = super.exportDOM(editor);
    domExportOutput.element.setAttribute(
      'style',
      convertCssPropertiesToCssText(this.style),
    );
    return domExportOutput;
  }
}

interface TableStyledPayload {
  style?: CSSProperties;
  key?: NodeKey;
}

export function $createTableStyledNode({
  key,
  style,
}: TableStyledPayload): TableStyledNode {
  return $applyNodeReplacement(new TableStyledNode(style, key));
}

export function convertTableStyledElement(
  domNode: Node,
): null | DOMConversionOutput {
  if (domNode instanceof HTMLTableElement) {
    return {
      node: $createTableStyledNode({
        style: convertStyleToCssProperties(domNode.style),
      }),
    };
  }

  return null;
}
