import useFetchAgencyChannelSettings from 'components/domain/channelSettings/useFetchAgencyChannelSettings';
import useBookingDotComOperationsContracts from './useBookingDotComOperationsContracts';

const useImportBookingDotComPropertiesModalInit = () => {
  const { contracts, isLoading: isContractsLoading } =
    useBookingDotComOperationsContracts();
  const { isFetching: isFetchingAgencyChannelSettings, channelSettings } =
    useFetchAgencyChannelSettings();

  return {
    contracts,
    isLoading: isContractsLoading || isFetchingAgencyChannelSettings,
    channelSettings,
  };
};

export default useImportBookingDotComPropertiesModalInit;
