import { useTranslation } from 'react-i18next';
import { useFetchPropertiesFilterOptions } from 'components/domain/property/useFetchPropertiesFilterOptions';
import RangeField, {
  RangeFieldProps,
} from 'components/forms/rangeField/RangeField';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { PropertiesFilterOptions } from 'models/PropertiesFilter';

interface GuestsRangeFieldProps extends Omit<RangeFieldProps, 'min' | 'max'> {
  filterOptions?: PropertiesFilterOptions;
}

const GuestsRangeField = ({
  filterOptions: filterOptionsProp,
  ...rest
}: GuestsRangeFieldProps) => {
  const { t } = useTranslation();
  const { data, isLoading } = useFetchPropertiesFilterOptions({
    enabled: !filterOptionsProp,
  });
  const filterOptions = filterOptionsProp ?? data;

  if (isLoading) return <LoadingPlaceholder />;

  return (
    <RangeField
      colSmInput={8}
      colSmLabel={4}
      label={t('pageCalendar.stacked.guests')}
      name="guests"
      min={filterOptions?.minGuests}
      max={filterOptions?.maxGuests}
      {...rest}
    />
  );
};

GuestsRangeField.defaultProps = {
  filterOptions: undefined,
};

export default GuestsRangeField;
