import { useTranslation } from 'react-i18next';
import PageHeader from 'components/pageHeader/PageHeader';
import FormWithProvider from 'components/forms/form/Form';
import Loader from 'components/loader/Loader';
import useAppModal from 'hooks/useAppModal';
import useNotify from 'hooks/useNotify';
import EmployeeAccountForm from './EmployeeAccountForm';
import { Container } from './Account.styles';
import useAccountInitialValues from './useAccountInitialValues';
import { accountSchema } from './Account.schema';
import PhoneAuthModal, { modalId } from './PhoneAuthModal';
import useAccountUpdate from './useAccountUpdate';
import {
  AccountSubmitValues,
  AccountUpdateParams,
  PhoneResponseResult,
} from './Account.types';

const Account = () => {
  const { notifySuccess } = useNotify();
  const { initialValues, isInitialLoading, refetch } =
    useAccountInitialValues();
  const {
    updatePhoneAsync,
    uploadPictureAsync,
    updateAccountAsync,
    isActionsLoading,
    isActionsError,
  } = useAccountUpdate();
  const { openModal } = useAppModal();
  const { t } = useTranslation();

  const updatePictureAndAccount = async ({
    picture,
    firstName,
    lastName,
  }: AccountUpdateParams) => {
    let pictureUrl = initialValues?.pictureUrl;

    if (
      (!picture && !firstName && !lastName) ||
      (!picture &&
        firstName === initialValues?.firstName &&
        lastName === initialValues?.lastName)
    ) {
      return;
    }

    if (picture?.[0]) {
      const formData = new FormData();
      formData.append('profilePicture', picture[0], picture[0].name);
      pictureUrl = await uploadPictureAsync(formData);
    }

    await updateAccountAsync({
      firstName,
      lastName,
      pictureUrl,
    });

    refetch();
  };

  const handleSubmit = async (values: AccountSubmitValues) => {
    const {
      picture,
      phoneNumber,
      firstName,
      lastName,
      phoneNumber_areaCode,
      phoneNumber_base,
    } = values;

    const onlyPhoneChanged =
      phoneNumber !== initialValues?.phoneNumber &&
      ((!firstName && !lastName && !picture) ||
        (firstName === initialValues?.firstName &&
          lastName === initialValues?.lastName &&
          !picture));

    if (phoneNumber !== initialValues?.phoneNumber) {
      const { result, code } = await updatePhoneAsync({
        phoneNumber: phoneNumber_base,
        phoneAreaCode: phoneNumber_areaCode,
      });

      if (result === PhoneResponseResult.PHONE_NUMBER_MFA_VERIFICATION_NEEDED) {
        openModal({
          size: 'medium',
          customContent: (
            <PhoneAuthModal
              onSuccessfulVerification={() => {
                if (onlyPhoneChanged) {
                  notifySuccess(t('pageAccount.phoneChangedSuccess'));
                  refetch();
                } else {
                  updatePictureAndAccount({
                    picture,
                    firstName,
                    lastName,
                  });
                }
              }}
              originalMfaCode={code}
              phoneNumber={phoneNumber}
            />
          ),
          id: modalId,
        });
      }

      if (result === PhoneResponseResult.PHONE_NUMBER_UPDATED) {
        if (onlyPhoneChanged) {
          notifySuccess(t('pageAccount.phoneChangedSuccess'));
          refetch();
        } else {
          updatePictureAndAccount({
            picture,
            firstName,
            lastName,
          });
        }
      }
    } else {
      updatePictureAndAccount({
        picture,
        firstName,
        lastName,
      });
    }
  };

  if (isInitialLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <PageHeader title={t('pageAccount.pageHeaderTitle')} />
      <FormWithProvider
        key={JSON.stringify(initialValues)}
        horizontal
        schema={accountSchema()}
        defaultValues={initialValues}
        onSubmit={handleSubmit}
      >
        <EmployeeAccountForm
          refetch={refetch}
          isLoading={isActionsLoading}
          isError={isActionsError}
        />
      </FormWithProvider>
    </Container>
  );
};

export default Account;
