import { Col, ModalBody, ModalFooter, Row } from 'react-bootstrap';
import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from '../../../../forms/textField/TextField';
import SelectField from '../../../../forms/selectField/SelectField';
import Button from '../../../../button/Button';
import { AirbnbCancellationReason } from '../../../../../models/AirbnbCancellationReasons';
import { normalizeKey } from '../../../../../utils/localeUtils';

const CancelAirbnbLeadFormElements = ({ cancellationReasons, onCancel }) => {
  const { isSubmitting } = useFormState();
  const { t } = useTranslation();

  const cancellationReasonOptions = [
    { label: t('common.selectOption'), value: '' },
    ...cancellationReasons.map(({ value }: AirbnbCancellationReason) => ({
      label: t(
        normalizeKey(
          `componentLead.actionsInfo.airbnb.cancellationReason.${value}`,
        ),
      ),
      value,
    })),
  ];

  return (
    <>
      <ModalBody>
        <Row>
          <Col>
            <SelectField
              label={t('componentLead.actionsInfo.airbnb.cancelReasonSelect')}
              name="airbnbReason"
              options={cancellationReasonOptions}
              colSmInput={8}
              colSmLabel={4}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              label={t('componentLead.actionsInfo.airbnb.cancelMessageToGuest')}
              name="messageToGuest"
              colSmInput={8}
              colSmLabel={4}
              componentClass="textarea"
              required
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              label={t(
                'componentLead.actionsInfo.airbnb.cancelMessageToAirbnb',
              )}
              name="messageToAirbnb"
              colSmInput={8}
              colSmLabel={4}
              componentClass="textarea"
              required
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button disabled={isSubmitting} onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button bsStyle="danger" disabled={isSubmitting} type="submit">
          {t('componentLead.actionsInfo.airbnb.cancelConfirm')}
        </Button>
      </ModalFooter>
    </>
  );
};

export default CancelAirbnbLeadFormElements;
