import useAppConfig from 'hooks/useAppConfig';
import useAppUser from 'hooks/useAppUser';
import HostfullyLogo from 'assets/images/hf-logo.svg';
import * as S from './Navigation.styles';

const NavigationLogo = () => {
  const { isGuest, isOwner, isServiceProvider } = useAppUser();
  const {
    customSettings: { logoUrl },
  } = useAppConfig();

  const logoUrlToSet =
    (isGuest || isOwner || isServiceProvider) && logoUrl
      ? logoUrl
      : HostfullyLogo;

  return (
    <S.Navbar.Brand href="/">
      <img className="hf-logo" src={logoUrlToSet} alt="Hostfully" />
    </S.Navbar.Brand>
  );
};

export default NavigationLogo;
