const MoonIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 0.173815C3.81101 0.247947 3.84838 0.338672 3.8573 0.434266C3.86621 0.52986 3.84625 0.625931 3.8 0.710065C3.43866 1.37349 3.24996 2.11712 3.25125 2.87256C3.25125 5.38569 5.3 7.42069 7.825 7.42069C8.15438 7.42069 8.475 7.38632 8.78312 7.32069C8.87758 7.30024 8.97597 7.30806 9.066 7.34318C9.15603 7.37831 9.23373 7.43918 9.28938 7.51819C9.34812 7.60035 9.37807 7.69961 9.37456 7.80055C9.37106 7.90148 9.3343 7.99843 9.27 8.07631C8.7798 8.67846 8.16141 9.16363 7.4599 9.49643C6.75839 9.82924 5.99145 10.0013 5.215 10.0001C2.33375 10.0001 0 7.67882 0 4.81881C0 2.66631 1.32125 0.820065 3.2025 0.0375645C3.29621 -0.0020406 3.40024 -0.0101694 3.49897 0.0143993C3.59769 0.0389679 3.68578 0.0949074 3.75 0.173815ZM3.03625 0.81944C2.31288 1.20746 1.70815 1.78422 1.28633 2.48842C0.864516 3.19262 0.641375 3.99795 0.640625 4.81881C0.640625 7.33132 2.69 9.36632 5.215 9.36632C5.81965 9.36736 6.41851 9.24848 6.97691 9.01653C7.53531 8.78459 8.04216 8.4442 8.46812 8.01507C8.2575 8.04132 8.04313 8.05444 7.825 8.05444C4.94375 8.05444 2.61063 5.73319 2.61063 2.87319C2.61063 2.14381 2.76187 1.44944 3.03625 0.81944Z"
        fill="#999999"
      />
    </svg>
  );
};

export default MoonIcon;
