import styled from 'styled-components';

export const TableQuote = styled.table.attrs({
  'data-testid': 'lead-form-tab-leadDetails-order',
})<{ $isQuoteLoading: boolean }>`
  tr:last-child td {
    height: 50px !important;
    vertical-align: middle !important;
  }

  tr > td:first-child {
    font-weight: bold;
  }

  tr > td:last-child {
    text-align: right;
  }

  ${({ $isQuoteLoading }) => $isQuoteLoading && 'opacity: 0.2;'}
`;

export const LeadModalDetailsOrderWrapper = styled.div`
  .price-details-list-total {
    .price-details-list-item-total-wrapper {
      padding: 10px 0.5rem;
    }

    .order-view-price-details-list-item-total-title {
      flex-direction: column;
    }

    .order-view-price-details-list-item-total-subtitle {
      margin-left: 0;
    }
  }
`;
