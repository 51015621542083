import { useTranslation } from 'react-i18next';
import { HasOrderItems } from 'models/hasOrderItems/HasOrderItems';
import { useFormContext } from 'react-hook-form';
import { PriceDetailsList } from '../OrderViewPriceDetails.styles';
import OrderViewPriceDetailsListItem from '../OrderViewPriceDetailsListItem';

const SecurityDeposit = ({
  order,
  allowEdit,
  includeSecurityDepositInTotal,
}: {
  order: HasOrderItems;
  allowEdit: boolean;
  includeSecurityDepositInTotal: boolean;
}) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  const { securityDeposit, currency } = order;

  return (
    <PriceDetailsList
      $hasNoBorder={!includeSecurityDepositInTotal}
      data-testid="price-details-list-deposit"
    >
      <OrderViewPriceDetailsListItem
        title={t('componentOrder.deposit')}
        priceDetailsItems={[
          {
            label: t('componentOrder.securityDeposit'),
            amount: securityDeposit,
            isTotalAmount: false,
            isEditable: allowEdit,
            onItemUpdated(val) {
              setValue('quote.securityDeposit', val, {
                shouldDirty: true,
              });
            },
            currency,
          },
        ]}
      />
    </PriceDetailsList>
  );
};

export default SecurityDeposit;
