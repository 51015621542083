import styled from 'styled-components';
import { Container as SwitchContainer } from 'components/switch/Switch.styles';

export const SwitchFieldWrapperStyled = styled.div`
  label {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  ${SwitchContainer} {
    width: 50px;
    height: 38px;
  }
`;
