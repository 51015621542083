import styled from 'styled-components';

export const CheckboxGroupFieldStyled = styled.div<{
  $indentCheckboxes: boolean;
}>`
  .options {
    margin-left: ${({ $indentCheckboxes }) =>
      $indentCheckboxes ? '15px' : '0'};
  }
`;
