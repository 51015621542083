import { Alert, Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const ModalWrapper = styled(Modal)<{
  $width: number;
}>`
  .modal-dialog {
    width: ${({ $width }) => `${$width}px`};
  }
`;

export const ModalErrorMessage = styled(Alert).attrs({
  bsStyle: 'danger',
})`
  margin-top: 12px;
  margin-bottom: 0;
`;
