import { format } from 'date-fns';
import { Row } from '@tanstack/react-table';
import { Discount } from 'models/Discounts';

interface ValidDatesCellProps {
  row: Row<Discount>;
}

const ValidDatesCell = ({ row }: ValidDatesCellProps) => {
  const from = row.original.validFromDate;
  const to = row.original.validToDate;

  return `${format(from, 'yyyy-MM-dd')} - ${format(to, 'yyyy-MM-dd')}`;
};

export default ValidDatesCell;
