import FormWithProvider from 'components/forms/form/Form';
import Loader from 'components/loader/Loader';
import PageHeader from 'components/pageHeader/PageHeader';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import { Agent } from 'models/Agents';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import API from 'services/API';
import { convertToBase64 } from 'utils/funcs';
import ManageForm from '../manageForm/ManageForm';
import manageThirdPartyAgencySchema from '../manageForm/ManageForm.schema';
import { Container } from '../ThirdPartyAgencyManage.styles';

const AddThirdPartyAgency = () => {
  const navigate = useNavigate();
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  const { mutate: submitAgent, isLoading: isMutating } = useAppMutation(
    ['manageAgent'],
    (data: Agent) => API.post(`/v3/agents/`, data),
    {
      onSuccess: () => {
        navigate('/third-party-agency');
      },
      onError: () => {
        notifyError(t('pageThirdPartyAgencyManage.createError'));
      },
    },
  );

  const handleSubmit = async (values: Agent) => {
    const payload = { ...values };

    if (values.picture) {
      payload.picture = await convertToBase64(values.picture[0]);
      payload.pictureFileName = values.picture[0]?.name || null;
      payload.pictureName = '';
    }

    submitAgent(payload);
  };

  return (
    <>
      {isMutating && <Loader fullPage overlay />}
      <Container>
        <PageHeader title={t('pageThirdPartyAgencyManage.titleAdd')} />
        <FormWithProvider
          horizontal
          schema={manageThirdPartyAgencySchema(false)}
          onSubmit={handleSubmit}
        >
          <ManageForm agent={null} />
        </FormWithProvider>
      </Container>
    </>
  );
};

export default AddThirdPartyAgency;
