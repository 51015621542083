import { useTranslation } from 'react-i18next';
import { Container, LinksWrapper, Pipe } from './Footer.styles';

const Footer = () => {
  const { t } = useTranslation();

  const links = [
    {
      label: t('pageNotFound.links.contact'),
      url: 'https://www.hostfully.com/contact/',
    },
    { label: 'About Hostfully ', url: 'https://www.hostfully.com/about-us/' },
    {
      label: t('pageNotFound.links.aboutPropertyManagementPlatform'),
      url: 'https://www.hostfully.com/pricing/property-management-platform/',
    },
    {
      label: t('pageNotFound.links.aboutDigitalGuidebooks'),
      url: 'https://www.hostfully.com/pricing/digital-guidebooks/',
    },
    {
      label: t('pageNotFound.links.hostfullyUniversity'),
      url: 'https://university.hostfully.com/index?_gl=1*jfjfcl*_ga*NzE2NzI5NTAuMTY3NDgyMDIxNQ..*_ga_XPZK03Y2JL*MTcwODYxMjY0OC41My4xLjE3MDg2MTQ2NTEuNDMuMC4w*_ga_4H6Z2G81BE*MTcwODYxMjY0OC4zOS4xLjE3MDg2MTQ2NTEuMC4wLjA.',
    },
    {
      label: t('pageNotFound.links.faq'),
      url: 'https://www.hostfully.com/resources/faq/',
    },
    {
      label: t('pageNotFound.links.helpDesk'),
      url: 'https://help.hostfully.com/en/?_gl=1*13kbt3o*_ga*NzE2NzI5NTAuMTY3NDgyMDIxNQ..*_ga_XPZK03Y2JL*MTcwODYxMjY0OC41My4xLjE3MDg2MTQ2ODkuNS4wLjA.*_ga_4H6Z2G81BE*MTcwODYxMjY0OC4zOS4xLjE3MDg2MTQ2ODkuMC4wLjA.',
    },
  ];

  return (
    <Container>
      <p>{t('pageNotFound.popularPath')}</p>

      <LinksWrapper>
        {links.map((link, index) => (
          <a href={link.url} key={link.url}>
            {!!index && <Pipe>|</Pipe>} {link.label}
          </a>
        ))}
      </LinksWrapper>
    </Container>
  );
};

export default Footer;
