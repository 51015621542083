import { MouseEvent } from 'react';
import { Lead } from 'models/Leads';
import LeadListItemBasicInfo from 'components/domain/lead/list/LeadListItemBasicInfo';
import LeadListItemPriceTag from 'components/domain/lead/list/LeadListItemPriceTag';
import LeadListItemViewButton from 'components/domain/lead/list/LeadListItemViewButton';
import useOpenLeadModal from 'components/domain/lead/modal/useOpenLeadModal';
import useScreenBreakpoints from 'hooks/useScreenBreakpoints';
import LeadListItemRiskScoreTag from 'components/domain/lead/list/LeadListItemRiskScoreTag';
import LeadListItemCreationDetails from 'components/domain/lead/list/LeadListItemCreationDetails';
import PipelineLeadListItemStatuses from './PipelineLeadListItemStatuses';
import {
  PipelineLeadListItemStyled,
  PipelineLeadListItemContentWrapper,
  PipelineLeadListItemDetailsColumn,
  PipelineLeadListItemStatusesColumn,
  PipelineLeadListItemTagsColumn,
} from './PipelineLeadListItem.styles';
import PipelineLeadListItemActiveStatusIndicator from './PipelineLeadListItemActiveStatusIndicator';

const elementsWithCustomClickHandler = [
  '.lead-actions-popover',
  '.lead-property-link',
  '.lead-status-indicator-active',
  '.lead-view-button',
];

const PipelineLeadListItem = ({ lead }: { lead: Lead }) => {
  const { updateLeadModal } = useOpenLeadModal();
  const { isSM, isXS } = useScreenBreakpoints();

  const isSmallScreen = isXS || isSM;

  const onClick = (event: MouseEvent<HTMLLIElement>) => {
    const excludedElement = elementsWithCustomClickHandler.find(
      (elementSelector) => (event.target as Element).closest(elementSelector),
    );

    if (excludedElement) {
      return;
    }

    updateLeadModal({ jobCreationNotAllowed: true, leadUid: lead.uid });
  };

  return (
    <PipelineLeadListItemStyled
      data-testid={`lead-list-item-${lead.uid}`}
      onClick={onClick}
    >
      {isSmallScreen && <LeadListItemCreationDetails lead={lead} />}
      <PipelineLeadListItemContentWrapper>
        <PipelineLeadListItemDetailsColumn xs={9} md={5}>
          {!isSmallScreen && <LeadListItemCreationDetails lead={lead} />}
          <LeadListItemBasicInfo lead={lead} />
        </PipelineLeadListItemDetailsColumn>
        {!isSmallScreen && (
          <PipelineLeadListItemStatusesColumn md={5}>
            <PipelineLeadListItemStatuses lead={lead} />
          </PipelineLeadListItemStatusesColumn>
        )}
        <PipelineLeadListItemTagsColumn xs={3} md={2}>
          <LeadListItemPriceTag lead={lead} />
          <LeadListItemRiskScoreTag lead={lead} />

          {isSmallScreen ? (
            <PipelineLeadListItemActiveStatusIndicator lead={lead} />
          ) : (
            <LeadListItemViewButton lead={lead} />
          )}
        </PipelineLeadListItemTagsColumn>
      </PipelineLeadListItemContentWrapper>
    </PipelineLeadListItemStyled>
  );
};

export default PipelineLeadListItem;
