import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { normalizeKey } from 'utils/localeUtils';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import { AvailableAmenity } from 'models/Amenity';
import { AmenityItemStyled } from '../CategorizedAmenities.styles';
import AmenityDescriptionIcon from '../description/AmenityDescriptionIcon';

const PopularAmenityItem = ({ amenity }: { amenity: AvailableAmenity }) => {
  const { t } = useTranslation();
  const { amenityType } = amenity || {};
  const name = `amenities.${amenityType}.value`;
  const { watch, setValue } = useFormContext();
  const amenityValue = watch(name);

  useEffect(() => {
    if (!amenityValue && amenity.supportsDescription) {
      setValue(`amenities.${amenityType}.description`, null);
    }
  }, [amenityValue]);

  return (
    <AmenityItemStyled data-testid={`amenity-type-${amenityType}-popular`}>
      <CheckboxField name={name}>
        {t(normalizeKey(`amenities.types.${amenityType}`))}
      </CheckboxField>

      {amenity.supportsDescription && !!amenityValue && (
        <AmenityDescriptionIcon amenity={amenity} />
      )}
    </AmenityItemStyled>
  );
};
export default PopularAmenityItem;
