import { useTranslation } from 'react-i18next';
import SectionHeader from 'pages/property/common/SectionHeader';
import RentalConditionsTable from './RentalConditionsTable';

const RentalConditions = () => {
  const { t } = useTranslation();

  return (
    <section>
      <SectionHeader
        id="rental-conditions-header"
        title={t('pageProperty.feesTaxesAndPolicies.rentalConditions.title')}
        tooltip={t(
          'pageProperty.feesTaxesAndPolicies.rentalConditions.tooltip',
        )}
      />

      <RentalConditionsTable />
    </section>
  );
};

export default RentalConditions;
