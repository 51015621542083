import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setAuth } from '../store/slices/auth';
import { getUserAction } from '../store/slices/user/sagas/userActions';
import useAppDispatch from './useAppDispatch';
import useAppUser from './useAppUser';
import useFetchAppConfig from './useFetchAppConfig';
import useFetchAppUserPermissions from './useFetchAppUserPermissions';

const useAppInit = () => {
  const navigate = useNavigate();
  const [isInitInProgress, setIsInitInProgress] = useState(true);
  const [isUserFetching, setIsUserFetching] = useState(false);
  const dispatch = useAppDispatch();
  const { user } = useAppUser();
  const { isConfigFetching } = useFetchAppConfig();
  const { isUserPermissionsFetching } = useFetchAppUserPermissions();

  useEffect(() => {
    if (user) {
      dispatch(setAuth({ isAuthenticated: true })); // obtaining a user successfully means that access token must be valid thus this property can be set
      setIsUserFetching(false);
    } else if (!isUserFetching) {
      setIsUserFetching(true);
      dispatch(
        getUserAction({
          callback: (success) => {
            setIsUserFetching(false);
            if (!success) {
              dispatch(setAuth({ isAuthenticated: false }));
              navigate('/login');
            }
          },
        }),
      ); // user might be missing when a logged-in user refreshes the page
    }
  }, [user]);

  useEffect(() => {
    if (isInitInProgress) {
      setIsInitInProgress(
        isConfigFetching || isUserFetching || isUserPermissionsFetching,
      );
    }
  }, [isConfigFetching, isUserFetching, isUserPermissionsFetching]);

  return {
    isInitInProgress,
  };
};

export default useAppInit;
