import { useTranslation } from 'react-i18next';
import FacebookImage from '../../../../assets/images/social/facebook.png';
import InstagramImage from '../../../../assets/images/social/instagram.png';
import TwitterImage from '../../../../assets/images/social/twitter.png';
import YoutubeImage from '../../../../assets/images/social/youtube.png';
import LinkedinImage from '../../../../assets/images/social/linkedin.png';
import CopyrightNotice from '../../../../components/text/CopyrightNotice';
import useAppConfig from '../../../../hooks/useAppConfig';
import {
  Copyright,
  FooterContainer,
  LayoutMobileCustomFooterStyled,
  LayoutMobileFooterStyled,
  SocialMedia,
} from './LayoutMobile.styles';

const LayoutMobileFooter = () => {
  const {
    customSettings: { customFooterContent },
    showVersionNumber,
  } = useAppConfig();
  const { t } = useTranslation();

  if (customFooterContent) {
    return (
      <LayoutMobileCustomFooterStyled
        dangerouslySetInnerHTML={{ __html: customFooterContent }}
      />
    );
  }

  return (
    <LayoutMobileFooterStyled>
      <FooterContainer>
        <SocialMedia>
          <a href="https://www.facebook.com/hostfully">
            <img src={FacebookImage} alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/hostfully_">
            <img src={InstagramImage} alt="Instagram" />
          </a>
          <a href="https://twitter.com/hostfully">
            <img src={TwitterImage} alt="Twitter" />
          </a>
          <a href="https://www.youtube.com/c/Hostfully">
            <img src={YoutubeImage} alt="Youtube" />
          </a>
          <a href="https://www.linkedin.com/company/hostfully-inc-">
            <img src={LinkedinImage} alt="Linkedin" />
          </a>
        </SocialMedia>

        <div>
          <a
            data-testid="link-terms-of-service"
            href="https://www.hostfully.com/term"
          >
            {t('common.layout.termsOfService')}
          </a>{' '}
          |{' '}
          <a
            data-testid="link-privacy-policy"
            href="https://www.hostfully.com/privacy-policy"
          >
            {t('common.layout.privacyPolicy')}
          </a>
        </div>

        <Copyright>
          <p className="mb-0 py-3">
            <CopyrightNotice />
            {showVersionNumber && !!globalThis.versionNumber && (
              <p className="text-center text-muted">
                {globalThis.versionNumber}
              </p>
            )}
          </p>
        </Copyright>
      </FooterContainer>
    </LayoutMobileFooterStyled>
  );
};

export default LayoutMobileFooter;
