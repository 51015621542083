import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const LinkStyled = styled(Link)`
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: 5px;
  padding: 0 15px;
  width: fit-content;

  svg {
    --size: 12px;
    height: var(--size);
    width: var(--size);
  }
`;
