import { useTranslation } from 'react-i18next';
import useAppMutation from 'hooks/useAppMutation';
import API from 'services/API';
import useNotify from 'hooks/useNotify';

const useLeadCustomDataMutations = () => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  const { mutate: updateLeadCustomData, isLoading: isUpdatingLeadCustomData } =
    useAppMutation(
      ({
        leadUid,
        customDataFieldUid,
        text,
      }: {
        leadUid: string;
        customDataFieldUid: string;
        text: string;
      }) => {
        return API.post('/api/v3/custom-data', {
          leadUid,
          customDataFieldUid,
          text,
        });
      },
      {
        onError: () =>
          notifyError(
            t(
              'componentLead.modal.dataTab.customDataForm.errorUpdateCustomData',
            ),
          ),
      },
    );

  return {
    updateLeadCustomData,
    isUpdatingLeadCustomData,
  };
};

export default useLeadCustomDataMutations;
