import styled from 'styled-components';

export const UploadFieldFileListStyled = styled.ul`
  list-style: none;
  padding: 0;
`;

export const UploadFieldFileListItemStyled = styled.li<{
  $isDisabled: boolean;
}>`
  align-items: center;
  display: flex;
  flex-direction: row;

  span {
    ${({ $isDisabled }) => $isDisabled && 'opacity: 0.5;'}
  }
`;
