import { useContext } from 'react';
import PipelineContext from './PipelineContext';
import { PipelineFilterFormValues } from './Pipeline.types';
import { convertPipelineFilterFormValues } from './filter/PipelineFilters.utils';
import usePipelineFiltersFormStorage from './filter/usePipelineFiltersFormStorage';

const usePipelineSubmit = () => {
  const { setFilter } = useContext(PipelineContext);
  const { storeFilterFormValues } = usePipelineFiltersFormStorage();

  const handleSubmit = async (formValues: PipelineFilterFormValues) => {
    storeFilterFormValues(formValues);
    setFilter(convertPipelineFilterFormValues(formValues));
  };

  return {
    handleSubmit,
  };
};

export default usePipelineSubmit;
