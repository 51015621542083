import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAppMutation from 'hooks/useAppMutation';
import { format } from 'date-fns';
import { timeZoneDateTimeReplacer, toZoneDateTime } from 'utils/timezones';
import API from 'services/API';
import { DiscountCodeType } from 'models/Discounts';

import Loader from 'components/loader/Loader';
import PageHeader from 'components/pageHeader/PageHeader';
import FormWithProvider from 'components/forms/form/Form';

import { Container } from './AddDiscount.styles';
import AddForm from './addForm/AddForm';
import addDiscountSchema from './addForm/AddForm.schema';
import useAddDiscountProperties from './useAddDiscountProperties';

const AddDiscount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isPropertiesLoading, properties } = useAddDiscountProperties();

  const { mutate: createDiscountCode, isLoading: isCreatingDiscount } =
    useAppMutation(
      (values: any) => {
        // FIXME: All these fixes should be handled by the backend API;
        const newValues = {
          ...values,
          amount: String(values.amount),
          expirationDate: toZoneDateTime(
            format(values.expirationDate, 'yyyy-MM-dd'),
          ),
          validFromDate: toZoneDateTime(
            format(values.validFromDate, 'yyyy-MM-dd'),
          ),
          validToDate: toZoneDateTime(format(values.validToDate, 'yyyy-MM-dd')),
        };

        if (newValues.property === '') {
          delete newValues.property;
        }

        const payload = JSON.stringify(newValues, timeZoneDateTimeReplacer);
        return API.post('/v3/discount-codes', payload);
      },
      { onSuccess: () => navigate('/discount-codes') },
    );

  const post = (values) => {
    createDiscountCode(values);
  };

  if (isPropertiesLoading) {
    return <Loader fullPage />;
  }

  return (
    <>
      {isCreatingDiscount && <Loader fullPage overlay />}
      <Container>
        <PageHeader
          title={t('pageDiscountCodes.pageAddDiscount.addDiscountCodeHeader')}
        />
        <FormWithProvider
          schema={addDiscountSchema()}
          defaultValues={{
            type: DiscountCodeType.AMOUNT,
            property: '',
            amount: 0,
          }}
          horizontal
          onSubmit={post}
        >
          <AddForm properties={properties} />
        </FormWithProvider>
      </Container>
    </>
  );
};

export default AddDiscount;
