// TODO: react-bootstrap migration, use Bootstrap 5 switch
import styled from 'styled-components';
import theme from 'styles/theme';

export const Container = styled.div`
  .toggle-switch.small {
    left: -15px;
    margin-top: 2px;
    scale: 0.5;
  }

  .toggle-switch {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 62px;
    height: 32px;
    display: inline-block;
    position: relative;
    border-radius: 50px;
    overflow: hidden;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
    transition: background-color ease 0.3s;
  }

  .toggle-switch:before {
    content: ' ';
    display: block;
    position: absolute;
    z-index: 2;
    width: 28px;
    height: 28px;
    background: #fff;
    left: 2px;
    top: 2px;
    border-radius: 50%;
    font: 10px/28px Helvetica;
    text-transform: uppercase;
    font-weight: bold;
    text-indent: -22px;
    word-spacing: 37px;
    color: #fff;
    text-shadow: -1px -1px rgba(0, 0, 0, 0.15);
    white-space: nowrap;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
  }

  .toggle-switch:checked {
    background-color: ${theme.colors.bgGreen};
  }

  .toggle-switch:checked:before {
    left: 32px;
  }

  .toggle-switch:disabled {
    filter: grayscale(100%);
    opacity: 0.5;
  }
`;
