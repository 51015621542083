import { useEffect } from 'react';
import {
  $createParagraphNode,
  $getRoot,
  $isDecoratorNode,
  $isElementNode,
  $isTextNode,
} from 'lexical';
import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

const SetContentsPlugin = ({ contents }: { contents: string | undefined }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!contents) {
      return;
    }

    editor.update(() => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(contents, 'text/html');

      const nodes = $generateNodesFromDOM(editor, dom);

      const root = $getRoot();
      root.clear();

      nodes.forEach((node) => {
        if ($isElementNode(node) || $isDecoratorNode(node)) {
          root.append(node);
        } else if ($isTextNode(node)) {
          const paragraphNode = $createParagraphNode();
          paragraphNode.append(node);
          root.append(paragraphNode);
        }
      });
    });
  }, [contents, editor]);

  return null;
};

export default SetContentsPlugin;
