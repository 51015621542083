import { AxiosError } from 'axios';

export interface BaseResponse {
  _metadata: { count: number; totalCount?: number };
}

export interface BasePagingResponse {
  _paging: { limit: number; cursor: any };
}

export interface BaseErrorResponse
  extends AxiosError<{
    apiErrorCode?: string;
    apiErrorMessage: string;
    uid: string;
  }> {}

export enum ObjectUidFetchType {
  ALL = 'ALL',
  SPECIFIC = 'SPECIFIC',
}
