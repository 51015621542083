import { useTranslation } from 'react-i18next';
import SelectField from 'components/forms/selectField/SelectField';
import TextField from 'components/forms/textField/TextField';
import { Hotel } from 'models/Hotel';
import { FormFieldsStyled } from '../common/CommonModal.styles';

interface ConvertToUnitTypeModalFormFieldsProps {
  hotels: Hotel[] | undefined;
}

const ConvertToUnitTypeModalFormFields = ({
  hotels,
}: ConvertToUnitTypeModalFormFieldsProps) => {
  const { t } = useTranslation();
  const multiUnitPropertyOptions = (hotels ?? []).map(({ uid, name }) => ({
    label: name,
    value: uid,
  }));

  return (
    <FormFieldsStyled>
      <TextField
        name="units"
        type="number"
        label={t(
          'pageProperties.actions.convertToUnitTypeModal.inputUnitsLabel',
        )}
        rightAddons={[
          t(
            'pageProperties.actions.convertToUnitTypeModal.inputUnitsRightAddon',
          ),
        ]}
        colSmLabel={4}
        colSmInput={8}
        required
      />
      <SelectField
        name="multiUnitProperty"
        label={t(
          'pageProperties.actions.convertToUnitTypeModal.inputMultiUnitPropertyLabel',
        )}
        options={multiUnitPropertyOptions}
        colSmLabel={4}
        colSmInput={8}
        required
      />
    </FormFieldsStyled>
  );
};

export default ConvertToUnitTypeModalFormFields;
