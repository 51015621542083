/* eslint-disable react/no-unstable-nested-components */
import { useTranslation } from 'react-i18next';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
  Tooltip,
} from 'recharts';
import { trimAndLimitCharactersNumber } from 'utils/stringUtils';
import { AnalyticsTeamMetricValue } from 'pages/analytics/types/AnalyticsTeam.types';
import Loader from 'components/loader/Loader';
import { AnalyticsPropertyMetricValue } from 'pages/analytics/types/AnalyticsProperty.types';
import { getFormatter } from 'utils/analytics/analyticsUtils';
import useFormatNumber from 'hooks/useFormatNumber';
import useFormatCurrency from 'hooks/useFormatCurrency';
import LoadingLegendPlaceholder from '../common/LoadingLegendPlaceholder';
import { chartConstants } from '../../Chart.constants';
import CustomTooltip from '../common/CustomTooltip';
import { ChartFilter } from '../../Charts.types';

interface CustomBarChartProps {
  chartFilter: ChartFilter;
  data: AnalyticsTeamMetricValue[] | AnalyticsPropertyMetricValue[];
  isLoading: boolean;
  height: number;
  fillColor?: string;
  xAxisKey: string;
  yAxisKey: string;
  error: any;
  isInitialLoading: boolean;
}

const CustomBarChart = ({
  chartFilter,
  data,
  isLoading,
  height,
  fillColor,
  xAxisKey,
  yAxisKey,
  error,
  isInitialLoading,
}: CustomBarChartProps) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const formatNumber = useFormatNumber();

  if (isInitialLoading) {
    return <Loader />;
  }

  if (!isLoading && error) {
    return (
      <p className="error-message" data-testid="error-message">
        {t('pageAnalytics.chart.errorLoadingCharts')}
      </p>
    );
  }

  if (!isLoading && !error && data?.length === 0) {
    return (
      <p className="error-message" data-testid="error-message">
        {t('pageAnalytics.chart.noData')}
      </p>
    );
  }

  data?.sort((a, b) => {
    return b[xAxisKey] - a[xAxisKey];
  });

  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart
        layout="vertical"
        data={data}
        margin={chartConstants.CHART_MARGIN}
        maxBarSize={chartConstants.BAR_WIDTH}
      >
        {isLoading ? (
          <Legend
            verticalAlign="top"
            iconType="circle"
            height={chartConstants.LEGEND_HEIGHT}
            content={<LoadingLegendPlaceholder />}
          />
        ) : (
          <Legend
            verticalAlign="top"
            iconType="circle"
            height={chartConstants.LEGEND_HEIGHT}
          />
        )}
        <XAxis
          dataKey={xAxisKey}
          type="number"
          tickFormatter={(value) =>
            getFormatter({
              chartFilter,
              value,
              currency: data[0]?.currency,
              formatCurrency,
              formatNumber,
              axis: 'X',
            })
          }
          padding={{ right: 15 }}
        />
        <YAxis
          width={chartConstants.YAXIS_WITH}
          tickFormatter={(value) =>
            trimAndLimitCharactersNumber({ text: value, limit: 15 })
          }
          dataKey={yAxisKey}
          type="category"
        />
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
        <Bar
          activeBar={{ opacity: 0.4 }}
          name={t(`pageAnalytics.chart.metric.${chartFilter.metric}`)}
          dataKey={xAxisKey}
          fill={isLoading ? chartConstants.LOADING_COLOR : fillColor}
        />
        <Tooltip
          content={({ active, payload }) => (
            <CustomTooltip
              chartFilter={chartFilter}
              active={active}
              payload={payload}
            />
          )}
          cursor={{ fill: 'transparent' }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

CustomBarChart.defaultProps = {
  fillColor: 'rgba(49, 180, 141, 0.7)',
};

export default CustomBarChart;
