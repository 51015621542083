import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'components/loader/Loader';
import { PropertiesFilterSchemaType } from '../filters/PropertiesFilter.schema';
import PropertiesContext from '../PropertiesContext';
import PropertyListItem from '../item/PropertyListItem';
import useFetchPropertiesForPropertiesBody from './useFetchPropertiesForPropertiesBody';
import useSearchFieldFilters from './useSearchFieldFilters';
import PropertiesBodyListPlaceholder from './PropertiesBodyListPlaceholder';
import { PropertiesBodyListStyled } from './PropertiesBody.styles';

const PropertiesBody = () => {
  const { t } = useTranslation();
  const { defaultValues } = useFormState<PropertiesFilterSchemaType>();
  const { lastSubmittedFilters } = useContext(PropertiesContext);
  const { searchFieldFilters } = useSearchFieldFilters();
  const filters = lastSubmittedFilters ?? defaultValues;
  const { data, isLoading, fetchNextPage, hasNextPage } =
    useFetchPropertiesForPropertiesBody({ ...filters, ...searchFieldFilters });

  if (isLoading) {
    return <PropertiesBodyListPlaceholder />;
  }

  const loadedProperties = data?.pages?.flatMap(({ properties }) => properties);

  return (
    <PropertiesBodyListStyled>
      <InfiniteScroll
        dataLength={loadedProperties?.length ?? 0}
        next={fetchNextPage}
        hasMore={hasNextPage && !!loadedProperties?.length}
        loader={<Loader />}
        scrollableTarget="app-page-content"
        endMessage={
          !loadedProperties?.length && (
            <span>{t('pageProperties.body.noPropertyFound')}</span>
          )
        }
      >
        {loadedProperties?.map((property) => (
          <PropertyListItem key={property.uid} property={property} />
        ))}
      </InfiniteScroll>
    </PropertiesBodyListStyled>
  );
};

export default PropertiesBody;
