import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';

const BodyModalLoader = () => {
  return (
    <>
      <LoadingPlaceholder linesCount={1.2} width="40%" />
      <LoadingPlaceholder linesCount={1.2} width="80%" />
    </>
  );
};

export default BodyModalLoader;
