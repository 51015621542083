import styled from 'styled-components';
import theme from 'styles/theme';

export const JobViewWrapper = styled.span.attrs({ className: 'day-cell-job' })<{
  $isDone: boolean;
  $small: boolean;
}>`
  z-index: 989;
  position: absolute;
  left: calc(50% - 4px);
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #0066cc;
  cursor: pointer;
  top: 4px;
  ${({ $isDone }) => $isDone && `background-color: ${theme.colors.bgGreen};`}

  ${({ $small }) =>
    $small &&
    `
      left: calc(37% - 4px);
      width: 10px;
      height: 10px;
      border-radius: 10px;
  `}
`;
