import { useEffect } from 'react';
import useAppUser from 'hooks/useAppUser';
import { useTranslation } from 'react-i18next';
import { matchRoutes, useLocation } from 'react-router-dom';
import { normalizeKey } from 'utils/localeUtils';
import en from 'i18n/resources/common/en.json';

const translatedPageTitles = en.pageTitles;
const routesPath = Object.keys(translatedPageTitles).map((k) => {
  return { path: k };
});

const PageTitleHandler = () => {
  const { t } = useTranslation();
  const { agency, isOwner } = useAppUser();
  const location = useLocation();

  const findPathMatch = (paths: { path: string }[], pathname: string) => {
    return paths.find((pathObj) => pathname.includes(pathObj.path))?.path;
  };

  useEffect(() => {
    const matchedRoutes = matchRoutes(routesPath, location);
    const matchedDynamicRoute = findPathMatch(routesPath, location.pathname);

    const key =
      (matchedRoutes &&
        Object.keys(translatedPageTitles).find(
          (k) => k === matchedRoutes[0].route.path,
        )) ||
      matchedDynamicRoute;

    const title =
      key &&
      (t(normalizeKey(`common.pageTitles.${key}`), {
        nsSeparator: '>',
      }) as string);

    if (isOwner && agency?.name) {
      document.title = title ? `${agency.name} | ${title}` : agency.name;
    } else {
      document.title = title ? `Hostfully | ${title}` : 'Hostfully';
    }
  }, [location, agency, isOwner]);

  return null;
};

export default PageTitleHandler;
