import { css } from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const buttonAndAnchorBaseStyles = css`
  min-width: 95px;
  align-self: flex-end;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: white;
  transition: 0.3s;
  opacity: 1;

  background: ${({ $backgroundColor }: { $backgroundColor: string }) =>
    $backgroundColor};

  &:hover {
    opacity: 0.8;
    text-decoration: none;
    color: white;
  }

  ${mediaQuery.sm} {
    font-size: 12px;
  }
`;
