import { useIsMutating } from '@tanstack/react-query';
import Button from 'components/button/Button';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import FormWithProvider from 'components/forms/form/Form';
import useAppModal from 'hooks/useAppModal';
import { Property } from 'models/Properties';
import { RentalConditions } from 'models/RentalCondition';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  DefinitionRadioOptions,
  editSchema,
} from './EditRentalConditionsModal.schema';
import { EditModalContainer } from './EditRentalConditionsModal.styles';
import EditRentalConditionsModalFields from './EditRentalConditionsModalFields';

const EditRentalConditionsModal = ({
  property,
  rentalConditions,
  onSubmit,
  mutationKey,
  modalId,
}: {
  property: Property;
  rentalConditions: RentalConditions;
  onSubmit: (data: any) => void;
  mutationKey: string[];
  modalId: string;
}) => {
  const { closeModal } = useAppModal();
  const { t } = useTranslation();

  const isMutating = useIsMutating({
    mutationKey,
  });

  const isLoading = isMutating > 0;

  const handleClose = () => {
    closeModal(modalId);
  };

  return (
    <EditModalContainer>
      <FormWithProvider
        horizontal={false}
        schema={editSchema()}
        onSubmit={onSubmit}
        defaultValues={{
          // Create logic to initialize values in PMP-10867
          appliesTo: PROPERTIES_SELECTION.SELECTED_PROPERTIES,
          appliesToProperties: [
            {
              label: property.name,
              value: property.uid,
              isFixed: true,
            },
          ],
          rentalConditions: rentalConditions.rentalConditions,
          definition: DefinitionRadioOptions.PROPERTY,
        }}
        noValidate
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t(
              'pageProperty.feesTaxesAndPolicies.rentalConditions.editModal.title',
            )}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <EditRentalConditionsModalFields />
        </Modal.Body>

        <Modal.Footer>
          <Button bsStyle="primary" type="submit" disabled={isLoading}>
            {t('common.save')}
          </Button>

          <Button bsStyle="default" onClick={handleClose}>
            {t('common.cancel')}
          </Button>
        </Modal.Footer>
      </FormWithProvider>
    </EditModalContainer>
  );
};

export default EditRentalConditionsModal;
