import useAppSelector from '../../hooks/useAppSelector';
import { CustomContentModalData, ModalProps } from './Modal.types';
import ModalRenderer from './ModalRenderer';

const CustomContentModal = ({ modalId }: ModalProps) => {
  const modalData = useAppSelector(
    (state) => state.modal.activeModals[modalId],
  );

  const { customContent, size, title, width } =
    modalData as CustomContentModalData;

  return (
    <ModalRenderer
      width={width}
      customContent={customContent}
      modalId={modalId}
      size={size}
      title={title}
    />
  );
};

export default CustomContentModal;
