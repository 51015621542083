import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';
import theme from 'styles/theme';
import { TransactionsListItemContentWrapper } from '../OrderViewTransactionsListItem.styles';

export const IssueRefundTransactionDetailsSection = styled.section`
  ${mediaQuery.sm} {
    overflow-x: auto;
  }
`;

export const IssueRefundTransactionDetailsWrapper = styled(
  TransactionsListItemContentWrapper,
)`
  border-bottom: 1px solid ${theme.colors.gray300};
  border-top: 1px solid ${theme.colors.gray300};
  gap: 0.5rem;
  margin: 1rem 0;
  padding: 0.5rem 0;

  ${mediaQuery.sm} {
    min-width: 530px;
  }

  > div {
    padding: 0;
  }
`;

export const IssueRefundAmountRow = styled(Row)`
  .input-group .form-control {
    width: 50%;

    ${mediaQuery.xs} {
      width: 100%;
    }
  }
`;

export const SecurityDepositInfoIconContainer = styled.span`
  position: relative;
  left: 5px;
  top: 1px;
`;
