import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import SelectField from '../selectField/SelectField';
import { languagesListWithFlag } from '../../../utils/constants/languages';
import { getAvailableI18nLanguage } from '../../../utils/languageUtils';
import { LanguageSelectFieldProps } from './LanguageSelectField.types';

const LanguageSelectField: FC<LanguageSelectFieldProps> = ({
  name,
  normalizeValue,
  ...rest
}) => {
  const { watch, setValue } = useFormContext();

  const currentValue = watch(name);

  useEffect(() => {
    if (normalizeValue) {
      const normalizedValue = getAvailableI18nLanguage(currentValue);
      setValue(name, normalizedValue);
    }
  }, []);

  return <SelectField name={name} options={languagesListWithFlag} {...rest} />;
};

export default LanguageSelectField;
