import { useSearchParams } from 'react-router-dom';
import useFetchUserRegistrationDetails from './useFetchUserRegistrationDetails';

const useLoginFormInitialValue = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const { isFetching, userRegistrationDetails } =
    useFetchUserRegistrationDetails(code);

  const { email, registrationToken } = userRegistrationDetails || {};

  return {
    isLoading: isFetching,
    initialValue: {
      email,
      registrationToken,
      rememberMe: !!localStorage.getItem('remember_me'),
    },
  };
};

export default useLoginFormInitialValue;
