import { useLocation } from 'react-router-dom';
import useAppUser from '../useAppUser';
import useAppConfig from '../useAppConfig';

const useIsPageWithoutFooter = (): boolean => {
  const { pathname } = useLocation();
  const { isGuest } = useAppUser();
  const {
    customSettings: { customFooterContent },
  } = useAppConfig();

  if (isGuest) {
    return !customFooterContent;
  }

  return (
    pathname === '/calendar' ||
    pathname === '/calendar-migrated' ||
    pathname === '/owner/calendar' ||
    pathname === '/owner/calendar-migrated'
  );
};

export default useIsPageWithoutFooter;
