import { ReactNode } from 'react';
import { Radio } from 'react-bootstrap';
import GenericFormField from '../genericFormField/GenericFormField';
import {
  FieldRendererProps,
  GenericFormFieldBaseProps,
} from '../genericFormField/GenericFormField.types';
import { RadioGroupContainer } from './RadioGroupField.styles';

interface Props extends Omit<GenericFormFieldBaseProps, 'fieldRenderer'> {
  /**
   * If true, displays the radio inputs horizontally instead of vertically (default).
   */
  horizontal?: boolean;
  options: { value: number | string; label: string | ReactNode }[];
}

const RadioGroupField = ({
  name,
  id = name,
  options,
  horizontal,
  ...rest
}: Props) => {
  const fieldRenderer = ({
    field: { value: currentValue, ref, ...restFieldProps },
  }: FieldRendererProps) => (
    <RadioGroupContainer $horizontal={horizontal}>
      {options.map(({ label, value }) => (
        <Radio
          id={`${id}-${value}`}
          key={`${id}-${value}`}
          data-testid={name}
          name={name}
          value={value}
          checked={value === currentValue}
          inputRef={ref}
          {...restFieldProps}
        >
          {label}
        </Radio>
      ))}
    </RadioGroupContainer>
  );

  return (
    <GenericFormField
      id={id}
      name={name}
      fieldRenderer={fieldRenderer}
      {...rest}
    />
  );
};

RadioGroupField.defaultProps = {
  horizontal: undefined,
};

export default RadioGroupField;
