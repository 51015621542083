import { ReactNode, useMemo } from 'react';
import { useFetchPropertiesFilterOptions } from 'components/domain/property/useFetchPropertiesFilterOptions';
import useFetchPricingPlan from 'components/domain/property/useFetchPricingPlan';
import { FormProvider } from 'components/forms/form/Form';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { getFormDefaultValues } from './PropertiesFilter.schema';

interface PropertiesFilterFormProviderProps {
  children: ReactNode;
}

const PropertiesFilterFormProvider = ({
  children,
}: PropertiesFilterFormProviderProps) => {
  const { isLoading: isLoadingPricingPlan } = useFetchPricingPlan();
  const { data: serverFilterOptions, isLoading } =
    useFetchPropertiesFilterOptions();
  const defaultValues = useMemo(
    () => getFormDefaultValues(serverFilterOptions ?? {}),
    [serverFilterOptions],
  );

  if (isLoading || isLoadingPricingPlan) return <LoadingPlaceholder />;

  return (
    <FormProvider defaultValues={defaultValues} schema={{}}>
      {children}
    </FormProvider>
  );
};

export default PropertiesFilterFormProvider;
