import { useTranslation } from 'react-i18next';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  ChatHistory,
  ChatHistoryData,
  GetChatSuggestionResponseTO,
} from 'pages/inbox/messages/editor/InboxMessageSuggestion.types';
import { Lead } from 'models/Leads';
import { RichTextEditorSuggestionButton } from '../RichTextEditor.styles';
import { clearEditor } from '../RichTextEditor.utils';

const ControlsPluginSuggestAnswer = ({
  globalContext,
  chatHistory,
  setSuggestion,
  suggestionButtonLoading,
  suggestionButtonDisabled,
  getSuggestions,
  activeLead,
}: {
  globalContext: string;
  chatHistory: ChatHistory[];
  setSuggestion: (suggestion: GetChatSuggestionResponseTO) => void;
  suggestionButtonLoading: boolean;
  suggestionButtonDisabled: boolean;
  getSuggestions: (
    chatHistory: ChatHistoryData,
    callback: (suggestion: GetChatSuggestionResponseTO) => void,
  ) => void;
  activeLead: Lead;
}) => {
  const { t } = useTranslation();
  const [editor] = useLexicalComposerContext();

  const onClick = () => {
    getSuggestions(
      {
        chatHistory,
        globalContext,
        leadUid: activeLead?.uid,
      },
      (suggestion) => {
        clearEditor(editor);
        setSuggestion({
          ...suggestion,
          suggestedChatMessage: `<div style="white-space: pre-wrap;">${suggestion.suggestedChatMessage}</div>`,
        });
      },
    );
  };

  return (
    <RichTextEditorSuggestionButton
      id="suggestion-button"
      data-testid="suggestion-button"
      disabled={suggestionButtonDisabled}
      onClick={onClick}
    >
      {suggestionButtonLoading
        ? t('pageInbox.messages.editor.suggestAnAnswerLoading')
        : t('pageInbox.messages.editor.suggestAnAnswer')}
    </RichTextEditorSuggestionButton>
  );
};

export default ControlsPluginSuggestAnswer;
