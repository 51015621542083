import { useContext, useEffect, useState } from 'react';
import {
  AvailableTemplate,
  GetTemplatesResponseTO,
  TemplateType,
} from '../../../../models/Templates';
import useAppQuery from '../../../../hooks/useAppQuery';
import API from '../../../../services/API';
import useAppUser from '../../../../hooks/useAppUser';
import InboxContext from '../../InboxContext';
import InboxMessagesContext from '../InboxMessagesContext';

const useSelectInboxMessageTemplate = () => {
  const [requestedTemplate, setRequestedTemplate] =
    useState<AvailableTemplate>();
  const { isEmployee, agencyUid } = useAppUser();
  const { activeLead } = useContext(InboxContext);
  const { setSelectedTemplate, toggleTemplateSelector } =
    useContext(InboxMessagesContext);

  const { uid: leadUid, property } = activeLead || {};
  const { uid: propertyUid } = property || {};

  const { data: fetchedTemplate, isInitialLoading: isLoadingTemplate } =
    useAppQuery(
      ['template', agencyUid, leadUid, propertyUid, requestedTemplate],
      async () => {
        const { name, templateType, uid } = requestedTemplate;
        const baseParams = {
          agencyUid,
          leadUid,
          propertyUid,
          filled: true,
        };
        const params = uid // uid is supposed to be present for custom templates only
          ? {
              ...baseParams,
              uid,
              templateType: TemplateType.CUSTOM_TEMPLATE,
            }
          : {
              ...baseParams,
              name,
              templateType,
            };

        const response = await API.get<GetTemplatesResponseTO>(
          '/v3/templates',
          { params },
        );

        return response.data.templates[0];
      },
      { enabled: isEmployee && !!leadUid && !!requestedTemplate },
    );

  useEffect(() => {
    if (fetchedTemplate) {
      setRequestedTemplate(null);
      setSelectedTemplate(fetchedTemplate);
      toggleTemplateSelector();
    }
  }, [fetchedTemplate]);

  const selectTemplate = (template: AvailableTemplate) => {
    setRequestedTemplate(template);
  };

  return { isLoadingTemplate, selectTemplate };
};

export default useSelectInboxMessageTemplate;
