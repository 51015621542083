import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import LeadListItemBasicInfoLoadingPlaceholder from 'components/domain/lead/list/LeadListItemBasicInfoLoadingPlaceholder';
import {
  Container,
  LeftSide,
  RightSide,
  TagsWrapper,
  Header,
  AssigneePlaceHolderWrapper,
} from './LeadListItemPlaceHolder.styles';

interface LeadListItemPlaceHolderProps {
  assigneePlaceHolder?: boolean;
  statusPlaceholder?: boolean;
}

const LeadListItemPlaceHolder = ({
  assigneePlaceHolder,
  statusPlaceholder,
}: LeadListItemPlaceHolderProps) => {
  return (
    <Container>
      <LeftSide>
        <Header>
          <LoadingPlaceholder linesCount={1.2} width="30%" />
        </Header>

        <LeadListItemBasicInfoLoadingPlaceholder />
      </LeftSide>

      <RightSide>
        {statusPlaceholder && (
          <TagsWrapper>
            <LoadingPlaceholder circle width="25px" />
            <LoadingPlaceholder circle width="25px" />
            <LoadingPlaceholder circle width="25px" />
          </TagsWrapper>
        )}

        {assigneePlaceHolder && (
          <AssigneePlaceHolderWrapper>
            <LoadingPlaceholder circle width="40px" />
          </AssigneePlaceHolderWrapper>
        )}
      </RightSide>
    </Container>
  );
};

LeadListItemPlaceHolder.defaultProps = {
  assigneePlaceHolder: true,
  statusPlaceholder: false,
};

export default LeadListItemPlaceHolder;
