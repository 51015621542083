import { LeadModalProps } from './LeadModal.types';
import LeadModalContextProvider from './LeadModalContextProvider';
import LeadModalFormWrapper from './LeadModalFormWrapper';

const LeadModal = ({
  availableProperties,
  modalId,
  params,
}: LeadModalProps) => {
  return (
    <LeadModalContextProvider
      availableProperties={availableProperties}
      modalId={modalId}
    >
      <LeadModalFormWrapper params={params} />
    </LeadModalContextProvider>
  );
};

export default LeadModal;
