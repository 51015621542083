import React from 'react';
import SelectField from 'components/forms/selectField/SelectField';
import { Modal, Button } from 'react-bootstrap';
import useAppModal from 'hooks/useAppModal';
import { useTranslation } from 'react-i18next';
import { Job } from 'models/Jobs';
import { getJobStartDate } from 'utils/job/jobUtils';
import multiplesJobsSchema from './MultiplesJobsModal.schema';
import { Container } from './MultiplesJobsModal.styles';

export interface JobWithService extends Job {
  serviceTitle: string;
}

interface MultiplesJobsModalProps {
  modalId: string;
  jobs: JobWithService[];
  onConfirm: (selectedJob: JobWithService) => void;
}

const MultiplesJobsModal: React.FC<MultiplesJobsModalProps> = ({
  modalId,
  jobs,
  onConfirm,
}) => {
  const { closeModal } = useAppModal();
  const { t } = useTranslation();

  const handleSubmit = (formValue: { selectedJobUid: string }) => {
    const selectedJob = jobs.find(
      (job) => job.uid === formValue.selectedJobUid,
    );

    onConfirm(selectedJob);
    closeModal(modalId);
  };

  return (
    <Container
      data-testid="multiples-job-test-id"
      id="create-update-job-form"
      schema={multiplesJobsSchema()}
      horizontal
      onSubmit={handleSubmit}
      defaultValues={{
        selectedJobUid: jobs[0].uid,
      }}
    >
      <Modal.Body>
        <SelectField
          name="selectedJobUid"
          label={t('pageCalendar.stacked.jobsOn', {
            date: getJobStartDate(jobs[0]),
          })}
          options={jobs.map(({ uid, serviceTitle }) => ({
            value: uid,
            label: serviceTitle,
          }))}
          colSmInput={8}
          colSmLabel={4}
          required
        />
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => closeModal(modalId)}>
          {t('pageCalendar.stacked.close')}
        </Button>
        <Button bsStyle="primary" type="submit">
          {t('pageCalendar.stacked.next')}
        </Button>
      </Modal.Footer>
    </Container>
  );
};

export default MultiplesJobsModal;
