import { useFormState } from 'react-hook-form';
import FilterIcon, { FilterIconProps } from './FilterIcon';

type FilterIconWithCounterFromFormProps = Omit<
  FilterIconProps,
  'filterCounter'
>;

const FilterIconWithCounterFromForm = (
  props: FilterIconWithCounterFromFormProps,
) => {
  const { dirtyFields } = useFormState();
  const filterCounter = Object.keys(dirtyFields).length;

  return <FilterIcon filterCounter={filterCounter} {...props} />;
};

export default FilterIconWithCounterFromForm;
