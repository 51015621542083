import { useTranslation } from 'react-i18next';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import {
  CreateRefundResponseTO,
  RefundCreationPayload,
} from 'models/Transactions';
import API from 'services/API';

const useCreateRefund = () => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  const { mutateAsync, isLoading } = useAppMutation(
    ({
      refund,
      transactionUid,
    }: {
      refund: RefundCreationPayload;
      successCallback: () => void;
      transactionUid: string;
    }) =>
      API.post<CreateRefundResponseTO>(
        `/api/internal/transactions/${transactionUid}/refunds`,
        refund,
      ),
    {
      onSuccess: (_response, { successCallback }) => {
        successCallback?.();
      },
      onError: () => {
        notifyError(t('componentTransaction.error.couldNotCreateRefund'));
      },
    },
  );

  return { createRefund: mutateAsync, isLoading };
};

export default useCreateRefund;
