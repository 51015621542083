/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useState,
  useRef,
  useEffect,
  HTMLInputTypeAttribute,
} from 'react';
import ErrorMessage from 'components/forms/_errorMessage/ErrorMessage';
import { FormControl } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { FormGroupContainer } from '../genericFormField/GenericFormField.styles';
import { ContainerError, PinItemsContainer } from './PinCodeField.styles';

type PinCodeFieldProps = {
  name: string;
  length: number;
  type?: HTMLInputTypeAttribute;
  submitWhenPasted?: boolean;
};

const PinCodeField: React.FC<PinCodeFieldProps> = ({
  name,
  length,
  type,
  submitWhenPasted,
}) => {
  const {
    control,
    formState: { errors, dirtyFields },
    setValue,
  } = useFormContext();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [pin, setPin] = useState(new Array(length).fill(''));

  const handleChange = (digit: string, index: number, onChange: any) => {
    const newPin = [...pin];
    newPin[index] = digit;

    if (digit && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }

    setPin(newPin);
    onChange(newPin.join(''));
  };

  const hasErrors = !!errors[name];
  const isValid = !!dirtyFields[name] && !errors[name];

  const validationState = () => {
    if (hasErrors) {
      return 'error';
    }
    if (isValid) {
      return 'success';
    }
    return undefined;
  };

  useEffect(() => {
    // automatically focus the first input field
    inputRefs.current[0]?.focus();
  }, []);

  // paste event:
  useEffect(() => {
    const handlePaste = (e: ClipboardEvent) => {
      const pasted = e.clipboardData.getData('text');
      if (pasted.length === length) {
        setPin(pasted.split(''));
        setValue(name, pasted);

        // submit the form
        if (submitWhenPasted) {
          submitButtonRef.current?.click();
        }
      }
    };
    document.addEventListener('paste', handlePaste);
    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, [length]);

  return (
    <FormGroupContainer validationState={validationState()} $name={name}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <PinItemsContainer $length={length}>
            {pin.map((_, index) => (
              <FormControl
                // eslint-disable-next-line react/no-array-index-key
                key={`key-pin-field-${index}`}
                // eslint-disable-next-line no-return-assign
                inputRef={(el: HTMLInputElement) =>
                  (inputRefs.current[index] = el)
                }
                data-testid={`pin-code-field-${index}`}
                type={type}
                maxLength={1}
                value={pin[index]}
                onChange={(e) => {
                  // @ts-expect-error
                  handleChange(e.target.value, index, field.onChange);
                }}
              />
            ))}
          </PinItemsContainer>
        )}
      />
      {errors && errors[name] && (
        <ContainerError>
          {/* @ts-expect-error */}
          <ErrorMessage error={errors[name].message} />
        </ContainerError>
      )}
      <button ref={submitButtonRef} type="submit" style={{ display: 'none' }} />
    </FormGroupContainer>
  );
};

PinCodeField.defaultProps = {
  type: 'text',
  submitWhenPasted: false,
};

export default PinCodeField;
