import Calendar from 'pages/calendar/Calendar';
import StackedCalendarWrapper from './StackedCalendarWrapper';
import useCalendarProperties from './propertiesList/useCalendarProperties';

const CalendarsWrapper = () => {
  const { previousPropertiesLength } = useCalendarProperties();

  return (
    <>
      <Calendar />
      <StackedCalendarWrapper
        previousPropertiesLength={previousPropertiesLength}
      />
    </>
  );
};

export default CalendarsWrapper;
