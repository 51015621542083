import { ModalFooter } from 'react-bootstrap';
import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import useAppModal from 'hooks/useAppModal';

const PropertyExpectationAddEditModalFooter = ({
  modalId,
}: {
  modalId: string;
}) => {
  const { t } = useTranslation();
  const { closeModal } = useAppModal();
  const { isDirty, isSubmitting } = useFormState();
  const cancelHandler = () => closeModal(modalId);

  return (
    <ModalFooter>
      <Button disabled={isSubmitting} onClick={cancelHandler}>
        {t('common.cancel')}
      </Button>
      <Button
        bsStyle="primary"
        disabled={!isDirty || isSubmitting}
        type="submit"
      >
        {t(isSubmitting ? 'common.saving' : 'common.save')}
      </Button>
    </ModalFooter>
  );
};

export default PropertyExpectationAddEditModalFooter;
