import useAppQuery from 'hooks/useAppQuery';
import { GetLeadRelatedThreadsResponseTO } from 'models/inbox/Threads';
import API from 'services/API';

const useFetchThreadUid = (leadUid: string) => {
  const { data, isLoading: isFetchingThreadUid } = useAppQuery(
    ['leadRelatedThread', leadUid],
    async () => {
      const leadResponse = await API.get<GetLeadRelatedThreadsResponseTO>(
        `/v3/lead-related-threads?leadUid=${leadUid}`,
      );

      if (leadResponse?.data?.threadRelatedLeads?.length) {
        const { threadUid } = leadResponse.data.threadRelatedLeads[0];
        return threadUid;
      }

      return null;
    },
    { enabled: !!leadUid },
  );

  return {
    isFetchingThreadUid,
    threadUid: data,
  };
};

export default useFetchThreadUid;
