import { PipelineFilter } from '../Pipeline.types';
import usePipelineForm from '../usePipelineForm';
import usePipelineFiltersFormStorage from './usePipelineFiltersFormStorage';
import { convertPipelineFilterFormValues } from './PipelineFilters.utils';

const usePipelineInitialFilter = (): PipelineFilter => {
  const { getStoredFilterFormValues } = usePipelineFiltersFormStorage();
  const { getDefaultValues } = usePipelineForm();

  const initialFilterFormValues =
    getStoredFilterFormValues() || getDefaultValues();

  return convertPipelineFilterFormValues(initialFilterFormValues);
};

export default usePipelineInitialFilter;
