import { useCallback, useContext } from 'react';
import InboxMessagesContext from './InboxMessagesContext';

const useInboxMessageMetadata = (messageUid: string) => {
  const { messagesMetadata, updateMessagesMetadata } =
    useContext(InboxMessagesContext);

  const updateMessageMetadata = useCallback(
    (metadataItem: { isSending: boolean; isSendError: boolean }) => {
      updateMessagesMetadata({ [messageUid]: metadataItem });
    },
    [messageUid],
  );

  return {
    isSending: messagesMetadata[messageUid]?.isSending,
    isSendError: messagesMetadata[messageUid]?.isSendError,
    updateMessageMetadata,
  };
};

export default useInboxMessageMetadata;
