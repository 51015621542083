import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ALL_WEEK_DAYS } from 'models/DayOfWeek';
import { PropertySettingsMainTabFormValues } from '../../PropertySettingsMainTab.schema';

const useOnlyCheckInOnTitle = () => {
  const { t } = useTranslation();
  const {
    bookingSettings: { daysOfTheWeekToCheckInOn },
  } = useWatch<PropertySettingsMainTabFormValues>();

  const daysMarkedAsCheckedCount = Object.entries(
    daysOfTheWeekToCheckInOn,
  ).filter(([_, isChecked]) => isChecked).length;

  // all days are checked
  if (daysMarkedAsCheckedCount === ALL_WEEK_DAYS.length) {
    return t(
      'pageProperty.mainSettings.bookingSettings.onlyCheckInOnNoRestrictionCount',
      { count: daysMarkedAsCheckedCount },
    );
  }

  return daysMarkedAsCheckedCount
    ? t('pageProperty.mainSettings.bookingSettings.onlyCheckInOnSelected', {
        count: daysMarkedAsCheckedCount,
      })
    : t('pageProperty.mainSettings.bookingSettings.onlyCheckInOnNoRestriction');
};

export default useOnlyCheckInOnTitle;
