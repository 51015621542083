import { useTranslation } from 'react-i18next';
import SelectField from 'components/forms/selectField/SelectField';
import { SelectFieldProps } from 'components/forms/selectField/SelectField.types';
import { PROPERTY_STATUS } from 'models/Properties';

interface StatusSelectFieldProps extends SelectFieldProps {}

const StatusSelectField = (props: StatusSelectFieldProps) => {
  const { t } = useTranslation();

  return (
    <SelectField
      colSmInput={8}
      colSmLabel={4}
      label={t('pageCalendar.stacked.status')}
      options={[
        { label: t('pageCalendar.stacked.all'), value: '' },
        {
          label: t('pageCalendar.stacked.active'),
          value: PROPERTY_STATUS.ACTIVE,
        },
        {
          label: t('pageCalendar.stacked.inactive'),
          value: PROPERTY_STATUS.INACTIVE,
        },
      ]}
      {...props}
    />
  );
};

export default StatusSelectField;
