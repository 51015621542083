import { useEffect } from 'react';
import API from '../services/API';
import { GetUserPermissionsResponseTO } from '../models/UserPermisisons';
import { setAppUserPermissions } from '../store/slices/userPermissions';
import useAppQuery from './useAppQuery';
import useAppDispatch from './useAppDispatch';
import useAppUser from './useAppUser';

const useFetchAppUserPermissions = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppUser();

  const {
    data: fetchedAppUserPermissions,
    isLoading: isUserPermissionsFetching,
  } = useAppQuery(
    ['userPermissions'],
    async () => {
      const response = await API.get<GetUserPermissionsResponseTO>(
        '/v3/permissions',
      );

      return response.data.permissions;
    },
    { enabled: !!user },
  );

  useEffect(() => {
    if (fetchedAppUserPermissions) {
      dispatch(
        setAppUserPermissions({ permissions: fetchedAppUserPermissions }),
      );
    }
  }, [fetchedAppUserPermissions]);

  return { isUserPermissionsFetching };
};

export default useFetchAppUserPermissions;
