import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const LayoutMobileContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  font-family: 'DM Sans', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const LayoutMobileContent = styled.main`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding-top: 20px;

  svg {
    margin-bottom: 26px;
  }

  .form-control {
    margin-bottom: 1rem;
  }

  form {
    width: 100%;
    max-width: 333px;
  }

  .checkbox {
    padding: 0 !important;
    text-align: left;
    margin-bottom: 10px;

    label {
      font-size: 16px;
      color: rgb(62, 203, 154);
      font-weight: 600;
    }
  }

  .action-mobile {
    color: #3ecb9a;
    font-weight: 600;
    text-decoration: none;
    font-size: 16px;
    margin-top: 16px;
    display: block;
  }

  .error-message {
    color: rgb(255, 114, 84);
    margin-top: 16px;
  }

  h3 {
    margin-top: 0;
    font-size: 18px;
    font-weight: 600;
  }

  // Styles based in the old pmp-webapp Layout page (should be temporary):

  input:not([type='checkbox']) {
    box-sizing: border-box;
    margin: 0;

    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: #3c3a46;
    animation-name: onautofillcancel;
    height: 60px;

    &:focus {
      color: #212529;
      background-color: #fff;
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    }
  }

  button {
    --bs-white-rgb: 255, 255, 255;
    box-sizing: border-box;
    margin: 0;
    font-family: inherit;
    text-transform: none;
    -webkit-appearance: button;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color: #3ecb9a;
    border-color: #3ecb9a;
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
    cursor: pointer;
    height: 60px;
    width: 100%;
    font-size: 20px;

    &:hover {
      background-color: #5bd3a9;
      border-color: #51d0a4;
    }

    &:focus,
    &:active,
    &:focus:active {
      outline: 0;
      background-color: #5bd3a9;
      border-color: #51d0a4;
      box-shadow: 0 0 0 0.25rem rgb(53 173 131 / 50%);
    }

    &:disabled {
      background-color: #3ecb9a;
      border-color: #3ecb9a;

      &:hover {
        background-color: #3ecb9a;
        border-color: #3ecb9a;
      }
    }
  }
`;

export const LayoutMobileHeaderStyled = styled.header`
  height: 60px;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 40%);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px;

  .help-icon {
    color: #ccc;
    width: 28px;
    height: 28px;
    cursor: pointer;
  }

  > svg {
    height: 60px;
  }
`;

export const LayoutMobileFooterStyled = styled.div.attrs({
  'data-testid': 'layout-mobile-footer',
})`
  & > :first-child {
    & > :first-child {
      // social media icons section;
      width: 100%;
      max-width: 300px;
      justify-content: space-between;

      & > * {
        margin: 0;
      }
    }
  }
`;

export const FooterContainer = styled.footer.attrs({
  'data-testid': 'footer-public',
})`
  background-color: rgb(243, 243, 247);
  padding: 16px;

  display: flex;
  justify-content: space-around;
  align-items: center;

  ${mediaQuery.xs} {
    flex-direction: column;

    & > * {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;

export const Copyright = styled.div`
  p {
    margin: 0;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  align-items: center;

  a {
    margin-right: 16px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  ${mediaQuery.xs} {
    margin-bottom: 14px;
  }
`;

export const CustomLogoImage = styled.img`
  max-height: 45px;
  margin: auto;
  transform: translateX(16px);
`;

export const LayoutMobileCustomHeaderStyled = styled.header`
  min-height: 60px;
  width: 100vw;
`;

export const LayoutMobileCustomFooterStyled = styled.footer`
  width: 100vw;
`;
