import { HashRouter, Route, Routes } from 'react-router-dom';

import useFeatures from 'hooks/useFeatures';
import Login from 'pages/login/Login';
import ForgotPassword from 'pages/forgotPassword/ForgotPassword';
import {
  LEGACY_PAGES_PRIVATE,
  LEGACY_PAGES_PUBLIC,
} from 'pages/legacy/legacyPages.constants';
import LegacyPage from 'pages/legacy/LegacyPage';
import Discounts from 'pages/discounts/Discounts';
import Resolutions from 'pages/channels/airbnb/resolutions/Resolutions';
import AddDiscount from 'pages/addDiscount/AddDiscount';
import StackedCalendar from 'pages/stackedCalendar/StackedCalendar';
import Inbox from 'pages/inbox/Inbox';
import ThirdPartyAgency from 'pages/thirdPartyAgency/ThirdPartyAgency';
import EditThirdPartyAgency from 'pages/thirdPartyAgencyManage/editThirdPartyAgency/EditThirdPartyAgency';
import AddThirdPartyAgency from 'pages/thirdPartyAgencyManage/addThirdPartyAgency/AddThirdPartyAgency';
import Logout from 'pages/logout/Logout';
import LegacyRedirect from 'pages/legacy/LegacyRedirect';
import Statements from 'pages/statements/Statements';
import OwnerAccount from 'pages/ownerAccount/OwnerAccount';
import Properties from 'pages/properties/Properties';
import ExchangeToken from 'pages/exchangeToken/ExchangeToken';
import Authorization from 'pages/authorization/Authorization';
import Analytics from 'pages/analytics/Analytics';
import Dashboard from 'pages/dashboard/Dashboard';
import Refer from 'pages/refer/Refer';
import Account from 'pages/account/Account';
import OwnerAdjustments from 'pages/ownerAdjustments/OwnerAdjustments';
import Pipeline from 'pages/pipeline/Pipeline';
import NotFound from 'pages/notFound/NotFound';
import AgencyProperties from 'pages/agencyProperties/Properties';
import TurnoManagement from 'pages/turnoManagement/TurnoManagement';
import OrderView from 'pages/orderView/OrderView';
import OrderViewLegacyAdapter from 'pages/orderView/OrderViewLegacyAdapter';
import AddSingleProperty from 'pages/property/add/single/AddSingleProperty';
import Property from 'pages/property/Property';
import PropertySettingsAmenitiesTab from 'pages/property/amenities/PropertySettingsAmenitiesTab';
import PropertySettingsChannelsTab from 'pages/property/channels/PropertySettingsChannelsTab';
import PropertySettingsCustomDataTab from 'pages/property/customData/PropertySettingsCustomDataTab';
import PropertySettingsDescriptionsTab from 'pages/property/descriptions/PropertySettingsDescriptionsTab';
import PropertySettingsFeesAndPoliciesTab from 'pages/property/feesAndPolicies/PropertySettingsFeesAndPoliciesTab';
import PropertySettingsKpisTab from 'pages/property/kpis/PropertySettingsKpisTab';
import PropertySettingsMainTab from 'pages/property/mainSettings/PropertySettingsMainTab';
import PropertySettingsOwnersTab from 'pages/property/owners/PropertySettingsOwnersTab';
import PropertySettingsPhotosTab from 'pages/property/photos/PropertySettingsPhotosTab';
import PropertySettingsPricingTab from 'pages/property/pricing/PropertySettingsPricingTab';
import PropertySettingsReviewsTab from 'pages/property/reviews/PropertySettingsReviewsTab';
import PropertySettingsServiceProvidersTab from 'pages/property/serviceProviders/PropertySettingsServiceProvidersTab';
import { PropertySettingsTab } from 'pages/property/PropertySettingsTabs.constants';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import WindowMessagesHandler from './WindowMessagesHandler';
import PageTitleHandler from './PageTitleHandler';
import GoogleAnalyticsHandler from './GoogleAnalyticsHandler';

const migratedPagesProvider = {
  CALENDAR: () => <StackedCalendar />,
  DASHBOARD: ({ allowAnalyticsWebapp }) =>
    allowAnalyticsWebapp && <Dashboard />,
  PIPELINE: () => <Pipeline />,
  PROPERTIES: ({ allowPropertiesWebapp }) =>
    allowPropertiesWebapp && <AgencyProperties />,
  ORDER_VIEW: ({ allowOrderViewWebapp }) =>
    allowOrderViewWebapp && <OrderViewLegacyAdapter />,
};

const Router = () => {
  const featureFlags = useFeatures();

  return (
    <HashRouter basename="/">
      <PageTitleHandler />
      <WindowMessagesHandler />
      <GoogleAnalyticsHandler />
      <Routes>
        <Route path="/r/:redirectUrl" element={<LegacyRedirect />} />
        {/* logout must not be a private route as it'd break redirection to requested path */}
        <Route path="/logout" element={<Logout />} />

        {/* Private Routes */}
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/l/:pageUrl" element={<LegacyPage />} />
          {LEGACY_PAGES_PRIVATE.map(({ pageId, path }) => (
            <Route
              key={pageId}
              path={`/${path}`}
              element={
                migratedPagesProvider[pageId]?.(featureFlags) || (
                  <LegacyPage pageId={pageId} />
                )
              }
            />
          ))}
          <Route path="/channels/airbnb/resolutions">
            <Route index element={<Resolutions />} />
          </Route>
          <Route path="/discount-codes">
            <Route index element={<Discounts />} />
            <Route path="add" element={<AddDiscount />} />
          </Route>
          <Route path="/third-party-agency">
            <Route index element={<ThirdPartyAgency />} />
            <Route path="add" element={<AddThirdPartyAgency />} />
            <Route path=":id" element={<EditThirdPartyAgency />} />
          </Route>
          <Route path="/inbox" element={<Inbox />} />
          <Route path="/calendar-migrated" element={<StackedCalendar />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/refer" element={<Refer />} />
          <Route path="/account" element={<Account />} />
          <Route
            path="/owner-adjustments/:propertyUid"
            element={<OwnerAdjustments />}
          />
          <Route path="/properties-migrated" element={<AgencyProperties />} />
          <Route path="/order/:uid" element={<OrderView />} />
          <Route path="/turnover-management" element={<TurnoManagement />} />

          <Route path="/property">
            <Route path="add">
              <Route path="single" element={<AddSingleProperty />} />
            </Route>
            <Route path=":uid" element={<Property />}>
              <Route index element={<PropertySettingsMainTab />} />
              <Route
                path={PropertySettingsTab.amenities}
                element={<PropertySettingsAmenitiesTab />}
              />
              <Route
                path={PropertySettingsTab.channels}
                element={<PropertySettingsChannelsTab />}
              />
              <Route
                path={PropertySettingsTab.customData}
                element={<PropertySettingsCustomDataTab />}
              />
              <Route
                path={PropertySettingsTab.descriptions}
                element={<PropertySettingsDescriptionsTab />}
              />
              <Route
                path={PropertySettingsTab.feesAndPolicies}
                element={<PropertySettingsFeesAndPoliciesTab />}
              />
              <Route
                path={PropertySettingsTab.kpis}
                element={<PropertySettingsKpisTab />}
              />
              <Route
                path={PropertySettingsTab.owners}
                element={<PropertySettingsOwnersTab />}
              />
              <Route
                path={PropertySettingsTab.photos}
                element={<PropertySettingsPhotosTab />}
              />
              <Route
                path={PropertySettingsTab.pricing}
                element={<PropertySettingsPricingTab />}
              />
              <Route
                path={PropertySettingsTab.reviews}
                element={<PropertySettingsReviewsTab />}
              />
              <Route
                path={PropertySettingsTab.serviceProviders}
                element={<PropertySettingsServiceProvidersTab />}
              />
            </Route>
          </Route>

          {/* guest */}
          <Route path="/guest">
            <Route path="inbox" element={<Inbox />} />
          </Route>

          {/* owner */}
          <Route path="/owner">
            <Route path="inbox" element={<Inbox />} />
            <Route path="calendar" element={<StackedCalendar />} />
            <Route path="account" element={<OwnerAccount />} />
            <Route path="statements" element={<Statements />} />
            <Route path="properties" element={<Properties />} />
          </Route>

          <Route path="/oauth/authorize" element={<Authorization />} />
        </Route>

        {/* Public Routes */}
        <Route path="/" element={<PublicRoute />}>
          {LEGACY_PAGES_PUBLIC.map(({ pageId, path }) => (
            <Route
              key={pageId}
              path={`/${path}`}
              element={<LegacyPage pageId={pageId} />}
            />
          ))}
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/exchange-token" element={<ExchangeToken />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </HashRouter>
  );
};

export default Router;
