import { useIsMutating } from '@tanstack/react-query';
import Button from 'components/button/Button';
import PropertyMultiSelectFilterField from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import FormWithProvider from 'components/forms/form/Form';
import TextField from 'components/forms/textField/TextField';
import useAppModal from 'hooks/useAppModal';
import { Property, PropertyBusinessType } from 'models/Properties';
import { Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { OptionalObjectSchema } from 'yup/lib/object';

export const addModalId = 'add-cancellation-policy-modal';

const addSchema = () => ({
  appliesTo: Yup.string().required(),
  appliesToProperties: Yup.array()
    .of(
      Yup.object({
        label: Yup.string(),
        value: Yup.string(),
        isFixed: Yup.boolean().optional(),
      }),
    )
    .label('properties')
    .when('appliesTo', {
      is: PROPERTIES_SELECTION.ALL_PROPERTIES,
      then: (schema) => schema.optional().nullable(true),
      otherwise: (schema) => schema.min(1).required(),
    }),
  text: Yup.string().max(1000).required(),
});

export type CancellationPolicyAddFormSchemaType = Yup.InferType<
  OptionalObjectSchema<ReturnType<typeof addSchema>>
>;

const AddCancellationPolicyModal = ({
  property,
  onSubmit,
  mutationKey,
}: {
  property: Property;
  onSubmit: (data: CancellationPolicyAddFormSchemaType) => void;
  mutationKey: string[];
}) => {
  const { closeModal } = useAppModal();
  const { t } = useTranslation();
  const isMutating = useIsMutating({
    mutationKey,
  });

  const isUpdating = isMutating > 0;

  const handleClose = () => {
    closeModal(addModalId);
  };

  const filterPropertyBy = ({
    businessType,
  }: {
    businessType: PropertyBusinessType;
  }) =>
    businessType !== PropertyBusinessType.HOTEL &&
    businessType !== PropertyBusinessType.UNIT;

  return (
    <FormWithProvider
      schema={addSchema()}
      onSubmit={onSubmit}
      defaultValues={{
        appliesTo: PROPERTIES_SELECTION.SELECTED_PROPERTIES,
        appliesToProperties: [
          {
            label: property.name,
            value: property.uid,
            isFixed: true,
          },
        ],
      }}
    >
      <Modal.Header>
        <Modal.Title>
          {t(
            'pageProperty.feesTaxesAndPolicies.cancelationPolicy.modal.addTitle',
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="d-flex flex-column">
          <TextField
            name="text"
            label={t(
              'pageProperty.feesTaxesAndPolicies.cancelationPolicy.modal.text',
            )}
            componentClass="textarea"
            required
            colSmInput={8}
            colSmLabel={4}
            rows={6}
            maxCharacters={1000}
          />
          <PropertyMultiSelectFilterField
            label={t(
              'pageProperty.feesTaxesAndPolicies.cancelationPolicy.modal.appliesTo',
            )}
            name="appliesTo"
            filterPropertyBy={filterPropertyBy}
            colSmInput={8}
            colSmLabel={4}
            filtersColXs={12}
          />
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button bsStyle="default" onClick={handleClose}>
          {t('common.cancel')}
        </Button>
        <Button bsStyle="primary" type="submit" disabled={isUpdating}>
          {t('common.save')}
        </Button>
      </Modal.Footer>
    </FormWithProvider>
  );
};

export default AddCancellationPolicyModal;
