import { useEffect } from 'react';
import { Col, HelpBlock, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import FormWithProvider from 'components/forms/form/Form';
import { useNavigate } from 'react-router-dom';
import exchangeTokenSchema from './ExchangeToken.schema';
import useExchangeTokenSubmit from './useExchangeTokenSubmit';
import ExchangeTokenForm from './ExchangeTokenForm';
import ExchangeTokenSuccess from './ExchangeTokenSuccess';

const ExchangeToken = () => {
  const { t } = useTranslation();
  const {
    handleSubmitExchangeToken,
    clearErrors,
    errorMessage,
    token,
    isLoading,
    client,
  } = useExchangeTokenSubmit();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, []);

  if (client) {
    return <ExchangeTokenSuccess client={client} />;
  }

  return (
    <FormWithProvider
      horizontal
      onSubmit={handleSubmitExchangeToken}
      schema={exchangeTokenSchema(t)}
    >
      <Row>
        <Col xs={6} xsOffset={3}>
          <h2>{t('pageExchangeToken.title')}</h2>
          <p>{t('pageExchangeToken.subtitle')}</p>
          <ExchangeTokenForm clearErrors={clearErrors} />
          <HelpBlock>{t('pageExchangeToken.sample')}</HelpBlock>
          <Button
            data-testid="submit-button"
            type="submit"
            block
            bsSize="large"
            disabled={!!errorMessage || isLoading}
          >
            {t('pageExchangeToken.submit')}
          </Button>
          {!!errorMessage && (
            <p className="error-message" data-testid="error-message">
              {errorMessage}
            </p>
          )}
        </Col>
      </Row>
    </FormWithProvider>
  );
};

export default ExchangeToken;
