import { FeeScopeEnum } from 'models/PropertyFees';
import { useTranslation } from 'react-i18next';
import { normalizeKey } from 'utils/localeUtils';

const ScopeCell = ({ getValue }: { getValue: () => FeeScopeEnum }) => {
  const { t } = useTranslation();
  const value = getValue();

  return (
    <span>
      {t(
        normalizeKey(
          `pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.scopes.${value}`,
        ),
      )}
    </span>
  );
};

export default ScopeCell;
