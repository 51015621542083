import { useContext } from 'react';
import InboxContext from '../InboxContext';
import { ThreadParticipantBestProfile } from '../../../models/inbox/Threads';

const useThreadParticipantBestProfile = (
  threadId: string,
): ThreadParticipantBestProfile => {
  const { threadParticipantBestProfilesMap } = useContext(InboxContext);
  return threadParticipantBestProfilesMap[threadId] || { name: '' };
};

export default useThreadParticipantBestProfile;
