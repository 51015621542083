import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const BodyWrapper = styled.div`
  display: flex;
  justify-content: start;
`;

export const MonthsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const MonthWrapper = styled.div`
  flex: 0 1 47%;
  margin-bottom: 50px;
  justify-content: space-between;
  font-size: 12px;
  max-width: 535px;

  ${mediaQuery.xs || mediaQuery.md} {
    flex: 0 1 100%;
  }
`;

export const Month = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const MonthInner = styled.p`
  font-family: SourceSansPro_regular;
  font-size: 16px;
  margin-bottom: 15px;
`;

export const DayStringCellWrapper = styled.div`
  height: 40px;
  display: flex;
`;
