export enum Breakpoint {
  'XS' = 'XS',
  'SM' = 'SM',
  'MD' = 'MD',
  'LG' = 'LG',
}

export const breakpoints = {
  [Breakpoint.XS]: 768,
  [Breakpoint.SM]: 992,
  [Breakpoint.MD]: 1200,
};

export function getScreenBreakpoint(screenWidth: number) {
  if (screenWidth >= breakpoints[Breakpoint.MD]) {
    return Breakpoint.LG;
  }
  if (screenWidth >= breakpoints[Breakpoint.SM]) {
    return Breakpoint.MD;
  }
  if (screenWidth >= breakpoints[Breakpoint.XS]) {
    return Breakpoint.SM;
  }
  return Breakpoint.XS;
}

const getMediaQueryFromBreakpoint = (breakpoint: number) => {
  return `@media (max-width: ${breakpoint}px)`;
};

/**
 * This is a media query object based in a non-mobile-first approach.
 * It uses "max-width" with breakpoints based on Bootstrap 3.
 * It should be most used for applying styles in mobile (xs) width.
 */
const mediaQuery = {
  custom: getMediaQueryFromBreakpoint,
  xs: getMediaQueryFromBreakpoint(breakpoints[Breakpoint.XS]),
  sm: getMediaQueryFromBreakpoint(breakpoints[Breakpoint.SM]),
  md: getMediaQueryFromBreakpoint(breakpoints[Breakpoint.MD]),
};

export default mediaQuery;
