export enum CancellationPolicyCategories {
  POLICY_WITH_100_PERCENT_PENALTY = 'POLICY_WITH_100_PERCENT_PENALTY',
  POLICY_WITH_30_PERCENT_PENALTY = 'POLICY_WITH_30_PERCENT_PENALTY',
  POLICY_WITH_50_PERCENT_PENALTY = 'POLICY_WITH_50_PERCENT_PENALTY',
  POLICY_WITH_NO_PENALTY = 'POLICY_WITH_NO_PENALTY',
  POLICY_WITH_PENALTY_IN_NIGHTS = 'POLICY_WITH_PENALTY_IN_NIGHTS',
  UNDEFINED = 'UNDEFINED',
}

export interface CancellationPolicy {
  name: string;
  code: number;
  category: CancellationPolicyCategories;
}

export type GetCancellationPoliciesResponseTO = CancellationPolicy[];
