import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import usePropertiesUpdatingAllowed from 'components/domain/property/usePropertiesUpdatingAllowed';
import Tooltip from 'components/tooltip/Tooltip';

const PropertyEditActionWrapper = ({
  children,
  shouldCheckPermission = true,
  tooltipId,
}: {
  children: ReactNode;
  shouldCheckPermission?: boolean;
  tooltipId: string;
}) => {
  const propertiesUpdatingAllowed = usePropertiesUpdatingAllowed();
  const { t } = useTranslation();

  if (shouldCheckPermission && !propertiesUpdatingAllowed) {
    return (
      <Tooltip
        id={tooltipId}
        text={t('common.permissions.actionNotAllowedForAccount')}
      >
        {children}
      </Tooltip>
    );
  }

  return children;
};

PropertyEditActionWrapper.defaultProps = {
  shouldCheckPermission: true,
};

export default PropertyEditActionWrapper;
