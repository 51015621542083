import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { Col, Row } from 'react-bootstrap';

const AnalyticsFilterLoader = () => {
  const margin = '.5em 0';

  return (
    <Row>
      {[1, 2, 3, 4, 5, 6].map((number) => (
        <Col sm={6} md={3} lg={2} key={number}>
          <LoadingPlaceholder
            linesCount={2.5}
            key={`filter-${number}`}
            margin={margin}
          />
        </Col>
      ))}
    </Row>
  );
};

export default AnalyticsFilterLoader;
