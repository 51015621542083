import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  height: 100vh;
  height: 100svh;
  width: 100vw;
  display: flex;
  flex-direction: column;

  ${mediaQuery.xs} {
    position: relative;
  }
`;

export const ChildContent = styled.div.attrs({
  'data-testid': 'app-page-child-content',
})<{ $showOnlyContent: boolean }>`
  ${({ $showOnlyContent }) => !$showOnlyContent && 'flex: 1 0 auto;'}
`;

export const Content = styled.div.attrs({ id: 'app-page-content' })<{
  $showOnlyContent: boolean;
}>`
  display: flex;
  flex: 1;
  overflow-y: auto;
  ${({ $showOnlyContent }) =>
    $showOnlyContent
      ? `
        align-items: center;
        flex-direction: row;
        justify-content: center;
      `
      : `
        padding-top: 18px;
        flex-direction: column;
      `};

  ${mediaQuery.xs} {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: calc(100vh - 60px);
    height: calc(100svh - 60px);
    top: 60px;
    padding-top: 20px;
  }
`;
