import styled from 'styled-components';
import { Panel } from 'react-bootstrap';
import theme from 'styles/theme';

// replace with Card in BS5?
export const PropertyEditPermissionInfoContainer = styled(Panel)`
  border: 1px solid #dee2e6;
`;

export const PropertyEditPermissionInfoBody = styled(Panel.Body)`
  border-left: 4px solid ${theme.colors.hostfullyRed};
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
`;
