const usStates = {
  1: {
    code: 'AL',
    name: 'Alabama',
  },
  2: {
    code: 'AK',
    name: 'Alaska',
  },
  3: {
    code: 'AS',
    name: 'American Samoa',
  },
  4: {
    code: 'AZ',
    name: 'Arizona',
  },
  5: {
    code: 'AR',
    name: 'Arkansas',
  },
  6: {
    code: 'AF',
    name: 'Armed Forces Africa',
  },
  7: {
    code: 'AA',
    name: 'Armed Forces Americas',
  },
  8: {
    code: 'AC',
    name: 'Armed Forces Canada',
  },
  9: {
    code: 'AE',
    name: 'Armed Forces Europe',
  },
  10: {
    code: 'AM',
    name: 'Armed Forces Middle East',
  },
  11: {
    code: 'AP',
    name: 'Armed Forces Pacific',
  },
  12: {
    code: 'CA',
    name: 'California',
  },
  13: {
    code: 'CO',
    name: 'Colorado',
  },
  14: {
    code: 'CT',
    name: 'Connecticut',
  },
  15: {
    code: 'DE',
    name: 'Delaware',
  },
  16: {
    code: 'DC',
    name: 'District of Columbia',
  },
  17: {
    code: 'FM',
    name: 'Federated States Of Micronesia',
  },
  18: {
    code: 'FL',
    name: 'Florida',
  },
  19: {
    code: 'GA',
    name: 'Georgia',
  },
  20: {
    code: 'GU',
    name: 'Guam',
  },
  21: {
    code: 'HI',
    name: 'Hawaii',
  },
  22: {
    code: 'ID',
    name: 'Idaho',
  },
  23: {
    code: 'IL',
    name: 'Illinois',
  },
  24: {
    code: 'IN',
    name: 'Indiana',
  },
  25: {
    code: 'IA',
    name: 'Iowa',
  },
  26: {
    code: 'KS',
    name: 'Kansas',
  },
  27: {
    code: 'KY',
    name: 'Kentucky',
  },
  28: {
    code: 'LA',
    name: 'Louisiana',
  },
  29: {
    code: 'ME',
    name: 'Maine',
  },
  30: {
    code: 'MH',
    name: 'Marshall Islands',
  },
  31: {
    code: 'MD',
    name: 'Maryland',
  },
  32: {
    code: 'MA',
    name: 'Massachusetts',
  },
  33: {
    code: 'MI',
    name: 'Michigan',
  },
  34: {
    code: 'MN',
    name: 'Minnesota',
  },
  35: {
    code: 'MS',
    name: 'Mississippi',
  },
  36: {
    code: 'MO',
    name: 'Missouri',
  },
  37: {
    code: 'MT',
    name: 'Montana',
  },
  38: {
    code: 'NE',
    name: 'Nebraska',
  },
  39: {
    code: 'NV',
    name: 'Nevada',
  },
  40: {
    code: 'NH',
    name: 'New Hampshire',
  },
  41: {
    code: 'NJ',
    name: 'New Jersey',
  },
  42: {
    code: 'NM',
    name: 'New Mexico',
  },
  43: {
    code: 'NY',
    name: 'New York',
  },
  44: {
    code: 'NC',
    name: 'North Carolina',
  },
  45: {
    code: 'ND',
    name: 'North Dakota',
  },
  46: {
    code: 'MP',
    name: 'Northern Mariana Islands',
  },
  47: {
    code: 'OH',
    name: 'Ohio',
  },
  48: {
    code: 'OK',
    name: 'Oklahoma',
  },
  49: {
    code: 'OR',
    name: 'Oregon',
  },
  50: {
    code: 'PW',
    name: 'Palau',
  },
  51: {
    code: 'PA',
    name: 'Pennsylvania',
  },
  52: {
    code: 'PR',
    name: 'Puerto Rico',
  },
  53: {
    code: 'RI',
    name: 'Rhode Island',
  },
  54: {
    code: 'SC',
    name: 'South Carolina',
  },
  55: {
    code: 'SD',
    name: 'South Dakota',
  },
  56: {
    code: 'TN',
    name: 'Tennessee',
  },
  57: {
    code: 'TX',
    name: 'Texas',
  },
  58: {
    code: 'UT',
    name: 'Utah',
  },
  59: {
    code: 'VT',
    name: 'Vermont',
  },
  60: {
    code: 'VI',
    name: 'Virgin Islands',
  },
  61: {
    code: 'VA',
    name: 'Virginia',
  },
  62: {
    code: 'WA',
    name: 'Washington',
  },
  63: {
    code: 'WV',
    name: 'West Virginia',
  },
  64: {
    code: 'WI',
    name: 'Wisconsin',
  },
  65: {
    code: 'WY',
    name: 'Wyoming',
  },
};

export const usStateOptions = Object.values(usStates).map(({ code, name }) => ({
  label: name,
  value: code,
}));
