const comparator = new Intl.Collator('en-US', { numeric: true });

export const compare = (valueA: unknown, valueB: unknown) => {
  if (typeof valueA === 'string' && typeof valueB === 'string') {
    return comparator.compare(valueA, valueB);
  }

  if (valueA > valueB) {
    return 1;
  }

  if (valueA < valueB) {
    return -1;
  }

  return 0;
};
