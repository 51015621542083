import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-hook-form';
import Button from 'components/button/Button';
import useAppModal from 'hooks/useAppModal';
import { DeactivationModalFooterStyled } from './DeactivationModal.styles';

interface DeactivationModalFooterProps {
  hasBack: boolean;
  hasNext: boolean;
  isUpdating: boolean;
  modalId: string;
  onBack: () => void;
}

const DeactivationModalFooter = ({
  hasBack,
  hasNext,
  isUpdating,
  modalId,
  onBack,
}: DeactivationModalFooterProps) => {
  const { t } = useTranslation();
  const { closeModal } = useAppModal();
  const { isSubmitting, isValid } = useFormState();
  const cancelHandler = () => closeModal(modalId);

  return (
    <DeactivationModalFooterStyled>
      {hasBack && (
        <Button data-testid="modal-back-button" onClick={onBack}>
          {t('common.back')}
        </Button>
      )}

      <Button
        data-testid="modal-cancel-button"
        disabled={isSubmitting || isUpdating}
        onClick={cancelHandler}
      >
        {t('common.cancel')}
      </Button>

      {hasNext ? (
        <Button data-testid="modal-next-button" bsStyle="primary" type="submit">
          {t('common.next')}
        </Button>
      ) : (
        <Button
          data-testid="modal-submit-button"
          bsStyle="primary"
          disabled={isSubmitting || isUpdating || !isValid}
          type="submit"
        >
          {isSubmitting || isUpdating
            ? t('common.saving')
            : t('pageProperties.actions.deactivate')}
        </Button>
      )}
    </DeactivationModalFooterStyled>
  );
};

export default DeactivationModalFooter;
