import styled from 'styled-components';

export const Container = styled.div.attrs({
  className: 'container',
})`
  form {
    width: 100%;
  }

  form .form-group {
    display: flex;
    justify-content: space-between;
  }
`;
