import styled from 'styled-components';
import SelectField from '../selectField/SelectField';
import TextField from '../textField/TextField';

export const AmountFieldStyled = styled(TextField)``;

export const CurrencySelectStyled = styled(SelectField)`
  background-color: inherit;
  border: none;
  height: 32px;
  min-width: 70px;
`;

export const AmountFieldWrapper = styled.div`
  margin-bottom: 15px;

  .input-group-addon {
    padding: 0;

    .form-field-container > div {
      padding: 0;
    }
  }
`;
