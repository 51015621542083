import { LeadStatus } from 'models/Leads';
import { StackedCalendarMode } from 'pages/stackedCalendar/Calendar.types';

export enum CalendarDayCellSelectionType {
  END = 'END',
  ONGOING = 'ONGOING',
  START = 'START',
  START_AND_END = 'START_AND_END',
  PRICING = 'PRICING',
}

export interface CalendarSelection {
  limit: {
    start?: Date;
    end?: Date;
  };
  mouseUpCount: number;
  min?: Date;
  max?: Date;
  pivot?: Date;
  propertyUidPivot: string;
  propertyUids?: string[];
  mode?: StackedCalendarMode;
}

export interface DraggableLead {
  leadUid: string;
  leadStatus: LeadStatus;
  leadPropertyUid: string;
  leadUnitUid: string;
  bgColor: string;
  borderColor: string;
  checkIn: Date;
  checkOut: Date;
  widthPixels: number;
}

export interface CalendarSelectionContainer {
  selection?: CalendarSelection;
  visibleProperties?: string[];
  dragging?: DraggableLead;
}

export interface CalendarSelectionService {
  getSelectionContainer: () => CalendarSelectionContainer;
  notify: () => void;
  reset: () => void;
  subscribe: (
    notifyCallback: (_selection: CalendarSelection) => void,
  ) => CalendarSelectionSubscription;
  updateSelectionMinMaxDates: (min: Date, max: Date) => void;
  setDraggingLead: (draggingLead: DraggableLead) => void;
  resetDraggingLead: () => void;
}

export interface CalendarSelectionSubscription {
  unsubscribe: () => void;
}

export interface CalendarSelectionLimit {
  start: Date;
  end: Date;
}

export type CalendarSelectionLimitProvider = (
  dayDate: Date,
  propertyUid: string,
) => CalendarSelectionLimit | null;

export interface CalendarSelectionMouseDownHandlerPayload {
  min: Date;
  max: Date;
  limit: {
    start: Date;
    end: Date;
  };
  propertyUid: string;
  mode?: StackedCalendarMode;
}

export interface CalendarSelectionMouseEnterHandlerPayload {
  date: Date;
  propertyUid: string;
}

export interface CalendarSelectionMouseUpHandlerPayload {
  actionCallback: (selectionData: {
    from: Date;
    to: Date;
    propertyUids: string[];
  }) => void;
  date: Date;
}
