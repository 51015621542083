import { useTranslation } from 'react-i18next';
import SectionHeader from 'pages/property/common/SectionHeader';
import useCustomAmenityModal from './modals/useCustomAmenityModal';

const CustomAmenitiesHeader = () => {
  const { openAddModal } = useCustomAmenityModal();

  const handleNewItem = () => {
    openAddModal();
  };

  const { t } = useTranslation();

  return (
    <SectionHeader
      id="custom-amenities"
      title={t('pageProperty.amenities.customAmenities.title')}
      button={{
        label: t('pageProperty.amenities.customAmenities.add'),
        onClick: handleNewItem,
      }}
      tooltip={t('pageProperty.amenities.customAmenities.tooltip')}
    />
  );
};

export default CustomAmenitiesHeader;
