import styled from 'styled-components';
import { Alert, Col, Row } from 'react-bootstrap';
import Button from '../../../../components/button/Button';

export const Container = styled.div.attrs({ className: 'container' })`
  position: relative;

  .resolutions-filter-form {
    text-align: center;
    padding-bottom: 2em;
  }
  .resolutions-filter-form label {
    font-weight: 300;
  }
  .resolutions-filter-submit {
    margin-left: 5em;
  }
`;

export const ResolutionsListWrapper = styled.div.attrs({
  'data-testid': 'resolutions-list',
})`
  .resolutions-header {
    padding: 0.9em 0em;
  }
  .resolution {
    font-weight: 300;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 0.9em 0em;
  }
  .resolution-title {
    font-weight: 700;
  }
`;

export const PropertyPicture = styled.img.attrs({
  className: 'img-circle',
  'data-testid': 'property-picture',
})`
  height: 70px;
  width: 70px;
`;

export const LeadModalButton = styled(Button)`
  padding-left: 0;
  padding-right: 0;
`;

export const NoResolutionsInfo = styled(Alert).attrs({
  className: 'text-center',
})``;

export const ResolutionRow = styled(Row)`
  display: flex;
`;

export const ResolutionColumn = styled(Col).attrs({
  'data-testid': 'resolution-column',
  xs: 4,
})``;

export const BookingColumn = styled(Col).attrs({
  'data-testid': 'booking-column',
  xs: 4,
})``;

export const StatusColumn = styled(Col).attrs({
  'data-testid': 'status-column',
  xs: 1,
})``;

export const DescriptionColumn = styled(Col).attrs({
  'data-testid': 'description-column',
  xs: 3,
})``;

export const ResolutionFilterIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
