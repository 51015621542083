import useAppConfig from './useAppConfig';
import useAppUser from './useAppUser';
import useFavicon from './useFavicon';

const useAppFavicon = () => {
  const {
    customSettings: { faviconUrl },
  } = useAppConfig();
  const { isGuest, isOwner, isServiceProvider } = useAppUser();

  const faviconUrlToSet =
    isGuest || isOwner || isServiceProvider ? faviconUrl : null;
  useFavicon(faviconUrlToSet);
};

export default useAppFavicon;
