import { TemplateModalProps } from './TemplateModal.types';
import TemplateModalContextProvider from './TemplateModalContextProvider';
import TemplateModalFormWrapper from './TemplateModalFormWrapper';

const TemplateModal = ({ modalId, params }: TemplateModalProps) => {
  return (
    <TemplateModalContextProvider modalId={modalId}>
      <TemplateModalFormWrapper params={params} />
    </TemplateModalContextProvider>
  );
};

export default TemplateModal;
