import useAppQuery from 'hooks/useAppQuery';
import {
  AvailableTemplate,
  TemplateType,
  GetTemplatesResponseTO,
  TemplateEntityType,
} from 'models/Templates';
import API from 'services/API';

const useFetchTemplate = (
  requestedTemplate: AvailableTemplate,
  entityUid: string,
  entityType: TemplateEntityType,
) => {
  const { data: fetchedTemplate, isInitialLoading: isLoadingTemplate } =
    useAppQuery(
      ['template', entityUid, entityType, requestedTemplate],
      async () => {
        const { name } = requestedTemplate;
        const params = {
          jobUid: entityType === TemplateEntityType.JOB ? entityUid : undefined,
          leadUid:
            entityType === TemplateEntityType.LEAD ? entityUid : undefined,
          filled: true,
          templateType: TemplateType.SYSTEM_TEMPLATE,
          name,
        };

        const response = await API.get<GetTemplatesResponseTO>(
          '/v3/templates',
          { params },
        );

        return response.data.templates[0];
      },
      { enabled: !!entityUid && !!requestedTemplate?.name },
    );

  return {
    isLoadingTemplate,
    fetchedTemplate,
  };
};

export default useFetchTemplate;
