import styled, { css } from 'styled-components';

export const Container = styled.div<{ fullPage?: boolean; overlay?: boolean }>`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ fullPage }) =>
    fullPage &&
    css`
      width: 100vw;
      height: 100vh;
      z-index: 10;
      position: fixed;
      top: 0;
      left: 0;
      background-color: #eee;
    `}

  ${({ overlay }) =>
    overlay &&
    css`
      background-color: rgba(0, 0, 0, 0.2);
    `}
`;
