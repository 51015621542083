export interface ChartFilter {
  type: ChartType;
  metric:
    | FinancialPerformanceMetric
    | PropertyPerformanceMetric
    | TeamPerformanceMetric
    | PieChartMetric;
  offset: number;
  limit: number;
}

export enum ChartType {
  FINANCIAL_PERFORMANCE = 'FINANCIAL_PERFORMANCE',
  PROPERTY_PERFORMANCE = 'PROPERTY_PERFORMANCE',
  TEAM_PERFORMANCE = 'TEAM_PERFORMANCE',
}

export enum FinancialPerformanceMetric {
  RENTAL_REVENUE = 'RENTAL_REVENUE',
  FEES = 'FEES',
  TAXES = 'TAXES',
  TOTAL_REVENUE = 'TOTAL_REVENUE',
}

export enum PropertyPerformanceMetric {
  RENTAL_REVENUE = 'RENTAL_REVENUE',
  NIGHTS_BOOKED = 'NIGHTS_BOOKED',
  OCCUPANCY_RATE = 'OCCUPANCY_RATE',
  AVERAGE_DAILY_RATE = 'AVERAGE_DAILY_RATE',
  REV_PAR = 'REV_PAR',
}

export enum TeamPerformanceMetric {
  LEADS_ASSIGNED = 'LEADS_ASSIGNED',
  TOTAL_REVENUE = 'TOTAL_REVENUE',
  LEADS_CONVERTED = 'LEADS_CONVERTED',
  LEADS_PROCESSED = 'LEADS_PROCESSED',
}

export enum PieChartMetric {
  REV_PAR = 'REV_PAR',
  CHANNEL = 'CHANNEL',
}
