import { useTranslation } from 'react-i18next';
import TextAreaField from 'components/forms/textAreaField/TextAreaField';

const PropertyExpectationAddEditModalDescriptionField = () => {
  const { t } = useTranslation();

  return (
    <TextAreaField
      label={t(
        'pageProperty.feesTaxesAndPolicies.propertyExpectations.modal.description',
      )}
      name="propertyExpectation.expectationDescription"
      maxCharacters={100}
      colSmInput={8}
      colSmLabel={4}
      required
    />
  );
};

export default PropertyExpectationAddEditModalDescriptionField;
