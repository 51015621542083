import useAppUser from 'hooks/useAppUser';
import useAppQuery from 'hooks/useAppQuery';
import axios from 'axios';
import { GetActivationStatusResponse } from 'models/Integrations';

const useIntegrationsActivationStatus = ({
  affiliate,
}: {
  affiliate: string;
}) => {
  const { agencyUid } = useAppUser();

  const {
    data: active,
    isInitialLoading: isIntegrationActivationStatusLoading,
  } = useAppQuery(
    ['activation-status', affiliate],
    async () => {
      const response = await axios.get<GetActivationStatusResponse>(
        `/api/internal/integrations/activation-status?affiliate=${affiliate}&agencyUid=${agencyUid}`,
      );
      return response.data.status.active;
    },
    { enabled: !!agencyUid && !!affiliate },
  );

  return {
    active,
    isLoading: isIntegrationActivationStatusLoading,
  };
};

export default useIntegrationsActivationStatus;
