import styled from 'styled-components';
import { Glyphicon } from 'react-bootstrap';

export const AutoScheduledInfoContainer = styled.div`
  max-width: 70%;
  text-align: end;

  .clock-icon {
    margin-right: 0.25rem;
    top: 2px;
  }
`;

// TODO replace with FA/react-icon one migrated to BS5
export const ClockIcon = styled(Glyphicon).attrs({
  glyph: 'time',
})``;
