import useAppModal from '../../../../hooks/useAppModal';
import OwnerAdjustmentModal from '../OwnerAdjustmentModal';
import { ownerAdjustmentsModalId } from '../OwnerAdjustmentModal.constants';
import { OwnerAdjustmentModalProps } from '../OwnerAdjustmentModal.types';

const useOwnerAdjustmentModal = () => {
  const { openModal } = useAppModal();

  const openOwnerAdjustmentModal = ({
    defaultAdjustment,
    propertyUid,
  }: OwnerAdjustmentModalProps) => {
    openModal({
      customContent: (
        <OwnerAdjustmentModal
          defaultAdjustment={defaultAdjustment}
          propertyUid={propertyUid}
        />
      ),
      id: ownerAdjustmentsModalId,
      size: 'medium',
    });
  };

  return {
    openOwnerAdjustmentModal,
  };
};

export default useOwnerAdjustmentModal;
