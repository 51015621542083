import { ReactNode } from 'react';
import { Col } from 'react-bootstrap';
import Button from 'components/button/Button';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-hook-form';
import useAppModal from 'hooks/useAppModal';

interface CommonModalFooterProps {
  children?: ReactNode;
  isUpdating: boolean;
  modalId: string;
  submitLabel: string;
}

const CommonModalFooter = ({
  children,
  isUpdating,
  modalId,
  submitLabel,
}: CommonModalFooterProps) => {
  const { t } = useTranslation();
  const { closeModal } = useAppModal();
  const { isSubmitting, errors } = useFormState();
  const cancelHandler = () => closeModal(modalId);

  return (
    <>
      <Col sm={12}>
        {errors.invalid && (
          <p className="text-danger text-center">
            {errors.invalid.message?.toString()}
          </p>
        )}
      </Col>
      <Col sm={12}>
        {children}
        <Button
          data-testid="modal-cancel-button"
          disabled={isSubmitting || isUpdating}
          onClick={cancelHandler}
        >
          {t('common.cancel')}
        </Button>
        <Button
          data-testid="modal-save-button"
          bsStyle="primary"
          disabled={isSubmitting || isUpdating}
          type="submit"
        >
          {isSubmitting || isUpdating ? t('common.saving') : submitLabel}
        </Button>
      </Col>
    </>
  );
};

CommonModalFooter.defaultProps = {
  children: undefined,
};

export default CommonModalFooter;
