import { Col, Image } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import { stackedCalendarStyleConstants } from '../Calendar.constants';
import Button from '../../../components/button/Button';
import mediaQuery from '../../../styles/mediaQuery';

export const PropertiesListContainer = styled(Col)`
  flex: 1 0 ${stackedCalendarStyleConstants.leftPanelWidth}px;
  max-width: ${stackedCalendarStyleConstants.leftPanelWidth}px;

  ${mediaQuery.xs} {
    flex: 1 0 ${stackedCalendarStyleConstants.leftPanelWidthMobile}px;
    max-width: ${stackedCalendarStyleConstants.leftPanelWidthMobile}px;
  }
`;

export const PropertiesListElements = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: ${stackedCalendarStyleConstants.leftPanelWidth}px;

  ${mediaQuery.xs} {
    width: ${stackedCalendarStyleConstants.leftPanelWidthMobile}px;
  }
`;

export const PropertyListItemWrapper = styled.div<{
  isSubUnit: boolean;
  isUnitType: boolean;
  isUnit: boolean;
  isHotel: boolean;
  hasExpandIcon: boolean;
}>`
  align-items: center;
  border-bottom: ${stackedCalendarStyleConstants.border};
  display: flex;
  height: ${stackedCalendarStyleConstants.days.height}px;
  justify-content: space-between;
  padding-right: 10px;
  position: relative;

  button {
    padding: 0;
  }

  .property-name {
    font-weight: 700;
    color: #555;
    font-family: SourceSansPro_regular, sans-serif;
  }

  .property-edit-icon {
    display: none;
  }

  :hover .property-edit-icon {
    display: inline;

    ${({ hasExpandIcon }) =>
      hasExpandIcon &&
      css`
        position: absolute;
        right: 20px;
        background-color: #fff;
      `}

    ${mediaQuery.xs} {
      display: none;
    }
  }

  ${({ isSubUnit, isUnitType, isUnit, isHotel }) => {
    if (isSubUnit) {
      return css`
        padding-left: 54px;

        ${mediaQuery.xs} {
          padding-left: 46px;
        }
      `;
    }

    if (isUnitType) {
      return css`
        padding-left: 16px;

        ${mediaQuery.xs} {
          padding-left: 8px;
        }
      `;
    }

    if (isUnit) {
      return css`
        padding-left: 54px;

        ${mediaQuery.xs} {
          padding-left: 46px;
        }
      `;
    }

    if (isHotel) {
      return css`
        .property-avatar-name-button {
          cursor: default;

          text-decoration: none !important;

          outline: none !important;
        }
      `;
    }

    return '';
  }}
`;

export const PropertyListItemAvatar = styled(Image)`
  width: 30px;
  height: 30px;
  margin-right: 8px;
`;

export const PropertyExpandArrowWrapper = styled(Button).attrs({
  bsStyle: 'link',
  'data-testid': 'property-expand-icon',
})`
  :focus,
  svg:focus {
    outline: none;
  }
`;
