import {
  Button,
  Col,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import useGetCurrencySymbol from 'hooks/useGetCurrencySymbol';
import useAppModal from 'hooks/useAppModal';
import useScreenBreakpoints from 'hooks/useScreenBreakpoints';
import FormWithProvider from 'components/forms/form/Form';
import TextField from 'components/forms/textField/TextField';
import {
  OrderAdjustmentCreationPayload,
  OrderAdjustmentTransactionType,
} from 'models/OrderAdjustments';
import SelectField from 'components/forms/selectField/SelectField';
import { AdjustmentType } from 'models/Quote';
import { queryClient } from 'App';
import { LEAD_GQL_QUERY_BASE } from 'components/domain/lead/useFetchLead';
import { AdjustmentItem } from 'models/order/AdjustmentItem';
import useCreateOrderAdjustment from 'components/domain/orderAdjustment/useCreateOrderAdjustment';
import useUpdateOrderAdjustment from 'components/domain/orderAdjustment/useUpdateOrderAdjustment';

const orderAdjustmentFormSchema = () => ({
  note: Yup.string().optional(),
  amount: Yup.number().moreThan(0).required(),
  transactionType: Yup.mixed<OrderAdjustmentTransactionType>().required(),
  adjustmentType: Yup.mixed<AdjustmentType>().required(),
});

const OrderAdjustmentModal = ({
  orderUid,
  leadUid,
  currency,
  modalId,
  adjustmentItem,
}: {
  orderUid: string;
  leadUid: string;
  currency: string;
  modalId: string;
  adjustmentItem?: AdjustmentItem;
}) => {
  const { closeModal } = useAppModal();
  const { isXS } = useScreenBreakpoints();
  const { createOrderAdjustment, isLoading: isCreating } =
    useCreateOrderAdjustment({
      orderUid,
    });
  const { updateOrderAdjustment, isLoading: isUpdating } =
    useUpdateOrderAdjustment({
      orderUid,
    });
  const getCurrencySymbol = useGetCurrencySymbol();
  const { t } = useTranslation();

  const onCancel = () => closeModal(modalId);
  const onSubmit = ({ amount, note, transactionType, adjustmentType }) => {
    const orderAdjustment: OrderAdjustmentCreationPayload = {
      amount,
      note,
      transactionType,
      adjustmentType,
    };

    const successCallback = () => {
      queryClient.invalidateQueries([LEAD_GQL_QUERY_BASE, leadUid]).then(() => {
        closeModal(modalId);
      });
    };

    if (adjustmentItem?.uid) {
      updateOrderAdjustment({
        successCallback,
        orderAdjustment,
        uid: adjustmentItem?.uid,
      });
    } else {
      createOrderAdjustment({
        successCallback,
        orderAdjustment,
      });
    }
  };

  const transactionTypeOptions = [
    ...Object.values(OrderAdjustmentTransactionType).map((value) => ({
      value,
      label: t(`componentOrderAdjustment.transactionType.${value}`),
    })),
  ];

  const adjustmentTypeOptions = [
    ...Object.values(AdjustmentType).map((value) => ({
      value,
      label: t(`componentOrderAdjustment.adjustmentType.${value}`),
    })),
  ];

  return (
    <FormWithProvider
      defaultValues={{
        transactionType:
          adjustmentItem?.transactionType ||
          OrderAdjustmentTransactionType.CHARGE,
        adjustmentType: adjustmentItem?.adjustmentType || AdjustmentType.RENT,
        amount: Math.abs(adjustmentItem?.amount) || '',
        note: adjustmentItem?.note || '',
      }}
      onSubmit={onSubmit}
      schema={orderAdjustmentFormSchema()}
    >
      <ModalHeader closeButton>
        <ModalTitle>
          {adjustmentItem
            ? t('componentOrderAdjustment.editOrderAdjustmentTitle')
            : t('componentOrderAdjustment.addOrderAdjustmentTitle')}
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={12}>
            <p>{t('componentOrderAdjustment.addOrderAdjustmentDescription')}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectField
              label={t('componentOrderAdjustment.transactionTypeLabel')}
              name="transactionType"
              options={transactionTypeOptions}
              colSmInput={8}
              colSmLabel={4}
              required
              labelOnTop={isXS}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectField
              label={t('componentOrderAdjustment.adjustmentTypeLabel')}
              name="adjustmentType"
              options={adjustmentTypeOptions}
              colSmInput={8}
              colSmLabel={4}
              required
              labelOnTop={isXS}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              label={t('componentOrderAdjustment.amountLabel')}
              name="amount"
              leftAddon={getCurrencySymbol(currency)}
              colSmInput={8}
              colSmLabel={4}
              labelOnTop={isXS}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              label={t('componentOrderAdjustment.notesLabel')}
              name="note"
              colSmInput={8}
              colSmLabel={4}
              componentClass="textarea"
              labelOnTop={isXS}
              maxCharacters={100}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button disabled={isUpdating || isCreating} onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button
          bsStyle="primary"
          disabled={isUpdating || isCreating}
          type="submit"
        >
          {t(isUpdating || isCreating ? 'common.saving' : 'common.save')}
        </Button>
      </ModalFooter>
    </FormWithProvider>
  );
};

OrderAdjustmentModal.defaultProps = {
  adjustmentItem: undefined,
};

export default OrderAdjustmentModal;
