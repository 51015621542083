import { useState, useCallback } from 'react';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Label,
  Tooltip,
} from 'recharts';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import Loader from 'components/loader/Loader';
import { useTranslation } from 'react-i18next';
import { AnalyticsChannelMetricValue } from 'pages/analytics/types/AnalyticsChannel.types';
import { AnalyticsPropertyMetricValue } from 'pages/analytics/types/AnalyticsProperty.types';
import { PieLegend } from '../../Pies.types';
import { chartConstants } from '../../Chart.constants';
import CustomTooltip from '../common/CustomTooltip';
import {
  ChartFilter,
  FinancialPerformanceMetric,
  PieChartMetric,
  PropertyPerformanceMetric,
  TeamPerformanceMetric,
} from '../../Charts.types';
import {
  CustomPieChartInner,
  CustomPieChartWrapper,
  ChannelLegendsWrapper,
} from './CustomPieChart.styles';
import ChannelLegend from './ChannelLegend';

interface CustomPieChartProps {
  pieData: AnalyticsPropertyMetricValue[] | AnalyticsChannelMetricValue[];
  dataKey: string;
  nameKey: string;
  isLoading: boolean;
  height: number;
  title: string;
  label?: React.ReactElement;
  legends: PieLegend[];
  error: any;
  metric:
    | FinancialPerformanceMetric
    | PropertyPerformanceMetric
    | TeamPerformanceMetric
    | PieChartMetric;
  isInitialLoading: boolean;
}

const CustomPieChart = ({
  pieData,
  dataKey,
  nameKey,
  isLoading,
  height,
  title,
  label,
  legends,
  error,
  metric,
  isInitialLoading,
}: CustomPieChartProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { t } = useTranslation();
  const TooltipContent = useCallback(
    ({ active, payload }) => (
      <CustomTooltip
        showTitle={false}
        chartFilter={
          {
            metric,
          } as ChartFilter
        }
        active={active}
        payload={payload}
      />
    ),
    [metric],
  );

  const onMouseEnter = useCallback(
    (entry, index: number) => {
      setActiveIndex(index);
    },
    [setActiveIndex],
  );

  const onMouseOut = useCallback(() => {
    setActiveIndex(null);
  }, [setActiveIndex]);

  return (
    <CustomPieChartWrapper>
      <h2>{title}</h2>

      {isInitialLoading && !error && <Loader />}
      {!isLoading && error && (
        <p className="error-message" data-testid="error-message">
          {t('pageAnalytics.pie.errorLoadingPies')}
        </p>
      )}
      {!isLoading && !error && pieData?.length === 0 && (
        <p className="error-message" data-testid="error-message">
          {t('pageAnalytics.pie.noData')}
        </p>
      )}

      {!isInitialLoading && !error && pieData?.length > 0 && (
        <CustomPieChartInner>
          <ResponsiveContainer width="100%" height={height}>
            <PieChart height={250}>
              <Pie
                data={pieData}
                dataKey={dataKey}
                nameKey={nameKey}
                cx="50%"
                cy="50%"
                innerRadius={label ? 45 : 0}
                outerRadius={100}
                onMouseEnter={onMouseEnter}
                onMouseOut={onMouseOut}
              >
                <>
                  {pieData.map((entry, i) => (
                    <Cell
                      style={{
                        outline: 'none',
                        opacity: i === activeIndex ? 0.4 : 1,
                      }}
                      key={`cell-${entry.channel}`}
                      fill={
                        isLoading
                          ? chartConstants.LOADING_COLOR
                          : legends[i].color
                      }
                    />
                  ))}
                  {label ? <Label position="center" content={label} /> : null}
                </>
              </Pie>
              <Tooltip
                content={TooltipContent}
                cursor={{ fill: 'transparent' }}
              />
            </PieChart>
          </ResponsiveContainer>
          <ChannelLegendsWrapper>
            {legends.map((legend) => (
              <ChannelLegend
                key={legend.name}
                label={
                  isLoading ? (
                    <LoadingPlaceholder linesCount={1} width="100%" />
                  ) : (
                    legend.name
                  )
                }
                color={isLoading ? chartConstants.LOADING_COLOR : legend.color}
              />
            ))}
          </ChannelLegendsWrapper>
        </CustomPieChartInner>
      )}
    </CustomPieChartWrapper>
  );
};

CustomPieChart.defaultProps = {
  label: null,
};

export default CustomPieChart;
