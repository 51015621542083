import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';
import theme from 'styles/theme';

export const PipelineLeadListItemStyled = styled.li`
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  list-style-type: none;
  padding: 0.5em;

  ${mediaQuery.sm} {
    padding: 0.5em 0.25em;
  }

  .lead-view-button {
    display: none;
  }

  &:hover {
    background: ${theme.colors.bgHover};

    .lead-view-button {
      display: block;
    }

    .risk-score-label {
      display: none;
    }
  }
`;

export const PipelineLeadListItemContentWrapper = styled(Row)`
  display: flex;
`;

export const PipelineLeadListItemColumn = styled(Col)`
  display: flex;
`;

export const PipelineLeadListItemDetailsColumn = styled(
  PipelineLeadListItemColumn,
)`
  flex-direction: column;
`;
export const PipelineLeadListItemStatusesColumn = styled(
  PipelineLeadListItemColumn,
)`
  justify-content: end;
`;
export const PipelineLeadListItemTagsColumn = styled(
  PipelineLeadListItemColumn,
)`
  align-items: end;
  flex-direction: column;
  gap: 1em;
`;
