import { Message } from 'models/inbox/Messages';
import {
  InboxMessageAttachmentIcon,
  InboxMessageAttachmentLink,
} from './InboxMessages.styles';

const InboxMessageAttachments = ({ message }: { message: Message }) => {
  const { attachments } = message;

  return (
    <>
      {(attachments || [])
        .filter(({ uid }) => !!uid)
        .map(({ fileName, uid, uri }) => (
          <div key={uid}>
            <InboxMessageAttachmentIcon />
            <InboxMessageAttachmentLink href={uri}>
              {fileName}
            </InboxMessageAttachmentLink>
          </div>
        ))}
    </>
  );
};

export default InboxMessageAttachments;
