import { useEffect, useMemo } from 'react';
import useAppDispatch from 'hooks/useAppDispatch';
import { resetStackedCalendarState } from 'store/slices/stackedCalendar';
import { Container } from './StackedCalendar.styles';
import CalendarContextProvider from './CalendarContextProvider';
import CalendarsWrapper from './CalendarsWrapper';

const StackedCalendar = () => {
  const dispatch = useAppDispatch();
  const calendarWrapper = useMemo(() => <CalendarsWrapper />, []);

  useEffect(() => {
    return () => {
      dispatch(resetStackedCalendarState());
    };
  }, []);

  return (
    <CalendarContextProvider>
      <Container>{calendarWrapper}</Container>
    </CalendarContextProvider>
  );
};

export default StackedCalendar;
