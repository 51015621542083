import useAppQuery from '../../../../../hooks/useAppQuery';
import API from '../../../../../services/API';
import {
  AirbnbCancellationReason,
  GetAirbnbCancellationReasonsResponseTO,
} from '../../../../../models/AirbnbCancellationReasons';

const useFetchAirbnbCancellationReasons = (leadUid: string | undefined) => {
  const { data: cancellationReasons, isInitialLoading: isFetching } =
    useAppQuery(
      ['airbnbCancellationReasons', leadUid],
      async (): Promise<AirbnbCancellationReason[]> => {
        const response = await API.get<GetAirbnbCancellationReasonsResponseTO>(
          '/v3/airbnb/cancellation-reasons',
          {
            params: {
              leadUid,
            },
          },
        );
        return response?.data?.cancellationReasons;
      },
      {
        enabled: !!leadUid,
      },
    );

  return {
    isFetching,
    cancellationReasons,
  };
};

export default useFetchAirbnbCancellationReasons;
