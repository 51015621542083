import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const GetStartedMessage = styled.h4.attrs({
  className: 'text-center',
  'data-testid': 'get-started-message',
})`
  margin-bottom: 2em;
`;

export const TwoFactorAuthTitle = styled.h2.attrs({
  className: 'text-center',
  'data-testid': 'title-2fa',
})`
  font-size: 30px;

  ${mediaQuery.xs} {
    font-size: 18px;
    margin-bottom: 1em;
  }
`;

export const WelcomeTitle = styled.h2.attrs({
  'data-testid': 'welcome-message',
})`
  font-size: 30px;

  ${mediaQuery.xs} {
    font-size: 18px;
    margin-bottom: 1em;
  }
`;
