import FunnelIcon from 'components/icons/FunnelIcon';
import { FilterIconContainer } from './FilterIcon.styles';

export interface FilterIconProps {
  isFilterOpen: boolean;
  filterCounter: number;
  onClick: () => void;
}

const FilterIcon = ({
  isFilterOpen,
  filterCounter,
  onClick,
}: FilterIconProps) => {
  return (
    <FilterIconContainer
      type="button"
      onClick={onClick}
      className={isFilterOpen ? 'active' : ''}
    >
      <FunnelIcon isActive={isFilterOpen} />
      {!!filterCounter && (
        <span
          className="selected-filters-counter"
          data-testid="selected-filters-counter"
        >
          {filterCounter}
        </span>
      )}
    </FilterIconContainer>
  );
};

export default FilterIcon;
