import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import Button from 'components/button/Button';
import TextField from 'components/forms/textField/TextField';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';

import { Agent } from 'models/Agents';
import AgentImage from 'assets/images/agent.png';
import FileField from 'components/forms/fileField/FileField';

interface Props {
  agent: Agent;
}

const ManageForm = ({ agent }: Props) => {
  const navigate = useNavigate();
  const emailInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const { watch } = useFormContext();

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, []);

  const watchPicture = watch('picture');
  const isEditing = !!agent;

  const imageSrc = () => {
    if (isEditing && !watchPicture) {
      return agent.pictureName || AgentImage;
    }
    if (watchPicture && watchPicture?.[0]) {
      return URL.createObjectURL(watchPicture[0]);
    }
    return AgentImage;
  };

  return (
    <>
      <Row>
        {!isEditing && (
          <Col xs={12} sm={8}>
            <TextField
              required
              label={t('common.email')}
              inputRef={emailInputRef}
              name="email"
              type="email"
            />
            <TextField
              required
              label={t('common.password')}
              name="password"
              type="password"
            />
          </Col>
        )}
      </Row>

      <Row>
        <Col xs={12} sm={4}>
          <TextField
            colSmLabel={4}
            colSmInput={8}
            label={t('pageThirdPartyAgencyManage.firstName')}
            name="firstName"
            trimOnBlur
          />
        </Col>
        <Col xs={12} sm={4}>
          <TextField
            colSmLabel={4}
            colSmInput={8}
            label={t('pageThirdPartyAgencyManage.lastName')}
            name="lastName"
            trimOnBlur
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={8}>
          <TextField
            label={t('pageThirdPartyAgencyManage.phoneNumber')}
            name="phoneNumber"
          />
          <TextField
            label={t('pageThirdPartyAgencyManage.businessName')}
            name="businessName"
            trimOnBlur
          />
          <TextField
            label={t('pageThirdPartyAgencyManage.websiteUrl')}
            name="website"
            leftAddon="https://"
            trimOnBlur
          />
          <TextField
            colSmInput={4}
            label={t('pageThirdPartyAgencyManage.commissionRate')}
            name="commissionRate"
            type="number"
            rightAddons={['%']}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={8}>
          <Col xs={12} sm={10} smOffset={2} className="col-align">
            <div className="profile-picture">
              <img src={imageSrc()} alt="Profile" />
            </div>
            <FileField name="picture" accept="image/*" />
          </Col>

          <Col xs={12} sm={10} smOffset={2} className="col-align">
            <CheckboxField name="active" data-testid="active">
              {t('pageThirdPartyAgencyManage.thirdPartyActive')}
            </CheckboxField>
          </Col>
        </Col>
        <Col xs={12} sm={8}>
          <hr />
          <div className="form-actions">
            <Button bsStyle="primary" type="submit">
              {t('common.save')}
            </Button>
            <Button onClick={() => navigate('/third-party-agency')}>
              {t('common.cancel')}
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ManageForm;
