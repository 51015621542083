import { UseMutationOptions } from '@tanstack/react-query';
import useAppMutation from 'hooks/useAppMutation';
import API from 'services/API';
import {
  GetAssignmentSettingsResponseTO,
  UpdateAssignmentSettingsTO,
} from 'models/AssignmentSettings';

type SaveAssignmentSettingsArgs = UpdateAssignmentSettingsTO & {
  propertyUid: string;
};

type ReactQueryMutationOptions = Omit<
  UseMutationOptions<
    GetAssignmentSettingsResponseTO,
    unknown,
    SaveAssignmentSettingsArgs
  >,
  'mutationFn'
>;

const saveAssignmentSettings = async ({
  propertyUid,
  ...assignmentSettings
}: SaveAssignmentSettingsArgs): Promise<GetAssignmentSettingsResponseTO> => {
  return API.put(
    `/api/internal/properties/${propertyUid}/assignment-settings`,
    assignmentSettings,
  );
};

const useSaveAssignmentSettingsMutation = ({
  ...options
}: ReactQueryMutationOptions = {}) => {
  return useAppMutation({
    mutationFn: saveAssignmentSettings,
    ...options,
  });
};

export default useSaveAssignmentSettingsMutation;
