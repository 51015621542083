import { useTranslation } from 'react-i18next';
import SelectField from 'components/forms/selectField/SelectField';
import { RoomTypes } from 'models/bookingDotCom/RoomTypes';
import { orderBy } from 'utils/arrayUtils';

const ROOM_TYPE_ORDER = [
  RoomTypes.APARTMENT,
  RoomTypes.SINGLE,
  RoomTypes.STUDIO,
  RoomTypes.FAMILY,
  RoomTypes.TWIN_DOUBLE,
  RoomTypes.DORMITORY_ROOM,
  RoomTypes.BED_IN_DORMITORY,
  RoomTypes.BUNGALOW,
  RoomTypes.CHALET,
  RoomTypes.HOLIDAY_HOME,
  RoomTypes.VILLA,
  RoomTypes.MOBILE_HOME,
  RoomTypes.TENT,
  RoomTypes.QUADRUPLE,
  RoomTypes.SUITE,
  RoomTypes.TRIPLE,
  RoomTypes.TWIN,
  RoomTypes.DOUBLE,
];

const RoomTypeField = () => {
  const { t } = useTranslation();

  const sortedRoomTypes = orderBy(Object.values(RoomTypes), [
    { field: (roomType) => ROOM_TYPE_ORDER.indexOf(roomType), order: 'asc' },
  ]);

  const roomTypeOptions = sortedRoomTypes.map((value) => ({
    label: t(
      `pageProperty.mainSettings.bookingDotComSettings.roomTypeOptions.${value}`,
    ),
    value,
  }));

  return (
    <SelectField
      label={t('pageProperty.mainSettings.bookingDotComSettings.roomType')}
      name="bookingDotComSettings.roomType"
      options={roomTypeOptions}
      colSmInput={7}
      colSmLabel={5}
      required
    />
  );
};

export default RoomTypeField;
