import { Row } from '@tanstack/react-table';
import DecimalFormat from 'decimal-format';
import { Discount, DiscountCodeType } from 'models/Discounts';

interface AmountPercentageCellProps {
  row: Row<Discount>;
  getValue: () => number;
}

const AmountPercentageCell = ({ row, getValue }: AmountPercentageCellProps) => {
  const decimalFormat = new DecimalFormat('#,###.##');
  const value = getValue();

  return row.original.type === DiscountCodeType.PERCENT
    ? `${row.original.percent}%`
    : `${row.original.propertyCurrency}${decimalFormat.format(value)}`;
};

export default AmountPercentageCell;
