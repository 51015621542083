import { useEffect, useMemo, useState } from 'react';
import { ImportableProperties } from 'models/bookingDotCom/ImportableProperties';
import useBookingDotComOperationsImportImportablePropertiesMutation from '../useBookingDotComOperationsImportImportablePropertiesMutation';
import ImportBookingDotComPropertiesContext from './ImportBookingDotComPropertiesContext';
import { CONNECTION_STEP } from './ImportBookingDotComPropertiesModal.utils';

const ImportBookingDotComPropertiesContextProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState<CONNECTION_STEP>(
    CONNECTION_STEP.CONNECT_TO_PROVIDER,
  );
  const [importableProperties, setImportableProperties] = useState<
    ImportableProperties[]
  >([]);
  const [isImportablePropertiesLoading, setIsImportablePropertiesLoading] =
    useState<boolean>(false);

  const { getImportableProperties } =
    useBookingDotComOperationsImportImportablePropertiesMutation();

  useEffect(() => {
    setIsImportablePropertiesLoading(true);
    getImportableProperties({
      successCallback: (_importableProperties) => {
        setImportableProperties(_importableProperties);
        setIsImportablePropertiesLoading(false);
        if (_importableProperties?.length > 0) {
          setCurrentStep(CONNECTION_STEP.PROPERTY_SELECTION);
        }
      },
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      currentStep,
      setCurrentStep,
      importableProperties,
      setImportableProperties,
      isImportablePropertiesLoading,
      setIsImportablePropertiesLoading,
    }),
    [currentStep, importableProperties, isImportablePropertiesLoading],
  );

  return (
    <ImportBookingDotComPropertiesContext.Provider value={contextValue}>
      {children}
    </ImportBookingDotComPropertiesContext.Provider>
  );
};

export default ImportBookingDotComPropertiesContextProvider;
