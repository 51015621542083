import { useLocation } from 'react-router-dom';
import {
  FullIframeLayoutDesktopContent,
  LayoutDesktopContainer,
  LayoutDesktopContent,
} from './LayoutDesktop.styles';
import LayoutDesktopFooter from './LayoutDesktopFooter';
import LayoutDesktopHeader from './LayoutDesktopHeader';

const LayoutDesktop = ({ children }) => {
  const { pathname } = useLocation();

  const isIframeFull = pathname.includes('signup');

  if (isIframeFull)
    return (
      <FullIframeLayoutDesktopContent>
        {children}
      </FullIframeLayoutDesktopContent>
    );

  return (
    <LayoutDesktopContainer>
      <LayoutDesktopHeader />

      <LayoutDesktopContent>
        <div className="card">{children}</div>
      </LayoutDesktopContent>

      <LayoutDesktopFooter />
    </LayoutDesktopContainer>
  );
};

export default LayoutDesktop;
