import React from 'react';
import TextField from 'components/forms/textField/TextField';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import AirbnbIcon from 'assets/images/icons/channels/airbnb-white.svg?react';
import LanguageSelectField from 'components/forms/languageSelectField/LanguageSelectField';
import SelectField from 'components/forms/selectField/SelectField';
import { countriesListWithFlag } from 'utils/constants/countries';
import { countryPhoneCodesWithoutFlag } from 'utils/constants/countryPhoneCodes';
import FileField from 'components/forms/fileField/FileField';
import { useFormContext } from 'react-hook-form';
import {
  Container,
  PasswordWrapper,
  AirbnbWrapper,
  FormButtonsWrapper,
  UploadImageWrapper,
  PhoneNumberWrapper,
} from './AccountForm.styles';

interface AccountFormProps {
  onChangePassword: () => void;
  isUpdateButtonDisabled: boolean;
  airbnbDetails: {
    clientId: string;
    redirectUri: string;
    isAirbnbConnected: boolean;
  };
}

const AccountForm: React.FC<AccountFormProps> = ({
  onChangePassword,
  isUpdateButtonDisabled,
  airbnbDetails,
}) => {
  const { t } = useTranslation();

  const airbnbConnectUrl = `https://www.airbnb.com/oauth2/auth?client_id=${airbnbDetails.clientId}&redirect_uri=${airbnbDetails.redirectUri}&scope=vr%2Cmessages_read%2Cmessages_write&state=`;

  const { reset } = useFormContext();

  const handleCancel = () => reset();

  return (
    <Container>
      <TextField
        name="firstName"
        label={t('pageOwnerAccount.firstName')}
        labelOnTop
      />

      <TextField
        name="lastName"
        label={t('pageOwnerAccount.lastName')}
        labelOnTop
      />

      <TextField
        name="email"
        label={t('pageOwnerAccount.email')}
        labelOnTop
        disabled
      />

      <PasswordWrapper>
        <p>{t('pageOwnerAccount.password')}</p>
        <Button
          bsStyle="danger"
          onClick={onChangePassword}
          id="change-password-button"
        >
          {t('pageOwnerAccount.changePassword')}
        </Button>
      </PasswordWrapper>

      <UploadImageWrapper>
        <p>{t('pageOwnerAccount.pictureUrl')}</p>

        <FileField name="picture" accept="image/*" />
      </UploadImageWrapper>

      <PhoneNumberWrapper>
        <p>{t('pageOwnerAccount.phoneNumber')}</p>

        <div>
          <SelectField
            name="phoneNumberAreaCode"
            options={countryPhoneCodesWithoutFlag}
          />

          <TextField name="phoneNumber" type="number" />
        </div>
      </PhoneNumberWrapper>

      <TextField
        name="businessName"
        label={t('pageOwnerAccount.businessName')}
        labelOnTop
      />

      <TextField
        name="website"
        label={t('pageOwnerAccount.websiteUrl')}
        leftAddon="https://"
        labelOnTop
      />

      <SelectField
        name="country"
        label={t('pageOwnerAccount.country')}
        options={countriesListWithFlag}
        labelOnTop
      />

      <LanguageSelectField
        name="preferredLocale"
        label={t('pageOwnerAccount.language')}
        labelOnTop
      />

      <AirbnbWrapper>
        <div>
          <p>{t('pageOwnerAccount.airbnb')}</p>

          <div>
            {airbnbDetails.isAirbnbConnected ? (
              <p>{t('pageOwnerAccount.youAreAlreadyConnectedToAirbnb')}</p>
            ) : (
              <a className="airbnb-connect-button" href={airbnbConnectUrl}>
                <AirbnbIcon />
                {t('pageOwnerAccount.airbnbConnectButton')}
              </a>
            )}
          </div>
        </div>

        <p className="airbnb-warning">
          {t('pageOwnerAccount.airbnbAllowInfo')}
        </p>
      </AirbnbWrapper>

      <FormButtonsWrapper>
        <Button onClick={handleCancel}>{t('pageOwnerAccount.cancel')}</Button>
        <Button
          bsStyle="primary"
          type="submit"
          disabled={isUpdateButtonDisabled}
        >
          {t('pageOwnerAccount.update')}
        </Button>
      </FormButtonsWrapper>
    </Container>
  );
};

export default AccountForm;
