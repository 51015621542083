import { useEffect, useState } from 'react';
import { $getRoot, $isParagraphNode } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import CloseIcon from 'components/icons/CloseIcon';
import { GetChatSuggestionResponseTO } from 'pages/inbox/messages/editor/InboxMessageSuggestion.types';
import { clearEditor } from '../RichTextEditor.utils';

const ClearEditorPlugin = ({
  setSuggestion,
}: {
  setSuggestion?: (suggestion: GetChatSuggestionResponseTO) => void;
}) => {
  const [editor] = useLexicalComposerContext();
  const [isEditorEmpty, setIsEditorEmpty] = useState(true);

  useEffect(() => {
    editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const children = $getRoot().getChildren();

        if (!children.length) {
          setIsEditorEmpty(true);
          return;
        }

        if (children.length > 1) {
          setIsEditorEmpty(false);
          return;
        }

        if ($isParagraphNode(children[0])) {
          setIsEditorEmpty(children[0].getChildren().length === 0);
        } else {
          setIsEditorEmpty(false);
        }
      });
    });
  }, [editor]);

  if (isEditorEmpty) {
    return null;
  }

  const clearEditorHandler = () => {
    clearEditor(editor);
    setSuggestion(null);
  };

  return <CloseIcon onClick={clearEditorHandler} />;
};

export default ClearEditorPlugin;

ClearEditorPlugin.defaultProps = {
  setSuggestion: () => {},
};
