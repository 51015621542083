import { useMemo, useRef, useState } from 'react';
import { AsyncTypeAheadFieldInstance } from 'components/forms/typeAheadField/AsyncTypeAheadField.types';
import useAppUser from 'hooks/useAppUser';
import useLocalStorage from 'hooks/useLocalStorage';
import PropertiesContext from './PropertiesContext';
import { getPropertiesFilterLocalStorageKey } from './filters/PropertiesFilter.constants';
import {
  PropertiesFilterSchema,
  PropertiesFilterSchemaType,
} from './filters/PropertiesFilter.schema';

const PropertiesContextProvider = ({ children }) => {
  const { userUid } = useAppUser();
  const propertySearchFieldRef = useRef<AsyncTypeAheadFieldInstance>();
  const propertiesFilterLocalStorageKey =
    getPropertiesFilterLocalStorageKey(userUid);

  const { getValue: getStoredFormValues } =
    useLocalStorage<PropertiesFilterSchemaType>(
      propertiesFilterLocalStorageKey,
    );
  const storedFormValues = getStoredFormValues();
  const [lastSubmittedFilters, setLastSubmittedFilters] = useState(
    storedFormValues
      ? (PropertiesFilterSchema.cast(
          storedFormValues,
        ) as PropertiesFilterSchemaType)
      : null,
  );

  const contextValue = useMemo(
    () => ({
      lastSubmittedFilters,
      propertiesFilterLocalStorageKey,
      propertySearchFieldRef,
      setLastSubmittedFilters,
    }),
    [
      lastSubmittedFilters,
      propertiesFilterLocalStorageKey,
      propertySearchFieldRef,
      setLastSubmittedFilters,
    ],
  );

  return (
    <PropertiesContext.Provider value={contextValue}>
      {children}
    </PropertiesContext.Provider>
  );
};

export default PropertiesContextProvider;
