import { useContext, useEffect, useState } from 'react';
import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';
import useAppUser from 'hooks/useAppUser';
import { PropertyBusinessType } from 'models/Properties';
import { getUnitType } from 'utils/lead/leadUtils';
import { addDays, differenceInCalendarDays, format, isSameDay } from 'date-fns';
import { CalendarDay } from 'pages/calendar/body/CalendarBody.types';
import CalendarBodyContext from 'pages/calendar/body/CalendarBodyContext';
import StandaloneCalendarContext from 'pages/calendar/CalendarContext';
import CalendarContext from 'pages/stackedCalendar/CalendarContext';
import useGetCalendarDataProcess from 'pages/stackedCalendar/useGetCalendarDataProcess';
import useCalendarBodyRefreshRequest from '../stackedCalendar/useCalendarBodyRefreshRequest';
import { canUsePropertyCalendarCache } from './Calendar.utils';
import { StandaloneCalendarDataFetchParams } from './Calendar.types';

const usePropertyCalendarsData = () => {
  const { daysCalendar, setDaysCalendar } = useContext(CalendarBodyContext);
  const { calendarFilter } = useContext(StandaloneCalendarContext);
  const { selectedProperty, propertiesMap, allProperties } =
    useContext(CalendarContext);
  const bodyRefreshRequest = useCalendarBodyRefreshRequest();
  const { agencyUid } = useAppUser();
  const processFetchedData = useGetCalendarDataProcess();
  const [fetchParams, setFetchParams] =
    useState<StandaloneCalendarDataFetchParams>();

  const {
    data: propertyCalendars,
    isInitialLoading: isPropertyCalendarsLoading,
  } = useAppQuery(
    ['getPropertyCalendars', selectedProperty, fetchParams, agencyUid],
    async () => {
      const { start, end } = fetchParams;

      const propertyToFetch = getUnitType(allProperties, selectedProperty);

      const response = await API.get<any>(
        `/v3/property-calendar?propertiesUids=${
          propertyToFetch?.uid || selectedProperty
        }&from=${format(start, 'yyyy-MM-dd')}&to=${format(end, 'yyyy-MM-dd')}`,
      );
      return response.data.propertyCalendars;
    },
    {
      enabled:
        !!fetchParams &&
        !canUsePropertyCalendarCache(
          daysCalendar[selectedProperty]?.daysCalendar,
          calendarFilter,
        ),
    },
  );

  useEffect(() => {
    if (!propertyCalendars && propertiesMap[selectedProperty]) {
      const property = propertiesMap[selectedProperty];

      if (property.businessType === PropertyBusinessType.UNIT) {
        const { start, end } = calendarFilter;

        const days = [];
        for (
          let tmp = start;
          differenceInCalendarDays(tmp, end) <= 0;
          tmp = addDays(tmp, 1)
        ) {
          const obj: CalendarDay = {
            availableForCheckIn: true,
            date: tmp,
            isLoading: false,
            minimumStay: 0,
            price: null,
            unvailable: false,
            jobs: null,
            leads: null,
          } as CalendarDay;

          days.push(obj);
        }
        setDaysCalendar((prevState) => ({
          ...prevState,
          [selectedProperty]: {
            daysCalendar: days,
          },
        }));
      }
      return;
    }

    if (!propertyCalendars) return;

    propertyCalendars.forEach(({ entries, propertyUid }) => {
      const property = propertiesMap[propertyUid];
      const shouldActAsUnitType =
        property?.businessType === PropertyBusinessType.UNIT_TYPE &&
        propertiesMap[selectedProperty]?.businessType ===
          PropertyBusinessType.UNIT;

      for (let e = 0; e < entries.length; e += 1) {
        const entry = entries[e];
        daysCalendar[
          shouldActAsUnitType ? selectedProperty : propertyUid
        ]?.daysCalendar.map((dayCalendar) => {
          if (format(dayCalendar.date, 'yyyy-MM-dd') === entry.date) {
            dayCalendar.minimumStay = entry.availability?.minimumStayLength;
            dayCalendar.unvailable = entry.availability?.unvailable;
            dayCalendar.availableForCheckIn =
              entry.availability?.availableForCheckIn;
            dayCalendar.price = {
              value: entry.pricing?.value,
              currency: entry.pricing?.currency,
            };
            dayCalendar.isLoading = false;
          }
          return dayCalendar;
        });
      }
    });

    processFetchedData([propertyCalendars, [], []]);

    setDaysCalendar((prevState) => ({
      ...prevState,
      [selectedProperty]: {
        daysCalendar: daysCalendar[selectedProperty]?.daysCalendar,
      },
    }));
  }, [propertyCalendars]);

  useEffect(() => {
    if (isPropertyCalendarsLoading) {
      let days = daysCalendar[selectedProperty]?.daysCalendar;
      if (!days) {
        days = [];
      }

      for (
        let tmp = calendarFilter.start;
        differenceInCalendarDays(tmp, calendarFilter.end) <= 0;
        tmp = addDays(tmp, 1)
      ) {
        const obj: CalendarDay = {
          availableForCheckIn: true,
          date: tmp,
          isLoading: true,
          minimumStay: 0,
          price: null,
          unvailable: false,
          jobs: null,
          leads: null,
        } as CalendarDay;

        if (!days.find((day) => isSameDay(day.date, tmp))) {
          days.push(obj);
        }
      }
      setDaysCalendar((prevState) => ({
        ...prevState,
        [selectedProperty]: {
          daysCalendar: days,
        },
      }));
    }
  }, [isPropertyCalendarsLoading]);

  useEffect(() => {
    setFetchParams({
      start: calendarFilter.start,
      end: calendarFilter.end,
    });
  }, [calendarFilter.start, calendarFilter.end]);

  useEffect(() => {
    if (bodyRefreshRequest) {
      const { properties } = bodyRefreshRequest;

      const unitTypeFromUnit =
        propertiesMap[selectedProperty].unitTypeUid === properties[0];
      if (!properties.includes(selectedProperty) && !unitTypeFromUnit) {
        return;
      }

      setFetchParams({
        start: calendarFilter.start,
        end: calendarFilter.end,
        requestId: new Date().getTime(),
      });
    }
  }, [bodyRefreshRequest]);

  return {
    isLoading:
      isPropertyCalendarsLoading &&
      !daysCalendar[selectedProperty]?.daysCalendar.length,
  };
};

export default usePropertyCalendarsData;
