import React, { useState } from 'react';
import { Alert, Col, ControlLabel, FormControl, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from 'components/button/Button';
import PageHeader from 'components/pageHeader/PageHeader';
import Loader from 'components/loader/Loader';
import { useFetchDiscounts } from 'components/domain/discount/useFetchDiscounts';

import { DiscountCodeStatus } from 'models/Discounts';
import { Container, Status } from './Discounts.styles';
import DiscountsTable from './DiscountsTable';

const Discounts = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [status, setStatus] = useState<DiscountCodeStatus>(
    DiscountCodeStatus.ACTIVE,
  );

  const { data: discounts, isLoading: isLoadingGetDiscounts } =
    useFetchDiscounts(status);

  const handleAddDiscount = () => {
    navigate('/discount-codes/add');
  };

  const noDiscountsFound = discounts?.length === 0 && !isLoadingGetDiscounts;

  return (
    <Container>
      <PageHeader
        title={`${t('pageDiscountCodes.pageDiscountCodes.discountCodes')}`}
        subtitle={
          <span>
            {`${t('pageDiscountCodes.pageDiscountCodes.subtitleTip')} `}
            <a
              href="https://help.hostfully.com/en/articles/4128686-using-booking-discount-codes"
              target="_blank"
              rel="noreferrer"
            >
              {t('pageDiscountCodes.pageDiscountCodes.subtitleLink')}
            </a>
          </span>
        }
        buttons={
          <Button
            bsSize="xsmall"
            bsStyle="primary"
            data-testid="add-discount-btn"
            onClick={handleAddDiscount}
          >
            {t('pageDiscountCodes.pageDiscountCodes.addDiscountCode')}
          </Button>
        }
      />

      <Status>
        <ControlLabel>{t('common.status')}</ControlLabel>

        <FormControl
          id="status"
          componentClass="select"
          value={status}
          onChange={(e: React.FormEvent<any>) =>
            setStatus(e.currentTarget.value)
          }
        >
          {(
            Object.keys(DiscountCodeStatus) as Array<
              keyof typeof DiscountCodeStatus
            >
          ).map((sts: string) => (
            <option key={sts} value={sts}>
              {sts}
            </option>
          ))}
        </FormControl>
      </Status>

      <Col>
        {isLoadingGetDiscounts && <Loader />}
        {!isLoadingGetDiscounts && discounts.length > 0 && (
          <DiscountsTable data={discounts} status={status} />
        )}
        {noDiscountsFound && (
          <Row>
            <Col>
              <Alert bsStyle="warning">
                {t('pageDiscountCodes.pageDiscountCodes.noDiscountsFound')}
              </Alert>
            </Col>
          </Row>
        )}
      </Col>
    </Container>
  );
};

export default Discounts;
