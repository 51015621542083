import { ReactNode, useContext } from 'react';
import TableContext from '../TableContext';
import { SingleCellFooterStyled } from './SingleCellFooter.styles';

interface SingleCellFooterProps {
  className?: string;
  children?: ReactNode;
}

const SingleCellFooter = ({ className, children }: SingleCellFooterProps) => {
  const { tableInstance } = useContext(TableContext);

  return (
    <SingleCellFooterStyled className={className}>
      <tr>
        <td colSpan={tableInstance.getHeaderGroups()[0].headers.length}>
          {children}
        </td>
      </tr>
    </SingleCellFooterStyled>
  );
};

SingleCellFooter.defaultProps = {
  className: '',
  children: null,
};

export default SingleCellFooter;
