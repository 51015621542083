import { UseQueryOptions } from '@tanstack/react-query';
import { GetAgencySettingsResponseTO } from 'models/AgencySettings';
import API from 'services/API';
import useAppQuery from './useAppQuery';
import useAppUser from './useAppUser';

export const AGENCY_SETTINGS_BASE_QUERY_KEY = 'agencySettings';

type ReactQueryOptions = Omit<
  UseQueryOptions<GetAgencySettingsResponseTO>,
  'queryKey' | 'queryFn'
>;

const useAgencySettings = ({ enabled, ...options }: ReactQueryOptions = {}) => {
  const { agencyUid } = useAppUser();
  const { data: agencySettings, isLoading: isAgencySettingsLoading } =
    useAppQuery(
      [AGENCY_SETTINGS_BASE_QUERY_KEY, agencyUid],
      async () => {
        const response = await API.get<GetAgencySettingsResponseTO>(
          `v3/agencies/${agencyUid}/settings`,
          {
            headers: {
              Authorization: agencyUid,
            },
          },
        );
        return response.data;
      },
      { enabled: enabled !== undefined ? enabled : true, ...options },
    );

  const isLeadAssignmentEnabled =
    agencySettings?.settings?.leadAssignmentEnabled;

  return { isLeadAssignmentEnabled, isAgencySettingsLoading };
};

export default useAgencySettings;
