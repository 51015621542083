import { ReactNode, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import CheckboxField, {
  CheckboxFieldProps,
} from '../checkboxField/CheckboxField';
import GenericFormField from '../genericFormField/GenericFormField';
import {
  FieldRendererProps,
  GenericFormFieldBaseProps,
} from '../genericFormField/GenericFormField.types';
import { CheckboxGroupFieldStyled } from './CheckboxGroupField.styles';

interface CheckboxOptionCommon
  extends Partial<Omit<CheckboxFieldProps, 'name' | 'label' | 'value'>> {
  /**
   * If not provided, it will render with its "name" as the label
   */
  label?: ReactNode;
  name: string;
}

export type CheckboxOption =
  | (CheckboxOptionCommon & { value?: boolean })
  | (CheckboxOptionCommon & { value?: undefined; indeterminate: true });

export type CheckboxGroupFieldProps = CheckboxFieldProps &
  Omit<GenericFormFieldBaseProps, 'fieldRenderer'> & {
    allLabel?: ReactNode;
    options: CheckboxOption[];
    hideParentCheckbox?: boolean;
  };

/**
 * Component for controlling a group of checkboxes with a parent checkbox for selecting/deselecting all.
 * It should receive as a prop all of the checkboxes initial state {@link CheckboxOption}.
 */
const CheckboxGroupField = ({
  allLabel,
  name,
  options,
  hideParentCheckbox,
  ...props
}: CheckboxGroupFieldProps) => {
  const { setValue } = useFormContext();
  const getOptionName = (option: Pick<CheckboxOption, 'name'>) =>
    `${hideParentCheckbox ? '' : `${name}Option`}${option.name}`;
  const optionsValues = useWatch({ name: options?.map(getOptionName) });
  const updatedOptions = optionsValues.map((value, index) => ({
    ...options[index],
    value,
  }));

  const indeterminateOptions = updatedOptions?.filter(
    (o) => !o.value && 'indeterminate' in o && o.indeterminate,
  );

  const checkedOptions = updatedOptions?.filter((o) => o.value);
  const isEveryChecked = checkedOptions.length === options.length;
  const isAnyIndeterminate = indeterminateOptions.length > 0;

  const isIndeterminate =
    (checkedOptions.length && !isEveryChecked) || isAnyIndeterminate;

  const handleOnChange = () => {
    options?.forEach((option) => {
      setValue(getOptionName(option), !isEveryChecked);
    });
  };

  // Set the default value for all the options
  useEffect(() => {
    options?.forEach((option) => {
      setValue(getOptionName(option), option.value, { shouldDirty: false });
    });
  }, []);

  const fieldRenderer = ({
    field: { ref, onChange: _onChange, value: _value, ...fieldProps },
  }: FieldRendererProps) => (
    <CheckboxGroupFieldStyled
      data-testid="checkbox-group-container"
      $indentCheckboxes={!hideParentCheckbox}
    >
      {!hideParentCheckbox && (
        <CheckboxField
          ref={ref}
          checked={isEveryChecked}
          value={`${isEveryChecked}`}
          indeterminate={isIndeterminate}
          onChange={() => {
            handleOnChange();
          }}
          {...props}
          {...fieldProps}
        >
          {allLabel}
        </CheckboxField>
      )}

      <div data-testid="checkbox-group-options" className="options">
        {updatedOptions?.map(
          ({
            name: optionName,
            value: optionValue,
            indeterminate: optionIndeterminate,
            label: optionLabel,
            ...optionProps
          }) => (
            <CheckboxField
              key={optionName}
              name={getOptionName({ name: optionName })}
              indeterminate={
                optionIndeterminate && optionValue === undefined
                  ? optionIndeterminate
                  : false
              }
              {...optionProps}
            >
              {optionLabel ?? optionName}
            </CheckboxField>
          ),
        )}
      </div>
    </CheckboxGroupFieldStyled>
  );

  return (
    <GenericFormField
      colSmInput={12}
      name={`${name}Parent`}
      fieldRenderer={fieldRenderer}
      {...props}
    />
  );
};

CheckboxGroupField.defaultProps = {
  allLabel: undefined,
  hideParentCheckbox: undefined,
};

export default CheckboxGroupField;
