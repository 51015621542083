import useAppUser from 'hooks/useAppUser';
import { UserType } from 'models/Users';
import PublicLayout from 'pages/_layouts/publicLayout/PublicLayout';
import { Outlet, Navigate, useSearchParams } from 'react-router-dom';

const PublicRoute = () => {
  const [searchParams] = useSearchParams();
  const { userType } = useAppUser();
  const hasAccessToken = localStorage.getItem('access_token');

  // if user is already logged in mobile browser, and the URL contains mobile app,
  // then we should clean the localStorage and redirect to the login page:
  const isLoginPageFromMobile = searchParams
    .get('clientId')
    ?.startsWith('pmpMobile_');

  if (isLoginPageFromMobile) {
    localStorage.removeItem('access_token');
    return (
      <PublicLayout>
        <Outlet />
      </PublicLayout>
    );
  }

  if (userType && hasAccessToken) {
    const initialPageRedirection = {
      [UserType.ServiceProviderUser]: '/service-provider/dashboard',
      [UserType.Employee]: '/dashboard',
      [UserType.Guest]: '/guest/dashboard',
      [UserType.Owner]: '/owner/calendar',
      [UserType.Agent]: '/agent/dashboard', // FIXME: [UserType.Agent] doesnt have a dashboard (dashboard_agent.jsp empty);
    };
    return <Navigate to={initialPageRedirection[userType] || '/dashboard'} />;
  }

  return !hasAccessToken ? (
    <PublicLayout>
      <Outlet />
    </PublicLayout>
  ) : (
    <Navigate to="/dashboard" />
  );
};

export default PublicRoute;
