import styled from 'styled-components';
import { Alert, ListGroup, ListGroupItem } from 'react-bootstrap';
import Button from '../../../components/button/Button';

export const NothingFoundMessage = styled(Alert).attrs({
  className: 'text-center',
  'data-testid': 'no-thread-found-message',
})``;

export const ThreadsList = styled(ListGroup)`
  margin-bottom: 0.7em;
`;

export const ThreadsListItem = styled(ListGroupItem)<{ active: boolean }>`
  border: none;
  display: flex;

  ${({ active }) =>
    active &&
    `
    color: #1f1f1f !important;
    background-color: #e6e6e6 !important;
    border-color: #e6e6e6 !important;
  `}
`;

export const ThreadAvatarContainer = styled.div.attrs({
  className: 'hidden-xs',
})`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin-right: 1em;
  overflow: hidden;
`;

export const ThreadAvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const avatarThemeToBgColor: { [theme: number]: string } = {
  0: '#007bff',
  1: '#6c757d',
  2: '#28a745',
  3: '#dc3545',
  4: '#ffc107',
  5: '#17a2b8',
  6: '#343a40',
};

export const ThreadAvatarText = styled.div<{ theme: number }>`
  align-items: center;
  background-color: ${(props) =>
    avatarThemeToBgColor[props.theme] || avatarThemeToBgColor[0]};
  color: #fff;
  display: flex;
  flex-shrink: 0;
  height: 100%;
  justify-content: center;
  text-transform: uppercase;
`;

export const ThreadInfoContainer = styled.div.attrs({
  'data-testid': 'thread-details',
})<{ unread: boolean }>`
  flex: 1 1 auto;
  font-weight: ${({ unread }) => (unread ? '700' : '300')};
`;

export const ThreadInfoParticipantName = styled.div.attrs({
  'data-testid': 'thread-participant-name',
})`
  font-size: 1.2em;
`;

export const LoadMoreLink = styled(Button).attrs({
  bsStyle: 'link',
  'data-testid': 'load-more-threads',
})``;
