import { createAction } from '@reduxjs/toolkit';

export enum SyncCheckActionType {
  RUN_SYNC_CHECK = 'RUN_SYNC_CHECK',
}

export interface RunSyncCheckActionPayload {
  force?: boolean;
}

export interface RunSyncCheckAction {
  type: SyncCheckActionType.RUN_SYNC_CHECK;
  payload: RunSyncCheckActionPayload;
}

export const runSyncCheckAction = createAction<RunSyncCheckActionPayload>(
  SyncCheckActionType.RUN_SYNC_CHECK,
);
