import styled, { css } from 'styled-components';

export const PropertyChannelsWidgetContainer = styled.div.attrs({
  'data-testid': 'property-channels-widget-container',
})`
  position: fixed;
  right: 0px;
  top: 210px;
  z-index: 99;
`;

export const PropertyChannelsListHeader = styled.div.attrs({
  className: 'property-channels-list-header',
})`
  display: flex;
  align-items: center;

  height: 40px;
  padding: 0 16px;

  > p {
    margin-bottom: 2px;
    margin-left: 14px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
`;

export const PropertyChannelsListWrapper = styled.div<{ $isExpanded: boolean }>`
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 10px 0px 0px 10px;

  overflow: auto;
  top: 0px;

  scrollbar-width: none; /* Firefox */
  position: absolute;

  right: 0px;
  transform: translateX(
    ${({ $isExpanded }) => ($isExpanded ? '0' : 'calc(100% - 50px)')}
  );
  transition: transform 0.5s ease-out;

  ::-webkit-scrollbar {
    display: none;
  }

  ${({ $isExpanded }) =>
    !$isExpanded &&
    css`
      .property-channels-list-item,
      .property-channels-list-header {
        padding-left: 13px;
      }
    `}
`;

export const PropertyChannelsList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  border-top: 1px solid #e6e6e6;

  > li:first-child {
    margin-top: 8px;
  }

  > li {
    margin-bottom: 8px;
  }
`;

export const PropertyChannelsListItem = styled.div.attrs({
  'data-testid': 'property-channels-list-item',
  className: 'property-channels-list-item',
})`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  height: 40px;

  padding: 0 16px;
`;

export const PropertyChannelIconContainer = styled.div.attrs({
  'data-testid': 'property-channel-icon',
})`
  margin-right: 16px;

  img {
    width: 24px;
    height: 24px;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const PropertyChannelNameContainer = styled.div.attrs({
  'data-testid': 'property-channel-name',
})`
  line-height: 24px;
`;

const sharedIconHoverStyles = css`
  :hover {
    position: relative;
    ::after {
      content: '';
      position: absolute;

      height: 26px;
      width: 26px;
      border-radius: 50%;
      background-color: rgba(206, 212, 218, 1);
      top: -5px;
      left: -5px;
      z-index: -1;
    }
  }
`;

export const PropertyChannelIconsContainer = styled.div.attrs({
  'data-testid': 'property-channel-icons',
})`
  justify-content: flex-end;
  flex-grow: 1;
  display: flex;
  margin-left: 24px;
  align-items: center;

  > span {
    height: 16px;
    width: 16px;

    a {
      height: 16px;
      width: 16px;
      display: block;

      ${sharedIconHoverStyles}
    }

    div {
      cursor: pointer;
      height: 16px;
      width: 16px;

      ${sharedIconHoverStyles}
    }

    :not(:last-child) {
      margin-right: 8px;
    }
  }
`;
