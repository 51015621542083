import styled from 'styled-components';
import { Glyphicon } from 'react-bootstrap';
import SelectTemplateIcon from 'assets/images/template-select.svg?react';

export const InboxMessageEditorContainer = styled.div`
  margin-bottom: 1.5em;
`;

export const InboxMessageEditorAttachmentsContainer = styled.div.attrs({
  className: 'text-right',
})``;

export const InboxMessageEditorAttachmentRow = styled.div.attrs(
  (props: { $hasError: boolean }) => ({
    className: props.$hasError ? 'text-danger' : '',
    'data-testid': 'inbox-message-editor-attachment-container',
  }),
)<{ $hasError: boolean }>`
  margin: 0.2em 0;
`;

export const InboxMessageEditorAttachmentText = styled.span.attrs({
  className: 'small',
})``;

export const InboxMessageEditorDeleteAttachmentIcon = styled(Glyphicon).attrs({
  className: 'small',
  'data-testid': 'inbox-message-editor-attachment-delete-button',
  glyph: 'trash',
})`
  cursor: pointer;
  margin-left: 0.3em;
  top: 2px;
`;

export const InboxMessageEditorErrorContainer = styled.div.attrs({
  className: 'text-danger text-right',
  'data-testid': 'inbox-message-editor-error',
})``;

export const InboxMessageEditorTemplateSelectIcon = styled(SelectTemplateIcon)<{
  disabled: boolean;
}>`
  padding: 5px;
  width: 35px;
  height: 35px;

  ${(props) =>
    !props.disabled &&
    `
    cursor: pointer;
    
    :hover {
      background-color: #f5f5f5;
      border-radius: 50%;
    }
  `}
`;
