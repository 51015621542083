import { useTranslation } from 'react-i18next';
import { Message } from '../../../models/inbox/Messages';
import useInboxMessageMetadata from './useInboxMessageMetadata';
import {
  InboxMessageResendButton,
  InboxMessageSendError,
  InboxMessageSendingLoader,
} from './InboxMessages.styles';
import useResendInboxMessage from './useResendInboxMessage';

const InboxMessageFooterError = ({ message }: { message: Message }) => {
  const { uid } = message;
  const { isSending, isSendError } = useInboxMessageMetadata(uid);
  const { resendMessage } = useResendInboxMessage(uid);
  const { t } = useTranslation();

  if (isSending) {
    return <InboxMessageSendingLoader />;
  }

  if (isSendError) {
    return (
      <InboxMessageSendError>
        {t('pageInbox.messages.sendError')}
      </InboxMessageSendError>
    );
  }

  return (
    <InboxMessageResendButton onClick={resendMessage}>
      {t('pageInbox.messages.retry')}
    </InboxMessageResendButton>
  );
};

export default InboxMessageFooterError;
