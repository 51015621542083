import { useContext } from 'react';
import CalendarContext from 'pages/stackedCalendar/CalendarContext';
import { PropertyBusinessType } from 'models/Properties';
import { Job, JobStatus } from 'models/Jobs';
import Tooltip from 'components/tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { JobViewWrapper } from './JobView.style';

interface Props {
  jobs: Job[];
}

const JobView = ({ jobs }: Props) => {
  const { selectedProperty, propertiesMap } = useContext(CalendarContext);
  const { t } = useTranslation();

  const currentPropertyIsUnitType =
    propertiesMap[selectedProperty]?.businessType ===
    PropertyBusinessType.UNIT_TYPE;

  if (!jobs?.length || currentPropertyIsUnitType) return null;

  const dataJobUids = jobs.map(({ uid }) => uid).join('--\u200c--');

  return (
    <Tooltip
      id={`job_tooltip_${jobs[0].uid}`}
      placement="top"
      text={
        jobs.length > 1
          ? t('pageCalendar.tooltip.jobNumber', { number: jobs.length })
          : t('pageCalendar.tooltip.jobSingle')
      }
    >
      <JobViewWrapper
        data-job-uid={dataJobUids}
        $isDone={jobs && jobs[0].status === JobStatus.DONE}
      />
    </Tooltip>
  );
};

export default JobView;
