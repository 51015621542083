import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import InboxActiveLeadLoader from './InboxActiveLeadLoader';

const InboxLeadLoader = () => {
  return (
    <>
      <LoadingPlaceholder linesCount={2} width="60%" />
      <LoadingPlaceholder linesCount={2.5} margin="1em 0" />
      <InboxActiveLeadLoader />
    </>
  );
};

export default InboxLeadLoader;
