import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import Loader from 'components/loader/Loader';
import useLeadActions, {
  buttonStyles,
} from 'components/domain/lead/actions/useLeadActions';
import InboxContext from '../InboxContext';
import { LeadAvailablePipelineAction } from '../../../models/Leads';
import {
  LeadButtonsAdditionalInfo,
  LeadButtonsContainer,
} from './InboxLead.styles';
import InboxLeadsContext from './InboxLeadsContext';

const InboxActiveLeadButtons = () => {
  const { activeLead: lead } = useContext(InboxContext);
  const { deleteLeadFromMap } = useContext(InboxLeadsContext);
  const { airbnbActions, standardActions, isExecutingAction, takeAction } =
    useLeadActions({ lead });
  const { t } = useTranslation();

  if (!airbnbActions.length && !standardActions.length) {
    return null;
  }

  if (isExecutingAction) {
    return (
      <LeadButtonsContainer>
        <Loader />
      </LeadButtonsContainer>
    );
  }

  const handleAction = (action: LeadAvailablePipelineAction) => {
    takeAction({
      action,
      lead,
      updateLeadCallback: () => deleteLeadFromMap(lead.uid),
    });
  };

  // prettier breaks locale Trans component interpolation, so it must be excluded from formatting
  // prettier-ignore
  const docsLink = (
    <Trans i18nKey="componentLead.actionsInfo.airbnb.docDetails">
      Please see <a
        href="https://help.hostfully.com/en/articles/4623220-airbnb-inquiry-booking-request-auto-responses"
        rel="noreferrer"
        target="_blank"
      >
        online documentation
      </a> for details
    </Trans>
  );

  return (
    <>
      <LeadButtonsContainer>
        {[...airbnbActions, ...standardActions].map((action) => (
          <Button
            bsStyle={buttonStyles[action] || 'success'}
            className={`lead-action-button-${action.toLowerCase()}`}
            onClick={() => handleAction(action)}
            data-testid={`lead-action-button-${action}`}
            key={`lead-action-button-${action}`}
          >
            {t(`componentLead.actions.${action}`)}
          </Button>
        ))}
      </LeadButtonsContainer>
      {!!airbnbActions.length && (
        <>
          <LeadButtonsAdditionalInfo>
            {t('componentLead.actionsInfo.airbnb.declineNote')}
          </LeadButtonsAdditionalInfo>
          <LeadButtonsAdditionalInfo>{docsLink}</LeadButtonsAdditionalInfo>
        </>
      )}
    </>
  );
};

export default InboxActiveLeadButtons;
