import useAppQuery from '../../hooks/useAppQuery';
import API from '../../services/API';
import {
  GetUserRegistrationDetailsResponseTO,
  UserRegistrationDetails,
} from '../../models/Login';

const useFetchUserRegistrationDetails = (
  registrationTokenParam: string | undefined,
) => {
  const { data: userRegistrationDetails, isInitialLoading: isFetching } =
    useAppQuery(
      [
        `user-registration-details-${registrationTokenParam}`,
        registrationTokenParam,
      ],
      async (): Promise<UserRegistrationDetails | undefined> => {
        const response = await API.get<GetUserRegistrationDetailsResponseTO>(
          `/web-app/public-user-registration-details?rt=${registrationTokenParam}`,
        );
        return response.data;
      },
      {
        enabled: !!registrationTokenParam,
      },
    );

  return { isFetching, userRegistrationDetails };
};

export default useFetchUserRegistrationDetails;
