import { useTranslation } from 'react-i18next';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { getLeadDetailLocalizedIntValue } from 'utils/lead/leadUtils';
import OrderViewPriceDetailsListItem from 'pages/orderView/priceDetails/OrderViewPriceDetailsListItem';
import { PriceDetailsList } from 'pages/orderView/priceDetails/OrderViewPriceDetails.styles';
import useOrderViewFetchedData from 'pages/orderView/useOrderViewFetchedData';

const ChannelCommission = () => {
  const { t } = useTranslation();
  const { lead } = useOrderViewFetchedData();

  const {
    order: { channelCommissionMetadata, currency },
    checkInDate,
    checkOutDate,
  } = lead;
  const { commissionAmount } = channelCommissionMetadata || {};

  const nightsCount = differenceInCalendarDays(checkOutDate, checkInDate);

  return (
    <PriceDetailsList data-testid="channel-commission-list">
      <OrderViewPriceDetailsListItem
        title={t('pageOrder.channelCommission')}
        priceDetailsItems={[
          {
            label: t('pageOrder.totalCommissionWithNightValue', {
              nights: getLeadDetailLocalizedIntValue(nightsCount, 'night', t),
            }),
            amount: commissionAmount,
            currency,
          },
        ]}
      />
    </PriceDetailsList>
  );
};

export default ChannelCommission;
