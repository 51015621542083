import { useQueryClient } from '@tanstack/react-query';
import { ALL_PROPERTIES_BASE_QUERY_KEY } from './useFetchAllProperties';

const useInvalidateFetchAllProperties = () => {
  const queryClient = useQueryClient();
  const invalidateFetchAllProperties = () => {
    return queryClient.invalidateQueries({
      queryKey: [ALL_PROPERTIES_BASE_QUERY_KEY],
    });
  };

  return {
    invalidateFetchAllProperties,
  } as const;
};

export default useInvalidateFetchAllProperties;
