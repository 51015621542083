import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useFormatNumber from 'hooks/useFormatNumber';
import SelectField from 'components/forms/selectField/SelectField';
import TextField from 'components/forms/textField/TextField';
import PropertyFormFieldLabel from 'pages/property/common/PropertyFormFieldLabel';
import { range } from 'utils/arrayUtils';

const NumberOfBedsField = () => {
  const { t } = useTranslation();
  const { uid: propertyUid } = useParams();
  const formatNumber = useFormatNumber();
  const rooms = useWatch({ name: 'capacityDetails.rooms' });

  const isEditMode = !!propertyUid;
  const roomsConfiguredInModal = !!rooms?.length;

  // If on Edit mode just return the field as a text field but disabled
  if (isEditMode || roomsConfiguredInModal) {
    return (
      <TextField
        label={
          <PropertyFormFieldLabel name="capacityDetails.bedCount">
            {t('pageProperty.mainSettings.capacityDetails.bedCount')}
          </PropertyFormFieldLabel>
        }
        name="capacityDetails.bedCount"
        colSmInput={7}
        colSmLabel={5}
        rightTooltip={t(
          'pageProperty.mainSettings.capacityDetails.bedCountTooltip',
        )}
        disabled
      />
    );
  }

  const numberOfBedsOptions = range(0, 21).map((value) => ({
    label: formatNumber({ value }),
    value,
  }));

  return (
    <SelectField
      label={
        <PropertyFormFieldLabel name="capacityDetails.bedCount">
          {t('pageProperty.mainSettings.capacityDetails.bedCount')}
        </PropertyFormFieldLabel>
      }
      name="capacityDetails.bedCount"
      options={numberOfBedsOptions}
      colSmInput={7}
      colSmLabel={5}
      rightTooltip={t(
        'pageProperty.mainSettings.capacityDetails.bedCountTooltip',
      )}
    />
  );
};

export default NumberOfBedsField;
