import { Container } from './OwnerAdjustments.styles';
import OwnerAdjustmentsHeader from './header/OwnerAdjustmentsHeader';
import OneTimeAdjustmentsTable from './tables/OneTimeAdjustmentsTable';
import RecurringAdjustmentsTable from './tables/RecurringAdjustmentsTable';

const OwnerAdjustments = () => {
  return (
    <Container>
      <OwnerAdjustmentsHeader />
      <RecurringAdjustmentsTable />
      <OneTimeAdjustmentsTable />
    </Container>
  );
};

export default OwnerAdjustments;
