import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useWatch } from 'react-hook-form';
import { PROPERTIES_FILTER_OPTIONS_BASE_QUERY_KEY } from 'components/domain/property/useFetchPropertiesFilterOptions';
import TagsSelectField from 'components/forms/domain/tag/TagsSelectField';
import useAppUser from 'hooks/useAppUser';
import { TagType } from 'models/Tags';
import useIsMobileScreen from 'hooks/useIsMobileScreen';
import {
  PropertyTagsContainer,
  PropertyTagsMoreButton,
} from './PropertyTags.styles';
import PropertyTagsModal from './PropertyTagsModal';

const MAX_TAGS_DISPLAYED = 4;

const PropertyTags = ({
  propertyUid,
  propertyName,
}: {
  propertyUid: string;
  propertyName: string;
}) => {
  const isMobile = useIsMobileScreen();
  const tags = useWatch({ name: 'tags' });
  const { t } = useTranslation();
  const [showMoreModal, setShowMoreModal] = useState(false);
  const { agencyUid } = useAppUser();
  const queryClient = useQueryClient();

  const handleSuccessCallback = () => {
    queryClient.invalidateQueries([
      PROPERTIES_FILTER_OPTIONS_BASE_QUERY_KEY,
      agencyUid,
    ]);
  };

  const hasMoreTagsHidden = tags?.length > MAX_TAGS_DISPLAYED;

  const params = {
    entityUid: propertyUid,
    tagType: TagType.PROPERTY,
  };

  if (isMobile) {
    return null;
  }

  return (
    <>
      {showMoreModal && (
        <PropertyTagsModal
          params={params}
          successCallback={handleSuccessCallback}
          onClose={() => setShowMoreModal(false)}
          name={propertyName}
        />
      )}

      <PropertyTagsContainer $hasMore={hasMoreTagsHidden}>
        <TagsSelectField
          name="tags"
          options={[]}
          className="tags-selector"
          saveOnChange
          saveOnChangeParams={params}
          successCallback={handleSuccessCallback}
          maxTagLength={10}
          maxTagsDisplayed={MAX_TAGS_DISPLAYED}
        />
        {hasMoreTagsHidden ? (
          <PropertyTagsMoreButton onClick={() => setShowMoreModal(true)}>
            {t('pageProperties.body.more')}
          </PropertyTagsMoreButton>
        ) : null}
      </PropertyTagsContainer>
    </>
  );
};

export default PropertyTags;
