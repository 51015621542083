import { useTranslation } from 'react-i18next';
import { FormControl } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { useFormContext } from 'react-hook-form';
import { getDateTimeLocale } from 'i18n/dateTimeInternationalization';
import GenericFormField from '../genericFormField/GenericFormField';
import {
  CalendarIcon,
  ClockIcon,
  DateTimePickerFieldWrapper,
} from './DateTimePickerField.styles';

import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  name: string;
  label?: string;
  timeOnly?: boolean;
  withTime?: boolean;
  dateFormat?: string;
  readOnly?: boolean;
  minTime?: Date;
  maxTime?: Date;
  minDate?: Date;
  maxDate?: Date;
  injectTimes?: Date[];
  todayButton?: string;
  timeIntervals?: number;
  help?: string;
  disabled?: boolean;
  'data-testid'?: string;
  [key: string]: any;
}

const DateTimePickerField = ({
  name,
  timeOnly,
  withTime,
  dateFormat,
  readOnly,
  minTime,
  maxTime,
  minDate,
  maxDate,
  injectTimes,
  todayButton,
  timeIntervals,
  help,
  disabled,
  label,
  'data-testid': dataTestId,
  ...rest
}: Props) => {
  const { trigger } = useFormContext();
  const {
    t,
    i18n: { language: preferredLanguage },
  } = useTranslation();
  const locale = getDateTimeLocale(preferredLanguage);

  const clickInputDatePicker = () => {
    const datePicker = document.getElementById(name);
    if (datePicker) {
      datePicker.click();
    }
  };

  const fieldRenderer = ({ field: { onChange, ref, value } }: any) => (
    <DateTimePickerFieldWrapper>
      <ReactDatePicker
        onChange={(...args) => {
          onChange(...args);
          trigger(name);
        }}
        selected={value}
        customInput={<FormControl data-testid={dataTestId} inputRef={ref} />}
        id={name}
        locale={locale}
        dateFormat={timeOnly ? 'p' : dateFormat ?? 'PP'}
        timeCaption={t('common.time')}
        readOnly={readOnly}
        showTimeSelect={timeOnly || withTime}
        showTimeSelectOnly={timeOnly}
        minTime={minTime}
        minDate={minDate}
        maxTime={maxTime}
        maxDate={maxDate}
        injectTimes={injectTimes}
        todayButton={todayButton}
        timeIntervals={timeIntervals}
        disabled={disabled}
        autoComplete="off"
        popperClassName="date-time-picker-popper"
        popperModifiers={[
          {
            name: 'arrow',
            options: {
              padding: ({ popper }) => ({
                right: popper.width / 2,
              }),
            },
          },
        ]}
      />
      {!timeOnly ? (
        <CalendarIcon onClick={clickInputDatePicker} />
      ) : (
        <ClockIcon onClick={clickInputDatePicker} />
      )}
    </DateTimePickerFieldWrapper>
  );

  return (
    <GenericFormField
      name={name}
      fieldRenderer={fieldRenderer}
      help={help}
      label={label}
      {...rest}
    />
  );
};

DateTimePickerField.defaultProps = {
  timeOnly: false,
  label: '',
  'data-testid': undefined,
  withTime: false,
  dateFormat: undefined,
  readOnly: false,
  minTime: undefined,
  maxTime: undefined,
  minDate: undefined,
  maxDate: undefined,
  injectTimes: undefined,
  todayButton: undefined,
  timeIntervals: 10,
  help: '',
  disabled: false,
};

export default DateTimePickerField;
