import styled from 'styled-components';

export const DailyRateFieldWrapper = styled.div.attrs({
  'data-testid': 'daily-rate-field',
  className: 'daily-price-field-wrapper form-group',
})`
  margin-bottom: 0 !important;

  .form-field-container {
    margin-left: 0;
    margin-right: 0;
  }

  .menu-container {
    // width of the menu container
    width: 100%;
    min-width: 240px;
    text-align: left;
    z-index: 999;
  }

  .control-container {
    border: none;
    background-color: white;
    min-height: 32px;
    flex-wrap: nowrap;
    min-width: 100px;
  }

  .input-group-addon {
    padding: 0;
  }

  .input-group-addon:last-child {
    padding: 6px 12px !important;
  }

  .single-value-container {
    line-height: 27px;
  }

  .indicators-container > div {
    padding: 6px 0;
  }
`;
