import useAppModal from '../../../../hooks/useAppModal';
import AdjustmentModal from './AdjustmentModal';
import { AdjustmentModalParams } from './AdjustmentModal.types';

const adjustmentModalId = 'adjustment-modal';

const useOpenAdjustmentModal = () => {
  const { openModal } = useAppModal();

  const openAdjustmentModal = (params) => {
    openModal({
      customContent: (
        <AdjustmentModal modalId={adjustmentModalId} params={params} />
      ),
      id: adjustmentModalId,
      size: 'lg',
    });
  };

  return {
    createAdjustmentModal: (params: AdjustmentModalParams) => {
      openAdjustmentModal(params);
    },
  };
};

export default useOpenAdjustmentModal;
