import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const InvoicePaymentMethodsLinkContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 50px;

  ${mediaQuery.sm} {
    margin-top: 0;
  }
`;
