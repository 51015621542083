import { useCallback, useContext, useEffect, useState } from 'react';
import { ThreadRelatedLead } from '../../../models/inbox/Threads';
import useAppUser from '../../../hooks/useAppUser';
import InboxContext from '../InboxContext';
import useAppQuery from '../../../hooks/useAppQuery';
import API from '../../../services/API';
import { GetInboxPropertiesResponse } from '../Inbox.types';

const useInboxLeadMissingProperties = () => {
  const { agencyUid, isEmployee } = useAppUser();
  const { propertiesMap, setPropertiesMap } = useContext(InboxContext);
  const [propertyUids, setPropertyUids] = useState<string[]>([]);

  const { data: fetchedProperties } = useAppQuery(
    ['missingLeadProperties', agencyUid, propertyUids],
    async () => {
      const uids = propertyUids.join(',');
      const response = await API.post<GetInboxPropertiesResponse>(
        '/v3/graphql',
        {
          query: `{properties (agencyUid:"${agencyUid}", propertyUids:"${uids}", includeDeleted:true, limit:"9999") {
              uid,
              name
            }}`,
          variables: {},
        },
      );

      return response.data.data.properties;
    },
    { enabled: isEmployee && !!propertyUids.length },
  );

  useEffect(() => {
    if (fetchedProperties) {
      setPropertiesMap((currentMap) => ({
        ...currentMap,
        ...fetchedProperties.reduce(
          (accumulator, property) => ({
            ...accumulator,
            [property.uid]: property,
          }),
          {},
        ),
      }));
    }
  }, [fetchedProperties]);

  return useCallback(
    (threadRelatedLeads: ThreadRelatedLead[]) => {
      const missingPropertyUids = threadRelatedLeads
        .map(({ propertyUid }) => propertyUid)
        .filter((propertyUid) => !propertiesMap[propertyUid]);

      if (missingPropertyUids.length) {
        setPropertyUids(missingPropertyUids);
      }
    },
    [propertiesMap],
  );
};

export default useInboxLeadMissingProperties;
