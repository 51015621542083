export const chartConstants = {
  LEGEND_HEIGHT: 36,
  BAR_WIDTH: 30,
  YAXIS_WITH: 100,
  LOADING_COLOR: '#f5f5f5',
  CHART_MARGIN: { top: 5, right: 20, bottom: 5, left: 0 },
};

export const pieChartsContants = {
  MAXIMUM_PIE_CHART_ITEMS: 5,
};
