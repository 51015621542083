import { BaseResponse } from './_base';

export enum ResolutionStatus {
  ALL = 'ALL',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export interface AirbnbResolution {
  id: string;
  leadUid: string;
  title: string;
  reason: string;
  created: string;
  status: ResolutionStatus;
  description: string;
}

export interface GetAllResolutionsResponseTO extends BaseResponse {
  resolutions: AirbnbResolution[];
}
