import { AssignmentSettingsWithEmployee } from 'components/domain/property/useFetchAssignmentSettingsWithEmployees';
import { UserEmployeeRole } from 'models/Users';
import { orderBy } from 'utils/arrayUtils';

const ROLES_SORTED_WEIGHTS = {
  [UserEmployeeRole.MANAGER]: 100,
  [UserEmployeeRole.ASSOCIATE]: 400,
  [UserEmployeeRole.BOOKING_AGENT]: 300,
  [UserEmployeeRole.PROPERTY_SALES_MANAGER]: 200,
};

export const getSortedAssignees = ({
  assignees,
  automaticAssignment,
}: AssignmentSettingsWithEmployee) => {
  return orderBy(assignees, [
    {
      field: (assignee) =>
        assignee.employeeUid === automaticAssignment.employeeUid,
      order: 'desc',
    },
    {
      field: (assignee) => ROLES_SORTED_WEIGHTS[assignee.employee.role],
      order: 'asc',
    },
    {
      field: (assignee) => assignee.employee.bestProfile,
      order: 'asc',
    },
  ]);
};
