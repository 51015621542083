import { useState } from 'react';
import API from 'services/API';
import useAppMutation from 'hooks/useAppMutation';
import { AxiosResponse } from 'axios';
import { BaseResponse } from 'models/_base';
import { useTranslation } from 'react-i18next';

interface SendInviteResponseTO extends BaseResponse {
  message: string;
  status: string;
}

interface SendInvitePayload {
  guestUid: string;
  onInviteSuccess: (status: string) => void;
}

const useInviteGuestData = () => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const { t } = useTranslation();

  const { mutate: sendInviteHandle, isLoading: isInviting } = useAppMutation(
    ['sendInviteHandle'],
    ({ guestUid }: SendInvitePayload) =>
      API.post(`api/internal/guests/${guestUid}/invite`),
    {
      onSuccess: (
        { data: { status } }: AxiosResponse<SendInviteResponseTO>,
        { onInviteSuccess }: SendInvitePayload,
      ) => {
        onInviteSuccess(status);
      },
      onError: () => {
        setErrorMessage(t('componentLead.modal.dataTab.canNotInviteGuest'));
      },
    },
  );

  const sendInvite = (guestUid: string, onInviteSuccess: () => void) => {
    sendInviteHandle({ guestUid, onInviteSuccess });
  };

  return { isInviting, errorMessage, sendInvite };
};

export default useInviteGuestData;
