import React from 'react';
import { ThreadParticipantBestProfile } from '../../../models/inbox/Threads';
import {
  ThreadAvatarContainer,
  ThreadAvatarImage,
  ThreadAvatarText,
} from './InboxThreads.styles';

interface Props {
  bestProfile: ThreadParticipantBestProfile;
}

const getThemeNumber = (firstLetter: string): number => {
  const position = firstLetter.toLowerCase().charCodeAt(0) - 96;

  if (position < 5) return 1;
  if (position < 10) return 2;
  if (position < 16) return 3;
  if (position < 22) return 4;
  return 0;
};

const ThreadAvatar: React.FC<Props> = ({ bestProfile: { name, photoUrl } }) => {
  if (photoUrl) {
    return (
      <ThreadAvatarContainer>
        <ThreadAvatarImage src={photoUrl} />
      </ThreadAvatarContainer>
    );
  }

  const firstLetter = name.substring(0, 1);

  return (
    <ThreadAvatarContainer>
      <ThreadAvatarText theme={getThemeNumber(firstLetter)}>
        {firstLetter}
      </ThreadAvatarText>
    </ThreadAvatarContainer>
  );
};

export default ThreadAvatar;
