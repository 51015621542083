import { useTranslation } from 'react-i18next';
import { useFetchPropertiesFilterOptions } from 'components/domain/property/useFetchPropertiesFilterOptions';
import SelectField from 'components/forms/selectField/SelectField';
import { SelectFieldProps } from 'components/forms/selectField/SelectField.types';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { PropertiesFilterOptions } from 'models/PropertiesFilter';

interface LocationFieldProps extends SelectFieldProps {
  filterOptions?: PropertiesFilterOptions;
}

const LocationSelectField = ({
  filterOptions: filterOptionsProp,
  ...rest
}: LocationFieldProps) => {
  const { t } = useTranslation();
  const { data, isLoading } = useFetchPropertiesFilterOptions({
    enabled: !filterOptionsProp,
  });
  const filterOptions = filterOptionsProp ?? data;
  const locations = filterOptions?.cities.map((location) => ({
    label: location,
    value: location,
  }));

  if (isLoading) return <LoadingPlaceholder />;

  return (
    <SelectField
      colSmInput={8}
      colSmLabel={4}
      label={t('pageCalendar.stacked.location')}
      name="location"
      options={[
        { label: t('pageCalendar.stacked.all'), value: '' },
        ...locations,
      ]}
      {...rest}
    />
  );
};

LocationSelectField.defaultProps = {
  filterOptions: undefined,
};

export default LocationSelectField;
