import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';
import { PropertyMainSettingsData } from './PropertySettingsMainTab.types';

const useFetchPropertyMainSettingsData = (propertyUid: string) => {
  const { data: mainSettingsData, isInitialLoading: isFetching } = useAppQuery(
    ['propertyMainSettingsData', propertyUid],
    async (): Promise<PropertyMainSettingsData> => {
      const response = await API.get<PropertyMainSettingsData>(
        `/api/internal/properties/${propertyUid}/main-settings`,
      );
      return response.data;
    },
    { enabled: !!propertyUid },
  );

  return { isFetching, mainSettingsData };
};

export default useFetchPropertyMainSettingsData;
