import { addDays, isSameDay, subDays } from 'date-fns';
import { DayCellData, StackedCalendarMode } from '../Calendar.types';
import { getDayCellDataKey } from '../StackedCalendar.utils';

export enum BREAKPOINT_TYPE {
  CHECKIN = 'CHECKIN',
  CHECKOUT = 'CHECKOUT',
}

function checkSelectionLimit(
  date,
  dayCellDataMap,
  shiftDay,
  breakpointType: BREAKPOINT_TYPE,
  mode: StackedCalendarMode,
) {
  let result = date;

  for (let currentDate = date; ; currentDate = shiftDay(currentDate, 1)) {
    const dayCellDataKey = getDayCellDataKey(currentDate);

    const dayCellData = dayCellDataMap[dayCellDataKey];

    if (!dayCellData || dayCellData.isLoading) {
      return result;
    }

    const { leads } = dayCellData;

    if (mode !== StackedCalendarMode.PRICING && leads?.length) {
      if (
        breakpointType === BREAKPOINT_TYPE.CHECKIN &&
        !dayCellData.entry?.availability?.availableForCheckIn
      ) {
        return currentDate;
      }
      if (
        breakpointType === BREAKPOINT_TYPE.CHECKOUT &&
        !dayCellData.entry?.availability?.availableForCheckOut
      ) {
        return currentDate;
      }
    }

    result = currentDate;
  }
}

export function getSelectionLimit(
  date: Date,
  dayCellDataMap: { [date: string]: DayCellData },
  mode: StackedCalendarMode,
): { start: Date; end: Date } | null {
  const start = checkSelectionLimit(
    date,
    dayCellDataMap,
    subDays,
    BREAKPOINT_TYPE.CHECKOUT,
    mode,
  );
  const end = checkSelectionLimit(
    date,
    dayCellDataMap,
    addDays,
    BREAKPOINT_TYPE.CHECKIN,
    mode,
  );

  if (isSameDay(start, end)) {
    return null;
  }

  return { start, end };
}
