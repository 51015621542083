import { UseMutationOptions } from '@tanstack/react-query';
import useAppMutation from 'hooks/useAppMutation';
import { UpdateAssignmentSettingsTO } from 'models/AssignmentSettings';
import API from 'services/API';

export interface PropertyBulkUpdateRequest {
  assignmentSettings?: UpdateAssignmentSettingsTO;
}

interface BulkUpdatePropertiesArgs {
  propertyBulkUpdateRequest: PropertyBulkUpdateRequest;
  propertiesUids?: string[];
  updateAllProperties: boolean;
}

export type BulkUpdateMutationOptions = Omit<
  UseMutationOptions<
    BulkUpdatePropertiesResponseTO,
    unknown,
    BulkUpdatePropertiesArgs
  >,
  'mutationFn'
>;

type BulkUpdatePropertiesResponseTO = {
  propertyBulkEdit: {
    batchOperationUid: string;
  };
};

const bulkUpdateProperties = async ({
  propertiesUids,
  updateAllProperties,
  propertyBulkUpdateRequest,
}: BulkUpdatePropertiesArgs): Promise<BulkUpdatePropertiesResponseTO> => {
  return API.post('/api/internal/properties/bulk-update', {
    propertyBulkUpdateRequest,
    ...(updateAllProperties
      ? { updateAllProperties }
      : { updateAllProperties: false, propertiesUids }),
  });
};

const useBulkUpdatePropertiesMutation = ({
  ...options
}: BulkUpdateMutationOptions = {}) => {
  return useAppMutation({
    mutationFn: bulkUpdateProperties,
    ...options,
  });
};

export default useBulkUpdatePropertiesMutation;
