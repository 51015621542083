import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { format, isFirstDayOfMonth, isToday } from 'date-fns';
import {
  NavigationDayCellDayName,
  NavigationDayCellDayNumber,
  NavigationDayCellStyled,
  NavigationDayMonthStyled,
} from './StackedCalendarNavigation.styles';

const NavigationDayCell = memo(
  ({ dayDate, width }: { dayDate: Date; width: number }) => {
    const { t } = useTranslation();
    const today = isToday(dayDate);
    const firstDayOfMonth = isFirstDayOfMonth(dayDate);

    return (
      <NavigationDayCellStyled $isToday={today} $width={width}>
        {firstDayOfMonth && (
          <NavigationDayMonthStyled>
            {t('common.monthFullYear', { date: dayDate })}
          </NavigationDayMonthStyled>
        )}
        <NavigationDayCellDayName $isToday={today}>
          {t('common.dayOfWeekShort', { date: dayDate })}
        </NavigationDayCellDayName>
        <NavigationDayCellDayNumber $isToday={today}>
          {format(dayDate, 'd')}
        </NavigationDayCellDayNumber>
      </NavigationDayCellStyled>
    );
  },
);

export default NavigationDayCell;
