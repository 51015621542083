// TODO: react-bootstrap migration, use Bootstrap 5 switch
import { forwardRef } from 'react';
import { Container } from './Switch.styles';

export interface SwitchProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  ({ className, ...props }, ref) => {
    return (
      <Container>
        <input
          className={`toggle-switch${className ? ` ${className}` : ''}`}
          type="checkbox"
          aria-label="Switch"
          ref={ref}
          {...props}
        />
      </Container>
    );
  },
);

export default Switch;
