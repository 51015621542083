import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const AdjustmentModalTitle = styled(Modal.Title)`
  text-align: center;
`;

export const AdjustmentModalBody = styled.div`
  padding: 15px;
  text-align: center;
  background-color: #fcf8e3;
`;

export const AdjustmentModalHighlight = styled.span`
  color: #ff6633;
`;
