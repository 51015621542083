import React from 'react';
import { AxiosError } from 'axios';
import TextField from 'components/forms/textField/TextField';
import { useTranslation } from 'react-i18next';
import { PasswordForm } from 'models/OwnerAccount';
import { Modal } from 'react-bootstrap';
import Button from 'components/button/Button';
import useAppMutation from 'hooks/useAppMutation';
import API from 'services/API';
import useAppUser from 'hooks/useAppUser';
import useAppModal from 'hooks/useAppModal';
import useNotify from 'hooks/useNotify';
import passwordSchema from './PasswordForm.schema';
import { FormContainer, WarningMessage } from './PasswordModal.styles';

interface PasswordModalProps {
  modalId: string;
}

const PasswordModal: React.FC<PasswordModalProps> = ({ modalId }) => {
  const { t } = useTranslation();
  const { userUid } = useAppUser();
  const { closeModal } = useAppModal();
  const { notifySuccess } = useNotify();

  const {
    mutate: updatePassword,
    error,
    isError,
  } = useAppMutation(
    (payload: PasswordForm) => {
      return API.post(`v3/users/${userUid}/password-change`, payload);
    },
    {
      onSuccess: () => {
        notifySuccess(t('componentPasswordModal.passwordChanged'));
        closeModal(modalId);
      },
    },
  );

  const handleSubmit = (values: PasswordForm) => {
    updatePassword(values);
  };

  const wrongPasswordError =
    (error as AxiosError<{ errorMessage: string }>)?.response?.data
      ?.errorMessage === 'Wrong password';

  return (
    <FormContainer
      schema={passwordSchema(t)}
      horizontal
      onSubmit={handleSubmit}
      id="change-password-form"
      data-testid="password-modal-test-id"
    >
      <Modal.Body>
        <TextField
          name="currentPassword"
          label={t('componentPasswordModal.currentPassword')}
          type="password"
        />

        <TextField
          name="newPassword"
          label={t('componentPasswordModal.newPassword')}
          type="password"
        />

        <TextField
          name="newPasswordRepeated"
          label={t('componentPasswordModal.confirmPassword')}
          type="password"
        />
      </Modal.Body>

      <Modal.Footer>
        {isError && wrongPasswordError && (
          <WarningMessage>
            {t('componentPasswordModal.wrongPasswordError')}
          </WarningMessage>
        )}

        {isError && !wrongPasswordError && (
          <WarningMessage>
            {t('componentPasswordModal.genericError')}
          </WarningMessage>
        )}

        <div className="password-modal-actions">
          <Button
            onClick={() => closeModal(modalId)}
            id="password-modal-close-button"
          >
            {t('componentPasswordModal.close')}
          </Button>
          <Button
            bsStyle="primary"
            type="submit"
            form="change-password-form"
            data-testid="password-modal-submit-button"
          >
            {t('componentPasswordModal.save')}
          </Button>
        </div>
      </Modal.Footer>
    </FormContainer>
  );
};

export default PasswordModal;
