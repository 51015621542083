import { useTranslation } from 'react-i18next';
import SelectField from 'components/forms/selectField/SelectField';
import { SelectFieldProps } from 'components/forms/selectField/SelectField.types';
import { PROPERTIES_SORTING_STRATEGY } from 'models/Properties';

interface SortBySelectFieldProps extends SelectFieldProps {}

const SortBySelectField = (props: SortBySelectFieldProps) => {
  const { t } = useTranslation();

  return (
    <SelectField
      colSmInput={8}
      colSmLabel={4}
      label={t('form.sortBy')}
      options={[
        {
          label: t('form.sortBy_ALPHABETICALLY'),
          value: PROPERTIES_SORTING_STRATEGY.SORT_BY_NAME,
        },
        {
          label: t('form.sortBy_CREATION_DATE_DESC'),
          value: PROPERTIES_SORTING_STRATEGY.SORT_BY_CREATION_DATE_DESC,
        },
      ]}
      {...props}
    />
  );
};

export default SortBySelectField;
