import styled from 'styled-components';

export const PinItemsContainer = styled.div<{ $length: number }>`
  margin: 0 auto;
  width: 80%;
  display: grid;
  grid-template-columns: repeat(${({ $length }) => $length}, 1fr);
  gap: 8px;

  & input {
    display: inline !important;
    padding: 6px !important;
    text-align: center !important;
  }
`;

export const ContainerError = styled.div`
  width: 80%;
  margin: 0 auto;
`;
