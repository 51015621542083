import { useFormContext, useWatch } from 'react-hook-form';
import { OwnerAdjustmentFileResponse } from 'models/ownerAdjustments/OwnerAdjustmentFile';

type SelectedFile = OwnerAdjustmentFileResponse | { url: null; fileName: null };

const useCurrentSelectedFile = () => {
  const { setValue } = useFormContext();
  const fileUrl: string | null = useWatch({ name: 'attachedImageURL' });
  const fileName: string | null = useWatch({ name: 'attachedImageName' });
  const currentFile = fileName ? { url: fileUrl, fileName } : null;

  const setCurrentFile = (file: SelectedFile) => {
    setValue('attachedImageURL', file.url);
    setValue('attachedImageName', file.fileName);
  };

  return { currentFile, setCurrentFile };
};

export default useCurrentSelectedFile;
