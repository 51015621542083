import useScreenBreakpoints from 'hooks/useScreenBreakpoints';
import {
  calendarMobileStyleConstants,
  calendarStyleConstants,
} from './Calendar.constant';

const useResponsiveStyleCalendar = () => {
  const { isXS, isSM } = useScreenBreakpoints();

  if (isXS || isSM) {
    return calendarMobileStyleConstants;
  }

  return calendarStyleConstants;
};

export default useResponsiveStyleCalendar;
