import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalBody, ModalHeader } from 'react-bootstrap';
import useAppModal from 'hooks/useAppModal';
import MFACodeForm from 'components/domain/user/mfaCodeForm/MFACodeForm';
import { MFAType } from 'components/domain/user/mfaCodeForm/MFA.types';
import FormWithProvider from 'components/forms/form/Form';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import useAppUser from 'hooks/useAppUser';
import API from 'services/API';
import { mfaSchema } from './Account.schema';
import { ErrorMessage, PhoneAuthModalContainer } from './Account.styles';

export const modalId = 'phone-auth-modal';

const PhoneAuthModal = ({
  phoneNumber,
  originalMfaCode,
  onSuccessfulVerification,
}) => {
  const { notifySuccess, notifyError } = useNotify();
  const { closeModal } = useAppModal();
  const { t } = useTranslation();
  const [mfaCode, setMfaCode] = useState(originalMfaCode);
  const { userUid } = useAppUser();

  const { mutate: verifyPhoneNumber, error } = useAppMutation(
    (payload: any) => {
      return API.post(
        `/api/internal/employees/${userUid}/update-phone-number?verificationStep=true`,
        payload,
      );
    },
    {
      onSuccess: () => {
        onSuccessfulVerification();
        closeModal(modalId);
      },
    },
  );

  const { mutate: resendCode } = useAppMutation(
    () => {
      return API.post('/api/internal/employees/resend-code', {
        code: mfaCode,
      });
    },
    {
      onSuccess: (response) => {
        if (response.data?.result === 'RESEND_CODE' && response.data?.mfaCode) {
          notifySuccess(t('pageLogin.codeResentSuccess'));
          setMfaCode(response.data.mfaCode);
        }
      },
      onError: () => {
        notifyError(t('pageLogin.codeResentError'));
      },
    },
  );

  const handleSubmit = (values: { pinCode: string }) => {
    verifyPhoneNumber({
      code: mfaCode,
      challenge: values.pinCode,
    });
  };

  const errorMfaInvalid =
    (error as any)?.response?.data?.apiErrorCode?.startsWith(
      'Invalid MFA challenge',
    ) || (error as any)?.response?.status === 403;

  return (
    <PhoneAuthModalContainer>
      <FormWithProvider onSubmit={handleSubmit} schema={mfaSchema()}>
        <ModalHeader closeButton />
        <ModalBody>
          <h2>{t('pageLogin.titleMFAPhone')}</h2>
          <MFACodeForm
            type={MFAType.SMS}
            resendCode={resendCode}
            codeSentTo={phoneNumber.slice(-4)}
          />
          {error && (
            <ErrorMessage>
              {errorMfaInvalid
                ? t('pageAccount.invalidMfaCode')
                : t('pageAccount.somethingWentWrong')}
            </ErrorMessage>
          )}
        </ModalBody>
      </FormWithProvider>
    </PhoneAuthModalContainer>
  );
};

export default PhoneAuthModal;
