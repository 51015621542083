import { TableProps } from './Table.types';
import TableContextProvider from './TableContextProvider';
import TableCaption from './header/TableCaption';
import TableHeader from './header/TableHeader';
import TableBody from './body/TableBody';
import TablePagination from './TablePagination';
import { BSTableStyled } from './Table.styles';
import SingleCellFooter from './footer/SingleCellFooter';
import TableColGroup from './header/TableColGroup';

const Table = <T,>({
  tableInstance,
  children,
  emptyState,
  isLoading,
  ...tableProps
}: TableProps<T>) => {
  return (
    <TableContextProvider
      tableInstance={tableInstance}
      emptyState={emptyState}
      isLoading={isLoading}
    >
      <BSTableStyled {...tableProps}>{children}</BSTableStyled>
    </TableContextProvider>
  );
};

Table.Caption = TableCaption;
Table.ColGroup = TableColGroup;
Table.Header = TableHeader;
Table.Body = TableBody;
Table.Pagination = TablePagination;
Table.SingleCellFooter = SingleCellFooter;

Table.defaultProps = {
  className: '',
  children: null,
  emptyState: null,
  isLoading: false,
};

export default Table;
