import useAppDispatch from 'hooks/useAppDispatch';
import useAppMutation from 'hooks/useAppMutation';
import useAppUser from 'hooks/useAppUser';
import API from 'services/API';
import { runSyncCheckAction } from 'store/slices/syncCheck/sagas/syncCheckActions';

const useUpdateUserActivity = () => {
  const { userUid } = useAppUser();
  const dispatch = useAppDispatch();

  const { mutate: updateUserActivity } = useAppMutation(
    () => {
      return API.put<any>(
        `api/internal/users/activity?userUid=${userUid}&isDashboardAccess=true`,
      );
    },
    {
      onSuccess: () => {
        dispatch(runSyncCheckAction({}));
      },
    },
  );

  return {
    updateUserActivity,
  };
};

export default useUpdateUserActivity;
