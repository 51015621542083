import { Glyphicon } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import { DeleteRoomButtonContainer } from './RoomsAndBedTypesFormElements.styles';

const RoomsAndBedTypesFormDeleteRoomButton = ({
  deleteRoomHandler,
}: {
  deleteRoomHandler: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <DeleteRoomButtonContainer smOffset={5}>
      <Button
        data-testid="delete-room-button"
        bsStyle="link"
        onClick={deleteRoomHandler}
      >
        <Glyphicon glyph="trash" className="small" />
        {t(
          'pageProperty.mainSettings.capacityDetails.setRoomsAndBedTypesModal.deleteRoom',
        )}
      </Button>
    </DeleteRoomButtonContainer>
  );
};

export default RoomsAndBedTypesFormDeleteRoomButton;
