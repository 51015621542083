import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  input[type='file'] {
    display: none;
  }

  button {
    svg {
      margin-bottom: -2px;
      margin-right: 10px;
    }
  }
`;

export const ImagePreviewStyled = styled.img`
  border-radius: 2px;
  height: 125px;
  /* max-height: min(125px, 100%); */
  max-width: min(150px, calc(100% - 34px));
  object-fit: cover;
`;
