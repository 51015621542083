import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.section`
  position: relative;
  z-index: 100;
  width: 45%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  svg {
    margin-left: 75px;
  }

  ${mediaQuery.custom(1920)} {
    svg {
      margin-left: 200px;
    }
  }
  ${mediaQuery.custom(1440)} {
    svg {
      margin-left: 0;
    }
  }

  ${mediaQuery.custom(1024)} {
    height: 60vh;
    width: 100%;
    margin-top: 40px;
    align-items: flex-start;

    svg {
      margin-left: 0;
    }
  }
`;
