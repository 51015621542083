import useResponsiveStyleCalendar from '../useResponsiveStyleCalendar';
import { DayStringCellS } from './DayCell.style';

interface Props {
  hasBorder: boolean;
  str: string;
}

const DayStringCell = ({ hasBorder, str }: Props) => {
  const calendarStyleConstants = useResponsiveStyleCalendar();

  return (
    <DayStringCellS
      $calendarStyleConstants={calendarStyleConstants}
      $hasBorder={hasBorder}
    >
      {str}
    </DayStringCellS>
  );
};

export default DayStringCell;
