import styled, { createGlobalStyle } from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div.attrs({
  className: 'container',
})`
  .help-block {
    display: flex;
    align-items: center;

    > *:not(:last-child) {
      margin-right: 25px;
    }
  }

  ${mediaQuery.xs} {
    .help-block {
      align-items: center;

      > *:not(:last-child) {
        margin-right: 0px;
      }
    }
  }
`;

export const GlobalPipelineStyles = createGlobalStyle`
  .lead-actions-popover .popover-content {
    padding: 0;
  }
`;
