import { useEffect, useRef, useState } from 'react';
import useFormatCurrency from 'hooks/useFormatCurrency';
import { AmountFieldContainer, ResetButton } from '../Fields.styles';
import DisplayableValue from './DisplayableValue';

interface AmountFieldProps {
  isEditable: boolean;
  value: number;
  onItemUpdated: Function;
  currency: string;
}

const AmountField = ({
  isEditable,
  value,
  onItemUpdated,
  currency,
}: AmountFieldProps) => {
  const inputRef = useRef(null);
  const [showResetButton, setShowResetButton] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const formatCurrency = useFormatCurrency();

  useEffect(() => {
    if (editMode) {
      inputRef.current.focus();
    }
  }, [editMode]);

  const onSave = (val) => {
    setEditMode(false);
    setShowResetButton(false);
    onItemUpdated(parseFloat(val));
  };

  const handleDisplayClick = () => {
    if (isEditable) {
      setEditMode(true);
    }
  };

  if (!isEditable) {
    return <span>{formatCurrency({ value, currency })}</span>;
  }

  return (
    <AmountFieldContainer>
      {editMode ? (
        <input
          ref={inputRef}
          type="number"
          defaultValue={value}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              onSave((e.target as HTMLInputElement).value);
            }
          }}
          onFocus={() => {
            setShowResetButton(true);
          }}
          onBlur={(e) => {
            onSave(e.target.value);
            setEditMode(false);
            setShowResetButton(false);
          }}
        />
      ) : (
        <DisplayableValue
          currency={currency}
          value={value}
          type="currency"
          onClick={handleDisplayClick}
        />
      )}
      {showResetButton && (
        <ResetButton
          onMouseDown={(e) => {
            e.preventDefault();
            onSave(0);
          }}
        >
          <span aria-hidden="true">×</span>
        </ResetButton>
      )}
    </AmountFieldContainer>
  );
};

export default AmountField;
