import React from 'react';
import { useTranslation } from 'react-i18next';
import FormWithProvider from 'components/forms/form/Form';
import useAppUser from 'hooks/useAppUser';
import API from 'services/API';
import useAppQuery from 'hooks/useAppQuery';
import {
  OwnerAccount as OwnerAccountType,
  OwnerAccountForm,
  OwnerAccountSettingsUpdateResponseTO,
  OwnerAccountUpdate,
} from 'models/OwnerAccount';
import useAppMutation from 'hooks/useAppMutation';
import useAppModal from 'hooks/useAppModal';
import PasswordModal from 'components/domain/user/passwordModal/PasswordModal';
import PageHeader from 'components/pageHeader/PageHeader';
import useNotify from 'hooks/useNotify';
import { getLanguageCode } from 'i18n/i18n.utils';
import AccountForm from './accountForm/AccountForm';
import { Container } from './OwnerAccount.styles';
import ownerAccountSchema from './accountForm/AccountForm.schema';

const OwnerAccount: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { userUid } = useAppUser();
  const { openModal } = useAppModal();
  const { notifySuccess, notifyError } = useNotify();

  const { data: ownerAccount, refetch: refetchOwnerAccount } = useAppQuery(
    ['getOwnerAccount'],
    async () => {
      const response = await API.get<{ owner: OwnerAccountType }>(
        `v3/owners/${userUid}`,
      );
      return response.data.owner;
    },
    {
      enabled: true,
      onError: () => notifyError(t('pageOwnerAccount.somethingWentWrong')),
    },
  );

  const { mutate: updateAccount, isLoading: isUpdateAccountLoading } =
    useAppMutation(
      (payload: OwnerAccountUpdate) => {
        return API.patch(`v3/owners/${userUid}`, payload);
      },
      {
        onSuccess: ({
          data: { owner },
        }: OwnerAccountSettingsUpdateResponseTO) => {
          const { preferredLocale } = owner;
          i18n.changeLanguage(getLanguageCode(preferredLocale)).then(() => {
            notifySuccess(
              t('pageOwnerAccount.yourAccountSettingsWereSuccessfullyUpdated'),
            );
          });
          refetchOwnerAccount();
        },
      },
    );

  const { mutateAsync: uploadFileAsync, isLoading: isUploadFileLoading } =
    useAppMutation(async (payload: any) => {
      return API.post('/v3/files/uploadFile', payload).then(
        (response) => response.data.files[0].uri,
      );
    });

  const { data: airbnbConnect } = useAppQuery(
    ['getAirbnbDetails'],
    async () => {
      const response = await API.get<{ clientId: string; redirectUri: string }>(
        'airbnb-oauth-details',
      );
      return response.data;
    },
    { enabled: true },
  );

  const onSubmit = async (formValues: OwnerAccountForm) => {
    const { picture, phoneNumberAreaCode, ...rest } = formValues;

    const phoneNumberAreaCodeFormated = Number(
      phoneNumberAreaCode.replace('+', ''),
    );

    if (picture && picture?.[0]) {
      const formData = new FormData();
      formData.append('profilePicture', picture[0], picture[0].name);

      const pictureUrl = await uploadFileAsync(formData);

      return updateAccount({
        ...rest,
        phoneNumberAreaCode: phoneNumberAreaCodeFormated,
        pictureUrl: (pictureUrl as string) || ownerAccount?.pictureUrl,
      });
    }

    return updateAccount({
      ...rest,
      phoneNumberAreaCode: phoneNumberAreaCodeFormated,
      pictureUrl: ownerAccount?.pictureUrl,
    });
  };

  const handleModal = () => {
    const modalId = 'change-password-modal';
    openModal({
      title: t('pageOwnerAccount.changePassword'),
      customContent: <PasswordModal modalId={modalId} />,
      id: modalId,
    });
  };

  return (
    <Container data-testid="owner-account-test-id">
      <PageHeader title={t('pageOwnerAccount.accountSettings')} />

      <FormWithProvider
        key={JSON.stringify(ownerAccount)}
        schema={ownerAccountSchema()}
        defaultValues={{
          firstName: ownerAccount?.firstName,
          lastName: ownerAccount?.lastName,
          email: ownerAccount?.email,
          businessName: ownerAccount?.businessName || '',
          website: ownerAccount?.website?.replace(/(https?:\/\/)/g, '') || '',
          country: ownerAccount?.country,
          preferredLocale: ownerAccount?.preferredLocale,
          phoneNumberAreaCode: `+${ownerAccount?.phoneNumberAreaCode}`,
          phoneNumber: ownerAccount?.phoneNumber,
        }}
        horizontal
        onSubmit={onSubmit}
      >
        <AccountForm
          onChangePassword={handleModal}
          isUpdateButtonDisabled={isUploadFileLoading || isUpdateAccountLoading}
          airbnbDetails={{
            ...airbnbConnect,
            isAirbnbConnected: !!ownerAccount?.airbnbUserId,
          }}
        />
      </FormWithProvider>
    </Container>
  );
};

export default OwnerAccount;
