import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import SelectField from 'components/forms/selectField/SelectField';
import { prepareLeadChannelGroups } from 'pages/inbox/filter/ThreadFilterForm';
import RangePickerField from 'components/forms/rangePickerField/RangePickerField';
import RangeDropdownField from 'components/forms/rangeDropdownField/RangeDropdownField';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  getRangeFromPresetDateRange,
  getTimeSpanFromPresetDateRange,
} from 'utils/dateTimeUtils';
import { getCurrencyOptions } from 'utils/analytics/analyticsUtils';
import PropertySelectField from 'components/forms/domain/property/PropertySelectField';
import { Property } from 'models/Properties';
import { getPropertyCurrency } from 'utils/property/propertyUtils';
import AnalyticsContext from '../AnalyticsContext';
import { DateType } from '../types/Analytics.types';

const AnalyticsFilters = ({
  showOnlyCurrencyFilter,
}: {
  showOnlyCurrencyFilter: boolean;
}) => {
  const { t } = useTranslation();
  const { allProperties, leadChannels } = useContext(AnalyticsContext);
  const { presetDateRange, propertyUid } = useWatch();
  const { setValue } = useFormContext();

  useEffect(() => {
    const range = getRangeFromPresetDateRange(presetDateRange);

    if (range) {
      setValue('range', {
        startDate: range[0],
        endDate: range[1],
      });

      setValue('timeSpan', getTimeSpanFromPresetDateRange(presetDateRange));
    }
  }, [presetDateRange]);

  const currencyOptions = getCurrencyOptions(allProperties);
  const dateTypeOptions = Object.values(DateType).map((dateType: DateType) => ({
    label: t(`form.dateType.${dateType}`),
    value: dateType,
  }));

  useEffect(() => {
    if (propertyUid) {
      setValue('currency', getPropertyCurrency(allProperties, propertyUid));
    }
  }, [propertyUid]);

  if (showOnlyCurrencyFilter) {
    return (
      currencyOptions.length > 1 && (
        <SelectField
          disabled={!!propertyUid}
          colSmInput={12}
          name="currency"
          options={currencyOptions}
        />
      )
    );
  }

  return (
    <Row>
      <Col sm={6} md={3} lg={2}>
        <RangeDropdownField name="presetDateRange" />
      </Col>
      <Col sm={6} md={3} lg={3}>
        <RangePickerField
          name="rangePicker"
          disabled
          startDateFieldName="range.startDate"
          endDateFieldName="range.endDate"
        />
      </Col>
      <Col sm={6} md={3} lg={2}>
        <SelectField
          colSmInput={12}
          name="dateType"
          options={dateTypeOptions}
        />
      </Col>
      <Col sm={6} md={3} lg={2}>
        <PropertySelectField
          colSmInput={12}
          containerClassName="property-container"
          name="propertyUid"
          emptyValueLabel={t('common.all')}
          pictureAsLabel={false}
          properties={allProperties as unknown as Property[]}
          showUnits={false}
        />
      </Col>
      <Col sm={6} md={2} lg={2}>
        <SelectField
          colSmInput={12}
          name="channel"
          options={[
            { label: t('pageAnalytics.filters.allChannels'), value: '' },
          ]}
          optionGroups={prepareLeadChannelGroups(leadChannels, t)}
        />
      </Col>
      {currencyOptions.length > 1 ? (
        <Col sm={6} md={1} lg={1}>
          <SelectField
            disabled={!!propertyUid}
            colSmInput={12}
            name="currency"
            options={getCurrencyOptions(allProperties)}
          />
        </Col>
      ) : null}
    </Row>
  );
};

export default AnalyticsFilters;
