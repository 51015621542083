import { queryClient } from 'App';
import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import { CancellationPolicy } from 'models/CancellationPolicy';
import { PropertyInternalFee } from 'models/PropertyFees';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import API from 'services/API';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import { CANCELLATION_POLICY_BASE_QUERY_KEY } from '../useCancellationPolicyData';

const useDeleteModal = ({
  cancellationPolicyUid,
}: {
  cancellationPolicyUid: CancellationPolicy['uid'];
}) => {
  const { t } = useTranslation();
  const { uid: propertyUid } = useParams();
  const { openConfirmModal, setErrorConfirmModal } = useAppModal();

  const { mutateAsync: deleteCancellationPolicy } = useAppMutation(
    ['cancellationPolicyDelete', cancellationPolicyUid],
    async ({ uid }: { uid: PropertyInternalFee['uid'] }) => {
      await API.delete(`/api/internal/cancellation-policies/${uid}`);
    },
    {
      onSuccess: () => {
        queryClient.resetQueries([
          CANCELLATION_POLICY_BASE_QUERY_KEY,
          propertyUid,
        ]);
      },
      onError: (error: any) => {
        setErrorConfirmModal(
          getErrorMessage({
            apiErrorCode: error.response.data?.apiErrorCode,
            apiErrorMessage: error.response.data?.apiErrorMessage,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      },
    },
  );

  const openConfirmDeleteModal = () => {
    openConfirmModal({
      body: t(
        'pageProperty.feesTaxesAndPolicies.cancelationPolicy.deleteModal.description',
      ),
      onConfirmAsync: () =>
        deleteCancellationPolicy({
          uid: cancellationPolicyUid,
        }),
      title: t(
        'pageProperty.feesTaxesAndPolicies.cancelationPolicy.deleteModal.title',
      ),
      confirmLabel: t('common.delete'),
      confirmColor: 'danger',
    });
  };

  return {
    openConfirmDeleteModal,
  };
};

export default useDeleteModal;
