import { Col } from 'react-bootstrap';
import useInboxDisplayLead from '../messages/useInboxDisplayLead';
import InboxLead from './InboxLead';
import { InboxLeadDisplayMode } from './InboxLead.types';

const InboxThreadRightPanel = () => {
  const displayLead = useInboxDisplayLead(InboxLeadDisplayMode.RIGHT_PANEL);
  if (displayLead) {
    return (
      <Col md={3}>
        <InboxLead />
      </Col>
    );
  }

  return null;
};

export default InboxThreadRightPanel;
