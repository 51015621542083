import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RadioGroupField from 'components/forms/radioGroupField/RadioGroupField';
import TextField from 'components/forms/textField/TextField';
import { Choice, REQUIRE_OPEN_TEXT_FIELD_CHOICES } from 'models/AirbnbFlows';
import { AirbnbDeactivationFlowStyled } from './AirbnbDeactivationFlow.styles';

interface AirbnbDeactivationFlowProps {
  flowChoices: Choice[];
}

const AirbnbDeactivationFlow = ({
  flowChoices,
}: AirbnbDeactivationFlowProps) => {
  const { t } = useTranslation();
  const { choice: selectedChoice } = useWatch();

  const choices = flowChoices.map((choice) => ({
    label: choice.label,
    value: choice.value,
  }));

  const isTextFieldRequiredForOption =
    REQUIRE_OPEN_TEXT_FIELD_CHOICES.includes(selectedChoice);

  return (
    <AirbnbDeactivationFlowStyled data-testid="airbnb-deactivation-flow">
      <p>
        {t('pageProperties.actions.deactivatePropertyForAirbnbShareTheReason')}
      </p>
      <RadioGroupField name="choice" options={choices} />
      {isTextFieldRequiredForOption && (
        <TextField
          name="deactivationReason"
          componentClass="textarea"
          placeholder={t(
            'pageProperties.actions.deactivatePropertyOtherReasonsPlaceholder',
          )}
          required
        />
      )}
    </AirbnbDeactivationFlowStyled>
  );
};

export default AirbnbDeactivationFlow;
