import styled from 'styled-components';
import { ModalFooter } from 'react-bootstrap';
import FormWithProvider from '../../../forms/form/Form';

export const TemplateModalForm = styled(FormWithProvider)``;

export const TemplateModalBody = styled.div`
  padding: 15px;
`;

export const TemplateModalFooter = styled(ModalFooter)`
  padding: 15px 0;
  text-align: right;
  border-top: 0;

  .error-message {
    color: #f44336;
  }

  .btn,
  .btn-group {
    margin-left: 5px;
  }
`;

export const TemplateModalLead = styled.div`
  padding: 5px 0;
  border-bottom: 1px solid rgb(224, 224, 224);
  margin-bottom: 30px;
`;
