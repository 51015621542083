import styled from 'styled-components';
import FilterContainer from 'components/filters/FilterContainer';
import { FilterIconContainer } from 'components/filters/FilterIcon.styles';

export const FilterContainerStyled = styled(FilterContainer)<{
  isCollapsed: boolean;
}>`
  display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'flex')};
  flex-direction: column;

  .form-field-container {
    display: flex;
  }
`;

export const PropertyMultiSelectFilterFieldStyled = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${FilterIconContainer} {
    align-self: flex-end;
    margin-top: -50px;
  }

  ${FilterContainerStyled},
  > .form-field-container {
    width: calc(100% + 30px);
    margin: 0 -30px 0 -15px;
  }
`;
