import { Modal } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { LeadStatus } from '../../../../models/Leads';
import LeadModalFormBodyBlock from './LeadModalFormBodyBlock';
import LeadModalFormBodyTabs from './LeadModalFormBodyTabs';

const LeadModalBody = () => {
  const { watch } = useFormContext();
  const status = watch('status');

  return (
    <Modal.Body data-testid="lead-modal-body">
      {status === LeadStatus.BLOCKED ? (
        <LeadModalFormBodyBlock />
      ) : (
        <LeadModalFormBodyTabs />
      )}
    </Modal.Body>
  );
};

export default LeadModalBody;
