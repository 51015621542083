export enum ImportType {
  ALL_PROPERTIES = 'ALL_PROPERTIES',
  SELECTED_PROPERTY = 'SELECTED_PROPERTY',
}

export enum CONNECTION_STEP {
  CONNECT_TO_PROVIDER = 'CONNECT_TO_PROVIDER',
  REFRESH = 'REFRESH',
  PROPERTY_SELECTION = 'PROPERTY_SELECTION',
}
