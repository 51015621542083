import styled from 'styled-components';
import Button from 'components/button/Button';

export const SortingButton = styled(Button)`
  border: none;
  display: flex;
  flex-direction: column;
  height: 20px;
  justify-content: center;
  padding: 5px 8px;

  .glyphicon {
    font-size: 0.375rem;
  }
`;

export const TableHeaderCellWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const TableHeaderCellContent = styled.div`
  flex: 1;
`;

export const TableHeaderCellStyled = styled.th`
  &[aria-sort='ascending'] {
    .glyphicon-triangle-bottom {
      display: none;
    }
  }

  &[aria-sort='descending'] {
    .glyphicon-triangle-top {
      display: none;
    }
  }

  &[aria-sort='none'] ${SortingButton} {
    color: #aeb5bc;
  }
`;
