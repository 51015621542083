import { useLayoutEffect, useState } from 'react';
import { debounce } from 'utils/funcs';

const mobileWidth = 576;

const useIsMobileScreen = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(
    window.innerWidth < mobileWidth,
  );

  useLayoutEffect(() => {
    const updateSize = () => {
      setIsMobileScreen(window.innerWidth < mobileWidth);
    };
    window.addEventListener('resize', debounce(updateSize));
  }, []);

  return isMobileScreen;
};

export default useIsMobileScreen;
