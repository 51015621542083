import * as Yup from 'yup';

/**
 * Treat -0 as a negative number
 */
Yup.addMethod(
  Yup.number,
  'useNegativeZeroAsNegative',
  function notNegativeZero() {
    return this.transform((value) => {
      return Object.is(value, -0) ? -Number.MAX_VALUE : value;
    });
  },
);
/**
 * Treat NaN as a null
 */
Yup.addMethod(Yup.number, 'useNaNAsNull', function useNaNAsNull() {
  return this.transform((value) => {
    return Number.isNaN(value) ? null : value;
  });
});
