export const REQUIRE_OPEN_TEXT_FIELD_CHOICES = [
  'OtherReason',
  'OtherReasons',
] as const;

export interface Choice {
  value: string;
  label: string;
  choices?: null | Choice[];
}

export interface AirbnbFlow {
  choices: Choice[];
}

export interface GetAirbnbListingDeactivationFlowTO {
  flow: AirbnbFlow;
}
