import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  max-width: 508px;

  input,
  .input-group,
  select {
    width: 318px;

    & > input {
      margin-left: 0;
    }
  }

  ${mediaQuery.xs} {
    input,
    .input-group,
    select {
      width: 318px;
      width: 218px;
      margin-left: 21px;
    }
  }
`;

export const PasswordWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 318px;
  margin-bottom: 15px;

  p {
    font-weight: bold;
    display: flex;
    align-items: center;
    margin: 0px;
  }

  & > button {
    width: fit-content;
    height: 38px;
  }

  ${mediaQuery.xs} {
    grid-template-columns: auto 218px;
  }
`;

export const PhoneNumberWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 318px;
  margin-bottom: 15px;
  height: 38px;

  p {
    font-weight: bold;
    display: flex;
    align-items: center;
    margin: 0px;
  }

  select {
    width: 97px;
    margin: 0;
  }

  input {
    width: calc(318px - 97px);
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: -12px;

    input {
      margin-left: -10px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  ${mediaQuery.xs} {
    grid-template-columns: auto 218px;

    input {
      width: calc(218px - 97px);
      margin: 0;
    }

    > div {
      margin-left: 0;

      input {
        margin-left: 0;
      }
    }
  }
`;

export const UploadImageWrapper = styled(PasswordWrapper)`
  input {
    margin-left: 0;
  }
`;

export const AirbnbWrapper = styled.div`
  margin-bottom: 35px;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    font-weight: bold;
    display: flex;
    align-items: center;
    margin: 0px;
  }

  .airbnb-warning {
    color: #adb5bd;
    text-align: start;
    font-weight: normal;
  }

  .airbnb-connect-button {
    width: 320px;
    height: 38px;
    background: #ff5a5f;
    color: white;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 14px;
      height: 14px;
      margin-right: 14px;
      fill: white;
    }
  }

  ${mediaQuery.xs} {
    .airbnb-connect-button {
      width: 218px;
    }

    .airbnb-warning {
      justify-content: flex-end;
    }
  }
`;

export const FormButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button + button {
    margin-left: 20px;
  }
`;
