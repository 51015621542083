import { useTranslation } from 'react-i18next';
import { Lead } from 'models/Leads';
import { Transaction, TransactionStatus } from 'models/Transactions';
import ExclamationSign from 'components/icons/ExclamationSign.svg?react';
import { getTransactionAutoRefundDate } from 'utils/tests/transaction/transactionUtils';
import useOrderViewFetchedData from '../../useOrderViewFetchedData';
import CancelScheduledRefundButton from './CancelScheduledRefundButton';
import DeleteManualTransactionButton from './DeleteManualTransactionButton';
import IssueRefundButton from './IssueRefundButton';
import { TransactionFailureInfoContainer } from './OrderViewTransactionsListItemActions.styles';

function shouldRenderCancelScheduledRefundButton({
  lead: { autoRefundOk, autoRefundFail, wasSecurityDepositReturned },
  transaction,
}: {
  lead: Lead;
  transaction: Transaction;
}) {
  return (
    !!getTransactionAutoRefundDate(transaction) &&
    !autoRefundOk &&
    !autoRefundFail &&
    !wasSecurityDepositReturned
  );
}

const OrderViewTransactionsListItemActions = ({
  transaction,
}: {
  transaction: Transaction;
}) => {
  const { lead } = useOrderViewFetchedData();
  const { t } = useTranslation();
  const { manual, refundable, status } = transaction;

  if (status === TransactionStatus.FAILURE) {
    return (
      <TransactionFailureInfoContainer data-testid="failure-info">
        {/* TODO replace with FA/react-icon one migrated to BS5  */}
        <ExclamationSign />
        {t('pageOrder.transactions.failed')}
      </TransactionFailureInfoContainer>
    );
  }

  if (shouldRenderCancelScheduledRefundButton({ lead, transaction })) {
    return <CancelScheduledRefundButton transaction={transaction} />;
  }

  if (manual) {
    return <DeleteManualTransactionButton transaction={transaction} />;
  }

  if (refundable) {
    return <IssueRefundButton transaction={transaction} />;
  }

  return null;
};

export default OrderViewTransactionsListItemActions;
