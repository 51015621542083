import { isToday } from 'date-fns';
import { css } from 'styled-components';
import { isBeforeToday } from 'utils/dateTimeUtils';
import { dayCellCommonConstants } from './DayCellCommon.constants';

export const getDayCellCommonClasses = (day: Date) => {
  const dayCellClasses = [
    isToday(day) && 'date-today',
    isBeforeToday(day) && 'date-before-today',
  ];
  return dayCellClasses.filter((_) => _).join(' ');
};

export const DayCellCommonStyles = () => css`
  .date-today {
    border-left: ${dayCellCommonConstants.borderLeftToday};
  }

  .date-before-today {
    background-color: ${dayCellCommonConstants.disabledBackgroundColor};
  }
`;
