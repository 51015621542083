import { useCallback } from 'react';
import useAppUserPermissions from '../hooks/useAppUserPermissions';
import { restrictedRoutes } from './router.constants';

const useHasRouteAccess = () => {
  const permissions = useAppUserPermissions();
  return useCallback(
    (route: string) => {
      if (route.startsWith('#')) {
        route = route.substring(1);
      }

      const restrictedRoute = restrictedRoutes[route];
      if (!restrictedRoute) {
        return true;
      }

      const hasPermission = (permission) => permissions.includes(permission);
      return restrictedRoute.permissions.every(hasPermission);
    },
    [permissions],
  );
};

export default useHasRouteAccess;
