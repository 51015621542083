import styled, { css } from 'styled-components';

const toastBorderModifier = {
  error: () => css`
    color: red;
    border: 2px solid red;
  `,
  success: () => css`
    color: #23476a;
    border: 1px solid #ff9a03;
  `,
  warning: () => css`
    color: #23476a;
    border: 1px solid #ff9a03;
  `,
};

export const Toast = styled.div<{
  toastType?: 'error' | 'success' | 'warning';
}>`
  position: relative;
  width: fit-content;
  min-width: 50%;
  height: 58px;
  padding: 15px 45px 15px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 6px;
  font-size: 18px;
  box-shadow: 0px 6px 10px 3px #888888;
  ${({ toastType }) => toastBorderModifier[toastType || 'warning']}

  .glyphicon-remove {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #333;
    opacity: 0.5;
  }
`;
