import { Lead, LeadStatus } from 'models/Leads';
import { getLeadCheckOutDate } from 'utils/lead/leadUtils';
import { isSameWeek, isSunday } from 'date-fns';
import { getTotalDaysOnRow } from './Calendar.utils';
import useResponsiveStyleCalendar from './useResponsiveStyleCalendar';

export const hideFavicon = (date: Date, lead: Lead): boolean => {
  return (
    lead.status === LeadStatus.BLOCKED ||
    (isSunday(date) &&
      isSunday(getLeadCheckOutDate(lead)) &&
      isSameWeek(date, getLeadCheckOutDate(lead)))
  );
};

export const rowWidthDynamicFormula = (
  date: Date,
  lead: Lead,
  isCheckinVisible: boolean,
  isCheckoutVisible: boolean,
) => {
  const calendarStyleConstants = useResponsiveStyleCalendar();
  const totalDaysOnRow = getTotalDaysOnRow(date, lead);
  let totalWidth = '';
  if (isCheckinVisible) {
    totalWidth += '60%';
  } else {
    totalWidth += '0px';
  }
  if (isCheckoutVisible) {
    totalWidth += ' + 20%';
  }

  if (totalDaysOnRow > 0) {
    totalWidth += ` + ${totalDaysOnRow * 100}%`;
  }
  const totalNumberOfBorders =
    totalDaysOnRow +
    (isCheckinVisible ? 1 : 0) +
    (isCheckoutVisible ? 1 : 0) -
    1;
  const borderpx =
    totalNumberOfBorders * parseInt(calendarStyleConstants.cellBorderPx, 10);
  return `calc(${totalWidth} + ${borderpx}px)`;
};
