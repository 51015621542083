import {
  LeadPipelineTemplateAction,
  LeadPipelineTemplateActions,
} from 'components/domain/template/modal/TemplateModal.types';
import useAppQuery from 'hooks/useAppQuery';
import { Template, TemplateContent } from 'models/Templates';
import API from 'services/API';

export interface LeadActionFacadeResponseTO {
  leadAction: {
    leadUid: string;
    action: LeadPipelineTemplateAction;
    subject: string;
    content: string;
    recipients: string[];
    copyAgency: boolean;
    language: string;
  };
}

const useLeadActionsFacade = ({
  leadUid,
  leadPipelineTemplateAction,
}: {
  leadUid: string;
  leadPipelineTemplateAction: LeadPipelineTemplateAction;
}): {
  isLoadingLeadFacadeTemplate: boolean;
  fetchedLeadFacadeTemplate: Template;
  recipients: string[];
  copyAgency: boolean;
} => {
  const {
    data: fetchedLeadFacadeTemplate,
    isInitialLoading: isLoadingLeadFacadeTemplate,
  } = useAppQuery(
    ['leadActionFacadeHandler', leadUid, leadPipelineTemplateAction],
    async () => {
      const response = await API.get(
        `/api/internal/leads-actions-facade?leadUid=${leadUid}&leadAction=${leadPipelineTemplateAction}`,
      );

      const {
        leadAction: { subject, content, recipients, copyAgency, language },
      } = response.data;

      return {
        recipients,
        copyAgency,
        template: {
          contents: [
            {
              content,
              locale: language || 'en',
              title: subject,
            } as TemplateContent,
          ],
        },
      };
    },
    {
      enabled:
        LeadPipelineTemplateActions.includes(leadPipelineTemplateAction) &&
        !!leadUid,
    },
  );

  return {
    isLoadingLeadFacadeTemplate,
    fetchedLeadFacadeTemplate: fetchedLeadFacadeTemplate?.template,
    recipients: fetchedLeadFacadeTemplate?.recipients,
    copyAgency: fetchedLeadFacadeTemplate?.copyAgency,
  };
};

export default useLeadActionsFacade;
