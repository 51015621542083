import { ReactNode } from 'react';
import { propertySettingsBulkSavableFields } from './Common.constants';
import BulkSaveIconWithTooltip from './BulkSaveIconWithTooltip';

const PropertyFormFieldLabel = ({
  children,
  name,
}: {
  children: string | ReactNode;
  name: string;
}) => {
  const [sectionName, ...restFieldName] = name.split('.');

  if (
    propertySettingsBulkSavableFields[sectionName]?.includes(
      restFieldName.join('.'),
    )
  ) {
    return (
      <>
        {children}
        <BulkSaveIconWithTooltip />
      </>
    );
  }

  return children;
};

export default PropertyFormFieldLabel;
