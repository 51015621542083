import { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import useLocalStorage from 'hooks/useLocalStorage';
import AmenitiesMultiSelectField from 'components/forms/domain/property/AmenitiesMultiSelectField';
import BedroomsRangeField from 'components/forms/domain/property/BedroomsRangeField';
import GuestsRangeField from 'components/forms/domain/property/GuestsRangeField';
import LocationSelectField from 'components/forms/domain/property/LocationSelectField';
import StatusSelectField from 'components/forms/domain/property/StatusSelectField';
import TagsMultiSelectField from 'components/forms/domain/property/TagsMultiSelectField';
import TypeSelectField from 'components/forms/domain/property/TypeSelectField';
import { useFetchPropertiesFilterOptions } from 'components/domain/property/useFetchPropertiesFilterOptions';
import { Form } from 'components/forms/form/Form';
import {
  FilterFormActions,
  FilterFormClearButton,
  FilterFormSubmitButton,
} from 'components/filters/FilterFormButtons';
import PropertiesContext from '../PropertiesContext';
import {
  PropertiesFilterSchema,
  PropertiesFilterSchemaType,
  getFormDefaultValues,
} from './PropertiesFilter.schema';
import SortBySelectField from './fields/SortBySelectField';
import usePropertiesFilterFormApplyStoredValues from './usePropertiesFilterFormApplyStoredValues';

const PropertiesFilter = () => {
  const {
    propertiesFilterLocalStorageKey,
    propertySearchFieldRef,
    setLastSubmittedFilters,
  } = useContext(PropertiesContext);
  const { data: serverFilterOptions } = useFetchPropertiesFilterOptions();
  const { reset, watch } = useFormContext();
  const { setValue: storeFilterFormValue, clearLocalStorage } = useLocalStorage(
    propertiesFilterLocalStorageKey,
  );
  const isPropertiesSearchActive = !!watch('propertySearch')?.length;
  usePropertiesFilterFormApplyStoredValues();

  const handleSubmit = (formValues) => {
    const nextFormValues = PropertiesFilterSchema.cast(
      { ...formValues },
      {
        stripUnknown: true,
      },
    );

    storeFilterFormValue(nextFormValues);
    setLastSubmittedFilters(nextFormValues as PropertiesFilterSchemaType);
  };

  const handleClear = () => {
    const defaultValues = getFormDefaultValues(serverFilterOptions);
    reset(defaultValues);
    propertySearchFieldRef.current?.clear();
    clearLocalStorage();
    setLastSubmittedFilters(defaultValues as PropertiesFilterSchemaType);
  };

  return (
    <Form
      data-testid="properties-filter-form"
      horizontal
      onSubmit={handleSubmit}
    >
      <Row>
        <Col md={4} sm={6}>
          <BedroomsRangeField
            name="bedrooms"
            disabled={isPropertiesSearchActive}
          />
        </Col>
        <Col md={4} sm={6}>
          <StatusSelectField
            name="status"
            disabled={isPropertiesSearchActive}
          />
        </Col>
        <Col md={4} sm={6}>
          <AmenitiesMultiSelectField
            name="amenities"
            disabled={isPropertiesSearchActive}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4} sm={6}>
          <GuestsRangeField name="guests" disabled={isPropertiesSearchActive} />
        </Col>
        <Col md={4} sm={6}>
          <TypeSelectField name="type" disabled={isPropertiesSearchActive} />
        </Col>
        <Col md={4} sm={6}>
          <TagsMultiSelectField
            name="tags"
            disabled={isPropertiesSearchActive}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4} sm={6}>
          <SortBySelectField
            name="sortBy"
            disabled={isPropertiesSearchActive}
          />
        </Col>
        <Col md={4} sm={6}>
          <LocationSelectField
            name="location"
            disabled={isPropertiesSearchActive}
          />
        </Col>
      </Row>

      <FilterFormActions>
        <FilterFormClearButton onClick={handleClear} />
        <FilterFormSubmitButton disabled={isPropertiesSearchActive} />
      </FilterFormActions>
    </Form>
  );
};

export default PropertiesFilter;
