import { useTranslation } from 'react-i18next';
import Button from '../../../../components/button/Button';

const DeleteOrderAdjustmentButton = ({
  onDelete,
}: {
  onDelete: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Button
      type="button"
      className="btn btn-xs btn-link"
      onClick={onDelete}
      data-testid="price-details-list-item-delete-button"
    >
      {t('common.delete')}
    </Button>
  );
};

export default DeleteOrderAdjustmentButton;
