import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';
import FormWithProvider from 'components/forms/form/Form';

export const Container = styled(FormWithProvider)`
  select {
    width: calc(100% - 2px);
    max-width: 200px;
  }

  select {
    margin-left: 4px;
  }

  h3 {
    margin-bottom: 40px;
  }

  ${mediaQuery.xs} {
    select {
      max-width: 65vw;
    }
  }
`;

export const PropertyWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: start;
  gap: 19px;

  img {
    border-radius: 4px;
    border: 1px solid #aeaca8;
    width: 90px;
    height: 70px;
    padding: 4px;
  }

  select {
    width: 270px;
    margin-right: 10px;
  }

  label {
    display: none;
  }

  ${mediaQuery.xs} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 72px;
  }
`;

export const ContentWrapper = styled.div`
  > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  ${mediaQuery.xs} {
    > div {
      flex-direction: column;
    }
  }
`;

export const DatesWrapper = styled.div`
  margin-left: 50px;

  input {
    margin-left: 26px;
    width: 120px;
  }

  ${mediaQuery.xs} {
    input {
      margin-left: 0;
      width: 65vw;
    }
  }
`;

export const FooterWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DatePickerWrapper = styled.div`
  margin-left: -40px;

  ${mediaQuery.xs} {
    span {
      right: -20vw;
    }
  }
`;

export const NotesWrapper = styled.div`
  margin-left: -26px;
  > div {
    width: calc(100% + 26px);
  }

  > span {
    margin: 0 auto;
  }

  ${mediaQuery.xs} {
    margin-left: 15px;
    > div {
      width: 100%;
    }

    textarea {
      width: 65vw;
    }

    > span {
      margin-top: 20px;
    }
  }
`;
