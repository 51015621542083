import { useMemo, useState } from 'react';
import InboxLeadsContext from './InboxLeadsContext';

const InboxLeadsContextProvider = ({ children }) => {
  const [selectedLeadUid, setSelectedLeadUid] = useState<string>();
  const [leadsMap, setLeadsMap] = useState({});
  const [threadRelatedLeadsMap, setThreadRelatedLeadsMap] = useState({});
  const [threadRelatedPropertiesMap, setThreadRelatedPropertiesMap] = useState(
    {},
  );
  const contextValue = useMemo(
    () => ({
      leadsMap,
      selectedLeadUid,
      threadRelatedLeadsMap,
      threadRelatedPropertiesMap,
      setSelectedLeadUid,
      deleteLeadFromMap: (leadUid) => {
        setLeadsMap((currentMap) => {
          delete currentMap[leadUid];
          return { ...currentMap };
        });
      },
      updateLeadsMap: (lead) => {
        setLeadsMap((currentMap) => ({
          ...currentMap,
          [lead.uid]: lead,
        }));
      },
      updateThreadRelatedLeadsMap: ({ threadUid, leads }) => {
        setThreadRelatedLeadsMap((currentMap) => ({
          ...currentMap,
          [threadUid]: leads,
        }));
      },
      isThreadRelatedPropertyExists: (threadUid) => {
        return !!threadUid && !!threadRelatedPropertiesMap[threadUid];
      },
      addThreadRelatedProperty: (threadUid, property) => {
        setThreadRelatedPropertiesMap((previousMap) => ({
          ...previousMap,
          [threadUid]: property,
        }));
      },
    }),
    [leadsMap, selectedLeadUid, threadRelatedLeadsMap],
  );

  return (
    <InboxLeadsContext.Provider value={contextValue}>
      {children}
    </InboxLeadsContext.Provider>
  );
};

export default InboxLeadsContextProvider;
