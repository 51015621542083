import { memo } from 'react';
import { isBefore, isSameDay } from 'date-fns';
import useAppSelector from 'hooks/useAppSelector';
import { getLeadCheckInDate } from 'utils/lead/leadUtils';
import { CalendarLead } from 'pages/stackedCalendar/Calendar.types';
import LeadView from './LeadView';

const LeadViewRenderer = memo(
  ({
    lead,
    dayDate,
    isCheckInDay,
  }: {
    lead: CalendarLead;
    dayDate: Date;
    isCheckInDay: boolean;
  }) => {
    const visibleDates = useAppSelector(
      (state) => state.stackedCalendar.visibleDates,
    );
    const firstRenderedDay = visibleDates[1];

    const isCheckInOutsideRange = isBefore(
      getLeadCheckInDate(lead),
      firstRenderedDay,
    );
    const renderLeadView =
      isCheckInDay ||
      (isCheckInOutsideRange && isSameDay(dayDate, firstRenderedDay));

    if (!renderLeadView) {
      return null;
    }

    return <LeadView lead={lead} />;
  },
);

export default LeadViewRenderer;
