import { Container } from './Properties.styles';
import PropertiesContextProvider from './PropertiesContextProvider';
import PropertiesBody from './body/PropertiesBody';
import PropertiesFilterFormProvider from './filters/PropertiesFilterFormProvider';
import PropertiesHeader from './header/PropertiesHeader';

const PropertiesList = () => {
  return (
    <PropertiesContextProvider>
      <Container>
        <PropertiesFilterFormProvider>
          <PropertiesHeader />
          <PropertiesBody />
        </PropertiesFilterFormProvider>
      </Container>
    </PropertiesContextProvider>
  );
};

export default PropertiesList;
