import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import {
  PropertiesListContainer,
  PropertiesListElements,
  PropertyListItemWrapper,
} from './PropertiesList.styles';

const PropertiesListLoader = () => {
  return (
    <PropertiesListContainer>
      <PropertiesListElements>
        {[1, 2, 3, 4, 5].map((number) => (
          <PropertyListItemWrapper
            hasExpandIcon={false}
            isSubUnit={false}
            isUnitType={false}
            isUnit={false}
            isHotel={false}
            key={number}
          >
            <LoadingPlaceholder circle width="30px" />
            <LoadingPlaceholder linesCount={1.2} width="75%" />
          </PropertyListItemWrapper>
        ))}
      </PropertiesListElements>
    </PropertiesListContainer>
  );
};

export default PropertiesListLoader;
