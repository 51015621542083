import { useTranslation } from 'react-i18next';
import HostfullyLogo from 'assets/images/hf-logo-white.png';
import QuestionCircleHelp from 'assets/images/question-circle.svg?react';
import useAppConfig from 'hooks/useAppConfig';
import {
  HelpLink,
  LayoutDesktopCustomHeaderStyled,
  LayoutDesktopHeaderStyled,
} from './LayoutDesktop.styles';

const LayoutDesktopHeader = () => {
  const {
    customSettings: { customHeaderContent, logoUrl },
  } = useAppConfig();
  const { t } = useTranslation();

  if (customHeaderContent) {
    return (
      <LayoutDesktopCustomHeaderStyled
        dangerouslySetInnerHTML={{ __html: customHeaderContent }}
      />
    );
  }

  const handleRedirectHelp = () => {
    window.open('https://help.hostfully.com/', '_blank');
  };

  return (
    <LayoutDesktopHeaderStyled>
      <div className="container">
        <div className="inner-container">
          <img src={logoUrl || HostfullyLogo} alt="Logo" />
          <HelpLink onClick={handleRedirectHelp}>
            <QuestionCircleHelp />
            <span>{t('common.layout.help')}</span>
          </HelpLink>
        </div>
      </div>
    </LayoutDesktopHeaderStyled>
  );
};

export default LayoutDesktopHeader;
