import { Lead } from 'models/Leads';
import LeadListItemBasicInfo from 'components/domain/lead/list/LeadListItemBasicInfo';
import useScreenBreakpoints from 'hooks/useScreenBreakpoints';
import LeadListItemCreationDetails from 'components/domain/lead/list/LeadListItemCreationDetails';
import Loader from '../../../loader/Loader';
import { TemplateModalLead } from './TemplateModal.styles';

const TemplateModalLeadWrapper = ({
  lead,
  isLoading,
}: {
  lead: Lead;
  isLoading: boolean;
}) => {
  const { isSM, isXS } = useScreenBreakpoints();

  const isSmallScreen = isXS || isSM;

  if (!lead) {
    return null;
  }

  if (isLoading) {
    // TODO - implement skeleton loader
    return <Loader />;
  }

  return (
    <TemplateModalLead>
      {!isSmallScreen && <LeadListItemCreationDetails lead={lead} />}
      <LeadListItemBasicInfo lead={lead} />
    </TemplateModalLead>
  );
};

export default TemplateModalLeadWrapper;
