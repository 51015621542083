import { Lead } from 'models/Leads';
import {
  QuoteOverrides,
  Quote,
  LeadQuoteOverride,
  FeeAmountType,
} from 'models/Quote';
import { HasFeeItem, HasOrderItems } from 'models/hasOrderItems/HasOrderItems';

export function prepareOrderItems(quote: Quote, lead: Lead): HasOrderItems {
  if (!quote) return null;

  const otherFees: HasFeeItem[] =
    quote.overrides?.fees?.otherFees
      .filter((f) => f.enabled)
      .map((fee) => ({
        ...fee,
        taxAmount:
          quote?.fees?.otherFees?.find((o) => o.name === fee.name)?.taxAmount ||
          0,
        grossAmount:
          fee.amount ||
          quote?.fees?.otherFees?.find((o) => o.name === fee.name)
            ?.grossPrice ||
          0,
        netPrice:
          fee.netPrice ||
          quote?.fees?.otherFees?.find((o) => o.name === fee.name)?.netPrice ||
          0,
        isEditable:
          quote?.fees?.otherFees?.find((o) => o.name === fee.name)
            ?.isEditable || true,
        type: quote?.fees?.otherFees?.find((o) => o.name === fee.name)?.type,
      })) || [];

  return {
    force100PercentPaymentAtReservation:
      quote?.overrides?.force100PercentPaymentAtReservation != null
        ? quote?.overrides?.force100PercentPaymentAtReservation
        : lead?.order?.force100PercentPaymentAtReservation,
    includeSecurityDepositInTotal: quote.includeSecurityDepositInTotal,
    securityDeposit: quote.securityDeposit,
    currency: quote.currency,
    totalAmount: quote.totalAmount,
    rent: {
      ...quote.rent,
      extraGuestsBaseNetPrice:
        quote?.overrides?.rent?.extraGuestsBaseNetPrice ||
        quote.rent.extraGuestsBaseNetPrice,
      rentBaseNetPrice:
        quote?.overrides?.rent?.rentBaseNetPrice || quote.rent.rentBaseNetPrice,
      taxAmount: quote?.overrides?.rent?.taxAmount || quote.rent.taxAmount,
      taxationRate:
        quote?.overrides?.rent?.taxationRate || quote.rent.taxationRate,
    },
    services: quote.services,
    adjustmentItems: quote.adjustmentItems,
    fees: {
      cleaningFee: {
        ...quote?.fees?.cleaningFee,
        amountType: FeeAmountType.AMOUNT,
        grossAmount: quote?.fees?.cleaningFee?.netPrice || 0,
      },
      otherFees: [...otherFees],
    },
  };
}

export function convertQuoteOverrideToLeadQuoteOverride(
  overrides: QuoteOverrides,
): LeadQuoteOverride {
  return {
    ...overrides,
    rent: {
      ...overrides.rent,
      rentBaseNetPrice: overrides?.rent?.rentBaseNetPrice || null,
      rentNetPrice: null,
      extraGuestsBaseNetPrice: overrides?.rent?.extraGuestsBaseNetPrice,
    },
    fees: {
      cleaningFee: overrides?.fees?.cleaningFee
        ? {
            netPrice: overrides?.fees?.cleaningFee?.netPrice,
            taxationRate: overrides?.fees?.cleaningFee?.taxationRate,
          }
        : null,
      otherFees:
        overrides?.fees?.otherFees?.map((fee) => ({
          ...fee,
        })) || [],
    },
  };
}
