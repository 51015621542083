const DarkBluePaintSvg = () => {
  return (
    <svg
      width="175"
      height="155"
      viewBox="0 0 175 155"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.8089 16.1862C18.2621 22.9489 10.2605 32.254 6.1393 42.549C3.71062 48.4288 2.11258 54.4334 1.38141 60.4268C1.0795 62.5035 0.848573 64.5297 0.670094 66.536C0.0613695 72.6872 -0.0632549 78.8044 0.29699 84.8473C0.745135 92.6362 2.20903 100.233 4.66293 107.505C7.93153 117.197 13.4198 126.012 20.847 133.498C28.4923 141.203 38.3582 147.086 49.7213 150.716C61.0845 154.345 73.6568 155.63 86.5168 154.475C102.451 153.137 118.307 148.206 132.422 140.201C149.62 130.169 162.773 115.638 169.387 99.3631C176.428 80.9685 175.943 62.0135 168.014 45.7735C164.616 38.7288 160.012 32.2606 154.33 26.549C144.18 16.3111 130.864 8.68975 115.509 4.33069C104.984 1.40956 93.6458 0.179586 82.0166 0.697054C70.6086 1.07725 59.0666 3.19697 47.9394 6.95539C41.1878 9.25797 34.7377 12.3702 28.8089 16.1862Z"
        fill="#2D2AA5"
      />
    </svg>
  );
};

export default DarkBluePaintSvg;
