import { AllowedFlags } from 'hooks/useFeatures';

export enum Unread {
  /* eslint-disable no-unused-vars */
  message = 'message',
  lead = 'lead',
  /* eslint-enable no-unused-vars */
}

export type UnreadType = keyof typeof Unread;

export interface Item {
  path: string;
  labelKey: string;
  unreadType?: UnreadType;
  allowedToRender?: (props: AllowedFlags) => boolean;
}

export interface NavigationProps {
  navItems: Item[];
  dropdownItems: Item[];
  dropdownSecondaryItems?: Item[];
}
