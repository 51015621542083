/* eslint-disable react/require-default-props */
import { memo } from 'react';
import { addDays, isWithinInterval } from 'date-fns';
import { Job } from 'models/Jobs';
import useFormatCurrency from 'hooks/useFormatCurrency';
import { getLeadCheckInDate, getLeadCheckOutDate } from 'utils/lead/leadUtils';
import { getDayCellCommonClasses } from 'pages/calendar/common/cells/DayCellCommon.styles';
import { classNames } from 'utils/classNameUtils';
import JobView from '../jobs/JobView';
import { stackedCalendarStyleConstants } from '../../Calendar.constants';
import {
  CalendarLead,
  DayCellData,
  StackedCalendarMode,
} from '../../Calendar.types';
import useCalendarBodySelection from '../../../calendar/common/body/selection/useCalendarBodySelection';
import MoonIcon from './MoonIcon';
import DayCellSelection from './DayCellSelection';
import DayCellLead from './DayCellLead';

const DayCell = memo(
  ({
    dayData,
    dayDate,
    leads,
    jobs,
    mode,
    propertyUid,
    isUnitType,
    isUnit,
  }: {
    dayData: DayCellData;
    dayDate: Date;
    leads: CalendarLead[];
    jobs: Job[];
    mode: StackedCalendarMode;
    propertyUid: string;
    isUnitType?: boolean;
    isUnit?: boolean;
  }) => {
    const { entry, isWeekend, isEmpty } = dayData;
    const { pricing, availability } = entry;
    const isPricingMode =
      mode === StackedCalendarMode.PRICING ||
      mode === StackedCalendarMode.COMBINED;
    const width = stackedCalendarStyleConstants.daysCellWidth[mode];

    const formatCurrency = useFormatCurrency();
    const { handleMouseEnter } = useCalendarBodySelection();

    const onMouseEnter = () => {
      handleMouseEnter({ date: dayDate, propertyUid });
    };

    const formattedPrice =
      isPricingMode &&
      !!pricing &&
      formatCurrency({
        currency: pricing.currency || 'USD',
        value: Number(pricing.value || 0),
        options: {
          round: true,
          removeDecimals: true,
        },
      });

    const currentDayMinStay = availability.minimumStayLength;

    const isUnavailable =
      availability.unavailable || !availability.availableForCheckIn;

    const shouldShowCellInfo =
      !isEmpty &&
      isPricingMode &&
      !leads?.some((lead) =>
        // need to add 1 day to allow currentLead to skip checkOut but contain checkIn
        isWithinInterval(addDays(dayDate, 1), {
          start: getLeadCheckInDate(lead),
          end: getLeadCheckOutDate(lead),
        }),
      );

    return (
      <div
        className={classNames({
          'body-day-cell': true,
          [getDayCellCommonClasses(dayDate)]: true,
          [`width-${width}`]: true,
          unavailable: isUnavailable,
          weekend: isWeekend,
        })}
        data-day-date={dayDate.getTime()}
        data-is-unit-type={isUnitType}
        data-is-unit={isUnit}
        data-property-uid={propertyUid}
        onMouseEnter={onMouseEnter}
      >
        {leads?.map((lead) => (
          <DayCellLead
            key={lead.uid}
            mode={mode}
            dayDate={dayDate}
            lead={lead}
            width={width}
          />
        ))}

        {shouldShowCellInfo && (
          <>
            {currentDayMinStay && (
              <span className="day-cell-content-line">
                <MoonIcon /> {currentDayMinStay}
              </span>
            )}
            {formattedPrice && (
              <span className="day-cell-content-line">{formattedPrice}</span>
            )}
          </>
        )}

        <DayCellSelection dayDate={dayDate} propertyUid={propertyUid} />
        {!!jobs?.length && <JobView mode={mode} jobs={jobs} />}
      </div>
    );
  },
);

export default DayCell;
