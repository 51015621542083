import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import {
  PropertyItemPermissionsEmployeeListItemStyled,
  PropertyItemPermissionsEmployeeListStyled,
} from './PropertyItemPermissions.styles';

const PropertyItemPermissionsUsersListPlaceholder = () => {
  return (
    <PropertyItemPermissionsEmployeeListStyled>
      <PropertyItemPermissionsEmployeeListItemStyled $isPlaceholder>
        <LoadingPlaceholder circle width="24px" />
      </PropertyItemPermissionsEmployeeListItemStyled>
    </PropertyItemPermissionsEmployeeListStyled>
  );
};

export default PropertyItemPermissionsUsersListPlaceholder;
