import PropertyExpectationsHeader from './PropertyExpectationsHeader';
import PropertyExpectationsTable from './PropertyExpectationsTable';

const PropertyExpectations = () => {
  return (
    <div data-testid="property-expectations-section">
      <PropertyExpectationsHeader />
      <PropertyExpectationsTable />
    </div>
  );
};

export default PropertyExpectations;
