import Hero from './hero/Hero';
import Info from './info/Info';
import DarkBluePaintSvg from './svg/DarkBluePaintSvg';
import OrangePaintSvg from './svg/OrangePaintSvg';
import {
  Container,
  Content,
  Main,
  OrangeWrapper,
  DarkBlueWrapper,
  YellowWrapper,
  LightBlueWrapper,
  PurpleWrapper,
} from './NotFound.styles';
import LightBluePaintSvg from './svg/LightBluePaintSvg';
import PurplePaintSvg from './svg/PurplePaintSvg';
import YellowPaintSvg from './svg/YellowPaintSvg';
import Footer from './footer/Footer';

const NotFound = () => {
  return (
    <Container>
      <Content>
        <Main>
          <Hero />
          <Info />
        </Main>

        <Footer />
      </Content>

      <PurpleWrapper>
        <PurplePaintSvg />
      </PurpleWrapper>

      <LightBlueWrapper>
        <LightBluePaintSvg />
      </LightBlueWrapper>

      <YellowWrapper>
        <YellowPaintSvg />
      </YellowWrapper>

      <DarkBlueWrapper>
        <DarkBluePaintSvg />
      </DarkBlueWrapper>

      <OrangeWrapper>
        <OrangePaintSvg />
      </OrangeWrapper>
    </Container>
  );
};

export default NotFound;
