import { useTranslation } from 'react-i18next';
import Badge from 'components/badge/Badge';
import { PropertyBase, PropertyBusinessType } from 'models/Properties';
import MasterUnitIcon from './icons/MasterUnitIcon.svg?react';
import MultiUnitIcon from './icons/MultiUnitIcon.svg?react';

interface PropertyLabelProps {
  property: Pick<PropertyBase, 'businessType'> & {
    subUnits?: PropertyBase['subUnits'];
  };
}

const PropertyLabel = ({ property }: PropertyLabelProps) => {
  const { t } = useTranslation();

  if (property.businessType === PropertyBusinessType.HOTEL) {
    return (
      <Badge
        data-testid="property-label"
        fontColor="#3a84d8"
        backgroundColor="#e7f1ff"
        outline
      >
        <MultiUnitIcon />
        {t('pageProperties.label.multiUnit')}
      </Badge>
    );
  }

  if (property.subUnits?.length) {
    return (
      <Badge
        data-testid="property-label"
        fontColor="#3a84d8"
        backgroundColor="#e7f1ff"
        outline
      >
        <MasterUnitIcon />
        {t('pageProperties.label.masterUnit')}
      </Badge>
    );
  }

  return null;
};

export default PropertyLabel;
