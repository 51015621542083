import { useContext } from 'react';
import { Col } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import { ImportableProperties } from 'models/bookingDotCom/ImportableProperties';
import { Trans, useTranslation } from 'react-i18next';
import { ImportType } from '../../modal/ImportBookingDotComPropertiesModal.utils';
import ConnectProviderButton from '../button/ConnectProviderButton';
import ImportBookingDotComPropertiesContext from '../../modal/ImportBookingDotComPropertiesContext';
import { PropertiesListInputWrapper } from './PropertiesListInput.styles';

const PropertiesListInput = () => {
  const { importType, propertyIds } = useWatch();
  const { importableProperties } = useContext(
    ImportBookingDotComPropertiesContext,
  );
  const { setValue } = useFormContext();
  const { t } = useTranslation();

  if (importType === ImportType.ALL_PROPERTIES) {
    return null;
  }

  const renderProperties = (properties: ImportableProperties[]) => {
    return properties.map((property) => {
      const isChecked = propertyIds?.includes(property.bookingDotComId);

      return (
        <CheckboxField
          key={property.bookingDotComId}
          name="propertyId"
          data-testid={`property-checkbox-${property.bookingDotComId}`}
          checked={isChecked}
          onClick={(event: any) => {
            const updatedIds = event.target.checked
              ? [...propertyIds, property.bookingDotComId] // Add to array if checked
              : propertyIds.filter((id) => id !== property.bookingDotComId); // Remove if unchecked

            setValue('propertyIds', updatedIds); // Update the form value
          }}
        >
          {property.bookingDotComId} - {property.name}
        </CheckboxField>
      );
    });
  };

  return (
    <Col xs={12}>
      <PropertiesListInputWrapper>
        {renderProperties(importableProperties)}
        <p>
          <Trans
            i18nKey="pageProperties.importBookingDotComPropertiesModal.propertyNotListed"
            components={{
              backToStart: (
                <ConnectProviderButton
                  renderAsLink
                  label={t(
                    'pageProperties.importBookingDotComPropertiesModal.here',
                  )}
                />
              ),
            }}
          />
        </p>
      </PropertiesListInputWrapper>
    </Col>
  );
};

export default PropertiesListInput;
