import CalendarBodyContextProvider from './CalendarBodyContextProvider';
import Body from './Body';

const BodyWrapper = () => {
  return (
    <CalendarBodyContextProvider>
      <Body />
    </CalendarBodyContextProvider>
  );
};

export default BodyWrapper;
