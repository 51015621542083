import { useTranslation } from 'react-i18next';

interface YesOrNoCellProps {
  getValue: () => boolean;
}

const YesOrNoCell = ({ getValue }: YesOrNoCellProps) => {
  const { t } = useTranslation();
  return getValue() ? t('common.yes') : t('common.no');
};

export default YesOrNoCell;
