import { useContext } from 'react';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import TableContext from '../TableContext';

const TableBodyLoading = () => {
  const { tableInstance } = useContext(TableContext);
  const rows = Array.from({ length: 5 });
  const columns = tableInstance.getHeaderGroups()[0].headers;

  return (
    <>
      {rows.map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <tr key={index}>
          {columns.map((header) => (
            <td
              className={`body-cell body-cell-${header.column.id} body-cell-loading`}
              key={header.id}
            >
              <LoadingPlaceholder key={header.id} />
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

TableBodyLoading.defaultProps = {
  className: '',
};

export default TableBodyLoading;
