import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import mediaQuery from '../../../../styles/mediaQuery';

export const AdditionalChargeAmountRow = styled(Row)`
  .input-group .form-control {
    width: 50%;

    ${mediaQuery.xs} {
      width: 100%;
    }
  }
`;
