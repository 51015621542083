import { useTranslation } from 'react-i18next';
import useAppMutation from 'hooks/useAppMutation';
import API from 'services/API';
import useNotify from 'hooks/useNotify';
import { Property } from 'models/Properties';
import { getUnitType } from 'utils/lead/leadUtils';

interface PinCodePayload {
  pinCode: string;
  pinCodeUrl: string;
  uid?: string;
}

const usePinCodeMutations = () => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  const { mutate: createPinCode, isLoading: isCreatingPinCode } =
    useAppMutation(
      ({
        pinCode,
        leadUid,
        propertyUid,
        availableProperties,
      }: {
        pinCode: PinCodePayload;
        leadUid: string;
        propertyUid: string;
        availableProperties: Property[];
        successCallback?: Function;
      }) => {
        const unitType = getUnitType(availableProperties, propertyUid);

        return API.post(
          '/api/v3.1/pin-codes/',
          {
            leadUid,
            propertyUid: (!unitType && propertyUid) || null,
            unitUid: unitType && unitType.uid,
            pinCode: pinCode.pinCode,
            pinCodeUrl: pinCode.pinCodeUrl,
          },
          {
            headers: {
              'X-VERSION': '3.1',
            },
          },
        );
      },
      {
        onSuccess: (response, { successCallback }) => {
          successCallback?.();
        },
        onError: () =>
          notifyError(
            t(
              'componentLead.modal.stayDetailsTab.pinCodeForm.errorCreatePinCode',
            ),
          ),
      },
    );

  const { mutate: updatePinCode, isLoading: isUpdatingPinCode } =
    useAppMutation(
      ({
        pinCode,
      }: {
        pinCode: PinCodePayload;
        successCallback?: Function;
      }) => {
        return API.put(
          `/api/v3.1/pin-codes/${pinCode.uid}`,
          {
            pinCode: pinCode.pinCode,
            pinCodeUrl: pinCode.pinCodeUrl,
          },
          {
            headers: {
              'X-VERSION': '3.1',
            },
          },
        );
      },
      {
        onSuccess: (response, { successCallback }) => {
          successCallback?.();
        },
        onError: () =>
          notifyError(
            t(
              'componentLead.modal.stayDetailsTab.pinCodeForm.errorUpdatePinCode',
            ),
          ),
      },
    );

  return {
    createPinCode,
    updatePinCode,
    isCreatingPinCode,
    isUpdatingPinCode,
  };
};

export default usePinCodeMutations;
