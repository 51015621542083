import { GenericFormFieldBaseProps } from 'components/forms/genericFormField/GenericFormField.types';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import GenericFormField from 'components/forms/genericFormField/GenericFormField';

interface FieldPlaceholderProps
  extends Omit<GenericFormFieldBaseProps, 'fieldRenderer'> {}

/**
 * Simulates a field input but replaces its input by a <LoadingPlaceholder /> component.
 */
const FieldPlaceholder = ({ ...props }: FieldPlaceholderProps) => {
  const fieldRenderer = () => {
    return <LoadingPlaceholder linesCount={2.4} />;
  };

  return <GenericFormField fieldRenderer={fieldRenderer} {...props} />;
};

export default FieldPlaceholder;
