import { useContext } from 'react';
import LeadModalContext from 'components/domain/lead/modal/LeadModalContext';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import AmountField from './common/AmountField';

interface SecurityDepositProps {
  isEditable: boolean;
}

const SecurityDeposit = ({ isEditable }: SecurityDepositProps) => {
  const { quote } = useContext(LeadModalContext);
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  return (
    <tr>
      <td>{t('componentOrder.securityDeposit')}:</td>
      <td />
      <td />
      <td>
        <AmountField
          value={quote.securityDeposit}
          isEditable={isEditable}
          currency={quote.currency}
          onItemUpdated={(value) => {
            setValue('overrides.securityDeposit', value);
          }}
        />
      </td>
    </tr>
  );
};

export default SecurityDeposit;
