import * as Yup from 'yup';

const ownerAccountSchema = () => ({
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string().email(),
  phoneNumberAreaCode: Yup.string(),
  phoneNumber: Yup.string(),
  businessName: Yup.string().nullable(),
  website: Yup.string().nullable(),
  country: Yup.string(),
  preferredLocale: Yup.string(),
});

export default ownerAccountSchema;
