import styled from 'styled-components';
import theme from 'styles/theme';

export const CustomSelectFieldContainerStyled = styled.div`
  .select__group:not(:first-child) {
    border-top: 1px solid ${theme.colors.gray300};
  }

  .select__group-heading {
    color: ${theme.colors.fontDark};
    font-size: 1rem;
    font-weight: bold;
    text-transform: none;
  }

  .select__menu {
    z-index: 3;
  }
`;
