import { useEffect } from 'react';
import API from '../services/API';
import { GetAppConfigResponseTO } from '../models/Config';
import { setAppConfig } from '../store/slices/config';
import useAppUser from './useAppUser';
import useAppQuery from './useAppQuery';
import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';

const useFetchAppConfig = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppUser();
  const appConfigRefreshRequested = useAppSelector(
    (state) => state.config.appConfigRefreshRequested,
  );

  const {
    data: fetchedAppConfig,
    isLoading: isConfigFetching,
    refetch,
  } = useAppQuery(
    ['appConfig'],
    async () => {
      const response = await API.get<GetAppConfigResponseTO>(
        '/v3/web-app-config',
      );

      return response.data.webAppConfig;
    },
    { enabled: !!user },
  );

  useEffect(() => {
    if (fetchedAppConfig) {
      dispatch(setAppConfig({ appConfig: fetchedAppConfig }));
    }
  }, [fetchedAppConfig]);

  useEffect(() => {
    if (appConfigRefreshRequested) {
      refetch();
    }
  }, [appConfigRefreshRequested]);

  return { isConfigFetching };
};

export default useFetchAppConfig;
