import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  padding: 10px 10px 20px 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(173, 181, 189, 1);
  transition: 0.3s;
  cursor: pointer;
`;

export const Header = styled.div`
  margin-bottom: 10px;
`;

export const LeftSide = styled.div`
  width: 100%;

  > div {
    display: flex;
    align-items: center;
  }
`;

export const InfoWrapper = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  min-width: fit-content;
`;

export const TagsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  ${mediaQuery.sm} {
    & span:last-child {
      display: none;
    }
  }
`;

export const PropertyPlaceHolderWrapper = styled.div`
  width: 60px;
  height: 60px;
`;

export const AssigneePlaceHolderWrapper = styled.div`
  margin: auto 0;
`;
