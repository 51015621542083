import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { LoadingPlaceholderWrapper } from './LoadingLegendPlaceholder.styles';

const LoadingLegendPlaceholder = () => {
  return (
    <LoadingPlaceholderWrapper>
      <LoadingPlaceholder linesCount={1.5} width="150px" />
    </LoadingPlaceholderWrapper>
  );
};

export default LoadingLegendPlaceholder;
