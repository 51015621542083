import { useTranslation } from 'react-i18next';
import { normalizeKey } from 'utils/localeUtils';
import turnoTag from '../assets/turnoTag.svg?url';
import turnoPerson from '../assets/turnoPerson.svg?url';
import turnoSupport from '../assets/turnoSupport.svg?url';
import { Card, Container } from './BenefitsCards.styles';

const benefitItems = [
  {
    id: 1,
    imageUrl: turnoTag,
    imageAlt: 'price tag',
    descriptionTranslateKey: 'freeTurnoSubscription',
  },
  {
    id: 2,
    imageUrl: turnoPerson,
    imageAlt: 'person checked',
    descriptionTranslateKey: 'findLocalTrustedCleaners',
  },
  {
    id: 3,
    imageUrl: turnoSupport,
    imageAlt: 'person support',
    descriptionTranslateKey: 'bringYourTeamToTurno',
  },
];

const BenefitsCards = () => {
  const { t } = useTranslation();

  return (
    <Container>
      {benefitItems.map(
        ({ id, imageUrl, imageAlt, descriptionTranslateKey }) => (
          <Card key={id}>
            <img src={imageUrl} alt={imageAlt} />
            <p>
              {t(
                normalizeKey(`pageTurnoManagement.${descriptionTranslateKey}`),
              )}
            </p>
          </Card>
        ),
      )}
    </Container>
  );
};

export default BenefitsCards;
