import Button from './Button';
import { ToggleButtonWrapper } from './ToggleButton.styles';

interface ToggleButtonProps {
  toggleVertically?: boolean;
  isExpanded: boolean;
  onClick?: () => void;
}

const ToggleButton = (props: ToggleButtonProps) => {
  const { toggleVertically, isExpanded, onClick } = props;
  return (
    <Button
      onClick={() => onClick?.()}
      className="btn btn-xs btn-link"
      $noOutlineWhenFocus
    >
      <ToggleButtonWrapper
        $isExpanded={isExpanded}
        $vertical={toggleVertically}
      />
    </Button>
  );
};

ToggleButton.defaultProps = {
  toggleVertically: false,
  onClick: () => {},
};

export default ToggleButton;
