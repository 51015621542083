import { useTranslation } from 'react-i18next';
import { HasOrderItems } from 'models/hasOrderItems/HasOrderItems';
import { useFormContext } from 'react-hook-form';
import { Lead } from 'models/Leads';
import { showCleaningFeeRow } from 'utils/lead/leadUtils';
import { QuoteFeeType } from 'models/Quote';
import { PriceDetailsList } from '../OrderViewPriceDetails.styles';
import OrderViewPriceDetailsListItem from '../OrderViewPriceDetailsListItem';
import { PriceDetailsItem } from '../OrderViewPriceDetailsListItemRow';

const Fees = ({
  order,
  lead,
  allowEdit,
  priceDetailsItems,
}: {
  order: HasOrderItems;
  lead: Lead;
  allowEdit: boolean;
  priceDetailsItems: PriceDetailsItem[];
}) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  const { fees, currency } = order;

  return (
    <PriceDetailsList data-testid="price-details-list-fees">
      <OrderViewPriceDetailsListItem
        title={t('componentOrder.fees')}
        priceDetailsItems={[
          showCleaningFeeRow({
            lead,
            cleaningFee: fees?.cleaningFee,
            allowEdit,
          }) && {
            label: t('componentOrder.cleaning'),
            amount: fees.cleaningFee.netPrice,
            currency,
            isEditable: allowEdit,
            onItemUpdated(val) {
              setValue('quote.fees.cleaningFee.netPrice', val, {
                shouldDirty: true,
              });
            },
          },
          ...priceDetailsItems,
          {
            label: t('componentOrder.totalFees'),
            amount:
              fees.otherFees
                .filter(({ removed }) => !removed)
                .filter(({ type }) => type !== QuoteFeeType.TAX)
                .reduce((acc, fee) => acc + fee.netPrice, 0) +
              fees.cleaningFee.netPrice,
            isTotalAmount: true,
            currency,
          },
        ]}
      />
    </PriceDetailsList>
  );
};

export default Fees;
