import { createElement } from 'react';
import useAppSelector from '../../hooks/useAppSelector';
import ConfirmModal from './ConfirmModal';
import CustomContentModal from './CustomContentModal';
import { ModalType } from './Modal.types';

const modalComponentsMapping = {
  [ModalType.CONFIRM]: ConfirmModal,
  [ModalType.CUSTOM_CONTENT]: CustomContentModal,
};

const Modals = () => {
  const activeModals = useAppSelector((state) => state.modal.activeModals);

  return (
    <>
      {Object.entries(activeModals).map(([modalId, { type }]) =>
        createElement(modalComponentsMapping[type], { modalId, key: modalId }),
      )}
    </>
  );
};

export default Modals;
