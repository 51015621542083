import useFormatCurrency from 'hooks/useFormatCurrency';
import { format } from 'date-fns';
import { LeadChannel, LeadChannelNameMap } from 'models/Leads';
import { trimAndLimitCharactersNumber } from 'utils/stringUtils';
import useScreenBreakpoints from 'hooks/useScreenBreakpoints';
import useFormatNumber from 'hooks/useFormatNumber';
import {
  NameType,
  Payload,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { getFormatter } from 'utils/analytics/analyticsUtils';
import { TimeSpan, getDateFormatBasedOnTimeSpan } from 'utils/dateTimeUtils';
import {
  CustomTooltipWrapper,
  CustomTooltipTitle,
  CustomTooltipContent,
} from '../barCharts/CustomBarChart.styles';
import { ChartFilter, ChartType } from '../../Charts.types';

const CustomTooltip = ({
  active,
  payload,
  chartFilter,
  showTitle,
  timeSpan,
}: {
  active: boolean;
  payload: Payload<ValueType, NameType>[];
  chartFilter: ChartFilter;
  showTitle?: boolean;
  timeSpan?: TimeSpan;
}) => {
  const formatNumber = useFormatNumber();
  const formatCurrency = useFormatCurrency();
  const { isXS, isSM } = useScreenBreakpoints();

  // TODO: refactor this, should not be handler like this
  const getTitle = (myPayload) => {
    let title = null;

    if (myPayload.teamMember) {
      title = myPayload.teamMember;
    } else if (myPayload.propertyName) {
      title = myPayload.propertyName;
    } else if (myPayload.from) {
      title = format(
        new Date(myPayload.from),
        getDateFormatBasedOnTimeSpan(timeSpan),
      );
    }

    return trimAndLimitCharactersNumber({
      text: title,
      limit: isXS || isSM ? 15 : 25,
    });
  };

  if (active && payload && payload.length) {
    return (
      <CustomTooltipWrapper>
        {showTitle && (
          <CustomTooltipTitle>
            {getTitle(payload[0].payload)}
          </CustomTooltipTitle>
        )}
        <CustomTooltipContent>
          <span>
            {!!payload[0].name &&
              (LeadChannelNameMap.get(payload[0].name as LeadChannel) ||
                payload[0].name)}
          </span>
          <b>
            {getFormatter({
              value: payload[0].payload.value,
              currency: payload[0].payload.currency,
              formatCurrency,
              formatNumber,
              axis:
                chartFilter.type === ChartType.FINANCIAL_PERFORMANCE
                  ? 'Y'
                  : 'X',
              chartFilter,
              timeSpan,
            })}
          </b>
        </CustomTooltipContent>
      </CustomTooltipWrapper>
    );
  }

  return null;
};

CustomTooltip.defaultProps = {
  showTitle: true,
  timeSpan: null,
};

export default CustomTooltip;
