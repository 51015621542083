import { ReactNode } from 'react';
import { BadgeProps as BSBadgeProps } from 'react-bootstrap';
import { BadgeStyled } from './Badge.styles';

interface BadgeProps extends Omit<BSBadgeProps, 'as' | 'children'> {
  children?: ReactNode;
  backgroundColor?: string;
  fontColor?: string;
  outline?: boolean;
}

const Badge = ({
  children,
  backgroundColor,
  fontColor,
  outline,
  ...props
}: BadgeProps) => {
  return (
    <BadgeStyled
      $backgroundColor={backgroundColor}
      $fontColor={fontColor}
      $outline={outline}
      {...props}
    >
      {children}
    </BadgeStyled>
  );
};

Badge.defaultProps = {
  children: undefined,
  backgroundColor: undefined,
  fontColor: undefined,
  outline: undefined,
};

export default Badge;
