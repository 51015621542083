import { useCallback, useContext } from 'react';
import { Lead } from 'models/Leads';
import {
  endOfMonth,
  getDate,
  isFirstDayOfMonth,
  isSameDay,
  isSaturday,
  isSunday,
} from 'date-fns';
import { getDayCellCommonClasses } from '../common/cells/DayCellCommon.styles';
import { isCheckinVisible } from '../Calendar.utils';
import { CalendarDay } from '../body/CalendarBody.types';
import CalendarContext from '../../stackedCalendar/CalendarContext';
import useResponsiveStyleCalendar from '../useResponsiveStyleCalendar';
import useCalendarBodySelection from '../common/body/selection/useCalendarBodySelection';
import {
  DayCellDate,
  DayCellMinStay,
  DayCellPricing,
  DayCellWrapper,
} from './DayCell.style';
import LeadView from './LeadView';
import JobView from './JobView';
import SelectionView from './SelectionView';

interface Props {
  calendarDay: CalendarDay;
}

const DayCellUnit = ({ calendarDay }: Props) => {
  const calendarStyleConstants = useResponsiveStyleCalendar();

  const { selectedProperty } = useContext(CalendarContext);
  const { handleMouseEnter } = useCalendarBodySelection();

  const { date: dayDate } = calendarDay;

  const isSaturdayOrEndOfMonth: boolean =
    isSaturday(dayDate) || isSameDay(dayDate, endOfMonth(dayDate));

  const getLeadComponent = (lead: Lead) => {
    const onSunday = isSunday(dayDate);
    const isMonthStart = isFirstDayOfMonth(dayDate);
    const showCheckin = isCheckinVisible(dayDate, lead);

    if (onSunday || showCheckin || isMonthStart) {
      return <LeadView lead={lead} date={dayDate} />;
    }

    return null;
  };

  const onSelectionMouseEnter = useCallback(() => {
    handleMouseEnter({
      date: dayDate,
      propertyUid: selectedProperty,
    });
  }, [dayDate, selectedProperty]);

  if (calendarDay.isLoading) {
    return (
      <DayCellWrapper
        $calendarStyleConstants={calendarStyleConstants}
        $borderRight={isSaturdayOrEndOfMonth}
      >
        <DayCellDate>{getDate(dayDate)}</DayCellDate>
        <DayCellMinStay $isLoading />
        <DayCellPricing $isLoading />
      </DayCellWrapper>
    );
  }

  return (
    <DayCellWrapper
      $inRed={false}
      $calendarStyleConstants={calendarStyleConstants}
      $borderRight={isSaturdayOrEndOfMonth}
      className={`body-day-cell ${getDayCellCommonClasses(dayDate)}`}
      data-day-date={dayDate.getTime()}
      data-property-uid={selectedProperty}
      onMouseEnter={onSelectionMouseEnter}
    >
      <SelectionView dayDate={dayDate} propertyUid={selectedProperty} />

      <DayCellDate>{getDate(dayDate)}</DayCellDate>
      <JobView jobs={calendarDay.jobs} />
      {calendarDay.leads?.map((lead) => getLeadComponent(lead))}
    </DayCellWrapper>
  );
};

export default DayCellUnit;
