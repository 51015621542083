import Button from 'components/button/Button';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import IconLink from 'assets/images/icons/icon-link.png';

export const PaymentTimelineRow = styled(Col)`
  border-bottom: 1px dashed #ddd;
  padding-bottom: 10px;
  text-align: left;
  display: flex;
  align-items: center;
`;

export const ColWithMargin = styled(Col)<{ textLeft?: boolean }>`
  margin-top: 10px;
  margin-bottom: 10px;

  ${({ textLeft }) => textLeft && 'text-align: left;'}
`;

export const CopyLinkButton = styled(Button).attrs({
  'data-testid': 'payment-copy-link-button',
})`
  background-image: url(${IconLink});
  background-position: 8px 8px;
  background-size: 18px;
  background-repeat: no-repeat;
  margin-left: 20px;
  padding-left: 30px;
`;
