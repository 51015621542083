import Tooltip from 'components/tooltip/Tooltip';
import { LeadCircleIconContainer } from './LeadCircleIcon.styles';
import { LeadCircleIconPosition } from './LeadCircleIcon.types';

interface Props {
  id: string;
  src: string;
  tooltip: string;
  position: LeadCircleIconPosition;
}

const LeadCircleIcon = ({ id, position, src, tooltip }: Props) => (
  <Tooltip id={`${id}-tooltip`} text={tooltip}>
    <LeadCircleIconContainer
      data-testid={`lead-status-indicator-icon-${id}`}
      position={position}
      src={src}
    />
  </Tooltip>
);

export default LeadCircleIcon;
