import { BaseResponse } from './_base';

export enum BedType {
  airMattress = 'airMattress',
  bunkBed = 'bunkBed',
  couch = 'couch',
  crib = 'crib',
  doubleBed = 'doubleBed',
  floorMattress = 'floorMattress',
  futonBed = 'futonBed',
  hammockBed = 'hammockBed',
  kingBed = 'kingBed',
  queenBed = 'queenBed',
  singleBed = 'singleBed',
  sofaBed = 'sofaBed',
  toddlerBed = 'toddlerBed',
  waterBed = 'waterBed',
}

export enum RoomType {
  BEDROOM = 'BEDROOM',
  COMMON_SPACES = 'COMMON_SPACES',
  LIVING_ROOM = 'LIVING_ROOM',
}

export interface PropertyRoom {
  uid: string;
  roomNumber: number;
  bedCounts: Partial<Record<BedType, number>>;
  hasPrivateBathroom?: boolean;
  roomType: RoomType;
}

export interface GetPropertyRoomsResponseTO extends BaseResponse {
  rooms: PropertyRoom[];
}
