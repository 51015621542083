import { Property } from './Properties';

export interface GetPropertyRentalConditionsResponse {
  rentalConditions: RentalConditions;
}

export interface RentalConditions {
  propertyUid: Property['uid'];
  rentalConditions?: string;
  rentalConditionsPdfUrl?: string;
}

export enum RentalConditionResourceType {
  AGENCY = 'agency',
  HOTEL = 'hotel',
  PROPERTY = 'property',
}
