import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { isDeepEqual } from 'utils/objectUtils';
import { differenceWith } from 'utils/arrayUtils';
import { BaseOption, ReactMultiSelectProps } from './MultiSelectField.types';
import {
  SELECT_ALL_OPTION_VALUE,
  isNotSelectAll,
  isSelectAll,
} from './MultiSelectField.constants';

interface UseHandleSelectAllOptionArgs {
  name: string;
  options: BaseOption[];
  fixedOptions?: BaseOption[];
}

function useHandleSelectAllOption({
  name,
  options,
  fixedOptions,
}: UseHandleSelectAllOptionArgs) {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const currentSelected: BaseOption[] | undefined = useWatch({ name }) ?? [];

  const selectAllOption = {
    label: t('form.multiSelectField.selectAll'),
    value: SELECT_ALL_OPTION_VALUE,
  };

  const handleOnChangeWithSelectAll: ReactMultiSelectProps['onChange'] = (
    newValue: BaseOption[],
  ) => {
    const addedPills = differenceWith(newValue, currentSelected, isDeepEqual);
    const removedPills = differenceWith(currentSelected, newValue, isDeepEqual);
    const isAddingSelectAll = !!addedPills.find(isSelectAll);
    const isRemovingSelectAll = !!removedPills.find(isSelectAll);
    const currentlyHasSelectAll = !!currentSelected.find(isSelectAll);
    const isIndividuallySelectingEachOption =
      options.length && isDeepEqual(options, newValue);

    if (isRemovingSelectAll) {
      if (fixedOptions) {
        setValue(name, fixedOptions);
        return;
      }
      setValue(name, []);
    } else if (isAddingSelectAll || isIndividuallySelectingEachOption) {
      setValue(name, [selectAllOption, ...options]);
    } else if (currentlyHasSelectAll && removedPills.length > 0) {
      setValue(name, newValue.filter(isNotSelectAll));
    } else {
      setValue(name, newValue);
    }
  };

  return { selectAllOption, handleOnChangeWithSelectAll };
}

export default useHandleSelectAllOption;
