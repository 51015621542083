import { useWatch } from 'react-hook-form';
import { classNames } from 'utils/classNameUtils';
import Tooltip from 'components/tooltip/Tooltip';
import { Glyphicon } from 'react-bootstrap';
import { TextFieldCharacterCounterContainer } from './TextFieldCharacterCounter.styles';

const TextFieldCharacterCounter = ({
  name,
  maxCharacters,
  maxCharactersDescription,
}: {
  name: string;
  maxCharacters: number;
  maxCharactersDescription?: string;
}) => {
  const currentValue = useWatch({ name });
  const currentCount = currentValue?.length || 0;

  return (
    <TextFieldCharacterCounterContainer data-testid="character-counter">
      <span
        className={classNames({
          'text-danger': currentCount > maxCharacters,
        })}
      >
        {currentCount}
      </span>
      {` / ${maxCharacters}`}

      {maxCharactersDescription && (
        <Tooltip
          id="text-field-max-ch-description"
          text={maxCharactersDescription}
        >
          <Glyphicon glyph="info-sign" />
        </Tooltip>
      )}
    </TextFieldCharacterCounterContainer>
  );
};

TextFieldCharacterCounter.defaultProps = {
  maxCharactersDescription: undefined,
};

export default TextFieldCharacterCounter;
