import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import API from 'services/API';
import useAppEvent from 'hooks/useAppEvent';
import useAppModal from 'hooks/useAppModal';
import useNotify from 'hooks/useNotify';
import useAppMutation from 'hooks/useAppMutation';
import { AppEventType } from 'models/Events';
import { extractErrorMessage } from '../../../../utils/axiosExceptionUtils';
import { normalizeKey } from '../../../../utils/localeUtils';
import { PricingPeriodsUpdatePayload } from './PropertyPricingModal.types';
import PropertyPricingModalContext from './PropertyPricingModalContext';

const useUpdatePropertiesPricing = () => {
  const { modalId } = useContext(PropertyPricingModalContext);
  const { publish } = useAppEvent();
  const { closeModal } = useAppModal();
  const { notifyError } = useNotify();
  const { t } = useTranslation();
  const { mutate: updatePropertiesPricing, isLoading: isUpdating } =
    useAppMutation(
      (payload: PricingPeriodsUpdatePayload) => {
        return API.post('api/internal/pricing-periods/updates', payload);
      },
      {
        onSuccess: ({ data }) => {
          const { updatedPricingPeriods } = data;
          publish(AppEventType.PROPERTY_PRICING_PERIODS_UPDATED, {
            updatedPricingPeriods,
          });
          closeModal(modalId);
        },
        onError: (err) => {
          const errorMessage = extractErrorMessage(err);
          const messageToDisplay = t(normalizeKey(errorMessage));
          notifyError(messageToDisplay);
        },
      },
    );

  return { isUpdating, updatePropertiesPricing };
};

export default useUpdatePropertiesPricing;
