import { OwnerAdjustment } from 'models/ownerAdjustments/OwnerAdjustment';
import { OwnerAdjustmentSchedule } from 'models/ownerAdjustments/OwnerAdjustmentSchedule';
import {
  ADJUSTMENT_TYPES,
  AdjustmentType,
} from './modal/OwnerAdjustmentModal.types';

export const getAdjustmentType = (
  adjustment: OwnerAdjustment | OwnerAdjustmentSchedule,
): AdjustmentType => {
  return 'dateAccountedFor' in adjustment
    ? ADJUSTMENT_TYPES.ONE_TIME
    : ADJUSTMENT_TYPES.RECURRING;
};
