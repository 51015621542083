import { UseMutationOptions } from '@tanstack/react-query';
import useAppMutation from 'hooks/useAppMutation';
import { PricingPlan, PricingPlanTerm } from 'models/PricingPlan';
import API from 'services/API';

interface UpgradePricingPlanArgs {
  currentPlan?: PricingPlan | null;
  nextPlan: PricingPlan;
}

interface UpgradePricingPlanResponse {
  type: string;
  term: PricingPlanTerm;
}

type ReactQueryMutationOptions = Omit<
  UseMutationOptions<
    UpgradePricingPlanResponse,
    unknown,
    UpgradePricingPlanArgs
  >,
  'mutationFn'
>;

const upgradePricingPlan = async ({
  currentPlan,
  nextPlan,
}: UpgradePricingPlanArgs): Promise<UpgradePricingPlanResponse> => {
  const currentTerm = currentPlan?.term;
  const nextTerm = nextPlan?.term;
  const planType = nextPlan?.type;
  const isTermUpgrade = currentTerm && nextTerm && currentTerm !== nextTerm;
  const type = isTermUpgrade ? 'term' : 'tier';

  return API.post(
    `/api/internal/pricing-plan/${type}/upgrade?planType=${planType}`,
  );
};

const useUpgradePricingPlanMutation = ({
  ...options
}: ReactQueryMutationOptions = {}) => {
  return useAppMutation({
    mutationFn: upgradePricingPlan,
    ...options,
  });
};

export default useUpgradePricingPlanMutation;
