import * as Yup from 'yup';
import {
  PROPERTIES_SORTING_STRATEGY,
  PROPERTY_STATUS,
  PropertiesSortingStrategy,
} from 'models/Properties';
import { PropertiesFilterOptions } from 'models/PropertiesFilter';
import { SearchFieldProperty } from 'components/forms/domain/property/PropertySearchField/PropertySearchField.types';

export const PropertiesFilterSchema = Yup.object({
  amenities: Yup.array().of(
    Yup.object({
      isCustom: Yup.boolean(),
      label: Yup.string(),
      value: Yup.string(),
    }),
  ),
  bedrooms: Yup.array().of(Yup.number()).length(2),
  guests: Yup.array().of(Yup.number()).length(2),
  location: Yup.string(),
  sortBy: Yup.string(),
  status: Yup.string(),
  tags: Yup.array().of(
    Yup.object({ label: Yup.string(), value: Yup.string() }),
  ),
  type: Yup.string(),
});

export type PropertiesFilterSchemaType = Omit<
  Yup.InferType<typeof PropertiesFilterSchema>,
  'bedrooms' | 'guests' | 'sortBy'
> & {
  bedrooms: [number, number];
  guests: [number, number];
  sortBy: PropertiesSortingStrategy;
};

export const getFormDefaultValues = (
  serverFilterOptions: Partial<PropertiesFilterOptions>,
): PropertiesFilterSchemaType & { propertySearch: SearchFieldProperty[] } => ({
  amenities: [],
  bedrooms: [serverFilterOptions.minBedrooms, serverFilterOptions.maxBedrooms],
  guests: [serverFilterOptions.minGuests, serverFilterOptions.maxGuests],
  location: '',
  sortBy: PROPERTIES_SORTING_STRATEGY.SORT_BY_NAME,
  status: PROPERTY_STATUS.ACTIVE,
  tags: [],
  type: '',
  propertySearch: [],
});
