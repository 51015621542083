import * as Yup from 'yup';

const manageThirdPartyAgencySchema = (isEditing: boolean) => ({
  firstName: Yup.string().trim().nullable(),
  lastName: Yup.string().trim().nullable(),
  ...(!isEditing && {
    email: Yup.string().email().required(),
    password: Yup.string().min(8).required(),
  }),
  phoneNumber: Yup.string()
    .trim()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    )
    .transform((cur, ori) => (ori === '' ? null : cur))
    .nullable(),
  bussinessName: Yup.string().trim().nullable(),
  websiteUrl: Yup.string().trim().nullable(),
  active: Yup.boolean().nullable(),
  commissionRate: Yup.number().integer().max(100).min(0).nullable(),
});

export default manageThirdPartyAgencySchema;
