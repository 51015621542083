import styled, { css } from 'styled-components';

export const DayCellLeadContainer = styled.div.attrs({
  className: 'day-cell-lead-container',
})<{
  isCheckInDay: boolean;
  isCheckOutDay: boolean;
  $background: string;
  $opacity: number;
  $width: number;
  $zIndex: number;
}>`
  position: absolute;
  left: -1px;
  background: ${({ $background }) => $background};
  opacity: ${({ $opacity }) => $opacity};
  width: calc(100% + 1px);
  height: 30px;
  z-index: ${({ $zIndex }) => $zIndex};

  ${({ isCheckInDay, $width }) =>
    isCheckInDay &&
    css`
      border-radius: 5px 0 0 5px;
      left: 63%;
      width: ${$width * 0.37}px;
    `}

  ${({ isCheckOutDay, $width }) =>
    isCheckOutDay &&
    css`
      border-radius: 0 5px 5px 0;
      width: ${$width * 0.18}px;
    `}
`;
