import { UseMutationOptions } from '@tanstack/react-query';
import useAppMutation from 'hooks/useAppMutation';
import {
  PropertyExpectationsBulkUpdatePayload,
  PropertyExpectationsBulkUpdateResponseTO,
} from 'models/PropertyExpectations';
import API from 'services/API';

type BulkUpdatePropertyExpectationsMutationOptions = Omit<
  UseMutationOptions<
    PropertyExpectationsBulkUpdateResponseTO,
    unknown,
    PropertyExpectationsBulkUpdatePayload
  >,
  'mutationFn'
>;

const useBulkUpdatePropertyExpectations = (
  options: BulkUpdatePropertyExpectationsMutationOptions = {},
) => {
  return useAppMutation({
    mutationFn: async (
      payload: PropertyExpectationsBulkUpdatePayload,
    ): Promise<PropertyExpectationsBulkUpdateResponseTO> => {
      return API.post('/api/internal/property-expectations/bulk-save', payload);
    },
    ...options,
  });
};

export default useBulkUpdatePropertyExpectations;
