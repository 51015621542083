import styled from 'styled-components';

export const BulkSaveTitleStyled = styled.span`
  font-weight: bold;
`;

export const BulkSavePropertiesModalStyled = styled.div`
  section {
    display: flex;
    flex-direction: column;
  }
`;

export const BulkSavePropertiesModalFooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
