import { FC } from 'react';
import { useWatch } from 'react-hook-form';
import defaultAvatar from 'assets/images/placeholder-profile2.png';
import SelectField from '../../selectField/SelectField';
import { Employee } from '../../../../models/Employees';
import { EmployeeLabelAvatar } from './EmployeeSelectField.styles';
import { EmployeeSelectFieldProps } from './EmployeeSelectField.types';

function getEmployeeAvatar(employeeUid: string, employees: Employee[]) {
  if (!employeeUid || !employees) {
    return '\u00a0'; // there must be some whitespace character rendered in order to keep the alignment of the dropdown
  }

  const { pictureUrl } = employees.find(({ uid }) => employeeUid === uid) || {};

  return <EmployeeLabelAvatar alt="" src={pictureUrl || defaultAvatar} />;
}

const EmployeeSelectField: FC<EmployeeSelectFieldProps> = ({
  avatarAsLabel,
  employees,
  emptyValueLabel,
  name,
  label,
  ...rest
}) => {
  const currentValue = useWatch({ name });

  if (!employees) {
    return null;
  }

  const labelToRender = avatarAsLabel
    ? getEmployeeAvatar(currentValue, employees)
    : label;

  const options = [
    ...(emptyValueLabel
      ? [
          {
            label: emptyValueLabel,
            value: '',
          },
        ]
      : []),
    ...(employees || []).map(({ bestProfile, uid }) => ({
      label: bestProfile,
      value: uid,
    })),
  ];

  return (
    <SelectField
      label={labelToRender}
      name={name}
      options={options}
      {...rest}
    />
  );
};

export default EmployeeSelectField;
