import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import { Container } from './Info.styles';

const Info = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const redirectToHome = () => {
    navigate('/login');
  };

  return (
    <Container>
      <div>
        <h1>{t('pageNotFound.weAreSorry')}</h1>

        <p>{t('pageNotFound.pleaseGoBack')}</p>

        <Button bigRounded onClick={redirectToHome}>
          {t('pageNotFound.goBack')}
        </Button>
      </div>
    </Container>
  );
};

export default Info;
