import { useTranslation } from 'react-i18next';
import { useFetchPropertiesFilterOptions } from 'components/domain/property/useFetchPropertiesFilterOptions';
import MultiSelectField, {
  MultiSelectFieldProps,
} from 'components/forms/multiSelectField/MultiSelectField';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { PropertiesFilterOptions } from 'models/PropertiesFilter';

interface TagsMultiSelectFieldProps
  extends Omit<MultiSelectFieldProps, 'fieldRenderer' | 'options'> {
  filterOptions?: PropertiesFilterOptions;
}

const TagsMultiSelectField = ({
  filterOptions: filterOptionsProp,
  ...rest
}: TagsMultiSelectFieldProps) => {
  const { t } = useTranslation();
  const { data, isLoading } = useFetchPropertiesFilterOptions({
    enabled: !filterOptionsProp,
  });
  const filterOptions = filterOptionsProp ?? data;
  const tags = filterOptions?.propertyTagNames
    .sort((tag1, tag2) => tag1.localeCompare(tag2))
    .map((tag) => ({
      label: tag,
      value: tag,
    }));

  if (isLoading) return <LoadingPlaceholder />;

  return (
    <MultiSelectField
      colSmInput={8}
      colSmLabel={4}
      label={t('pageCalendar.stacked.tags')}
      name="tags"
      options={tags}
      {...rest}
    />
  );
};

TagsMultiSelectField.defaultProps = {
  filterOptions: undefined,
};

export default TagsMultiSelectField;
