import { useTranslation } from 'react-i18next';
import SelectField from 'components/forms/selectField/SelectField';
import PropertyFormFieldLabel from 'pages/property/common/PropertyFormFieldLabel';
import { range } from 'utils/arrayUtils';
import useFormatNumber from 'hooks/useFormatNumber';

const BathroomsField = () => {
  const { t } = useTranslation();
  const formatNumber = useFormatNumber();

  const valueToOption = (value: number) => ({
    label: formatNumber({ value }),
    value,
  });

  const bathroomsOptions = [
    valueToOption(0),
    ...range(1, 10)
      .flatMap((n) => [n, n + 0.5])
      .map(valueToOption),
    ...range(10, 30 + 1).map(valueToOption),
  ];

  return (
    <SelectField
      label={
        <PropertyFormFieldLabel name="capacityDetails.bathroomCount">
          {t('pageProperty.mainSettings.capacityDetails.bathroomCount')}
        </PropertyFormFieldLabel>
      }
      name="capacityDetails.bathroomCount"
      options={bathroomsOptions}
      colSmInput={7}
      colSmLabel={5}
      rightTooltip={t(
        'pageProperty.mainSettings.capacityDetails.bathroomCountTooltip',
      )}
    />
  );
};

export default BathroomsField;
