import styled from 'styled-components';
import mediaQuery from '../../styles/mediaQuery';

export const IframeLoaderWrapper = styled.div.attrs({ className: 'container' })`
  position: absolute;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
  height: 10px;
  padding-left: 48px;
  top 48px;
  
  > .loader-container {
    height: 10px;
    justify-content: flex-start;
  }
  
  ${mediaQuery.xs} {
    top: 25px;

    > .loader-container {
      justify-content: center;
    }
  }
`;
