import { AxiosError } from 'axios';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import API from 'services/API';

const useResendMFACode = () => {
  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useNotify();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const mfaCode = searchParams.get('mfaCode');

  const { mutate: resendCode } = useAppMutation(
    () => {
      return API.post('/mfa-resend-code', {
        mfaCode,
      });
    },
    {
      onSuccess: (response) => {
        if (
          response.data?.status === 'MFA_CHALLENGE' &&
          response.data?.mfaCode
        ) {
          notifySuccess(t('pageLogin.codeResentSuccess'));
          setSearchParams((s) => {
            s.set('mfaCode', response.data.mfaCode);
            return s;
          });
        }
      },
      onError: (error: AxiosError) => {
        notifyError(t('pageLogin.codeResentError'));
        if (error.response?.status === 400) {
          navigate('/login');
        }
      },
    },
  );

  return { resendCode };
};

export default useResendMFACode;
