import { useFetchPropertiesFilterOptions } from 'components/domain/property/useFetchPropertiesFilterOptions';
import useAppQuery from 'hooks/useAppQuery';
import useAppUser from 'hooks/useAppUser';
import { GetAllEmployeesResponseTO } from 'models/Employees';
import API from 'services/API';

const useStackedCalendarInit = () => {
  const { agencyUid, isOwner } = useAppUser();

  const { data: filterOptions, isLoading: isOptionsLoading } =
    useFetchPropertiesFilterOptions({ enabled: !isOwner });

  const { data: employees, isLoading: isEmployeesLoading } = useAppQuery(
    ['employees', agencyUid],
    async () => {
      const response = await API.get<GetAllEmployeesResponseTO>(
        `/v3/employees?agencyUid=${agencyUid}`,
      );
      return response.data.employees;
    },
    {
      enabled: !isOwner,
      staleTime: 6000, // 6 seconds
    },
  );

  return {
    filterOptions,
    employees,
    isInitializing: isOptionsLoading || isEmployeesLoading,
  };
};

export default useStackedCalendarInit;
