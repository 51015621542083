import { useEffect } from 'react';
import TextField from 'components/forms/textField/TextField';
import { useWatch } from 'react-hook-form';

const ExchangeTokenForm = ({ clearErrors }: { clearErrors: () => void }) => {
  const { redirectUri } = useWatch();

  useEffect(() => {
    clearErrors();
  }, [redirectUri]);

  return <TextField colSmInput={12} name="redirectUri" />;
};

export default ExchangeTokenForm;
