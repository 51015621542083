import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';

export const Container = styled.section.attrs({
  className: 'container',
})`
  --refer-font-weight: 300;
  position: relative;
`;

export const Description = styled.p`
  font-weight: var(--refer-font-weight);
`;

export const Steps = styled(Row)`
  padding: 40px 0 40px;
`;

export const Step = styled(Col)`
  align-items: center;
  display: flex;
  font-size: 16px;
  flex-direction: column;
  margin-top: 20px;

  svg {
    margin-bottom: 20px;
  }

  span:nth-of-type(1) {
    font-weight: 500;
  }

  span:nth-of-type(2) {
    font-weight: var(--refer-font-weight);
    text-align: center;
  }
`;

export const CreateReferralWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0;
`;

export const CreateReferral = styled.a`
  font-weight: var(--refer-font-weight);
  text-align: center;
`;

export const Details = styled.ol`
  font-weight: var(--refer-font-weight);
`;

export const DetailsItem = styled.li``;
