import styled from 'styled-components';

export const HelperTextMuted = styled.p.attrs({
  className: 'text-muted text-center',
})`
  margin-bottom: 1em;
`;

export const TypeStartingEnding = styled.h3.attrs({
  'data-testid': 'type-starting-ending',
  className: 'text-center',
})`
  margin-bottom: 1.5em;
`;

export const ResendCode = styled.p.attrs({
  className: 'text-muted text-center',
  'data-testid': 'resend-code',
})`
  margin-top: 1em;

  span {
    text-decoration: underline;
    cursor: pointer;
  }
`;
