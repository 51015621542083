import { DeepPartial } from 'react-hook-form';
import * as Yup from 'yup';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';

export const propertySettingsBulkSaveFormSchema = () =>
  Yup.object({
    appliesTo: Yup.string().required(),
    appliesToProperties: Yup.array()
      .of(
        Yup.object({
          isFixed: Yup.boolean().optional(),
          label: Yup.string(),
          value: Yup.string(),
        }),
      )
      .label('properties')
      .when('appliesTo', {
        is: PROPERTIES_SELECTION.ALL_PROPERTIES,
        then: (schema) => schema.optional().nullable(true),
        otherwise: (schema) => schema.min(1).required(),
      }),
  });

export type PropertySettingsBulkSaveFormValues = DeepPartial<
  Yup.InferType<ReturnType<typeof propertySettingsBulkSaveFormSchema>>
>;
