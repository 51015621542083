import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { CollapseSectionStyled } from 'pages/property/common/Common.styles';

export const BookingSettingsCollapseStyled = styled(CollapseSectionStyled)``;

export const OpenSetMinStayContainer = styled(Col)`
  margin-bottom: 10px;
  margin-top: -15px;

  button {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const SetMinSayModalFieldsetStyled = styled.fieldset`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const SetMinSayModalFooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
