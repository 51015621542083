import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import defaultImage from 'assets/images/property/littlehouse.png';
import { Property } from 'models/Properties';
import SelectField from '../../selectField/SelectField';
import {
  getPropertyByUid,
  getPropertyOptions,
} from '../../../../utils/property/propertyUtils';
import { PropertySelectFieldProps } from './PropertySelectField.types';
import { PropertyLabelPicture } from './PropertySelectField.styles';

function getPropertyPicture(propertyUid: string, properties: Property[]) {
  if (!propertyUid || !properties) {
    return '\u00a0'; // there must be some whitespace character rendered in order to keep the alignment of the dropdown
  }

  const { mainPicture } = getPropertyByUid(properties, propertyUid) || {};

  return (
    <PropertyLabelPicture
      alt=""
      src={mainPicture?.largeThumbnail || defaultImage}
    />
  );
}

const PropertySelectField: FC<PropertySelectFieldProps> = ({
  emptyValueLabel,
  name,
  label,
  pictureAsLabel,
  properties,
  enableUnitTypes = true,
  enableHotels = true,
  showUnits = true,
  showInactiveProperties = true,
  shouldShowUnitDefault,
  ...rest
}) => {
  const { watch, setValue } = useFormContext();
  const currentValue = watch(name);
  const unitUid = watch('unitUid');

  const labelToRender = pictureAsLabel
    ? getPropertyPicture(currentValue, properties)
    : label;

  useEffect(() => {
    if (shouldShowUnitDefault) {
      setValue(name, unitUid || currentValue);
    }
  }, []);

  const options = [
    ...(emptyValueLabel
      ? [
          {
            label: emptyValueLabel,
            value: '',
          },
        ]
      : []),
    ...(properties || []).reduce(
      (acc, property) => [
        ...acc,
        ...getPropertyOptions({
          property,
          enableUnitTypes,
          enableHotels,
          showInactiveProperties,
          showUnits,
        }),
      ],
      [],
    ),
  ];

  return (
    <SelectField
      label={labelToRender}
      name={name}
      options={options}
      {...rest}
    />
  );
};

export default PropertySelectField;
