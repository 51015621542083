export enum PinCodeType {
  REMOTELOCK = 'REMOTELOCK',
  IGLOOHOME = 'IGLOOHOME',
  VIRTUALKEY = 'VIRTUALKEY',
  POINTCENTRAL = 'POINTCENTRAL',
  OTHER = 'OTHER',
}

export interface PinCodeLead {
  pinCode: string;
  pinCodeUrl: string;
  uid: string;
}

export interface PinCode {
  leadUid: string;
  propertyUid: string;
  unitUid: string;
  lockType: PinCodeType;
  pinCode: string;
  pinCodeUrl: string;
  startDateTimeUTC: string;
  endDateTimeUTC: string;
  uid: string;
}

export interface GetPinCodesResponseTO {
  pincodes: PinCode[];
}
