import styled from 'styled-components';
import CommonTable from 'components/table/CommonTable';
import { Discount } from 'models/Discounts';
import mediaQuery from 'styles/mediaQuery';

export const DiscountsTableStyled = styled(CommonTable<Discount>)`
  .column-actions {
    width: 80px;
  }

  .column-amount {
    width: 150px;
  }

  .column-status {
    width: 90px;
  }

  .column-expirationDate {
    width: 110px;
  }

  .column-validFromDate {
    width: 155px;
  }

  .column-property {
    width: 120px;
  }

  .column-singleUse {
    width: 100px;
  }

  ${mediaQuery.md} {
    .column-amount {
      width: 100px;
    }

    .column-status {
      width: 60px;
    }

    .column-expirationDate {
      width: 90px;
    }

    .column-validFromDate {
      width: 155px;
    }

    .column-property {
      width: 100px;
    }

    .column-singleUse {
      width: 50px;
    }
  }
`;
