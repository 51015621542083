import { FC } from 'react';
import { GenericFormFieldProps } from '../genericFormField/GenericFormField.types';
import TextField from '../textField/TextField';

interface Props
  extends Omit<GenericFormFieldProps, 'componentClass' | 'fieldRenderer'> {
  maxCharacters?: number;
  trimOnBlur?: boolean;
}

const TextAreaField: FC<Props> = (props: Props) => {
  return <TextField {...props} componentClass="textarea" />;
};

TextAreaField.defaultProps = {
  maxCharacters: undefined,
  trimOnBlur: false,
};

export default TextAreaField;
