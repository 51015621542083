import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';
import theme from 'styles/theme';
import FormWithProvider from '../../../forms/form/Form';

export const LeadModalForm = styled(FormWithProvider).attrs({
  noValidate: true,
})`
  .order-view-price-details-list-item-wrapper {
    padding: 10px 0;
    margin: 0 7px;
  }

  .order-view-price-details-list-item-row-wrapper {
    padding: 5px 7px;
  }
`;

export const LeadRebookParagraph = styled.p`
  text-align: right;
  cursor: pointer;
  color: ${theme.colors.primary};
  margin-bottom: -30px;

  ${mediaQuery.sm} {
    margin-bottom: 0;
  }
`;
