import styled from 'styled-components';

export const FieldDescriptionsStyled = styled.section`
  ul {
    padding-left: 30px;
  }

  li {
    list-style-type: circle;
    margin: 10px 0;
  }
`;

export const FieldDescriptionStyled = styled.span`
  font-weight: bold;
`;
