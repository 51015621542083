import { useEffect, useRef } from 'react';
import useAppDispatch from 'hooks/useAppDispatch';
import { useNavigate } from 'react-router-dom';
import API from 'services/API';
import { requestedPathKey } from 'services/API.constants';
import { setAuth } from 'store/slices/auth';
import { cleanUser } from 'store/slices/user';
import useAppConfig from '../../hooks/useAppConfig';
import { setRequestedPath } from '../../store/slices/login';
import Loader from '../../components/loader/Loader';

function getRequestedPath() {
  const requestedPath = sessionStorage.getItem(requestedPathKey);
  sessionStorage.removeItem(requestedPathKey);

  // it should never have '/logout' value, but we must be sure not to redirect there
  if (!requestedPath || requestedPath.includes('/logout')) {
    return null;
  }

  const pathname = requestedPath.replace(/^#/, '');
  const paramIndex = pathname.indexOf('?');

  if (paramIndex !== -1) {
    return {
      hash: '',
      pathname: pathname.substring(0, paramIndex),
      search: pathname.substring(paramIndex),
    };
  }

  return {
    hash: '',
    pathname,
    search: '',
  };
}

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    customSettings: { logoutRedirectUrl },
  } = useAppConfig();
  const isLogoutInProgress = useRef(false);

  useEffect(() => {
    if (isLogoutInProgress.current) {
      return;
    }

    isLogoutInProgress.current = true;

    API.post('/web/logout')
      .then(() => {
        dispatch(setAuth({ isAuthenticated: false }));
        dispatch(cleanUser());
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');

        const requestedPath = getRequestedPath();

        if (logoutRedirectUrl) {
          window.location.href = logoutRedirectUrl;
        } else {
          if (requestedPath) {
            dispatch(setRequestedPath({ requestedPath }));
          }

          navigate('/login');
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        navigate('/dashboard');
      });
  }, []);

  return <Loader fullPage />;
};

export default Logout;
