import { useEffect } from 'react';
import useAppModal from 'hooks/useAppModal';
import MultiplesJobsModal, {
  JobWithService,
} from 'pages/stackedCalendar/multiplesJobModal/MultiplesJobsModal';
import { useTranslation } from 'react-i18next';
import useFetchServices from 'pages/stackedCalendar/useFetchServices';
import JobModal from 'pages/stackedCalendar/jobModal/JobModal';
import { JobLeadParams } from 'models/Jobs';
import useAppEvent from 'hooks/useAppEvent';
import { AppEventType } from 'models/Events';
import { JobsMap } from 'pages/stackedCalendar/Calendar.types';
import { Property } from 'models/Properties';
import useFetchAllProperties from 'components/domain/property/useFetchAllProperties';
import {
  propertyCalendarConditionGraphQLQuery,
  propertyCalendarGraphQLQuery,
} from 'pages/stackedCalendar/StackedCalendar.utils';

interface CreateJobModal {
  propertyUid: string;
  jobDate: Date;
}

interface UseOpenJobModal {
  jobsMap?: JobsMap;
  properties?: Property[];
}

const useOpenJobModal = ({ jobsMap, properties }: UseOpenJobModal = {}) => {
  const { t } = useTranslation();
  const { openModal } = useAppModal();
  const { services } = useFetchServices();
  const { subscribe, unsubscribe } = useAppEvent();
  const { allProperties } = useFetchAllProperties({
    enabled: !properties?.length,
    conditions: propertyCalendarConditionGraphQLQuery,
    fields: propertyCalendarGraphQLQuery,
  });

  const jobsProperties = properties?.length ? properties : allProperties;

  const createJobModal = (leadParams: JobLeadParams) => {
    const JobModalId = 'job-modal';

    return openModal({
      customContent: (
        <JobModal
          modalId={JobModalId}
          properties={jobsProperties}
          services={services}
          leadParams={leadParams}
        />
      ),
      id: JobModalId,
    });
  };

  const updateJobModal = (jobsUids: string[]) => {
    const multiplesJobsModalId = 'multiples-jobs-modal';
    const JobModalId = 'job-modal';

    const currentsJobs = jobsUids.map((jobUid) => jobsMap[jobUid]);

    const currentJobsWithServiceName = currentsJobs.map((currentJob) => {
      return {
        ...currentJob,
        serviceTitle: services?.find(
          (service) => service?.uid === currentJob?.serviceUid,
        )?.title,
      };
    });

    const openEditJobModal = (job: JobWithService) => {
      return openModal({
        customContent: (
          <JobModal
            modalId={JobModalId}
            job={job}
            properties={jobsProperties}
            services={services}
          />
        ),
        id: JobModalId,
      });
    };

    if (jobsUids.length === 1) {
      openEditJobModal(currentJobsWithServiceName[0]);
      return;
    }

    const openJob = (selectedJob: JobWithService) => {
      openEditJobModal(selectedJob);
    };

    openModal({
      customContent: (
        <MultiplesJobsModal
          modalId={multiplesJobsModalId}
          jobs={currentJobsWithServiceName}
          onConfirm={openJob}
        />
      ),
      id: multiplesJobsModalId,
      title: t('pageCalendar.stacked.selectAJob'),
    });
  };

  useEffect(() => {
    const openJobModalListener = ({ detail }: CustomEvent<CreateJobModal>) => {
      createJobModal(detail);
    };

    subscribe(AppEventType.OPEN_CREATE_JOB_MODAL, openJobModalListener);
    return () => {
      unsubscribe(AppEventType.OPEN_CREATE_JOB_MODAL, openJobModalListener);
    };
  }, [createJobModal]);

  return { createJobModal, updateJobModal };
};

export default useOpenJobModal;
