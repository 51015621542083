import { HasFeeItem, HasOrderItems } from 'models/hasOrderItems/HasOrderItems';
import FormWithProvider from 'components/forms/form/Form';
import useOrderViewFetchedData from '../useOrderViewFetchedData';
import OrderViewPriceDetailsInner from './OrderViewPriceDetailsInner';

const OrderViewPriceDetailsList = () => {
  const { lead } = useOrderViewFetchedData();

  const order: HasOrderItems = {
    ...lead.order,
    fees: {
      otherFees: lead.order.fees.otherFees as unknown as HasFeeItem[],
      cleaningFee: {
        ...(lead.order.fees.cleaningFee as unknown as HasFeeItem),
      },
    },
  };

  return (
    <FormWithProvider onSubmit={() => {}}>
      <OrderViewPriceDetailsInner
        lead={lead}
        order={order}
        showAmountPaidRow
        showBalanceRow
      />
    </FormWithProvider>
  );
};

export default OrderViewPriceDetailsList;
