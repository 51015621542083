import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { TextNode } from 'lexical';
import { AutoLinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableRowNode } from '@lexical/table';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import LexicalClickableLinkPlugin from '@lexical/react/LexicalClickableLinkPlugin';
import {
  ContentEditableStyled,
  RichTextEditorContainer,
  RichTextEditorInnerContainer,
  RichTextEditorPlaceholder,
} from './RichTextEditor.styles';
import SetContentsPlugin from './plugins/SetContentsPlugin';
import ControlsPlugin from './plugins/ControlsPlugin';
import { RichTextEditorProps } from './RichTextEditor.types';
import ClearEditorPlugin from './plugins/ClearEditorPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import { DivNode } from './plugins/nodes/DivNode';
import { ExtendedTextNode } from './plugins/nodes/ExtendedTextNode';
import { ImageNode } from './plugins/nodes/ImageNode';
import { TableStyledNode } from './plugins/nodes/TableStyledNode';
import { TableCellStyledNode } from './plugins/nodes/TableCellStyledNode';
import { LinkStyledNode } from './plugins/nodes/LinkStyledNode';

const editorConfig: InitialConfigType = {
  namespace: 'hostfullyRichTextEditor',
  nodes: [
    AutoLinkNode,
    DivNode,
    ImageNode,
    HeadingNode,
    LinkStyledNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    ExtendedTextNode,
    {
      replace: TextNode,
      // eslint-disable-next-line no-underscore-dangle
      with: (node: TextNode) => new ExtendedTextNode(node.__text, node.__key),
    },
    TableCellStyledNode,
    TableRowNode,
    TableStyledNode,
  ],
  onError: (error) => {
    throw error;
  },
  editable: true,
};

const RichTextEditor = ({
  id,
  contents,
  placeholderText,
  controlsSettings,
  customFooter,
}: RichTextEditorProps) => {
  const onChange = (editorState, editor) => {
    editor.update(() => {
      // TODO implement when required
    });
  };

  const placeholder = placeholderText && (
    <RichTextEditorPlaceholder>{placeholderText}</RichTextEditorPlaceholder>
  );

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <RichTextEditorContainer id={id}>
        {/* TODO toolbars here */}
        <RichTextEditorInnerContainer>
          <RichTextPlugin
            contentEditable={
              <ContentEditableStyled id={`${id}-editable-area`} />
            }
            placeholder={placeholder}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <AutoLinkPlugin />
          {controlsSettings.clearEditorButton && (
            <ClearEditorPlugin setSuggestion={controlsSettings.setSuggestion} />
          )}
          <ControlsPlugin controlsSettings={controlsSettings} />
          <HistoryPlugin />
          <OnChangePlugin onChange={onChange} />
          <SetContentsPlugin contents={contents} />
          <LexicalClickableLinkPlugin newTab />
        </RichTextEditorInnerContainer>
      </RichTextEditorContainer>
      {customFooter}
    </LexicalComposer>
  );
};

RichTextEditor.defaultProps = {
  initialContent: undefined,
  placeholderText: '',
};

export default RichTextEditor;
