import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';
import theme from 'styles/theme';

export const Container = styled.div.attrs({
  className: 'container',
})``;

export const WarningMessage = styled.p`
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: ${theme.colors.fontDark};
  font-weight: 300;
`;

export const TableWrapper = styled.div`
  th,
  td {
    font-size: 14px;
    color: ${theme.colors.fontDark};
    font-weight: 300;
  }

  tr {
    pointer-events: none;

    a {
      pointer-events: all;
    }
  }

  ${mediaQuery.xs} {
    .hide-on-mobile {
      display: none;
    }

    tr {
      cursor: pointer;
      pointer-events: all;
    }
  }
`;
