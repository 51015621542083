import { memo, useContext, useLayoutEffect, useRef } from 'react';
import { ScrollSync } from 'react-scroll-sync';
import Loader from 'components/loader/Loader';
import useAppSelector from 'hooks/useAppSelector';
import useAppUser from 'hooks/useAppUser';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { debounce } from '../../utils/funcs';
import useScreenBreakpoints from '../../hooks/useScreenBreakpoints';
import {
  StackedCalendarInnerWrapperStyled,
  StackedCalendarWrapperStyled,
  WarningMessageLink,
  WarningMessageParagraph,
} from './StackedCalendar.styles';
import PropertiesList from './propertiesList/PropertiesList';
import CalendarContext from './CalendarContext';
import useCalendarLoadedPropertiesNumber from './propertiesList/useCalendarLoadedPropertiesNumber';
import StackedCalendarBody from './body/StackedCalendarBody';
import StackedCalendarNavigation from './navigation/StackedCalendarNavigation';
import { stackedCalendarStyleConstants } from './Calendar.constants';
import StackedCalendarHeader from './header/StackedCalendarHeader';
import StackedCalendarFilters from './filters/StackedCalendarFilters';
import StackedCalendarFiltersFormProvider from './filters/StackedCalendarFiltersFormProvider';
import useStackedCalendarVisibleProperties from './useStackedCalendarVisibleProperties';
import InfiniteScroll from './InfiniteScroll';

const StackedCalendarWrapper = memo(
  ({ previousPropertiesLength }: { previousPropertiesLength: number }) => {
    const { isOwner } = useAppUser();
    const {
      setBodyWidth,
      setFilter,
      totalPropertiesNumber,
      selectedProperty,
      allProperties,
      filter,
      setSelectedProperty,
    } = useContext(CalendarContext);
    const isLoadingProperties = useAppSelector(
      (state) => state.stackedCalendar.isLoadingProperties,
    );
    const nextPropertiesOffset = useAppSelector(
      (state) => state.stackedCalendar.nextPropertiesOffset,
    );
    const visibleProperties = useStackedCalendarVisibleProperties();

    const loadedPropertiesNumber = useCalendarLoadedPropertiesNumber();
    const calendarBodyWrapperRef = useRef<HTMLDivElement>();
    const { isXS } = useScreenBreakpoints();
    const navigate = useNavigate();

    useLayoutEffect(() => {
      const updateBodyWidth = () => {
        if (calendarBodyWrapperRef.current) {
          const leftPanelWidth = isXS
            ? stackedCalendarStyleConstants.leftPanelWidthMobile
            : stackedCalendarStyleConstants.leftPanelWidth;
          setBodyWidth(
            calendarBodyWrapperRef.current.offsetWidth - leftPanelWidth,
          );
        }
      };
      const resizeHandler = debounce(updateBodyWidth);

      updateBodyWidth();

      window.addEventListener('resize', resizeHandler);

      return () => {
        window.removeEventListener('resize', resizeHandler);
      };
    }, [calendarBodyWrapperRef.current]);

    const hasMoreProperties =
      totalPropertiesNumber > loadedPropertiesNumber &&
      previousPropertiesLength === filter.limit;

    const loadMoreProperties = () => {
      setFilter((currentFilter) => ({
        ...currentFilter,
        offset: nextPropertiesOffset,
      }));
    };

    const loader = visibleProperties.length ? <Loader /> : null;

    if (totalPropertiesNumber === 1) {
      setSelectedProperty(allProperties[0].uid);
    }

    if (selectedProperty) {
      return null;
    }

    const navigateToProperties = () => navigate('/properties');
    const shouldShowCalendar =
      isLoadingProperties || visibleProperties.length > 0;

    return (
      <>
        <StackedCalendarFiltersFormProvider>
          <StackedCalendarHeader />
          {!isOwner && <StackedCalendarFilters />}
        </StackedCalendarFiltersFormProvider>
        {shouldShowCalendar ? (
          <StackedCalendarWrapperStyled data-testid="stacked-calendar-wrapper">
            <InfiniteScroll
              load={loadMoreProperties}
              hasMore={hasMoreProperties}
              loader={loader}
            >
              <ScrollSync horizontal>
                <>
                  <StackedCalendarNavigation />
                  <StackedCalendarInnerWrapperStyled
                    ref={calendarBodyWrapperRef}
                  >
                    <PropertiesList />
                    <StackedCalendarBody />
                  </StackedCalendarInnerWrapperStyled>
                </>
              </ScrollSync>
            </InfiniteScroll>
          </StackedCalendarWrapperStyled>
        ) : (
          <WarningMessageParagraph>
            <Trans i18nKey="pageCalendar.stacked.noPropertyResult">
              No results found. Please try selecting different filters or update
              your properties
              <WarningMessageLink onClick={navigateToProperties}>
                here
              </WarningMessageLink>
              .
            </Trans>
          </WarningMessageParagraph>
        )}
      </>
    );
  },
);

export default StackedCalendarWrapper;
