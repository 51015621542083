import PageHeader from 'components/pageHeader/PageHeader';
import { useTranslation } from 'react-i18next';
import useAppUser from 'hooks/useAppUser';
import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';
import { GetStatementsResponseTO } from 'models/Statements';
import { Container, TableWrapper, WarningMessage } from './Statements.styles';

const Statements = () => {
  const { t } = useTranslation();
  const { userUid } = useAppUser();

  const { data: ownerStatements } = useAppQuery(
    ['getOwnerStatements'],
    async () => {
      const response = await API.get<GetStatementsResponseTO>(
        `v3/statements/${userUid}`,
      );
      return response.data.ownerStatement.statements;
    },
    { enabled: true },
  );

  const handleStatementMobileClick = (statementUrl: string) => {
    window.open(statementUrl, '_blank');
  };

  return (
    <Container data-testid="owner-statements-test-id">
      <PageHeader title={t('pageStatements.statements')} />

      <TableWrapper>
        <table id="ownerStatementTable" className="table table-striped">
          <thead>
            <tr>
              <th>{t('pageStatements.title')}</th>
              <th className="hide-on-mobile">{t('pageStatements.action')}</th>
            </tr>
          </thead>
          <tbody>
            {ownerStatements?.map(({ title, url }) => (
              <tr
                onClick={() => handleStatementMobileClick(url)}
                id="statement"
                key={`${title}-${url}`}
              >
                <td>{title}</td>
                <td className="hide-on-mobile">
                  <a target="_blank" rel="noreferrer" href={url}>
                    {t('pageStatements.viewStatement')}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableWrapper>

      {!ownerStatements?.length && (
        <WarningMessage>{t('pageStatements.noReviewMessage')}</WarningMessage>
      )}
    </Container>
  );
};

export default Statements;
