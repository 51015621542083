import { useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../button/Button';
import useAppUser from '../../../../../hooks/useAppUser';
import { canOpenMailerOnSave } from '../../../../../utils/lead/leadUtils';
import Tooltip from '../../../../tooltip/Tooltip';

const LeadModalFooterSubmitButtons = ({
  isPropertyUnavailable,
}: {
  isPropertyUnavailable: boolean;
}) => {
  const { isEmployeeAssociate } = useAppUser();
  const { isSubmitting } = useFormState();
  const { t } = useTranslation();
  const { source, status, uid: leadUid } = useWatch();

  const submitButton = (
    <Button
      bsStyle="primary"
      data-testid="button-submit"
      disabled={isEmployeeAssociate || isSubmitting || isPropertyUnavailable}
      id="submit"
      type="submit"
    >
      {isSubmitting
        ? t('common.saving')
        : t(`common.${leadUid ? 'save' : 'create'}`)}
    </Button>
  );
  const submitAndOpenMailerButton = (
    <Button
      bsStyle="primary"
      data-testid="button-submit-open-mailer"
      disabled={isEmployeeAssociate || isSubmitting || isPropertyUnavailable}
      id="submit-open-mailer"
      type="submit"
    >
      {isSubmitting
        ? t('common.saving')
        : t(
            `componentLead.modal.form.${
              leadUid ? 'save' : 'create'
            }AndOpenMailer`,
          )}
    </Button>
  );

  if (isEmployeeAssociate) {
    return (
      <>
        <Tooltip
          id="disabled-save-lead-button-tooltip"
          text={t('common.permissions.actionNotAllowedForAccount')}
        >
          {submitButton}
        </Tooltip>
        {canOpenMailerOnSave({ source, status }) && (
          <Tooltip
            id="disabled-save-lead-open-mailer-button-tooltip"
            text={t('common.permissions.actionNotAllowedForAccount')}
          >
            {submitAndOpenMailerButton}
          </Tooltip>
        )}
      </>
    );
  }

  return (
    <>
      {submitButton}
      {canOpenMailerOnSave({ source, status }) && submitAndOpenMailerButton}
    </>
  );
};

export default LeadModalFooterSubmitButtons;
