import * as Yup from 'yup';

const addDiscountSchema = () => ({
  name: Yup.string()
    .required()
    .matches(/[a-zA-Z0-9]+/),
  property: Yup.string(),
  type: Yup.string().required(),
  amount: Yup.number().required(),
  expirationDate: Yup.mixed().required(),
  validFromDate: Yup.mixed().required(),
  validToDate: Yup.mixed().required(),
  singleUse: Yup.boolean(),
});

export default addDiscountSchema;
