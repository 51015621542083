import Button from 'components/button/Button';
import { Channel } from 'models/Channels';
import { useTranslation } from 'react-i18next';
import useImportBookingDotComPropertiesModalInit from '../useImportBookingDotComPropertiesModalInit';

const FooterModalNoConnection = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { channelSettings } = useImportBookingDotComPropertiesModalInit();

  const isBookingDotComActive = !!channelSettings?.find(
    ({ enumId }) => enumId === Channel.booking_com,
  )?.isActive;

  return (
    <Button
      data-testid="modal-save-button"
      className="btn btn-primary"
      href={
        isBookingDotComActive ? '#/channels/bookingdotcom/sync' : '#/channels'
      }
      onClick={onClose}
    >
      {t(
        'pageProperties.importBookingDotComPropertiesModal.goToBookingDotComChannelPageSubmit',
      )}
    </Button>
  );
};

export default FooterModalNoConnection;
