import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div.attrs({
  'data-testid': 'app-page-content-footer',
  id: 'webapp-footer',
})`
  background-color: rgb(243, 243, 247);
  padding: 20px 100px 20px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mediaQuery.xs} {
    flex-direction: column;
    padding: 20px 30px;
    text-align: center;

    .copyright {
      margin-bottom: 6px;
    }
  }
`;

export const Link = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer',
})`
  color: #000;
  text-decoration: none;
  cursor: pointer;
`;
