import { parseISO } from 'date-fns';
import { Job } from 'models/Jobs';

export function getJobStartDate(job: Job | undefined): Date | null {
  if (!job) {
    return null;
  }

  if (!job.startDateObj) {
    // object might be frozen when coming from redux (eg modal data)
    if (Object.isExtensible(job)) {
      job.startDateObj = parseISO(job.startLocalDateTime);
    } else {
      return parseISO(job.startLocalDateTime);
    }
  }

  return job.startDateObj;
}

export function getJobEndDate(job: Job | undefined): Date | null {
  if (!job) {
    return null;
  }

  if (!job.endDateObj) {
    // object might be frozen when coming from redux (eg modal data)
    if (Object.isExtensible(job)) {
      job.endDateObj = parseISO(job.endLocalDateTime);
    } else {
      return parseISO(job.endLocalDateTime);
    }
  }

  return job.endDateObj;
}
