import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import loadBackground from 'assets/images/calendar/load-bg.jpeg';
import { StackedCalendarMode } from '../Calendar.types';
import { LoadingDayCellStyles } from './days/LoadingDayCell.styles';
import { DayCellStyles } from './days/DayCell.styles';
import { LeadViewStyles } from './leads/LeadView.styles';

export const StackedCalendarBodyStyled = styled(Col).attrs(
  ({ $mode }: { $mode: StackedCalendarMode }) => ({
    className: `stacked-calendar-body-mode-${$mode.toLowerCase()}`,
  }),
)<{
  $mode: StackedCalendarMode;
}>`
  background: url(${loadBackground}) repeat;
  overflow: hidden;
  width: 100%;

  ${({ $mode }) =>
    $mode === StackedCalendarMode.PRICING &&
    `
      .day-cell-job,
      .day-cell-lead-container {
        opacity: 0.5;
      }
  `}

  .stacked-calendar-body-container {
    background-color: #fff;
    display: flex;
  }

  .stacked-calendar-body-container-scrollable {
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;
    transform: translate3d(0, 0, 0);
    width: 100%;
  }

  .stacked-calendar-body-row {
    display: flex;
    flex: 1 1 auto;
  }

  ${LoadingDayCellStyles}

  ${DayCellStyles}
  
  ${LeadViewStyles}
`;

export const getSelectionStyle = ($propertyUid, isPricingMode) =>
  !!$propertyUid &&
  !isPricingMode &&
  `
  div.body-day-cell:not([data-property-uid='${$propertyUid}']):before {
    content: '';
    background-color: rgb(221 221 221 / 50%);
    height: 40px;
    width: 100%;
    position: absolute;
    left: 0;
  }
`;
