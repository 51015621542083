import FilterContainer from 'components/filters/FilterContainer';
import usePipelineInit from '../usePipelineInit';
import PipelineFiltersForm from './PipelineFiltersForm';
import PipelineFilterFormWatcher from './PipelineFilterFormWatcher';

const PipelineFilters = ({ isFilterOpen }: { isFilterOpen: boolean }) => {
  const { isLoading } = usePipelineInit();

  if (isLoading) {
    return null;
  }

  return (
    <FilterContainer
      className="pipeline-filter-container"
      isCollapsed={!isFilterOpen}
    >
      <PipelineFilterFormWatcher />
      <PipelineFiltersForm />
    </FilterContainer>
  );
};

export default PipelineFilters;
