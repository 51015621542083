import { useContext, useMemo } from 'react';
import { PropertyBusinessType } from 'models/Properties';
import { getPropertyUidFromStringType } from 'utils/property/propertyUtils';
import CalendarContext from '../CalendarContext';
import useStackedCalendarVisibleProperties from '../useStackedCalendarVisibleProperties';

const useCalendarLoadedPropertiesNumber = () => {
  const { propertiesMap } = useContext(CalendarContext);
  const visibleProperties = useStackedCalendarVisibleProperties();

  const totalCount = useMemo(() => {
    let count = 0;

    visibleProperties.forEach((propertyUid) => {
      const { propertyUid: uid } = getPropertyUidFromStringType(propertyUid);
      const property = propertiesMap[uid];

      // Only units, standalone and subunits should be counted
      if (
        property.businessType !== PropertyBusinessType.HOTEL &&
        property.businessType !== PropertyBusinessType.UNIT_TYPE
      ) {
        count += 1;
      }

      // specifically handle Hotel type properties
      if (
        property.businessType === PropertyBusinessType.HOTEL &&
        property.unitTypes
      ) {
        property.unitTypes.forEach((unitType) => {
          unitType.units?.forEach((unit) => {
            if (!visibleProperties.includes(`${unit.uid}[UNIT]`)) {
              count += 1;
            }
          });
        });
      }

      if (
        property.businessType === PropertyBusinessType.STANDALONE_PROPERTY &&
        property.subUnits
      ) {
        property.subUnits.forEach((subUnit) => {
          if (!visibleProperties.includes(`${subUnit.uid}[SUB_UNIT]`)) {
            count += 1;
          }
        });
      }
    });

    return count;
  }, [propertiesMap, visibleProperties]);

  return totalCount;
};

export default useCalendarLoadedPropertiesNumber;
