import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { UploadFieldListItemProps } from '../UploadField.types';
import { ImagePreviewStyled } from '../UploadField.styles';

const ImagePreviewListItem = <T,>({
  file,
  isDisabled,
  isLoading,
  getUrl,
}: UploadFieldListItemProps<T>) => {
  return (
    <>
      {isLoading && <LoadingPlaceholder width="150px" linesCount={8.9} />}
      {!isLoading && (
        <ImagePreviewStyled
          src={getUrl(file)}
          alt=""
          aria-disabled={isDisabled}
        />
      )}
    </>
  );
};

export default ImagePreviewListItem;
