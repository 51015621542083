import { useTranslation } from 'react-i18next';
import SelectField from 'components/forms/selectField/SelectField';
import { PropertyExpectationType } from 'models/PropertyExpectations';

const PropertyExpectationAddEditModalTypeField = () => {
  const { t } = useTranslation();

  const options = Object.values(PropertyExpectationType).map((value) => ({
    label: t(
      `pageProperty.feesTaxesAndPolicies.propertyExpectations.expectationType.${value}`,
    ),
    value,
  }));

  return (
    <SelectField
      label={t(
        'pageProperty.feesTaxesAndPolicies.propertyExpectations.modal.type',
      )}
      name="propertyExpectation.expectationType"
      options={options}
      colSmInput={8}
      colSmLabel={4}
      required
    />
  );
};

export default PropertyExpectationAddEditModalTypeField;
