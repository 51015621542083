import { useContext } from 'react';
import { Button, Col } from 'react-bootstrap';
import { Loader } from 'react-bootstrap-typeahead';
import {
  canAddNewLineItem,
  canForceFullPayment,
  isLeadImported,
  isLeadEditable,
} from 'utils/lead/leadUtils';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import { useTranslation } from 'react-i18next';
import { Lead, LeadSource, LeadType } from 'models/Leads';
import { useFormContext, useWatch } from 'react-hook-form';
import { QuoteFee, LeadQuoteFeeOverride, FeeAmountType } from 'models/Quote';
import useFeatures from 'hooks/useFeatures';
import OrderViewPriceDetailsInner from 'pages/orderView/priceDetails/OrderViewPriceDetailsInner';
import LeadModalContext from '../../../LeadModalContext';
import useQuoteData from '../../../useQuoteData';
import useWatchQuoteData from '../../../useWatchQuoteData';
import Rent from './fields/Rent';
import Cleaning from './fields/Cleaning';
import Fees from './fields/Fees';
import Services from './fields/Services';
import SecurityDeposit from './fields/SecurityDeposit';
import Total from './fields/Total';
import {
  LeadModalDetailsOrderWrapper,
  TableQuote,
} from './LeadModalDetailsOrder.styles';
import OrderPropertyFees from './OrderPropertyFees';
import AdjustmentItems from './fields/AdjustmentItems';

const LeadModalDetailsOrder = () => {
  const { quote, lead, setQuote } = useContext(LeadModalContext);
  const { t } = useTranslation();
  const { isLoading: isQuoteLoading } = useQuoteData(); // v1
  const { isNewQuoteLoading } = useWatchQuoteData(); // v2
  const { setValue, getValues } = useFormContext();

  const {
    uid: leadUid,
    quote: v2Order,
    checkInDate,
    checkOutDate,
    adultCount,
    childrenCount,
    propertyUid,
  } = useWatch();

  const { allowNewLeadModalWebapp } = useFeatures();

  if (
    (!allowNewLeadModalWebapp && !quote) ||
    (allowNewLeadModalWebapp && !v2Order)
  ) {
    return <Loader />;
  }

  const updateMode = !!leadUid;

  const isEditable = isLeadEditable(lead);
  const isImported = isLeadImported(lead);

  const isAirbnbInquiry =
    lead?.source === LeadSource.DIRECT_AIRBNB &&
    lead?.leadType === LeadType.INQUIRY;

  const addNewLineItem = () => {
    const otherFeesKey = allowNewLeadModalWebapp
      ? 'quote.fees.otherFees'
      : 'overrides.fees.otherFees';

    const otherFees = getValues(otherFeesKey);

    if (allowNewLeadModalWebapp) {
      otherFees.push({
        name: `New item ${otherFees.length}`,
        amountType: FeeAmountType.AMOUNT,
        netPrice: 0,
        taxationRate: 0,
        enabled: true,
        removed: false,
        amount: 0,
        feeId: null,
        feeUid: null,
      } as LeadQuoteFeeOverride);

      setValue(otherFeesKey, otherFees, {
        shouldDirty: true,
      });
    } else {
      setQuote({
        ...quote,
        fees: {
          ...quote.fees,
          otherFees: [
            ...quote.fees.otherFees,
            {
              name: `New item ${otherFees.length}`,
              amountType: 'AMOUNT',
              isEditable: true,
              netPrice: 0,
              taxationRate: 0,
              taxAmount: 0,
              grossPrice: 0,
            } as QuoteFee,
          ],
        },
      });

      otherFees.push({
        name: `New item ${otherFees.length}`,
        amountType: FeeAmountType.AMOUNT,
        netPrice: 0,
        taxationRate: 0,
        enabled: true,
        removed: false,
        amount: 0,
        feeId: null,
        feeUid: null,
      } as LeadQuoteFeeOverride);

      setValue(otherFeesKey, otherFees);
    }
  };

  return (
    <LeadModalDetailsOrderWrapper data-testid="lead-order">
      <div>
        {updateMode && canForceFullPayment(lead) && (
          <Col xs={7} data-testid="force-payment">
            <CheckboxField
              name={
                allowNewLeadModalWebapp
                  ? 'quote.force100PercentPaymentAtReservation'
                  : 'force100PercentPaymentAtReservation'
              }
            >
              {t('componentOrder.forcePaymentAtReservation')}
            </CheckboxField>
          </Col>
        )}

        <Col
          xsOffset={!updateMode || (lead && !canForceFullPayment(lead)) ? 7 : 0}
          xs={5}
        >
          {isEditable && canAddNewLineItem(lead) && (
            <div className="text-right">
              <Button
                onClick={addNewLineItem}
                data-testid="add-new-item-button"
                className="btn btn-link"
              >
                <span
                  style={{ top: 0, fontSize: 'x-small' }}
                  className="glyphicon glyphicon-plus"
                />{' '}
                {t('componentOrder.addLineItem')}
              </Button>
            </div>
          )}
        </Col>
      </div>

      <br />

      <Col xs={12}>
        {!allowNewLeadModalWebapp && (
          <TableQuote className="table" $isQuoteLoading={isQuoteLoading}>
            <tbody>
              {quote.rent && (
                <Rent isEditable={isEditable} isAPayout={isImported} />
              )}

              {!isImported && quote.fees?.cleaningFee && !isAirbnbInquiry && (
                <Cleaning isEditable={isEditable} />
              )}

              {!isImported && !!quote.fees?.otherFees.length && (
                <Fees isEditable={isEditable} />
              )}

              {!isImported && !!quote.services?.length && <Services />}

              {!isImported &&
                !!quote.securityDeposit &&
                quote.includeSecurityDepositInTotal && (
                  <SecurityDeposit isEditable={isEditable} />
                )}

              {!isImported && !!quote.adjustmentItems?.length && (
                <AdjustmentItems />
              )}

              {!!quote.totalPrice && <Total isAPayout={isImported} />}

              {!isImported &&
                !!quote.securityDeposit &&
                !quote.includeSecurityDepositInTotal && (
                  <SecurityDeposit isEditable={isEditable} />
                )}
            </tbody>
          </TableQuote>
        )}

        {allowNewLeadModalWebapp && (
          <OrderViewPriceDetailsInner
            isLoading={isNewQuoteLoading}
            showTitle={false}
            showAmountPaidRow={false}
            showBalanceRow={false}
            handleOrderAdjustment={false}
            lead={
              {
                ...lead,
                checkInDate: checkInDate || lead?.checkInDate,
                checkOutDate: checkOutDate || lead?.checkOutDate,
              } ||
              ({
                property: { uid: propertyUid },
                checkInDate,
                checkOutDate,
                adultCount,
                childrenCount,
              } as Lead)
            }
            allowEdit={isEditable}
            order={v2Order}
          />
        )}

        {!isImported &&
          (quote || v2Order) &&
          (!updateMode ||
            lead.source === LeadSource.ORBIRENTAL_FORM ||
            lead.source === LeadSource.ORBIRENTAL_WIDGET ||
            lead.source === LeadSource.ORBIRENTAL_API) && (
            <OrderPropertyFees isEditable={isEditable} order={v2Order} />
          )}

        {quote?.adjustments.find((a) => !a.isPaid) && (
          <div className="form-group">
            <div className="alert alert-warning" role="alert">
              <strong>Warning!</strong> Your order has changed
            </div>
          </div>
        )}
      </Col>
    </LeadModalDetailsOrderWrapper>
  );
};

export default LeadModalDetailsOrder;
