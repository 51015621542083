import Button from 'components/button/Button';
import { CalendarType } from 'models/Calendar';
import { useTranslation } from 'react-i18next';

interface CalendarTodayButtonProps {
  type: CalendarType;
}

const CalendarTodayButton = ({ type }: CalendarTodayButtonProps) => {
  const { t } = useTranslation();

  const dispatchScrollEvent = (date: Date) => {
    const event = new CustomEvent('scrollToDateEvent', {
      detail: { date },
    });
    window.dispatchEvent(event);
  };

  const dispatchSetTodayOnStandaloneFilters = () => {
    const event = new CustomEvent('setTodayOnStandaloneFilters');
    window.dispatchEvent(event);
  };

  const handleClick = async () => {
    if (type === CalendarType.STACKED_CALENDAR) {
      dispatchScrollEvent(new Date());
    }

    if (type === CalendarType.STANDALONE_CALENDAR) {
      dispatchSetTodayOnStandaloneFilters();
    }
  };

  return (
    <Button onClick={handleClick} data-testid="calendar-today-button">
      {t('common.today')}
    </Button>
  );
};

export default CalendarTodayButton;
