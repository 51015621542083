import { Row } from '@tanstack/react-table';
import useAppUser from 'hooks/useAppUser';
import useFormatCurrency from 'hooks/useFormatCurrency';
import { FeeAmountTypeEnum, PropertyInternalFee } from 'models/PropertyFees';

const AmountCell = ({
  row,
  getValue,
}: {
  row: Row<PropertyInternalFee>;
  getValue: () => number;
}) => {
  const {
    agency: { currency },
  } = useAppUser();
  const formatCurrency = useFormatCurrency();
  const value = getValue();

  return row.original.amountType === FeeAmountTypeEnum.TAX
    ? `${value}%`
    : formatCurrency({ currency, value });
};

export default AmountCell;
