import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const DashboardAnalytics = styled(Row)`
  color: #737373;
  select {
    min-width: 80px;
  }

  .dashboard-analytics-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .form-group {
      margin-bottom: 0;
    }
    a {
      color: #737373;
      text-decoration: underline;
    }
  }
`;

export const BottomSection = styled.div`
  max-width: 1200px;
  margin: 20px auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${mediaQuery.sm} {
    padding: 30px;

    ul {
      margin-top: 30px;
    }
  }
`;

export const TabsWrapper = styled.div<{ $isAnalyticsHidden: boolean }>`
  ${(props) => !props.$isAnalyticsHidden && `margin-top: 40px`};
  position: relative;

  .checkbox {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 30px;
    font-size: 16px;
    color: #737373;
  }

  ${mediaQuery.xs} {
    width: 100%;

    .checkbox {
      top: 40px;
    }
  }
`;

export const InfoIconWrapper = styled.div`
  position: absolute;
  top: 13px;
  right: 10px;
  color: #495057;

  ${mediaQuery.xs} {
    top: 53px;
  }
`;

export const ChartsWrapper = styled.div`
  margin-top: 60px;
`;

export const HideCheckboxWrapper = styled.div``;
