import AirbnbIcon from 'assets/images/icons/channels/airbnb.svg?react';
import BookingIcon from 'assets/images/icons/channels/booking.svg?react';
import GoogleIcon from 'assets/images/icons/channels/google.svg?react';
import HomeToGoIcon from 'assets/images/icons/channels/hometogo.svg?react';
import HostfullyIcon from 'assets/images/icons/channels/hostfully.svg?react';
import HvmiIcon from 'assets/images/icons/channels/hvmi.svg?react';
import TripAdvisorIcon from 'assets/images/icons/channels/tripadvisor.svg?react';
import VrboIcon from 'assets/images/icons/channels/vrbo.svg?react';
import GolightlyIcon from 'assets/images/icons/channels/golightly.svg?react';
import HomadsIcon from 'assets/images/icons/channels/homads.svg?react';
import VacayMyWayIcon from 'assets/images/icons/channels/vacaymyway.svg?react';

import { Channel } from 'models/Channels';

export const channelsToShow = [
  Channel.airbnb,
  Channel.orbirental,
  Channel.booking_com,
  Channel.homeaway,
  Channel.google,
  Channel.hvmi,
  Channel.tripadvisor,
  Channel.hometogo,
  Channel.golightly,
  Channel.vacaymyway,
  Channel.homads,
];

export const ChannelProps = {
  [Channel.airbnb]: {
    enumId: Channel.airbnb,
    name: 'Airbnb',
    Icon: AirbnbIcon,
    agencyChannelLink: '#/channels?_ref=airbnb_ref',
    propertyChannelLink: '#/channels/airbnb/sync',
    isPropertyLinkEditable: false,
  },
  [Channel.booking_com]: {
    enumId: Channel.booking_com,
    name: 'Booking.com',
    Icon: BookingIcon,
    agencyChannelLink: '#/channels?_ref=bookingdotcom_ref',
    propertyChannelLink: '#/channels/bookingdotcom/sync',
    isPropertyLinkEditable: true,
  },
  [Channel.golightly]: {
    enumId: Channel.golightly,
    name: 'Golightly',
    Icon: GolightlyIcon,
    agencyChannelLink: '#/channels?_ref=golightly_ref',
    propertyChannelLink: '#/channels/default/sync?chname=Golightly',
    isPropertyLinkEditable: false,
  },
  [Channel.google]: {
    enumId: Channel.google,
    name: 'Google',
    Icon: GoogleIcon,
    agencyChannelLink: '#/channels?_ref=google_ref',
    propertyChannelLink: '#/l/channels%2Fgoogle%2Fsynch.jsp?chname=Google',
    isPropertyLinkEditable: true,
  },
  [Channel.homads]: {
    enumId: Channel.homads,
    name: 'Homads',
    Icon: HomadsIcon,
    agencyChannelLink: '#/channels?_ref=homads_ref',
    propertyChannelLink: '#/channels/default/sync?chname=Homads',
    isPropertyLinkEditable: false,
  },
  [Channel.homeaway]: {
    enumId: Channel.homeaway,
    name: 'Vrbo',
    Icon: VrboIcon,
    agencyChannelLink: '#/channels?_ref=homeaway_ref',
    propertyChannelLink: '#/channels/vrbo/sync',
    isPropertyLinkEditable: false,
  },
  [Channel.hometogo]: {
    enumId: Channel.hometogo,
    name: 'HomeToGo',
    Icon: HomeToGoIcon,
    agencyChannelLink: '#/channels?_ref=hometogo_ref',
    propertyChannelLink: '#/channels/default/sync?chname=Hometogo',
    isPropertyLinkEditable: false,
  },
  [Channel.hvmi]: {
    enumId: Channel.hvmi,
    name: 'HVMI',
    Icon: HvmiIcon,
    agencyChannelLink: '#/channels?_ref=hvmi_ref',
    propertyChannelLink: '#/channels/default/sync?chname=HVMI',
    isPropertyLinkEditable: false,
  },
  [Channel.orbirental]: {
    enumId: Channel.orbirental,
    name: 'Hostfully',
    Icon: HostfullyIcon,
    agencyChannelLink: '#/channels?_ref=hostfully_ref',
    propertyChannelLink: '#/channels/default/sync?chname=Orbirental',
    isPropertyLinkEditable: false,
  },
  [Channel.tripadvisor]: {
    enumId: Channel.tripadvisor,
    name: 'TripAdvisor',
    Icon: TripAdvisorIcon,
    agencyChannelLink: '#/channels?_ref=tripadvisor_ref',
    propertyChannelLink: '#/channels/default/sync?chname=TripAdvisor',
    isPropertyLinkEditable: true,
  },
  [Channel.vacaymyway]: {
    enumId: Channel.vacaymyway,
    name: 'VacayMyWay',
    Icon: VacayMyWayIcon,
    agencyChannelLink: '#/channels?_ref=vacaymyway_ref',
    propertyChannelLink: '#/channels/default/sync?chname=VacayMyWay',
    isPropertyLinkEditable: false,
  },
};

export const editExternalLinkModalId =
  'property-channel-edit-external-link-modal';
