import useAppQuery from '../../../hooks/useAppQuery';
import API from '../../../services/API';
import { GetAllEmployeesResponseTO } from '../../../models/Employees';
import useAppUser from '../../../hooks/useAppUser';

const useFetchEmployees = (enabled: boolean = true) => {
  const { agencyUid, isEmployee } = useAppUser();

  const { data: employees, isInitialLoading: isFetching } = useAppQuery(
    [`employees-${agencyUid}`, agencyUid],
    async () => {
      const response = await API.get<GetAllEmployeesResponseTO>(
        `/v3/employees?agencyUid=${agencyUid}`,
      );
      return response.data.employees;
    },
    { enabled: enabled && isEmployee },
  );

  return { employees, isFetching };
};

export default useFetchEmployees;
