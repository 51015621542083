import { Message } from '../../../models/inbox/Messages';
import { Template, TemplateType } from '../../../models/Templates';
import { BaseResponse } from '../../../models/_base';
import { ThreadParticipantReadStatus } from '../../../models/inbox/Threads';
import { GetChatSuggestionResponseTO } from './editor/InboxMessageSuggestion.types';

export interface InboxMessagesContextProps {
  hasMoreMessages: boolean;
  isLoading: boolean;
  isTemplateSelectorActive: boolean;
  messages: Message[];
  messagesMetadata: InboxMessagesMetadata;
  suggestion?: GetChatSuggestionResponseTO;
  selectedTemplate?: Template;
  templateSelectorFilter: InboxTemplateSelectorFilter;
  addNewMessages: (_messages: Message[]) => void;
  loadMoreMessages: () => void;
  onMessageSent: (_message: Message) => void;
  setSelectedTemplate: (_template: Template) => void;
  toggleTemplateSelector: () => void;
  updateMessage: (_message: Message) => void;
  updateMessagesMetadata: (_metadata: InboxMessagesMetadata) => void;
  updateTemplateSelectorFilter: (_filter: InboxTemplateSelectorFilter) => void;
  setSuggestion: (_suggestion: GetChatSuggestionResponseTO) => void;
}

export interface InboxMessagesMetadata {
  [uid: string]: {
    isSending: boolean;
    isSendError: boolean;
  };
}

export interface InboxTemplateSelectorFilter {
  templateName?: string;
  templateType?: TemplateType;
}

export enum MessagingActionOrigin {
  UI_INBOX_BACKGROUND_REFRESH = 'UI_INBOX_BACKGROUND_REFRESH',
  UI_INBOX_LOADING = 'UI_INBOX_LOADING',
}

export interface InboxBackgroundRefreshResponseTO extends BaseResponse {
  newMessages: {
    message: Message;
    threadParticipantsReadStatuses: ThreadParticipantReadStatus[];
  }[];
}
