import { useTranslation } from 'react-i18next';
import Tooltip from 'components/tooltip/Tooltip';
import { Transaction } from 'models/Transactions';
import { getTransactionCreatedDate } from 'utils/tests/transaction/transactionUtils';
import {
  TransactionsListItem,
  TransactionsListItemActionsColumn,
  TransactionsListItemAmountColumn,
  TransactionsListItemColumn,
  TransactionsListItemContentWrapper,
  TransactionsListItemDetailsColumn,
} from './OrderViewTransactionsListItem.styles';
import OrderViewTransactionsListItemDetails from './OrderViewTransactionsListItemDetails';
import OrderViewTransactionsListItemAmount from './OrderViewTransactionsListItemAmount';
import OrderViewTransactionsListItemType from './OrderViewTransactionsListItemType';
import OrderViewTransactionsListItemActions from './actions/OrderViewTransactionsListItemActions';

const OrderViewTransactionsListItem = ({
  transaction,
}: {
  transaction: Transaction;
}) => {
  const { t } = useTranslation();
  const { uid } = transaction;

  const transactionFullDateTime = t('common.dateTimeLong', {
    date: getTransactionCreatedDate(transaction),
  });

  return (
    <TransactionsListItem data-testid={`transactions-list-item-${uid}`}>
      <TransactionsListItemContentWrapper>
        <TransactionsListItemColumn data-testid="creation-date" xs={2}>
          <Tooltip
            id={`transaction-date-tooltip-${uid}`}
            text={`${transactionFullDateTime} UTC`}
          >
            {t('common.dateMedium', {
              date: getTransactionCreatedDate(transaction),
            })}
          </Tooltip>
        </TransactionsListItemColumn>
        <TransactionsListItemColumn xs={2}>
          <OrderViewTransactionsListItemType transaction={transaction} />
        </TransactionsListItemColumn>
        <TransactionsListItemDetailsColumn data-testid="details" xs={4}>
          <OrderViewTransactionsListItemDetails transaction={transaction} />
        </TransactionsListItemDetailsColumn>
        <TransactionsListItemAmountColumn xs={1}>
          <OrderViewTransactionsListItemAmount transaction={transaction} />
        </TransactionsListItemAmountColumn>
        <TransactionsListItemActionsColumn data-testid="actions" xs={3}>
          <OrderViewTransactionsListItemActions transaction={transaction} />
        </TransactionsListItemActionsColumn>
      </TransactionsListItemContentWrapper>
    </TransactionsListItem>
  );
};

export default OrderViewTransactionsListItem;
