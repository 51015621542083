import { format } from 'date-fns';
import useAppQuery from '../../../hooks/useAppQuery';
import API from '../../../services/API';
import {
  CheckPropertyAvailabilityResponseTO,
  PropertyAvailability,
} from '../../../models/Properties';
import { BaseErrorResponse } from '../../../models/_base';

const useCheckPropertyAvailability = ({
  enabled,
  propertyUid,
  startDate,
  endDate,
  leadUidsToExclude,
}: {
  enabled: boolean;
  propertyUid: string;
  startDate: Date;
  endDate: Date;
  leadUidsToExclude?: string[];
}) => {
  const leadUids = leadUidsToExclude?.join(',') || "''";

  const {
    data: propertyAvailability,
    error,
    isInitialLoading: isChecking,
    isFetching,
  } = useAppQuery<PropertyAvailability, BaseErrorResponse>(
    ['property-availability-check', propertyUid, startDate, endDate, leadUids],
    async (): Promise<PropertyAvailability> => {
      const response = await API.get<CheckPropertyAvailabilityResponseTO>(
        `/api/internal/property-availability-check/${propertyUid}?startDate=${format(
          startDate,
          'yyyy-MM-dd',
        )}&endDate=${format(
          endDate,
          'yyyy-MM-dd',
        )}&leadsUidsToExclude=${leadUids}`,
      );
      return response.data.propertyAvailability;
    },
    {
      enabled: enabled && !!propertyUid && !!startDate && !!endDate,
      keepPreviousData: true,
      cacheTime: 5000,
      staleTime: 5000,
    },
  );

  return {
    error: error?.response?.data?.apiErrorMessage,
    isChecking,
    isFetching,
    propertyAvailability,
  };
};

export default useCheckPropertyAvailability;
