import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TextField from 'components/forms/textField/TextField';
import useFetchPropertyTypes from 'components/domain/property/useFetchPropertyTypes';
import SelectField from 'components/forms/selectField/SelectField';
import { FormSectionColumn } from 'pages/property/common/Common.styles';
import AreaSizeField from 'components/forms/domain/property/areaSizeField/AreaSizeField';
import PropertyDetailsUploadField from './PropertyDetailsUploadField';
import { PropertyDetailsCollapseStyled } from './PropertyDetails.styles';

const PropertyDetails = () => {
  const { t } = useTranslation();
  const { data: propertyTypes, isLoading: isLoadingPropertyTypes } =
    useFetchPropertyTypes();

  if (isLoadingPropertyTypes) {
    return null;
  }

  const propertyTypeOptions = propertyTypes.map(({ label, type }) => ({
    label,
    value: type,
  }));

  return (
    <PropertyDetailsCollapseStyled
      data-testid="property-details"
      defaultExpanded
      label={t('pageProperty.mainSettings.propertyDetails.sectionName')}
      variant="card"
    >
      <fieldset>
        <Row>
          <FormSectionColumn>
            <SelectField
              label={t(
                'pageProperty.mainSettings.propertyDetails.propertyType',
              )}
              name="propertyDetails.propertyType"
              options={propertyTypeOptions}
              colSmInput={7}
              colSmLabel={5}
              required
            />
            <TextField
              label={t(
                'pageProperty.mainSettings.propertyDetails.propertyName',
              )}
              name="propertyDetails.propertyName"
              colSmInput={7}
              colSmLabel={5}
              rightTooltip={t(
                'pageProperty.mainSettings.propertyDetails.propertyNameTooltip',
              )}
              maxLength={49}
              required
            />
            <PropertyDetailsUploadField />
          </FormSectionColumn>
          <FormSectionColumn>
            <TextField
              label={t('pageProperty.mainSettings.propertyDetails.weblink')}
              name="propertyDetails.weblink"
              colSmInput={7}
              colSmLabel={5}
              rightTooltip={t(
                'pageProperty.mainSettings.propertyDetails.weblinkTooltip',
              )}
            />
            <TextField
              label={t('pageProperty.mainSettings.propertyDetails.floorCount')}
              name="propertyDetails.floorCount"
              type="number"
              min={0}
              colSmInput={7}
              colSmLabel={5}
              rightTooltip={t(
                'pageProperty.mainSettings.propertyDetails.floorCountTooltip',
              )}
            />
            <AreaSizeField
              areaUnitTypeFieldName="propertyDetails.propertySizeUnit"
              label={t(
                'pageProperty.mainSettings.propertyDetails.propertySize',
              )}
              name="propertyDetails.propertySize"
              step="any"
              min={0}
              colSmInput={7}
              colSmLabel={5}
              rightTooltip={t(
                'pageProperty.mainSettings.propertyDetails.propertySizeTooltip',
              )}
              required
            />
          </FormSectionColumn>
        </Row>
      </fieldset>
    </PropertyDetailsCollapseStyled>
  );
};

export default PropertyDetails;
