import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LeadState {
  newLeadsCount: number;
  newLeadsUids: string[];
}

const leadSlice = createSlice({
  name: 'lead',
  initialState: {
    newLeadsCount: 0,
  } as LeadState,
  reducers: {
    setNewLeadsData: (
      state,
      action: PayloadAction<{ newLeadsCount: number; newLeadsUids: string[] }>,
    ) => {
      state.newLeadsCount = action.payload.newLeadsCount;
      state.newLeadsUids = action.payload.newLeadsUids;
    },
  },
});

export const { setNewLeadsData } = leadSlice.actions;

export default leadSlice.reducer;
