import { UseQueryOptions } from '@tanstack/react-query';
import useAppQuery from 'hooks/useAppQuery';
import {
  AirbnbFlow,
  GetAirbnbListingDeactivationFlowTO,
} from 'models/AirbnbFlows';
import API from 'services/API';

export const AIRBNB_LISTING_DEACTIVATION_FLOW_BASE_QUERY_KEY =
  'airbnb-listing-deactivation-flow';

type ReactQueryOptions = Omit<
  UseQueryOptions<AirbnbFlow>,
  'queryKey' | 'queryFn'
>;

type UseFetchAirbnbListingDeactivationFlowArgs = {
  propertyUid: string;
} & ReactQueryOptions;

const useFetchAirbnbListingDeactivationFlow = ({
  propertyUid,
  enabled,
  ...options
}: UseFetchAirbnbListingDeactivationFlowArgs) => {
  return useAppQuery({
    queryKey: [AIRBNB_LISTING_DEACTIVATION_FLOW_BASE_QUERY_KEY],
    queryFn: async () => {
      const response = await API.get<GetAirbnbListingDeactivationFlowTO>(
        `/api/internal/airbnb/flows/listing-deactivation?propertyUid=${propertyUid}`,
      );
      return response.data.flow;
    },
    enabled: enabled !== undefined ? enabled : true,
    staleTime: 30000,
    keepPreviousData: true,
    ...(options ?? {}),
  });
};

export default useFetchAirbnbListingDeactivationFlow;
