import { useCallback } from 'react';
import useAppEvent from 'hooks/useAppEvent';
import useAppModal from 'hooks/useAppModal';
import { AppEventType } from 'models/Events';
import { LeadAvailablePipelineAction } from 'models/Leads';
import { TemplateModalParams, TemplateName } from './TemplateModal.types';

const useTemplateModalParamsProvider = () => {
  const { publish } = useAppEvent();
  const { closeModal } = useAppModal();

  return useCallback(
    ({
      leadUid,
      templateModalId,
      templateName,
    }: {
      leadUid: string;
      templateModalId: string;
      templateName: TemplateName;
    }): TemplateModalParams => {
      if (templateName === TemplateName.QUOTE) {
        return {
          leadUid,
          templateName,
          leadPipelineTemplateAction: LeadAvailablePipelineAction.SEND_QUOTE,
          updateLeadCallback: () => {
            closeModal(templateModalId);
            publish(AppEventType.LEAD_UPDATED, { lead: { uid: leadUid } });
          },
        };
      }

      return { leadUid, templateName };
    },
    [closeModal, publish],
  );
};

export default useTemplateModalParamsProvider;
