import { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FormWithProvider from '../../../../components/forms/form/Form';
import InboxMessagesContext from '../InboxMessagesContext';
import SelectField from '../../../../components/forms/selectField/SelectField';
import TextField from '../../../../components/forms/textField/TextField';
import { debounce } from '../../../../utils/funcs';
import { templatesDisplayOrder } from './InboxMessageTemplates.constants';

const InboxMessageTemplatesFilter = () => {
  const { updateTemplateSelectorFilter } = useContext(InboxMessagesContext);
  const { t } = useTranslation();

  const templateTypeOptions = [
    { label: t('common.all'), value: '' },
    ...templatesDisplayOrder.map((templateType) => ({
      label: t(`pageInbox.messages.templates.templateType_${templateType}`),
      value: templateType,
    })),
  ];

  const onTypeChange = (event) => {
    const templateType = event.target.value;
    updateTemplateSelectorFilter({ templateType });
  };
  const onNameChange = debounce((event) => {
    const templateName = event.target.value;
    updateTemplateSelectorFilter({ templateName });
  });

  return (
    <FormWithProvider
      horizontal
      defaultValues={{}}
      schema={{}}
      onSubmit={() => {}}
    >
      <Row>
        <Col sm={5}>
          <SelectField
            label={t('pageInbox.messages.templates.templateType')}
            labelOnTop
            name="templateType"
            onChange={onTypeChange}
            options={templateTypeOptions}
          />
        </Col>
        <Col sm={6}>
          <TextField
            label={t('form.search')}
            labelOnTop
            placeholder={t('pageInbox.messages.templates.templateName')}
            name="templateName"
            onChange={onNameChange}
          />
        </Col>
      </Row>
    </FormWithProvider>
  );
};

export default InboxMessageTemplatesFilter;
