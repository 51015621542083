import styled from 'styled-components';
import theme from 'styles/theme';

export const OrderViewPriceDetailsListItemTitleWrapper = styled.div`
  color: ${theme.colors.gray500};
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
  padding: 0 0.5em;
`;
