import { Checkbox } from 'react-bootstrap';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { PropertyEmployeeItemPlaceholderStyled } from './PropertyPermissionsModal.styles';

const PropertyPermissionsEmployeeItemPlaceholder = () => {
  return (
    <PropertyEmployeeItemPlaceholderStyled>
      <Checkbox disabled />
      <LoadingPlaceholder width="30px" circle margin="0 8px" />
      <div>
        <div>
          <LoadingPlaceholder width="120px" />
        </div>
        <LoadingPlaceholder width="60px" />
      </div>
    </PropertyEmployeeItemPlaceholderStyled>
  );
};

export default PropertyPermissionsEmployeeItemPlaceholder;
