/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import DateTimePickerField from 'components/forms/dateTimePickerField/DateTimePickerField';
import TextField from 'components/forms/textField/TextField';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { FormSectionColumn } from 'pages/property/common/Common.styles';
import { Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import useIsMobileScreen from 'hooks/useIsMobileScreen';
import { useWatch } from 'react-hook-form';
import {
  DateTimePickerFieldCol,
  PropertyMiscInfoCollapseStyled,
} from './PropertyMiscInfo.styles';

const PropertyMiscInfo = () => {
  const propertyUid = useWatch({ name: 'propertyUid' });
  const isMobileScreen = useIsMobileScreen();
  const { getCopyAddonIcon } = useCopyToClipboard();
  const { t } = useTranslation();

  const rentalLicenseNumberTooltip = (
    <Trans
      i18nKey="pageProperty.mainSettings.miscInfo.rentalLicenseNumberTooltip"
      components={{
        1: (
          <a
            href="https://help.hostfully.com/en/articles/8202150-quick-guide-to-your-property-settings"
            rel="noreferrer"
            target="_blank"
            style={{ textDecoration: 'underline' }}
          />
        ),
      }}
    />
  );

  return (
    <PropertyMiscInfoCollapseStyled
      defaultExpanded
      label={t('pageProperty.mainSettings.miscInfo.title')}
      variant="card"
      data-testid="property-misc-info"
    >
      <Row>
        <FormSectionColumn>
          <TextField
            name="propertyUid"
            label={t('pageProperty.mainSettings.miscInfo.uniqueId')}
            disabled
            rightAddons={[getCopyAddonIcon(propertyUid)]}
            colSmInput={7}
            colSmLabel={5}
          />
          <TextField
            name="miscInfo.externalId"
            label={t('pageProperty.mainSettings.miscInfo.externalId')}
            colSmInput={7}
            colSmLabel={5}
            rightTooltip={t(
              'pageProperty.mainSettings.miscInfo.externalIdTooltip',
            )}
          />
          <TextField
            name="miscInfo.accountingId"
            label={t('pageProperty.mainSettings.miscInfo.accountingId')}
            colSmInput={7}
            colSmLabel={5}
            rightTooltip={t(
              'pageProperty.mainSettings.miscInfo.accountingIdTooltip',
            )}
          />
          <TextField
            name="miscInfo.rentalLicenseNumber"
            label={t('pageProperty.mainSettings.miscInfo.rentalLicenseNumber')}
            colSmInput={7}
            colSmLabel={5}
            rightTooltip={rentalLicenseNumberTooltip}
          />
          <DateTimePickerFieldCol>
            <DateTimePickerField
              name="miscInfo.rentalLicenseExpirationDate"
              label={`${t(
                'pageProperty.mainSettings.miscInfo.rentalLicenseExpirationDate',
              )}:`}
              rightTooltip={t(
                'pageProperty.mainSettings.miscInfo.rentalLicenseExpirationDateTooltip',
              )}
              labelOnTop={!isMobileScreen}
            />
          </DateTimePickerFieldCol>
        </FormSectionColumn>

        <FormSectionColumn>
          <TextField
            name="miscInfo.wifiNetwork"
            label={t('pageProperty.mainSettings.miscInfo.wifiNetwork')}
            colSmInput={7}
            colSmLabel={5}
            rightTooltip={t(
              'pageProperty.mainSettings.miscInfo.valuesOnlyTemplateAirbnbTooltip',
            )}
          />
          <TextField
            name="miscInfo.wifiPassword"
            label={t('pageProperty.mainSettings.miscInfo.wifiPassword')}
            colSmInput={7}
            colSmLabel={5}
            rightTooltip={t(
              'pageProperty.mainSettings.miscInfo.valuesOnlyTemplateAirbnbTooltip',
            )}
          />
        </FormSectionColumn>
      </Row>
    </PropertyMiscInfoCollapseStyled>
  );
};

export default PropertyMiscInfo;
