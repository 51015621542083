import { ChangeEvent } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import GenericFormField from 'components/forms/genericFormField/GenericFormField';
import NumberField from 'components/forms/numberField/NumberField';
import SelectField from 'components/forms/selectField/SelectField';
import { BedType } from 'models/PropertyRooms';
import { RoomsAndBedTypesFormValues } from './RoomsAndBedTypesModal.schema';
import { BedTypesContainer } from './RoomsAndBedTypesFormBedTypes.styles';

const bedTypesOrder = [
  BedType.kingBed,
  BedType.queenBed,
  BedType.doubleBed,
  BedType.singleBed,
  BedType.sofaBed,
  BedType.futonBed,
  BedType.floorMattress,
  BedType.bunkBed,
  BedType.toddlerBed,
  BedType.crib,
  BedType.hammockBed,
  BedType.airMattress,
  BedType.waterBed,
  BedType.couch,
];

const RoomsAndBedTypesFormBedTypes = ({ roomIndex }: { roomIndex: number }) => {
  const {
    fields: beds,
    append,
    remove,
  } = useFieldArray<RoomsAndBedTypesFormValues, `rooms.${number}.beds`>({
    name: `rooms.${roomIndex}.beds`,
  });
  const { setValue } = useFormContext();
  const { t } = useTranslation();

  const bedTypeOptions = [
    {
      label: t('common.selectOption'),
      value: '',
    },
    ...bedTypesOrder
      .filter(
        (bedType) =>
          !beds.some(
            ({ bedType: selectedBedType }) => selectedBedType === bedType,
          ),
      )
      .map((bedType) => ({
        label: t(`componentProperty.rooms.bedType.${bedType}`),
        value: bedType,
      })),
  ];

  const onBedTypeDelete = (bedIndex: number) => {
    remove(bedIndex);
  };

  const onBedTypeSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const bedType = event.target.value as BedType;

    setValue(`rooms.${roomIndex}.bedTypes`, '');

    append({
      bedType,
      count: 1,
    });
  };

  const fieldRenderer = () => (
    <BedTypesContainer>
      {beds.map(({ bedType, id }, index) => (
        <NumberField
          className="bed-type"
          data-testid={`rooms.${roomIndex}.beds.${index}.count`}
          deleteHandler={() => onBedTypeDelete(index)}
          key={id}
          label={t(`componentProperty.rooms.bedType.${bedType}`)}
          name={`rooms.${roomIndex}.beds.${index}.count`}
          min={1}
          colSmLabel={6}
          colSmInput={6}
        />
      ))}
      <SelectField
        className="bed-types-select"
        name={`rooms.${roomIndex}.bedTypes`}
        options={bedTypeOptions}
        onChange={onBedTypeSelect}
        inline
      />
    </BedTypesContainer>
  );

  return (
    <GenericFormField
      fieldRenderer={fieldRenderer}
      label={t(
        'pageProperty.mainSettings.capacityDetails.setRoomsAndBedTypesModal.bedTypes',
      )}
      name={`rooms.${roomIndex}.bedTypes`}
      colSmLabel={5}
      colSmInput={7}
      required
    />
  );
};

export default RoomsAndBedTypesFormBedTypes;
