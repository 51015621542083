import { LeadStatus } from '../../../models/Leads';
import { ThreadFilterSortOrder } from '../Inbox.types';

export const LeadStatusRelevant = 'RELEVANT';

export const ThreadFilterFormLeadStatusesMap = {
  [LeadStatusRelevant]: [LeadStatusRelevant],
  QUOTE: [LeadStatus.NEW, LeadStatus.QUOTE_SENT],
  [LeadStatus.ON_HOLD]: [LeadStatus.ON_HOLD, LeadStatus.HOLD_EXPIRED],
  BOOKED: [
    LeadStatus.BOOKED_BY_AGENT,
    LeadStatus.BOOKED_BY_CUSTOMER,
    LeadStatus.BOOKED_BY_OWNER,
    LeadStatus.PAID_IN_FULL,
    LeadStatus.BOOKED_EXTERNALLY,
  ],
  [LeadStatus.PENDING]: [LeadStatus.PENDING],
  [LeadStatus.DECLINED]: [LeadStatus.DECLINED],
  [LeadStatus.STAY]: [LeadStatus.STAY],
  CLOSED: [LeadStatus.CLOSED_QUOTE, LeadStatus.CLOSED_HOLD],
  [LeadStatus.CANCELLED]: [
    LeadStatus.CANCELLED,
    LeadStatus.IGNORED,
    LeadStatus.CANCELLED_BY_TRAVELER,
    LeadStatus.CANCELLED_BY_OWNER,
  ],
  [LeadStatus.ARCHIVED]: [LeadStatus.ARCHIVED],
};

export const getInboxFilterLocalStorageKey = (userUid: string) =>
  `inbox-user:${userUid}`;

export const inboxFilterFormExpandedStorageKey = 'inbox-filters-expanded';

export const defaultThreadFilter = {
  sortBy: ThreadFilterSortOrder.SORT_BY_RECENT_UPDATE_DATE,
  leadStatuses: [],
  leadChannel: '',
  agentUid: '',
  propertyUid: '',
  showUnreadOnly: false,
};
