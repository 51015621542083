import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const PropertySettingsBulkSaveFieldsRow = styled(Row)`
  > div:not(:first-child) {
    border-left: 1px solid #e5e5e5;
  }
`;

export const CurrentPropertyName = styled.div`
  margin: 10px 0 0 15px;
`;
