import styled from 'styled-components';
import { SourceColor } from 'models/Leads';
import mediaQuery from '../../../../styles/mediaQuery';

export const CurrentBalanceContainer = styled.div.attrs({
  className: 'form-field-container form-group',
})`
  font-weight: bold;

  .control-label {
    text-align: end;
  }

  ${mediaQuery.xs} {
    .control-label {
      text-align: start;
    }
  }
`;

export const CurrentBalanceAmountWrapper = styled.span`
  color: ${SourceColor.DEFAULT};
`;
