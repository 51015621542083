import { Job, JobStatus } from 'models/Jobs';
import Tooltip from 'components/tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { StackedCalendarMode } from 'pages/stackedCalendar/Calendar.types';
import { JobViewWrapper } from './JobView.styles';

interface Props {
  jobs: Job[];
  mode: StackedCalendarMode;
}

const JobView = ({ jobs, mode }: Props) => {
  const { t } = useTranslation();

  if (!jobs?.length) return null;

  const dataJobUids = jobs.map(({ uid }) => uid).join('--\u200c--');
  return (
    <Tooltip
      id={`job_tooltip_${jobs[0].uid}`}
      placement="top"
      text={
        jobs.length > 1
          ? t('pageCalendar.tooltip.jobNumber', { number: jobs.length })
          : t('pageCalendar.tooltip.jobSingle')
      }
    >
      <JobViewWrapper
        $small={mode === StackedCalendarMode.BOOKING}
        data-job-uid={dataJobUids}
        $isDone={jobs && jobs[0].status === JobStatus.DONE}
      />
    </Tooltip>
  );
};

export default JobView;
