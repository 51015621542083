import useAppMutation from 'hooks/useAppMutation';
import useAppUser from 'hooks/useAppUser';
import useNotify from 'hooks/useNotify';
import { useTranslation } from 'react-i18next';
import API from 'services/API';

const useAccountUpdate = () => {
  const { t } = useTranslation();
  const { notifySuccess } = useNotify();
  const { userUid } = useAppUser();

  const {
    mutateAsync: uploadPictureAsync,
    isLoading: isUploadPictureLoading,
    isError: isUploadingPictureError,
  } = useAppMutation(async (payload: any) => {
    return API.post('/v3/files/uploadFile', payload).then(
      (response) => response.data.files[0].uri as string,
    );
  });

  const {
    mutateAsync: updateAccountAsync,
    isLoading: isUpdateAccountLoading,
    isError: isUpdateAccountError,
  } = useAppMutation(
    (payload: any) => {
      return API.put(`api/v3/employees/${userUid}`, payload);
    },
    {
      onSuccess: () => {
        notifySuccess(t('pageAccount.accountUpdatedSuccess'));
      },
    },
  );

  const {
    mutateAsync: updatePhoneAsync,
    isLoading: isUpdatePhoneLoading,
    isError: isUpdatePhoneError,
  } = useAppMutation(
    async (payload: { phoneNumber: string; phoneAreaCode: number }) => {
      const response = await API.post(
        `/api/internal/employees/${userUid}/update-phone-number`,
        payload,
      );

      return response.data;
    },
  );

  return {
    isActionsLoading:
      isUpdateAccountLoading || isUpdatePhoneLoading || isUploadPictureLoading,
    isActionsError:
      isUpdateAccountError || isUpdatePhoneError || isUploadingPictureError,
    updateAccountAsync,
    updatePhoneAsync,
    uploadPictureAsync,
  };
};

export default useAccountUpdate;
