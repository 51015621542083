import { MessagingChannel } from '../../../../models/inbox/Messages';

export const MessagingChannelPriorities: Record<MessagingChannel, number> = {
  [MessagingChannel.AIRBNB]: 3,
  [MessagingChannel.BOOKING_COM]: 3,
  [MessagingChannel.VRBO]: 3,
  [MessagingChannel.EMAIL]: 2,
  [MessagingChannel.SMS]: 1,
  [MessagingChannel.WHATSAPP]: 1,
  [MessagingChannel.HOSTFULLY_PMP]: 0,
  [MessagingChannel.AUTO]: -1,
};
