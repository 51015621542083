import { ReactNode, useContext } from 'react';
import FormWithProvider from 'components/forms/form/Form';
import useAppUser from 'hooks/useAppUser';
import useLocalStorage from 'hooks/useLocalStorage';
import CalendarContext from '../CalendarContext';
import {
  getCalendarFilterLocalStorageKey,
  stackedCalendarPropertiesConstants,
} from '../Calendar.constants';
import useStackedCalendarInit from '../useStackedCalendarInit';
import { defaultValues } from './StackedCalendarFilters.schema';

interface StackedCalendarFiltersFormProviderProps {
  children?: ReactNode;
}

const StackedCalendarFiltersFormProvider = ({
  children,
}: StackedCalendarFiltersFormProviderProps) => {
  const { userUid } = useAppUser();
  const { filterOptions } = useStackedCalendarInit();
  const localStorageKey = getCalendarFilterLocalStorageKey(userUid);
  const { setValue } = useLocalStorage(localStorageKey);
  const { setFilter } = useContext(CalendarContext);

  const dispatchScrollEvent = (date) => {
    const event = new CustomEvent('scrollToDateEvent', {
      detail: { date },
    });

    window.dispatchEvent(event);
  };

  const handleSubmit = async (values: any) => {
    if (values.availableFrom) {
      dispatchScrollEvent(values.availableFrom);
    }

    setFilter({
      ...values,
      offset: 0,
      limit: stackedCalendarPropertiesConstants.defaultLimit,
    });
    setValue(values);
  };

  return (
    <FormWithProvider
      horizontal
      onSubmit={handleSubmit}
      defaultValues={defaultValues(filterOptions)}
      schema={{}}
      key={JSON.stringify(filterOptions)}
    >
      {children}
    </FormWithProvider>
  );
};

StackedCalendarFiltersFormProvider.defaultProps = {
  children: undefined,
};

export default StackedCalendarFiltersFormProvider;
