import CommonTable from 'components/table/CommonTable';
import { OwnerAdjustment } from 'models/ownerAdjustments/OwnerAdjustment';
import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const OneTimeAdjustmentsTableStyled = styled(
  CommonTable<OwnerAdjustment>,
)`
  .column-created {
    width: 120px;
  }

  .column-dateAccountedFor {
    width: 155px;
  }

  .column-amount {
    width: 120px;
  }

  ${mediaQuery.md} {
    .column-created {
      width: 110px;
    }

    .column-dateAccountedFor {
      width: 130px;
    }

    .column-amount {
      width: 100px;
    }
  }
`;
