import { Lead } from 'models/Leads';

export enum LeadActionFailureType {
  CHANNEL_COMMUNICATION_ERROR = 'CHANNEL_COMMUNICATION_ERROR',
  PROPERTY_AVAILABILITY_ISSUE = 'PROPERTY_AVAILABILITY_ISSUE',
  LEAD_STATE_ISSUE = 'LEAD_STATE_ISSUE',
  LEAD_DATA_ERROR = 'LEAD_DATA_ERROR',
  LEAD_PAYMENT_INFO_ISSUE = 'LEAD_PAYMENT_INFO_ISSUE',
  LEAD_PAYMENT_PROCESSING_ERROR = 'LEAD_PAYMENT_PROCESSING_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export enum AirbnbDeclineReason { // must reflect com.orbirental.backend.leads.channelHelpers.airbnb.AirbnbDeclineReasonEnum
  dates_not_available = 'dates_not_available',
  not_a_good_fit = 'not_a_good_fit',
  waiting_for_better_reservation = 'waiting_for_better_reservation',
  not_comfortable = 'not_comfortable',
}

export interface LeadActionResponseTO {
  lead: Lead;
}

export interface LeadActionFormData {}
