import { memo } from 'react';
import useIsMobileScreen from 'hooks/useIsMobileScreen';
import Navigation from './navigation/Navigation';
import MobileNavigation from './mobileNavigation/MobileNavigation';
import useHeaderItems from './useHeaderItems';

const Header = memo(() => {
  const isMobile = useIsMobileScreen();
  const { navItems, dropdownItems, dropdownSecondaryItems } = useHeaderItems();

  return isMobile ? (
    <MobileNavigation
      navItems={navItems}
      dropdownItems={dropdownItems}
      dropdownSecondaryItems={dropdownSecondaryItems}
    />
  ) : (
    <Navigation
      navItems={navItems}
      dropdownItems={dropdownItems}
      dropdownSecondaryItems={dropdownSecondaryItems}
    />
  );
});

export default Header;
