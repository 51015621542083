import { Currency } from 'models/Currency';
import { BaseResponse } from 'models/_base';
import { OwnerAdjustmentStatus, OwnerAdjustmentType } from './OwnerAdjustment';

export const RECURRING_EXPENSE_PERIOD = {
  NONE: 'NONE',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  YEARLY: 'YEARLY',
} as const;

export type RecurringExpensePeriod =
  (typeof RECURRING_EXPENSE_PERIOD)[keyof typeof RECURRING_EXPENSE_PERIOD];

export interface OwnerAdjustmentScheduleResponse {
  uid: string;
  propertyUid: string;
  name: string;
  created: string | null;
  nextScheduled: string;
  endRecurringSchedule: string;
  currency: Currency;
  currencySymbol: string;
  amount: number;
  attachedImageURL: string | null;
  attachedImageName: string | null;
  taxationRate: number;
  status: OwnerAdjustmentStatus;
  recurringExpensePeriod: RecurringExpensePeriod;
  notes: string;
  type: OwnerAdjustmentType | null;
}

export interface OwnerAdjustmentSchedule
  extends Omit<
    OwnerAdjustmentScheduleResponse,
    'created' | 'nextScheduled' | 'endRecurringSchedule'
  > {
  created: Date | null;
  nextScheduled: Date;
  endRecurringSchedule: Date;
}

export interface GetOwnerAdjustmentsSchedulesResponseTO extends BaseResponse {
  ownerAdjustmentSchedules: OwnerAdjustmentScheduleResponse[];
}
