import * as Yup from 'yup';

const blockSchema = () => ({
  property: Yup.string(),
  from: Yup.string(),
  to: Yup.string(),
  notes: Yup.string(),
  currentPropertyUid: Yup.string(),
});
export default blockSchema;
