import { useEffect } from 'react';
import useAppUser from 'hooks/useAppUser';
import { useTranslation } from 'react-i18next';
import { getLanguageCode } from './i18n.utils';

const useUserLanguage = () => {
  const { user, isOwner } = useAppUser();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (isOwner && user) {
      const { preferredLocale } = user;

      if (preferredLocale) {
        i18n.changeLanguage(getLanguageCode(preferredLocale));
      }
    }
  }, [isOwner, user]);
};

export default useUserLanguage;
