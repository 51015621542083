import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import { useTranslation } from 'react-i18next';
import API from 'services/API';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import { CustomAmenity } from 'models/Amenity';
import { isNotSelectAll } from 'components/forms/multiSelectField/MultiSelectField.constants';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import { useQueryClient } from '@tanstack/react-query';
import { PROPERTIES_CUSTOM_AMENITIES_BASE_QUERY_KEY } from '../useCustomAmenitiesData';
import CustomAmenityModal, {
  addModalId,
  editModalId,
} from './CustomAmenityModal';

const useCustomAmenityModal = () => {
  const { t } = useTranslation();
  const { property } = usePropertySettingsInitData();
  const queryClient = useQueryClient();
  const { notifyError } = useNotify();
  const { openModal, closeModal } = useAppModal();

  const { mutate: createCustomAmenity } = useAppMutation(
    (payload: {
      applyToAll?: boolean;
      propertyUids?: string[];
      customAmenity: {
        name: string;
      };
    }) => {
      return API.post('/api/internal/custom-amenities/bulk-save', payload);
    },
    {
      onSuccess: () => {
        closeModal(addModalId);
        queryClient.invalidateQueries([
          PROPERTIES_CUSTOM_AMENITIES_BASE_QUERY_KEY,
        ]);
      },
      onError: () => {
        notifyError(t('pageProperty.amenities.customAmenities.errorCreate'));
      },
    },
  );

  const { mutate: updateCustomAmenity } = useAppMutation(
    (payload: {
      applyToAll?: boolean;
      propertyUids?: string[];
      customAmenity: {
        uid: string;
        name: string;
      };
    }) => {
      return API.post('/api/internal/custom-amenities/bulk-update', payload);
    },
    {
      onSuccess: () => {
        closeModal(editModalId);
        queryClient.invalidateQueries([
          PROPERTIES_CUSTOM_AMENITIES_BASE_QUERY_KEY,
        ]);
      },
      onError: () => {
        notifyError(t('pageProperty.amenities.customAmenities.errorUpdate'));
      },
    },
  );

  const handleAddSubmit = (values) => {
    const applyToAll = values.appliesTo === PROPERTIES_SELECTION.ALL_PROPERTIES;

    createCustomAmenity({
      ...(applyToAll
        ? { applyToAll: true }
        : {
            propertyUids: values.appliesToProperties
              .filter(isNotSelectAll)
              .map((p) => p.value),
          }),
      customAmenity: {
        name: values.name,
      },
    });
  };

  const handleEditSubmit = (values) => {
    const applyToAll = values.appliesTo === PROPERTIES_SELECTION.ALL_PROPERTIES;

    updateCustomAmenity({
      ...(applyToAll
        ? { applyToAll: true }
        : {
            propertyUids: values.appliesToProperties
              .filter(isNotSelectAll)
              .map((p) => p.value),
          }),
      customAmenity: {
        uid: values.uid,
        name: values.name,
      },
    });
  };

  const openAddModal = () => {
    openModal({
      id: addModalId,
      customContent: (
        <CustomAmenityModal property={property} onSubmit={handleAddSubmit} />
      ),
    });
  };

  const openEditModal = (customAmenity: CustomAmenity) => {
    openModal({
      id: editModalId,
      customContent: (
        <CustomAmenityModal
          property={property}
          customAmenity={customAmenity}
          onSubmit={handleEditSubmit}
        />
      ),
    });
  };

  return { openAddModal, openEditModal };
};

export default useCustomAmenityModal;
