import { useTranslation } from 'react-i18next';
import useAppModal from 'hooks/useAppModal';
import { AdditionalChargeModalParams } from './AdditionalChargeModal.types';
import AdditionalChargeModal from './AdditionalChargeModal';

const additionalChargeModalId = 'additional-charge-modal';

const useAdditionalChargeModal = () => {
  const { openModal } = useAppModal();
  const { t } = useTranslation();

  const openAdditionalChargeModal = (params: AdditionalChargeModalParams) => {
    openModal({
      customContent: (
        <AdditionalChargeModal
          modalId={additionalChargeModalId}
          params={params}
        />
      ),
      id: additionalChargeModalId,
      title: t('pageOrder.transactions.actions.additionalCharge.modalTitle'),
    });
  };

  return { openAdditionalChargeModal };
};

export default useAdditionalChargeModal;
