import { useTranslation } from 'react-i18next';
import useAppModal from 'hooks/useAppModal';
import { IssueRefundModalParams } from './IssueRefundModal.types';
import IssueRefundModal from './IssueRefundModal';

const issueRefundModalId = 'issue-refund-modal';

const useIssueRefundModal = () => {
  const { openModal } = useAppModal();
  const { t } = useTranslation();

  const openIssueRefundModal = (params: IssueRefundModalParams) => {
    openModal({
      customContent: (
        <IssueRefundModal modalId={issueRefundModalId} params={params} />
      ),
      id: issueRefundModalId,
      title: t('pageOrder.transactions.actions.issueRefund'),
    });
  };

  return { openIssueRefundModal };
};

export default useIssueRefundModal;
