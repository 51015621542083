import { Fragment } from 'react';
import { Lead, LeadStatus } from 'models/Leads';
import LeadCircle from '../../components/domain/lead/status/LeadCircle';
import {
  LeadListStatusesContainer,
  LeadListStatusesDivider,
} from './PipelineLeadListItemStatuses.styles';
import PipelineLeadListItemActiveStatusIndicator from './PipelineLeadListItemActiveStatusIndicator';

const PipelineLeadStatusCategory = {
  BOOKED: 'booked',
  HOLD: 'hold',
  QUOTE: 'quote',
  STAY: 'stay',
} as const;

const categoriesToDisplay = [
  PipelineLeadStatusCategory.QUOTE,
  PipelineLeadStatusCategory.HOLD,
  PipelineLeadStatusCategory.BOOKED,
  PipelineLeadStatusCategory.STAY,
];

const bookedCategoryStatuses = [
  LeadStatus.BOOKED_BY_AGENT,
  LeadStatus.BOOKED_BY_CUSTOMER,
  LeadStatus.BOOKED_BY_OWNER,
  LeadStatus.BOOKED_EXTERNALLY,
  LeadStatus.CANCELLED,
  LeadStatus.CANCELLED_BY_OWNER,
  LeadStatus.CANCELLED_BY_TRAVELER,
  LeadStatus.PAID_IN_FULL,
];
const holdCategoryStatuses = [
  LeadStatus.CLOSED_HOLD,
  LeadStatus.HOLD_EXPIRED,
  LeadStatus.ON_HOLD,
  LeadStatus.PENDING,
];
const quoteCategoryStatuses = [
  LeadStatus.CLOSED_QUOTE,
  LeadStatus.DECLINED,
  LeadStatus.IGNORED,
  LeadStatus.NEW,
  LeadStatus.QUOTE_SENT,
];
const stayCategoryStatuses = [LeadStatus.ARCHIVED, LeadStatus.STAY];

function getCurrentCategory(status: LeadStatus) {
  if (bookedCategoryStatuses.includes(status)) {
    return PipelineLeadStatusCategory.BOOKED;
  }

  if (holdCategoryStatuses.includes(status)) {
    return PipelineLeadStatusCategory.HOLD;
  }

  if (quoteCategoryStatuses.includes(status)) {
    return PipelineLeadStatusCategory.QUOTE;
  }

  if (stayCategoryStatuses.includes(status)) {
    return PipelineLeadStatusCategory.STAY;
  }

  return null;
}

const PipelineLeadListItemStatuses = ({ lead }: { lead: Lead }) => {
  const currentCategory = getCurrentCategory(lead.status);

  return (
    <LeadListStatusesContainer data-testid="lead-statuses-pipeline">
      {categoriesToDisplay.map((category, index) => (
        <Fragment key={`lead-status-${lead.uid}-${category}`}>
          {index > 0 && <LeadListStatusesDivider />}
          {category === currentCategory ? (
            <PipelineLeadListItemActiveStatusIndicator lead={lead} />
          ) : (
            <LeadCircle inactiveLabelKey={category} lead={lead} />
          )}
        </Fragment>
      ))}
    </LeadListStatusesContainer>
  );
};

export default PipelineLeadListItemStatuses;
