import { UseMutationOptions } from '@tanstack/react-query';
import useAppMutation from 'hooks/useAppMutation';
import { PropertyBase } from 'models/Properties';
import API from 'services/API';

type ActivatePropertyMutationArgs = Pick<PropertyBase, 'uid'> &
  Partial<Pick<PropertyBase, 'name'>>;

type ActivatePropertyResponseTO = '';

type ReactQueryMutationOptions = Omit<
  UseMutationOptions<
    ActivatePropertyResponseTO,
    unknown,
    ActivatePropertyMutationArgs
  >,
  'mutationFn' | 'mutationKey'
>;

const activateProperty = async ({
  uid,
}: ActivatePropertyMutationArgs): Promise<ActivatePropertyResponseTO> => {
  const response = await API.post<ActivatePropertyResponseTO>(
    `/api/internal/properties/${uid}/activate`,
  );
  return response.data;
};

const useActivatePropertyMutation = ({
  ...options
}: ReactQueryMutationOptions = {}) => {
  return useAppMutation({
    mutationFn: activateProperty,
    ...options,
  });
};

export default useActivatePropertyMutation;
