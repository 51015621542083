import { AdjustmentModalProps } from './AdjustmentModal.types';
import AdjustmentModalContextProvider from './AdjustmentModalContextProvider';
import AdjustmentModalInner from './AdjustmentModalInner';

const AdjustmentModal = ({ modalId, params }: AdjustmentModalProps) => {
  return (
    <AdjustmentModalContextProvider modalId={modalId}>
      <AdjustmentModalInner params={params} />
    </AdjustmentModalContextProvider>
  );
};

export default AdjustmentModal;
