import useAppQuery from 'hooks/useAppQuery';
import useAppUser from 'hooks/useAppUser';
import useNotify from 'hooks/useNotify';
import { Employee } from 'models/Employees';
import { useTranslation } from 'react-i18next';
import API from 'services/API';

const useAccountInitialValues = () => {
  const { t } = useTranslation();
  const { notifyError } = useNotify();
  const { userUid } = useAppUser();

  const {
    data: account,
    isFetching: isInitialLoading,
    refetch,
  } = useAppQuery(
    ['get-employee-account', userUid],
    async () => {
      const response = await API.get<{ employee: Employee }>(
        `api/v3/employees/${userUid}`,
      );
      return response.data.employee;
    },
    {
      enabled: true,
      onError: () => notifyError(t('pageAccount.somethingWentWrong')),
    },
  );

  return {
    initialValues: {
      firstName: account?.firstName,
      lastName: account?.lastName,
      email: account?.email,
      role: account?.role,
      phoneNumber: `+${account?.phoneAreaCode || ''}${
        account?.phoneNumber || ''
      }`,
      pictureUrl: account?.pictureUrl,
    },
    isInitialLoading,
    refetch,
  };
};

export default useAccountInitialValues;
