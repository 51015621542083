import { createGlobalStyle, css } from 'styled-components';
import SourceSansProLight from 'assets/fonts/SourceSansPro-Light.ttf';
import SourceSansProRegular from 'assets/fonts/SourceSansPro-Regular.ttf';
import theme from './theme';
import { ScrollableShadowStyles } from './ScrollableShadow.styles';

// BS5 utility classes that are not available in BS3 - this should be removed once migrated to BS5
const bootstrap5Adapter = css`
  .d-flex {
    display: flex !important;
  }

  .d-none {
    display: none !important;
  }

  .flex-column {
    flex-direction: column !important;
  }

  .invisible {
    visibility: hidden !important;
  }
`;

const customBootstrap = css`
  .has-success .help-block,
  .has-success .control-label,
  .has-success .radio,
  .has-success .checkbox,
  .has-success .radio-inline,
  .has-success .checkbox-inline,
  .has-success .form-control-feedback,
  .has-success .input-group-addon,
  .has-success.radio label,
  .has-success.checkbox label,
  .has-success.radio-inline label,
  .has-success.checkbox-inline label {
    color: ${theme.colors.fontGreen} !important;
  }

  .has-success .form-control,
  .has-success .input-group-addon {
    border-color: ${theme.colors.fontGreen} !important;
  }
`;

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: SourceSansPro_light;
    src: url(${SourceSansProLight});
  }
  @font-face {
    font-family: SourceSansPro_regular;
    src: url(${SourceSansProRegular});
  } 

  html {
    font-size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
  }

  body {
    font-family: 'Source Sans Pro', sans-serif;
    color: ${theme.colors.fontDark} !important;
  }

  div.tooltip a {
    color: #fff;
  }

  ${bootstrap5Adapter}

  ${customBootstrap}

  ${ScrollableShadowStyles}
`;

export default GlobalStyles;
