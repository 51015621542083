import { Row } from 'react-bootstrap';
import { PropertyBusinessType } from 'models/Properties';
import { FormProvider } from 'components/forms/form/Form';
import PropertyStatusBadge from 'components/domain/property/status/PropertyStatusBadge';
import useAppUser from 'hooks/useAppUser';
import { PropertyForPropertyListItem } from '../Properties.types';
import {
  PropertyListItemStyled,
  PropertyActionsColumnStyled,
  PropertyDetailsColumnStyled,
  PropertyPermissionsColumnStyled,
} from './PropertyListItem.styles';
import PropertyInfo from './PropertyInfo';
import PropertyPicture from './PropertyPicture';
import PropertyActions from './PropertyActions';
import PropertyCollapsibleArea from './PropertyCollapsibleArea';
import { useChildProperties, getDefaultFormValues } from './PropertyItem.utils';
import PropertyItemPermissions from './permissions/PropertyItemPermissions';
import useManagePropertyPermissionsAllowed from './permissions/useManagePropertyPermissionsAllowed';

interface PropertyListItemProps {
  isChildProperty?: boolean;
  property: PropertyForPropertyListItem;
}

const PropertyListItem = ({
  isChildProperty,
  property,
}: PropertyListItemProps) => {
  const { agency } = useAppUser();
  const childProperties = useChildProperties(property);
  const isPropertyWithChildren = childProperties.length > 0;
  const canManagePermissions = useManagePropertyPermissionsAllowed();
  const isHotel = property.businessType === PropertyBusinessType.HOTEL;
  const isSubUnit = property.businessType === PropertyBusinessType.SUB_UNIT;
  const canSeePermissions =
    canManagePermissions && agency.usePermissionedPMS && !isHotel && !isSubUnit;

  return (
    <FormProvider defaultValues={getDefaultFormValues(property)}>
      <PropertyListItemStyled
        data-is-child={isChildProperty}
        data-property-uid={property.uid}
      >
        <Row>
          <PropertyDetailsColumnStyled
            $canSeePermissions={canSeePermissions}
            $isChildProperty={isChildProperty}
          >
            <PropertyPicture property={property} />
            <PropertyInfo property={property} />
          </PropertyDetailsColumnStyled>
          <PropertyPermissionsColumnStyled
            $canSeePermissions={canSeePermissions}
          >
            {canSeePermissions && (
              <PropertyItemPermissions property={property} />
            )}
          </PropertyPermissionsColumnStyled>
          <PropertyActionsColumnStyled $canSeePermissions={canSeePermissions}>
            <PropertyStatusBadge isActive={property.isActive} />
            <PropertyActions property={property} />
          </PropertyActionsColumnStyled>
        </Row>

        {isPropertyWithChildren && (
          <PropertyCollapsibleArea property={property}>
            {childProperties.map((childProperty) => (
              <PropertyListItem
                key={childProperty.uid}
                property={childProperty}
                isChildProperty
              />
            ))}
          </PropertyCollapsibleArea>
        )}
      </PropertyListItemStyled>
    </FormProvider>
  );
};

PropertyListItem.defaultProps = {
  isChildProperty: undefined,
};

export default PropertyListItem;
