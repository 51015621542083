import { FC, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TypeAheadField from '../../typeAheadField/TypeAheadField';
import usePrevious from '../../../../hooks/usePrevious';
import useTagMutations from '../../../domain/tag/useTagMutations';
import { TypeAheadSearchOption } from '../../typeAheadField/TypeAheadField.styles';
import { TagsSelectFieldProps } from './TagsSelectField.types';

const TagsSelectField: FC<TagsSelectFieldProps> = ({
  name,
  saveOnChange,
  saveOnChangeParams,
  successCallback,
  placeHolder,
  ...props
}) => {
  const { t } = useTranslation();
  const { resetField } = useForm();
  const tags = useWatch({ name });
  const prevTags = usePrevious(tags);
  const { addTag, deleteTag } = useTagMutations();

  useEffect(() => {
    if (saveOnChange && tags) {
      const { entityUid, tagType } = saveOnChangeParams;

      const tagsToAdd = tags.filter(
        ({ name: tagName }) =>
          prevTags &&
          !prevTags.find(
            ({ name: existingTagName }) => existingTagName === tagName,
          ),
      );

      const tagsToDelete = prevTags?.filter(
        ({ name: tagName }) =>
          !tags.find(
            ({ name: existingTagName }) => existingTagName === tagName,
          ),
      );

      const successCallbackReset = () => {
        resetField(name, { defaultValue: tags });
        successCallback?.();
        // TODO reset cached query
      };

      tagsToAdd?.forEach(({ name: tag }) => {
        addTag({
          entityUid,
          tag,
          tagType,
          successCallback: successCallbackReset,
        });
      });

      tagsToDelete?.forEach(({ name: tag }) => {
        deleteTag({
          entityUid,
          tag,
          tagType,
          successCallback: successCallbackReset,
        });
      });
    }
  }, [tags]);

  if (saveOnChange && !saveOnChangeParams) {
    throw new Error(
      'Invalid argument: "saveOnChangeParams" must be provided when "saveOnChange" is set to true',
    );
  }

  return (
    <TypeAheadField
      allowNew
      labelKey="name"
      multiple
      name={name}
      newSelectionPrefix={t('componentTag.addNewTagPrefix')}
      placeholder={placeHolder || t('componentTag.addTagPlaceholder')}
      placeholderAlwaysVisible
      renderMenuItemChildren={({ name: tag }) => (
        <TypeAheadSearchOption>{tag}</TypeAheadSearchOption>
      )}
      selected={tags}
      saveOnEnter
      {...props}
    />
  );
};

export default TagsSelectField;
