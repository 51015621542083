import Tooltip from 'components/tooltip/Tooltip';
import useScreenBreakpoints from 'hooks/useScreenBreakpoints';

const PropertyListItemName = ({
  hasExpandIcon,
  name,
  uid,
}: {
  hasExpandIcon: boolean;
  name: string;
  uid: string;
}) => {
  const { isXS } = useScreenBreakpoints();
  const iconOffset = hasExpandIcon ? 3 : 0;
  const maxLength = (isXS ? 17 : 23) - iconOffset;

  if (name.length <= maxLength) {
    return (
      <span
        data-testid={`stacked-calendar-property-full-name-${uid}`}
        className="property-name"
      >
        {name}
      </span>
    );
  }

  return (
    <Tooltip id={`property-name-tooltip-${uid}`} text={name}>
      <span
        className="property-name"
        data-testid={`stacked-calendar-property-truncate-name-${uid}`}
      >
        {name.substring(0, maxLength - 3)}...
      </span>
    </Tooltip>
  );
};

export default PropertyListItemName;
