import { ImageCircle } from 'components/image/ImageCircle.styles';
import styled from 'styled-components';
import theme from 'styles/theme';

export const PropertyItemPermissionsStyled = styled.div.attrs({
  'data-testid': 'property-list-item-permissions',
})`
  align-items: start;
  display: flex;
  flex-direction: column;
`;

export const PropertyItemPermissionsEmployeeListStyled = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
  list-style-type: none;
  padding: 0 12px;
  cursor: pointer;
`;

export const PropertyItemPermissionsEmployeeInfoPropsStyled = styled.div`
  &:not(:first-child) {
    margin-top: 5px;
  }
`;

export const PropertyItemPermissionsEmployeeListItemStyled = styled.li<{
  $isPlaceholder?: boolean;
}>`
  margin: 0 -8px;
  padding: ${({ $isPlaceholder }) => ($isPlaceholder ? '7px 8px' : '0')};

  &.is-auto-assignee + &:not(.is-auto-assignee) {
    align-items: center;
    display: flex;

    ::before {
      content: '';
      background-color: ${theme.colors.gray500};
      height: 24px;
      margin-left: 10px;
      width: 1px;
    }
  }

  /* overrides the size when using regular div for avatar initials */
  ${ImageCircle} div {
    width: var(--size);
    height: var(--size);
  }
`;

export const PropertyItemPermissionsEmployeeListItemExtraStyled = styled(
  PropertyItemPermissionsEmployeeListItemStyled,
)`
  align-items: center;
  background-color: ${theme.colors.gray300};
  border-radius: 12px;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-left: 0;
  text-align: center;
  width: 24px;
`;
