import * as Yup from 'yup';
import { TFunction, Namespace } from 'react-i18next';
import { CURRENCIES_WITHOUT_NONE } from 'models/Currency';
import { RECURRING_EXPENSE_PERIOD } from 'models/ownerAdjustments/OwnerAdjustmentSchedule';
import { MEDIA_TYPE } from 'utils/constants/files';
import {
  ADJUSTMENT_TYPES,
  OwnerAdjustmentFormData,
} from './OwnerAdjustmentModal.types';

const common = (t: TFunction<Namespace<'en'>>) => ({
  adjustmentType: Yup.string().oneOf(Object.values(ADJUSTMENT_TYPES)),
  name: Yup.string().required(),
  amount: Yup.number()
    // treat -0 as null
    .transform((value) => (Object.is(value, -0) ? null : value))
    .typeError(
      t('form.validation.isNumber', {
        label: t('pageOwnerAdjustments.modal.form.amount'),
      }),
    )
    .required(),
  currency: Yup.string().oneOf(CURRENCIES_WITHOUT_NONE),
  taxationRate: Yup.number()
    .useNegativeZeroAsNegative()
    .min(0)
    .typeError(
      t('form.validation.isNumber', {
        label: t('pageOwnerAdjustments.modal.form.taxRate'),
      }),
    ),
  notes: Yup.string().nullable(),
  attachment: Yup.mixed().test({
    message: t('pageOwnerAdjustments.modal.form.validation.attachmentInvalid'),
    test: (fileList: FileList | File[] | null) => {
      if (!fileList?.length) return true;

      const [file] = Array.from(fileList);
      const isSizeValid = file.size < 10_000_000;
      const isTypeValid = [
        MEDIA_TYPE.IMAGE_PNG,
        MEDIA_TYPE.IMAGE_JPEG,
        MEDIA_TYPE.IMAGE_GIF,
      ].includes(file.type);

      return isSizeValid && isTypeValid;
    },
  }),
  attachedImageURL: Yup.string().url().min(4).nullable(true),
  attachedImageName: Yup.string().nullable(true),
});

const ownerAdjustmentModalSchema = (t: TFunction<Namespace<'en'>>) =>
  Yup.lazy((ownerAdjustment: OwnerAdjustmentFormData) => {
    return ownerAdjustment.adjustmentType === ADJUSTMENT_TYPES.ONE_TIME
      ? Yup.object({
          ...common(t),
          dateAccountedFor: Yup.date().required(),
        })
      : Yup.object({
          ...common(t),
          recurringPeriod: Yup.string().oneOf(
            Object.values(RECURRING_EXPENSE_PERIOD),
          ),
          nextScheduled: Yup.date().required(),
          endRecurringSchedule: Yup.date()
            .min(
              Yup.ref('nextScheduled'),
              t('pageOwnerAdjustments.modal.form.validation.endOnInvalid'),
            )
            .nullable(),
        });
  });

export default ownerAdjustmentModalSchema;
