import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const EditModalContainer = styled.div`
  .tooltip-container span {
    margin-left: 5px;
    top: 3px;
  }

  textarea {
    resize: vertical;
  }
`;

export const AppliesToRow = styled(Row)`
  margin-top: 10px;
`;
