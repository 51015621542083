import useAppUser from '../../../hooks/useAppUser';
import useAppQuery from '../../../hooks/useAppQuery';
import API from '../../../services/API';
import {
  GetReviewByHostResponseTO,
  ReviewByHost,
} from '../../../models/Reviews';

const useFetchReviewByHost = (leadUid: string, enabled: boolean) => {
  const { isEmployee } = useAppUser();

  const { data: reviewByHost, isInitialLoading: isFetching } = useAppQuery(
    [`review-by-host-${leadUid}`, leadUid],
    async (): Promise<ReviewByHost> => {
      const response = await API.get<GetReviewByHostResponseTO>(
        `/v3/reviews-by-host?leadUid=${leadUid}`,
      );
      return response.data.review;
    },
    {
      enabled: enabled && !!leadUid && !!isEmployee,
    },
  );

  return { isFetching, reviewByHost };
};

export default useFetchReviewByHost;
