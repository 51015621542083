import useAppQuery from 'hooks/useAppQuery';
import { GetCancellationPolicyResponse } from 'models/CancellationPolicy';
import { useParams } from 'react-router-dom';
import API from 'services/API';

export const CANCELLATION_POLICY_BASE_QUERY_KEY = 'cancellation-policy';

const useCancellationPolicyData = () => {
  const { uid: propertyUid } = useParams();

  const {
    data: cancellationPolicyData,
    isLoading: isLoadingCancellationPolicy,
  } = useAppQuery(
    [CANCELLATION_POLICY_BASE_QUERY_KEY, propertyUid],
    async () => {
      const response = await API.get<GetCancellationPolicyResponse>(
        `/api/internal/cancellation-policies`,
        {
          params: {
            propertyUid,
          },
        },
      );
      return response.data.cancellationPolicy;
    },
    {
      enabled: !!propertyUid,
    },
  );

  return {
    cancellationPolicyData,
    isLoadingCancellationPolicy,
  };
};

export default useCancellationPolicyData;
