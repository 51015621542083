import { ModalFooter } from 'react-bootstrap';
import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import useAppModal from 'hooks/useAppModal';

const RoomsAndBedTypesModalFooter = ({ modalId }: { modalId: string }) => {
  const { t } = useTranslation();
  const { closeModal } = useAppModal();
  const { isDirty, isSubmitting } = useFormState();
  const cancelHandler = () => closeModal(modalId);

  return (
    <ModalFooter>
      <Button
        data-testid="cancel-button"
        disabled={isSubmitting}
        onClick={cancelHandler}
      >
        {t('common.cancel')}
      </Button>
      <Button
        bsStyle="primary"
        data-testid="apply-button"
        disabled={isSubmitting || !isDirty}
        type="submit"
      >
        {t('common.apply')}
      </Button>
    </ModalFooter>
  );
};

export default RoomsAndBedTypesModalFooter;
