import { HighlightStyled } from './TextHighlighter.styles';

interface TextHighlighterProps {
  text: string;
  textToHighlight: string;
}

const TextHighlighter = ({ text, textToHighlight }: TextHighlighterProps) => {
  if (!textToHighlight?.trim()) {
    return text;
  }

  const textParts = text.split(new RegExp(`(${textToHighlight})`, 'gi'));

  return (
    <>
      {textParts.map((textPart, index) =>
        textPart.toLocaleLowerCase() === textToHighlight.toLocaleLowerCase() ? (
          // eslint-disable-next-line react/no-array-index-key
          <HighlightStyled key={`${textPart}-${index}`}>
            {textPart}
          </HighlightStyled>
        ) : (
          textPart
        ),
      )}
    </>
  );
};

export default TextHighlighter;
