import { useEffect, useMemo, useState } from 'react';
import CalendarBodySelectionService from '../../calendar/common/body/selection/CalendarBodySelectionService';
import useCalendarMode from '../useCalendarMode';
import { getSelectionStyle } from './StackedCalendarBody.styles';

const StackedCalendarBodySelectionHandler = () => {
  const { isPricingMode } = useCalendarMode();
  const [propertyUids, setPropertyUids] = useState<string[]>(null);

  useEffect(() => {
    const subscription = CalendarBodySelectionService.subscribe((selection) => {
      if (selection) {
        const { propertyUids: selectedPropertyUids } = selection;
        setPropertyUids(selectedPropertyUids);
      } else {
        setPropertyUids(null);
      }
    });

    return () => {
      subscription.unsubscribe();
      CalendarBodySelectionService.reset();
    };
  }, []);

  return useMemo(
    () => <style>{getSelectionStyle(propertyUids, isPricingMode)}</style>,
    [propertyUids, isPricingMode],
  );
};

export default StackedCalendarBodySelectionHandler;
