import { Trans, useTranslation } from 'react-i18next';
import { ImageCircle } from 'components/image/ImageCircle.styles';
import { Lead, LeadType } from 'models/Leads';
import { formatRangeMediumWithCurrentYearCheck } from 'utils/dateTimeUtils';
import {
  getFullName,
  getLeadCheckInDate,
  getLeadCheckOutDate,
  getLeadLocalizedNightsCount,
} from 'utils/lead/leadUtils';
import { getPropertyEditPath } from 'utils/property/propertyUtils';
import Tooltip from 'components/tooltip/Tooltip';
import theme from 'styles/theme';
import LeadDetailIntValue from '../LeadDetailIntValue';
import useCheckPropertyAvailability from '../../property/useCheckPropertyAvailability';
import useCheckLeadMinimumStay from '../useCheckLeadMinimumStay';
import {
  GuestCounter,
  InfoWrapper,
  BasicInfoWrapper,
  GuestName,
  InfoPropertyDetails,
  NightCountLabel,
} from './LeadListItemBasicInfo.styles';

const getRingColor = ({
  showStatusRing,
  isPropertyUnavailable,
}: {
  showStatusRing: boolean;
  isPropertyUnavailable: boolean;
}) => {
  if (isPropertyUnavailable && showStatusRing) {
    return 'red';
  }
  if (showStatusRing) {
    return theme.colors.bgGreen;
  }

  return undefined;
};

const LeadListItemBasicInfo = ({
  lead,
  showGuestCounter = true,
  showStatusRing = true,
}: {
  lead: Lead;
  showGuestCounter?: boolean;
  showStatusRing?: boolean;
}) => {
  const { t } = useTranslation();
  const { adultCount, childrenCount, leadType, property, uid, hotel } = lead;
  const checkInDate = getLeadCheckInDate(lead);
  const checkOutDate = getLeadCheckOutDate(lead);
  const isInquiry = leadType === LeadType.INQUIRY;

  // checking lead availability only makes sense for an inquiry
  const { propertyAvailability } = useCheckPropertyAvailability({
    enabled: isInquiry,
    propertyUid: property.uid,
    startDate: checkInDate,
    endDate: checkOutDate,
    leadUidsToExclude: [uid],
  });

  const { isMinimumStayNotSatisfied } = useCheckLeadMinimumStay({
    propertyUid: property.uid,
    startDate: checkInDate,
    endDate: checkOutDate,
    enabled: isInquiry,
  });
  const isPropertyUnavailable = isInquiry && !propertyAvailability?.available;
  const {
    fromFormatted: checkInDateFormatted,
    toFormatted: checkOutDateFormatted,
  } = formatRangeMediumWithCurrentYearCheck({
    from: checkInDate,
    to: checkOutDate,
    includeDayOfWeek: true,
    t,
  });

  return (
    <BasicInfoWrapper>
      <ImageCircle
        data-testid="lead-property-image"
        $ringColor={getRingColor({ showStatusRing, isPropertyUnavailable })}
      >
        <div>
          <img
            src={property?.mainPicture?.largeThumbnail}
            alt={property?.name}
          />
        </div>
      </ImageCircle>

      <InfoWrapper>
        <h4>
          <GuestName data-testid="lead-guest-name">
            {getFullName(lead)}
          </GuestName>
          {showGuestCounter && (
            <div data-testid="lead-guest-counter">
              {!!adultCount && (
                <GuestCounter>
                  <LeadDetailIntValue
                    baseLocaleKey="adult"
                    value={adultCount}
                  />
                </GuestCounter>
              )}
              {!!childrenCount && (
                <GuestCounter>
                  <LeadDetailIntValue
                    baseLocaleKey="child"
                    value={childrenCount}
                  />
                </GuestCounter>
              )}
            </div>
          )}
        </h4>
        <p data-testid="lead-date-details">
          <Trans
            i18nKey="componentLead.details.checkInToCheckOutWrapped"
            values={{
              checkInDate: checkInDateFormatted,
              checkOutDate: checkOutDateFormatted,
            }}
            components={{ joinerWrapper: <span className="dates-separator" /> }}
          />{' '}
          <NightCountLabel
            isMinimumStayNotSatisfied={isMinimumStayNotSatisfied}
            data-testid="lead-night-count"
          >
            {isMinimumStayNotSatisfied ? (
              <Tooltip
                id="minimm-stay-not-satisfied-tooltip"
                text={t('componentLead.details.minimumStayNotSatisfied')}
              >
                ({getLeadLocalizedNightsCount(lead, t)})
              </Tooltip>
            ) : (
              <>({getLeadLocalizedNightsCount(lead, t)})</>
            )}
          </NightCountLabel>
        </p>

        <InfoPropertyDetails>
          {hotel && (
            <a
              className="lead-property-link"
              data-testid="lead-hotel-link"
              href={`#/hotel?uid=${hotel?.uid}`}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => {
                e.stopPropagation();
                return false;
              }}
            >
              {`${hotel?.name}` || ''}
            </a>
          )}
          {hotel && ' | '}

          <a
            className="lead-property-link"
            data-testid="lead-property-link"
            href={getPropertyEditPath(property)}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.stopPropagation();
              return false;
            }}
          >
            {property?.name || ''}
          </a>
        </InfoPropertyDetails>
      </InfoWrapper>
    </BasicInfoWrapper>
  );
};

LeadListItemBasicInfo.defaultProps = {
  showGuestCounter: true,
  showStatusRing: true,
};

export default LeadListItemBasicInfo;
