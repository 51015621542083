import { useEffect } from 'react';

function getExistingLinkElement(id?: string) {
  if (id) {
    const links = document.getElementsByTagName('link');
    return Array.from(links).find((link) => link.getAttribute('id') === id);
  }

  return null;
}

const useCssStylesheet = (stylesheetUrl?: string, id?: string) => {
  useEffect(() => {
    const existingStylesheetLink = getExistingLinkElement(id);

    if (stylesheetUrl) {
      const { head } = document;
      const link = existingStylesheetLink || document.createElement('link');

      link.id = id || '';
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = stylesheetUrl;

      head.appendChild(link);

      return () => {
        head.removeChild(link);
      };
    }

    if (existingStylesheetLink) {
      existingStylesheetLink.parentElement.removeChild(existingStylesheetLink);
    }

    return () => {};
  }, [stylesheetUrl]);
};

export default useCssStylesheet;
