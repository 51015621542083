import { BaseSyntheticEvent } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import useUpdatePropertyAmenities from 'components/domain/property/amenities/useUpdatePropertyAmenities';
import { FormContextParts } from 'components/forms/form/Form.types';
import useNotify from 'hooks/useNotify';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import { PROPERTY_AMENITIES_QUERY_KEY } from '../../usePropertyAmenities';
import usePropertySettingsBulkSaveModal from '../../../bulkSave/usePropertySettingsBulkSaveModal';
import usePropertySettingsInitData from '../../../usePropertySettingsInitData';
import {
  CategorizedAmenitiesFormValues,
  getFormDefaultValues,
} from './CategorizedAmenities.schema';
import useCategorizedAmenitiesUpdatePayload from './useCategorizedAmenitiesUpdatePayload';

export async function handlePropertyAmenitiesUpdateSuccess({
  notifySuccess,
  propertyUid,
  queryClient,
  reset,
  t,
}: {
  propertyUid: string;
  queryClient: QueryClient;
  reset: (values: CategorizedAmenitiesFormValues) => void;
  notifySuccess: (message: string) => void;
  t: TFunction;
}) {
  const queryKey = [PROPERTY_AMENITIES_QUERY_KEY, propertyUid];
  await queryClient.invalidateQueries(queryKey).then(() => {
    const updatedFormValues = getFormDefaultValues(
      queryClient.getQueryData(queryKey),
    );

    reset(updatedFormValues);

    notifySuccess(t('pageProperty.amenities.updateSuccess'));
  });
}

const useCategorizedAmenitiesFormSubmit = () => {
  const getUpdatePayload = useCategorizedAmenitiesUpdatePayload();
  const { notifyError, notifySuccess } = useNotify();
  const { property } = usePropertySettingsInitData();
  const { openPropertySettingsBulkSaveModal } =
    usePropertySettingsBulkSaveModal();
  const { isLoading: isUpdating, mutateAsync: updatePropertyAmenities } =
    useUpdatePropertyAmenities();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const onSubmit = async (
    formValues: CategorizedAmenitiesFormValues,
    { nativeEvent }: BaseSyntheticEvent<SubmitEvent> | undefined,
    {
      formState: { dirtyFields },
      reset,
    }: FormContextParts<CategorizedAmenitiesFormValues>,
  ) => {
    if (!Object.keys(dirtyFields.amenities || {}).length) {
      return;
    }

    const isBulkSave = nativeEvent.submitter.id === 'bulk-save-button';

    if (isBulkSave) {
      openPropertySettingsBulkSaveModal({ dirtyFields, formValues });
      return;
    }

    const payload = getUpdatePayload({ dirtyFields, formValues });

    await updatePropertyAmenities({ payload, property })
      .then(async (response) => {
        const apiErrorCode = response?.data?.apiErrorCode;
        const apiErrorMessage = response?.data?.apiErrorMessage;

        if (apiErrorCode || apiErrorMessage) {
          notifyError(
            getErrorMessage({
              apiErrorCode,
              apiErrorMessage,
              baseKey: 'pageProperty.apiErrorCodes.',
              t,
            }),
          );
          return;
        }

        await handlePropertyAmenitiesUpdateSuccess({
          notifySuccess,
          propertyUid: property.uid,
          queryClient,
          reset,
          t,
        });
      })
      .catch(({ response: { data } }) => {
        notifyError(
          getErrorMessage({
            ...data,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      });
  };

  return {
    isUpdating,
    onSubmit,
  };
};

export default useCategorizedAmenitiesFormSubmit;
