import useFeatures from 'hooks/useFeatures';
import useAppUser from '../../../../hooks/useAppUser';
import useHasRouteAccess from '../../../../router/useHasRouteAccess';
import { itemsByRole } from './headerItemsConstants';

const useHeaderItems = () => {
  const hasRouteAccess = useHasRouteAccess();
  const hasItemAccess = (item) => hasRouteAccess(item.path);
  const allowedFlags = useFeatures();
  const { userType } = useAppUser();
  const navigationProps = { ...itemsByRole[userType] };
  navigationProps.navItems = navigationProps.navItems
    .filter(hasItemAccess)
    .filter(
      ({ allowedToRender }) =>
        !allowedToRender || allowedToRender(allowedFlags),
    );

  navigationProps.dropdownItems = navigationProps.dropdownItems
    .filter(hasItemAccess)
    .filter(
      ({ allowedToRender }) =>
        !allowedToRender || allowedToRender(allowedFlags),
    );
  navigationProps.dropdownSecondaryItems =
    navigationProps.dropdownSecondaryItems?.filter(hasItemAccess);
  return navigationProps;
};

export default useHeaderItems;
