import useAppModal from 'hooks/useAppModal';
import ImportBookingDotComPropertiesModal from './modal/ImportBookingDotComPropertiesModal';

const modalId = 'importBookingDotComPropertiesModal';

const useImportBookingDotComPropertiesModal = () => {
  const { openModal, closeModal } = useAppModal();

  const openBookingDotComPropertiesModal = () => {
    openModal({
      id: modalId,
      customContent: (
        <ImportBookingDotComPropertiesModal
          onClose={() => closeModal(modalId)}
        />
      ),
    });
  };

  return { openBookingDotComPropertiesModal };
};

export default useImportBookingDotComPropertiesModal;
