import { useContext, useEffect } from 'react';
import { max, min, parse } from 'date-fns';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppEvent from 'hooks/useAppEvent';
import {
  AppEventType,
  JobDeletedEventPayload,
  JobUpdatedEventPayload,
  LeadCreatedEventPayload,
  LeadDeletedEventPayload,
  LeadUpdatedEventPayload,
  PropertyPricingPeriodsUpdatedEventPayload,
} from 'models/Events';
import { setBodyRefreshRequest } from 'store/slices/stackedCalendar';
import { getJobEndDate, getJobStartDate } from 'utils/job/jobUtils';
import { getLeadCheckInDate, getLeadCheckOutDate } from 'utils/lead/leadUtils';
import CalendarContext from '../../../stackedCalendar/CalendarContext';

const useCalendarBodyUpdateEvents = (
  propertiesUidsProvider: (propertyUid: string) => string[],
) => {
  const dispatch = useAppDispatch();
  const { subscribe, unsubscribe } = useAppEvent();
  const { leadsMap, jobsMap } = useContext(CalendarContext);

  useEffect(() => {
    const leadCreatedListener = ({
      detail: { lead },
    }: CustomEvent<LeadCreatedEventPayload>) => {
      const { propertyUid } = lead;

      dispatch(
        setBodyRefreshRequest({
          properties: propertiesUidsProvider(propertyUid),
          fromDate: getLeadCheckInDate(lead),
          toDate: getLeadCheckOutDate(lead),
        }),
      );
    };
    const leadDeletedListener = ({
      detail: { leadUid },
    }: CustomEvent<LeadDeletedEventPayload>) => {
      const lead = leadsMap[leadUid];

      if (!lead) {
        return;
      }

      const {
        property: { uid: propertyUid },
      } = lead;

      dispatch(
        setBodyRefreshRequest({
          properties: propertiesUidsProvider(propertyUid),
          fromDate: getLeadCheckInDate(lead),
          toDate: getLeadCheckOutDate(lead),
        }),
      );
    };

    const leadUpdatedListener = ({
      detail: { lead: updatedLead },
    }: CustomEvent<LeadUpdatedEventPayload>) => {
      const { propertyUid, uid: leadUid } = updatedLead;
      const currentLead = leadsMap[leadUid];

      if (!currentLead) {
        return;
      }

      const {
        property: { uid: currentPropertyUid },
      } = currentLead;

      dispatch(
        setBodyRefreshRequest({
          properties: [
            ...propertiesUidsProvider(propertyUid),
            ...(currentPropertyUid !== propertyUid ? [currentPropertyUid] : []),
          ],
          fromDate: min([
            getLeadCheckInDate(currentLead),
            getLeadCheckInDate(updatedLead),
          ]),
          toDate: max([
            getLeadCheckOutDate(currentLead),
            getLeadCheckOutDate(updatedLead),
          ]),
        }),
      );
    };

    const jobCreateListener = ({
      detail: { job },
    }: CustomEvent<JobUpdatedEventPayload>) => {
      const { propertyUid } = job;

      dispatch(
        setBodyRefreshRequest({
          properties: propertiesUidsProvider(propertyUid),
          fromDate: getJobStartDate(job),
          toDate: getJobEndDate(job),
        }),
      );
    };

    const jobUpdatedListener = ({
      detail: { job: updatedJob },
    }: CustomEvent<JobUpdatedEventPayload>) => {
      const { propertyUid, uid: jobUid } = updatedJob;
      const currentJob = jobsMap[jobUid];

      if (!currentJob) return;

      const { propertyUid: currentPropertyUid } = currentJob;

      // Udpate jobs map for current job
      jobsMap[jobUid] = updatedJob;

      dispatch(
        setBodyRefreshRequest({
          properties: [
            ...propertiesUidsProvider(propertyUid),
            ...(currentPropertyUid !== propertyUid ? [currentPropertyUid] : []),
          ],
          fromDate: min([
            getJobStartDate(currentJob),
            getJobStartDate(updatedJob),
          ]),
          toDate: max([getJobEndDate(currentJob), getJobEndDate(updatedJob)]),
        }),
      );
    };

    const jobDeletedListener = ({
      detail: { jobUid },
    }: CustomEvent<JobDeletedEventPayload>) => {
      const job = jobsMap[jobUid];

      if (!job) return;

      const { propertyUid } = job;

      dispatch(
        setBodyRefreshRequest({
          properties: propertiesUidsProvider(propertyUid),
          fromDate: getJobStartDate(job),
          toDate: getJobEndDate(job),
        }),
      );
    };

    const pricingPeriodsUpdatedListener = ({
      detail: { updatedPricingPeriods },
    }: CustomEvent<PropertyPricingPeriodsUpdatedEventPayload>) => {
      if (updatedPricingPeriods?.length) {
        const referenceDate = new Date();
        const bodyRefreshRequest = updatedPricingPeriods.reduce(
          (acc, { date, propertyUid }) => {
            const parsedDate = parse(date, 'yyyy-MM-dd', referenceDate);
            acc.fromDate = acc.fromDate
              ? min([acc.fromDate, parsedDate])
              : parsedDate;
            acc.toDate = acc.toDate
              ? max([acc.toDate, parsedDate])
              : parsedDate;
            acc.properties.add(propertyUid);

            return acc;
          },
          {
            fromDate: null,
            toDate: null,
            properties: new Set<string>(),
          },
        );

        dispatch(
          setBodyRefreshRequest({
            ...bodyRefreshRequest,
            properties: Array.from(bodyRefreshRequest.properties),
            shouldHighlightUpdatedCells: true,
          }),
        );
      }
    };

    subscribe(AppEventType.LEAD_CREATED, leadCreatedListener);
    subscribe(AppEventType.LEAD_DELETED, leadDeletedListener);
    subscribe(AppEventType.LEAD_UPDATED, leadUpdatedListener);
    subscribe(AppEventType.JOB_CREATED, jobCreateListener);
    subscribe(AppEventType.JOB_UPDATED, jobUpdatedListener);
    subscribe(AppEventType.JOB_DELETED, jobDeletedListener);
    subscribe(
      AppEventType.PROPERTY_PRICING_PERIODS_UPDATED,
      pricingPeriodsUpdatedListener,
    );

    return () => {
      unsubscribe(AppEventType.LEAD_CREATED, leadCreatedListener);
      unsubscribe(AppEventType.LEAD_DELETED, leadDeletedListener);
      unsubscribe(AppEventType.LEAD_UPDATED, leadUpdatedListener);
      unsubscribe(AppEventType.JOB_CREATED, jobCreateListener);
      unsubscribe(AppEventType.JOB_UPDATED, jobUpdatedListener);
      unsubscribe(AppEventType.JOB_DELETED, jobDeletedListener);
      unsubscribe(
        AppEventType.PROPERTY_PRICING_PERIODS_UPDATED,
        pricingPeriodsUpdatedListener,
      );
    };
  }, []);
};

export default useCalendarBodyUpdateEvents;
