import { Row } from '@tanstack/react-table';
import Button from 'components/button/Button';
import { HouseRule } from 'models/HouseRules';
import { useTranslation } from 'react-i18next';

const ActionsCell = ({ row }: { row: Row<HouseRule> }) => {
  const { t } = useTranslation();

  return (
    <>
      <Button bsStyle="link">{t('common.edit')}</Button>
      <Button bsStyle="link">{t('common.delete')}</Button>
    </>
  );
};

export default ActionsCell;
