import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { queryClient } from 'App';
import { LEAD_GQL_QUERY_BASE } from 'components/domain/lead/useFetchLead';
import useCreateRefund from 'components/domain/transaction/useCreateRefund';
import FormWithProvider from 'components/forms/form/Form';
import useAppModal from 'hooks/useAppModal';
import {
  IssueRefundFormValues,
  IssueRefundModalParams,
} from './IssueRefundModal.types';
import IssueRefundModalFormElements from './IssueRefundModalFormElements';

const issueRefundFormSchema = (maxAmount: number, t: TFunction) => ({
  amount: Yup.number()
    .positive()
    .max(
      maxAmount,
      t('pageOrder.transactions.actions.invalidRefundAmount', { maxAmount }),
    )
    .required(),
  canReturnSecurityDeposit: Yup.boolean().required(),
  markSecurityDepositAsReturned: Yup.boolean().optional(),
});

const IssueRefundModal = ({
  modalId,
  params: { lead, transaction },
}: {
  modalId: string;
  params: IssueRefundModalParams;
}) => {
  const { closeModal } = useAppModal();
  const { createRefund } = useCreateRefund();
  const { t } = useTranslation();

  const {
    order: { securityDeposit },
    uid: leadUid,
    usePreAuthForDeposits,
    wasSecurityDepositReceived,
    wasSecurityDepositReturned,
  } = lead;
  const {
    amount: transactionAmount,
    refundableAmount,
    uid: transactionUid,
  } = transaction;

  const onCancel = () => {
    closeModal(modalId);
  };

  const onSubmit = async ({
    amount,
    markSecurityDepositAsReturned,
  }: IssueRefundFormValues) => {
    await createRefund({
      refund: {
        amount,
        markSecurityDepositAsReturned,
      },
      transactionUid,
      successCallback: () => {
        queryClient.invalidateQueries([LEAD_GQL_QUERY_BASE, leadUid]);
        closeModal(modalId);
      },
    });
  };

  const canReturnSecurityDeposit =
    (!usePreAuthForDeposits &&
      wasSecurityDepositReceived &&
      !wasSecurityDepositReturned &&
      securityDeposit > 0) ??
    false;

  const maxAmount = refundableAmount || transactionAmount;

  const initialAmount =
    canReturnSecurityDeposit && securityDeposit < maxAmount
      ? securityDeposit
      : maxAmount;

  const formDefaultValues = {
    amount: initialAmount,
    canReturnSecurityDeposit,
    markSecurityDepositAsReturned: canReturnSecurityDeposit || undefined,
  };

  return (
    <FormWithProvider
      defaultValues={formDefaultValues}
      onSubmit={onSubmit}
      schema={issueRefundFormSchema(maxAmount, t)}
    >
      <IssueRefundModalFormElements
        lead={lead}
        onCancel={onCancel}
        transaction={transaction}
      />
    </FormWithProvider>
  );
};

export default IssueRefundModal;
