import styled from 'styled-components';
import { CollapseSectionStyled } from 'pages/property/common/Common.styles';
import { breakpoints } from 'styles/mediaQuery';

export const BookingDotComCollapseStyled = styled(CollapseSectionStyled)`
  // only apply fix for > SM screens
  @media (min-width: ${breakpoints.SM}px) {
    .cancellation-policy-field {
      label {
        width: 20.15%;
      }

      label + div {
        width: 79.85%;
      }
    }
  }
`;
