import { SortingState } from '@tanstack/react-table';
import { ExpectedQueryData } from 'components/table/hooks/useServerFilteredTable';
import { getSortParam } from 'components/table/utils/sorting';
import {
  GetPropertyFeesInternalResponse,
  PropertyInternalFee,
} from 'models/PropertyFees';
import API from 'services/API';

export const PROPERTIES_FEES_BASE_QUERY_KEY = 'propertyFees';

export const propertyFeesQuery = ({
  propertyUid,
  pageIndex,
  sorting,
  pageSize,
}: {
  propertyUid: string;
  pageIndex: number;
  pageSize: number;
  sorting: SortingState;
}) => ({
  queryKey: [
    PROPERTIES_FEES_BASE_QUERY_KEY,
    propertyUid,
    { pageIndex, sorting },
  ],
  queryFn: async (): Promise<ExpectedQueryData<PropertyInternalFee>> => {
    const sort = getSortParam(sorting, {
      name: 'SORT_BY_NAME',
      type: 'SORT_BY_TYPE',
      amount: 'SORT_BY_VALUE',
      scope: 'SORT_BY_SCOPE',
    });

    const response = await API.get<GetPropertyFeesInternalResponse>(
      '/api/internal/fees',
      {
        params: {
          propertyUid,
          _limit: pageSize,
          ...(pageIndex > 0
            ? { _cursor: btoa(`{"offset":${pageIndex * pageSize}}`) }
            : {}),
          ...(sort ? { sort: sort.sort, sortDirection: sort.direction } : {}),
        },
      },
    );

    const { fees, _metadata: metadata } = response.data;

    return {
      metadata,
      data: fees,
    };
  },
  enabled: true,
});
