import { AvailableTemplate } from '../../../../models/Templates';
import Button from '../../../../components/button/Button';
import Loader from '../../../../components/loader/Loader';
import { InboxMessageTemplateName } from './InboxMessageTemplates.styles';
import useSelectInboxMessageTemplate from './useSelectInboxMessageTemplate';

const InboxMessageTemplatesListItem = ({
  template,
}: {
  template: AvailableTemplate;
}) => {
  const { isLoadingTemplate, selectTemplate } = useSelectInboxMessageTemplate();
  const { localizedName } = template;

  const selectTemplateHandler = () => {
    if (!isLoadingTemplate) {
      selectTemplate(template);
    }
  };

  return (
    <InboxMessageTemplateName>
      <Button
        data-testid="inbox-message-templates-list-item"
        $noBorder
        onClick={selectTemplateHandler}
      >
        {localizedName}
      </Button>
      {isLoadingTemplate && <Loader inline />}
    </InboxMessageTemplateName>
  );
};

export default InboxMessageTemplatesListItem;
