import { useTranslation } from 'react-i18next';
import SectionHeader from 'pages/property/common/SectionHeader';
import usePropertyExpectationAddEditModal from './modals/usePropertyExpectationAddEditModal';

const PropertyExpectationsHeader = () => {
  const { openAddPropertyExpectationModal } =
    usePropertyExpectationAddEditModal();
  const { t } = useTranslation();

  return (
    <SectionHeader
      id="property-expectations"
      title={t('pageProperty.feesTaxesAndPolicies.propertyExpectations.title')}
      tooltip={t(
        'pageProperty.feesTaxesAndPolicies.propertyExpectations.tooltip',
      )}
      button={{
        label: t(
          'pageProperty.feesTaxesAndPolicies.propertyExpectations.addExpectation',
        ),
        onClick: openAddPropertyExpectationModal,
      }}
    />
  );
};

export default PropertyExpectationsHeader;
