import { Transaction } from 'models/Transactions';
import useOrderViewFetchedData from '../useOrderViewFetchedData';
import TransactionAmountRenderer from './TransactionAmountRenderer';

const OrderViewTransactionsListItemAmount = ({
  transaction,
}: {
  transaction: Transaction;
}) => {
  const { lead } = useOrderViewFetchedData();

  return <TransactionAmountRenderer lead={lead} transaction={transaction} />;
};

export default OrderViewTransactionsListItemAmount;
