import { useEffect } from 'react';
import API from '../services/API';
import { GetAppPublicCustomisationsResponseTO } from '../models/Config';
import { setAppConfig } from '../store/slices/config';
import useAppConfig from './useAppConfig';
import useAppUser from './useAppUser';
import useAppQuery from './useAppQuery';
import useAppDispatch from './useAppDispatch';
import useAppPublicCustomisationsApply from './useAppPublicCustomisationsApply';

const useAppPublicCustomisations = () => {
  const dispatch = useAppDispatch();
  const appConfig = useAppConfig(true);
  const { user } = useAppUser();

  useAppPublicCustomisationsApply();

  const { data: fetchedAppPublicCustomisations, isInitialLoading } =
    useAppQuery(
      ['appPublicCustomisations'],
      async () => {
        const response = await API.get<GetAppPublicCustomisationsResponseTO>(
          '/web-app/public-customisations',
        );

        return response.data;
      },
      { enabled: !user && !appConfig },
    );

  useEffect(() => {
    if (fetchedAppPublicCustomisations) {
      const { customSettings, showVersionNumber } =
        fetchedAppPublicCustomisations;
      const updatedAppConfig = {
        customSettings,
        featureFlags: [],
        showIntercom: false,
        showVersionNumber,
        hasAiEnabled: false,
      };

      dispatch(setAppConfig({ appConfig: updatedAppConfig }));
    }
  }, [fetchedAppPublicCustomisations]);

  return { isCustomisationsLoading: isInitialLoading || !appConfig };
};

export default useAppPublicCustomisations;
