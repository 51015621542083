import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import { PropertyBase } from 'models/Properties';
import { PropertyItemPermissionsStyled } from './PropertyItemPermissions.styles';
import PropertyItemPermissionsUsersList from './PropertyItemPermissionsEmployeeList';
import usePropertyItemPermissionsModal from './usePropertyItemPermissionsModal';

interface PropertyItemPermissionsProps {
  property: Pick<PropertyBase, 'uid' | 'businessType' | 'name'>;
}

const PropertyItemPermissions = ({
  property,
}: PropertyItemPermissionsProps) => {
  const { t } = useTranslation();
  const { openPropertyItemPermissionsModal } =
    usePropertyItemPermissionsModal(property);

  const handleSetPermissions = () => {
    openPropertyItemPermissionsModal();
  };

  return (
    <PropertyItemPermissionsStyled>
      <PropertyItemPermissionsUsersList
        property={property}
        onClick={handleSetPermissions}
      />
      <Button onClick={handleSetPermissions} bsStyle="link">
        {t('pageProperties.permissions.setPermissions')}
      </Button>
    </PropertyItemPermissionsStyled>
  );
};

export default PropertyItemPermissions;
