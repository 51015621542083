import { useFormState } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import PropertyEditActionWrapper from './PropertyEditActionWrapper';

const FormFooterSaveButton = ({
  saveButtonLabel,
}: {
  saveButtonLabel: string;
}) => {
  const { isSubmitting, dirtyFields } = useFormState();
  const { uid: propertyUid } = useParams();
  const { t } = useTranslation();

  const isEditMode = !!propertyUid;

  const isActuallyDirty = Object.keys(dirtyFields).length > 0;

  return (
    <PropertyEditActionWrapper
      shouldCheckPermission={isEditMode}
      tooltipId="save-button-tooltip"
    >
      <Button
        data-testid="save-button"
        bsStyle="primary"
        disabled={isSubmitting || !isActuallyDirty}
        type="submit"
      >
        {isSubmitting
          ? t('common.saving')
          : saveButtonLabel ?? t('common.save')}
      </Button>
    </PropertyEditActionWrapper>
  );
};

export default FormFooterSaveButton;
