import { useTranslation } from 'react-i18next';
import { LeadChannel, LeadChannelNameMap } from 'models/Leads';
import { getChannelPieLegends } from '../Analytics.utils';
import usePieChartsData from '../usePieChartsData';
import CustomPieChart from './types/pieCharts/CustomPieChart';
import { PieLegend } from './Pies.types';
import { PieChartMetric } from './Charts.types';

const ChannelPieChart = () => {
  const {
    channelPieData,
    isChannelPieLoading,
    channelPieError,
    isInitialChannelPieLoading,
  } = usePieChartsData();
  const { t } = useTranslation();

  const nameKey = 'channel';

  const revenueByChannelLegends: PieLegend[] = getChannelPieLegends(
    channelPieData,
  ).map((legend) => ({
    ...legend,
    name:
      LeadChannelNameMap.get(legend.name as LeadChannel) ||
      t('pageAnalytics.pie.others'),
  }));

  return (
    <div data-testid="channelPieChart-container">
      <CustomPieChart
        isInitialLoading={isInitialChannelPieLoading}
        metric={PieChartMetric.CHANNEL}
        title={t('pageAnalytics.pie.revenueByChannel')}
        nameKey={nameKey}
        dataKey="value"
        pieData={channelPieData}
        isLoading={isChannelPieLoading}
        height={250}
        error={channelPieError}
        legends={revenueByChannelLegends}
      />
    </div>
  );
};

export default ChannelPieChart;
