import { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  [key: string]: any;
}

const FileField = forwardRef<HTMLInputElement, Props>(
  ({ name, ...props }, forwardedRef) => {
    const { register } = useFormContext();
    const { ref, ...restRegister } = register(name);

    return (
      <input
        type="file"
        ref={(e) => {
          ref(e);

          if (typeof forwardedRef === 'function') {
            forwardedRef(e);
          } else if (forwardedRef !== null) {
            forwardedRef.current = e;
          }
        }}
        {...restRegister}
        {...props}
      />
    );
  },
);

export default FileField;
