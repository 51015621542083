const DollarLeadIcon = ({ statusColor }: { statusColor: string }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.905762"
      y="1.65625"
      width="21.6888"
      height="21.6875"
      rx="10.8438"
      stroke={statusColor || '#999999'}
      strokeWidth="1.5"
      strokeMiterlimit="1"
    />
    <path
      d="M5.90576 16.4108C6.11389 18.755 8.03342 20.4186 10.9556 20.6338V22.3438H12.4223V20.6338C15.6145 20.382 17.5945 18.6116 17.5945 15.9931C17.5945 13.7572 16.2628 12.4634 13.4376 11.735L12.4223 11.472V6.12547C14.0001 6.28016 15.0647 7.12953 15.3333 8.39797H17.4004C17.167 6.14797 15.2348 4.53359 12.4223 4.355V2.65625H10.9556V4.39016C8.22889 4.71359 6.35717 6.46016 6.35717 8.82828C6.35717 10.873 7.71561 12.32 10.0992 12.9303L10.957 13.1581V18.8267C9.34123 18.5877 8.22889 17.7017 7.96029 16.4108H5.90576ZM10.6744 11.0164C9.20764 10.6466 8.42436 9.85625 8.42436 8.74391C8.42436 7.41641 9.41436 6.43625 10.9556 6.17328V11.0881L10.6744 11.0178V11.0164ZM12.9117 13.6489C14.7215 14.1031 15.5161 14.8569 15.5161 16.1366C15.5161 17.6792 14.3545 18.7072 12.4223 18.8633V13.5266L12.9117 13.6475V13.6489Z"
      fill={statusColor || '#999999'}
    />
  </svg>
);

export default DollarLeadIcon;
