import { useTranslation } from 'react-i18next';

const CopyrightNotice = () => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <span data-testid="copyright-notice">
      {t('common.layout.footerCopyrightNote', { year })}
    </span>
  );
};

export default CopyrightNotice;
