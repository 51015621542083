import { ReactNode } from 'react';
import { Modal, ModalTitle } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormWithProvider from 'components/forms/form/Form';
import PropertyMultiSelectFilterField from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import { extractPropertiesMultiSelectFields } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.utils';
import { PropertyMultiSelectFieldProps } from 'components/forms/domain/property/PropertyMultiSelectField/PropertyMultiSelectField.types';
import useAppModal from 'hooks/useAppModal';
import { PropertyBase } from 'models/Properties';
import useBulkUpdatePropertiesMutation, {
  BulkUpdateMutationOptions,
  PropertyBulkUpdateRequest,
} from '../useBulkUpdatePropertiesMutation';
import { BulkSavePropertiesModalStyled } from './BulkSavePropertiesModal.styles';
import BookSavePropertiesModalFooter from './BulkSavePropertiesModalFooter';
import BulkSavePropertiesModalTitle from './BulkSavePropertiesModalTitle';
import { bulkSavePropertiesModalId } from './BulkSavePropertiesModal.constants';
import BulkSavePropertiesModalFieldsDescriptions from './BulkSavePropertiesModalFieldsDescriptions';

export interface BulkSavePropertiesModalProps {
  graphQlFilters?: PropertyMultiSelectFieldProps['graphQlFilters'];
  fieldsDescriptions: Record<string, string>;
  filterPropertyBy?: PropertyMultiSelectFieldProps['filterPropertyBy'];
  onError?: BulkUpdateMutationOptions['onError'];
  onSettled?: BulkUpdateMutationOptions['onSettled'];
  onSuccess?: BulkUpdateMutationOptions['onSuccess'];
  preSelectedProperties?: Pick<PropertyBase, 'uid' | 'name'>[];
  propertyBulkUpdateRequest: PropertyBulkUpdateRequest;
  title?: ReactNode;
}

const bulkSaveFormSchema = Yup.object({
  bulkSave: Yup.string().required(),
  bulkSaveProperties: Yup.array()
    .of(Yup.object({ label: Yup.string(), value: Yup.string() }))
    .label('properties')
    .when('bulkSave', {
      is: PROPERTIES_SELECTION.ALL_PROPERTIES,
      then: (schema) => schema.optional().nullable(true),
      otherwise: (schema) => schema.min(1).required(),
    }),
});

type BulkSaveFormSchemaType = Yup.InferType<typeof bulkSaveFormSchema>;

const BulkSavePropertiesModal = ({
  graphQlFilters,
  fieldsDescriptions,
  filterPropertyBy,
  onError,
  onSettled,
  onSuccess,
  preSelectedProperties,
  propertyBulkUpdateRequest,
  title,
}: BulkSavePropertiesModalProps) => {
  const { t } = useTranslation();
  const { closeModal } = useAppModal();
  const { mutateAsync: bulkUpdateProperties, isLoading: isUpdating } =
    useBulkUpdatePropertiesMutation({
      onError,
      onSettled,
      onSuccess: (...args) => {
        closeModal(bulkSavePropertiesModalId);
        return onSuccess?.(...args);
      },
    });

  const handleSubmit = (formValues: BulkSaveFormSchemaType) => {
    return bulkUpdateProperties({
      propertyBulkUpdateRequest,
      ...extractPropertiesMultiSelectFields('bulkSave', formValues),
    });
  };

  return (
    <BulkSavePropertiesModalStyled data-testid="bulk-save-properties-modal">
      <Modal.Header data-testid="bulk-save-properties-modal-header" closeButton>
        <ModalTitle>{title ?? <BulkSavePropertiesModalTitle />}</ModalTitle>
      </Modal.Header>
      <FormWithProvider
        defaultValues={{
          bulkSave: PROPERTIES_SELECTION.ALL_PROPERTIES,
          bulkSaveProperties: preSelectedProperties
            ? preSelectedProperties.map((p) => ({
                label: p.name,
                value: p.uid,
              }))
            : undefined,
        }}
        onSubmit={handleSubmit}
        resolver={bulkSaveFormSchema}
        disabled={isUpdating}
        noValidate
      >
        <fieldset disabled={isUpdating}>
          <Modal.Body>
            <BulkSavePropertiesModalFieldsDescriptions
              fieldsDescriptions={fieldsDescriptions}
            />
            <section data-testid="bulk-save-properties">
              <span>{t('componentProperty.bulkSaveModal.appliedTo')}</span>
              <PropertyMultiSelectFilterField
                name="bulkSave"
                graphQlFilters={graphQlFilters}
                filterPropertyBy={filterPropertyBy}
              />
            </section>
          </Modal.Body>
          <Modal.Footer>
            <BookSavePropertiesModalFooter />
          </Modal.Footer>
        </fieldset>
      </FormWithProvider>
    </BulkSavePropertiesModalStyled>
  );
};

BulkSavePropertiesModal.defaultProps = {
  graphQlFilters: undefined,
  filterPropertyBy: undefined,
  onError: undefined,
  onSettled: undefined,
  onSuccess: undefined,
  preSelectedProperties: undefined,
  title: undefined,
};

export default BulkSavePropertiesModal;
