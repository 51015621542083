import { BaseResponse } from './_base';

export enum FeatureFlag {
  ALLOW_PMP_WEBAPP_AGENCY_CALENDAR = 'ALLOW_PMP_WEBAPP_AGENCY_CALENDAR',
  ALLOW_PRICING_CALENDAR = 'ALLOW_PRICING_CALENDAR',
  ALLOW_ANALYTICS_WEBAPP = 'ALLOW_ANALYTICS_WEBAPP',
  ALLOW_PIPELINE_WEBAPP = 'ALLOW_PIPELINE_WEBAPP',
  ALLOW_PROPERTIES_WEBAPP = 'ALLOW_PROPERTIES_WEBAPP',
  ALLOW_ORDER_VIEW_WEBAPP = 'ALLOW_ORDER_VIEW_WEBAPP',
  ALLOW_NEW_LEAD_MODAL_WEBAPP = 'ALLOW_NEW_LEAD_MODAL_WEBAPP',
  ALLOW_BC_IMPORT = 'ALLOW_BC_IMPORT',
}

export interface AppConfig {
  customSettings: AppCustomSettings;
  featureFlags: FeatureFlag[];
  showIntercom: boolean;
  showVersionNumber: boolean;
  hasAiEnabled: boolean;
}

export interface AppCustomSettings {
  customCssUrl?: string;
  customFooterContent?: string;
  customHeaderContent?: string;
  faviconUrl?: string;
  logoutRedirectUrl?: string;
  logoUrl?: string;
}

export type AppPublicCustomisations = Omit<
  AppCustomSettings,
  'logoutRedirectUrl'
>;

export interface GetAppConfigResponseTO extends BaseResponse {
  webAppConfig: AppConfig;
}

export interface GetAppPublicCustomisationsResponseTO {
  customSettings: AppPublicCustomisations;
  showVersionNumber: boolean;
}
