import styled from 'styled-components';
import theme from 'styles/theme';

export const CustomAmenitiesDeleteModalContainer = styled.div`
  strong {
    color: ${theme.colors.fontGreen};
    font-weight: 400;
  }
`;

export const CustomAmenitiesSection = styled.section`
  margin-top: 40px;
`;
