import { useFormContext } from 'react-hook-form';
import defaultImage from 'assets/images/property/littlehouse.png';

interface BlockModalPropertyImageProps {
  properties: any[];
}

const BlockModalPropertyImage = ({
  properties,
}: BlockModalPropertyImageProps) => {
  const { watch } = useFormContext();

  const currentPropertyUid = watch('currentPropertyUid');

  const currentProperty = properties.find(
    (property) => property?.uid === currentPropertyUid,
  );

  return (
    <img
      src={currentProperty?.mainPicture?.largeThumbnail || defaultImage}
      alt="property"
    />
  );
};

export default BlockModalPropertyImage;
