import { LeadGroupedStatus, LeadSortingStrategy } from 'models/Leads';
import { PipelineFilterFormValues } from './Pipeline.types';

const usePipelineForm = () => {
  const getDefaultValues = (): PipelineFilterFormValues => {
    return {
      sortStrategy: LeadSortingStrategy.CREATION_DATE_DESC,
      range: {
        checkInFrom: '',
        checkInTo: '',
      },
      leadStatus: LeadGroupedStatus.RELEVANT,
      lead: [],
      propertyUid: '',
      channel: '',
      userUid: '',
    };
  };

  return {
    getDefaultValues,
  };
};

export default usePipelineForm;
