import { LinkProps } from 'react-router-dom';
import ExternalLinkIcon from 'assets/images/icons/external-link.svg?react';
import { LinkStyled } from './LinkWithIcon.styles';

interface LinkWithIconProps extends LinkProps {}

const LinkWithIcon = ({ children, ...props }: LinkWithIconProps) => {
  return (
    <LinkStyled {...props}>
      {children}
      <ExternalLinkIcon strokeWidth="10px" />
    </LinkStyled>
  );
};

export default LinkWithIcon;
