import useAppModal from 'hooks/useAppModal';
import BulkSavePropertiesModal, {
  BulkSavePropertiesModalProps,
} from './BulkSavePropertiesModal';
import { bulkSavePropertiesModalId } from './BulkSavePropertiesModal.constants';

const useBulkSaveModal = () => {
  const { openModal } = useAppModal();

  const openBulkSaveModal = ({ ...props }: BulkSavePropertiesModalProps) => {
    openModal({
      customContent: <BulkSavePropertiesModal {...props} />,
      id: bulkSavePropertiesModalId,
      size: 'medium',
    });
  };

  return {
    openBulkSaveModal,
  };
};

export default useBulkSaveModal;
