import { useTranslation } from 'react-i18next';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import {
  CreateTransactionResponseTO,
  ManualTransactionCreationPayload,
} from 'models/Transactions';
import API from 'services/API';

const useCreateManualTransaction = () => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  const { mutateAsync, isLoading } = useAppMutation(
    ({
      transaction,
    }: {
      successCallback: (response: CreateTransactionResponseTO) => void;
      transaction: ManualTransactionCreationPayload;
    }) =>
      API.post<CreateTransactionResponseTO>(
        '/api/internal/transactions/manual',
        transaction,
      ),
    {
      onSuccess: (response, { successCallback }) => {
        successCallback?.(response.data);
      },
      onError: () => {
        notifyError(t('componentTransaction.error.couldNotRecordTransaction'));
      },
    },
  );

  return { createManualTransaction: mutateAsync, isLoading };
};

export default useCreateManualTransaction;
