import { memo, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useFilterIcon from 'hooks/useFilterIcon';
import {
  Container,
  PageHeaderFilterWrapper,
  PageHeaderStyled,
} from './Inbox.styles';
import InboxFilter from './filter/InboxFilter';
import InboxFilterLoader from './filter/InboxFilterLoader';
import InboxThreads from './threads/InboxThreads';
import InboxMessages from './messages/InboxMessages';
import useInboxInit from './useInboxInit';
import InboxThreadRightPanel from './lead/InboxThreadRightPanel';
import InboxLeadStandalone from './lead/InboxLeadStandalone';
import { inboxFilterFormExpandedStorageKey } from './filter/InboxFilter.constants';
import InboxFilterFormProvider from './filter/InboxFilterFormProvider';
import InboxLeadFilter from './lead/InboxLeadFilter';
import { cleanUpMessageContentClassNamesCache } from './messages/InboxMessage.utils';

const InboxBody = memo(() => {
  const { t } = useTranslation();
  const { isLoading } = useInboxInit();
  const { filterIconWithCounterFromForm: FilterIconRenderer, isFilterOpen } =
    useFilterIcon({
      stateStorageKey: inboxFilterFormExpandedStorageKey,
    });

  useEffect(() => {
    return () => {
      cleanUpMessageContentClassNamesCache();
    };
  }, []);

  return (
    <Container>
      <InboxFilterFormProvider>
        <PageHeaderStyled title={t('pageInbox.inboxHeader')}>
          <PageHeaderFilterWrapper>
            <InboxLeadFilter />
            <FilterIconRenderer />
          </PageHeaderFilterWrapper>
        </PageHeaderStyled>

        {!isLoading && <InboxFilter isFilterOpen={isFilterOpen} />}
      </InboxFilterFormProvider>

      {isLoading && <InboxFilterLoader />}
      {!isLoading && (
        <>
          <InboxLeadStandalone />
          <Row>
            <InboxThreads />
            <InboxMessages />
            <InboxThreadRightPanel />
          </Row>
        </>
      )}
    </Container>
  );
});

export default InboxBody;
