import { ComponentPropsWithoutRef } from 'react';

import { GenericFormFieldBaseProps } from 'components/forms/genericFormField/GenericFormField.types';
import { SelectFieldProps } from '../selectField/SelectField.types';
import {
  SelectFieldStyled,
  TextFieldStyled,
  SelectTextWrapper,
} from './SelectTextField.styles';

type InputBaseProps = Omit<
  GenericFormFieldBaseProps,
  'fieldRenderer' | 'name'
> &
  ComponentPropsWithoutRef<'input'>;

export interface SelectTextFieldProps extends InputBaseProps {
  selectFieldName: string;
  textFieldName: string;
  options: SelectFieldProps['options'];
  position?: 'left' | 'right';
}

const SelectTextField = ({
  selectFieldName,
  textFieldName,
  label,
  options,
  position = 'left',
  ...fieldProps
}: SelectTextFieldProps) => {
  const isLeftPosition = position === 'left';

  const selectField = (
    <SelectFieldStyled
      name={selectFieldName}
      key={selectFieldName}
      options={options}
      required
    />
  );

  return (
    <SelectTextWrapper>
      <TextFieldStyled
        name={textFieldName}
        label={label}
        leftAddon={isLeftPosition ? [selectField] : null}
        rightAddons={!isLeftPosition ? [selectField] : null}
        {...fieldProps}
      />
    </SelectTextWrapper>
  );
};

SelectTextField.defaultProps = {
  position: 'left',
};

export default SelectTextField;
