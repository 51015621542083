import { Trans } from 'react-i18next';

const LeadDetailIntValue = ({
  baseLocaleKey,
  value,
}: {
  baseLocaleKey: 'adult' | 'child';
  value: number;
}) => {
  if (value === 1) {
    return (
      <Trans
        i18nKey={`componentLead.details.${baseLocaleKey}SingleWrapped`}
        components={{ numberWrapper: <div /> }}
      />
    );
  }

  return (
    <Trans
      i18nKey={`componentLead.details.${baseLocaleKey}NumberWrapped`}
      values={{ number: value }}
      components={{ numberWrapper: <div /> }}
    />
  );
};

export default LeadDetailIntValue;
