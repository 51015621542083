import { useContext } from 'react';
import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LeadModalContext from '../LeadModalContext';
import useAppModal from '../../../../../hooks/useAppModal';
import useAppUser from '../../../../../hooks/useAppUser';
import Button from '../../../../button/Button';
import Tooltip from '../../../../tooltip/Tooltip';
import { LeadStatus } from '../../../../../models/Leads';
import { canBeDeleted } from '../../../../../utils/lead/leadUtils';

const LeadModalFooterDeleteButton = ({
  deleteLead,
  isDeleting,
}: {
  deleteLead: Function;
  isDeleting: boolean;
}) => {
  const { lead } = useContext(LeadModalContext);
  const { openConfirmModal } = useAppModal();
  const { isEmployeeAssociate } = useAppUser();
  const { isSubmitting } = useFormState();
  const { t } = useTranslation();

  if (!canBeDeleted(lead)) {
    return null;
  }

  const leadTypeMessageKey =
    lead?.status === LeadStatus.BLOCKED ? 'Block' : 'Lead';

  const deleteHandler = () => {
    if (isEmployeeAssociate) {
      return;
    }

    openConfirmModal({
      onConfirm: deleteLead,
      body: t(`componentLead.modal.delete${leadTypeMessageKey}ConfirmMessage`),
      title: t(`componentLead.modal.delete${leadTypeMessageKey}`),
      confirmColor: 'danger',
      confirmLabel: t('common.delete'),
    });
  };

  const deleteButton = (
    <Button
      bsStyle="danger"
      data-testid="delete-lead-button"
      onClick={deleteHandler}
      disabled={isEmployeeAssociate || isDeleting || isSubmitting}
    >
      {t(`common.${isDeleting ? 'deleting' : 'delete'}`)}
    </Button>
  );

  if (isEmployeeAssociate) {
    return (
      <Tooltip
        id="disabled-delete-lead-button-tooltip"
        text={t('common.permissions.actionNotAllowedForAccount')}
      >
        {deleteButton}
      </Tooltip>
    );
  }

  return deleteButton;
};

export default LeadModalFooterDeleteButton;
