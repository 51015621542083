import { PropertyBase } from 'models/Properties';
import { useTranslation } from 'react-i18next';

interface PropertyGuestsBedroomsAndBathroomsCountProps {
  property: Pick<
    PropertyBase,
    'availability' | 'bathroomsNumber' | 'bedroomsNumber'
  >;
}

const PropertyGuestsBedroomsAndBathroomsCount = ({
  property,
}: PropertyGuestsBedroomsAndBathroomsCountProps) => {
  const { t } = useTranslation();

  const guestsBedroomsAndBathrooms = [
    t('pageProperties.body.characteristics.guests', {
      count: property.availability.maxGuests,
    }),
    t('pageProperties.body.characteristics.bedrooms', {
      count: property.bedroomsNumber,
    }),
    t('pageProperties.body.characteristics.bathrooms', {
      count: Number(property.bathroomsNumber),
    }),
  ].join(' | ');

  return <span>{guestsBedroomsAndBathrooms}</span>;
};

export default PropertyGuestsBedroomsAndBathroomsCount;
