import useResponsiveStyleCalendar from '../useResponsiveStyleCalendar';
import { DayCellWrapper } from './DayCell.style';

const OffsetCell = () => {
  const calendarStyleConstants = useResponsiveStyleCalendar();

  return (
    <DayCellWrapper $calendarStyleConstants={calendarStyleConstants} $empty />
  );
};

export default OffsetCell;
