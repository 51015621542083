import styled, { css } from 'styled-components';
import { LeadCircleIconPosition } from './LeadCircleIcon.types';

interface LeadCircleIconProps {
  src: string;
  position: LeadCircleIconPosition;
}

const positions = {
  'top-left': css`
    top: -3px;
    left: -3px;
  `,
  'top-right': css`
    top: -3px;
    right: -3px;
  `,
  'bottom-right': css`
    bottom: -3px;
    right: -3px;
  `,
  'bottom-left': css`
    bottom: -3px;
    left: -3px;
  `,
};

export const LeadCircleIconContainer = styled.img.attrs<LeadCircleIconProps>({
  className: 'lead-circle-icon',
})<LeadCircleIconProps>`
  height: 25px;
  width: 25px;
  position: absolute;
  z-index: 2;
  ${({ position }) => positions[position]}
`;
