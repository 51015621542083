export const debounce =
  (callback: (...args: any[]) => void, time: number = 250, interval?: any) =>
  (...args) => {
    clearTimeout(interval);
    interval = setTimeout(() => callback(...args), time);
  };

export const convertToBase64 = (file: File) => {
  if (!file) return Promise.resolve('');

  return new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result as string);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
