import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import PageHeader from 'components/pageHeader/PageHeader';
import { Col } from 'react-bootstrap';
import { Container, OrderViewTableHeader } from '../OrderView.styles';
import {
  OrderViewDetailsItem,
  OrderViewDetailsItemList,
  OrderViewDetailsWrapper,
} from '../details/OrderViewDetails.styles';
import {
  OrderViewPriceDetailsListWrapper,
  PriceDetailsList,
} from '../priceDetails/OrderViewPriceDetails.styles';
import { OrderViewPriceDetailsListItemRowWrapper } from '../priceDetails/OrderViewPriceDetailsListItemRow.styles';
import { OrderViewPriceDetailsListItemWrapper } from '../priceDetails/OrderViewPriceDetailsListItem.styles';
import {
  OrderViewDetailsLeadItemPlaceholder,
  OrderViewDetailsLeadPlaceholder,
} from './OrderViewLoader.styles';

const OrderViewLoader = () => {
  return (
    <Container>
      <PageHeader isLoading />
      <OrderViewDetailsWrapper>
        <OrderViewDetailsLeadPlaceholder>
          <LoadingPlaceholder circle width="70px" />
          <OrderViewDetailsLeadItemPlaceholder>
            {Array.from({ length: 3 }).map((_, index) => (
              <LoadingPlaceholder
                // eslint-disable-next-line react/no-array-index-key
                key={`order-view-lead-item-${index}`}
                linesCount={1.2}
                margin="0px 0px 15px 25px"
                width="75%"
              />
            ))}
          </OrderViewDetailsLeadItemPlaceholder>
        </OrderViewDetailsLeadPlaceholder>
        <div>
          <OrderViewDetailsItemList>
            {Array.from({ length: 2 }).map((_, index) => (
              <OrderViewDetailsItem
                // eslint-disable-next-line react/no-array-index-key
                key={`order-view-details-item-order-${index}`}
              >
                <LoadingPlaceholder
                  linesCount={1.2}
                  margin="0px 0px 15px 25px"
                  width="75%"
                />
              </OrderViewDetailsItem>
            ))}
          </OrderViewDetailsItemList>
        </div>
      </OrderViewDetailsWrapper>

      <div>
        <OrderViewTableHeader>
          <Col xs={6}>
            <LoadingPlaceholder
              linesCount={1.8}
              margin="0 0 15px 0"
              width="75%"
            />
          </Col>
        </OrderViewTableHeader>

        <OrderViewPriceDetailsListWrapper>
          <PriceDetailsList>
            <OrderViewPriceDetailsListItemWrapper>
              {Array.from({ length: 6 }).map((_, index) => (
                <OrderViewPriceDetailsListItemRowWrapper
                  // eslint-disable-next-line react/no-array-index-key
                  key={`order-view-price-details-item-${index}`}
                  $hasBorder={false}
                  $isBold={false}
                >
                  <div>
                    <span>
                      <LoadingPlaceholder linesCount={1} width="120px" />
                    </span>
                  </div>
                  <div className="text-right">
                    <LoadingPlaceholder linesCount={1} width="60px" />
                  </div>
                </OrderViewPriceDetailsListItemRowWrapper>
              ))}
            </OrderViewPriceDetailsListItemWrapper>
          </PriceDetailsList>
        </OrderViewPriceDetailsListWrapper>
      </div>
    </Container>
  );
};

export default OrderViewLoader;
