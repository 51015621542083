const CALENDAR_CELL_BORDER_PX = '0.5';

export const calendarStyleConstants = {
  border: `${CALENDAR_CELL_BORDER_PX}px solid rgb(221, 221, 221)`,
  monthWidthInPercentage: 47,
  days: {
    height: 70,
  },
  headerDays: {
    height: 50,
  },
  leadLabelColor: {
    ICS: '#FFD458',
    default: 'white',
  },
  cellBorderPx: CALENDAR_CELL_BORDER_PX,
};

export const calendarMobileStyleConstants = {
  border: `${CALENDAR_CELL_BORDER_PX}px solid rgb(221, 221, 221)`,
  monthWidthInPercentage: 100,
  days: {
    height: 70,
  },
  headerDays: {
    height: 50,
  },
  leadLabelColor: {
    ICS: '#FFD458',
    default: 'white',
  },
  cellBorderPx: CALENDAR_CELL_BORDER_PX,
};
export const calendarConstants = {
  numberOfMonthsToDisplayOnCalendar: 6,
};
