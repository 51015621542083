import useAppConfig from 'hooks/useAppConfig';
import { GuestCustomFooterStyled } from './GuestFooter.styles';

const GuestFooter = () => {
  const {
    customSettings: { customFooterContent },
  } = useAppConfig();

  if (customFooterContent) {
    return (
      <GuestCustomFooterStyled>
        <div className="container">
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: customFooterContent }} />
        </div>
      </GuestCustomFooterStyled>
    );
  }

  return null;
};

export default GuestFooter;
