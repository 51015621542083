import { useContext, useEffect } from 'react';
import useFetchLead from 'components/domain/lead/useFetchLead';
import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';
import { QuoteResponse } from 'models/Quote';
import useAppUser from 'hooks/useAppUser';
import { format } from 'date-fns';
import { getLeadCheckInDate, getLeadCheckOutDate } from 'utils/lead/leadUtils';
import usePaypal from 'hooks/usePaypal';
import AdjustmentModalContext from './AdjustmentModalContext';

const leadFields = `uid,
  address,
  address2,
  adultCount,
  agreementSigned,
  arrivalLocalDateTime,
  assignee{bestProfile, uid},
  availablePipelineActions,
  bookedDateTimeString,
  cellPhoneNumber,
  channel{bestProfile, channel},
  checkInLocalDateTime,
  checkOutLocalDateTime,
  childrenCount,
  city,
  countdown{color, countdownMinutes, countdownPercentage, visualCountdownValue},
  countryCode,
  departureLocalDateTime,
  email,
  firstName,
  hasCompletedPreArrivalForm,
  infantCount,
  lastName,
  notes,
  order{uid, currency, totalAmount, force100PercentPaymentAtReservation, securityDeposit, paymentLinkSecret, paymentLinkUrl, balance},
  petCount,
  phoneNumber,
  preferredLocale,
  property{uid},
  reviewByHost{cleanlinessRating, communicationRating, notes, overallRating, respectRulesRating, uid},
  secondaryEmail,
  source,
  state,
  status,
  wereCheckInInstructionsSent,
  zipCode,
  willHaveParty,
  willHaveVisit,
  passportID,
  passportCountryCode,
  customData{uid, name, type, value{text, longText}},
  guest{identificationDocumentUrl, registrationToken, uid},
  extraNotes,
  flightNumber,
  reasonForTrip,
  rentalAgreementURL,
  creationMetadata,
  externalBookingID,
  pinCode{pinCode, pinCodeUrl, uid}
  wasSecurityDepositReceived,
  wasSecurityDepositReturned,
  extraGuests{email, firstName, lastName, age},
  fieldsPermissionsMetadata{fieldName,metadata {editable}}
  unitUid
`;

const useAdjustmentModalData = (leadUid: string) => {
  const { setLead, setIsARefund } = useContext(AdjustmentModalContext);
  const { isFetching: isFetchingLead, lead } = useFetchLead({
    leadUid,
    fields: leadFields,
  });
  const usePaypalIntegration = usePaypal(lead?.property.uid);
  const { agencyUid } = useAppUser();

  setLead(lead);

  const { data: quote, isLoading: isQuoteLoading } = useAppQuery(
    [leadUid],
    async () => {
      const response = await API.post<QuoteResponse>('/api/v3/quotes', {
        leadUid,
        overrides: null,
        agencyUid,
        propertyUid: lead.property.uid,
        checkInDate: format(getLeadCheckInDate(lead), 'yyyy-MM-dd'),
        checkOutDate: format(getLeadCheckOutDate(lead), 'yyyy-MM-dd'),
        guests: lead.adultCount + lead.childrenCount,
      });
      return response.data.quote;
    },
    {
      enabled: !!agencyUid && !!lead,
    },
  );

  useEffect(() => {
    if (quote) {
      const amountToAdjust = quote?.adjustments
        .filter((adjustment) => !adjustment.isPaid)
        .reduce((acc, adjustment) => {
          return acc + adjustment.amount;
        }, 0);

      setIsARefund(amountToAdjust < 0);
    }
  }, [quote]);

  return {
    isLoading: isFetchingLead || isQuoteLoading,
    lead,
    usePaypalIntegration,
  };
};

export default useAdjustmentModalData;
