import { useTranslation } from 'react-i18next';
import { PropertyExpectationType } from 'models/PropertyExpectations';

const NameCell = ({
  getValue,
}: {
  getValue: () => PropertyExpectationType;
}) => {
  const { t } = useTranslation();
  const value = getValue();

  return (
    <span>
      {t(
        `pageProperty.feesTaxesAndPolicies.propertyExpectations.expectationType.${value}`,
      )}
    </span>
  );
};

export default NameCell;
