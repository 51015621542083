import { ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericFormFieldBaseProps } from 'components/forms/genericFormField/GenericFormField.types';
import { AREA_UNIT_TYPE } from 'models/AreaUnitType';
import SelectTextField from 'components/forms/selectTextField/SelectTextField';

type InputBaseProps = Omit<GenericFormFieldBaseProps, 'fieldRenderer'> &
  ComponentPropsWithoutRef<'input'>;

export interface AreaSizeFieldProps extends InputBaseProps {
  areaUnitTypeFieldName: string;
}

const AreaSizeField = ({
  name,
  areaUnitTypeFieldName,
  min,
  ...fieldProps
}: AreaSizeFieldProps) => {
  const { t } = useTranslation();

  const options = Object.values(AREA_UNIT_TYPE).map((value) => ({
    label: t(`pageProperty.mainSettings.areaUnitType.${value}`),
    value,
  }));

  return (
    <SelectTextField
      textFieldName={name}
      selectFieldName={areaUnitTypeFieldName}
      options={options}
      min={min}
      position="left"
      {...fieldProps}
    />
  );
};

export default AreaSizeField;
