import PriceTag from 'components/priceTag/PriceTag';
import { Lead } from 'models/Leads';

const LeadListItemPriceTag = ({ lead }: { lead: Lead }) => {
  const { order } = lead;

  if (!order) {
    return null;
  }

  const { currency, totalAmount } = order;

  return (
    <PriceTag
      currency={currency}
      data-testid="lead-price-tag"
      value={totalAmount}
    />
  );
};

export default LeadListItemPriceTag;
