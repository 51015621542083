import useAppQuery from '../../../hooks/useAppQuery';
import API from '../../../services/API';
import {
  GetTagsSuggestionsResponseTO,
  Tag,
  TagType,
} from '../../../models/Tags';
import useAppUser from '../../../hooks/useAppUser';

const useFetchTagSuggestions = (tagType: TagType, enabled: boolean) => {
  const { isEmployee } = useAppUser();

  const { data: tags, isInitialLoading: isFetching } = useAppQuery(
    [`tag-suggestions-${tagType}`, tagType],
    async (): Promise<Tag[]> => {
      const response = await API.get<GetTagsSuggestionsResponseTO>(
        `/v3/tags?suggestions=true&tagType=${tagType}`,
      );
      return response.data.tags;
    },
    {
      enabled: enabled && !!isEmployee,
    },
  );

  return { isFetching, tags };
};

export default useFetchTagSuggestions;
