import { ReactNode } from 'react';
import { FilterContainerStyled } from './FilterContainer.styles';

export interface FilterContainerProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'ref' | 'as'> {
  isCollapsed: boolean;
  className?: string;
  children?: ReactNode;
}

const FilterContainer = ({
  isCollapsed,
  className,
  children,
  ...props
}: FilterContainerProps) => {
  return (
    <FilterContainerStyled
      className={className}
      $isCollapsed={isCollapsed}
      {...props}
    >
      {children}
    </FilterContainerStyled>
  );
};

FilterContainer.defaultProps = {
  className: '',
  children: undefined,
};

export default FilterContainer;
