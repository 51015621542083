import styled from 'styled-components';

export const FilterIconContainer = styled.button.attrs({
  'data-testid': 'filter-icon',
})`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  border-radius: 4px;

  display: flex;
  align-items: center;
  height: 36px;

  cursor: pointer;

  svg > rect {
    fill: white;
  }

  svg > path {
    fill: #337ab7;
  }

  .selected-filters-counter {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #337ab7;
    color: white;
    border-radius: 10px;
    padding: 0 4px;
    min-width: 20px;
    height: 20px;
    font-weight: 600;
    margin-right: 8px;
    font-size: 14px;
  }

  &.active {
    background: #337ab7;

    svg > rect {
      fill: #337ab7;
    }

    svg > path {
      fill: white;
    }

    .selected-filters-counter {
      background: white;
      color: #337ab7;
    }
  }

  &:hover:not(&.active) {
    background: #337ab7;
    svg > rect {
      fill: #337ab7;
    }

    svg > path {
      fill: white;
    }

    .selected-filters-counter {
      background: #337ab7;
      color: white;
    }
  }
`;
