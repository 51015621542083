import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import DateTimePickerField from 'components/forms/dateTimePickerField/DateTimePickerField';
import SelectField from 'components/forms/selectField/SelectField';
import TextField from 'components/forms/textField/TextField';
import AmountField from 'components/forms/amountField/AmountField';
import { ADJUSTMENT_TYPES, AdjustmentType } from './OwnerAdjustmentModal.types';
import useAdjustmentTypeOptions from './hooks/useAdjustmentTypeOptions';
import useRecurringPeriodOptions from './hooks/useRecurringExpenseOptions';
import OwnerAdjustmentModalUploadField from './OwnerAdjustmentModalUploadField';

const OwnerAdjustmentModalFormFields = () => {
  const { t } = useTranslation();
  const adjustmentType: AdjustmentType = useWatch({ name: 'adjustmentType' });
  const isNew = !useWatch({ name: 'uid' });
  const adjustmentTypeOptions = useAdjustmentTypeOptions();
  const recurringPeriodOptions = useRecurringPeriodOptions();
  const isOneTimeAdjustment = adjustmentType === ADJUSTMENT_TYPES.ONE_TIME;
  const isRecurringAdjustment = adjustmentType === ADJUSTMENT_TYPES.RECURRING;

  return (
    <>
      {isNew && (
        <SelectField
          label={t('pageOwnerAdjustments.modal.form.adjustmentType')}
          name="adjustmentType"
          options={adjustmentTypeOptions}
          required
        />
      )}

      {isRecurringAdjustment && (
        <SelectField
          label={t('pageOwnerAdjustments.modal.form.recurringPeriod')}
          name="recurringExpensePeriod"
          options={recurringPeriodOptions}
          required
        />
      )}

      <TextField
        label={t('pageOwnerAdjustments.modal.form.name')}
        name="name"
        required
      />

      {isOneTimeAdjustment && (
        <DateTimePickerField
          name="dateAccountedFor"
          label={t('pageOwnerAdjustments.modal.form.dateAccountedFor')}
          required
        />
      )}

      {isRecurringAdjustment && (
        <DateTimePickerField
          name="nextScheduled"
          label={t('pageOwnerAdjustments.modal.form.startOn')}
          required
        />
      )}

      {isRecurringAdjustment && (
        <DateTimePickerField
          name="endRecurringSchedule"
          label={t('pageOwnerAdjustments.modal.form.endOn')}
        />
      )}

      <AmountField
        label={t('pageOwnerAdjustments.modal.form.amount')}
        name="amount"
        currencyFieldName="currency"
        step="any"
        required
      />

      <TextField
        label={t('pageOwnerAdjustments.modal.form.taxRate')}
        name="taxationRate"
        type="number"
        min={0}
        step="any"
        required
        rightAddons={['%']}
      />

      <TextField
        componentClass="textarea"
        label={t('pageOwnerAdjustments.modal.form.notes')}
        name="notes"
      />

      <OwnerAdjustmentModalUploadField />
    </>
  );
};

export default OwnerAdjustmentModalFormFields;
