import { Dispatch, SetStateAction } from 'react';
import { Lead, LeadAvailablePipelineAction } from '../../../../models/Leads';

export const LeadPipelineTemplateActions = [
  LeadAvailablePipelineAction.SEND_QUOTE,
  LeadAvailablePipelineAction.PLACE_ON_HOLD,
  LeadAvailablePipelineAction.SEND_CHECK_IN_INSTRUCTIONS,
] as const;

// export type LeadPipelineTemplateAction =  (typeof LeadPipelineTemplateActions)[number]; will fail prettier
export type LeadPipelineTemplateAction =
  | LeadAvailablePipelineAction.SEND_QUOTE
  | LeadAvailablePipelineAction.PLACE_ON_HOLD
  | LeadAvailablePipelineAction.SEND_CHECK_IN_INSTRUCTIONS;

export enum TemplateName {
  QUOTE = 'Quote',
  HOLD_CONFIRMATION = 'Hold Confirmation',
  ALTERNATIVE_PROPERTY_OPTIONS = 'Alternative Property Options',
  MINIMUM_STAY = 'Minimum Stay',
  HOLD_PAYMENT_REMINDER = 'Hold Reminder',
  HOLD_REMINDER = 'Hold Reminder (no payment)',
  BALANCE_DUE = 'Balance Due / Expired Booking',
  PRE_ARRIVAL_FORM = 'Pre-Arrival Form',
  EMAIL_SERVICE_PROVIDER = 'Notification for new Job',
}

export const PipelineActionTemplateNameMap = new Map<
  LeadAvailablePipelineAction,
  TemplateName
>([
  [
    LeadAvailablePipelineAction.SEND_ALTERNATIVE_OPTIONS,
    TemplateName.ALTERNATIVE_PROPERTY_OPTIONS,
  ],
  [
    LeadAvailablePipelineAction.SEND_MINIMUM_STAY_NOTE,
    TemplateName.MINIMUM_STAY,
  ],
  [
    LeadAvailablePipelineAction.SEND_PAYMENT_REMINDER,
    TemplateName.HOLD_PAYMENT_REMINDER,
  ],
  [LeadAvailablePipelineAction.SEND_REMINDER, TemplateName.HOLD_REMINDER],
  [LeadAvailablePipelineAction.SEND_BALANCE_REQUEST, TemplateName.BALANCE_DUE],
  [
    LeadAvailablePipelineAction.SEND_PRE_ARRIVAL_FORM,
    TemplateName.PRE_ARRIVAL_FORM,
  ],
]);

export interface TemplateModalContextProps {
  lead?: Lead;
  modalId: string;
  setLead: Dispatch<SetStateAction<Lead>>;
}

export interface TemplateModalParams {
  leadUid?: string;
  jobUid?: string;
  templateName?: TemplateName;
  leadPipelineTemplateAction?: LeadPipelineTemplateAction;
  updateLeadCallback?: () => void;
}

export interface TemplateModalProps {
  modalId: string;
  params: TemplateModalParams;
}
