import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { htmlMessageWrapperId } from 'components/editor/RichTextEditor.constants';
import { Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const EmailModalConfirmContainer = () => {
  const { t } = useTranslation();
  const [editor] = useLexicalComposerContext();
  const { setValue } = useFormContext();

  const onClick = () => {
    const htmlContent =
      editor.getRootElement().firstElementChild?.id === htmlMessageWrapperId
        ? editor.getRootElement().firstElementChild
        : editor.getRootElement();

    setValue('htmlContent', htmlContent);
  };

  return (
    <Button type="submit" bsStyle="primary" onClick={onClick}>
      {t('componentTemplate.sendMessage')}
    </Button>
  );
};

export default EmailModalConfirmContainer;
