import * as Yup from 'yup';
import { AmenityType, PropertyAmenity } from 'models/Amenity';
import { DeepPartial, FieldNamesMarkedBoolean } from 'react-hook-form';

export const categorizedAmenitiesSchema = () =>
  Yup.object({
    amenities: Yup.object(
      Object.values(AmenityType).reduce(
        (acc, type) => ({
          ...acc,
          [type]: Yup.object({
            description: Yup.string().nullable(),
            value: Yup.boolean().required(),
          }),
        }),
        {},
      ),
    ),
  });

export type CategorizedAmenitiesFormValues = DeepPartial<
  Yup.InferType<ReturnType<typeof categorizedAmenitiesSchema>>
>;

export type CategorizedAmenitiesFormDirtyFields = Partial<
  Readonly<FieldNamesMarkedBoolean<CategorizedAmenitiesFormValues>>
>;

export function getFormDefaultValues(
  propertyAmenities: PropertyAmenity[],
): CategorizedAmenitiesFormValues {
  const baseValues = Object.values(AmenityType).reduce(
    (acc, amenity) => ({
      ...acc,
      [amenity]: {
        description: null,
        value: false,
      },
    }),
    {},
  );

  return {
    amenities: propertyAmenities?.reduce(
      (acc, { amenity, description }) => ({
        ...acc,
        [amenity]: {
          description,
          value: true,
        },
      }),
      baseValues,
    ),
  };
}
