import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { IntercomProvider } from 'react-use-intercom';
import { Provider } from 'react-redux';
import { store } from 'store';
import { Toaster } from 'react-hot-toast';

import GlobalStyles from 'styles/GlobalStyles';
import Router from './router';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'i18n/setupInternationalization';
import Modals from './components/modal/Modals';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      cacheTime: 0,
    },
  },
});

const intercomAppId = 'p5dki4sl';

const App = () => {
  return (
    <>
      <GlobalStyles />
      <IntercomProvider appId={intercomAppId}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <Router />
            <Modals />
          </Provider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </IntercomProvider>

      <Toaster
        position="bottom-left"
        reverseOrder={false}
        toastOptions={{ duration: 5000 }}
      />
    </>
  );
};

export default App;
