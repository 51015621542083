import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';
import FormWithProvider from 'components/forms/form/Form';
import { Col } from 'react-bootstrap';

export const Container = styled(FormWithProvider)`
  input,
  select,
  textarea {
    width: calc(100% - 2px);
    max-width: 270px;
  }

  select {
    margin-left: 4px;
  }

  ${mediaQuery.xs} {
    input,
    select,
    textarea {
      width: calc(80% - 2px);
    }
  }
`;

export const PropertyWrapper = styled.div`
  .property-container {
    display: flex;
    margin-left: -80px;
  }

  select {
    margin-left: -6px;
  }

  ${mediaQuery.xs} {
    .property-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: -60px;
    }

    select {
      margin: 10px auto 0;
    }
  }
`;

export const JobWrapper = styled.div`
  margin-left: 10%;
`;

export const FooterWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DatePickerWrapper = styled.div`
  position: relative;
  height: 34px;
  margin-bottom: 14px;

  > div {
    display: flex;

    label {
      position: absolute;
      width: 18%;
      display: flex;
      justify-content: flex-end;
      white-space: nowrap;
      padding-right: -15px;
    }

    input {
      position: absolute;
      left: 22%;
      top: 0;
      bottom: 0;
      transform: translateY(-50%);
      display: flex;
      justify-content: flex-start;
    }

    span {
      top: -2px;
      right: 56px;
    }

    ${mediaQuery.xs} {
      input {
        left: 28%;
      }

      span {
        right: -25px;
      }
    }
  }
`;

export const ColWithMargin = styled(Col)<{ textRight?: boolean }>`
  margin-top: 10px;
  margin-bottom: 10px;
  display: block !important;
  padding-left: 10px;
  ${({ textRight }) => textRight && 'text-align: right;'}
`;
