import { ButtonProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import { Actions } from './FilterFormButtons.styles';

export interface FilterFormClearButtonProps
  extends Omit<ButtonProps, 'ref' | 'as' | 'bsStyle' | 'type'> {}
export interface FilterFormSubmitButtonProps
  extends Omit<ButtonProps, 'ref' | 'as' | 'bsStyle' | 'type'> {}

export const FilterFormClearButton = ({
  children,
  ...props
}: FilterFormClearButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      type="button"
      data-testid="clear-filters"
      bsStyle="default"
      {...props}
    >
      {children ?? t('form.clearFilters')}
    </Button>
  );
};

export const FilterFormSubmitButton = ({
  children,
  ...props
}: FilterFormSubmitButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      type="submit"
      data-testid="submit-filters"
      bsStyle="primary"
      {...props}
    >
      {children ?? t('form.applyFilters')}
    </Button>
  );
};

export const FilterFormActions = Actions;
