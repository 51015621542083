import useAppMutation from 'hooks/useAppMutation';
import API from 'services/API';

interface Parameters {
  onSuccess: () => void;
  onError: () => void;
}

const useForgotPasswordSubmit = ({ onSuccess, onError }: Parameters) => {
  const { mutate: submitResetPassword } = useAppMutation(
    ['resetPassword'],
    (email: string) => API.post('/reset-password', { email }),
    {
      onSuccess,
      onError,
    },
  );

  return { submitResetPassword };
};

export default useForgotPasswordSubmit;
