import { Trans, useTranslation } from 'react-i18next';
import { queryClient } from 'App';
import Button from 'components/button/Button';
import useCancelScheduledAutoRefund from 'components/domain/transaction/useCancelScheduledAutoRefund';
import useAppModal from 'hooks/useAppModal';
import useFormatCurrency from 'hooks/useFormatCurrency';
import { Transaction } from 'models/Transactions';
import { getTransactionAutoRefundDate } from 'utils/tests/transaction/transactionUtils';
import { LEAD_GQL_QUERY_BASE } from 'components/domain/lead/useFetchLead';
import useOrderViewFetchedData from '../../useOrderViewFetchedData';
import {
  AutoScheduledInfoContainer,
  ClockIcon,
} from './CancelScheduledRefundButton.styles';

const CancelScheduledRefundButton = ({
  transaction,
}: {
  transaction: Transaction;
}) => {
  const { openConfirmModal } = useAppModal();
  const { cancelScheduledAutoRefund, isLoading } =
    useCancelScheduledAutoRefund();
  const {
    lead: {
      order: { currency, securityDeposit },
      uid: leadUid,
    },
  } = useOrderViewFetchedData();
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation();

  if (isLoading) {
    return t('common.canceling');
  }

  const amount = formatCurrency({
    currency,
    value: securityDeposit,
    options: {
      round: false,
      removeDecimals: securityDeposit % Math.floor(securityDeposit) === 0,
    },
  });

  const { uid: transactionUid } = transaction;

  const onClick = () => {
    openConfirmModal({
      body: (
        <Trans
          i18nKey="pageOrder.transactions.actions.confirmCancelScheduledRefundMessage"
          components={{ lineBreak: <br /> }}
          values={{ amount, date: getTransactionAutoRefundDate(transaction) }}
        />
      ),
      confirmColor: 'danger',
      confirmLabel: t('pageOrder.transactions.actions.cancelScheduledRefund'),
      title: t('pageOrder.transactions.actions.cancelScheduledRefund'),
      onConfirm: () => {
        cancelScheduledAutoRefund({
          transactionUid,
          successCallback: () => {
            queryClient.invalidateQueries([LEAD_GQL_QUERY_BASE, leadUid]);
          },
        });
      },
    });
  };

  return (
    <>
      <AutoScheduledInfoContainer data-testid="auto-scheduled-refund-info">
        <ClockIcon className="clock-icon" />
        {t('pageOrder.transactions.autoRefundInfo', {
          amount,
          date: getTransactionAutoRefundDate(transaction),
        })}
      </AutoScheduledInfoContainer>
      <div>
        <Button
          bsStyle="link"
          data-testid="cancel-scheduled-refund-button"
          onClick={onClick}
        >
          {t('pageOrder.transactions.actions.cancelScheduledRefund')}
        </Button>
      </div>
    </>
  );
};

export default CancelScheduledRefundButton;
