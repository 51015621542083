/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import ReactSlider from 'react-slider';
import theme from 'styles/theme';

export const RangeFieldContainer = styled.div`
  position: relative;
  // height: 48px;
`;

export const RangeFieldStyled = styled(ReactSlider)<{ disabled?: boolean }>`
  ${({ disabled }) => disabled && 'filter: grayscale(1);'}
  height: 28px;
`;

export const RangeFieldValues = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  width: 100%;
`;

export const RangeFieldTrack = styled.div<{ index: number }>`
  background: #f3f3f3;
  border: 1px solid #ccc;
  height: 16px;
  border-radius: 4px;
  top: 6px;

  background: ${({ index }) =>
    index === 2 || index === 0 ? '#fafafa' : theme.colors.bgGreen};
`;

export const RangeFieldThumb = styled.div`
  height: 28px;
  width: 34px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  //inner shadow
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;

  &.active {
    outline: none;
    border: 1px solid #ccc;
  }

  ::before,
  ::after {
    content: '';
    display: block;
    position: absolute;
    height: 14px;
    width: 1px;
    background: #e8e7e6;
    top: 6px;
  }

  ::before {
    left: 14px;
  }

  ::after {
    left: 17px;
  }
`;

export const RangeFieldRail = styled.div``;
