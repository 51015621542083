import { Col, Modal, ModalTitle, Row } from 'react-bootstrap';
import Button from 'components/button/Button';
import { Trans, useTranslation } from 'react-i18next';
import { ImportBCPropertiesModalStyled } from './ImportBookingDotComPropertiesModal.styles';

const ImportBookingDotComPropertiesConfirmationModal = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <ImportBCPropertiesModalStyled data-testid="import-bc-properties-confirmation-modal">
      <Modal.Header data-testid="import-bc-properties-modal-header" closeButton>
        <ModalTitle>
          {t('pageProperties.importBookingDotComPropertiesModal.title')}
        </ModalTitle>
      </Modal.Header>

      <Modal.Body>
        <p>
          {t(
            'pageProperties.importBookingDotComPropertiesModal.confirmation.yourPropertiesAreBeingImported',
          )}
        </p>
        <p>
          {t(
            'pageProperties.importBookingDotComPropertiesModal.confirmation.mayTakeAWhile',
          )}
        </p>
        <p>
          <Trans
            i18nKey="pageProperties.importBookingDotComPropertiesModal.confirmation.reviewDocumentation"
            defaults="Please create a service <link1>here</link1>."
            components={{
              link1: (
                // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                <a
                  target="_blank"
                  href="https://help.hostfully.com/en/articles/10145918-import-properties-from-booking-com"
                  rel="noreferrer"
                />
              ),
            }}
          />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col xs={12}>
            <Button
              bsStyle="primary"
              data-testid="modal-close-button"
              onClick={onClose}
            >
              {t('common.close')}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </ImportBCPropertiesModalStyled>
  );
};

export default ImportBookingDotComPropertiesConfirmationModal;
