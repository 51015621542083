import { useCallback } from 'react';
import { removeActiveModal, setActiveModal } from '../store/slices/modal';
import { modalIds } from '../components/modal/Modal.constants';
import {
  ConfirmModalProps,
  CustomContentModalProps,
  ModalType,
} from '../components/modal/Modal.types';
import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';

const useAppModal = () => {
  const activeModals = useAppSelector((state) => state.modal.activeModals);
  const dispatch = useAppDispatch();

  const openConfirmModal = useCallback(
    (props: ConfirmModalProps) => {
      dispatch(
        setActiveModal({
          modalId: modalIds.confirm,
          modalData: {
            ...props,
            type: ModalType.CONFIRM,
          },
        }),
      );
    },
    [dispatch],
  );

  const openModal = useCallback(
    ({ id: modalId, ...props }: CustomContentModalProps) => {
      dispatch(
        setActiveModal({
          modalId,
          modalData: {
            ...props,
            type: ModalType.CUSTOM_CONTENT,
          },
        }),
      );
    },
    [dispatch],
  );

  const closeModal = useCallback(
    (modalId: string) => {
      dispatch(removeActiveModal({ modalId }));
    },
    [dispatch],
  );

  const setErrorConfirmModal = useCallback(
    (errorMessage: string) => {
      if (activeModals[modalIds.confirm]) {
        dispatch(
          setActiveModal({
            modalId: modalIds.confirm,
            modalData: {
              ...activeModals[modalIds.confirm],
              errorMessage,
            },
          }),
        );
      }
    },
    [activeModals, dispatch],
  );

  return { openConfirmModal, openModal, closeModal, setErrorConfirmModal };
};

export default useAppModal;
