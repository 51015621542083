import { Table } from '@tanstack/react-table';

export const handlePaginationAfterDelete = (tableInstance: Table<any>) => {
  const currentPageRows = tableInstance.getRowModel().rows;
  const isOnlyOneItemOnPage = currentPageRows.length === 1;
  const shouldGoBackOnePage =
    isOnlyOneItemOnPage && tableInstance.getCanPreviousPage();

  if (shouldGoBackOnePage) {
    tableInstance.previousPage();
  }
};
