import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatISO } from 'date-fns';
import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import useAppQuery from 'hooks/useAppQuery';
import useNotify from 'hooks/useNotify';
import API from 'services/API';
import { getAvailableI18nLanguage } from 'utils/languageUtils';

const useFetchEmailProperties = (
  propertiesListJoined: string,
  availableFrom: Date | null,
  availableTo: Date | null,
  modalId: string,
) => {
  const { closeModal } = useAppModal();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { notifySuccess } = useNotify();

  const { data: emailSuggester, refetch: refetchEmailSuggester } = useAppQuery(
    ['emailSuggester', propertiesListJoined],
    async () => {
      const response = await API.get(
        `/api/internal/templates/available-properties-list?propertiesUids=${propertiesListJoined}${
          availableFrom
            ? `&checkInDate=${formatISO(availableFrom, {
                representation: 'date',
              })}`
            : ''
        }${
          availableTo
            ? `&checkOutDate=${formatISO(availableTo, {
                representation: 'date',
              })}`
            : ''
        }&locale=${getAvailableI18nLanguage(language)}`,
      );
      return response.data.template;
    },
  );

  const { mutate: sendPropertiesEmail } = useAppMutation(
    (payload: {
      content: string;
      bcc: boolean;
      subject: string;
      to: string;
      case: string;
    }) => {
      const formData = {
        propertiesUID: propertiesListJoined,
        showEditTemplateButton: 'false',
      };
      Object.keys(payload).forEach((key) => {
        formData[key] = payload[key];
      });

      return API.post('/process_message_flow.jsp', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      });
    },
    {
      onSuccess: () => {
        notifySuccess(t('componentTemplate.sendEmail.emailSentSuccessfully'));
        closeModal(modalId);
      },
    },
  );

  useEffect(() => {
    if (propertiesListJoined) {
      refetchEmailSuggester();
    }
  }, [propertiesListJoined, availableFrom, availableTo]);

  return { emailSuggester, sendPropertiesEmail };
};

export default useFetchEmailProperties;
