import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAppQuery from '../../../../hooks/useAppQuery';
import API from '../../../../services/API';
import useAppUser from '../../../../hooks/useAppUser';
import {
  AvailableTemplate,
  GetAvailableTemplatesResponseTO,
  TemplateTarget,
  TemplateType,
} from '../../../../models/Templates';
import InboxContext from '../../InboxContext';
import { normalizeKey } from '../../../../utils/localeUtils';

const useInboxAvailableTemplates = () => {
  const { isEmployee, agencyUid } = useAppUser();
  const { availableTemplates, setAvailableTemplates } =
    useContext(InboxContext);
  const { t } = useTranslation();

  const {
    data: fetchedAvailableTemplates,
    isInitialLoading: isLoadingTemplates,
  } = useAppQuery(
    ['availableTemplates', agencyUid],
    async () => {
      let fetchedTemplates;

      await Promise.all(
        Object.keys(TemplateType).map(async (templateType) => {
          const response = await API.get<GetAvailableTemplatesResponseTO>(
            '/v3/available-templates',
            {
              params: {
                agencyUid,
                templateType,
                target:
                  templateType === TemplateType.SYSTEM_AUTOMATIC_TEMPLATE
                    ? TemplateTarget.GUEST
                    : undefined,
              },
            },
          );

          return {
            [templateType]: response.data['available-templates'],
          };
        }),
      ).then((templates) => {
        fetchedTemplates = templates.reduce(
          (accumulator, singleTypeTemplates) => ({
            ...accumulator,
            ...singleTypeTemplates,
          }),
          {},
        );
      });

      return fetchedTemplates;
    },
    { enabled: isEmployee && !availableTemplates },
  );

  useEffect(() => {
    if (fetchedAvailableTemplates) {
      Object.values(fetchedAvailableTemplates).forEach(
        (templates: AvailableTemplate[]) => {
          templates.forEach((template) => {
            const { name } = template;
            const localeKey = name
              .trim()
              .toUpperCase()
              .replace(/([.,!?<>+=()/\\-])/g, '')
              .replace(/\s+/g, '_');
            template.localizedName = t([
              normalizeKey(`pageInbox.messages.templates.names.${localeKey}`),
              normalizeKey(name),
            ]);
          });

          templates.sort(({ localizedName: name1 }, { localizedName: name2 }) =>
            name1.localeCompare(name2),
          );
        },
      );

      setAvailableTemplates(fetchedAvailableTemplates);
    }
  }, [fetchedAvailableTemplates]);

  return { isLoadingTemplates };
};

export default useInboxAvailableTemplates;
