import { LeadStatus, LeadSource } from 'models/Leads';

export const TRANSPARENT_STATUSES = [LeadStatus.ON_HOLD, LeadStatus.PENDING];

export const LEAD_STATUS_WITHOUT_PAYMENT_STATUS = [
  LeadStatus.NEW,
  LeadStatus.QUOTE_SENT,
  LeadStatus.CLOSED_QUOTE,
  LeadStatus.ON_HOLD,
  LeadStatus.HOLD_EXPIRED,
  LeadStatus.CLOSED_HOLD,
  LeadStatus.SAMPLE,
  LeadStatus.PENDING,
  LeadStatus.DECLINED,
  LeadStatus.IGNORED,
];

export const IMPORT_SOURCES = [
  LeadSource.IMPORT_HOMEAWAY,
  LeadSource.IMPORT_VRBO,
  LeadSource.IMPORT_BOOKINGCOM,
  LeadSource.IMPORT_ABRITEL,
  LeadSource.IMPORT_AIRBNB,
  LeadSource.IMPORT_TRIPADVISOR,
];

export const SOURCES_ORDER_NOT_EDITABLE = [
  LeadSource.DIRECT_AIRBNB,
  LeadSource.DIRECT_TRIPPING,
  LeadSource.DIRECT_BOOKINGDOTCOM,
];
