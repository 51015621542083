export enum RoomTypes {
  APARTMENT = 'Apartment',
  BED_IN_DORMITORY = 'Bed_in_Dormitory',
  BUNGALOW = 'Bungalow',
  CHALET = 'Chalet',
  DORMITORY_ROOM = 'Dormitory_room',
  DOUBLE = 'Double',
  FAMILY = 'Family',
  HOLIDAY_HOME = 'Holiday_home',
  MOBILE_HOME = 'Mobile_home',
  QUADRUPLE = 'Quadruple',
  SINGLE = 'Single',
  STUDIO = 'Studio',
  SUITE = 'Suite',
  TENT = 'Tent',
  TRIPLE = 'Triple',
  TWIN = 'Twin',
  TWIN_DOUBLE = 'Twin_Double',
  VILLA = 'Villa',
}
