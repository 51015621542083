import { useLocation } from 'react-router-dom';
import {
  LayoutMobileContainer,
  LayoutMobileContent,
} from './LayoutMobile.styles';
import LayoutMobileHeader from './LayoutMobileHeader';
import LayoutMobileFooter from './LayoutMobileFooter';

const LayoutMobile = ({ children }) => {
  const { pathname } = useLocation();

  const isLoginPage = pathname.includes('login');

  return (
    <LayoutMobileContainer>
      <LayoutMobileHeader />

      <LayoutMobileContent>{children}</LayoutMobileContent>

      {!isLoginPage && <LayoutMobileFooter />}
    </LayoutMobileContainer>
  );
};

export default LayoutMobile;
