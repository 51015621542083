/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from 'react-i18next';
import { Glyphicon } from 'react-bootstrap';
import Tooltip from 'components/tooltip/Tooltip';
import useNotify from './useNotify';

const useCopyToClipboard = () => {
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useNotify();

  const copyToClipBoard = async (link: string, message?: string) => {
    try {
      await navigator.clipboard.writeText(link);
      if (message) {
        notifySuccess(message);
      }
    } catch (err) {
      notifyError(t('common.somethingWentWrong'));
    }
  };

  const getCopyAddonIcon = (string: string, message?: string) => {
    return (
      <Tooltip id="copy-addon-tooltip" text={t('common.copy')}>
        <a
          href="#"
          data-testid="copy-addon"
          onClick={(e) => {
            e.preventDefault();
            copyToClipBoard(
              string,
              message || t('common.copyToClipboardSuccess'),
            );
          }}
        >
          <Glyphicon glyph="duplicate" />
        </a>
      </Tooltip>
    );
  };

  return { copyToClipBoard, getCopyAddonIcon };
};

export default useCopyToClipboard;
