import { useContext } from 'react';
import { Row } from '@tanstack/react-table';
import { queryClient } from 'App';
import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import API from 'services/API';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import TableContext from 'components/table/TableContext';
import { CustomAmenity } from 'models/Amenity';
import useNotify from 'hooks/useNotify';
import { handlePaginationAfterDelete } from 'utils/pagination';
import { PROPERTIES_CUSTOM_AMENITIES_BASE_QUERY_KEY } from '../useCustomAmenitiesData';
import { CustomAmenitiesDeleteModalContainer } from '../CustomAmenities.styles';

const useDeleteModal = ({ row }: { row: Row<CustomAmenity> }) => {
  const { t } = useTranslation();
  const { tableInstance } = useContext(TableContext);
  const { uid: propertyUid } = useParams();
  const { openConfirmModal, setErrorConfirmModal } = useAppModal();
  const { notifySuccess } = useNotify();

  const customAmenityUid = row.original?.uid;
  const customAmenityName = row.original?.name;

  const { mutateAsync: deleteCustomAmenity } = useAppMutation(
    ['customAmenityDelete', customAmenityUid],
    async ({ uid }: { uid: CustomAmenity['uid'] }) => {
      await API.delete(`api/v3/custom-amenities/${uid}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          PROPERTIES_CUSTOM_AMENITIES_BASE_QUERY_KEY,
          'detail',
          propertyUid,
        ]);

        handlePaginationAfterDelete(tableInstance);

        notifySuccess(
          t('pageProperty.amenities.customAmenities.deleteModal.success', {
            name: customAmenityName,
          }),
        );
      },
      onError: (error: any) => {
        setErrorConfirmModal(
          getErrorMessage({
            apiErrorCode: error.response.data?.apiErrorCode,
            apiErrorMessage: error.response.data?.apiErrorMessage,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      },
    },
  );

  const openConfirmDeleteModal = () => {
    openConfirmModal({
      body: (
        <CustomAmenitiesDeleteModalContainer>
          <Trans
            i18nKey="pageProperty.amenities.customAmenities.deleteModal.description"
            values={{ name: row.original?.name }}
            components={{
              highlight: <strong />,
            }}
          />
        </CustomAmenitiesDeleteModalContainer>
      ),
      onConfirmAsync: () =>
        deleteCustomAmenity({
          uid: customAmenityUid,
        }),
      title: t('pageProperty.amenities.customAmenities.deleteModal.title'),
      confirmLabel: t('common.delete'),
      confirmColor: 'danger',
    });
  };

  return {
    openConfirmDeleteModal,
  };
};

export default useDeleteModal;
