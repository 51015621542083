import { Row } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import useAppModal from 'hooks/useAppModal';
import { Discount } from 'models/Discounts';
import { useDeleteDiscountMutation } from 'components/domain/discount/useDeleteDiscountMutation';

interface ActionsCellProps<T> {
  row: Row<T>;
}

const ActionsCell = <T extends Discount>({ row }: ActionsCellProps<T>) => {
  const { t } = useTranslation();
  const { openConfirmModal } = useAppModal();
  const { mutate: deleteDiscount, isLoading: isDeleting } =
    useDeleteDiscountMutation();

  const handleDeleteClick = () => {
    openConfirmModal({
      onConfirm: () => deleteDiscount(row.original.uid),
      body: t('pageDiscountCodes.pageDiscountCodes.deleteConfirmMessage'),
      title: t('pageDiscountCodes.pageDiscountCodes.deleteTitle'),
      confirmColor: 'danger',
      confirmLabel: t('common.delete'),
    });
  };

  return (
    <Button
      bsStyle="link"
      data-testid="delete-button"
      disabled={isDeleting}
      onClick={handleDeleteClick}
    >
      {t('common.delete')}
    </Button>
  );
};

export default ActionsCell;
