import React, { SetStateAction } from 'react';
import { PropertyBase } from 'models/Properties';
import { Channel } from 'models/Channels';
import { LeadChannelTO } from 'models/Leads';
import { Employee } from 'models/Employees';
import { TimeSpan } from 'utils/dateTimeUtils';

export type AnalyticsBasicProperty = Pick<
  PropertyBase,
  'uid' | 'name' | 'businessType'
> & {
  pricing: Pick<PropertyBase['pricing'], 'currency'>;
} & {
  subUnits: Array<
    Pick<PropertyBase['subUnits'][number], 'uid' | 'name' | 'businessType'>
  >;
} & {
  unitTypes: Array<
    Pick<PropertyBase['unitTypes'][number], 'uid' | 'name' | 'businessType'>
  >;
};

export interface AnalyticsBasicPropertyResponse {
  data: {
    properties: AnalyticsBasicProperty[];
  };
}

export enum DateType {
  CHECK_IN = 'CHECK_IN',
  CHECK_OUT = 'CHECK_OUT',
  OCCUPANCY = 'OCCUPANCY',
  RESERVATION = 'RESERVATION',
  LEAD_CREATION = 'LEAD_CREATION',
}

export enum ValueType {
  PERCENTAGE = 'PERCENTAGE',
  CURRENCY = 'CURRENCY',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  STRING = 'STRING',
}

export type AnalyticsContextProps = {
  filter: AnalyticsFilter;
  setFilter: React.Dispatch<React.SetStateAction<AnalyticsFilter>>;
  allProperties: AnalyticsBasicProperty[];
  setAllProperties: React.Dispatch<
    React.SetStateAction<AnalyticsBasicProperty[]>
  >;
  leadChannels: LeadChannelTO[];
  setLeadChannels: React.Dispatch<SetStateAction<LeadChannelTO[]>>;
  employees: Employee[];
  setEmployees: React.Dispatch<SetStateAction<Employee[]>>;
  analyticsFilterLocalStorageKey: string;
  chartsFilterLocalStorageKey: string;
};

export interface AnalyticsFilter {
  range: {
    startDate: Date;
    endDate: Date;
  };
  propertyUid?: string;
  channel?: Channel;
  currency: string;
  dateType: DateType;
  timeSpan: TimeSpan;
}
