import { Alert } from 'react-bootstrap';
import styled from 'styled-components';

export const PipelineLeadsListContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const PipelineErrorMessage = styled(Alert).attrs({
  bsStyle: 'danger',
  className: 'text-center',
})``;

export const PipelineLeadsListEndMessage = styled.p.attrs({
  className: 'text-center',
})`
  margin-top: 1em;
`;
