import { OrderViewPriceDetailsListItemWrapper } from './OrderViewPriceDetailsListItem.styles';
import OrderViewPriceDetailsListItemTitle from './OrderViewPriceDetailsListItemTitle';
import OrderViewPriceDetailsListItemRow, {
  PriceDetailsItem,
} from './OrderViewPriceDetailsListItemRow';

const OrderViewPriceDetailsListItem = ({
  title,
  priceDetailsItems,
}: {
  title: string;
  priceDetailsItems: PriceDetailsItem[];
}) => {
  return (
    <OrderViewPriceDetailsListItemWrapper>
      <OrderViewPriceDetailsListItemTitle title={title} />
      {priceDetailsItems
        .filter((item) => !!item)
        .map(({ ...props }) => (
          <OrderViewPriceDetailsListItemRow
            key={`${title}-${props.label}-${props.amount}`}
            {...props}
          />
        ))}
    </OrderViewPriceDetailsListItemWrapper>
  );
};

export default OrderViewPriceDetailsListItem;
