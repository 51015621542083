import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const Container = styled.div`
  hr {
    width: 100%;
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid #eee;
  }

  .guest-information {
    label {
      font-weight: 500;
    }
  }
`;

export const SideInfo = styled.div`
  margin-top: 10px;
`;

export const RowSpacer = styled.div`
  width: 100%;
  float: left;
  margin-bottom: 15px;
`;

export const PinCodeImage = styled.p``;

export const PinCodeInputWrapper = styled.div`
  & input {
    font-size: 40px;
    font-weight: bold;
    line-height: 35px;
    height: 45px;
    color: #ff6633;
    padding: 0;
    border: 0;
    box-shadow: none;
  }
`;

export const PinCodeContainer = styled(Col)`
  .pin-code-valid {
    color: rgb(202, 199, 199);
    font-size: 13px;
  }
`;

export const PinCodeWrapper = styled.div<{ $color?: string }>`
  display: flex;
  flex-direction: column;
  font-size: '12px';
  ${({ $color }) => ($color ? `color: ${$color}; margin-bottom: 15px;` : '')}

  .form-group > div {
    padding: 0;
  }
`;
