import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import MFACodeForm from 'components/domain/user/mfaCodeForm/MFACodeForm';
import FormWithProvider from 'components/forms/form/Form';
import Loader from 'components/loader/Loader';
import { MFAType } from 'components/domain/user/mfaCodeForm/MFA.types';
import loginSchema from '../Login.schema';
import LoginForm from '../LoginForm';
import LoginPageTitle from '../LoginPageTitle';
import useLoginFormInitialValue from '../useLoginFormInitialValue';
import useLoginRedirectionParams from '../useLoginRedirectionParams';
import useLoginSubmit from '../useLoginSubmit';
import useResendMFACode from '../useResendMFACode';
import AlertMessage from './AlertMessage';
import LoginDesktopSignUpLink from './LoginDesktopSignUpLink';

const LoginDesktop = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { handleSubmitLogin, clearErrors, errorMessage } = useLoginSubmit();
  const { isLoading, initialValue } = useLoginFormInitialValue();
  const { resendCode } = useResendMFACode();
  useLoginRedirectionParams();

  const showMFA = !!searchParams.get('mfaCode');
  const mfaType = searchParams.get('mfaType') as MFAType;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <FormWithProvider
      horizontal
      onSubmit={handleSubmitLogin}
      schema={loginSchema(showMFA)}
      defaultValues={initialValue}
    >
      <Row>
        <Col xs={12} sm={6} smOffset={3}>
          <LoginPageTitle />
          {showMFA ? (
            <MFACodeForm
              type={mfaType}
              resendCode={resendCode}
              codeSentTo={searchParams.get('mfaCodeSentTo')}
              clearErrors={clearErrors}
              errorMessage={errorMessage}
            />
          ) : (
            <>
              <AlertMessage />
              <LoginForm
                clearErrors={clearErrors}
                errorMessage={errorMessage}
              />
              <LoginDesktopSignUpLink />
              <Link
                className="action-link"
                data-testid="forgot-password-link"
                to="/forgot-password"
              >
                {t('pageLogin.forgotPassword')}
              </Link>
            </>
          )}
        </Col>
      </Row>
    </FormWithProvider>
  );
};

export default LoginDesktop;
