import styled from 'styled-components';

export const LoadingPlaceholderWrapper = styled.div`
  text-align: center;
`;

export const CustomPieChartInner = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomPieChartWrapper = styled.div`
  h2 {
    margin-top: 33px;
    font-size: 16px;
    color: #737373;
  }

  p {
    color: #737373;
  }
`;

export const ChannelLegendsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 130px;
  z-index: -1;
`;

export const InnerLabelPieChart = styled.div`
  display: flex;
`;

export const ChannelLegendWrapper = styled.p<{ $color: string }>`
  padding-left: 15px;
  position: relative;
  height: fit-content;

  :before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 10px;
    top: 5px;
    height: 10px;
    border-radius: 50%;
    ${(props) => `background-color: ${props.$color};`}
  }
`;
