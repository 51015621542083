import { useSearchParams } from 'react-router-dom';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MFAType } from 'components/domain/user/mfaCodeForm/MFA.types';
import {
  GetStartedMessage,
  TwoFactorAuthTitle,
  WelcomeTitle,
} from './Login.styles';

const LoginPageTitle = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { registrationToken } = useWatch();
  const mfaCode = searchParams.get('mfaCode');
  const mfaType = searchParams.get('mfaType');

  if (registrationToken) {
    return (
      <GetStartedMessage>
        {t('pageLogin.getStartedCreateAccount')}
      </GetStartedMessage>
    );
  }

  if (mfaCode) {
    return (
      <TwoFactorAuthTitle>
        {mfaType === MFAType.EMAIL
          ? t('pageLogin.titleMFAEmail')
          : t('pageLogin.titleMFAPhone')}
      </TwoFactorAuthTitle>
    );
  }

  return <WelcomeTitle>{t('pageLogin.title')}</WelcomeTitle>;
};

export default LoginPageTitle;
