import { useContext, useState } from 'react';
import { getLeadDetailLocalizedIntValue } from 'utils/lead/leadUtils';
import LeadModalContext from 'components/domain/lead/modal/LeadModalContext';
import { DiscountCodeType } from 'models/Discounts';
import useFormatCurrency from 'hooks/useFormatCurrency';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { differenceInCalendarDays } from 'date-fns';
import ToggleButton from 'components/button/ToggleButton';
import { PreWarning, TDWithoutBorder } from './Fields.styles';
import PercentageField from './common/PercentageField';
import AmountField from './common/AmountField';

interface RentProps {
  isEditable: boolean;
  isAPayout: boolean;
}

const Rent = ({ isEditable, isAPayout }: RentProps) => {
  const { quote } = useContext(LeadModalContext);
  const [showBreakdown, setShowBreakdown] = useState(false);
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation();
  const { checkInDate, checkOutDate } = useWatch();

  const nightsCount = differenceInCalendarDays(checkOutDate, checkInDate);
  const { setValue } = useFormContext();

  const rentAmount = isAPayout ? quote.totalPrice : quote.rent.rentBaseNetPrice;
  const totalRentBreakdownPrice = quote.rent.rentBreakdowns
    ?.map((b) => b.nightlyBaseAmount)
    .reduce((acc, current) => acc + current, 0);
  const showBreakdowns =
    quote.rent.rentBreakdowns?.length > 0 &&
    totalRentBreakdownPrice === rentAmount;

  return (
    <>
      <tr>
        <td>
          {isAPayout ? t('componentOrder.total') : t('componentOrder.rent')}
        </td>
        <td>{getLeadDetailLocalizedIntValue(nightsCount, 'night', t)}</td>
        <td>
          {showBreakdowns && (
            <ToggleButton
              onClick={() => setShowBreakdown(!showBreakdown)}
              isExpanded={showBreakdown}
              toggleVertically
            />
          )}
        </td>
        <td>
          <AmountField
            value={isAPayout ? quote.totalPrice : quote.rent.rentBaseNetPrice}
            isEditable={isEditable}
            currency={quote.currency}
            onItemUpdated={(val) => {
              setValue('overrides.rent.rentBaseNetPrice', val);
            }}
          />
        </td>
      </tr>
      {showBreakdown &&
        quote.rent.rentBreakdowns?.map((ord) => {
          return (
            <tr key={`breakdown_${ord.nightlyDate}`}>
              <TDWithoutBorder />
              <TDWithoutBorder colSpan={2}>{ord.nightlyDate}</TDWithoutBorder>
              <TDWithoutBorder>
                {formatCurrency({
                  currency: quote.currency,
                  value: ord.nightlyBaseAmount,
                })}
              </TDWithoutBorder>
            </tr>
          );
        })}
      {!!quote.rent.extraGuestsBaseNetPrice && (
        <tr>
          <td />
          <td>{t('componentOrder.extraGuests')}</td>
          <td />
          <td>
            <AmountField
              value={quote.rent.extraGuestsBaseNetPrice}
              isEditable={isEditable}
              currency={quote.currency}
              onItemUpdated={(val) => {
                setValue('overrides.rent.extraGuestsBaseNetPrice', val);
              }}
            />
          </td>
        </tr>
      )}

      {!isAPayout && !!quote.rent.rateMultiplierAmount && (
        <>
          <tr>
            <td>{t('componentOrder.rateMultiplier')}</td>
            <td>
              <PercentageField
                value={quote.rent.rateMultiplier}
                isEditable={false}
                onItemUpdated={() => {}}
              />
            </td>
            <td />
            <td>
              <AmountField
                value={quote.rent.rateMultiplierAmount}
                isEditable={false}
                currency={quote.currency}
                onItemUpdated={() => {}}
              />
            </td>
          </tr>
          <tr>
            <td>{t('componentOrder.totalRent')}</td>
            <td />
            <td />
            <td>
              <AmountField
                value={quote.rent.listPrice}
                isEditable={false}
                currency={quote.currency}
                onItemUpdated={() => {}}
              />
            </td>
          </tr>
        </>
      )}

      {!!quote.rent.discount && (
        <tr>
          <td>{t('componentOrder.discountCode')}</td>
          <td>{quote.rent.discount.discountCode}</td>
          <td />
          <td>
            {quote.rent.discount.discountType === DiscountCodeType.PERCENT
              ? `-${quote.rent.discount.percent}%`
              : `-${formatCurrency({
                  currency: quote.currency,
                  value: quote.rent.discount.amount,
                })}`}
          </td>
        </tr>
      )}

      {!isAPayout && quote.rent.taxationRate > 0 && (
        <>
          <tr>
            <td>{t('componentOrder.taxes')}</td>
            <td>
              <PercentageField
                value={quote.rent.taxationRate}
                isEditable={isEditable}
                onItemUpdated={(val) => {
                  setValue('overrides.rent.taxationRate', val);
                }}
              />
            </td>
            <td />
            <td>
              <AmountField
                value={quote.rent.taxAmount}
                isEditable={isEditable}
                currency={quote.currency}
                onItemUpdated={(val) => {
                  setValue('overrides.rent.taxAmount', val);
                }}
              />
            </td>
          </tr>
          <tr>
            <PreWarning style={{ minWidth: '80px' }}>
              <b>{t('componentOrder.subTotal')}</b>
            </PreWarning>
            <PreWarning colSpan={100}>
              {formatCurrency({
                currency: quote.currency,
                value: quote.rent.grossPrice,
              })}
            </PreWarning>
          </tr>
        </>
      )}
    </>
  );
};

export default Rent;
