import { SortingState } from '@tanstack/react-table';
import { getSortParam } from 'components/table/utils/sorting';
import { OwnerAdjustment } from 'models/ownerAdjustments/OwnerAdjustment';
import { OwnerAdjustmentSchedule } from 'models/ownerAdjustments/OwnerAdjustmentSchedule';

const mapColumnToSortParam: {
  [key in keyof Partial<OwnerAdjustment & OwnerAdjustmentSchedule>]: string;
} = {
  created: 'SORT_BY_CREATED_ON_DATE',
  dateAccountedFor: 'SORT_BY_DATE_ACCOUNTED_FOR',
  name: 'SORT_BY_NAME',
  amount: 'SORT_BY_AMOUNT',
  nextScheduled: 'SORT_BY_NEXT_SCHEDULED',
  recurringExpensePeriod: 'SORT_BY_PERIOD',
  status: 'SORT_BY_STATUS',
};

export const getQueryParams = ({
  propertyUid,
  pageIndex,
  pageSize,
  sorting,
}: {
  propertyUid: string;
  pageIndex: number;
  pageSize: number;
  sorting: SortingState;
}) => {
  const { sort, direction } = getSortParam(sorting, mapColumnToSortParam) ?? {};
  const params = new URLSearchParams();
  params.set('propertyUid', propertyUid);
  params.set('_offSet', `${pageIndex * pageSize}`);
  params.set('_limit', `${pageSize}`);
  if (sort && direction) {
    params.set('sort', sort);
    params.set('sortDirection', direction);
  }

  return params.toString();
};
