import useAppUser from '../../../../hooks/useAppUser';

const useNavigationDropdownTitle = () => {
  const { user, isGuest } = useAppUser();

  if (isGuest) {
    return user?.bestProfile;
  }

  return user?.agency?.name || '';
};

export default useNavigationDropdownTitle;
