import { useContext, useEffect } from 'react';
import useFetchAllProperties from 'components/domain/property/useFetchAllProperties';
import useAppQuery from '../../hooks/useAppQuery';
import API from '../../services/API';
import useAppUser from '../../hooks/useAppUser';
import useFetchEmployees from '../../components/domain/employee/useFetchEmployees';
import { GetAllLeadChannelsResponseTO } from '../../models/Leads';
import InboxContext from './InboxContext';
import {
  inboxCalendarConditionGraphQLQuery,
  inboxGraphQLQuery,
} from './filter/InboxFilter.utils';
import { InboxProperty } from './Inbox.types';

const useInboxInit = () => {
  const { isEmployee } = useAppUser();
  const { setEmployees, setLeadChannels, setProperties, setPropertiesMap } =
    useContext(InboxContext);
  const { employees, isFetching: isEmployeesLoading } = useFetchEmployees();
  const { allProperties: properties, isFetching: isPropertiesLoading } =
    useFetchAllProperties({
      enabled: isEmployee,
      conditions: inboxCalendarConditionGraphQLQuery,
      fields: inboxGraphQLQuery,
    });

  const { data: leadChannels, isLoading: isLeadChannelsLoading } = useAppQuery(
    ['leadChannels'],
    async () => {
      const response = await API.get<GetAllLeadChannelsResponseTO>(
        '/v3/leads-channels',
      );
      return response.data.leadChannels;
    },
    { enabled: isEmployee },
  );

  useEffect(() => {
    if (employees) {
      setEmployees(employees);
    }

    if (leadChannels) {
      setLeadChannels(leadChannels);
    }

    if (properties) {
      const propertiesMap = properties.reduce((accumulator, property) => {
        accumulator[property.uid] = property;

        if (property.subUnits) {
          property.subUnits.forEach((subUnit) => {
            accumulator[subUnit.uid] = subUnit;
          });
        }

        if (property.unitTypes) {
          property.unitTypes.forEach((unitType) => {
            accumulator[unitType.uid] = unitType;
          });
        }

        return accumulator;
      }, {});
      setProperties(properties as InboxProperty[]);
      setPropertiesMap(propertiesMap);
    }
  }, [employees, leadChannels, properties]);

  return {
    isLoading:
      isEmployee &&
      (isEmployeesLoading || isLeadChannelsLoading || isPropertiesLoading),
  };
};

export default useInboxInit;
