import PlaceholderProfile from 'assets/images/placeholder-profile.png';

const useProfileAvatar = ({
  pictureUrl,
  firstName,
  lastName,
}: {
  pictureUrl?: string;
  firstName?: string;
  lastName?: string;
}) => {
  if (pictureUrl) {
    return (
      <img className="profile-image" src={pictureUrl} alt="User profile" />
    );
  }

  if (firstName && lastName) {
    return (
      <div className="profile-placeholder">
        {firstName?.charAt(0)}
        {lastName?.charAt(0)}
      </div>
    );
  }

  return (
    <img
      className="profile-image"
      src={PlaceholderProfile}
      alt="User profile"
    />
  );
};

export default useProfileAvatar;
