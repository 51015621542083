import { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { CONNECTION_STEP } from '../../modal/ImportBookingDotComPropertiesModal.utils';
import ImportBookingDotComPropertiesContext from '../../modal/ImportBookingDotComPropertiesContext';

const ConnectProviderButton = ({
  renderAsLink,
  label,
}: {
  renderAsLink: boolean;
  label: string;
}) => {
  const { setCurrentStep } = useContext(ImportBookingDotComPropertiesContext);
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);
  const isCypress = typeof Cypress !== 'undefined';
  const urlLink = !isCypress
    ? 'https://admin.booking.com/?groups_redirect=%2Fhotel%2Fhoteladmin%2Fextranet_ng%2Fmanage%2Fchannel-manager%2Fwidget.html?provider_id=684'
    : '#';

  const onClick = () => {
    setCurrentStep(CONNECTION_STEP.REFRESH);
  };

  useEffect(() => {
    if (!isCypress) {
      const BCscript = document.createElement('script');
      BCscript.src = `https://bstatic.com/psb/provdash/js/booking-widget.js`;
      BCscript.onload = () => {
        setScriptLoaded(true);
      };

      document.body.appendChild(BCscript);

      return () => {
        document.body.removeChild(BCscript);
      };
    }

    return undefined;
  }, []);

  return (
    <Button
      disabled={!scriptLoaded && !isCypress}
      data-testid="connect-provider-button"
      id="booking-widget"
      onClick={onClick}
      className={renderAsLink ? 'btn-link' : 'bui-button btn-success'}
      href={urlLink}
      data-provider-name="Hostfully Platform"
      data-widget-width="900"
    >
      <span className="bui-button__text">{label}</span>
    </Button>
  );
};

export default ConnectProviderButton;
