import { OwnerAdjustment } from 'models/ownerAdjustments/OwnerAdjustment';
import { OwnerAdjustmentSchedule } from 'models/ownerAdjustments/OwnerAdjustmentSchedule';

export const ADJUSTMENT_TYPES = {
  ONE_TIME: 'one-time',
  RECURRING: 'recurring',
} as const;

export type AdjustmentType =
  (typeof ADJUSTMENT_TYPES)[keyof typeof ADJUSTMENT_TYPES];

export const ADJUSTMENT_TYPE_KEYS = Object.values(ADJUSTMENT_TYPES);

export interface OwnerAdjustmentModalProps {
  defaultAdjustment:
    | Partial<OneTimeAdjustmentForm & OwnerAdjustmentFormMiscData>
    | Partial<RecurringAdjustmentForm & OwnerAdjustmentFormMiscData>;
  propertyUid: string;
}

type OwnerAdjustmentFormMiscData = {
  adjustmentType: AdjustmentType;
  propertyUid: string;
  uid?: string;
};

export type OneTimeAdjustmentForm = Pick<
  OwnerAdjustment,
  | 'name'
  | 'amount'
  | 'currency'
  | 'notes'
  | 'dateAccountedFor'
  | 'taxationRate'
  | 'attachedImageURL'
  | 'attachedImageName'
>;

export type RecurringAdjustmentForm = Pick<
  OwnerAdjustmentSchedule,
  | 'name'
  | 'amount'
  | 'currency'
  | 'notes'
  | 'nextScheduled'
  | 'endRecurringSchedule'
  | 'recurringExpensePeriod'
  | 'taxationRate'
  | 'attachedImageURL'
  | 'attachedImageName'
>;

export type OwnerAdjustmentFormData = OneTimeAdjustmentForm &
  RecurringAdjustmentForm &
  OwnerAdjustmentFormMiscData;
