import { Col, Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useFetchAgencyChannelSettings from 'components/domain/channelSettings/useFetchAgencyChannelSettings';
import LeadChannelIcon from 'components/domain/lead/LeadChannelIcon';
import TextField from 'components/forms/textField/TextField';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { Channel } from 'models/Channels';
import { LeadChannel } from 'models/Leads';
import {
  FormSectionColumn,
  SectionTitleContainer,
} from 'pages/property/common/Common.styles';
import { PropertySettingsMainTabFormValues } from '../../PropertySettingsMainTab.schema';
import { BookingDotComCollapseStyled } from './BookingDotComSettings.styles';
import RoomTypeField from './RoomTypeField';
import RoomNameField from './RoomNameField';
import PrimaryContactField from './PrimaryContactField';
import StatusField from './StatusField';
import CancellationPolicyField from './CancellationPolicyField';

const BookingDotComSettings = () => {
  const { t } = useTranslation();
  const { getCopyAddonIcon } = useCopyToClipboard();
  const {
    bookingDotComSettings: { hotelId, roomId },
  } = useWatch<PropertySettingsMainTabFormValues>();

  const { channelSettings, isFetching: isLoadingChannelSettings } =
    useFetchAgencyChannelSettings(true);

  const isBookingDotComActive = !!channelSettings?.find(
    ({ enumId }) => enumId === Channel.booking_com,
  )?.isActive;

  if (!isBookingDotComActive || isLoadingChannelSettings) {
    return null;
  }

  const sectionTitle = (
    <SectionTitleContainer>
      <LeadChannelIcon channel={LeadChannel.BOOKING_COM} />
      {t('pageProperty.mainSettings.bookingDotComSettings.sectionTitle')}
    </SectionTitleContainer>
  );

  return (
    <BookingDotComCollapseStyled
      data-testid="booking-dot-com-settings"
      defaultExpanded
      label={sectionTitle}
      variant="card"
    >
      <fieldset>
        <Row>
          <FormSectionColumn>
            <TextField
              label={t(
                'pageProperty.mainSettings.bookingDotComSettings.hotelId',
              )}
              name="bookingDotComSettings.hotelId"
              colSmInput={7}
              colSmLabel={5}
              disabled
              rightAddons={[getCopyAddonIcon(hotelId)]}
            />
            <TextField
              label={t(
                'pageProperty.mainSettings.bookingDotComSettings.roomId',
              )}
              name="bookingDotComSettings.roomId"
              colSmInput={7}
              colSmLabel={5}
              disabled
              rightAddons={[getCopyAddonIcon(roomId)]}
            />
            <StatusField />
            <RoomTypeField />
            <RoomNameField />
          </FormSectionColumn>
          <FormSectionColumn>
            <PrimaryContactField />
          </FormSectionColumn>
        </Row>
        <Row>
          <Col xs={12}>
            <CancellationPolicyField />
          </Col>
        </Row>
      </fieldset>
    </BookingDotComCollapseStyled>
  );
};

export default BookingDotComSettings;
