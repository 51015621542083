/* eslint-disable react/require-default-props */
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { addDays } from 'date-fns';
import useOpenLeadModal from 'components/domain/lead/modal/useOpenLeadModal';
import * as S from './Navigation.styles';

const calendarPagePath = '/calendar';
const allowedPages = ['/dashboard', '/pipeline', calendarPagePath];

interface AddLeadButtonProps {
  onMobileClick?: () => void;
}

const AddLeadButton = ({ onMobileClick }: AddLeadButtonProps) => {
  const { pathname } = useLocation();
  const { createLeadModal } = useOpenLeadModal();
  const { t } = useTranslation();

  const isAllowedPage = allowedPages.some((path) => pathname.startsWith(path));

  if (!isAllowedPage) {
    return null;
  }

  const isCalendarPage = pathname.startsWith(calendarPagePath);

  const handleAddLead = () => {
    if (onMobileClick) onMobileClick();

    const dateFrom = new Date();

    createLeadModal({
      dateFrom,
      dateTo: addDays(dateFrom, 1),
      jobCreationNotAllowed: !isCalendarPage,
    });
  };

  return (
    <S.AddLeadButton id="addLeadButton" bsSize="xs" onClick={handleAddLead}>
      <i className="glyphicon glyphicon-plus" />
      {t('componentLead.addLead')}
    </S.AddLeadButton>
  );
};

export default AddLeadButton;
