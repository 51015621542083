/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import useAppModal from 'hooks/useAppModal';
import Button from 'components/button/Button';
import { Modal } from 'react-bootstrap';
import FormWithProvider from 'components/forms/form/Form';
import { useTranslation } from 'react-i18next';
import RichTextEditor from 'components/editor/RichTextEditor';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import TagsSelectField from 'components/forms/domain/tag/TagsSelectField';
import TextField from 'components/forms/textField/TextField';
import { Container, EmailsWrapper } from './EmailModal.styles';
import emailSchema from './EmailModal.schemas';
import EmailModalConfirmContainer from './EmailModalConfirmContainer';

interface EmailModalProps {
  modalId: string;
  emailSuggester: {
    bcc: boolean;
    file: string;
    subject: string;
    content: string;
  };
  sendPropertiesEmail: (emailPayload: any) => void;
}

const EmailModal: React.FC<EmailModalProps> = ({
  modalId,
  emailSuggester,
  sendPropertiesEmail,
}) => {
  const { t } = useTranslation();
  const { closeModal } = useAppModal();

  const handleCancelClick = () => closeModal(modalId);

  const controlsRichTextSettings = {
    attachmentsAvailable: false,
    attachmentIconDisabled: false,
    isLoading: false,
    clearEditorButton: false,
  };

  const handleSubmit = (formValues) => {
    const { subject, emailsTo, htmlContent, sendCopyToAgency } = formValues;

    sendPropertiesEmail({
      subject,
      to: emailsTo.map(({ name }) => name).join(', '),
      content: htmlContent.outerHTML,
      case: '12',
      bcc: sendCopyToAgency,
    });
  };

  return (
    <Container>
      <Modal.Header>
        <Modal.Title>{t('pageCalendar.stacked.emailPropertyList')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <FormWithProvider
          key={emailSuggester?.content}
          schema={emailSchema()}
          defaultValues={{
            sendCopyToAgency: true,
            subject:
              emailSuggester?.subject ||
              t('componentTemplate.sendEmail.availablePropertyList'),
            emailsTo: [],
          }}
          horizontal
          onSubmit={handleSubmit}
        >
          <EmailsWrapper>
            <label className="col-sm-2 col-xs-2">
              {t('componentTemplate.to')}
            </label>
            <TagsSelectField
              className="tags-selector"
              colSmInput={12}
              name="emailsTo"
              options={[]}
              placeHolder={t(
                'componentTemplate.sendEmail.pressEnterBetweenEmail',
              )}
              tagValidationType="email"
            />
          </EmailsWrapper>

          <TextField label={t('componentTemplate.subject')} name="subject" />

          <RichTextEditor
            id="inbox-message-editor"
            contents={emailSuggester?.content}
            controlsSettings={controlsRichTextSettings}
            customFooter={
              <Modal.Footer>
                <CheckboxField name="sendCopyToAgency">
                  {t('componentTemplate.sendCopyToAgency')}
                </CheckboxField>

                <EmailModalConfirmContainer />
                <Button onClick={handleCancelClick}>
                  {t('common.cancel')}
                </Button>
              </Modal.Footer>
            }
          />
        </FormWithProvider>
      </Modal.Body>
    </Container>
  );
};

export default EmailModal;
