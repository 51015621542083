import useAppMutation from 'hooks/useAppMutation';
import { useTranslation } from 'react-i18next';
import useNotify from 'hooks/useNotify';
import API from 'services/API';
import { AxiosResponse } from 'axios';
import {
  GetChatSuggestionPayload,
  ChatHistoryData,
  GetChatSuggestionResponseTO,
} from './InboxMessageSuggestion.types';

const useInboxMessageSuggestionMessage = () => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  const { mutate: getSuggestionsHandle, isLoading } = useAppMutation(
    ['getSuggestionsHandle'],
    ({ request }: GetChatSuggestionPayload) =>
      API.post('/api/internal/ai/messaging/chat-suggestion', request),
    {
      onSuccess: (
        { data }: AxiosResponse<GetChatSuggestionResponseTO>,
        { onSuccess }: GetChatSuggestionPayload,
      ) => {
        onSuccess(data);
      },
      onError: () =>
        notifyError(t('pageInbox.messages.editor.suggestAnAnswerError')),
    },
  );

  const getSuggestions = (
    request: ChatHistoryData,
    onSuccess: (suggestion: GetChatSuggestionResponseTO) => void,
  ) => {
    getSuggestionsHandle({ request, onSuccess });
  };

  return { getSuggestions, isLoading };
};

export default useInboxMessageSuggestionMessage;
