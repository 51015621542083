import useAppQuery from 'hooks/useAppQuery';
import { AmenityChannel, GetAvailableAmenitiesResponse } from 'models/Amenity';
import { PropertyBusinessType } from 'models/Properties';
import API from 'services/API';
import usePropertySettingsInitData from '../usePropertySettingsInitData';

export const AVAILABLE_AMENITIES_QUERY_KEY = 'fetch-all-available-amenities';

const useFetchAvailableAmenities = ({
  activeChannels,
}: {
  activeChannels: AmenityChannel[];
}) => {
  const { property } = usePropertySettingsInitData();
  const { uid: propertyUid, businessType } = property;
  const isHotel = businessType === PropertyBusinessType.HOTEL;

  const {
    data: availableAmenities,
    error,
    isLoading,
  } = useAppQuery(
    [AVAILABLE_AMENITIES_QUERY_KEY, propertyUid, activeChannels],
    async () => {
      const response = await API.get<GetAvailableAmenitiesResponse>(
        '/api/internal/available-amenities',
        {
          params: {
            [isHotel ? 'hotelUid' : 'propertyUid']: propertyUid,
            channels: activeChannels.join(','),
          },
        },
      );

      const { amenities } = response.data;

      return amenities;
    },
    {
      enabled: !!property && !!activeChannels.length,
    },
  );

  return { availableAmenities, error, isLoading };
};

export default useFetchAvailableAmenities;
