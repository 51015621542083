import { Button, Col, Row } from 'react-bootstrap';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import { useTranslation } from 'react-i18next';
import useAppModal from 'hooks/useAppModal';
import { MessagingChannel } from 'models/inbox/Messages';
import { TemplateModalFooter } from '../TemplateModal.styles';
import TemplateModalRichEditorFooterSubmitButton from './TemplateModalRichEditorFooterSubmitButton';

const TemplateModalRichEditorFooter = ({
  isSending,
  errorMessage,
  channels,
  defaultMessagingChannel,
}: {
  isSending: boolean;
  errorMessage: string;
  channels: MessagingChannel[];
  defaultMessagingChannel: MessagingChannel;
}) => {
  const { t } = useTranslation();
  const { closeModal } = useAppModal();

  return (
    <>
      <CheckboxField name="sendCopyToAgency">
        {t('componentTemplate.sendCopyToAgency')}
      </CheckboxField>

      <TemplateModalFooter>
        <Row>
          <Col xs={6} className="text-left">
            {errorMessage && (
              <span className="error-message" data-testid="error-message">
                {errorMessage}
              </span>
            )}
          </Col>
          <Col xs={6}>
            <Button onClick={() => closeModal('template-modal')}>
              {t('common.cancel')}
            </Button>
            <TemplateModalRichEditorFooterSubmitButton
              isSending={isSending}
              channels={channels}
              defaultMessagingChannel={defaultMessagingChannel}
            />
          </Col>
        </Row>
      </TemplateModalFooter>
    </>
  );
};

export default TemplateModalRichEditorFooter;
