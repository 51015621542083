import { useCallback, useContext, useEffect, useState } from 'react';
import useAppQuery from '../../../hooks/useAppQuery';
import API from '../../../services/API';
import InboxContext from '../InboxContext';
import { GetMessagesResponseTO, Message } from '../../../models/inbox/Messages';
import { MessagingActionOrigin } from './InboxMessages.types';

let getMessagesAbortController: AbortController;
const limit = 15;

const useInboxMessages: () => {
  fetchedMessages: Message[];
  hasMore: boolean;
  isLoading: boolean;
  loadMore: () => void;
} = () => {
  const { activeMessageThreadUid } = useContext(InboxContext);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);

  const { data: fetchedMessages, isLoading: isLoadingMessages } = useAppQuery(
    ['inboxMessages', activeMessageThreadUid, offset],
    async () => {
      if (getMessagesAbortController) {
        getMessagesAbortController.abort();
      }

      getMessagesAbortController = new AbortController();

      const response = await API.get<GetMessagesResponseTO>('/v3/messages', {
        params: {
          threadUid: activeMessageThreadUid,
          limit,
          offset,
          actionOrigin: MessagingActionOrigin.UI_INBOX_LOADING,
        },
        signal: getMessagesAbortController.signal,
      });

      return response.data.messages;
    },
    { enabled: !!activeMessageThreadUid },
  );

  useEffect(() => {
    setHasMore(fetchedMessages?.length === limit);
  }, [fetchedMessages]);

  useEffect(() => {
    setOffset(0);
  }, [activeMessageThreadUid]);

  const loadMore = useCallback(() => {
    setOffset((currentOffset) => currentOffset + limit);
  }, [offset]);

  return {
    fetchedMessages,
    hasMore,
    isLoading: isLoadingMessages && !offset,
    loadMore,
  };
};

export default useInboxMessages;
