import useFetchAllProperties from 'components/domain/property/useFetchAllProperties';
import useAppUser from 'hooks/useAppUser';
import {
  propertyAnalyticsConditionGraphQLQuery,
  propertyAnalyticsGraphQLQuery,
} from 'pages/analytics/Analytics.utils';

const useAddDiscountProperties = () => {
  const { agencyUid } = useAppUser();

  const { allProperties: properties, isFetching: isPropertiesLoading } =
    useFetchAllProperties({
      enabled: !!agencyUid,
      conditions: propertyAnalyticsConditionGraphQLQuery,
      fields: propertyAnalyticsGraphQLQuery,
    });

  return { properties, isPropertiesLoading };
};

export default useAddDiscountProperties;
