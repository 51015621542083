import { useTranslation } from 'react-i18next';
import useAppModal from 'hooks/useAppModal';
import { AddManualTransactionModalParams } from './AddManualTransactionModal.types';
import AddManualTransactionModal from './AddManualTransactionModal';

const addManualTransactionModalId = 'add-manual-transaction-modal';

const useAddManualTransactionModal = () => {
  const { openModal } = useAppModal();
  const { t } = useTranslation();

  const openAddManualTransactionModal = (
    params: AddManualTransactionModalParams,
  ) => {
    openModal({
      customContent: (
        <AddManualTransactionModal
          modalId={addManualTransactionModalId}
          params={params}
        />
      ),
      id: addManualTransactionModalId,
      title: t(
        'pageOrder.transactions.actions.addManualTransaction.modalTitle',
      ),
    });
  };

  return { openAddManualTransactionModal };
};

export default useAddManualTransactionModal;
