import styled from 'styled-components';

export const DayCellWrapper = styled.div<{
  $empty?: boolean;
  $borderRight?: boolean;
  $inRed?: boolean;
  $calendarStyleConstants: any;
}>`
    text-align:center;
    flex: 0 1  calc(100% / 7);
    ${({ $calendarStyleConstants }) =>
      $calendarStyleConstants &&
      `height: ${$calendarStyleConstants.days.height}px;`}
    background-color: transparent;
    ${({ $calendarStyleConstants }) =>
      $calendarStyleConstants &&
      `border-left: ${$calendarStyleConstants.border};`}
    ${({ $calendarStyleConstants }) =>
      $calendarStyleConstants &&
      `border-bottom: ${$calendarStyleConstants.border};`}
    position: relative;
    padding: 5px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    color: rgba(85, 85, 85, 0.5);
    user-select: none;
    ${({ $empty }) => $empty && `border-left: none;`}
    ${({ $inRed }) =>
      $inRed &&
      `color: red; 
      svg {
        filter: invert(16%) sepia(91%) saturate(7063%) hue-rotate(5deg) brightness(104%) contrast(119%);
      }`}
    ${({ $borderRight, $calendarStyleConstants }) =>
      $borderRight && `border-right: ${$calendarStyleConstants.border};`}
  }

  &.highlight {
    background-color: #feffbc;
    border-color: #d4d664;
    transition: all 0.3s ease-in-out;
  }
`;

export const DayCellDate = styled.span`
    width: 100%;
    height: 24px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 16px;
  }
`;

export const DayCellMinStay = styled.span<{ $isLoading?: boolean }>`
    font-size: 12px;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-left: 4px;
    }
    ${({ $isLoading }) =>
      $isLoading &&
      `
        width: 30px;
        height: 8px;
        background-color: #ced4da;
      `}
  }
`;

export const DayCellPricing = styled.span<{ $isLoading?: boolean }>`
    font-size: 12px;
    color: inherit;

    ${({ $isLoading }) =>
      $isLoading &&
      `
         width: 20px;
        height: 8px;
        margin-top: 6px;
        background-color: #ced4da;
      `}
  }
`;

export const DayStringCellS = styled.div<{
  $hasBorder: boolean;
  $calendarStyleConstants: any;
}>`
    flex: 0 1  calc(100% / 7);
    height: 40px;
    text-align: center;
    padding: 0;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    border-bottom: none;

    ${({ $hasBorder, $calendarStyleConstants }) =>
      $hasBorder &&
      `
        border-bottom: ${$calendarStyleConstants.border};
      `}
  }
`;
