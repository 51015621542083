import { ReactElement } from 'react';
import { Sizes } from 'react-bootstrap';

export enum ModalType {
  CONFIRM = 'CONFIRM',
  CUSTOM_CONTENT = 'CUSTOM_CONTENT',
}

export interface ModalData {
  type: ModalType;
  onHide?: () => void;
  errorMessage?: ReactElement | string;
}

export interface ModalProps {
  modalId: string;
}

export interface ConfirmModalProps {
  body: ReactElement | string;
  onConfirm?: Function;
  title: string;
  cancelLabel?: string;
  confirmLabel?: string;
  cancelColor?: string;
  confirmColor?: string;
  hideCancel?: boolean;
  onCancel?: Function;
  onHide?: () => void;
  onConfirmAsync?: () => Promise<void>;
}

export interface ConfirmModalData extends ConfirmModalProps, ModalData {}

export interface CustomContentModalProps {
  customContent: ReactElement | string;
  id: string;
  size?: Sizes;
  title?: ReactElement | string;
  width?: number;
  onHide?: () => void;
}

export interface CustomContentModalData
  extends CustomContentModalProps,
    ModalData {}
