import styled from 'styled-components';
import theme from 'styles/theme';

export const AmenityDescriptionIconContainer = styled.div<{
  $disabled: boolean;
}>`
  display: flex;
  align-items: center;
  margin-left: 8px;

  svg {
    width: 14px;
    height: 14px;
  }

  ${({ $disabled }) => ($disabled ? 'opacity: 0.7;' : 'cursor: pointer;')}
`;

export const AmenityDescriptionModalFooter = styled.div`
  display: flex;
  justify-content: end;
`;

export const AmenityTypeName = styled.span`
  color: ${theme.colors.fontGreen};
`;
