import { useQueryClient } from '@tanstack/react-query';
import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import { HouseRule } from 'models/HouseRules';
import { Property } from 'models/Properties';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import API from 'services/API';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import useNotify from 'hooks/useNotify';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import { useTranslation } from 'react-i18next';
import { HOUSE_RULES_BASE_QUERY_KEY } from '../HouseRules.utils';
import HouseRulesModal, {
  houseRuleModalId,
  HouseRulesFormSchemaType,
} from './HouseRulesModal';

const BULK_UPDATE_HOUSE_RULES_BASE_MUTATION_KEY = 'bulk-update-house-rules';

interface BulkCreateHouseRulesPayload {
  houseRule: Pick<HouseRule, 'description'>;
  objectUidFetchType: 'ALL' | 'SPECIFIC';
  objectUids: Property['uid'][];
}

const useHouseRulesModal = (houseRule?: HouseRule) => {
  const { property } = usePropertySettingsInitData();
  const { openModal, closeModal } = useAppModal();
  const { notifyError } = useNotify();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const mutationKey = [BULK_UPDATE_HOUSE_RULES_BASE_MUTATION_KEY];

  const { mutate: bulkCreateHouseRule } = useAppMutation(
    mutationKey,
    (payload: BulkCreateHouseRulesPayload) => {
      return API.post('/api/internal/house-rules/bulk-save', payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([HOUSE_RULES_BASE_QUERY_KEY]);
        closeModal(houseRuleModalId);
      },
      onError: (error: any) => {
        notifyError(
          getErrorMessage({
            apiErrorCode: error.response.data?.apiErrorCode,
            apiErrorMessage: error.response.data?.apiErrorMessage,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      },
    },
  );

  const handleCreateSubmit = (values: HouseRulesFormSchemaType) => {
    const applyToAllProperties =
      values.appliesTo === PROPERTIES_SELECTION.ALL_PROPERTIES;

    const payload: BulkCreateHouseRulesPayload = {
      houseRule: {
        description: values.description,
      },
      objectUidFetchType: applyToAllProperties ? 'ALL' : 'SPECIFIC',
      objectUids: applyToAllProperties
        ? []
        : values.appliesToProperties.map((item) => item.value),
    };

    bulkCreateHouseRule(payload);
  };

  const handleUpdateSubmit = (values: HouseRulesFormSchemaType) => {
    // TODO: Implement bulk update mutation in PMP-11160
  };

  const openBulkUpdateModal = () => {
    openModal({
      id: houseRuleModalId,
      customContent: (
        <HouseRulesModal
          property={property}
          houseRule={houseRule}
          onSubmit={handleUpdateSubmit}
          mutationKey={mutationKey}
        />
      ),
    });
  };

  const openBulkCreateModal = () => {
    openModal({
      id: houseRuleModalId,
      customContent: (
        <HouseRulesModal
          property={property}
          onSubmit={handleCreateSubmit}
          mutationKey={mutationKey}
        />
      ),
    });
  };

  return { openBulkUpdateModal, openBulkCreateModal };
};

export default useHouseRulesModal;
