import styled from 'styled-components';

export const PropertiesListInputWrapper = styled.div`
  margin-left: 20px;

  .checkbox {
    margin-top: 0;
  }

  p {
    display: flex;
    align-items: baseline;

    .btn-link {
      padding: 0px 3px;
    }
  }
`;
