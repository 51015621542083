import { Lead } from '../../../models/Leads';
import {
  ThreadRelatedLead,
  ThreadRelatedPropertyResponse,
} from '../../../models/inbox/Threads';

export interface InboxLeadsContextProps {
  leadsMap: { [leadUid: string]: Lead };
  threadRelatedLeadsMap: { [threadUid: string]: ThreadRelatedLead[] };
  selectedLeadUid?: string;
  setSelectedLeadUid: (_leadUid: string) => void;
  deleteLeadFromMap: (_leadUid: string) => void;
  updateLeadsMap: (_lead: Lead) => void;
  updateThreadRelatedLeadsMap: (_threadRelatedLeads: {
    threadUid: string;
    leads: ThreadRelatedLead[];
  }) => void;
  threadRelatedPropertiesMap: {
    [threadUid: string]: ThreadRelatedPropertyResponse[];
  };
  isThreadRelatedPropertyExists: (threadUid: string) => boolean;
  addThreadRelatedProperty: (
    threadUid: string,
    property: ThreadRelatedPropertyResponse,
  ) => void;
}

export enum InboxLeadDisplayMode {
  RIGHT_PANEL = 'RIGHT_PANEL',
  STANDALONE = 'STANDALONE',
}
