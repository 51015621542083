import { LeadCircleContainer } from 'components/domain/lead/status/LeadCircle.styles';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { Col, Row } from 'react-bootstrap';
import {
  LeadBody,
  LeadDetailLabel,
  LeadDetailValue,
  LeadHeaderStatusContainer,
} from './InboxLead.styles';

const InboxActiveLeadLoader = () => {
  return (
    <>
      <div>
        <LoadingPlaceholder linesCount={1.5} width="60%" />
      </div>
      <div>
        <LoadingPlaceholder linesCount={1} width="35%" />
      </div>
      <LeadHeaderStatusContainer>
        <LeadCircleContainer>
          <LoadingPlaceholder circle />
        </LeadCircleContainer>
      </LeadHeaderStatusContainer>
      <LeadBody>
        {[1, 2, 3].map((number) => (
          <Row key={number}>
            <Col md={6}>
              <LeadDetailLabel>
                <LoadingPlaceholder linesCount={1} width="70%" />
              </LeadDetailLabel>
              <LeadDetailValue>
                <LoadingPlaceholder linesCount={1} width="80%" />
              </LeadDetailValue>
            </Col>
            <Col md={6}>
              <LeadDetailLabel>
                <LoadingPlaceholder linesCount={1} width="70%" />
              </LeadDetailLabel>
              <LeadDetailValue>
                <LoadingPlaceholder linesCount={1} width="80%" />
              </LeadDetailValue>
            </Col>
          </Row>
        ))}
      </LeadBody>
    </>
  );
};

export default InboxActiveLeadLoader;
