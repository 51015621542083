import styled from 'styled-components';

export const RangePickerFieldWrapper = styled.div`
  position: relative;
  display: flex;

  & input {
    border-radius: 4px 0px 0px 4px;
  }

  .date-time-picker-popper {
    z-index: 999;
  }
`;
