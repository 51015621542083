import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useAppDispatch from '../../hooks/useAppDispatch';
import { setRequestedPath, setRequestedUrl } from '../../store/slices/login';

const useLoginRedirectionParams = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const guestBookingUid = searchParams.get('gbuid');
  const requestedUrl = searchParams.get('r');

  useEffect(() => {
    if (guestBookingUid) {
      dispatch(
        setRequestedPath({
          requestedPath: {
            hash: '',
            pathname: '/guest/booking-view',
            search: `?uid=${guestBookingUid}`,
          },
        }),
      );
    } else if (requestedUrl) {
      dispatch(
        setRequestedUrl({
          requestedUrl,
        }),
      );
    }
  }, [guestBookingUid, requestedUrl]);
};

export default useLoginRedirectionParams;
