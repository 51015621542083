import { Row } from '@tanstack/react-table';
import Button from 'components/button/Button';
import { useTranslation } from 'react-i18next';
import { CancellationPolicy } from 'models/CancellationPolicy';
import useEditCancellationPolicy from '../modals/useEditCancellationPolicy';
import useDeleteModal from './useDeleteModal';

const ActionsCell = ({ row }: { row: Row<CancellationPolicy> }) => {
  const { t } = useTranslation();

  const cancellationPolicyUid = row.original.uid;

  const { openEditModal } = useEditCancellationPolicy({
    cancellationPolicyUid,
  });
  const { openConfirmDeleteModal } = useDeleteModal({
    cancellationPolicyUid,
  });

  const cancelationPolicyValue = row.original.values?.[0];

  return (
    <div>
      <Button
        bsStyle="link"
        onClick={() => openEditModal(cancelationPolicyValue)}
        data-testid="row-edit-cancelation-policy-button"
      >
        {t('common.edit')}
      </Button>
      <Button
        bsStyle="link"
        onClick={openConfirmDeleteModal}
        data-testid="row-delete-cancelation-policy-button"
      >
        {t('common.delete')}
      </Button>
    </div>
  );
};

export default ActionsCell;
