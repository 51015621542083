import { useContext } from 'react';
import CalendarContext from './CalendarContext';
import { StackedCalendarMode } from './Calendar.types';

const useCalendarMode = () => {
  const { mode } = useContext(CalendarContext);

  return {
    isBookingMode: mode === StackedCalendarMode.BOOKING,
    isPricingMode: mode === StackedCalendarMode.PRICING,
    isCombinedMode: mode === StackedCalendarMode.COMBINED,
  };
};

export default useCalendarMode;
