import * as Yup from 'yup';
import { ObjectSchema } from 'yup';
import { BedType, RoomType } from 'models/PropertyRooms';

const bedTypeDescriptor = () =>
  Yup.object({
    bedType: Yup.mixed<BedType>().oneOf(Object.values(BedType)).required(),
    count: Yup.number().integer().positive().required(),
  });

export const roomsAndBedTypesFormSchema = () => ({
  rooms: Yup.array()
    .of(
      Yup.object({
        beds: Yup.array().of(bedTypeDescriptor()).required(),
        bedTypes: Yup.string().when('beds', {
          is: (beds: BedTypeDescriptor[]) => !beds?.length,
          then: (schema) =>
            schema.required({
              key: 'pageProperty.mainSettings.capacityDetails.setRoomsAndBedTypesModal.validation.beds',
            }),
          otherwise: (schema) => schema,
        }),
        hasPrivateBathroom: Yup.boolean().nullable().optional(),
        roomNumber: Yup.number().integer().required(),
        roomType: Yup.mixed<RoomType.BEDROOM | RoomType.LIVING_ROOM>()
          .oneOf([RoomType.BEDROOM, RoomType.LIVING_ROOM])
          .required(),
        sharedRoom: Yup.boolean().nullable().optional(),
      }),
    )
    .required(),
});

export type BedTypeDescriptor = Yup.InferType<
  ReturnType<typeof bedTypeDescriptor>
>;

export type RoomsAndBedTypesFormValues = Yup.InferType<
  ObjectSchema<ReturnType<typeof roomsAndBedTypesFormSchema>>
>;
