import { useEffect } from 'react';
import { runSyncCheckAction } from '../store/slices/syncCheck/sagas/syncCheckActions';
import useAppDispatch from './useAppDispatch';
import useAppUser from './useAppUser';

let syncCheckInterval;

const useAppSyncCheck = ({
  isInitInProgress,
}: {
  isInitInProgress: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { isEmployee, isGuest, isOwner } = useAppUser();

  if (
    (isEmployee || isGuest || isOwner) &&
    !isInitInProgress &&
    !syncCheckInterval
  ) {
    dispatch(runSyncCheckAction({}));

    syncCheckInterval = setInterval(() => {
      if (document.hasFocus()) {
        dispatch(runSyncCheckAction({}));
      }
    }, 10000);
  }

  useEffect(() => {
    return () => {
      if (syncCheckInterval) {
        clearInterval(syncCheckInterval);
      }
    };
  }, []);

  return null;
};

export default useAppSyncCheck;
