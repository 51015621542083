import styled from 'styled-components';

export const AirbnbDeactivationFlowStyled = styled.section`
  display: flex;
  flex-direction: column;

  > p {
    margin: 16px 0 6px;
  }

  > .form-field-container > div {
    padding-left: 0;
  }
`;
