import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDateTimeLocale } from './dateTimeInternationalization';

const useDateTimeInternationalization = () => {
  const [abbreviatedWeekdays, setAbbreviatedWeekdays] = useState([]);
  const { i18n } = useTranslation();

  useEffect(() => {
    const locale = getDateTimeLocale(i18n.language);
    const abbreviatedWeekdaysArr = [...Array(7)].map((x, i) => {
      return locale.localize.day(i, { width: 'abbreviated' });
    });
    setAbbreviatedWeekdays(abbreviatedWeekdaysArr);
  }, []);

  return {
    abbreviatedWeekdays,
  };
};

export default useDateTimeInternationalization;
