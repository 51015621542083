import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SwitchRegularField from 'components/forms/switchField/SwitchRegularField';
import { RoomType } from 'models/PropertyRooms';

const RoomsAndBedTypesFormSharedRoomField = ({
  roomIndex,
}: {
  roomIndex: number;
}) => {
  const { t } = useTranslation();
  const roomType = useWatch({ name: `rooms.${roomIndex}.roomType` });

  if (roomType === RoomType.BEDROOM) {
    return null;
  }

  return (
    <SwitchRegularField
      name={`rooms.${roomIndex}.sharedRoom`}
      label={t(
        'pageProperty.mainSettings.capacityDetails.setRoomsAndBedTypesModal.sharedRoom',
      )}
      rightTooltip={t(
        'pageProperty.mainSettings.capacityDetails.setRoomsAndBedTypesModal.sharedRoomTooltip',
      )}
      colSmLabel={5}
      colSmInput={7}
    />
  );
};

export default RoomsAndBedTypesFormSharedRoomField;
