import styled from 'styled-components';

export const NumberFieldWrapper = styled.div`
  input.form-control {
    padding-left: 6px;
    padding-right: 6px;
    text-align: center;
  }

  input.form-control::-webkit-outer-spin-button,
  input.form-control::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input.form-control[type='number'] {
    -moz-appearance: textfield;
  }

  .input-group-addon {
    padding: 0;

    .btn {
      color: inherit;
      padding: 3px 10px;

      &:focus,
      &:hover {
        text-decoration: none;
      }

      .button-text {
        display: inline-block;
        width: 12px;
      }
    }
  }
`;
