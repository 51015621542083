import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';
import theme from 'styles/theme';

export const TransactionListWrapper = styled.div`
  border: 1px solid ${theme.colors.gray300};
  border-radius: 5px;
  margin-bottom: 1rem;

  ${mediaQuery.sm} {
    margin-bottom: 3rem;
    overflow-x: auto;
  }
`;

export const TransactionList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0 0.5rem;

  ${mediaQuery.sm} {
    min-width: 900px;
  }

  > :not(:first-child) {
    border-top: 1px solid ${theme.colors.gray300};
  }
`;

export const TransactionListEmptyMessage = styled.div.attrs({
  className: 'text-center',
})`
  padding: 1.5em;
`;
