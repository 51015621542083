import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';
import useAppUser from 'hooks/useAppUser';

const useFetchAgencyIntegrationSettings = () => {
  const { agencyUid, isEmployee } = useAppUser();

  const { data: integrationSettings, isFetching } = useAppQuery(
    ['agencyIntegrationSettings', agencyUid],
    async () => {
      const response = await API.get(
        `v3/integrations-settings?agencyUid=${agencyUid}`,
      );
      return response.data.integrationSettings;
    },
    { enabled: isEmployee && !!agencyUid },
  );

  return { integrationSettings, isFetching };
};

export default useFetchAgencyIntegrationSettings;
