import { Col, ModalBody, ModalFooter, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormWithProvider from '../../../../forms/form/Form';
import TextField from '../../../../forms/textField/TextField';
import SelectField from '../../../../forms/selectField/SelectField';
import { AirbnbDeclineReason } from '../LeadActions.types';
import Button from '../../../../button/Button';

const declineFormSchema = () => ({
  airbnbDeclineReason: Yup.mixed<AirbnbDeclineReason>()
    .oneOf(Object.values(AirbnbDeclineReason))
    .required(),
  declineMessageToAirbnb: Yup.string().required(),
  declineMessageToGuest: Yup.string().required(),
});

const declineFormDefaultValues = {
  airbnbDeclineReason: AirbnbDeclineReason.dates_not_available,
};

const DeclineAirbnbLeadModalContent = ({ onCancel, onSubmit }) => {
  const { t } = useTranslation();

  const declineReasonOptions = Object.values(AirbnbDeclineReason).map(
    (reason: AirbnbDeclineReason) => ({
      label: t(`componentLead.actionsInfo.airbnb.declineReason.${reason}`),
      value: reason,
    }),
  );

  return (
    <FormWithProvider
      defaultValues={declineFormDefaultValues}
      onSubmit={onSubmit}
      schema={declineFormSchema()}
    >
      <ModalBody>
        <Row>
          <Col>
            <SelectField
              label={t('componentLead.actionsInfo.airbnb.declineReasonSelect')}
              name="airbnbDeclineReason"
              options={declineReasonOptions}
              colSmInput={8}
              colSmLabel={4}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              label={t(
                'componentLead.actionsInfo.airbnb.declineMessageToGuest',
              )}
              name="declineMessageToGuest"
              colSmInput={8}
              colSmLabel={4}
              componentClass="textarea"
              required
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              label={t(
                'componentLead.actionsInfo.airbnb.declineMessageToAirbnb',
              )}
              name="declineMessageToAirbnb"
              colSmInput={8}
              colSmLabel={4}
              componentClass="textarea"
              required
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCancel}>{t('common.cancel')}</Button>
        <Button bsStyle="danger" type="submit">
          {t('componentLead.actionsInfo.airbnb.declineConfirm')}
        </Button>
      </ModalFooter>
    </FormWithProvider>
  );
};

export default DeclineAirbnbLeadModalContent;
