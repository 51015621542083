import { Lead } from '../../../../models/Leads';
import useAppUser from '../../../../hooks/useAppUser';

const useLeadActionsPermission = (lead: Lead) => {
  const {
    isEmployeeAssociate,
    isEmployeeBookingAgent,
    isEmployeeManager,
    isEmployeePropertySalesManager,
    userUid,
  } = useAppUser();

  if (isEmployeeAssociate) {
    return false;
  }

  if (
    isEmployeeBookingAgent ||
    isEmployeeManager ||
    isEmployeePropertySalesManager
  ) {
    const { assignee } = lead;
    return !assignee || assignee.uid === userUid;
  }

  return false;
};

export default useLeadActionsPermission;
