import * as Yup from 'yup';
import { LeadStatus } from 'models/Leads';
import { areArraysEqual } from 'utils/arrayUtils';
import {
  ThreadFilterFormLeadStatusesMap,
  defaultThreadFilter,
} from './InboxFilter.constants';

export const InboxFilterSchema = Yup.object({
  sortBy: Yup.string(),
  leadStatus: Yup.string(),
  leadChannel: Yup.string(),
  agentUid: Yup.string(),
  propertyUid: Yup.string(),
  showUnreadOnly: Yup.string(),
});

export type InboxFilterSchemaType = Yup.InferType<typeof InboxFilterSchema>;

const threadFilterToFormValuesFieldMappers = {
  leadStatuses: (leadStatuses: LeadStatus[]) => {
    if (!leadStatuses?.length) {
      return { leadStatus: '' };
    }

    return Object.entries(ThreadFilterFormLeadStatusesMap)
      .find((entry) => areArraysEqual(entry[1], leadStatuses))
      .map((entry) => ({ leadStatus: entry[0] }))
      .reduce((acc, value) => ({ ...acc, ...value }), {});
  },
  showUnreadOnly: (showUnreadOnly: boolean) => ({
    showUnreadOnly: `${showUnreadOnly || ''}`,
  }),
};

export const getFormDefaultValues = () => {
  return Object.entries(defaultThreadFilter).reduce((values, entry) => {
    const key = entry[0];
    const value = entry[1];
    const fieldMapper = threadFilterToFormValuesFieldMappers[key];
    return {
      ...values,
      ...(fieldMapper ? fieldMapper(value) : { [key]: value }),
    };
  }, {});
};
