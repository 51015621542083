import useAppUser from 'hooks/useAppUser';
import useIsPageWithoutFooter from 'hooks/layout/useIsPageWithoutFooter';
import AgencyFooter from './AgencyFooter';
import OwnerFooter from './OwnerFooter';
import GuestFooter from './GuestFooter';

const Footer = () => {
  const { isEmployee, isGuest, isOwner } = useAppUser();
  const isPageWithoutFooter = useIsPageWithoutFooter();

  if (isPageWithoutFooter) {
    return null;
  }

  if (isEmployee) {
    return <AgencyFooter />;
  }

  if (isGuest) {
    return <GuestFooter />;
  }

  if (isOwner) {
    return <OwnerFooter />;
  }

  return null;
};

export default Footer;
