import { useEffect } from 'react';
import SelectField from 'components/forms/selectField/SelectField';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import useImportBookingDotComPropertiesModalInit from '../../useImportBookingDotComPropertiesModalInit';

const LegalEntityIDInput = () => {
  const { t } = useTranslation();
  const { legalEntityId } = useWatch();
  const { contracts } = useImportBookingDotComPropertiesModalInit();
  const { setValue } = useFormContext();

  const contractsOptions = contracts.map(
    ({ legalEntityId: le, legalContactEmail }) => ({
      label: `${le} - ${legalContactEmail}`,
      value: le,
    }),
  );

  useEffect(() => {
    if (!legalEntityId && contracts?.length > 0) {
      setValue('legalEntityId', contracts?.[0]?.legalEntityId);
    }
  }, [contracts, legalEntityId]);

  return (
    <SelectField
      label={t(
        'pageProperties.importBookingDotComPropertiesModal.legalEntityID',
      )}
      name="legalEntityId"
      options={contractsOptions}
      colSmInput={9}
      colSmLabel={3}
      required
    />
  );
};

export default LegalEntityIDInput;
