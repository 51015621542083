import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { normalizeKey } from 'utils/localeUtils';

interface FormValidationError {
  key: string;
  values?: Record<string, unknown>;
}

const useFormFieldErrorMessage = (name: string) => {
  const {
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  const error = errors?.[name]?.message;

  if (!error) {
    return null;
  }

  if (typeof error === 'string') {
    return error;
  }

  const { key, values } = error as unknown as FormValidationError;

  return t(normalizeKey(key), values);
};

export default useFormFieldErrorMessage;
