import { useContext } from 'react';
import { Col } from 'react-bootstrap';
import useFormatCurrency from 'hooks/useFormatCurrency';
import useFormatNumber from 'hooks/useFormatNumber';
import useKeyMetricsData from '../useKeyMetricsData';
import AnalyticsContext from '../AnalyticsContext';
import { isCardDataApplicable } from '../Analytics.utils';
import { AnalyticsKeyMetric } from '../types/AnalyticsKeyMetrics.types';
import usePercentageKeyMetricsData from '../usePercentageKeyMetricsData';
import { AnalyticsListWrapper } from './AnalyticsList.styles';
import AnalyticsListItem from './AnalyticsListItem';

const AnalyticsList = () => {
  const { keyMetrics, isKeyMetricsLoading, keyMetricsError } =
    useKeyMetricsData();
  const { percentageKeyMetrics, isPercentageKeyMetricsLoading } =
    usePercentageKeyMetricsData();
  const formatCurrency = useFormatCurrency();
  const formatNumber = useFormatNumber();
  const { filter } = useContext(AnalyticsContext);

  const rentalRevenue = keyMetrics?.find(
    (km) => km.metric === AnalyticsKeyMetric.RENTAL_REVENUE,
  );
  const rentalRevenuePercentage = percentageKeyMetrics?.find(
    (km) => km.metric === AnalyticsKeyMetric.RENTAL_REVENUE,
  );

  const nightsBooked = keyMetrics?.find(
    (km) => km.metric === AnalyticsKeyMetric.NIGHTS_BOOKED,
  );
  const nightsBookedPercentage = percentageKeyMetrics?.find(
    (km) => km.metric === AnalyticsKeyMetric.NIGHTS_BOOKED,
  );

  const occupancyRate = keyMetrics?.find(
    (km) => km.metric === AnalyticsKeyMetric.OCCUPANCY_RATE,
  );
  const occupancyRatePercentage = percentageKeyMetrics?.find(
    (km) => km.metric === AnalyticsKeyMetric.OCCUPANCY_RATE,
  );

  const avgDailyRate = keyMetrics?.find(
    (km) => km.metric === AnalyticsKeyMetric.AVERAGE_DAILY_RATE,
  );
  const avgDailyRatePercentage = percentageKeyMetrics?.find(
    (km) => km.metric === AnalyticsKeyMetric.AVERAGE_DAILY_RATE,
  );

  const revPar = keyMetrics?.find(
    (km) => km.metric === AnalyticsKeyMetric.REV_PAR,
  );
  const revParPercentage = percentageKeyMetrics?.find(
    (km) => km.metric === AnalyticsKeyMetric.REV_PAR,
  );

  return (
    <Col xs={12}>
      <AnalyticsListWrapper>
        <AnalyticsListItem
          backgroundColor="#3a84d81a"
          labelKey="rentalRevenue"
          helpKey="rentalRevenueHelp"
          value={
            rentalRevenue &&
            formatCurrency({
              value: rentalRevenue.value,
              currency: rentalRevenue.currency,
              options: {
                round: true,
                notation: 'compact',
                removeDecimals: rentalRevenue.value < 1000,
              },
            })
          }
          percentage={
            rentalRevenuePercentage && rentalRevenuePercentage.percentage
          }
          notApplicable={
            !isCardDataApplicable(filter, AnalyticsKeyMetric.RENTAL_REVENUE)
          }
          error={keyMetricsError}
          isLoading={isKeyMetricsLoading}
          isPercentageLoading={isPercentageKeyMetricsLoading}
        />

        <AnalyticsListItem
          backgroundColor="#ffc4541a"
          labelKey="nightsBooked"
          helpKey="nightsBookedHelp"
          value={
            nightsBooked &&
            formatNumber({
              value: nightsBooked.value,
              options: { notation: 'compact' },
            })
          }
          percentage={
            nightsBookedPercentage && nightsBookedPercentage.percentage
          }
          notApplicable={
            !isCardDataApplicable(filter, AnalyticsKeyMetric.NIGHTS_BOOKED)
          }
          error={keyMetricsError}
          isLoading={isKeyMetricsLoading}
          isPercentageLoading={isPercentageKeyMetricsLoading}
        />
        <AnalyticsListItem
          backgroundColor="#31b48d1a"
          labelKey="occupancyRate"
          helpKey="occupancyRateHelp"
          value={
            occupancyRate &&
            `${formatNumber({ value: occupancyRate.value * 100 })}%`
          }
          notApplicable={
            !isCardDataApplicable(filter, AnalyticsKeyMetric.OCCUPANCY_RATE)
          }
          percentage={
            occupancyRatePercentage && occupancyRatePercentage.percentage
          }
          error={keyMetricsError}
          isLoading={isKeyMetricsLoading}
          isPercentageLoading={isPercentageKeyMetricsLoading}
        />
        <AnalyticsListItem
          backgroundColor="#8a5bda1a"
          labelKey="avgDailyRate"
          helpKey="avgDailyRateHelp"
          value={
            avgDailyRate &&
            formatCurrency({
              value: avgDailyRate.value,
              currency: avgDailyRate.currency,
              options: {
                round: true,
                notation: 'compact',
                removeDecimals: avgDailyRate.value < 1000,
              },
            })
          }
          percentage={
            avgDailyRatePercentage && avgDailyRatePercentage.percentage
          }
          notApplicable={
            !isCardDataApplicable(filter, AnalyticsKeyMetric.AVERAGE_DAILY_RATE)
          }
          error={keyMetricsError}
          isLoading={isKeyMetricsLoading}
          isPercentageLoading={isPercentageKeyMetricsLoading}
        />
        <AnalyticsListItem
          backgroundColor="#ff72541a"
          labelKey="revPar"
          helpKey="revParHelp"
          value={
            revPar &&
            formatCurrency({
              value: revPar.value,
              currency: revPar.currency,
              options: {
                round: true,
                notation: 'compact',
                removeDecimals: revPar.value < 1000,
              },
            })
          }
          percentage={revParPercentage && revParPercentage.percentage}
          notApplicable={
            !isCardDataApplicable(filter, AnalyticsKeyMetric.REV_PAR)
          }
          error={keyMetricsError}
          isLoading={isKeyMetricsLoading}
          isPercentageLoading={isPercentageKeyMetricsLoading}
        />
      </AnalyticsListWrapper>
    </Col>
  );
};

export default AnalyticsList;
