import useAppMutation from '../../../hooks/useAppMutation';
import API from '../../../services/API';
import {
  LeadInternalResponse,
  LeadSavingPayload,
  LeadUpdateInternalPayload,
} from '../../../models/Leads';

type TError = {
  apiErrorMessage?: string;
};

const useLeadSynchronousMutations = () => {
  const { mutateAsync: createLead } = useAppMutation<
    LeadInternalResponse['lead'] & TError,
    TError,
    LeadSavingPayload
  >((payload: LeadSavingPayload) => {
    return API.post<LeadInternalResponse>('api/internal/leads', payload)
      .then(({ data }) => data.lead)
      .catch(({ response: { data } }) => data);
  });

  const { mutateAsync: updateLead } = useAppMutation<
    LeadInternalResponse['lead'] & TError,
    TError,
    { leadUid: string; payload: LeadUpdateInternalPayload }
  >(({ leadUid, payload }) => {
    return API.patch<LeadInternalResponse>(
      `api/internal/leads/${leadUid}`,
      payload,
    )
      .then(({ data }) => data.lead)
      .catch(({ response: { data } }) => data);
  });

  return { createLead, updateLead };
};

export default useLeadSynchronousMutations;
