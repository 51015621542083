import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Client } from './ExchangeToken.types';

const ExchangeTokenSuccess = ({ client }: { client: Client }) => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col xs={6} xsOffset={3}>
        <h2>{t('pageExchangeToken.successTitle')}</h2>
        <p>{t('pageExchangeToken.successSubtitle')}</p>

        <p>
          {t('pageExchangeToken.oAuthClientID')}: <b>{client.clientId}</b>
        </p>
        <p>
          {t('pageExchangeToken.oAuthClientSecret')}:{' '}
          <b>{client.clientSecret}</b>
        </p>
      </Col>
    </Row>
  );
};

export default ExchangeTokenSuccess;
