import { UseQueryOptions } from '@tanstack/react-query';
import useAppQuery from 'hooks/useAppQuery';
import {
  Discount,
  DiscountCodeStatus,
  GetDiscountsResponseTO,
} from 'models/Discounts';
import API from 'services/API';

export const DISCOUNTS_BASE_QUERY_KEY = 'discounts';

type ReactQueryOptions = Omit<
  UseQueryOptions<Discount[]>,
  'queryKey' | 'queryFn'
>;

export const useFetchDiscounts = (
  status: DiscountCodeStatus,
  options?: ReactQueryOptions,
) => {
  return useAppQuery({
    queryKey: [DISCOUNTS_BASE_QUERY_KEY, status],
    queryFn: async () => {
      const response = await API.get<GetDiscountsResponseTO>(
        `/v3/discount-codes?status=${status}`,
      );
      return response.data.discountCodes.map((discount) => ({
        ...discount,
        expirationDate: new Date(discount.expirationDate),
        validFromDate: new Date(discount.validFromDate),
        validToDate: new Date(discount.validToDate),
      }));
    },
    enabled: true,
    ...(options ?? {}),
  });
};
