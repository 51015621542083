import { useEffect } from 'react';
import useAppConfig from '../useAppConfig';
import onGoogleAnalyticsLoad from './onGoogleAnalyticsLoad';
import useLogCustomPageView from './useLogCustomPageView';

const gaScriptElementId = 'hf-ga-script';

const useGoogleAnalytics = () => {
  const appConfig = useAppConfig(true);
  const isConfigLoaded = !!appConfig;
  const isProduction = isConfigLoaded && !appConfig.showVersionNumber;
  const googleTagId = isProduction
    ? import.meta.env.VITE_GTAG_ID_PRODUCTION
    : import.meta.env.VITE_GTAG_ID_STAGING;

  const isEnabled = !!document.getElementById(gaScriptElementId);

  useEffect(() => {
    if (
      isConfigLoaded &&
      googleTagId &&
      !document.getElementById(gaScriptElementId)
    ) {
      const gaScript = document.createElement('script');
      gaScript.async = true;
      gaScript.id = gaScriptElementId;
      gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleTagId}`;
      gaScript.onload = () => onGoogleAnalyticsLoad(googleTagId);

      const headTag = document.getElementsByTagName('head')[0];
      headTag.append(gaScript);
    }
  }, [isConfigLoaded, googleTagId]);

  useLogCustomPageView(isEnabled);
};

export default useGoogleAnalytics;
