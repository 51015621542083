import { useTranslation } from 'react-i18next';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import { CancelScheduledAutoRefundResponseTO } from 'models/Transactions';
import API from 'services/API';

const useCancelScheduledAutoRefund = () => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  const { mutate, isLoading } = useAppMutation(
    ({
      transactionUid,
    }: {
      successCallback: () => void;
      transactionUid: string;
    }) =>
      API.post<CancelScheduledAutoRefundResponseTO>(
        `/api/internal/transactions/${transactionUid}/cancel-auto-refund`,
      ),
    {
      onSuccess: (response, { successCallback }) => {
        successCallback?.();
      },
      onError: () => {
        notifyError(t('componentTransaction.error.couldNotRecordTransaction'));
      },
    },
  );

  return { cancelScheduledAutoRefund: mutate, isLoading };
};

export default useCancelScheduledAutoRefund;
