import styled from 'styled-components';

export const PropertyLabelPicture = styled.img.attrs({
  'data-testid': 'property-select-label-picture',
})`
  border-radius: 4px;
  border: 1px solid #aeaca8;
  height: 70px;
  padding: 4px;
  width: 90px;
`;
