import { useCallback, useEffect, useRef } from 'react';

interface Props {
  load: () => void;
  hasMore: boolean;
  loader: React.ReactNode;
  children: React.ReactNode;
}
/**
 * This component is a simpler and custom version of react-infinite-scroll-component using IntersectionObserver API.
 *
 * It overcomes the issue of the react-infinite-scroll-component where it doesn't fetch the next page when the user resizes the window.
 *
 * https://github.com/ankeetmaini/react-infinite-scroll-component/issues/380
 *
 * https://github.com/ankeetmaini/react-infinite-scroll-component/issues/399
 */
const InfiniteScroll = ({ load, hasMore, loader, children }: Props) => {
  const sentinelRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  const handleIntersect = useCallback(
    (entries: IntersectionObserverEntry[], _: IntersectionObserver) => {
      // Check if the sentinel element is intersecting, and if so, call the load function
      if (entries[0].isIntersecting && hasMore) {
        load();
      }
    },
    [load],
  );

  useEffect(() => {
    // Create a new IntersectionObserver when the component mounts
    observerRef.current = new IntersectionObserver(handleIntersect, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });

    // Attach the observer to the sentinel element
    if (sentinelRef.current) {
      observerRef.current.observe(sentinelRef.current);
    }

    // Clean up the observer when the component unmounts
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [load]);

  useEffect(() => {
    // When the hasMore prop changes, disconnect the previous observer and reattach it to the new sentinel element
    if (observerRef.current && sentinelRef.current) {
      observerRef.current.disconnect();
      observerRef.current.observe(sentinelRef.current);
    }
  }, [hasMore]);

  return (
    <div className="infinite-scroll-component">
      {children}
      <div ref={sentinelRef}>{hasMore && loader}</div>
    </div>
  );
};

export default InfiniteScroll;
