import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import {
  ThreadAvatarContainer,
  ThreadInfoContainer,
  ThreadInfoParticipantName,
  ThreadsList,
  ThreadsListItem,
} from './InboxThreads.styles';

const InboxThreadsLoader = () => {
  return (
    <ThreadsList>
      {[1, 2, 3, 4, 5].map((number) => (
        <ThreadsListItem active={false} key={number}>
          <ThreadAvatarContainer>
            <LoadingPlaceholder circle />
          </ThreadAvatarContainer>
          <ThreadInfoContainer unread={false}>
            <ThreadInfoParticipantName>
              <LoadingPlaceholder linesCount={1.5} width="75%" />
            </ThreadInfoParticipantName>
            <LoadingPlaceholder width="40%" />
          </ThreadInfoContainer>
        </ThreadsListItem>
      ))}
    </ThreadsList>
  );
};

export default InboxThreadsLoader;
