import { useContext } from 'react';
import TableContext from '../TableContext';

const TableColGroup = () => {
  const { tableInstance } = useContext(TableContext);
  const headerGroups = tableInstance.getHeaderGroups();

  return (
    <>
      {headerGroups.map((headerGroup) => (
        <colgroup key={headerGroup.id}>
          {headerGroup.headers.map((column) => (
            <col key={column.id} className={`column-${column.id}`} />
          ))}
        </colgroup>
      ))}
    </>
  );
};

export default TableColGroup;
