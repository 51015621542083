import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useFetchBookingDotComStatus from 'components/domain/bookingDotCom/useFetchBookingDotComStatus';
import TextField from 'components/forms/textField/TextField';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import FieldPlaceholder from 'pages/property/common/FieldPlaceholder';

const StatusField = () => {
  const { t } = useTranslation();
  const { uid: propertyUid } = useParams();
  const { getCopyAddonIcon } = useCopyToClipboard();

  const { data: status, isLoading: isLoadingStatus } =
    useFetchBookingDotComStatus({ propertyUid });

  if (isLoadingStatus) {
    return (
      <FieldPlaceholder
        label={t('pageProperty.mainSettings.bookingDotComSettings.status')}
        name="bookingDotComSettings.status"
        colSmInput={7}
        colSmLabel={5}
      />
    );
  }

  return (
    <TextField
      label={t('pageProperty.mainSettings.bookingDotComSettings.status')}
      name="bookingDotComSettings.status"
      colSmInput={7}
      colSmLabel={5}
      value={status}
      disabled
      rightAddons={[getCopyAddonIcon(status)]}
    />
  );
};

export default StatusField;
