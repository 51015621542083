import { parseISO } from 'date-fns';
import { SortingState } from '@tanstack/react-table';
import {
  GetOwnerAdjustmentsResponseTO,
  OwnerAdjustment,
} from 'models/ownerAdjustments/OwnerAdjustment';
import { ExpectedQueryData } from 'components/table/hooks/useServerFilteredTable';
import API from 'services/API';
import { getQueryParams } from './common/OwnerAdjustmentsTable.utils';

export const ONE_TIME_ADJUSTMENTS_BASE_QUERY_KEY = 'owner-adjustments';

export const oneTimeAdjustmentsQuery = ({
  propertyUid,
  pageIndex,
  pageSize,
  sorting,
}: {
  propertyUid: string;
  pageIndex: number;
  pageSize: number;
  sorting: SortingState;
}) => ({
  queryKey: [
    ONE_TIME_ADJUSTMENTS_BASE_QUERY_KEY,
    propertyUid,
    { pageIndex, sorting },
  ],
  queryFn: async (): Promise<ExpectedQueryData<OwnerAdjustment>> => {
    const queryParams = getQueryParams({
      propertyUid,
      pageIndex,
      pageSize,
      sorting,
    });

    const response = await API.get<GetOwnerAdjustmentsResponseTO>(
      `/api/internal/owner-adjustments?${queryParams}`,
    );

    const { ownerAdjustments, _metadata: metadata } = response.data;

    return {
      metadata,
      data: ownerAdjustments.map((ownerAdjustment) => ({
        ...ownerAdjustment,
        created: parseISO(ownerAdjustment.created),
        dateAccountedFor: parseISO(ownerAdjustment.dateAccountedFor),
      })),
    };
  },
  enabled: true,
});
