import { useState } from 'react';
import Button from 'components/button/Button';
import FormWithProvider from 'components/forms/form/Form';
import TextField from 'components/forms/textField/TextField';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import useForgotPasswordSubmit from '../useForgotPasswordSubmit';

const ForgotPasswordMobile = () => {
  const [showError, setShowError] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { submitResetPassword } = useForgotPasswordSubmit({
    onSuccess: () => {
      setShowError(false);
      navigate('/login?resetPassword=true');
    },
    onError: () => setShowError(true),
  });

  const handleSubmitForgotPassword = (values) => {
    submitResetPassword(values.email);
  };

  return (
    <FormWithProvider
      horizontal
      onSubmit={handleSubmitForgotPassword}
      schema={{}}
    >
      <h3>{t('pageForgotPassword.resetPassword')}</h3>
      {showError ? (
        <p className="text-danger">{t('pageForgotPassword.error')}</p>
      ) : (
        <p>{t('pageForgotPassword.mobileDescription')}</p>
      )}
      <TextField
        name="email"
        required
        placeholder={t('common.email')}
        colSmInput={12}
      />
      <Button type="submit" block bsSize="large">
        {t('common.continue')}
      </Button>
      <Link className="action-mobile" to="/login">
        {t('pageForgotPassword.returnToSignIn')}
      </Link>
    </FormWithProvider>
  );
};

export default ForgotPasswordMobile;
