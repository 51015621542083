import styled from 'styled-components';
import { Col } from 'react-bootstrap';

export const LeadInternalGuestNotesFormColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  padding-top: 25px;
`;

export const LeadInternalGuestNotesInfo = styled.p.attrs({
  'data-testid': 'internal-guests-notes-info',
})`
  padding-right: 15px;
`;
