import { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { FieldError, useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LanguageSelectField from '../../../../../forms/languageSelectField/LanguageSelectField';
import PhoneNumberField from '../../../../../forms/phoneNumberField/PhoneNumberField';
import SelectField from '../../../../../forms/selectField/SelectField';
import StateSelectField from '../../../../../forms/stateSelectField/StateSelectField';
import TextField from '../../../../../forms/textField/TextField';
import EnvelopeIcon from '../../../../../icons/EnvelopeIcon';
import { countriesListWithFlag } from '../../../../../../utils/constants/countries';
import { LeadClientInfoFormColumn } from './LeadModalClientInfoTab.styles';

const LeadModalClientInfoTab = () => {
  const { errors } = useFormState();
  const { t } = useTranslation();
  const countryCode = useWatch({ name: 'countryCode' });

  useEffect(() => {
    const { email } = errors;

    if (email) {
      const { ref } = email as FieldError;
      setTimeout(() => {
        ref?.focus();
      });
    }
  }, [errors]);

  return (
    <Row>
      <LeadClientInfoFormColumn md={6}>
        <TextField
          label={t('componentLead.modal.form.firstName')}
          name="firstName"
          colSmInput={8}
          colSmLabel={4}
        />
        <TextField
          containerClassName="last-name-container"
          label={t('componentLead.modal.form.lastName')}
          name="lastName"
          colSmInput={8}
          colSmLabel={4}
        />
        <TextField
          label={t('componentLead.modal.form.email')}
          name="email"
          leftAddon={<EnvelopeIcon />}
          colSmInput={8}
          colSmLabel={4}
          required
        />
        <TextField
          label={t('componentLead.modal.form.secondaryEmail')}
          name="secondaryEmail"
          leftAddon={<EnvelopeIcon />}
          colSmInput={8}
          colSmLabel={4}
        />
        <PhoneNumberField
          label={t('componentLead.modal.form.phone')}
          name="phoneNumber"
          colSmInput={8}
          colSmLabel={4}
        />
        <PhoneNumberField
          label={t('componentLead.modal.form.cellPhone')}
          name="cellPhoneNumber"
          colSmInput={8}
          colSmLabel={4}
        />
      </LeadClientInfoFormColumn>
      <LeadClientInfoFormColumn md={6}>
        <TextField
          label={t('componentLead.modal.form.address')}
          name="address"
          colSmInput={8}
          colSmLabel={4}
        />
        <TextField
          containerClassName="address2-container"
          label={t('componentLead.modal.form.address2')}
          name="address2"
          colSmInput={8}
          colSmLabel={4}
        />
        <TextField
          label={t('componentLead.modal.form.city')}
          name="city"
          colSmInput={8}
          colSmLabel={4}
        />
        <StateSelectField
          countryCode={countryCode}
          defaultUsState="CA"
          label={t('componentLead.modal.form.state')}
          name="state"
          colSmInput={8}
          colSmLabel={4}
          secondaryField={
            <TextField
              inline
              name="zipCode"
              placeholder={t('componentLead.modal.form.zipCode')}
            />
          }
        />
        <SelectField
          label={t('componentLead.modal.form.country')}
          name="countryCode"
          options={countriesListWithFlag}
          colSmInput={8}
          colSmLabel={4}
        />
        <LanguageSelectField
          label={t('componentLead.modal.form.language')}
          name="preferredLocale"
          normalizeValue
          colSmInput={8}
          colSmLabel={4}
        />
      </LeadClientInfoFormColumn>
    </Row>
  );
};

export default LeadModalClientInfoTab;
