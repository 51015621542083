import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';
import theme from 'styles/theme';

export const TransactionsListItem = styled.li``;

export const TransactionsListItemContentWrapper = styled(Row)`
  display: flex;
  padding: 0.5rem;

  ${mediaQuery.xs} {
    & > div:not(:first-child) {
      padding-left: 0;
    }

    & > div:not(:last-child) {
      padding-right: 0;
    }
  }
`;

export const TransactionsListItemColumn = styled(Col)`
  align-items: center;
  display: flex;
`;

export const TransactionsListItemActionsColumn = styled(
  TransactionsListItemColumn,
)`
  flex-direction: column;
  align-items: end;
  align-self: center;

  .btn-link {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const TransactionsListItemAmountColumn = styled(
  TransactionsListItemColumn,
)`
  justify-content: end;
`;

export const TransactionsListItemDetailsColumn = styled(
  TransactionsListItemColumn,
)`
  color: ${theme.colors.gray600};
`;

export const TransactionDetailsMultilineWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    padding: 0.25rem 0;
  }

  > :not(:first-child) {
    border-top: 1px solid ${theme.colors.gray300};
  }
`;
