import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { normalizeKey } from 'utils/localeUtils';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import { AvailableAmenity } from 'models/Amenity';
import useScreenBreakpoints from 'hooks/useScreenBreakpoints';
import { AmenityItemStyled } from './CategorizedAmenities.styles';
import AmenityDescriptionIcon from './description/AmenityDescriptionIcon';
import AmenityItemChild from './AmenityItemChild';

const AmenityItem = ({
  amenity,
  isTheLastInItsRow,
  columnPosition,
}: {
  amenity: AvailableAmenity;
  isTheLastInItsRow: boolean;
  columnPosition: number;
}) => {
  const { isXS } = useScreenBreakpoints();
  const { t } = useTranslation();
  const { amenityType, children } = amenity || {};
  const name = `amenities.${amenityType}.value`;
  const { watch, setValue } = useFormContext();
  const amenityValue = watch(name);

  useEffect(() => {
    if (!amenityValue && amenity.supportsDescription) {
      setValue(`${amenityType}.description`, null);
    }
  }, [amenityValue]);

  const amountOfColumns = isXS ? 2 : 3;

  const shouldBeOnNextColumn =
    isTheLastInItsRow &&
    children.length >= 1 &&
    columnPosition < amountOfColumns;
  const gridColumn = shouldBeOnNextColumn ? columnPosition + 1 : null;

  return (
    <>
      <AmenityItemStyled
        $gridColumn={gridColumn}
        data-testid={`amenity-type-${amenityType}`}
      >
        <CheckboxField name={name} disabled={!!amenity.disabled}>
          {t(normalizeKey(`amenities.types.${amenityType}`))}
        </CheckboxField>

        {amenity.supportsDescription && !!amenityValue && !amenity.disabled && (
          <AmenityDescriptionIcon amenity={amenity} />
        )}
      </AmenityItemStyled>
      {children?.map((child) => {
        return (
          <AmenityItemChild
            key={child.amenityType}
            child={child}
            gridColumn={gridColumn}
            parentAmenityType={amenityType}
          />
        );
      })}
    </>
  );
};
export default AmenityItem;
