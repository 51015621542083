import styled from 'styled-components';

export const TextFieldCharacterCounterContainer = styled.span.attrs({
  className: 'character-counter text-muted',
})`
  position: absolute;
  right: 15px;

  .glyphicon {
    margin-left: 5px;
    top: 2px;
  }
`;
