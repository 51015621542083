export enum PhoneResponseResult {
  PHONE_NUMBER_MFA_VERIFICATION_NEEDED = 'PHONE_NUMBER_MFA_VERIFICATION_NEEDED',
  PHONE_NUMBER_UPDATED = 'PHONE_NUMBER_UPDATED',
}

export interface AccountSubmitValues {
  firstName: string;
  lastName: string;
  picture: File[] | null;
  phoneNumber: string;
  phoneNumber_areaCode: number;
  phoneNumber_base: string;
}

export type AccountUpdateParams = Pick<
  AccountSubmitValues,
  'firstName' | 'lastName' | 'picture'
>;
