import { useEffect } from 'react';
import useOpenLeadModal from 'components/domain/lead/modal/useOpenLeadModal';
import useSearchURLParams from 'hooks/useSearchURLParams';

const usePipelineSearchParams = () => {
  const { getParameter } = useSearchURLParams();
  const { updateLeadModal } = useOpenLeadModal();

  const leadUid = getParameter('leadUid');
  const leadUidLegacy = getParameter('leaduid');

  const requestedLeadUid = leadUid || leadUidLegacy;

  useEffect(() => {
    if (requestedLeadUid) {
      updateLeadModal({
        jobCreationNotAllowed: true,
        leadUid: requestedLeadUid,
      });
    }
  }, [requestedLeadUid]);
};

export default usePipelineSearchParams;
