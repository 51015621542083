import { useContext } from 'react';
import { flexRender } from '@tanstack/react-table';
import TableContext from '../TableContext';
import TableBodyLoading from './TableBodyLoading';
import TableEmptyState from './TableEmptyState';
import { TableBodyStyled } from './TableBody.styles';

interface TableBodyProps {
  className?: string;
}

const TableBody = ({ className }: TableBodyProps) => {
  const { isLoading, tableInstance } = useContext(TableContext);
  const { rows } = tableInstance.getRowModel();
  const isEmpty = rows.length === 0;

  if (isLoading) {
    return (
      <TableBodyStyled className={className}>
        <TableBodyLoading />
      </TableBodyStyled>
    );
  }

  return (
    <TableBodyStyled className={className}>
      {isEmpty && <TableEmptyState />}
      {rows.map((row) => (
        <tr key={row.id}>
          {row.getVisibleCells().map((cell) => (
            <td
              className={`body-cell body-cell-${cell.column.id}`}
              key={cell.id}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </TableBodyStyled>
  );
};

TableBody.defaultProps = {
  className: '',
};

export default TableBody;
