import { isDate, parseISO } from 'date-fns';
import { Transaction } from 'models/Transactions';

export function getTransactionCreatedDate(transaction: Transaction): Date {
  if (!transaction.createdDate || !isDate(transaction.createdDate)) {
    transaction.createdDate = parseISO(transaction.createdUtcDateTime);
  }

  return transaction.createdDate;
}

export function getTransactionAutoRefundDate(transaction: Transaction): Date {
  if (
    transaction.autoRefundUtcDateTime &&
    (!transaction.autoRefundDate || !isDate(transaction.autoRefundDate))
  ) {
    transaction.autoRefundDate = parseISO(transaction.autoRefundUtcDateTime);
  }

  return transaction.autoRefundDate;
}
