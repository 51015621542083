import { Trans, useTranslation } from 'react-i18next';
import {
  PRICING_PLAN_CATEGORY,
  PRICING_PLAN_TERM,
  PricingPlan,
} from 'models/PricingPlan';
import { toStartCase } from 'utils/stringUtils';
import UpgradePlanModalTable from './UpgradePlanModalTable';
import UpgradePlanModalPricePerProperty from './UpgradePlanModalPricePerProperty';

interface UpgradePlanModalCurrentProps {
  currentPlan: PricingPlan;
  propertyCount: number;
}

const UpgradePlanModalCurrent = ({
  currentPlan,
  propertyCount,
}: UpgradePlanModalCurrentProps) => {
  const { t } = useTranslation();

  if (!currentPlan) {
    return null;
  }

  const plan = toStartCase(currentPlan.category);
  const term =
    currentPlan.term === PRICING_PLAN_TERM.ANNUAL
      ? t('componentProperty.upgradePlanModal.annual')
      : t('componentProperty.upgradePlanModal.monthly');

  const data = [
    {
      description: t('componentProperty.upgradePlanModal.currentTermPrice', {
        term,
      }),
      price: currentPlan.price,
    },
  ];

  return (
    <section
      className="current-plan"
      data-testid="upgrade-plan-modal-current-plan"
    >
      <UpgradePlanModalTable
        caption={
          <Trans
            i18nKey="componentProperty.upgradePlanModal.youAreCurrentlyUsing"
            values={{ plan }}
            components={{ strong: <strong /> }}
          />
        }
        data={data}
      />
      {currentPlan.category === PRICING_PLAN_CATEGORY.PREMIUM && (
        <UpgradePlanModalPricePerProperty
          planPrice={currentPlan.price}
          propertyCount={propertyCount}
        />
      )}
    </section>
  );
};

export default UpgradePlanModalCurrent;
