import { useTranslation } from 'react-i18next';
import { normalizeKey } from 'utils/localeUtils';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import QuestionCircleHelp from 'assets/images/question-circle.svg?react';
import Tooltip from 'components/tooltip/Tooltip';
import { Glyphicon } from 'react-bootstrap';
import {
  AnalyticsListItemHeader,
  AnalyticsListItemNegativePercentage,
  AnalyticsListItemPercentage,
  AnalyticsListItemPlaceHolder,
  AnalyticsListItemPositivePercentage,
  AnalyticsListItemValue,
  AnalyticsListItemWrapper,
} from './AnalyticsList.styles';

interface Props {
  labelKey: string;
  value: string;
  percentage?: number;
  helpKey?: string;
  isLoading: boolean;
  backgroundColor: string;
  error: any;
  notApplicable?: boolean;
  isPercentageLoading: boolean;
}

const AnalyticsListItem = ({
  labelKey,
  value,
  percentage,
  helpKey,
  isLoading,
  backgroundColor,
  error,
  notApplicable,
  isPercentageLoading,
}: Props) => {
  const { t } = useTranslation();
  const displayPercentage =
    percentage != null && !isPercentageLoading && !error && !notApplicable;

  if (isLoading)
    return (
      <AnalyticsListItemWrapper
        isLoading={isLoading}
        backgroundColor={backgroundColor}
      >
        <AnalyticsListItemPlaceHolder data-testid="analytics-list-item-placeholder" />
      </AnalyticsListItemWrapper>
    );

  return (
    <AnalyticsListItemWrapper
      data-testid={`analytics-list-${labelKey}`}
      backgroundColor={backgroundColor}
    >
      <AnalyticsListItemHeader>
        {t(normalizeKey(`pageAnalytics.card.${labelKey}`))}
        {helpKey && (
          <Tooltip
            text={t(normalizeKey(`pageAnalytics.card.${helpKey}`))}
            id={helpKey}
          >
            <QuestionCircleHelp className="help-icon" />
          </Tooltip>
        )}
      </AnalyticsListItemHeader>
      <AnalyticsListItemValue>
        {isLoading && <LoadingPlaceholder linesCount={1} width="100px" />}
        {!isLoading && (error || notApplicable) && '--'}
        {!isLoading && !error && !notApplicable && value}
      </AnalyticsListItemValue>
      {displayPercentage && (
        <AnalyticsListItemPercentage>
          {isPercentageLoading && (
            <LoadingPlaceholder linesCount={1} width="25px" />
          )}
          {!isPercentageLoading && (error || notApplicable) && '--'}

          {percentage >= 0 && (
            <AnalyticsListItemPositivePercentage
              data-testid={`analytics-list-${labelKey}-percentage`}
            >
              <Glyphicon glyph="triangle-top" />
              {`+${percentage}%`}
            </AnalyticsListItemPositivePercentage>
          )}
          {percentage < 0 && (
            <AnalyticsListItemNegativePercentage
              data-testid={`analytics-list-${labelKey}-percentage`}
            >
              <Glyphicon glyph="triangle-bottom" />
              {`${percentage}%`}
            </AnalyticsListItemNegativePercentage>
          )}
        </AnalyticsListItemPercentage>
      )}
    </AnalyticsListItemWrapper>
  );
};

AnalyticsListItem.defaultProps = {
  helpKey: null,
  notApplicable: false,
  percentage: null,
};

export default AnalyticsListItem;
