import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RichTextEditorControlsAttachmentIcon,
  RichTextEditorControlsAttachmentIconContainer,
} from '../RichTextEditor.styles';
import Tooltip from '../../tooltip/Tooltip';

const ControlsPluginAttachments = ({
  disabled,
  onAttachmentSelected,
}: {
  disabled: boolean;
  onAttachmentSelected: (_event: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { t } = useTranslation();

  return (
    <RichTextEditorControlsAttachmentIconContainer htmlFor="attachment-upload">
      <Tooltip
        id="rich-text-editor-attachment-icon-tooltip"
        text={t('common.attachFiles')}
      >
        <RichTextEditorControlsAttachmentIcon disabled={disabled} />
      </Tooltip>
      <input
        disabled={disabled}
        hidden
        data-testid="attachment-upload"
        id="attachment-upload"
        multiple
        onChange={onAttachmentSelected}
        type="file"
      />
    </RichTextEditorControlsAttachmentIconContainer>
  );
};

export default ControlsPluginAttachments;
