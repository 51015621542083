import styled from 'styled-components';

export const Actions = styled.div.attrs({})`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 10px;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;

  & :not(:last-child) {
    margin-right: 10px;
  }
`;
