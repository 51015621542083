import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import TextField from 'components/forms/textField/TextField';
import Button from 'components/button/Button';

const LoginForm = ({
  errorMessage,
  clearErrors,
}: {
  errorMessage: string;
  clearErrors: () => void;
}) => {
  const { t } = useTranslation();
  const { email, password, registrationToken } = useWatch();

  useEffect(() => {
    clearErrors();
  }, [email, password]);

  return (
    <>
      <TextField
        placeholder={t('pageLogin.emailAddress')}
        colSmInput={12}
        name="email"
        type="email"
        disabled={!!registrationToken}
      />
      <TextField
        placeholder={t('pageLogin.password')}
        colSmInput={12}
        name="password"
        type="password"
      />
      <CheckboxField name="rememberMe">
        {t('pageLogin.rememberMe')}
      </CheckboxField>
      <Button
        data-testid="submit-button"
        disabled={!!errorMessage}
        type="submit"
        block
        bsSize="large"
      >
        {t('pageLogin.signIn')}
      </Button>
      {!!errorMessage && (
        <p className="error-message" data-testid="error-message">
          {errorMessage}
        </p>
      )}
    </>
  );
};

export default LoginForm;
