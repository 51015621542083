import { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useFetchPropertiesFilterOptions } from 'components/domain/property/useFetchPropertiesFilterOptions';
import useLocalStorage from 'hooks/useLocalStorage';
import { isDeepEqual } from 'utils/objectUtils';
import PropertiesContext from '../PropertiesContext';
import {
  PropertiesFilterSchema,
  PropertiesFilterSchemaType,
  getFormDefaultValues,
} from './PropertiesFilter.schema';

const usePropertiesFilterFormApplyStoredValues = () => {
  const { getValues: getCurrentFormValues } = useFormContext();
  const { propertiesFilterLocalStorageKey } = useContext(PropertiesContext);
  const { data: serverFilterOptions } = useFetchPropertiesFilterOptions();
  const { getValue: getStoredFilterFormValues } =
    useLocalStorage<PropertiesFilterSchemaType>(
      propertiesFilterLocalStorageKey,
    );
  const { setValue } = useFormContext();

  useEffect(() => {
    const currentFormValues = getCurrentFormValues();
    const storedFormValues = getStoredFilterFormValues();
    if (!storedFormValues || !currentFormValues || !serverFilterOptions) {
      return;
    }

    const defaultValues = getFormDefaultValues(serverFilterOptions);
    const storedValues = PropertiesFilterSchema.cast(storedFormValues);

    Object.keys(storedValues)
      .filter((key) => !isDeepEqual(storedValues[key], defaultValues[key]))
      .forEach((key) => {
        setValue(key, storedValues[key], {
          shouldDirty: true,
        });
      });
  }, []);
};

export default usePropertiesFilterFormApplyStoredValues;
