import { Trans, useTranslation } from 'react-i18next';
import { Modal, ModalTitle } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import Button from 'components/button/Button';
import FormWithProvider from 'components/forms/form/Form';
import useAppModal from 'hooks/useAppModal';
import useNotify from 'hooks/useNotify';
import { toStartCase } from 'utils/stringUtils';
import useFetchPricingPlan, {
  PRICING_PLAN_BASE_QUERY_KEY,
} from '../../useFetchPricingPlan';
import useUpgradePricingPlanMutation from '../../useUpgradePricingPlanMutation';
import { UpgradePlanModalStyled } from './UpgradePlanModal.styles';
import UpgradePlanModalCurrent from './UpgradePlanModalCurrent';
import UpgradePlanModalNext from './UpgradePlanModalNext';
import { upgradePlanModalId } from './UpgradePlanModal.constants';
import useThankYouModal from './useThankYouModal';

const UpgradePlanModal = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { notifyError } = useNotify();
  const { openThankYouModal } = useThankYouModal();
  const { closeModal } = useAppModal();
  const { data: pricingPlan, isLoading } = useFetchPricingPlan();
  const { mutateAsync: upgradePricingPlan, isLoading: isUpgradingPlan } =
    useUpgradePricingPlanMutation({
      onError: () => {
        notifyError(t('componentProperty.upgradePlanModal.onUpgradeError'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([PRICING_PLAN_BASE_QUERY_KEY]);
        closeModal(upgradePlanModalId);
        openThankYouModal();
      },
    });

  const isDisabled = isLoading || isUpgradingPlan;

  if (isLoading || !pricingPlan) {
    return null;
  }

  const { currentPlan } = pricingPlan;
  const nextPlan = pricingPlan.tierUpgradeOffer;

  const handleCancel = () => {
    closeModal(upgradePlanModalId);
  };

  const handleSubmit = () => {
    return upgradePricingPlan({ nextPlan, currentPlan });
  };

  return (
    <UpgradePlanModalStyled data-testid="upgrade-plan-modal">
      <Modal.Header data-testid="upgrade-plan-modal-header" closeButton>
        <ModalTitle>
          {t('componentProperty.upgradePlanModal.oneClickUpgrade')}
        </ModalTitle>
      </Modal.Header>
      <fieldset>
        <Modal.Body>
          <section className="content" data-testid="upgrade-plan-modal-content">
            <p>{t('componentProperty.upgradePlanModal.needToAddProperties')}</p>
            {currentPlan?.category && nextPlan?.category && (
              <p>
                {t('componentProperty.upgradePlanModal.currentToNextPlan', {
                  current: toStartCase(currentPlan.category),
                  next: toStartCase(nextPlan.category),
                })}
              </p>
            )}
          </section>
          <UpgradePlanModalCurrent
            currentPlan={currentPlan}
            propertyCount={pricingPlan.propertyCount}
          />
          <UpgradePlanModalNext
            currentPlan={currentPlan}
            nextPlan={nextPlan}
            propertyCount={pricingPlan.propertyCount}
          />
          <p className="terms" data-testid="upgrade-plan-modal-terms">
            <Trans
              i18nKey="componentProperty.upgradePlanModal.noticeAndTerms"
              components={{
                linkPrivacyNotice: (
                  // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                  <a
                    href="https://www.hostfully.com/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
                linkTermsOfService: (
                  // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                  <a
                    href="https://www.hostfully.com/terms-of-service/"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
              }}
            />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <FormWithProvider onSubmit={handleSubmit}>
            <Button
              data-testid="upgrade-plan-modal-cancel-button"
              disabled={isDisabled}
              onClick={handleCancel}
            >
              {t('common.cancel')}
            </Button>
            <Button
              data-testid="upgrade-plan-modal-save-button"
              bsStyle="primary"
              disabled={isDisabled || !nextPlan}
              type="submit"
            >
              {isDisabled
                ? t('common.saving')
                : t('componentProperty.upgradePlanModal.upgradeMyAccount')}
            </Button>
          </FormWithProvider>
        </Modal.Footer>
      </fieldset>
    </UpgradePlanModalStyled>
  );
};

export default UpgradePlanModal;
