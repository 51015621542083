import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Loader from '../../../components/loader/Loader';
import {
  LoadMoreLink,
  NothingFoundMessage,
  ThreadsList,
} from './InboxThreads.styles';
import useInboxThreads from './useInboxThreads';
import InboxThread from './InboxThread';
import InboxThreadsLoader from './InboxThreadsLoader';

const InboxThreads = () => {
  const { isLoading, isLoadingMoreThreads, hasMore, loadMore, threads } =
    useInboxThreads();
  const { t } = useTranslation();

  return (
    <>
      {!isLoading && !threads?.length && (
        <NothingFoundMessage>
          {t('pageInbox.threadsList.noThreadFound')}
        </NothingFoundMessage>
      )}
      <Col xs={5} sm={4} md={3}>
        {isLoading && <InboxThreadsLoader />}
        {!isLoading && (
          <>
            <ThreadsList>
              {threads?.map((thread) => (
                <InboxThread
                  key={`${thread.uid}-${thread.lastUpdateDateObj}`}
                  thread={thread}
                />
              ))}
            </ThreadsList>
            {hasMore && (
              <div className="text-center">
                {isLoadingMoreThreads && <Loader />}
                {!isLoadingMoreThreads && (
                  <LoadMoreLink onClick={loadMore}>
                    {t('pageInbox.threadsList.loadMore')}
                  </LoadMoreLink>
                )}
              </div>
            )}
          </>
        )}
      </Col>
    </>
  );
};

export default InboxThreads;
