import { useTranslation } from 'react-i18next';
import useTurnoCleaners from '../hooks/useTurnoCleaners';
import {
  Container,
  Divider,
  CleanerWrapper,
  ContainerFooter,
} from './TopRatedCleaner.styles';
import MyCleaner from './MyCleaner';

const TopRatedCleaner = () => {
  const { t } = useTranslation();
  const { cleaners, cleanersLoading } = useTurnoCleaners();

  if (!cleanersLoading && (!cleaners || cleaners?.length === 0)) {
    return null;
  }

  return (
    <Container>
      <div>
        <h3>{t('pageTurnoManagement.topRatedCleaners')}</h3>
        <p>{t('pageTurnoManagement.checkOutTheseVettedShortTerm')}</p>
      </div>

      <Divider />

      <CleanerWrapper>
        {cleanersLoading &&
          Array.from({ length: 3 }).map((index) => {
            return <MyCleaner key={`cleaner-${index}`} isLoading />;
          })}

        {!cleanersLoading &&
          cleaners?.map(({ cleaner, value, currency }) => {
            return (
              <MyCleaner
                value={value}
                currency={currency}
                key={`${cleaner.first_name}-${value}`}
                isLoading={false}
                cleaningOffer={cleaner}
              />
            );
          })}
      </CleanerWrapper>

      <ContainerFooter>
        <span>{t('pageTurnoManagement.poweredByTurno')}</span>
        <span className="additional-info">
          {t('pageTurnoManagement.forTwoBedroomProperties')}
        </span>
      </ContainerFooter>
    </Container>
  );
};

export default TopRatedCleaner;
