import { memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'components/loader/Loader';
import InboxMessagesContext from './InboxMessagesContext';
import { InboxNoMessagesInfo } from './InboxMessages.styles';
import InboxMessage from './InboxMessage';

const InboxMessagesList = memo(() => {
  const { hasMoreMessages, loadMoreMessages, messages } =
    useContext(InboxMessagesContext);
  const { t } = useTranslation();

  const loader = useMemo(() => <Loader />, []);

  if (!messages.length) {
    return (
      <InboxNoMessagesInfo>
        {t('pageInbox.messages.noMessagesInfo')}
      </InboxNoMessagesInfo>
    );
  }

  return (
    <InfiniteScroll
      dataLength={messages.length}
      next={loadMoreMessages}
      hasMore={hasMoreMessages}
      loader={loader}
      scrollableTarget="app-page-content"
    >
      {messages.map((message) => (
        <InboxMessage key={message.uid} message={message} />
      ))}
    </InfiniteScroll>
  );
});

export default InboxMessagesList;
