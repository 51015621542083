import useFetchLead from '../useFetchLead';

const fields = `
  address,
  address2,
  adultCount,
  cellPhoneNumber,
  childrenCount,
  city,
  countryCode,
  email,
  extraNotes,
  firstName,
  flightNumber,
  infantCount,
  lastName,
  notes,
  petCount,
  phoneNumber,
  preferredLocale,
  property{uid},
  reasonForTrip
  secondaryEmail,
  state,
  zipCode,
`;

const useLeadForRebooking = (leadUid: string | undefined) => {
  return useFetchLead({ leadUid, fields });
};

export default useLeadForRebooking;
