import { isMobile } from 'react-device-detect';

import ForgotPasswordDesktop from './forgotPasswordDesktop/ForgotPasswordDesktop';
import ForgotPasswordMobile from './forgotPasswordMobile/ForgotPasswordMobile';

const ForgotPassword = () => {
  return isMobile ? <ForgotPasswordMobile /> : <ForgotPasswordDesktop />;
};

export default ForgotPassword;
