import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import useAppEvent from 'hooks/useAppEvent';
import useNotify from 'hooks/useNotify';
import {
  AppEventType,
  PropertySettingsUpdatedEventPayload,
} from 'models/Events';
import { CategorizedAmenitiesFormValues } from './CategorizedAmenities.schema';
import { handlePropertyAmenitiesUpdateSuccess } from './useCategorizedAmenitiesFormSubmit';

const CategorizedAmenitiesEventListeners = () => {
  const { subscribe, unsubscribe } = useAppEvent();
  const { reset } = useFormContext<CategorizedAmenitiesFormValues>();
  const { notifySuccess } = useNotify();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  useEffect(() => {
    const amenitiesUpdatedListener = async ({
      detail: { propertyUid },
    }: CustomEvent<PropertySettingsUpdatedEventPayload>) => {
      await handlePropertyAmenitiesUpdateSuccess({
        notifySuccess,
        propertyUid,
        queryClient,
        reset,
        t,
      });
    };

    subscribe(AppEventType.PROPERTY_SETTINGS_UPDATED, amenitiesUpdatedListener);

    return () => {
      unsubscribe(
        AppEventType.PROPERTY_SETTINGS_UPDATED,
        amenitiesUpdatedListener,
      );
    };
  }, []);

  return null;
};

export default CategorizedAmenitiesEventListeners;
