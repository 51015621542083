import { Col, ModalBody, ModalFooter, Row } from 'react-bootstrap';
import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import TextField from 'components/forms/textField/TextField';
import useFormFieldErrorMessage from 'components/forms/_errorMessage/useFormFieldErrorMessage';
import useGetCurrencySymbol from 'hooks/useGetCurrencySymbol';
import useScreenBreakpoints from 'hooks/useScreenBreakpoints';
import { Lead } from 'models/Leads';
import { TransactionStatus } from 'models/Transactions';
import {
  TransactionsListItemAmountColumn,
  TransactionsListItemColumn,
} from '../OrderViewTransactionsListItem.styles';
import TransactionAmountRenderer from '../TransactionAmountRenderer';
import {
  IssueRefundTransactionDetailsSection,
  IssueRefundTransactionDetailsWrapper,
} from './IssueRefundModalFormElements.styles';
import { AdditionalChargeAmountRow } from './AdditionalChargeModalFormElements.styles';

const AdditionalChargeModalFormElements = ({
  lead,
  onCancel,
}: {
  lead: Lead;
  onCancel: () => void;
}) => {
  const getCurrencySymbol = useGetCurrencySymbol();
  const errorMessage = useFormFieldErrorMessage('root');
  const { isSubmitting } = useFormState();
  const { isXS } = useScreenBreakpoints();
  const { t } = useTranslation();

  const {
    order: { currency, securityDeposit },
  } = lead;

  return (
    <>
      <ModalBody>
        <div data-testid="additional-charge-info">
          {t('pageOrder.transactions.actions.additionalCharge.info')}
        </div>
        <IssueRefundTransactionDetailsSection>
          <IssueRefundTransactionDetailsWrapper data-testid="transaction-details">
            <TransactionsListItemColumn
              data-testid="security-deposit-label"
              xs={4}
            >
              {t(
                'pageOrder.transactions.actions.additionalCharge.securityDeposit',
              )}
            </TransactionsListItemColumn>
            <TransactionsListItemColumn
              className="text-muted"
              data-testid="additional-charge-option-label"
              xs={6}
            >
              {t('pageOrder.transactions.actions.additionalCharge.option')}
            </TransactionsListItemColumn>
            <TransactionsListItemAmountColumn xs={2}>
              <TransactionAmountRenderer
                lead={lead}
                transaction={{
                  amount: securityDeposit,
                  status: TransactionStatus.SUCCESS,
                }}
              />
            </TransactionsListItemAmountColumn>
          </IssueRefundTransactionDetailsWrapper>
        </IssueRefundTransactionDetailsSection>
        <AdditionalChargeAmountRow>
          <TextField
            label={t('pageOrder.transactions.actions.additionalCharge.amount')}
            name="amount"
            colSmInput={7}
            colSmLabel={5}
            labelOnTop={isXS}
            leftAddon={getCurrencySymbol(currency)}
            min={0}
            required
          />
        </AdditionalChargeAmountRow>
        {!!errorMessage && (
          <Row className="has-error">
            <Col className="help-block" xs={7} xsOffset={5}>
              {errorMessage}
            </Col>
          </Row>
        )}
      </ModalBody>
      <ModalFooter>
        <Button disabled={isSubmitting} onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button bsStyle="primary" disabled={isSubmitting} type="submit">
          {t('pageOrder.transactions.actions.additionalCharge.confirmCharge')}
        </Button>
      </ModalFooter>
    </>
  );
};

export default AdditionalChargeModalFormElements;
