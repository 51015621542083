import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import { useTranslation } from 'react-i18next';
import API from 'services/API';

const useBookingDotComOperationsQuickConnectNotify = () => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  const { mutateAsync } = useAppMutation(
    () => {
      return API.get(
        '/api/internal/bookingdotcom-operations/quick-connect-finish-notify',
      );
    },
    {
      onSuccess: () => {},
      onError: () =>
        notifyError(
          t(
            'pageProperties.importBookingDotComPropertiesModal.errorRefreshProvider',
          ),
        ),
    },
  );

  const handleQuickConnectFinishNotify = () =>
    new Promise((resolve) => {
      mutateAsync().then(resolve);
    });

  return { handleQuickConnectFinishNotify };
};

export default useBookingDotComOperationsQuickConnectNotify;
