import { Modal } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GuestLink } from './LeadModalHeader.styles';

function getExitingLeadTitle(watch) {
  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const email = watch('email');

  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  if (firstName || lastName) {
    return firstName || lastName;
  }

  return email || '';
}

function getExistingLeadLink(watch) {
  const email = watch('email');

  return `#/client?e=${encodeURIComponent(email)}`;
}

const LeadModalHeaderTitle = () => {
  const { watch } = useFormContext();
  const { t } = useTranslation();

  const updateMode = !!watch('uid');

  return (
    <Modal.Title data-testid="lead-modal-title">
      {updateMode ? (
        <GuestLink
          href={getExistingLeadLink(watch)}
          rel="noreferrer"
          target="_blank"
        >
          {getExitingLeadTitle(watch)}
        </GuestLink>
      ) : (
        t('componentLead.addLead')
      )}
    </Modal.Title>
  );
};

export default LeadModalHeaderTitle;
