import styled from 'styled-components';

export const BedTypesContainer = styled.div`
  border-bottom: 1px solid #e5e5e5;

  & > div {
    display: flex;
  }

  .form-field-container {
    & > .control-label:not(.label-on-top) {
      padding-left: 0;
      padding-right: 0;
      text-align: left !important;
    }

    & > div {
      padding-left: 5px;
      padding-right: 0;
    }

    .input-group-addon {
      .btn {
        padding: 3px 6px;
      }
    }

    .bed-types-select + .help-block {
      display: none;
    }
  }
`;
