import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import useFetchAssignmentSettingsWithEmployees, {
  AssigneeWithEmployee,
} from 'components/domain/property/useFetchAssignmentSettingsWithEmployees';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import useAgencySettings from 'hooks/useAgencySettings';
import { PropertyBase } from 'models/Properties';
import { UserEmployeeRole } from 'models/Users';
import BulkSaveButton from 'components/button/BulkSaveButton';
import Button from 'components/button/Button';
import SwitchField from 'components/forms/switchField/SwitchField';
import SelectField from 'components/forms/selectField/SelectField';
import {
  PropertyPermissionsModalContent,
  PropertyPermissionsModalFooter,
} from './PropertyPermissionsModal.styles';
import PropertyPermissionsEmployeeItem from './PropertyPermissionsEmployeeItem';
import PropertyPermissionsEmployeeItemPlaceholder from './PropertyPermissionsEmployeeItemPlaceholder';
import { FormValues } from './PropertyPermissionModal.types';

const PropertyPermissionsModal = ({
  property,
  onClose,
  onBulkSaveClick,
}: {
  property: Pick<PropertyBase, 'uid'>;
  onClose: () => void;
  onBulkSaveClick: (
    values: FormValues,
    enabledAssignees: AssigneeWithEmployee[],
  ) => void;
}) => {
  const { data: assignmentSettings, isLoading } =
    useFetchAssignmentSettingsWithEmployees({ propertyUid: property.uid });
  const { isAgencySettingsLoading, isLeadAssignmentEnabled } =
    useAgencySettings({ cacheTime: 30_000 });
  const { t } = useTranslation();
  const {
    setValue,
    getValues,
    formState: { isSubmitting },
  } = useFormContext<FormValues>();
  const isDisabled = isSubmitting || isAgencySettingsLoading;
  const isAutoAssignOn = useWatch({ name: 'autoAssignLeads' });
  const allAssignees = assignmentSettings?.assignees ?? [];
  const employeeFieldNames = allAssignees.map(
    (a) => `employeeUid-${a.employeeUid}`,
  );

  const updatedAssignees = useWatch({ name: employeeFieldNames }).map(
    (enabled, index) => ({
      ...allAssignees[index],
      enabled,
    }),
  );

  const enabledAssignees = updatedAssignees.filter(({ enabled }) => enabled);

  useEffect(() => {
    if (assignmentSettings) {
      assignmentSettings.assignees.forEach(({ employeeUid, enabled }) => {
        setValue(`employeeUid-${employeeUid}`, enabled);
      });

      const firstManagerUid = assignmentSettings.assignees.find(
        (assignee) => assignee.employee.role === UserEmployeeRole.MANAGER,
      )?.employeeUid;

      setValue(
        'autoAssignLeads',
        assignmentSettings.automaticAssignment.enabled,
      );
      setValue(
        'autoAssignLeadsTo',
        assignmentSettings.automaticAssignment.employeeUid ?? firstManagerUid,
      );
    }
  }, [assignmentSettings]);

  return (
    <fieldset disabled={isSubmitting}>
      <PropertyPermissionsModalContent>
        <div className="employees-list">
          <h2>{t('pageProperties.permissions.selectAllEmployees')}</h2>
          {isLoading && <PropertyPermissionsEmployeeItemPlaceholder />}
          {assignmentSettings?.assignees.map(
            ({ employee, employeeUid, editable }) => (
              <PropertyPermissionsEmployeeItem
                propertyUid={property.uid}
                editable={editable}
                key={employeeUid}
                employee={employee}
              />
            ),
          )}
        </div>
        <hr />
        <div className="auto-assign-field">
          {isAgencySettingsLoading && <LoadingPlaceholder />}
          {!isAgencySettingsLoading && (
            <SwitchField
              name="autoAssignLeads"
              disabled={!isLeadAssignmentEnabled}
            >
              <span>
                {t('pageProperties.permissions.autoAssignLeadsForThisProperty')}
              </span>
            </SwitchField>
          )}
        </div>

        {!isAgencySettingsLoading && isAutoAssignOn && (
          <div className="auto-assign-field">
            <span>{t('pageProperties.permissions.autoAssignLeadsTo')}:</span>
            <SelectField
              colSmInput={12}
              name="autoAssignLeadsTo"
              options={enabledAssignees.map(({ employee }) => ({
                label: employee.bestProfile,
                value: employee.uid,
              }))}
            />
          </div>
        )}
      </PropertyPermissionsModalContent>
      <PropertyPermissionsModalFooter>
        <Button
          data-testid="modal-cancel-button"
          disabled={isDisabled}
          onClick={onClose}
        >
          {t('common.cancel')}
        </Button>
        <BulkSaveButton
          data-testid="modal-bulk-save-button"
          onClick={() => onBulkSaveClick(getValues(), enabledAssignees)}
          disabled={isDisabled}
        >
          {t('pageProperties.permissions.bulkSave')}
        </BulkSaveButton>
        <Button
          data-testid="modal-save-button"
          bsStyle="primary"
          disabled={isDisabled}
          type="submit"
        >
          {t('common.save')}
        </Button>
      </PropertyPermissionsModalFooter>
    </fieldset>
  );
};

export default PropertyPermissionsModal;
