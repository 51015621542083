import useAppSelector from 'hooks/useAppSelector';
import { getPropertyUidFromStringType } from 'utils/property/propertyUtils';
import useStackedCalendarVisibleProperties from '../useStackedCalendarVisibleProperties';
import {
  PropertiesListContainer,
  PropertiesListElements,
} from './PropertiesList.styles';
import PropertiesListLoader from './PropertiesListLoader';
import PropertyListItem from './PropertyListItem';

const PropertiesList = () => {
  const isLoadingProperties = useAppSelector(
    (state) => state.stackedCalendar.isLoadingProperties,
  );
  const visibleProperties = useStackedCalendarVisibleProperties();

  if (isLoadingProperties) {
    return <PropertiesListLoader />;
  }

  return (
    <PropertiesListContainer>
      <PropertiesListElements>
        {visibleProperties.map((uid) => {
          const { propertyUid } = getPropertyUidFromStringType(uid);
          return (
            <PropertyListItem key={propertyUid} propertyUid={propertyUid} />
          );
        })}
      </PropertiesListElements>
    </PropertiesListContainer>
  );
};

export default PropertiesList;
