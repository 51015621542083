import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropertyBase, PropertyBusinessType } from 'models/Properties';
import { PropertiesBodyListStyled } from '../body/PropertiesBody.styles';
import { ExpandCollapseButtonStyled } from './PropertyListItem.styles';
import CollapseIcon from './icons/CollapseIcon.svg?react';
import ExpandIcon from './icons/ExpandIcon.svg?react';

interface PropertyCollapsibleAreaProps {
  children: ReactNode;
  property: Pick<PropertyBase, 'businessType'>;
}

const PropertyCollapsibleArea = ({
  children,
  property,
}: PropertyCollapsibleAreaProps) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(true);
  const action = isExpanded ? t('common.hide') : t('common.view');
  const handleExpandOrCollapseClick = () =>
    setIsExpanded((current) => !current);

  return (
    <>
      <ExpandCollapseButtonStyled
        data-testid="property-expand-button"
        bsStyle="link"
        onClick={handleExpandOrCollapseClick}
      >
        {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
        {property.businessType === PropertyBusinessType.HOTEL
          ? t('pageProperties.body.expandAndCollapse.multiUnit', { action })
          : t('pageProperties.body.expandAndCollapse.masterUnit', { action })}
      </ExpandCollapseButtonStyled>

      {isExpanded && (
        <PropertiesBodyListStyled $isChildPropertiesContainer>
          {children}
        </PropertiesBodyListStyled>
      )}
    </>
  );
};

export default PropertyCollapsibleArea;
