import { useTranslation } from 'react-i18next';
import SelectField from 'components/forms/selectField/SelectField';
import { RoomType } from 'models/PropertyRooms';
import { normalizeKey } from 'utils/localeUtils';

const RoomsAndBedTypesFormRoomTypeField = ({
  roomIndex,
}: {
  roomIndex: number;
}) => {
  const { t } = useTranslation();

  const roomTypeOptions = [RoomType.BEDROOM, RoomType.LIVING_ROOM]
    .map((roomType) => `${roomType}`)
    .map((roomType) => ({
      label: t(normalizeKey(`componentProperty.rooms.roomType.${roomType}`)),
      value: roomType,
    }));

  return (
    <SelectField
      label={t(
        'pageProperty.mainSettings.capacityDetails.setRoomsAndBedTypesModal.roomType',
      )}
      name={`rooms.${roomIndex}.roomType`}
      options={roomTypeOptions}
      colSmLabel={5}
      colSmInput={7}
    />
  );
};

export default RoomsAndBedTypesFormRoomTypeField;
