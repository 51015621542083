import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import InboxContext from '../InboxContext';
import {
  getThreadRelatedLeadCheckInDate,
  getThreadRelatedLeadCheckOutDate,
} from '../../../utils/lead/leadUtils';
import { formatRangeShortWithCurrentYearCheck } from '../../../utils/dateTimeUtils';
import {
  LeadSelectorButton,
  LeadSelectorItem,
  LeadSelectorItemDates,
  LeadSelectorItemPropertyName,
} from './InboxLead.styles';
import InboxLeadsContext from './InboxLeadsContext';

const InboxLeadSelector = () => {
  const { activeThread, propertiesMap } = useContext(InboxContext);
  const { threadRelatedLeadsMap, setSelectedLeadUid } =
    useContext(InboxLeadsContext);
  const { t } = useTranslation();

  if (!activeThread) {
    return null;
  }

  const { uid: threadUid } = activeThread;
  const leads = threadRelatedLeadsMap[threadUid];

  if (!leads || leads.length < 2) {
    return null;
  }

  return (
    <LeadSelectorButton
      title={t('pageInbox.leads.selectLead')}
      data-testid="inbox-lead-selector"
      id="inbox-lead-selector"
    >
      {leads.map((lead) => {
        const { leadUid, propertyUid } = lead;
        const { name: propertyName } = propertiesMap[propertyUid] || {};
        const checkInDate = getThreadRelatedLeadCheckInDate(lead);
        const checkOutDate = getThreadRelatedLeadCheckOutDate(lead);
        const selectLeadHandler = () => {
          setSelectedLeadUid(leadUid);
        };

        return (
          <LeadSelectorItem
            data-testid={`lead-selector-item-${leadUid}`}
            key={`lead-selector-item-${leadUid}`}
            onSelect={selectLeadHandler}
          >
            <LeadSelectorItemPropertyName>
              {propertyName}
            </LeadSelectorItemPropertyName>
            <LeadSelectorItemDates>
              {formatRangeShortWithCurrentYearCheck(
                checkInDate,
                checkOutDate,
                t,
              )}
            </LeadSelectorItemDates>
          </LeadSelectorItem>
        );
      })}
    </LeadSelectorButton>
  );
};

export default InboxLeadSelector;
