import { memo } from 'react';
import { getDayCellCommonClasses } from 'pages/calendar/common/cells/DayCellCommon.styles';
import { classNames } from 'utils/classNameUtils';

const LoadingDayCell = memo(
  ({
    dayTimestamp,
    isWeekend,
    width,
  }: {
    dayTimestamp: number;
    isWeekend: boolean;
    width: number;
  }) => {
    return (
      <div
        className={classNames({
          'body-day-cell body-inactive-day-cell': true,
          [getDayCellCommonClasses(new Date(dayTimestamp))]: true,
          [`width-${width}`]: true,
          weekend: isWeekend,
        })}
        data-day-date={dayTimestamp}
      >
        <span className="loading-placeholder first">{'\u200c'}</span>
        <span className="loading-placeholder second">{'\u200c'}</span>
      </div>
    );
  },
);

export default LoadingDayCell;
