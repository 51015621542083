import styled from 'styled-components';
import theme from 'styles/theme';
import DollarLeadIcon from './DollarLeadIcon';
import RentalLeadIcon from './RentalLeadIcon';
import TickLeadIcon from './TickLeadIcon';

const Container = styled.div``;

interface LeadStatusIconProps {
  statusColor: 'neutral' | 'success' | 'warning';
  type: 'tick' | 'rental' | 'dollar';
  id: string;
}

const LeadStatusIcon = ({ statusColor, type, id }: LeadStatusIconProps) => {
  const color = {
    neutral: theme.colors.hostfullyNeutral,
    success: theme.colors.bgGreen,
    warning: theme.colors.hostfullyRed,
  };

  const icon = {
    dollar: <DollarLeadIcon statusColor={color[statusColor]} />,
    rental: <RentalLeadIcon statusColor={color[statusColor]} />,
    tick: <TickLeadIcon statusColor={color[statusColor]} />,
  };

  return <Container data-testid={id}>{icon[type]}</Container>;
};

export default LeadStatusIcon;
