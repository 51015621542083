import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useGetCurrencySymbol = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return useCallback(
    (currency: string) => {
      return (
        currency &&
        new Intl.NumberFormat(language, {
          style: 'currency',
          currency,
        })
          .formatToParts(0)
          .find(({ type }) => type === 'currency')?.value
      );
    },
    [language],
  );
};

export default useGetCurrencySymbol;
