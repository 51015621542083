import { BaseResponse } from './_base';

export enum TemplateTarget {
  GUEST = 'GUEST',
  AGENT = 'AGENT',
  AGENCY = 'AGENCY',
}

export enum TemplateType {
  SYSTEM_TEMPLATE = 'SYSTEM_TEMPLATE',
  SYSTEM_AUTOMATIC_TEMPLATE = 'SYSTEM_AUTOMATIC_TEMPLATE',
  CUSTOM_TEMPLATE = 'CUSTOM_TEMPLATE',
}

export enum TemplateEntityType {
  LEAD = 'LEAD',
  JOB = 'JOB',
}

export interface GetAvailableTemplatesResponseTO extends BaseResponse {
  'available-templates': AvailableTemplate[];
}
export interface GetTemplatesResponseTO extends BaseResponse {
  templates: Template[];
}

export interface AvailableTemplate {
  name: string;
  agencyUid?: string;
  localizedName?: string;
  target?: TemplateTarget;
  templateType?: TemplateType;
  uid?: string;
}

export interface Template {
  contents: TemplateContent[];
  agencyUid?: string;
  name?: string;
  templateType?: TemplateType;
  uid?: string;
}

export interface TemplateContent {
  content: string;
  locale: string;
  title: string;
}
