import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import { Transaction } from 'models/Transactions';
import useOrderViewFetchedData from '../../useOrderViewFetchedData';
import useIssueRefundModal from './useIssueRefundModal';

const IssueRefundButton = ({ transaction }: { transaction: Transaction }) => {
  const { openIssueRefundModal } = useIssueRefundModal();
  const { lead } = useOrderViewFetchedData();
  const { t } = useTranslation();

  const onClick = () => {
    openIssueRefundModal({ lead, transaction });
  };

  return (
    <Button bsStyle="link" data-testid="issue-refund-button" onClick={onClick}>
      {t('pageOrder.transactions.actions.issueRefund')}
    </Button>
  );
};

export default IssueRefundButton;
