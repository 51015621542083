import { useSearchParams } from 'react-router-dom';

export const URLParamsName = {
  LEAD_UID: 'leadUid',
  PROPERTY_UID: 'propertyUid',
};

const useSearchURLParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setParameter = (
    paramsName: (typeof URLParamsName)[keyof typeof URLParamsName],
    value: string,
  ) => {
    searchParams.set(paramsName, value);
    setSearchParams(searchParams);
  };

  const deleteParameter = (
    paramsName: (typeof URLParamsName)[keyof typeof URLParamsName],
  ) => {
    searchParams.delete(paramsName);
    setSearchParams(searchParams);
  };

  const getParameter = (
    paramsName: (typeof URLParamsName)[keyof typeof URLParamsName],
  ) => {
    return searchParams.get(paramsName);
  };

  return {
    setParameter,
    deleteParameter,
    getParameter,
  };
};

export default useSearchURLParams;
