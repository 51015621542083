import { useCallback } from 'react';

const useLocalStorage = <T>(localStorageKey: string, defaultValue?: T) => {
  const getValue = useCallback((): T => {
    return JSON.parse(localStorage.getItem(localStorageKey));
  }, [localStorageKey]);

  const setValue = useCallback(
    (value: T | null): void => {
      localStorage.setItem(localStorageKey, JSON.stringify(value));
    },
    [localStorageKey],
  );

  const clearLocalStorage = useCallback(() => {
    localStorage.removeItem(localStorageKey);
  }, [localStorageKey]);

  const resetLocalStorageToDefault = useCallback(() => {
    clearLocalStorage();

    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [clearLocalStorage, setValue, defaultValue]);

  return {
    getValue,
    setValue,
    clearLocalStorage,
    resetLocalStorageToDefault,
  };
};

export default useLocalStorage;
