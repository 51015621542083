const YellowPaintSvg = () => {
  return (
    <svg
      width="179"
      height="169"
      viewBox="0 0 179 169"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.27673 37.56C2.11561 47.5066 -0.443862 59.2907 1.02554 70.9461C1.79667 77.6247 3.38605 84.1873 5.75782 90.4853C6.53548 92.6818 7.35208 94.8063 8.20631 96.8952C10.7693 103.318 13.7551 109.564 17.1441 115.594C21.4975 123.37 26.6771 130.655 32.5918 137.32C40.4734 146.205 49.9281 153.55 60.4722 158.98C71.3255 164.568 83.2743 167.658 95.4426 168.023C107.611 168.388 119.69 166.019 130.795 161.09C144.602 155.076 156.518 145.438 165.293 133.188C175.844 117.98 180.439 99.3851 178.205 80.9306C175.287 60.1994 165.243 41.0983 149.808 26.9281C143.15 20.7692 135.687 15.5478 127.629 11.4095C113.216 3.98129 97.2494 0.134596 81.0828 0.19529C70.0355 0.304615 59.1059 2.3683 48.7968 6.29108C38.6193 10.0099 29.2012 15.5337 20.9903 22.6C16.0195 26.9101 11.7329 31.9539 8.27673 37.56Z"
        fill="#FFC454"
      />
    </svg>
  );
};

export default YellowPaintSvg;
