import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import TagsSelectField from 'components/forms/domain/tag/TagsSelectField';
import { TagType } from 'models/Tags';
import {
  PropertyTagsModalContainer,
  PropertyTagsModalTitle,
} from './PropertyTags.styles';

const PropertyTagsModal = ({
  successCallback,
  params,
  onClose,
  name,
}: {
  successCallback: () => void;
  params: {
    entityUid: string;
    tagType: TagType;
  };
  onClose: () => void;
  name: string;
}) => {
  const { t } = useTranslation();

  return (
    <PropertyTagsModalContainer show onHide={onClose}>
      <PropertyTagsModalContainer.Header closeButton>
        <PropertyTagsModalContainer.Title>
          {t('pageProperties.body.allTags')}
        </PropertyTagsModalContainer.Title>
      </PropertyTagsModalContainer.Header>
      <PropertyTagsModalContainer.Body>
        <PropertyTagsModalTitle>
          {t('pageProperties.body.listOfTagsFor')} <span>{name}</span>
        </PropertyTagsModalTitle>

        <TagsSelectField
          name="tags"
          options={[]}
          className="tags-selector"
          saveOnChange
          saveOnChangeParams={params}
          successCallback={successCallback}
        />
      </PropertyTagsModalContainer.Body>
      <PropertyTagsModalContainer.Footer>
        <Button onClick={onClose}>{t('pageProperties.body.cancel')}</Button>
        <Button bsStyle="primary" onClick={onClose}>
          {t('pageProperties.body.done')}
        </Button>
      </PropertyTagsModalContainer.Footer>
    </PropertyTagsModalContainer>
  );
};

export default PropertyTagsModal;
