import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ThreadRelatedPropertyResponse } from 'models/inbox/Threads';
import {
  LeadBody,
  LeadContainer,
  LeadDates,
  LeadDetailChannelValue,
  LeadDetailLabel,
  LeadDetailValue,
  LeadHeader,
  LeadHeaderFirstRow,
  LeadHeaderStatusContainer,
  LeadPropertyName,
} from '../lead/InboxLead.styles';
import Button from '../../../components/button/Button';
import {
  Lead,
  LeadChannelNameMap,
  LeadCircleColor,
} from '../../../models/Leads';
import LeadChannelIcon from '../../../components/domain/lead/LeadChannelIcon';
import LeadCircle from '../../../components/domain/lead/status/LeadCircle';

export const ThreadRelatedPropertyBody = ({
  model,
}: {
  model: ThreadRelatedPropertyResponse;
}) => {
  const navigate = useNavigate();
  const { objectUid, propertyName } = model || {};
  const channelName = LeadChannelNameMap.get(model.channel);
  const { t } = useTranslation();
  const inquiryLeadModel = {
    status: 'NEW',
    source: 'DIRECT_HOMEAWAY',
    countdown: {
      color: LeadCircleColor.ORANGE,
      countdownPercentage: 1,
      visualCountdownValue: '204d',
    },
  } as Lead;
  const onViewClicked = () => {
    navigate(`/calendar?uid=${objectUid}`);
  };

  return (
    <LeadContainer data-testid={`active-property-${objectUid}`}>
      <LeadHeader>
        <LeadHeaderFirstRow>
          <div>
            <LeadPropertyName>{propertyName}</LeadPropertyName>
            <LeadDates />
          </div>
          <div>
            <Button data-testid="property-view-button" onClick={onViewClicked}>
              {t('common.view')}
            </Button>
          </div>
        </LeadHeaderFirstRow>
        <LeadHeaderStatusContainer>
          <LeadCircle lead={inquiryLeadModel} />
        </LeadHeaderStatusContainer>
      </LeadHeader>
      <LeadBody>
        <Row>
          <Col xs={6}>
            <LeadDetailLabel $id="assignee">
              {t('componentLead.details.assignee')}
            </LeadDetailLabel>
            <LeadDetailValue $id="assignee">
              {undefined || t('componentLead.details.unassigned')}
            </LeadDetailValue>
          </Col>
          <Col xs={6}>
            <LeadDetailLabel $id="channel">
              {t('componentLead.details.channel')}
            </LeadDetailLabel>
            <LeadDetailChannelValue>
              <LeadChannelIcon channel={model.channel} />
              <span>{channelName}</span>
            </LeadDetailChannelValue>
          </Col>
        </Row>
      </LeadBody>
    </LeadContainer>
  );
};
