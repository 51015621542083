import { countryFlags } from './flags';

const languagesListObject = {
  om: 'Afaan Oromoo',
  aa: 'Afaraf',
  af: 'Afrikaans',
  ak: 'Akan',
  an: 'aragonés',
  ig: 'Asụsụ Igbo',
  gn: "Avañe'ẽ",
  ae: 'avesta',
  ay: 'aymar aru',
  az: 'azərbaycan dili',
  id: 'Bahasa Indonesia',
  ms: 'bahasa Melayu',
  bm: 'bamanankan',
  jv: 'basa Jawa',
  su: 'Basa Sunda',
  bi: 'Bislama',
  bs: 'bosanski jezik',
  br: 'brezhoneg',
  ca: 'català',
  ch: 'Chamoru',
  ny: 'chiCheŵa',
  sn: 'chiShona',
  co: 'corsu',
  cy: 'Cymraeg',
  da: 'dansk',
  se: 'Davvisámegiella',
  de: 'Deutsch',
  nv: 'Diné bizaad',
  et: 'eesti',
  na: 'Ekakairũ Naoero',
  en: 'English',
  es: 'español',
  eo: 'Esperanto',
  eu: 'euskara',
  ee: 'Eʋegbe',
  to: 'faka Tonga',
  mg: 'fiteny malagasy',
  fr: 'French',
  fy: 'Frysk',
  ff: 'Fulfulde',
  fo: 'føroyskt',
  ga: 'Gaeilge',
  gv: 'Gaelg',
  sm: "gagana fa'a Samoa",
  gl: 'galego',
  sq: 'gjuha shqipe',
  gd: 'Gàidhlig',
  ki: 'Gĩkũyũ',
  ha: 'Hausa',
  ho: 'Hiri Motu',
  hr: 'hrvatski jezik',
  io: 'Ido',
  rw: 'Ikinyarwanda',
  rn: 'Ikirundi',
  ia: 'Interlingua',
  nd: 'isiNdebele',
  nr: 'isiNdebele',
  xh: 'isiXhosa',
  zu: 'isiZulu',
  it: 'italiano',
  ik: 'Iñupiaq',
  pl: 'polski',
  mh: 'Kajin M̧ajeļ',
  kl: 'kalaallisut',
  kr: 'Kanuri',
  kw: 'Kernewek',
  kg: 'KiKongo',
  sw: 'Kiswahili',
  ht: 'Kreyòl ayisyen',
  kj: 'Kuanyama',
  ku: 'Kurdî',
  la: 'latine',
  lv: 'latviešu valoda',
  lt: 'lietuvių kalba',
  ro: 'limba română',
  li: 'Limburgs',
  ln: 'Lingála',
  lg: 'Luganda',
  lb: 'Lëtzebuergesch',
  hu: 'magyar',
  mt: 'Malti',
  nl: 'Nederlands',
  no: 'Norsk',
  nb: 'Norsk bokmål',
  nn: 'Norsk nynorsk',
  uz: "O'zbek",
  oc: 'occitan',
  ie: 'Interlingue',
  hz: 'Otjiherero',
  ng: 'Owambo',
  pt: 'português',
  ty: 'Reo Tahiti',
  rm: 'rumantsch grischun',
  qu: 'Runa Simi',
  sc: 'sardu',
  za: 'Saɯ cueŋƅ',
  st: 'Sesotho',
  tn: 'Setswana',
  ss: 'SiSwati',
  sl: 'slovenski jezik',
  sk: 'slovenčina',
  so: 'Soomaaliga',
  fi: 'suomi',
  sv: 'Svenska',
  mi: 'te reo Māori',
  vi: 'Tiếng Việt',
  lu: 'Tshiluba',
  ve: 'Tshivenḓa',
  tw: 'Twi',
  tk: 'Türkmen',
  tr: 'Türkçe',
  ug: 'Uyƣurqə',
  vo: 'Volapük',
  fj: 'vosa Vakaviti',
  wa: 'walon',
  tl: 'Wikang Tagalog',
  wo: 'Wollof',
  ts: 'Xitsonga',
  yo: 'Yorùbá',
  sg: 'yângâ tî sängö',
  is: 'Íslenska',
  cs: 'čeština',
  el: 'ελληνικά',
  av: 'авар мацӀ',
  ab: 'аҧсуа бызшәа',
  ba: 'башҡорт теле',
  be: 'беларуская мова',
  bg: 'български език',
  os: 'ирон æвзаг',
  kv: 'коми кыв',
  ky: 'Кыргызча',
  mk: 'македонски јазик',
  mn: 'монгол',
  ce: 'нохчийн мотт',
  ru: 'русский язык',
  sr: 'српски језик',
  tt: 'татар теле',
  tg: 'тоҷикӣ',
  uk: 'українська мова',
  cv: 'чӑваш чӗлхи',
  cu: 'ѩзыкъ словѣньскъ',
  kk: 'қазақ тілі',
  hy: 'Հայերեն',
  yi: 'ייִדיש',
  he: 'עברית',
  ur: 'اردو',
  ar: 'العربية',
  fa: 'فارسی',
  ps: 'پښتو',
  ks: 'कश्मीरी',
  ne: 'नेपाली',
  pi: 'पाऴि',
  bh: 'भोजपुरी',
  mr: 'मराठी',
  sa: 'संस्कृतम्',
  sd: 'सिन्धी',
  hi: 'हिन्दी',
  as: 'অসমীয়া',
  bn: 'বাংলা',
  pa: 'ਪੰਜਾਬੀ',
  gu: 'ગુજરાતી',
  or: 'ଓଡ଼ିଆ',
  ta: 'தமிழ்',
  te: 'తెలుగు',
  kn: 'ಕನ್ನಡ',
  ml: 'മലയാളം',
  si: 'සිංහල',
  th: 'ไทย',
  lo: 'ພາສາລາວ',
  bo: 'བོད་ཡིག',
  dz: 'རྫོང་ཁ',
  my: 'ဗမာစာ',
  ka: 'ქართული',
  ti: 'ትግርኛ',
  am: 'አማርኛ',
  iu: 'ᐃᓄᒃᑎᑐᑦ',
  oj: 'ᐊᓂᔑᓈᐯᒧᐎᓐ',
  cr: 'ᓀᐦᐃᔭᐍᐏᐣ',
  km: 'ខ្មែរ',
  zh: 'Chinese',
  ja: 'Japanese',
  ii: 'ꆈꌠ꒿ Nuosuhxop',
  ko: 'Korean',
};

const i18nLanguagesListObject = {
  de_DE: 'Deutsch',
  en_US: 'English',
  es_ES: 'Español',
  fr_FR: 'French',
  it_IT: 'Italiano',
  nl_NL: 'Nederlands',
  no_NO: 'Norsk',
  pl_PL: 'Polski',
  pt_PT: 'Português',
  ko_KR: 'Korean',
  ja_JP: 'Japanese',
  tr_TR: 'Turkish',
  vi_VN: 'Vietnamese',
};

const i18nLanguageToLocaleCode = {
  de: 'de_DE',
  en: 'en_US',
  es: 'es_ES',
  fr: 'fr_FR',
  it: 'it_IT',
  nl: 'nl_NL',
  no: 'no_NO',
  pl: 'pl_PL',
  pt: 'pt_PT',
  tr: 'tr_TR',
  ja: 'ja_JP',
  ko: 'ko_KR',
  vi: 'vi_VN',
};

interface languagesListArrayType {
  value: string;
  label: string;
}

const i18nLanguagesListArray: languagesListArrayType[] = Object.keys(
  i18nLanguagesListObject,
).map((countryCode) => ({
  value: countryCode,
  label: i18nLanguagesListObject[countryCode],
}));

const languagesListWithFlag: languagesListArrayType[] =
  i18nLanguagesListArray.map(({ label: prevLabel, value }) => {
    const countryCode = value.substring(3, 5);

    return {
      value,
      label: `${countryFlags[countryCode]?.emoji} ${prevLabel}`,
    };
  });

export {
  i18nLanguagesListArray,
  i18nLanguageToLocaleCode,
  languagesListObject,
  languagesListWithFlag,
};
