import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from 'recharts';
import Loader from 'components/loader/Loader';
import { AnalyticsFinancialPerformanceMetricValue } from 'pages/analytics/types/AnalyticsFinancialPerformance.types';
import { getFormatter } from 'utils/analytics/analyticsUtils';
import useFormatCurrency from 'hooks/useFormatCurrency';
import useFormatNumber from 'hooks/useFormatNumber';
import { TimeSpan } from 'utils/dateTimeUtils';
import { ChartFilter, ChartType } from '../../Charts.types';
import LoadingLegendPlaceholder from '../common/LoadingLegendPlaceholder';
import CustomTooltip from '../common/CustomTooltip';
import { chartConstants } from '../../Chart.constants';

interface CustomLineChartProps {
  data: AnalyticsFinancialPerformanceMetricValue[];
  error: any;
  chartFilter: ChartFilter;
  isLoading: boolean;
  dataKey: string;
  xAxisKey: string;
  isInitialLoading: boolean;
  timeSpan: TimeSpan;
}

const CustomLineChart = ({
  chartFilter,
  error,
  isLoading,
  dataKey,
  xAxisKey,
  data,
  isInitialLoading,
  timeSpan,
}: CustomLineChartProps) => {
  const { t } = useTranslation();
  const formatNumber = useFormatNumber();
  const formatCurrency = useFormatCurrency();

  const TooltipContent = useCallback(
    ({ active, payload }) => (
      <CustomTooltip
        chartFilter={chartFilter}
        active={active}
        payload={payload}
        timeSpan={timeSpan}
      />
    ),
    [chartFilter, timeSpan],
  );

  if (isInitialLoading) {
    return <Loader />;
  }

  if (!isLoading && error) {
    return (
      <p className="error-message" data-testid="error-message">
        {t('pageAnalytics.chart.errorLoadingCharts')}
      </p>
    );
  }

  if (!isLoading && !error && data?.length === 0) {
    return (
      <p className="error-message" data-testid="error-message">
        {t('pageAnalytics.chart.noData')}
      </p>
    );
  }

  return (
    <ResponsiveContainer width="100%" height={500}>
      <LineChart data={data} margin={chartConstants.CHART_MARGIN}>
        {isLoading ? (
          <Legend
            verticalAlign="top"
            iconType="circle"
            height={36}
            content={<LoadingLegendPlaceholder />}
          />
        ) : (
          <Legend verticalAlign="top" iconType="circle" height={36} />
        )}
        <Line
          name={t(`pageAnalytics.chart.metric.${chartFilter.metric}`)}
          type="monotone"
          dataKey={dataKey}
          strokeWidth={5}
          stroke={isLoading ? '#f5f5f5' : '#3A84D8'}
        />
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
        <XAxis
          padding={{ left: 15 }}
          angle={-30}
          height={100}
          dx={-15}
          dy={20}
          dataKey={xAxisKey}
          tickFormatter={(value) =>
            getFormatter({
              value,
              axis: 'X',
              chartFilter,
              timeSpan,
            })
          }
        />
        <YAxis
          width={
            chartFilter.type === ChartType.FINANCIAL_PERFORMANCE
              ? 60
              : chartConstants.YAXIS_WITH
          }
          tickFormatter={(value) =>
            getFormatter({
              value,
              currency: data[0]?.currency,
              axis: 'Y',
              formatCurrency,
              formatNumber,
              chartFilter,
            })
          }
        />
        <Tooltip content={TooltipContent} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CustomLineChart;
