import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form } from 'components/forms/form/Form';
import { PropertyByUid } from 'models/Properties';
import { ChoiceTree } from '../airbnb/useAirbnbDeactivationOptions';
import { AlertStyled, PropertyNameStyled } from '../Confirmation.styles';
import AirbnbDeactivationFlow from '../airbnb/AirbnbDeactivationFlow';
import DeactivationModalFooter from './DeactivationModalFooter';

interface DeactivationModalBodyProps {
  choices: ChoiceTree[];
  isActiveOnAirbnb: boolean;
  isDisabled: boolean;
  modalId: string;
  name: string;
  property: PropertyByUid;
  onSubmit: (property: PropertyByUid) => Promise<void>;
}

const DeactivationModalBody = ({
  choices,
  isActiveOnAirbnb,
  isDisabled,
  modalId,
  name,
  property,
  onSubmit,
}: DeactivationModalBodyProps) => {
  const { t } = useTranslation();
  const { reset, setValue } = useFormContext();
  const { choice } = useWatch();
  const [currentSelectedChoice, setCurrentSelectedChoice] =
    useState<ChoiceTree | null>(null);
  const currentFlowChoices = currentSelectedChoice?.choices ?? choices;

  const selectedChoice = currentFlowChoices.find(
    ({ value }) => value === choice,
  );

  const hasNext = !!selectedChoice?.choices?.length;
  const hasBack = !!currentSelectedChoice;

  const handleBack = () => {
    setCurrentSelectedChoice(currentSelectedChoice.prev ?? null);
    setValue('choice', currentSelectedChoice.value);
  };

  const handleSubmit = (formValues: {
    choice: string;
    deactivationReason?: string;
  }) => {
    if (hasNext) {
      setCurrentSelectedChoice(selectedChoice);
      reset();
    } else if (isActiveOnAirbnb) {
      onSubmit({
        ...property,
        airbnbData: {
          ...property.airbnbData,
          deactivationData: {
            reason: formValues.choice,
            details: formValues.deactivationReason,
          },
        },
      });
    } else {
      onSubmit(property);
    }
  };

  return (
    <Form disabled={isDisabled} onSubmit={handleSubmit} noValidate>
      <Modal.Body>
        {t('pageProperties.actions.deactivatePropertyConfirmation')}{' '}
        <PropertyNameStyled>{name}</PropertyNameStyled>?
        <AlertStyled bsStyle="danger">
          {t('pageProperties.actions.deactivatePropertyAlert')}
        </AlertStyled>
        {isActiveOnAirbnb && (
          <AirbnbDeactivationFlow flowChoices={currentFlowChoices} />
        )}
      </Modal.Body>

      <Modal.Footer>
        <DeactivationModalFooter
          hasBack={hasBack}
          hasNext={hasNext}
          isUpdating={isDisabled}
          modalId={modalId}
          onBack={handleBack}
        />
      </Modal.Footer>
    </Form>
  );
};

export default DeactivationModalBody;
