import { useEffect, useState } from 'react';
import { Checkbox } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MOVE_BOOK_WARNING_DISMISSED } from './Calendar.constants';

const WarningDragAndDropModalBody = () => {
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    window.localStorage.setItem(
      MOVE_BOOK_WARNING_DISMISSED,
      dontShowAgain.toString(),
    );
  }, [dontShowAgain]);

  return (
    <div>
      <p>{t('pageCalendar.dragAndDrop.warningMessage')}</p>
      <Checkbox
        checked={dontShowAgain}
        onChange={() => setDontShowAgain(!dontShowAgain)}
      >
        {t('pageCalendar.dragAndDrop.notShowAgain')}
      </Checkbox>
    </div>
  );
};

export default WarningDragAndDropModalBody;
