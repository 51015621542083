import useAppModal from 'hooks/useAppModal';
import useFetchPricingPlan from '../../useFetchPricingPlan';
import UpgradePlanModal from './UpgradePlanModal';
import { upgradePlanModalId } from './UpgradePlanModal.constants';

const useUpgradePlanModal = () => {
  const { data: pricingPlan } = useFetchPricingPlan();
  const { openModal } = useAppModal();

  const openUpgradePlanModal = () => {
    openModal({
      customContent: <UpgradePlanModal />,
      id: upgradePlanModalId,
      size: 'medium',
    });
  };

  const canAddProperties = (amountToAdd: number) => {
    const { currentPlan } = pricingPlan;
    const nextPlan = pricingPlan.tierUpgradeOffer;

    if (!nextPlan) {
      return true;
    }

    const difference = currentPlan.propertiesLeftBeforeUpgrade - amountToAdd;

    return difference >= 0;
  };

  return {
    canAddProperties,
    openUpgradePlanModal,
  };
};

export default useUpgradePlanModal;
