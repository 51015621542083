import FormWithProvider from 'components/forms/form/Form';
import usePropertiesUpdatingAllowed from 'components/domain/property/usePropertiesUpdatingAllowed';
import { AmenityCategory } from 'models/Amenity';
import PropertyEditPermissionInfo from '../../../common/PropertyEditPermissionInfo';
import useAmenitiesDataMap from '../../useAmenitiesDataMap';
import useFetchAvailableAmenities from '../../useFetchAvailableAmenities';
import usePropertyAmenities from '../../usePropertyAmenities';
import CategoryCollapseSection from './CategoryCollapseSection';
import ChannelFilter from './channelFilter/ChannelFilter';
import useEnabledChannels from './channelFilter/useEnabledChannels';
import FormFooterActions from './FormFooterActions';
import useCategorizedAmenitiesFormSubmit from './useCategorizedAmenitiesFormSubmit';
import CategorizedAmenitiesLoadingPlaceholder from './CategorizedAmenitiesLoadingPlaceholder';
import PopularAmenitiesSection from './popularAmenitiesSection/PopularAmenitiesSection';
import {
  categorizedAmenitiesSchema,
  getFormDefaultValues,
} from './CategorizedAmenities.schema';
import CategorizedAmenitiesEventListeners from './CategorizedAmenitiesEventListeners';

const CategorizedAmenities = () => {
  const {
    channels,
    activeChannels,
    toggleChannel,
    isFetching: isFetchingEnabledChannels,
  } = useEnabledChannels();
  const { availableAmenities, isLoading: isLoadingAvailableAmenities } =
    useFetchAvailableAmenities({
      activeChannels,
    });
  const { propertyAmenities, isLoading: isLoadingPropertyAmenities } =
    usePropertyAmenities(availableAmenities?.length > 0);
  const { categorizedAmenities, popularAmenities } =
    useAmenitiesDataMap(availableAmenities);
  const { isUpdating, onSubmit } = useCategorizedAmenitiesFormSubmit();
  const propertiesUpdatingAllowed = usePropertiesUpdatingAllowed();

  const { INDOOR, OUTDOOR, FAMILY, EXTRAS } = categorizedAmenities || {};

  const isLoading =
    isFetchingEnabledChannels ||
    isLoadingAvailableAmenities ||
    isLoadingPropertyAmenities;

  if (isLoading) {
    return <CategorizedAmenitiesLoadingPlaceholder />;
  }

  const defaultValues = getFormDefaultValues(Object.values(propertyAmenities));

  return (
    <FormWithProvider
      data-testid="categorized-amenities-form"
      defaultValues={defaultValues}
      disabled={!propertiesUpdatingAllowed}
      onSubmit={onSubmit}
      resolver={categorizedAmenitiesSchema()}
      noValidate
    >
      <fieldset disabled={isUpdating || !propertiesUpdatingAllowed}>
        <CategorizedAmenitiesEventListeners />

        <PropertyEditPermissionInfo />

        <ChannelFilter channels={channels} toggleChannel={toggleChannel} />

        <PopularAmenitiesSection popularAmenities={popularAmenities} />

        <CategoryCollapseSection
          category={INDOOR}
          type={AmenityCategory.INDOOR}
        />

        <CategoryCollapseSection
          category={OUTDOOR}
          type={AmenityCategory.OUTDOOR}
        />

        <CategoryCollapseSection
          category={FAMILY}
          type={AmenityCategory.FAMILY}
        />

        <CategoryCollapseSection
          category={EXTRAS}
          type={AmenityCategory.EXTRAS}
        />
        <FormFooterActions />
      </fieldset>
    </FormWithProvider>
  );
};
export default CategorizedAmenities;
