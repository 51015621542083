import * as Yup from 'yup';

const loginSchema = (validatesOnlyPinCode: boolean) => {
  return validatesOnlyPinCode
    ? {
        pinCode: Yup.string().length(6).required(),
      }
    : {
        email: Yup.string().email().required(),
        password: Yup.string().required(),
        rememberMe: Yup.boolean(),
      };
};

export default loginSchema;
