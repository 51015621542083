import Loader from '../../loader/Loader';
import useFetchEmailProperties from './useFetchEmailProperties';
import EmailModal from './EmailModal';

const EmailPropertiesListModal = ({
  fetchEmailPropertiesParams: { availableFrom, availableTo, propertiesQuery },
  modalId,
}: {
  fetchEmailPropertiesParams: {
    availableFrom: Date | null;
    availableTo: Date | null;
    propertiesQuery: string;
  };
  modalId: string;
}) => {
  const { emailSuggester, sendPropertiesEmail } = useFetchEmailProperties(
    propertiesQuery,
    availableFrom,
    availableTo,
    modalId,
  );

  if (!emailSuggester) {
    return <Loader />;
  }

  return (
    <EmailModal
      modalId={modalId}
      emailSuggester={emailSuggester}
      sendPropertiesEmail={sendPropertiesEmail}
    />
  );
};

export default EmailPropertiesListModal;
