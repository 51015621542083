import useAppUser from '../../../hooks/useAppUser';
import useAppQuery from '../../../hooks/useAppQuery';
import { GetEntityTagsResponseTO, Tag, TagType } from '../../../models/Tags';
import API from '../../../services/API';

const useFetchEntityTags = (
  entityUid: string,
  tagType: TagType,
  enabled: boolean,
) => {
  const { isEmployee } = useAppUser();

  const { data: tags, isInitialLoading: isFetching } = useAppQuery(
    [`entity-tags-${entityUid}-${tagType}`, entityUid, tagType],
    async (): Promise<Tag[]> => {
      const response = await API.get<GetEntityTagsResponseTO>(
        `/v3/tags?objectUid=${entityUid}&tagType=${tagType}`,
      );
      return response.data.tags;
    },
    {
      enabled: enabled && !!isEmployee && !!entityUid,
    },
  );

  return { isFetching, tags };
};

export default useFetchEntityTags;
