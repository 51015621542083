import { useTranslation } from 'react-i18next';
import { isDate } from 'date-fns';
import useFormatCurrency from 'hooks/useFormatCurrency';
import { AmenityType } from 'models/Amenity';
import { ALL_WEEK_DAYS, DayOfWeek } from 'models/DayOfWeek';
import { countriesListArray } from 'utils/constants/countries';
import { usStateOptions } from 'utils/constants/states';
import { normalizeKey } from 'utils/localeUtils';
import { PropertySettingsTab } from '../PropertySettingsTabs.constants';
import { CHECK_IN_TIME_END_FLEXIBLE } from '../mainSettings/sections/airbnbSettings/AirbnbSettings.constants';
import {
  PropertySettingsBulkSavableFormValues,
  PropertySettingsBulkSaveModalParams,
} from './PropertySettingsBulkSaveModal.types';

const tabNameToLocaleGroup = {
  [PropertySettingsTab.mainSettings]: 'mainSettings',
  [PropertySettingsTab.pricing]: 'pricing',
};

const fieldLabelFactories = {
  [PropertySettingsTab.amenities]: {
    amenities: Object.values(AmenityType).reduce(
      (acc, amenity) => ({
        ...acc,
        [amenity]: ({ fieldName, t }) => t(`amenities.types.${fieldName}`),
      }),
      {},
    ),
  },
  [PropertySettingsTab.mainSettings]: {
    airbnbSettings: {
      allowBookingRequestAboveMaximumStay: ({ t }) =>
        t('pageProperty.mainSettings.airbnbSettings.allowBookingRequest'),
      allowBookingRequestWithinBookingLeadTime: ({ t }) =>
        t('pageProperty.mainSettings.airbnbSettings.allowBookingRequest'),
      bookingType: ({ t }) =>
        t('pageProperty.bulkSave.airbnbSettings.bookingType'),
      cancellationPolicy: ({ t }) =>
        t('pageProperty.bulkSave.airbnbSettings.cancellationPolicy'),
      checkInTimeStartFlexible: ({ t }) =>
        t('pageProperty.mainSettings.airbnbSettings.checkInTimeStart'),
      nonRefundableRateEnabled: ({ t }) =>
        t('pageProperty.mainSettings.airbnbSettings.nonRefundableRate'),
    },
    bookingDotComSettings: {
      cancellationPolicyCode: ({ t }) =>
        t('pageProperty.bulkSave.bookingDotComSettings.cancellationPolicyCode'),
      primaryContactUid: ({ t }) =>
        t('pageProperty.mainSettings.bookingDotComSettings.primaryContact'),
    },
    capacityDetails: {
      rooms: ({ t }) =>
        t(
          'pageProperty.mainSettings.capacityDetails.setRoomsAndBedTypesModal.roomsAndBedTypes',
        ),
    },
    propertyDetails: {
      internalThumbnailUrl: ({ t }) =>
        t('pageProperty.mainSettings.propertyDetails.internalThumbnail'),
      propertySizeUnit: ({ t }) =>
        t('pageProperty.mainSettings.propertyDetails.propertySize'),
    },
    vrboSettings: {
      bookingType: ({ t }) =>
        t('pageProperty.bulkSave.vrboSettings.bookingType'),
      cancellationPolicy: ({ t }) =>
        t('pageProperty.bulkSave.vrboSettings.cancellationPolicy'),
      showPropertyExactLocation: ({ t }) =>
        t('pageProperty.mainSettings.vrboSettings.showPropertyLocation.label'),
    },
  },
};

const airbnbAllowBookingRequestValueFactory = ({ allFieldValues, t }) =>
  [
    allFieldValues.airbnbSettings.allowBookingRequestAboveMaximumStay &&
      t('pageProperty.mainSettings.airbnbSettings.aboveMaximumStay'),
    allFieldValues.airbnbSettings.allowBookingRequestWithinBookingLeadTime &&
      t('pageProperty.mainSettings.airbnbSettings.withinBookingLeadTime'),
  ]
    .filter(Boolean)
    .join(', ');

const propertySizeUnitValueFactory = ({ fieldValue, t }) =>
  t(`pageProperty.mainSettings.areaUnitType.${fieldValue}`);

const fieldValueFactories = {
  [PropertySettingsTab.amenities]: {
    amenities: Object.values(AmenityType).reduce(
      (acc, amenity) => ({
        ...acc,
        [amenity]: ({ fieldValue: { description, value }, t }) =>
          `${t(`common.${value ? 'on' : 'off'}`)}${
            description ? ` (${description})` : ''
          }`,
      }),
      {},
    ),
  },
  [PropertySettingsTab.mainSettings]: {
    airbnbSettings: {
      allowBookingRequestAboveMaximumStay:
        airbnbAllowBookingRequestValueFactory,
      allowBookingRequestWithinBookingLeadTime:
        airbnbAllowBookingRequestValueFactory,
      bookingType: ({ fieldValue, t }) => t(`common.bookingType.${fieldValue}`),
      cancellationPolicy: ({ fieldValue, t }) =>
        t(
          `pageProperty.mainSettings.airbnbSettings.cancellationPolicyOptions.${fieldValue}`,
        ),
      checkInOption: ({ fieldValue, t }) =>
        t(
          `pageProperty.mainSettings.airbnbSettings.checkInOptionOptions.${fieldValue}`,
        ),
      checkInTimeEnd: ({ fieldValue, t }) =>
        fieldValue === CHECK_IN_TIME_END_FLEXIBLE
          ? t('pageProperty.mainSettings.airbnbSettings.checkInTimeEndFlexible')
          : fieldValue,
      checkInTimeStartFlexible: ({ fieldValue, t }) =>
        t(
          `pageProperty.mainSettings.airbnbSettings.checkInTimeStartOptions.${fieldValue}`,
        ),
      guestRequirements: ({ fieldValue, t }) =>
        t(
          `pageProperty.mainSettings.airbnbSettings.guestRequirementsOptions.${fieldValue}`,
        ),
      listingType: ({ fieldValue, t }) =>
        t(
          `pageProperty.mainSettings.airbnbSettings.listingTypeOptions.${fieldValue}`,
        ),
      nonRefundableRateDiscount: ({ fieldValue }) => `${Number(fieldValue)}%`,
      nonRefundableRateEnabled: ({ fieldValue, t }) =>
        fieldValue ? t('common.enabled') : t('common.disabled'),
    },
    bookingDotComSettings: {
      cancellationPolicyCode: ({
        bookingDotComCancellationPolicies,
        fieldValue,
        t,
      }) =>
        fieldValue
          ? bookingDotComCancellationPolicies.find(
              ({ code }) => code === Number(fieldValue),
            )?.name || ''
          : t('common.unset'),
      primaryContactUid: ({ employees, fieldValue, t }) =>
        fieldValue
          ? employees?.find(({ uid }) => uid === fieldValue)?.bestProfile
          : t('common.unset'),
      roomType: ({ fieldValue, t }) =>
        t(
          `pageProperty.mainSettings.bookingDotComSettings.roomTypeOptions.${fieldValue}`,
        ),
    },
    bookingSettings: {
      bookingLeadTime: ({ fieldValue, t }) =>
        t('pageProperty.mainSettings.bookingSettings.hourOption', {
          count: fieldValue,
        }),
      bookingType: ({ fieldValue, t }) =>
        t(`pageProperty.mainSettings.bookingTypeOptions.${fieldValue}`),
      bookingWindow: ({ fieldValue, t }) =>
        t(
          `pageProperty.mainSettings.bookingSettings.bookingWindowOptions.${fieldValue}`,
        ),
      checkInTime: ({ fieldValue, t }) =>
        t('pageProperty.mainSettings.bookingSettings.hourOption', {
          count: fieldValue,
        }),
      checkoutTime: ({ fieldValue, t }) =>
        t('pageProperty.mainSettings.bookingSettings.hourOption', {
          count: fieldValue,
        }),
      dayOfWeekMinimumStay: ({ fieldValue, t }) =>
        Object.entries(fieldValue)
          .toSorted(
            ([day1], [day2]) =>
              ALL_WEEK_DAYS.indexOf(day1 as DayOfWeek) -
              ALL_WEEK_DAYS.indexOf(day2 as DayOfWeek),
          )
          .map(([day, value]) => `${t(`common.dayOfWeek.${day}`)}: ${value}`)
          .join(', '),
      daysOfTheWeekToCheckInOn: ({ fieldValue, t }) => {
        const checkedDays = Object.entries(fieldValue)
          .filter(([_, isChecked]) => isChecked)
          .map(([day]) => t(`common.dayOfWeek.${day}`));
        const checkedCount = checkedDays.length;

        if (checkedCount === ALL_WEEK_DAYS.length) {
          return t(
            'pageProperty.mainSettings.bookingSettings.onlyCheckInOnNoRestrictionCount',
            { count: checkedCount },
          );
        }

        if (checkedCount) {
          return checkedDays.join(', ');
        }

        return t(
          'pageProperty.mainSettings.bookingSettings.onlyCheckInOnNoRestriction',
        );
      },
      fullPaymentTiming: ({ fieldValue, t }) =>
        t('common.daysOptions.day', {
          count: Number(fieldValue),
        }),
      maximumStay: ({ fieldValue, t }) =>
        t('common.nightsOptions.night', { count: Number(fieldValue) }),
      minimumStay: ({ fieldValue, t }) =>
        t('common.nightsOptions.night', { count: Number(fieldValue) }),
      minimumWeekendStay: ({ fieldValue, t }) =>
        t('common.nightsOptions.night', { count: Number(fieldValue) }),
      turnoverDays: ({ fieldValue, t }) =>
        t('common.daysOptions.day', {
          count: Number(fieldValue),
        }),
    },
    capacityDetails: {
      extraGuestFee: ({ fieldValue, formatCurrency }) =>
        formatCurrency(fieldValue),
      rooms: ({ fieldValue, t }) => {
        const roomsCount = fieldValue.length;
        const bedsCount = fieldValue.reduce(
          (acc: number, { beds }) =>
            acc +
            beds.reduce((roomBeds: number, { count }) => roomBeds + count, 0),
          0,
        );

        return `${t(
          'pageProperty.bulkSave.capacityDetails.roomsAndBedTypes.room',
          { count: roomsCount },
        )}, ${t(
          t('pageProperty.bulkSave.capacityDetails.roomsAndBedTypes.bed', {
            count: bedsCount,
          }),
        )}`;
      },
    },
    miscInfo: {
      rentalLicenseExpirationDate: ({ fieldValue, t }) =>
        isDate(fieldValue) ? t('common.dateMedium', { date: fieldValue }) : '',
    },
    propertyAddress: {
      countryCode: ({ fieldValue }) =>
        countriesListArray.find(({ value }) => value === fieldValue)?.label,
      state: ({ fieldValue }) =>
        usStateOptions.find(({ value }) => value === fieldValue)?.label ??
        fieldValue,
    },
    propertyDetails: {
      internalThumbnailUrl: ({ allFieldValues, t }) =>
        allFieldValues.propertyDetails.internalThumbnail?.[0]
          ? allFieldValues.propertyDetails.internalThumbnail?.[0]?.name ??
            `[${t('common.file').toLowerCase()}]`
          : t('common.unset'),
      propertySize: ({ allFieldValues, fieldValue, t }) =>
        `${fieldValue}${propertySizeUnitValueFactory({
          fieldValue: allFieldValues.propertyDetails.propertySizeUnit,
          t,
        })}`,
      propertySizeUnit: ({ allFieldValues, fieldValue, t }) =>
        `${
          allFieldValues.propertyDetails.propertySize
        }${propertySizeUnitValueFactory({ fieldValue, t })}`,
      propertyType: ({ fieldValue, propertyTypes }) =>
        propertyTypes?.find(({ type }) => type === fieldValue)?.label ||
        fieldValue,
    },
    vrboSettings: {
      bookingType: ({ fieldValue, t }) => t(`common.bookingType.${fieldValue}`),
      cancellationPolicy: ({ fieldValue, t }) =>
        t(
          `pageProperty.mainSettings.vrboSettings.cancellationPolicy.option_${fieldValue}`,
        ),
      showPropertyExactLocation: ({ fieldValue, t }) =>
        t(
          `pageProperty.mainSettings.vrboSettings.showPropertyLocation.option_${fieldValue}`,
        ),
    },
  },
};

function getFieldLabel({ fieldName, sectionName, tabName, t }) {
  const fieldLabelFactory =
    fieldLabelFactories[tabName]?.[sectionName]?.[fieldName];

  return (
    fieldLabelFactory?.({ fieldName, sectionName, t }) ??
    t(
      normalizeKey(
        `pageProperty.${tabNameToLocaleGroup[tabName]}.${sectionName}.${fieldName}`,
      ),
    )
  );
}

function getFieldValue({
  allFieldValues,
  bookingDotComCancellationPolicies,
  employees,
  fieldName,
  fieldValue,
  formatCurrency,
  propertyTypes,
  sectionName,
  tabName,
  t,
}) {
  const fieldValueFactory =
    fieldValueFactories[tabName]?.[sectionName]?.[fieldName];

  return (
    fieldValueFactory?.({
      allFieldValues,
      bookingDotComCancellationPolicies,
      employees,
      fieldValue,
      formatCurrency,
      propertyTypes,
      t,
    }) ??
    (fieldValue || t('common.unset'))
  );
}

const usePropertySettingsBulkSaveModalFieldDescriptions = () => {
  const formatCurrencyFn = useFormatCurrency();
  const { t } = useTranslation();

  return ({
    allFieldValues,
    bookingDotComCancellationPolicies,
    employees,
    fields,
    property,
    propertyTypes,
    tabName,
  }: {
    allFieldValues: PropertySettingsBulkSavableFormValues;
    bookingDotComCancellationPolicies: PropertySettingsBulkSaveModalParams['bookingDotComCancellationPolicies'];
    employees: PropertySettingsBulkSaveModalParams['employees'];
    fields: PropertySettingsBulkSavableFormValues;
    property: PropertySettingsBulkSaveModalParams['property'];
    propertyTypes: PropertySettingsBulkSaveModalParams['propertyTypes'];
    tabName: PropertySettingsBulkSaveModalParams['tabName'];
  }) => {
    const {
      pricing: { currency },
    } = property;
    const formatCurrency = (value: number) =>
      formatCurrencyFn({ currency, value });

    return Object.entries(fields).reduce(
      (acc, [sectionName, sectionFields]) => ({
        ...acc,
        ...Object.entries(sectionFields).reduce(
          (sectionAcc, [fieldName, fieldValue]) => ({
            ...sectionAcc,
            [getFieldLabel({ fieldName, sectionName, tabName, t })]:
              getFieldValue({
                allFieldValues,
                bookingDotComCancellationPolicies,
                employees,
                fieldName,
                fieldValue,
                formatCurrency,
                propertyTypes,
                sectionName,
                tabName,
                t,
              }),
          }),
          {},
        ),
      }),
      {},
    );
  };
};

export default usePropertySettingsBulkSaveModalFieldDescriptions;
