import { AmenityType, PropertyAmenitiesUpdatePayload } from 'models/Amenity';
import {
  CategorizedAmenitiesFormDirtyFields,
  CategorizedAmenitiesFormValues,
} from './CategorizedAmenities.schema';

const useCategorizedAmenitiesUpdatePayload = () => {
  return ({
    dirtyFields,
    formValues: { amenities },
  }: {
    dirtyFields: CategorizedAmenitiesFormDirtyFields;
    formValues: CategorizedAmenitiesFormValues;
  }): PropertyAmenitiesUpdatePayload =>
    Object.keys(dirtyFields.amenities).map((amenityType: AmenityType) => ({
      amenityType,
      description: amenities[amenityType].description,
      state: amenities[amenityType].value ? 'ON' : 'OFF',
    }));
};

export default useCategorizedAmenitiesUpdatePayload;
