import { NavItem } from 'react-bootstrap';
import styled from 'styled-components';

export const Container = styled(NavItem).attrs({
  className: 'profile-avatar',
})`
  .profile-placeholder {
    text-transform: uppercase;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e0e0e0;
    font-size: 24px;
    color: #fff;
    border: 1px solid #ccc;
  }

  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #ccc;
  }
`;
