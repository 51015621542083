import { createAction } from '@reduxjs/toolkit';

export enum UserActionType {
  GET_USER = 'GET_USER',
}

export interface GetUserActionPayload {
  callback: (successful: boolean) => void;
}

export interface GetUserAction {
  type: UserActionType.GET_USER;
  payload: GetUserActionPayload;
}

export const getUserAction = createAction<GetUserActionPayload>(
  UserActionType.GET_USER,
);
