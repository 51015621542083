import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAppDispatch from 'hooks/useAppDispatch';
import { nanoid } from 'nanoid';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Crypto from 'crypto-js';
import { setAuth } from 'store/slices/auth';
import { getUserAction } from 'store/slices/user/sagas/userActions';
import API from 'services/API';
import { Location } from 'utils/windowUtils';
import useAppSelector from '../../hooks/useAppSelector';
import {
  clearRequestedPath,
  clearRequestedUrl,
} from '../../store/slices/login';

const PMP_WEB_CLIENT_ID = 'pmpWeb_LTqCnTD8xis8YTZD7jcAwsFPN24t9dNR';
const SELF_REDIRECT_URI = '/app/login/web';
const DEVICE_ID_KEY = 'pmp-auth-device-id';
const codeVerifier = nanoid(48);

const prepareAuthRequestData = ({
  email,
  password,
  grantType,
  searchParams,
  isMobileApp,
}) => {
  const getCodeChallenge = () => {
    const codeVerifierHashed = Crypto.SHA256(codeVerifier).toString(
      Crypto.enc.Hex,
    );
    return window.btoa(codeVerifierHashed);
  };

  const deviceId = localStorage.getItem(DEVICE_ID_KEY) || uuidv4();
  if (!localStorage.getItem(DEVICE_ID_KEY)) {
    localStorage.setItem(DEVICE_ID_KEY, deviceId);
  }

  return {
    password,
    login: email,
    grantType: searchParams.get('grantType') || grantType,
    scope: searchParams.get('scope') || 'FULL',
    state: searchParams.get('state') || uuidv4(),
    redirectUri: searchParams.get('redirectUri') || SELF_REDIRECT_URI,
    clientId: isMobileApp ? searchParams.get('clientId') : PMP_WEB_CLIENT_ID,
    b64CodeChallenge:
      searchParams.get('b64CodeChallenge') || getCodeChallenge(),
    codeChallengeMethod: searchParams.get('codeChallengeMethod') || 'S256',
    deviceId,
  };
};

const useLoginSubmit = () => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { requestedPath, requestedUrl } = useAppSelector(
    (state) => state.login,
  );
  const { t } = useTranslation();

  const clearErrors = () => {
    setErrorMessage(null);
  };

  useEffect(() => {
    if (searchParams.get('mfaCode')) {
      navigate('/login'); // shouldn't allow retrigger the 2fa flow when user refresh the page
    }
  }, []);

  function setError(message?: string) {
    setErrorMessage(message || t('pageLogin.errorMessage'));
  }

  function authExchangeFlow({
    redirectUri,
    grantType,
    code,
    authRequestData,
    rememberMe,
    email,
    password,
    redirectUriSearchParam,
    isMobileApp,
  }) {
    if (isMobileApp) {
      Location.change(redirectUri); // redirection for mobile app
    } else {
      API.post('auth-exchange', {
        grantType,
        clientId: PMP_WEB_CLIENT_ID,
        code,
        codeVerifier,
        redirectUri: authRequestData.redirectUri,
      })
        .then(({ data: authExchangeData }) => {
          const { accessToken, refreshToken } = authExchangeData || {};

          if (rememberMe) {
            localStorage.setItem('remember_me', 'true');
          } else {
            localStorage.removeItem('remember_me');
          }

          if (accessToken && (refreshToken || !rememberMe)) {
            // TODO verify if grantType should depend on rememberMe
            localStorage.setItem('access_token', accessToken);
            if (refreshToken) {
              localStorage.setItem('refresh_token', refreshToken);
            }

            API.post('web/login', {
              username: email,
              password,
              rememberMe,
            })
              .then(({ data: webLoginData }) => {
                if (
                  webLoginData?.status === 'SUCCESS' ||
                  webLoginData?.status === 'SUCCESS_REDIRECT'
                ) {
                  dispatch(setAuth({ isAuthenticated: true }));
                  dispatch(getUserAction());

                  if (redirectUriSearchParam) {
                    Location.change(decodeURIComponent(redirectUriSearchParam));
                  } else if (webLoginData.redirectPage) {
                    Location.change(webLoginData.redirectPage);
                  } else if (requestedPath) {
                    dispatch(clearRequestedPath());
                    navigate(requestedPath);
                  } else if (requestedUrl) {
                    dispatch(clearRequestedUrl());
                    Location.change(requestedUrl);
                  }
                } else {
                  setError();
                }
              })
              .catch(() => {
                setError();
              });
          } else {
            setError();
          }
        })
        .catch(() => {
          setError();
        });
    }
  }

  function processLogin({ email, password, rememberMe }) {
    const grantType = rememberMe ? 'REFRESH_TOKEN' : 'AUTHORIZATION_CODE';
    const isMobileApp = searchParams.get('clientId')?.startsWith('pmpMobile_');
    const redirectUriSearchParam = searchParams.get('redirectUri');

    const authRequestData = prepareAuthRequestData({
      email,
      password,
      grantType,
      searchParams,
      isMobileApp,
    });

    API.post('auth', authRequestData)
      .then(({ data }) => {
        if (data?.status === 'SUCCESSFUL' || data?.status === 'MFA_CHALLENGE') {
          const { code, redirectUri, mfaCode, mfaCodeSentTo, mfaType } = data;

          if (mfaCode) {
            setSearchParams((params) => {
              params.set('mfaCode', mfaCode);
              params.set('mfaCodeSentTo', mfaCodeSentTo);
              params.set('mfaType', mfaType);
              return params;
            });
            return;
          }

          authExchangeFlow({
            redirectUri,
            grantType,
            code,
            authRequestData,
            rememberMe,
            email,
            password,
            redirectUriSearchParam,
            isMobileApp,
          });
        } else {
          setError();
        }
      })
      .catch(() => {
        setError();
      });
  }

  const handleSubmitLogin = (values: {
    email: string;
    password: string;
    registrationToken: string;
    rememberMe: boolean;
    pinCode: string;
  }) => {
    const { email, password, registrationToken, rememberMe, pinCode } = values;

    if (pinCode) {
      API.post('/mfa-exchange', {
        mfaCode: searchParams.get('mfaCode'),
        mfaChallenge: pinCode,
      })
        .then(({ data }) => {
          const { code, redirectUri } = data;

          const grantType = rememberMe ? 'REFRESH_TOKEN' : 'AUTHORIZATION_CODE';
          const isMobileApp = searchParams
            .get('clientId')
            ?.startsWith('pmpMobile_');
          const redirectUriSearchParam = searchParams.get('redirectUri');

          authExchangeFlow({
            redirectUri,
            grantType,
            code,
            authRequestData: prepareAuthRequestData({
              email,
              password,
              grantType,
              searchParams,
              isMobileApp,
            }),
            rememberMe,
            email,
            password,
            redirectUriSearchParam,
            isMobileApp,
          });
        })
        .catch(() => {
          setError(t('pageLogin.anotherPinCodeExpected'));
        });

      return;
    }

    if (registrationToken) {
      API.post('web-app/register-user', {
        email,
        password,
        registrationToken,
      })
        .then(() => {
          processLogin({ email, password, rememberMe });
        })
        .catch(() => {
          setError(t('pageLogin.errorCreateAccount'));
        });
    } else {
      processLogin({ email, password, rememberMe });
    }
  };

  return { handleSubmitLogin, clearErrors, errorMessage };
};

export default useLoginSubmit;
