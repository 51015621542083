import { useEffect } from 'react';
import useAppEvent from 'hooks/useAppEvent';
import { AppEventType, OpenUpdateLeadModalEventPayload } from 'models/Events';
import useOpenLeadModal from '../modal/useOpenLeadModal';

const useOpenUpdateLeadModalEventHandler = () => {
  const { subscribe, unsubscribe } = useAppEvent();
  const { updateLeadModal } = useOpenLeadModal();

  useEffect(() => {
    const openModalListener = ({
      detail: { params },
    }: CustomEvent<OpenUpdateLeadModalEventPayload>) => {
      updateLeadModal(params);
    };

    subscribe(AppEventType.OPEN_UPDATE_LEAD_MODAL, openModalListener);

    return () => {
      unsubscribe(AppEventType.OPEN_UPDATE_LEAD_MODAL, openModalListener);
    };
  }, []);
};

export default useOpenUpdateLeadModalEventHandler;
