function gtag(..._args: unknown[]) {
  // @ts-expect-error
  window.dataLayer = window.dataLayer || [];
  // @ts-expect-error
  // eslint-disable-next-line prefer-rest-params
  dataLayer.push(arguments);
}

interface CustomPageViewEvent {
  location: string;
  userRole: string;
  subscriptionPan: string;
  propertyCount: number;
  userUid: string;
  agencyUid: string;
  authentication: boolean;
}

const PAGE_TYPE_TO_REGEX_MAP = {
  calendar_stacked: /^\/calendar$/,
  calendar_single: /^\/calendar\?propertyUid=.*/,
  property_list: /^\/properties/,
  property_settings: /^\/property?uid=.*/,
  publishing_tools: /^\/publishing/,
  calendar_tools: /^\/calendar-import/,
  automation: /^\/my-templates/,
  refer_a_friend: /^\/refer/,
  login_pwd: /^\/forgot-password/,
  signup: /^\/signup/,
  order: /^\/order.*/,
} as const;

const locationToPageType = (location: string) => {
  const entries = Object.entries(PAGE_TYPE_TO_REGEX_MAP);

  // eslint-disable-next-line no-restricted-syntax
  for (const [pageType, regex] of entries) {
    if (regex.test(location)) {
      return pageType;
    }
  }

  // if no matches found return location without '/' and swapping '-' to '_'
  // e.g '/third-party-agency?queryParam=123' becomes 'third_party_agency'
  const routeFirstPartRegex = /^\/([^/?]+)/;
  const [, routeFirstPart] = location.match(routeFirstPartRegex);
  return routeFirstPart ? routeFirstPart.replaceAll('-', '_') : location;
};

const GoogleAnalyticsService = {
  gtag,
  publishCustomPageView: (event: CustomPageViewEvent) => {
    gtag('event', 'custom_page_view', {
      hf_page_type: locationToPageType(event.location),
      hf_location: event.location,
      hf_user_role: event.userRole,
      hf_subscription_plan: event.subscriptionPan,
      hf_property_count: event.propertyCount,
      hf_user_uid: event.userUid,
      hf_agency_uid: event.agencyUid,
      hf_authentication: event.authentication ? 'logged_in' : 'logged_out',
    });
  },
};

export default GoogleAnalyticsService;
