import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import {
  FilterFormActions,
  FilterFormClearButton,
  FilterFormSubmitButton,
} from 'components/filters/FilterFormButtons';
import SelectField from 'components/forms/selectField/SelectField';
import useLocalStorage from 'hooks/useLocalStorage';
import PropertySelectField from 'components/forms/domain/property/PropertySelectField';
import { Property } from 'models/Properties';
import { trimAndLimitCharactersNumber } from '../../../utils/stringUtils';
import { LeadChannel, LeadChannelTO } from '../../../models/Leads';
import { ThreadFilterSortOrder } from '../Inbox.types';
import InboxContext from '../InboxContext';
import {
  LeadStatusRelevant,
  ThreadFilterFormLeadStatusesMap,
} from './InboxFilter.constants';
import useInboxFilterFormApplyStoredValues from './useInboxFilterFormApplyStoredValues';
import {
  getFormDefaultValues,
  InboxFilterSchemaType,
} from './InboxFilter.schema';
import { getUpdatedThreadFilter } from './InboxFilter.utils';

const featuredLeadChannels = [
  LeadChannel.HOSTFULLY,
  LeadChannel.AIRBNB,
  LeadChannel.VRBO,
  LeadChannel.BOOKING_COM,
  LeadChannel.HVMI,
  LeadChannel.GOOGLE,
];

export function prepareLeadChannelGroups(
  leadChannels: LeadChannelTO[],
  t: TFunction,
) {
  const featuredChannels = leadChannels
    .filter(({ channel }) => featuredLeadChannels.includes(channel))
    .sort(
      ({ channel: channel1 }, { channel: channel2 }) =>
        featuredLeadChannels.indexOf(channel1) -
        featuredLeadChannels.indexOf(channel2),
    );
  const otherChannels = leadChannels
    .filter(({ channel }) => !featuredLeadChannels.includes(channel))
    .sort(({ bestProfile: bestProfile1 }, { bestProfile: bestProfile2 }) =>
      bestProfile1.localeCompare(bestProfile2),
    );

  return [
    {
      groupLabel: t('form.leadChannelsFeatured'),
      options: featuredChannels.map(({ bestProfile, channel }) => ({
        label: bestProfile,
        value: channel,
      })),
    },
    {
      groupLabel: t('form.leadChannelsOther'),
      options: otherChannels.map(({ bestProfile, channel }) => ({
        label: bestProfile,
        value: channel,
      })),
    },
  ];
}

const ThreadFilterForm = () => {
  const {
    inboxFilterLocalStorageKey,
    employees,
    leadChannels,
    leadSearchFieldRef,
    properties,
    updateThreadFilter,
  } = useContext(InboxContext);
  const [leadSearchActive, setLeadSearchActive] = useState(false);
  const { t } = useTranslation();
  const { lead } = useWatch();
  const { reset } = useFormContext();
  const { clearLocalStorage } = useLocalStorage<InboxFilterSchemaType>(
    inboxFilterLocalStorageKey,
  );

  useInboxFilterFormApplyStoredValues();

  useEffect(() => {
    setLeadSearchActive(!!lead?.length);
  }, [lead]);

  const allOption = { label: t('common.all'), value: '' };
  const employeesOptions = [
    allOption,
    ...employees.map(({ bestProfile, uid }) => ({
      label: trimAndLimitCharactersNumber({ text: bestProfile, limit: 45 }),
      value: uid,
    })),
  ];

  const showUnreadOnlyOptions = [
    allOption,
    { label: t('form.unread'), value: 'true' },
  ];
  const sortByOptions = Object.values(ThreadFilterSortOrder).map((value) => ({
    label: t(`form.sortBy_${value}`),
    value,
  }));
  const leadStatusOptions = [
    allOption,
    {
      label: t(`form.leadStatuses.${LeadStatusRelevant}`),
      value: LeadStatusRelevant,
    },
    ...Object.keys(ThreadFilterFormLeadStatusesMap)
      .filter((key) => key !== LeadStatusRelevant)
      .map((key: keyof typeof ThreadFilterFormLeadStatusesMap) => ({
        label: t(`form.leadStatuses.${key}`),
        value: key,
      })),
  ];

  const handleClear = () => {
    const defaultValues = getFormDefaultValues();
    reset(defaultValues);
    updateThreadFilter(getUpdatedThreadFilter(defaultValues));
    clearLocalStorage();
    leadSearchFieldRef.current?.clear();
  };

  return (
    <>
      <Row>
        <Col sm={6} md={4}>
          <SelectField
            colSmLabel={5}
            colSmInput={7}
            disabled={leadSearchActive}
            label={t('form.allMessages')}
            name="showUnreadOnly"
            options={showUnreadOnlyOptions}
          />
        </Col>
        <Col sm={6} md={4}>
          <SelectField
            colSmLabel={5}
            colSmInput={7}
            disabled={leadSearchActive}
            label={t('form.sortBy')}
            name="sortBy"
            options={sortByOptions}
          />
        </Col>
        <Col sm={6} md={4}>
          <PropertySelectField
            colSmLabel={5}
            colSmInput={7}
            name="propertyUid"
            emptyValueLabel={t('common.all')}
            pictureAsLabel={false}
            properties={properties as Property[]}
            disabled={leadSearchActive}
            label={t('form.property')}
            showUnits={false}
            enableHotels={false}
          />
        </Col>
        <Col sm={6} md={4}>
          <SelectField
            colSmLabel={5}
            colSmInput={7}
            disabled={leadSearchActive}
            label={t('form.leadChannel')}
            name="leadChannel"
            options={[allOption]}
            optionGroups={prepareLeadChannelGroups(leadChannels, t)}
          />
        </Col>
        <Col sm={6} md={4}>
          <SelectField
            colSmLabel={5}
            colSmInput={7}
            disabled={leadSearchActive}
            label={t('form.agent')}
            name="agentUid"
            options={employeesOptions}
          />
        </Col>
        <Col sm={6} md={4}>
          <SelectField
            colSmLabel={5}
            colSmInput={7}
            disabled={leadSearchActive}
            label={t('form.status')}
            name="leadStatus"
            options={leadStatusOptions}
          />
        </Col>
      </Row>
      <FilterFormActions>
        <FilterFormClearButton onClick={handleClear} />
        <FilterFormSubmitButton disabled={leadSearchActive} />
      </FilterFormActions>
    </>
  );
};

export default ThreadFilterForm;
