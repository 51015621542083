import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ResolutionStatus } from 'models/AirbnbResolutions';
import FormWithProvider from 'components/forms/form/Form';

import SelectField from 'components/forms/selectField/SelectField';
import Button from '../../../../components/button/Button';
import ResolutionsContext from './ResolutionsContext';

const ResolutionsFilter = () => {
  const { t } = useTranslation();

  const { setStatus } = useContext(ResolutionsContext);

  const statusOptions = Object.values(ResolutionStatus).map((value) => ({
    label: t(`pageAirbnbResolutions.resolutionStatus.${value}`),
    value,
  }));

  return (
    <FormWithProvider
      className="resolutions-filter-form"
      data-testid="resolutions-filter-form"
      inline
      defaultValues={{ status: 'ALL' }}
      schema={{}}
      onSubmit={({ status }) => setStatus(status)}
    >
      <SelectField
        label={t('pageAirbnbResolutions.pageAirbnbResolutions.status')}
        name="status"
        colSmLabel={4}
        colSmInput={8}
        options={statusOptions}
      />
      <Button
        bsStyle="primary"
        type="submit"
        className="resolutions-filter-submit"
      >
        {t('pageAirbnbResolutions.pageAirbnbResolutions.apply')}
      </Button>
    </FormWithProvider>
  );
};

export default ResolutionsFilter;
