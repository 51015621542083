import { css } from 'styled-components';

export const LoadingDayCellStyles = css`
  .loading-placeholder {
    background-color: #ebebeb;
    border-radius: 5px;
    display: inline-flex;
    line-height: 1;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &.first {
      width: calc(100% * 0.51);
    }

    &.second {
      margin-top: 0.3em;
      width: calc(100% * 0.34);
    }
  }

  &.stacked-calendar-body-mode-booking .loading-placeholder {
    line-height: 0.5;

    &.first {
      width: calc(100% * 0.63);
    }

    &.second {
      width: calc(100% * 0.42);
    }
  }
`;
