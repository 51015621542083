import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useFetchBookingDotComRoomNames from 'components/domain/bookingDotCom/useFetchBookingDotComRoomNames';
import SelectField from 'components/forms/selectField/SelectField';
import FieldPlaceholder from 'pages/property/common/FieldPlaceholder';
import { PropertySettingsMainTabFormValues } from '../../PropertySettingsMainTab.schema';

const RoomNameField = () => {
  const { t } = useTranslation();
  const { uid: propertyUid } = useParams();
  const {
    bookingDotComSettings: { roomType },
  } = useWatch<PropertySettingsMainTabFormValues>();

  const { data: roomNamesData, isLoading: isLoadingRoomNames } =
    useFetchBookingDotComRoomNames({ propertyUid });

  if (isLoadingRoomNames) {
    return (
      <FieldPlaceholder
        label={t('pageProperty.mainSettings.bookingDotComSettings.roomName')}
        colSmInput={7}
        colSmLabel={5}
        name="bookingDotComSettings.roomName"
        required
      />
    );
  }

  const roomNameOptions = (roomNamesData?.availableRoomNames ?? [])
    .filter(
      (availableRoomName) =>
        availableRoomName.typeName &&
        roomType &&
        availableRoomName.typeName.toLowerCase() === roomType.toLowerCase(),
    )
    .map(({ roomName }) => ({ value: roomName, label: roomName }));

  const isCustomRoomName =
    roomNamesData?.storedName &&
    !roomNamesData.availableRoomNames.find(
      ({ roomName }) => roomName === roomNamesData.storedName,
    );

  return (
    <SelectField
      label={t('pageProperty.mainSettings.bookingDotComSettings.roomName')}
      name="bookingDotComSettings.roomName"
      options={roomNameOptions}
      colSmInput={7}
      colSmLabel={5}
      disabled={isCustomRoomName}
      required
    />
  );
};

export default RoomNameField;
