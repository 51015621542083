import { ChannelSettings, ChannelSettingsResponseTO } from 'models/Channels';
import useAppUser from '../../../hooks/useAppUser';
import useAppQuery from '../../../hooks/useAppQuery';
import API from '../../../services/API';

const useFetchPropertyChannelSettings = (propertyUid: string) => {
  const { isEmployee } = useAppUser();

  const { data: channelSettings, isInitialLoading: isFetching } = useAppQuery(
    ['property-channel-settings', propertyUid],
    async (): Promise<ChannelSettings[]> => {
      const response = await API.get<ChannelSettingsResponseTO>(
        `/inner-api/channelSetting_get.jsp?propertyUID=${propertyUid}`,
      );
      return response.data;
    },
    {
      enabled: !!propertyUid && isEmployee,
    },
  );

  return { isFetching, channelSettings };
};

export default useFetchPropertyChannelSettings;
