import useAppModal from 'hooks/useAppModal';
import { LinkSubUnitsModalProps } from './LinkSubUnitsModal.types';
import LinkSubUnitsModal from './LinkSubUnitsModal';
import { linkSubUnitsModalId } from './LinkSubUnitsModal.constants';

const useLinkSubUnitsModal = () => {
  const { openModal } = useAppModal();

  const openLinkSubUnitsModal = ({ propertyUid }: LinkSubUnitsModalProps) => {
    openModal({
      customContent: <LinkSubUnitsModal propertyUid={propertyUid} />,
      id: linkSubUnitsModalId,
      size: 'medium',
    });
  };

  return {
    openLinkSubUnitsModal,
  };
};

export default useLinkSubUnitsModal;
