import TextHighlighter from 'components/text/TextHighlighter';
import { Props as ReactSelectProps } from 'react-select';

type Option = {
  label: string;
  value: string;
};

type FormatOptionLabel<T extends Option = Option> =
  ReactSelectProps<T>['formatOptionLabel'];

export function formatOptionLabelWithHighlight<T extends Option = Option>(
  ...args: Parameters<FormatOptionLabel<T>>
) {
  const [{ label }, { inputValue }] = args;

  return <TextHighlighter text={label} textToHighlight={inputValue} />;
}
