import { useTranslation } from 'react-i18next';
import PageHeader from 'components/pageHeader/PageHeader';
import { Container } from './Forbidden.styles';

const Forbidden = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <PageHeader
        title={t('common.pageError.forbidden.header')}
        subtitle={t('common.pageError.forbidden.message')}
        noDivider
      />
    </Container>
  );
};

export default Forbidden;
