import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAppUser from 'hooks/useAppUser';
import useFilterIcon from 'hooks/useFilterIcon';
import { CalendarType } from 'models/Calendar';
import CalendarContext from '../CalendarContext';
import {
  HeaderContainer,
  HeaderControlsContainer,
} from './StackedCalendarHeader.styles';
import ModeSwitcher from './ModeSwitcher';
import CalendarTodayButton from './CalendarTodayButton';

const CalendarHeader = () => {
  const { t } = useTranslation();
  const { agency, isOwner } = useAppUser();
  const { isFilterOpen, filterIconWithCounterFromForm: FilterIconRenderer } =
    useFilterIcon({
      stateStorageKey: 'calendar-filters-expanded',
    });
  const { setIsFilterOpen } = useContext(CalendarContext);

  useEffect(() => {
    setIsFilterOpen(isFilterOpen);
  }, [isFilterOpen]);

  return (
    <HeaderContainer>
      <h2 data-testid="stacked-calendar-title">
        <span>{agency.name}</span>{' '}
        {t('pageCalendar.stacked.propertiesCalendar')}
      </h2>

      {!isOwner && (
        <HeaderControlsContainer>
          <CalendarTodayButton type={CalendarType.STACKED_CALENDAR} />
          <ModeSwitcher />
          <FilterIconRenderer />
        </HeaderControlsContainer>
      )}
    </HeaderContainer>
  );
};

export default CalendarHeader;
