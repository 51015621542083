import { useContext, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { FieldError, useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DateTimePickerField from 'components/forms/dateTimePickerField/DateTimePickerField';
import EmployeeSelectField from 'components/forms/domain/employee/EmployeeSelectField';
import PropertySelectField from 'components/forms/domain/property/PropertySelectField';
import SelectField from 'components/forms/selectField/SelectField';
import TextField from 'components/forms/textField/TextField';
import { getPropertyByUid } from 'utils/property/propertyUtils';
import {
  canAddNewLineItem,
  canForceFullPayment,
  getLeadBookedDate,
  isLeadEditable,
  isLeadFieldDisabled,
} from 'utils/lead/leadUtils';
import LeadModalContext from '../../LeadModalContext';
import {
  AssigneeLabel,
  AssigneeLabelContainer,
  BookingDateContainer,
  LeadDetailsFormLeftColumn,
  LeadDetailsFormRightColumn,
} from './LeadModalDetailsTab.styles';
import LeadModalDetailsOrder from './order/LeadModalDetailsOrder';

function getGuestOptions(maxGuests: number) {
  return Array.from(new Array(maxGuests + 1)).map((_, index) => ({
    label: `${index}`,
    value: index,
  }));
}

const LeadModalDetailsTab = () => {
  const { availableEmployees, availableProperties, lead } =
    useContext(LeadModalContext);
  const { errors } = useFormState();
  const { t } = useTranslation();
  const { uid: leadUid, propertyUid } = useWatch();
  const updateMode = !!leadUid;

  useEffect(() => {
    const { checkInDate, checkOutDate } = errors;

    if (checkInDate || checkOutDate) {
      const { ref } = (checkInDate || checkOutDate) as FieldError;
      setTimeout(() => {
        ref?.focus();
      });
    }
  }, [errors]);

  const currentProperty = getPropertyByUid(availableProperties, propertyUid);
  const maxGuests = currentProperty?.availability?.maxGuests || 10;
  const guestOptions = getGuestOptions(maxGuests);
  const bookedDate = getLeadBookedDate(lead);

  return (
    <Row>
      <LeadDetailsFormLeftColumn md={5}>
        {updateMode && (
          <>
            <AssigneeLabelContainer>
              <AssigneeLabel sm={8}>
                {t('componentLead.modal.form.assignedTo')}
              </AssigneeLabel>
            </AssigneeLabelContainer>
            <EmployeeSelectField
              avatarAsLabel
              colSmInput={8}
              colSmLabel={4}
              containerClassName="assignee-container"
              employees={availableEmployees}
              emptyValueLabel={t('componentLead.modal.form.employeeUnassigned')}
              name="assigneeUid"
              disabled={isLeadFieldDisabled(lead, 'assigneeUid')}
            />
          </>
        )}
        <PropertySelectField
          colSmInput={8}
          colSmLabel={4}
          containerClassName="property-container"
          name="propertyUid"
          pictureAsLabel
          properties={availableProperties}
          disabled={isLeadFieldDisabled(lead, 'propertyUid')}
          showInactiveProperties={updateMode}
          enableHotels={false}
          enableUnitTypes={false}
          shouldShowUnitDefault
        />
        <DateTimePickerField
          name="checkInDate"
          label={t('common.from')}
          colSmInput={8}
          colSmLabel={4}
          required
          disabled={isLeadFieldDisabled(lead, 'checkInLocalDateTime')}
        />
        <DateTimePickerField
          containerClassName="check-out-date-container"
          name="checkOutDate"
          label={t('common.to')}
          colSmInput={8}
          colSmLabel={4}
          required
          disabled={isLeadFieldDisabled(lead, 'checkOutLocalDateTime')}
        />
        <SelectField
          label={t('componentLead.modal.form.adults')}
          name="adultCount"
          options={guestOptions.slice(1)}
          colSmInput={8}
          colSmLabel={4}
          disabled={isLeadFieldDisabled(lead, 'adultCount')}
        />
        <SelectField
          label={t('componentLead.modal.form.children')}
          name="childrenCount"
          options={guestOptions}
          colSmInput={8}
          colSmLabel={4}
          disabled={isLeadFieldDisabled(lead, 'childrenCount')}
        />
        <SelectField
          label={t('componentLead.modal.form.infants')}
          name="infantCount"
          options={guestOptions}
          colSmInput={8}
          colSmLabel={4}
          disabled={isLeadFieldDisabled(lead, 'infantCount')}
        />
        <TextField
          label={t('componentLead.modal.form.pets')}
          name="petCount"
          type="number"
          min={0}
          max={100}
          colSmInput={8}
          colSmLabel={4}
          disabled={isLeadFieldDisabled(lead, 'petCount')}
        />
        <TextField
          label={t('componentLead.modal.form.notes')}
          placeholder={t('componentLead.modal.form.notesPlaceholder')}
          name="notes"
          colSmInput={8}
          colSmLabel={4}
          componentClass="textarea"
          rows={6}
        />
      </LeadDetailsFormLeftColumn>
      <LeadDetailsFormRightColumn
        md={7}
        $hasMorePadding={
          !(updateMode && canForceFullPayment(lead)) &&
          !(isLeadEditable(lead) && canAddNewLineItem(lead))
        }
      >
        <LeadModalDetailsOrder />
        {bookedDate && (
          <BookingDateContainer>
            {t('componentLead.modal.form.bookedOn', {
              date: bookedDate,
            })}
          </BookingDateContainer>
        )}
      </LeadDetailsFormRightColumn>
    </Row>
  );
};

export default LeadModalDetailsTab;
