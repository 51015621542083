import styled from 'styled-components';

export const OrderViewPriceDetailsListItemRowWrapper = styled.div.attrs({
  className: 'order-view-price-details-list-item-row-wrapper',
})<{
  $isBold?: boolean;
  $isChildren?: boolean;
  $hasBorder?: boolean;
  $isCapitalized?: boolean;
}>`
  font-weight: ${({ $isBold }) => ($isBold ? 'bold' : 'normal')};
  padding: 0.5rem;
  border-bottom: ${({ $hasBorder }) => ($hasBorder ? '1px' : '0px')} solid
    #f3f3f7;
  display: flex;
  justify-content: space-between;

  & > div:first-child {
    text-transform: ${({ $isCapitalized }) =>
      $isCapitalized ? 'capitalize' : 'none'};
    display: flex;
    align-items: center;
    flex: 0 1 60%;
    word-break: break-word;
  }

  .tooltip-container {
    margin-left: 0.5rem;
    padding-top: 2px;
  }

  ${({ $isChildren }) =>
    $isChildren &&
    `
    padding-left: 1.5rem;
  `}
`;

export const OrderViewPriceDetailsButtonWrapper = styled.div`
  margin-left: 1.5rem;
`;
