import { LeadSource, LeadStatus } from 'models/Leads';
import styled from 'styled-components';
import { getOpacity, isFromICS } from 'utils/lead/leadUtils';

export const LeadChannelIconWrapper = styled.i<{
  $leadStatus: LeadStatus;
  $leadSource: LeadSource;
  fullOpacity?: boolean;
}>`
  height: 1.2em;
  width: 20px;
  height: 20px;
  margin-right: 0.2em;
  filter: none;
  ${({ $leadSource }) =>
    $leadSource &&
    isFromICS($leadSource) &&
    `filter: brightness(0) saturate(100%) invert(95%) sepia(21%) saturate(3624%) hue-rotate(313deg) brightness(104%) contrast(110%);`}
  ${({ $leadStatus, fullOpacity }) =>
    $leadStatus && `opacity: ${fullOpacity ? '1' : getOpacity($leadStatus)};`}
`;
