import { Col, Row } from 'react-bootstrap';
import LoadingPlaceholder from '../../../components/loader/LoadingPlaceholder';

const InboxFilterLoader = () => {
  const margin = '.5em 0';

  return (
    <Row>
      {[1, 2, 3, 4, 5, 6].map((number) => (
        <Col sm={6} md={4} lg={3} key={number}>
          <LoadingPlaceholder linesCount={2.5} margin={margin} />
        </Col>
      ))}
      <Col sm={10} md={12} lg={5}>
        <LoadingPlaceholder linesCount={2.5} margin={margin} />
      </Col>
    </Row>
  );
};

export default InboxFilterLoader;
