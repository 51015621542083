import styled from 'styled-components';
import FormWithProvider from 'components/forms/form/Form';
import UploadField from 'components/forms/fileField/UploadField/UploadField';

export const FormStyled = styled(FormWithProvider)`
  .modal-body {
    display: flex;
    flex-direction: column;

    .form-group {
      > label {
        width: 30%;
      }

      > div {
        width: 70%;
      }
    }
  }
`;

export const UploadFieldStyled = styled(UploadField)<{ $isVisible: boolean }>`
  ${({ $isVisible }) => !$isVisible && 'display: none;'}
`;
