import useAppQuery from 'hooks/useAppQuery';
import {
  AmenityType,
  GetPropertyAmenitiesResponse,
  PropertyAmenity,
} from 'models/Amenity';
import { PropertyBusinessType } from 'models/Properties';
import API from 'services/API';
import usePropertySettingsInitData from '../usePropertySettingsInitData';

export const PROPERTY_AMENITIES_QUERY_KEY = 'fetch-all-property-amenities';

const usePropertyAmenities = (enabled?: boolean) => {
  const { property } = usePropertySettingsInitData();
  const { uid: propertyUid, businessType } = property;
  const isHotel = businessType === PropertyBusinessType.HOTEL;

  const {
    data: propertyAmenities,
    error,
    isLoading,
  } = useAppQuery(
    [PROPERTY_AMENITIES_QUERY_KEY, propertyUid],
    async () => {
      const response = await API.get<GetPropertyAmenitiesResponse>(
        '/api/v3.1/amenities',
        {
          params: {
            [isHotel ? 'hotelUid' : 'propertyUid']: propertyUid,
          },
        },
      );

      const { amenities } = response.data;

      return amenities;
    },
    {
      enabled: enabled && !!propertyUid,
    },
  );

  const propertyAmenitiesMap = propertyAmenities?.reduce((acc, amenity) => {
    acc[amenity.amenity] = amenity;
    return acc;
  }, {} as Record<AmenityType, PropertyAmenity>);

  return { propertyAmenities: propertyAmenitiesMap, error, isLoading };
};

export default usePropertyAmenities;
