import { AdjustmentType } from './Quote';
import { BaseResponse } from './_base';

export enum OrderAdjustmentTransactionType {
  CHARGE = 'CHARGE',
  REFUND = 'REFUND',
}

export interface OrderAdjustmentCreationPayload {
  transactionType: OrderAdjustmentTransactionType;
  adjustmentType: AdjustmentType;
  amount: number;
  note?: String;
}

export interface OrderAdjustment {
  uid: string;
  transactionType: OrderAdjustmentTransactionType;
  adjustmentType: AdjustmentType;
  amount: number;
  note?: String;
  createdUtcDateTime: string;
  externalId?: string;
}

export interface CreateOrderAdjustmentResponseTO extends BaseResponse {
  orderAdjustmentItem: OrderAdjustment;
}
