import { useState } from 'react';
import DisplayableValue from './DisplayableValue';

interface TextFieldProps {
  isEditable: boolean;
  value: string;
  onItemUpdated: Function;
}

const TextField = ({ isEditable, value, onItemUpdated }: TextFieldProps) => {
  const [editMode, setEditMode] = useState(false);

  if (!isEditable) {
    return <span>{`${value}%`}</span>;
  }

  const handleDisplayClick = () => {
    if (isEditable) {
      setEditMode(true);
    }
  };

  const onSave = (newValue) => onItemUpdated(value, newValue);

  return editMode ? (
    <input
      type="text"
      defaultValue={value}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          // @ts-ignore
          onSave(e.target.value);
        }
      }}
      onBlur={(e) => {
        onSave(e.target.value);
        setEditMode(false);
      }}
    />
  ) : (
    <DisplayableValue value={value} type="text" onClick={handleDisplayClick} />
  );
};

export default TextField;
