import { Alert } from 'react-bootstrap';
import styled from 'styled-components';
import theme from 'styles/theme';

export const PropertyNameStyled = styled.span`
  color: ${theme.colors.fontGreen};
`;

export const AlertStyled = styled(Alert)`
  margin-bottom: 0;
  margin-top: 16px;
`;
