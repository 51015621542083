import { useTranslation } from 'react-i18next';
import Step1Icon from 'assets/images/refer/step-1.svg?react';
import Step2Icon from 'assets/images/refer/step-2.svg?react';
import Step3Icon from 'assets/images/refer/step-3.svg?react';
import PageHeader from 'components/pageHeader/PageHeader';
import {
  Container,
  CreateReferral,
  CreateReferralWrapper,
  Description,
  Details,
  DetailsItem,
  Step,
  Steps,
} from './Refer.styles';

const Refer = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <PageHeader
        title={t('pageRefer.title')}
        subtitle={t('pageRefer.subtitle')}
      />

      <Description data-testid="description">
        {t('pageRefer.description')}
      </Description>

      <Steps>
        <Step data-test-id="step-1" sm={4}>
          <Step1Icon />
          <span>{t('pageRefer.steps.step1.title')}</span>
          <span>{t('pageRefer.steps.step1.description')}</span>
        </Step>

        <Step data-test-id="step-2" sm={4}>
          <Step2Icon />
          <span>{t('pageRefer.steps.step2.title')}</span>
          <span>{t('pageRefer.steps.step2.description')}</span>
        </Step>

        <Step data-test-id="step-3" sm={4}>
          <Step3Icon />
          <span>{t('pageRefer.steps.step3.title')}</span>
          <span>{t('pageRefer.steps.step3.description')}</span>
        </Step>
      </Steps>

      <CreateReferralWrapper>
        <CreateReferral
          className="btn btn-primary"
          data-testid="create-referral"
          href="https://hostfully.partnerstack.com/?group=customers"
          target="_blank"
          rel="noreferrer"
        >
          {t('pageRefer.createReferral')}
        </CreateReferral>
      </CreateReferralWrapper>

      <Details data-testid="details">
        <DetailsItem>
          {t('pageRefer.details.1_rewardsAreSentAfterThreeMonths')}
        </DetailsItem>
        <DetailsItem>
          {t('pageRefer.details.2_seeFullTermsAndConditions')}
        </DetailsItem>
        <DetailsItem>
          {t('pageRefer.details.3_needsPayPalOrStripeAccount')}
        </DetailsItem>
        <DetailsItem>
          {t('pageRefer.details.4_paidOnlyIfUniqueLinkWasUSed')}
        </DetailsItem>
      </Details>
    </Container>
  );
};

export default Refer;
