import { ImageCircle } from 'components/image/ImageCircle.styles';
import { PropertyBase } from 'models/Properties';
import theme from 'styles/theme';
import { getPropertyEditPath } from 'utils/property/propertyUtils';

interface PropertyPictureProps {
  property: Pick<PropertyBase, 'businessType' | 'mainPicture' | 'name' | 'uid'>;
}

const PropertyPicture = ({ property }: PropertyPictureProps) => {
  return (
    <a
      aria-label={property.name}
      data-testid="property-list-item-picture"
      href={getPropertyEditPath(property)}
    >
      <ImageCircle $ringColor={theme.colors.gray300}>
        <div>
          <img src={property.mainPicture?.tinyThumbnail} alt={property.name} />
        </div>
      </ImageCircle>
    </a>
  );
};

export default PropertyPicture;
