import { Glyphicon } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import { GenericFormFieldProps } from '../genericFormField/GenericFormField.types';
import TextField from '../textField/TextField';
import Button from '../../button/Button';
import { NumberFieldWrapper } from './NumberField.styles';

interface Props extends Omit<GenericFormFieldProps, 'fieldRenderer'> {
  deleteHandler?: () => void;
  max?: number;
  min?: number;
}

const NumberField = ({ deleteHandler, ...rest }: Props) => {
  const { max, min, name } = rest;
  const { setValue } = useFormContext();
  const currentValue = useWatch({ name });

  const deleteOnMinus = deleteHandler && currentValue === min;

  const onLeftAddonClick = () => {
    if (deleteOnMinus) {
      deleteHandler();
    } else {
      setValue(name, currentValue - 1, { shouldDirty: true });
    }
  };

  const onRightAddonClick = () => {
    setValue(name, currentValue + 1, { shouldDirty: true });
  };

  const leftAddon = (
    <Button
      bsStyle="link"
      data-testid="decrement-button"
      disabled={!deleteHandler && currentValue === min}
      onClick={onLeftAddonClick}
    >
      {deleteOnMinus ? (
        <Glyphicon glyph="trash" className="small" />
      ) : (
        <span className="button-text">-</span>
      )}
    </Button>
  );

  const rightAddon = (
    <Button
      bsStyle="link"
      data-testid="increment-button"
      disabled={currentValue === max}
      onClick={onRightAddonClick}
    >
      <span className="button-text">+</span>
    </Button>
  );

  return (
    <NumberFieldWrapper>
      <TextField
        {...rest}
        leftAddon={leftAddon}
        rightAddons={[rightAddon]}
        type="number"
      />
    </NumberFieldWrapper>
  );
};

NumberField.defaultProps = {
  deleteHandler: undefined,
  max: undefined,
  min: undefined,
};

export default NumberField;
