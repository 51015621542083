import { Col } from 'react-bootstrap';
import PageHeader from 'components/pageHeader/PageHeader';
import useFilterIcon from 'hooks/useFilterIcon';
import { Container, ResolutionFilterIconWrapper } from './Resolutions.styles';
import ResolutionsList from './ResolutionsList';
import ResolutionsPageTitle from './ResolutionsPageTitle';
import ResolutionsFilter from './ResolutionsFilter';
import ResolutionsContextProvider from './ResolutionsContextProvider';

const Resolutions = () => {
  const { isFilterOpen, filterIcon } = useFilterIcon({
    stateStorageKey: 'resolutions-filters-expanded',
  });
  const resolutionsFilterIcon = filterIcon();

  return (
    <Container>
      <PageHeader title={<ResolutionsPageTitle />} />
      <ResolutionFilterIconWrapper>
        {resolutionsFilterIcon}
      </ResolutionFilterIconWrapper>

      <Col>
        <ResolutionsContextProvider>
          {isFilterOpen && <ResolutionsFilter />}
          <ResolutionsList />
        </ResolutionsContextProvider>
      </Col>
    </Container>
  );
};

export default Resolutions;
