import { useTranslation, Trans } from 'react-i18next';
import { HasOrderItems } from 'models/hasOrderItems/HasOrderItems';
import PercentageField from 'components/domain/lead/modal/tabs/details/order/fields/common/PercentageField';
import { useFormContext } from 'react-hook-form';
import { QuoteFeeType } from 'models/Quote';
import { PriceDetailsList } from '../OrderViewPriceDetails.styles';
import OrderViewPriceDetailsListItem from '../OrderViewPriceDetailsListItem';
import { PriceDetailsItem } from '../OrderViewPriceDetailsListItemRow';

const Taxes = ({
  order,
  allowEdit,
  priceDetailsItems,
}: {
  order: HasOrderItems;
  allowEdit: boolean;
  priceDetailsItems: PriceDetailsItem[];
}) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const {
    currency,
    rent: { taxAmount, taxationRate },
    fees,
  } = order;

  const totalTaxes =
    fees.otherFees.reduce((acc, fee) => acc + fee.taxAmount, 0) +
      fees.cleaningFee.taxAmount +
      fees.otherFees
        .filter(({ removed }) => !removed)
        .filter((o) => o.type === QuoteFeeType.TAX)
        .reduce((acc, fee) => acc + fee.netPrice, 0) +
      taxAmount || 0;

  if (
    totalTaxes <= 0 &&
    !fees.otherFees
      .filter(({ removed }) => !removed)
      .find((o) => o.type === QuoteFeeType.TAX)
  ) {
    return null;
  }

  return (
    !!order.rent && (
      <PriceDetailsList data-testid="price-details-list-taxes">
        <OrderViewPriceDetailsListItem
          title={t('componentOrder.taxes')}
          priceDetailsItems={[
            taxAmount > 0 && {
              label: (
                <Trans
                  i18nKey="componentOrder.genericTaxesWithInput"
                  components={{
                    input: (
                      <PercentageField
                        value={taxationRate}
                        isEditable={allowEdit}
                        onItemUpdated={(val) => {
                          // TODO set value to orderItem
                          setValue('quote.rent.taxationRate', val, {
                            shouldDirty: true,
                          });
                        }}
                      />
                    ),
                  }}
                />
              ),
              isEditable: allowEdit,
              amount: taxAmount,
              onItemUpdated: (val) => {
                setValue('quote.rent.taxAmount', val, {
                  shouldDirty: true,
                });
              },
              currency,
            },
            fees.cleaningFee.taxAmount > 0 && {
              label: t('componentOrder.cleaningFeeTaxes', {
                value: fees.cleaningFee.taxationRate,
              }),
              amount: fees.cleaningFee.taxAmount,
              currency,
            },
            ...priceDetailsItems,
            {
              label: t('componentOrder.totalTaxes'),
              amount: totalTaxes,
              isTotalAmount: true,
              currency,
            },
          ]}
        />
      </PriceDetailsList>
    )
  );
};

export default Taxes;
