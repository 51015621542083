import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  width: 100vw;
  position: relative;
  overflow: hidden;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Main = styled.main`
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${mediaQuery.custom(1024)} {
    flex-direction: column;
  }
`;

export const PurpleWrapper = styled.div`
  position: absolute;
  left: 3%;
  top: 35%;

  ${mediaQuery.custom(1920)} {
    left: 8%;
    top: 44%;
  }

  ${mediaQuery.custom(1440)} {
    left: 1%;
    top: 44%;
  }

  ${mediaQuery.custom(1024)} {
    left: 3%;
    top: 52%;
  }

  ${mediaQuery.custom(768)} {
    left: 1%;
    top: 50%;
  }
`;

export const LightBlueWrapper = styled.div`
  position: absolute;
  left: 10%;
  top: 17%;

  ${mediaQuery.custom(1920)} {
    left: 14%;
    top: 17%;
  }

  ${mediaQuery.custom(1440)} {
    left: 5%;
    top: 5%;
  }

  ${mediaQuery.custom(1024)} {
    left: 17%;
    top: 25%;
  }

  ${mediaQuery.custom(768)} {
    left: 5%;
    top: 10%;
  }
`;

export const YellowWrapper = styled.div`
  position: absolute;
  left: 25%;
  top: 7%;

  ${mediaQuery.custom(1920)} {
    left: 28%;
    top: 1%;
  }

  ${mediaQuery.custom(1440)} {
    left: 33%;
    top: 5%;
  }

  ${mediaQuery.custom(1024)} {
    left: 32%;
    top: -5%;
  }

  ${mediaQuery.custom(768)} {
    left: 60%;
    top: -15%;
  }
`;

export const OrangeWrapper = styled.div`
  display: block;
  position: absolute;
  left: 85%;
  top: 18%;

  ${mediaQuery.custom(1920)} {
    left: 82%;
    top: 21%;
  }

  ${mediaQuery.custom(768)} {
    display: none;
  }
`;

export const DarkBlueWrapper = styled.div`
  display: block;
  position: absolute;
  left: 80%;
  top: 8%;

  ${mediaQuery.custom(1920)} {
    left: 85%;
    top: 4%;
  }

  ${mediaQuery.custom(1440)} {
    left: 85%;
    top: -3%;
  }

  ${mediaQuery.custom(1024)} {
    display: none;
  }
`;

export const Gap = styled.div`
  width: 100%;
  height: 100px;
`;
