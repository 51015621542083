export const PRICING_PLAN_TERM = {
  MONTHLY: 'MONTHLY',
  ANNUAL: 'ANNUAL',
} as const;

export type PricingPlanTerm =
  (typeof PRICING_PLAN_TERM)[keyof typeof PRICING_PLAN_TERM];

export const PRICING_PLAN_CATEGORY = {
  FREE: 'FREE',
  STARTER: 'STARTER',
  PRO: 'PRO',
  PRO_PLUS: 'PRO_PLUS',
  PREMIUM: 'PREMIUM',
} as const;

export type PricingPlanCategory =
  (typeof PRICING_PLAN_CATEGORY)[keyof typeof PRICING_PLAN_CATEGORY];

export interface PricingPlan {
  type: string;
  category: PricingPlanCategory;
  price: number;
  term: PricingPlanTerm;
  monthlyPrice?: number;
  propertiesLeftBeforeUpgrade: number;
}

export interface GetPricingPlanResponseTO {
  propertyCount: number;
  currentPlan: PricingPlan;
  tierUpgradeOffer: PricingPlan;
  tierDowngradeOffer: PricingPlan | null;
  termUpgradeOffer: PricingPlan | null;
}
