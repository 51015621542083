import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from 'components/forms/textField/TextField';
import PropertyFormFieldLabel from 'pages/property/common/PropertyFormFieldLabel';
import { PropertySettingsMainTabFormValues } from '../../PropertySettingsMainTab.schema';

const CollectBalanceAtField = () => {
  const { t } = useTranslation();
  const {
    bookingSettings: { percentageUponReservation },
  } = useWatch<PropertySettingsMainTabFormValues>();

  return (
    <TextField
      label={
        <PropertyFormFieldLabel name="bookingSettings.fullPaymentTiming">
          {t('pageProperty.mainSettings.bookingSettings.fullPaymentTiming')}
        </PropertyFormFieldLabel>
      }
      name="bookingSettings.fullPaymentTiming"
      colSmInput={7}
      colSmLabel={5}
      type="number"
      min={0}
      disabled={percentageUponReservation >= 100}
      rightAddons={[t('pageProperty.mainSettings.bookingSettings.days')]}
      rightTooltip={t(
        'pageProperty.mainSettings.bookingSettings.doesNotPushToAirbnbTooltip',
      )}
    />
  );
};

export default CollectBalanceAtField;
