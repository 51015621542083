import { useTranslation } from 'react-i18next';
import Badge from 'components/badge/Badge';
import theme from 'styles/theme';

interface PropertyStatusBadgeProps {
  isActive?: boolean;
}

const PropertyStatusBadge = ({ isActive }: PropertyStatusBadgeProps) => {
  const { t } = useTranslation();
  return (
    <Badge
      backgroundColor={isActive ? theme.colors.bgGreen : theme.colors.gray600}
      data-testid="property-status-badge"
    >
      {isActive
        ? t('pageProperties.body.active')
        : t('pageProperties.body.inactive')}
    </Badge>
  );
};

PropertyStatusBadge.defaultProps = {
  isActive: undefined,
};

export default PropertyStatusBadge;
