export enum LeadV3Source {
  HOSTFULLY_UI = 'HOSTFULLY_UI',
  HOSTFULLY_DBS = 'HOSTFULLY_DBS',
  HOSTFULLY_API = 'HOSTFULLY_API',
  HOSTFULLY_LINKED = 'HOSTFULLY_LINKED',
  HOSTFULLY_OWNER_PORTAL = 'HOSTFULLY_OWNER_PORTAL',
  HOSTFULLY_ICAL = 'HOSTFULLY_ICAL',
  DIRECT_HOMETOGO = 'DIRECT_HOMETOGO',
  DIRECT_BOOKINGDOTCOM = 'DIRECT_BOOKINGDOTCOM',
  DIRECT_AIRBNB = 'DIRECT_AIRBNB',
  DIRECT_VRBO = 'DIRECT_VRBO',
  DIRECT_HVMI = 'DIRECT_HVMI',
  DIRECT_REDAWNING = 'DIRECT_REDAWNING',
  DIRECT_GOOGLE = 'DIRECT_GOOGLE',
}
