import { ReactElement } from 'react';
import useFormatCurrency from 'hooks/useFormatCurrency';
import {
  OrderViewPriceDetailsListItemTotalSubtitle,
  OrderViewPriceDetailsListItemTotalTitle,
  OrderViewPriceDetailsListItemTotalWrapper,
} from './OrderViewPriceDetailsListItemTotal.styles';

const OrderViewPriceDetailsListItemTotal = ({
  currency,
  label,
  amount,
  subtitle,
  ...props
}: {
  currency: string;
  label: string;
  amount: number;
  subtitle?: string | ReactElement;
}) => {
  const formatCurrency = useFormatCurrency();

  return (
    <OrderViewPriceDetailsListItemTotalWrapper
      {...props}
      className="price-details-list-item-total-wrapper"
    >
      <OrderViewPriceDetailsListItemTotalTitle>
        <span data-testid="price-details-list-item-total-label">{label}</span>
        {subtitle && (
          <OrderViewPriceDetailsListItemTotalSubtitle data-testid="price-details-list-item-total-subtitle">
            {subtitle}
          </OrderViewPriceDetailsListItemTotalSubtitle>
        )}
      </OrderViewPriceDetailsListItemTotalTitle>
      <div
        data-testid="price-details-list-item-total-value"
        className="text-right"
      >
        {formatCurrency({ value: amount, currency })}
      </div>
    </OrderViewPriceDetailsListItemTotalWrapper>
  );
};

OrderViewPriceDetailsListItemTotal.defaultProps = {
  subtitle: null,
};

export default OrderViewPriceDetailsListItemTotal;
