import { useTranslation } from 'react-i18next';

const BodyModalNoConnection = () => {
  const { t } = useTranslation();

  return (
    <p>
      {t(
        'pageProperties.importBookingDotComPropertiesModal.notConnectedToBookingDotCom',
      )}
      <br />
      {t(
        'pageProperties.importBookingDotComPropertiesModal.goToBookingDotComChannelPage',
      )}
    </p>
  );
};

export default BodyModalNoConnection;
