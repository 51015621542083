import styled from 'styled-components';
import { Alert, Row } from 'react-bootstrap';
import theme from 'styles/theme';
import mediaQuery from '../../styles/mediaQuery';

export const Container = styled.div.attrs({
  className: 'stacked-calendar',
})``;

export const StackedCalendarWrapperStyled = styled(Row)`
  margin: 10px 40px 25px 15px;

  .infinite-scroll-component {
    overflow: initial !important;
  }

  ${mediaQuery.xs} {
    margin: 10px 0px 16px 5px;
  }
`;

export const StackedCalendarInnerWrapperStyled = styled.div`
  display: flex;
  justify-content: start;
`;

export const WarningMessageParagraph = styled(Alert)`
  padding: 16px 24px;
  text-align: center;
`;

export const WarningMessageLink = styled.span`
  cursor: pointer;
  color: ${theme.colors.primary};
  text-decoration: underline;
`;
