import useFormatCurrency, {
  CurrencyFormattingOptions,
} from 'hooks/useFormatCurrency';
import { Currency } from 'models/Currency';
import { PriceTagStyled } from './PriceTag.styles';

const PriceTag = ({
  currency,
  currencyFormattingOptions,
  hasError,
  value,
  ...rest
}: {
  currency: Currency | string;
  currencyFormattingOptions?: CurrencyFormattingOptions;
  hasError?: boolean;
  value: number;
}) => {
  const formatCurrency = useFormatCurrency();

  const options = currencyFormattingOptions ?? {
    round: true,
    removeDecimals: true,
  };

  return (
    <PriceTagStyled $hasError={hasError} {...rest}>
      {formatCurrency({
        value,
        currency,
        options,
      })}
    </PriceTagStyled>
  );
};

PriceTag.defaultProps = {
  currencyFormattingOptions: undefined,
  hasError: false,
};

export default PriceTag;
