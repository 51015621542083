import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FormWithProvider from 'components/forms/form/Form';
import CommonModalFooter from '../common/CommonModalFooter';
import { ModalDescriptionStyled } from '../common/CommonModal.styles';
import { addSubUnitsModalId } from './AddSubUnitsModal.constants';
import { AddSubUnitsModalProps } from './AddSubUnitsModal.types';
import AddSubUnitsModalFormFields from './AddSubUnitsModalFormFields';
import addSubUnitsModalSchema from './AddSubUnitsModal.schema';
import useAddSubUnitsModalForm from './useAddSubUnitsModalForm';

const AddSubUnitsModal = ({ propertyUid }: AddSubUnitsModalProps) => {
  const { t } = useTranslation();
  const { isUpdating, handleSubmit } = useAddSubUnitsModalForm(propertyUid);
  const isDisabled = isUpdating;

  return (
    <div data-testid="add-sub-units-modal">
      <Modal.Header data-testid="add-sub-units-modal-header" closeButton>
        {t('pageProperties.actions.addSubUnitsModal.header')}
      </Modal.Header>

      <FormWithProvider
        defaultValues={{ units: null, tag: null, isNonBookable: false }}
        onSubmit={handleSubmit}
        resolver={addSubUnitsModalSchema()}
        disabled={isDisabled}
        noValidate
      >
        <fieldset disabled={isDisabled}>
          <Modal.Body>
            <ModalDescriptionStyled>
              {t(
                'pageProperties.actions.common.descriptionAutomaticallyCreateProperties',
              )}
              <br />
              <a
                href="https://help.hostfully.com/en/articles/2640923-managing-calendars-for-a-house-and-its-sub-units"
                rel="noreferrer"
                target="_blank"
              >
                {t('pageProperties.actions.common.seeDocumentation')}
              </a>
            </ModalDescriptionStyled>
            <AddSubUnitsModalFormFields />
          </Modal.Body>

          <Modal.Footer>
            <CommonModalFooter
              isUpdating={isUpdating}
              modalId={addSubUnitsModalId}
              submitLabel={t(
                'pageProperties.actions.addSubUnitsModal.saveSubUnits',
              )}
            />
          </Modal.Footer>
        </fieldset>
      </FormWithProvider>
    </div>
  );
};

export default AddSubUnitsModal;
