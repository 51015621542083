import { useContext } from 'react';
import LeadModalContext from 'components/domain/lead/modal/LeadModalContext';
import { useTranslation } from 'react-i18next';
import AmountField from './common/AmountField';

const AdjustmentItems = () => {
  const { quote } = useContext(LeadModalContext);
  const { t } = useTranslation();

  const adjustmentsTotal = quote.adjustmentItems
    .map((adjustmentItem) => adjustmentItem.amount)
    .reduce((a, b) => a + b, 0);

  return (
    <tr>
      <td>{t('componentOrder.orderAdjustments')}:</td>
      <td />
      <td />
      <td>
        <AmountField
          value={adjustmentsTotal}
          isEditable={false}
          currency={quote.currency}
          onItemUpdated={undefined}
        />
      </td>
    </tr>
  );
};

export default AdjustmentItems;
