import Switch, { SwitchProps } from 'components/switch/Switch';
import { Controller, useFormContext } from 'react-hook-form';
import { SwitchFieldWrapperStyled } from './SwitchField.styles';

interface Props extends SwitchProps {
  name: string;
}

const SwitchField = ({ name, children, ...props }: Props) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onBlur, onChange, value, ref } }) => (
        <SwitchFieldWrapperStyled className="checkbox">
          <label htmlFor={name}>
            <Switch
              id={name}
              data-testid={name}
              onBlur={onBlur}
              checked={value}
              onChange={onChange}
              ref={ref}
              {...props}
            />
            {children}
          </label>
        </SwitchFieldWrapperStyled>
      )}
    />
  );
};

export default SwitchField;
