import { useContext, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import AnalyticsContext from '../AnalyticsContext';

const ChartsFilterFormWatcher = () => {
  const formValues = useWatch();
  const { chartsFilterLocalStorageKey } = useContext(AnalyticsContext);

  useEffect(() => {
    localStorage.setItem(
      chartsFilterLocalStorageKey,
      JSON.stringify(formValues),
    );
  }, [formValues]);

  return null;
};

export default ChartsFilterFormWatcher;
