import { SyntheticEvent, useEffect, useState } from 'react';
import { Col, Nav, NavItem, Row, TabContainer } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  Property,
  PropertyBusinessType,
  PropertySubUnit,
  PropertyUnitType,
} from 'models/Properties';
import PropertyChannelsWidget from 'components/domain/property/channelsWidget/PropertyChannelsWidget';
import { VerticalTabContainer } from 'components/tab/Tab.styles';
import { normalizeKey } from 'utils/localeUtils';
import { PropertySettingsTab } from './PropertySettingsTabs.constants';
import PropertySettingsTabHeader from './PropertySettingsTabHeader';
import usePropertySettingsActiveTab from './usePropertySettingsActiveTab';

const standalonePropertyTabs = [
  // PropertySettingsTab.kpis, not part of v3.0
  PropertySettingsTab.mainSettings,
  PropertySettingsTab.descriptions,
  PropertySettingsTab.photos,
  PropertySettingsTab.amenities,
  PropertySettingsTab.pricing,
  PropertySettingsTab.feesAndPolicies,
  PropertySettingsTab.reviews,
  PropertySettingsTab.serviceProviders,
  PropertySettingsTab.owners,
  PropertySettingsTab.customData,
  PropertySettingsTab.channels,
];

const hotelTabs = [
  PropertySettingsTab.mainSettings,
  PropertySettingsTab.descriptions,
  PropertySettingsTab.photos,
  PropertySettingsTab.amenities,
  // TODO rental conditions and unit types
];

const unitTypeTabs = [
  PropertySettingsTab.mainSettings,
  PropertySettingsTab.descriptions,
  PropertySettingsTab.photos,
  PropertySettingsTab.amenities,
  PropertySettingsTab.pricing,
  PropertySettingsTab.feesAndPolicies,
  PropertySettingsTab.reviews,
  // TODO units
];

const businessTypeToTabs = {
  [PropertyBusinessType.HOTEL]: hotelTabs,
  [PropertyBusinessType.STANDALONE_PROPERTY]: standalonePropertyTabs,
  [PropertyBusinessType.SUB_UNIT]: standalonePropertyTabs,
  [PropertyBusinessType.UNIT]: null, // TODO implement (if needed?)
  [PropertyBusinessType.UNIT_TYPE]: unitTypeTabs,
};

const PropertySettingsTabs = ({
  property,
}: {
  property: Property | PropertySubUnit | PropertyUnitType;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const activeTabFromPathname = usePropertySettingsActiveTab();

  const [activeTab, setActiveTab] = useState<string>(activeTabFromPathname);

  useEffect(() => {
    // active tab needs to be updated when path changes due to back/forward browser navigation
    setActiveTab(activeTabFromPathname);
  }, [activeTabFromPathname]);

  const { businessType } = property;

  const tabsToRender = businessTypeToTabs[businessType];

  // Redirects to main-settings if current tab is not supported by the property business-type.
  useEffect(() => {
    if (tabsToRender && !tabsToRender.includes(activeTabFromPathname)) {
      navigate('');
    }
  }, [tabsToRender, activeTabFromPathname]);

  if (!tabsToRender) {
    return <div>Not implemented</div>;
  }

  const onSelect = (event: SyntheticEvent<TabContainer, Event>) => {
    const tabName = event as unknown as string; // should be fixed by BS5

    setActiveTab(tabName);

    if (tabName === PropertySettingsTab.mainSettings) {
      navigate('');
    } else {
      navigate(tabName);
    }
  };

  return (
    <VerticalTabContainer activeKey={activeTab} onSelect={onSelect}>
      <Row>
        <Col sm={2}>
          <Nav stacked>
            {tabsToRender.map((tabName: string) => (
              <NavItem
                data-testid={`property-settings-tab-${tabName}`}
                eventKey={tabName}
                key={tabName}
              >
                {t(normalizeKey(`pageProperty.tabNames.${tabName}`))}
              </NavItem>
            ))}
          </Nav>
        </Col>
        <Col sm={10}>
          <PropertySettingsTabHeader
            activeTab={activeTab}
            property={property}
          />
          <PropertyChannelsWidget
            propertyName={property.name}
            propertyUid={property.uid}
          />
          <Outlet />
        </Col>
      </Row>
    </VerticalTabContainer>
  );
};

export default PropertySettingsTabs;
