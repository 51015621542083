import { useEffect, useState } from 'react';
import { Button, MenuItem, SplitButton } from 'react-bootstrap';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { htmlMessageWrapperId } from 'components/editor/RichTextEditor.constants';
import { useFormContext } from 'react-hook-form';
import { MessagingChannel } from 'models/inbox/Messages';
import { normalizeKey } from 'utils/localeUtils';
import { useTranslation } from 'react-i18next';

const TemplateModalRichEditorFooterSubmitButton = ({
  isSending,
  channels,
  defaultMessagingChannel,
}: {
  isSending: boolean;
  channels: MessagingChannel[];
  defaultMessagingChannel: MessagingChannel;
}) => {
  const [editor] = useLexicalComposerContext();
  const { setValue } = useFormContext();
  const { t } = useTranslation();
  const [activeButtonId, setActiveButtonId] = useState<string>();

  function getSendButtonId(channel: MessagingChannel) {
    return `send-button-${channel}`;
  }

  useEffect(() => {
    setActiveButtonId(getSendButtonId(defaultMessagingChannel));
  }, [defaultMessagingChannel]);

  const onClick = () => {
    const htmlContent =
      editor.getRootElement().firstElementChild?.id === htmlMessageWrapperId
        ? editor.getRootElement().firstElementChild
        : editor.getRootElement();

    setValue(
      'channel',
      activeButtonId?.replace('send-button-', '') || defaultMessagingChannel,
    );
    setValue('content', htmlContent);
  };

  const buttons = channels.map((channel) => ({
    id: getSendButtonId(channel),
    disabled: isSending,
    label: t(
      isSending
        ? 'pageInbox.messages.editor.sending'
        : normalizeKey(`pageInbox.messages.editor.channels.send_${channel}`),
    ),
  }));

  const buttonsSwitcher =
    buttons.length > 1
      ? {
          initialButtonId: getSendButtonId(defaultMessagingChannel),
          items: channels.map((channel) => ({
            buttonId: getSendButtonId(channel),
            label: t(
              normalizeKey(`pageInbox.messages.editor.channels.${channel}`),
            ),
          })),
        }
      : undefined;

  const renderButtonsSwitcher = buttons?.length > 1 && !!buttonsSwitcher;
  const activeButton = renderButtonsSwitcher
    ? buttons.find(
        ({ id }) => id === (activeButtonId || buttonsSwitcher?.initialButtonId),
      )
    : buttons?.length && buttons[0];

  if (!activeButton) {
    return null;
  }

  const { id, disabled, label } = activeButton;

  if (renderButtonsSwitcher) {
    return (
      <SplitButton
        title={label}
        id={id}
        data-testid={id}
        key={id}
        bsStyle="primary"
        disabled={disabled}
        onClick={onClick}
        type="submit"
      >
        {buttonsSwitcher.items.map(({ buttonId, label: itemLabel }) => (
          <MenuItem
            key={`switcher-${buttonId}`}
            onClick={() => {
              setActiveButtonId(buttonId);
            }}
          >
            {itemLabel}
          </MenuItem>
        ))}
      </SplitButton>
    );
  }

  return (
    <Button
      id={id}
      bsStyle="primary"
      type="submit"
      data-testid={id}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default TemplateModalRichEditorFooterSubmitButton;
