import Tooltip from 'components/tooltip/Tooltip';
import { Glyphicon } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';

export const RadioAgencyLabel = () => {
  return (
    <Trans
      i18nKey="pageProperty.feesTaxesAndPolicies.rentalConditions.editModal.agencyRadio"
      components={{
        agencyLink: (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
          <a
            target="_blank"
            data-testid="page-agency-setting"
            href="#/agency-settings"
          />
        ),
      }}
    />
  );
};

export const RadioPropertyLabel = () => {
  const { t } = useTranslation();

  return (
    <div>
      {t(
        'pageProperty.feesTaxesAndPolicies.rentalConditions.editModal.propertyRadio',
      )}

      <Tooltip
        id="radio-property-tooltip"
        text={t(
          'pageProperty.feesTaxesAndPolicies.rentalConditions.editModal.propertyRadioTooltip',
        )}
      >
        <Glyphicon glyph="info-sign" />
      </Tooltip>
    </div>
  );
};
