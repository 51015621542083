import * as Yup from 'yup';
import { TFunction } from 'react-i18next';

const passwordSchema = (t: TFunction) => ({
  // should be equal to newPassword
  newPasswordRepeated: Yup.string()
    .oneOf(
      [Yup.ref('newPassword')],
      t('componentPasswordModal.passwordMismatch'),
    )
    .required(),
  newPassword: Yup.string().required(),
});

export default passwordSchema;
