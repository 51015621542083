const OrangePaintSvg = () => {
  return (
    <svg
      width="296"
      height="698"
      viewBox="0 0 296 698"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M72.183 515.392C268.404 672.725 544.022 766.145 655.131 634.728C766.24 503.311 729.897 141.637 621.699 54.312C513.5 -33.0133 443.146 -4.89683 284.458 75.633C125.77 156.163 -124.039 358.059 72.183 515.392Z"
        fill="#FE7254"
      />
    </svg>
  );
};

export default OrangePaintSvg;
