import { useTranslation } from 'react-i18next';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import API from 'services/API';
import {
  OwnerAdjustmentFileResponse,
  PostOwnerAdjustmentFileResponseTO,
} from 'models/ownerAdjustments/OwnerAdjustmentFile';

const uploadOwnerAdjustmentFile = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await API.post<PostOwnerAdjustmentFileResponseTO>(
    '/api/internal/owner-adjustments/owner-adjustment-file',
    formData,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    },
  );

  return response.data.ownerAdjustmentFile;
};

const useUploadFileMutation = ({
  onError,
  onSuccess,
}: {
  onSuccess: (arg: OwnerAdjustmentFileResponse) => void;
  onError: () => void;
}) => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  return useAppMutation({
    mutationFn: uploadOwnerAdjustmentFile,
    onSuccess,
    onError: () => {
      onError();
      notifyError(t('pageOwnerAdjustments.modal.errorOnFileUpload'));
    },
  });
};

export default useUploadFileMutation;
