import { useCallback } from 'react';
import { Lead, LeadAvailablePipelineAction } from 'models/Leads';
import { openInboxForLead } from 'utils/inbox/inboxUtils';

const useLeadPipelineActionCustomHandler = () => {
  return useCallback(
    ({ action, lead }: { action: LeadAvailablePipelineAction; lead: Lead }) => {
      if (action === LeadAvailablePipelineAction.OPEN_INBOX) {
        const { uid: leadUid } = lead;

        openInboxForLead(leadUid);

        return;
      }

      throw new Error(`Not implemented lead action: ${action}`);
    },
    [],
  );
};

export default useLeadPipelineActionCustomHandler;
