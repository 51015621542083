import useAppQuery from 'hooks/useAppQuery';
import useAppUser from 'hooks/useAppUser';
import API from 'services/API';
import { PricingPropertyResponse } from './PropertyPricingModal.types';

const usePropertiesWithDynamicPricingEnabled = (propertyUids: string[]) => {
  const { agencyUid, isEmployee } = useAppUser();

  const { data: propertiesWithDynamicPricing, isLoading: isPropertiesLoading } =
    useAppQuery(
      ['properties-with-dynamic-pricing', agencyUid],
      async () => {
        const uids = propertyUids.join(',');

        const response = await API.post<PricingPropertyResponse>(
          '/v3/graphql',
          {
            query: `{properties (agencyUid:"${agencyUid}", propertyUids:"${uids}") {uid,name,pricing{automatedPricingEnabled}}}`,
            variables: {},
          },
        );

        return response.data.data.properties.filter(
          (p) => p.pricing.automatedPricingEnabled,
        );
      },
      { enabled: isEmployee },
    );

  return {
    isPropertiesLoading,
    propertiesWithDynamicPricing,
  };
};

export default usePropertiesWithDynamicPricingEnabled;
