import { useTranslation } from 'react-i18next';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import API from 'services/API';
import {
  PropertyPhotoFile,
  PropertyPhotoFilesResponseTO,
} from './PropertyDetails.types';

const uploadFile = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await API.post<PropertyPhotoFilesResponseTO>(
    '/v3/property-photo-files',
    formData,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    },
  );

  return response.data?.files?.[0];
};

const useUploadFileMutation = ({
  onError,
  onSuccess,
}: {
  onSuccess: (arg: PropertyPhotoFile) => void;
  onError: () => void;
}) => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  return useAppMutation({
    mutationFn: uploadFile,
    onSuccess,
    onError: () => {
      onError();
      notifyError(
        t('pageProperty.mainSettings.propertyDetails.errorOnFileUpload'),
      );
    },
  });
};

export default useUploadFileMutation;
