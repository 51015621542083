import { useQueryClient } from '@tanstack/react-query';
import useAppUser from 'hooks/useAppUser';
import { PROPERTY_SETTINGS_QUERY_OPERATION_NAME } from 'pages/property/usePropertySettingsInitData';
import getPropertiesQuery from 'utils/property/getPropertiesQuery';
import { ALL_PROPERTIES_BASE_QUERY_KEY } from '../useFetchAllProperties';

export const useUpdateFetchPropertyQuery = ({
  propertyUid,
}: {
  propertyUid: string;
}) => {
  const { agencyUid } = useAppUser();
  const queryClient = useQueryClient();
  const queryKey = [
    ALL_PROPERTIES_BASE_QUERY_KEY,
    PROPERTY_SETTINGS_QUERY_OPERATION_NAME,
    agencyUid,
    getPropertiesQuery({
      filters: { propertyUids: [propertyUid], topLevelOnly: false },
    }),
  ];

  const updateFetchPropertyQuery = () => {
    queryClient.invalidateQueries(queryKey);
  };

  return { updateFetchPropertyQuery };
};
