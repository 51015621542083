import { useMemo } from 'react';
import {
  TableOptions,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

type Options<T> = Pick<TableOptions<T>, 'columns' | 'data'> &
  Partial<Omit<TableOptions<T>, 'columns' | 'data'>>;

const useTable = <T>({ state, ...options }: Partial<Options<T>>) => {
  const hiddenColumns = options.columns.filter(({ meta }) => meta?.isHidden);

  const columnVisibility = useMemo(() => {
    return hiddenColumns.reduce((result, current) => {
      return { ...result, [current.id]: false };
    }, {});
  }, [hiddenColumns.map((column) => column.id).join(',')]);

  const tableInstance = useReactTable({
    columns: [],
    data: [],
    state: {
      columnVisibility: state?.columnVisibility ?? columnVisibility,
      ...(state ?? {}),
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    ...options,
  });

  return tableInstance;
};

export default useTable;
