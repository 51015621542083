import useAppConfig from './useAppConfig';
import useCssStylesheet from './useCssStylesheet';

const useAppPublicCustomisationsApply = () => {
  const {
    customSettings: { customCssUrl },
  } = useAppConfig();

  useCssStylesheet(customCssUrl, 'hf-custom-settings-css');
};

export default useAppPublicCustomisationsApply;
