import { useEffect } from 'react';
import useAppEvent from 'hooks/useAppEvent';
import { AppEventType, OpenCreateLeadModalEventPayload } from 'models/Events';
import useOpenLeadModal from '../modal/useOpenLeadModal';

const useOpenCreateLeadModalEventHandler = () => {
  const { subscribe, unsubscribe } = useAppEvent();
  const { createLeadModal } = useOpenLeadModal();

  useEffect(() => {
    const openModalListener = ({
      detail: { params },
    }: CustomEvent<OpenCreateLeadModalEventPayload>) => {
      createLeadModal(params);
    };

    subscribe(AppEventType.OPEN_CREATE_LEAD_MODAL, openModalListener);

    return () => {
      unsubscribe(AppEventType.OPEN_CREATE_LEAD_MODAL, openModalListener);
    };
  }, []);
};

export default useOpenCreateLeadModalEventHandler;
