import useFetchAssignmentSettingsWithEmployees from 'components/domain/property/useFetchAssignmentSettingsWithEmployees';
import { AssignmentSettingsAssignee } from 'models/AssignmentSettings';
import { FormValues } from './PropertyPermissionModal.types';

/**
 * Returns a function that can extract the assignment settings given the
 * permissions modal form values.
 */
const useExtractAssignmentSettings = (propertyUid: string) => {
  const { data: currentAssignmentSettings } =
    useFetchAssignmentSettingsWithEmployees({ propertyUid });

  const extractAssignmentSettings = (values: FormValues) => {
    const automaticAssignment =
      values.autoAssignLeads && values.autoAssignLeadsTo
        ? { enabled: true, employeeUid: values.autoAssignLeadsTo }
        : { enabled: false, employeeUid: null };

    const assignees = Object.entries(values).reduce<
      AssignmentSettingsAssignee[]
    >((res, [key, value]) => {
      if (!key.startsWith('employeeUid-')) {
        return res;
      }

      const [, employeeUid] = key.split('employeeUid-');
      const editable = currentAssignmentSettings?.assignees?.find(
        (a) => a.employeeUid === employeeUid,
      )?.editable;

      return [...res, { employeeUid, enabled: !!value, editable }];
    }, []);

    return { assignees, automaticAssignment };
  };

  return { extractAssignmentSettings };
};

export default useExtractAssignmentSettings;
