import useAppSelector from '../../hooks/useAppSelector';

const useStackedCalendarPropertyDayData = ({
  daysMapKey,
  propertyUid,
}: {
  daysMapKey: string;
  propertyUid: string;
}) => {
  return useAppSelector(
    (state) =>
      state.stackedCalendar.propertiesEntries[propertyUid]?.[daysMapKey],
  );
};

export default useStackedCalendarPropertyDayData;
