import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import mediaQuery from '../../styles/mediaQuery';

export const Container = styled.div.attrs({
  className: 'container',
})`
  select[name='currency'] {
    padding-right: 0 !important;
  }
`;

export const ChartsAndPiesWrapper = styled(Row)`
  ${mediaQuery.xs} {
    flex-direction: column-reverse;
    display: flex;
  }
`;
