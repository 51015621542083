import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'components/loader/Loader';
import useRefreshOnLeadEvents from 'components/domain/lead/useRefreshOnLeadEvents';
import usePipelineLeadsListData from './usePipelineLeadsListData';
import {
  PipelineErrorMessage,
  PipelineLeadsListContainer,
  PipelineLeadsListEndMessage,
} from './PipelineLeadsList.styles';
import PipelineLeadListItem from './PipelineLeadListItem';
import PipelineLeadsListLoader from './loader/PipelineLeadsListLoader';

const PipelineLeadsList = () => {
  const {
    error,
    isInitialFetching,
    hasMoreLeads,
    leads,
    loadMoreLeads,
    refetch,
  } = usePipelineLeadsListData();
  const { t } = useTranslation();

  useRefreshOnLeadEvents({ refreshFn: refetch });

  if (error) {
    return (
      <PipelineErrorMessage>
        {t('common.apiResponses.internalServerError')}
      </PipelineErrorMessage>
    );
  }

  if (isInitialFetching) {
    return <PipelineLeadsListLoader />;
  }

  return (
    <PipelineLeadsListContainer>
      <InfiniteScroll
        dataLength={leads.length}
        next={loadMoreLeads}
        hasMore={hasMoreLeads}
        loader={<Loader />}
        scrollableTarget="app-page-content"
        endMessage={
          <PipelineLeadsListEndMessage>
            {!!leads.length && t('pagePipeline.noMoreLeads')}
            {!leads.length && t('pagePipeline.noLeadsFound')}
          </PipelineLeadsListEndMessage>
        }
      >
        {leads?.map((lead) => (
          <PipelineLeadListItem key={lead.uid} lead={lead} />
        ))}
      </InfiniteScroll>
    </PipelineLeadsListContainer>
  );
};

export default PipelineLeadsList;
