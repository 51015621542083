import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NotFound from 'pages/notFound/NotFound';
import useSearchURLParams from 'hooks/useSearchURLParams';

const OrderViewLegacyAdapter = () => {
  const navigate = useNavigate();

  const { getParameter } = useSearchURLParams();
  const orderUid = getParameter('o');

  if (!orderUid) {
    return <NotFound />;
  }

  useEffect(() => {
    if (orderUid) {
      navigate(`/order/${orderUid}`);
    }
  }, [orderUid]);

  return null;
};

export default OrderViewLegacyAdapter;
