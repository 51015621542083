import { useContext } from 'react';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { useTranslation } from 'react-i18next';
import CalendarContext from '../CalendarContext';
import useCalendarLoadedPropertiesNumber from '../propertiesList/useCalendarLoadedPropertiesNumber';
import {
  NavigationPropertyCellStyled,
  NavigationPropertyPlaceholder,
} from './StackedCalendarNavigation.styles';

const NavigationPropertyCell = () => {
  const { totalPropertiesNumber } = useContext(CalendarContext);
  const loadedPropertiesNumber = useCalendarLoadedPropertiesNumber();
  const { t } = useTranslation();

  if (totalPropertiesNumber === -1) {
    return (
      <NavigationPropertyPlaceholder>
        <LoadingPlaceholder width="75%" linesCount={1.2} />
      </NavigationPropertyPlaceholder>
    );
  }

  return (
    <NavigationPropertyCellStyled>
      {t('pageCalendar.stacked.propertiesCount', {
        current: loadedPropertiesNumber,
        total: totalPropertiesNumber,
      })}
    </NavigationPropertyCellStyled>
  );
};

export default NavigationPropertyCell;
