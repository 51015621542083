import { memo } from 'react';
import { getDayCellCommonClasses } from 'pages/calendar/common/cells/DayCellCommon.styles';
import { stackedCalendarStyleConstants } from '../../Calendar.constants';
import { StackedCalendarMode } from '../../Calendar.types';
import DayCell from './DayCell';
import GrayedOutDayCell from './GrayedOutDayCell';
import LoadingDayCell from './LoadingDayCell';
import useDayCellData from './useDayCellData';

const DayCellRenderer = memo(
  ({
    dayDate,
    mode,
    propertyUid,
    isUnitType,
    isUnit,
  }: {
    dayDate: Date;
    mode: StackedCalendarMode;
    propertyUid: string;
    isUnitType: boolean;
    isUnit: boolean;
  }) => {
    const { dayData, leads, jobs } = useDayCellData({ dayDate, propertyUid });
    const { isLoading, isWeekend, isGrayedOut } = dayData;

    if (isGrayedOut || (mode === StackedCalendarMode.BOOKING && isUnitType)) {
      return (
        <GrayedOutDayCell
          className={getDayCellCommonClasses(dayDate)}
          dayTimestamp={dayDate.getTime()}
          isWeekend={isWeekend}
          width={stackedCalendarStyleConstants.daysCellWidth[mode]}
        />
      );
    }

    if (isLoading) {
      return (
        <LoadingDayCell
          dayTimestamp={dayDate.getTime()}
          isWeekend={isWeekend}
          width={stackedCalendarStyleConstants.daysCellWidth[mode]}
        />
      );
    }

    return (
      <DayCell
        dayData={dayData}
        dayDate={dayDate}
        leads={leads}
        jobs={jobs}
        mode={mode}
        propertyUid={propertyUid}
        isUnitType={isUnitType}
        isUnit={isUnit}
      />
    );
  },
);

export default DayCellRenderer;
