import { i18nLanguageToLocaleCode } from './constants/languages';

export function getLanguageCode(localeCode: string | undefined) {
  return localeCode?.split(/[-_]/)[0];
}

export function getCountryCode(localeCode: string | undefined) {
  const parts = localeCode?.split(/[-_]/);

  return parts?.length > 1 ? parts[1] : null;
}

export function getAvailableI18nLanguage(localeCode) {
  return (
    i18nLanguageToLocaleCode[getLanguageCode(localeCode)] ||
    i18nLanguageToLocaleCode.en
  );
}
