import styled from 'styled-components';
import theme from 'styles/theme';

export const WeekDayListItemStyled = styled.li<{ $isChecked: boolean }>`
  background-color: ${({ $isChecked }) =>
    $isChecked ? theme.colors.primary : 'transparent'};
  padding: 0 8px;

  .checkbox {
    margin: 0;

    label {
      color: ${({ $isChecked }) =>
        $isChecked ? '#FFFFFF' : theme.colors.fontDark};
      padding-bottom: 8px;
      padding-top: 8px;
      width: 100%;
    }
  }
`;
