import { useLocation } from 'react-router-dom';
import {
  PropertySettingsTab,
  PropertySettingsTabType,
} from './PropertySettingsTabs.constants';

const usePropertySettingsActiveTab = (): PropertySettingsTabType => {
  const { pathname } = useLocation();

  return (
    Object.values(PropertySettingsTab).find((tab) =>
      pathname.endsWith(`/${tab}`),
    ) ?? PropertySettingsTab.mainSettings
  );
};

export default usePropertySettingsActiveTab;
