import NotFound from '../notFound/NotFound';
import usePropertySettingsInitData from './usePropertySettingsInitData';
import PropertyLoader from './PropertyLoader';
import PropertySettingsTabs from './PropertySettingsTabs';

const Property = () => {
  const { isLoading, property } = usePropertySettingsInitData();

  if (isLoading) {
    return <PropertyLoader />;
  }

  if (!property || !Object.keys(property).length) {
    return <NotFound />;
  }

  return (
    <div className="container">
      <PropertySettingsTabs property={property} />
    </div>
  );
};

export default Property;
