import { ReactNode, useContext, useMemo } from 'react';
import FormWithProvider from 'components/forms/form/Form';
import useLocalStorage from 'hooks/useLocalStorage';
import InboxContext from '../InboxContext';
import { getFormDefaultValues, InboxFilterSchema } from './InboxFilter.schema';
import { getUpdatedThreadFilter } from './InboxFilter.utils';

interface InboxFilterFormProviderProps {
  children?: ReactNode;
}

const InboxFilterFormProvider = ({
  children,
}: InboxFilterFormProviderProps) => {
  const { inboxFilterLocalStorageKey, updateThreadFilter } =
    useContext(InboxContext);
  const { setValue: storeFilterFormValue } = useLocalStorage(
    inboxFilterLocalStorageKey,
  );
  const defaultValues = useMemo(() => getFormDefaultValues(), []);
  const handleSubmit = (formValues) => {
    storeFilterFormValue(
      InboxFilterSchema.cast(formValues, { stripUnknown: true }),
    );

    updateThreadFilter(getUpdatedThreadFilter(formValues));
  };

  return (
    <FormWithProvider
      data-testid="thread-filter-form"
      horizontal
      defaultValues={defaultValues}
      schema={{}}
      onSubmit={handleSubmit}
    >
      {children}
    </FormWithProvider>
  );
};

InboxFilterFormProvider.defaultProps = {
  children: undefined,
};

export default InboxFilterFormProvider;
