import { useTranslation } from 'react-i18next';
import { useFetchPropertiesFilterOptions } from 'components/domain/property/useFetchPropertiesFilterOptions';
import SelectField from 'components/forms/selectField/SelectField';
import { SelectFieldProps } from 'components/forms/selectField/SelectField.types';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { PropertiesFilterOptions } from 'models/PropertiesFilter';

interface TypeSelectFieldProps extends SelectFieldProps {
  filterOptions?: PropertiesFilterOptions;
}

const TypeSelectField = ({
  filterOptions: filterOptionsProp,
  ...rest
}: TypeSelectFieldProps) => {
  const { t } = useTranslation();
  const { data, isLoading } = useFetchPropertiesFilterOptions({
    enabled: !filterOptionsProp,
  });
  const filterOptions = filterOptionsProp ?? data;

  if (isLoading) return <LoadingPlaceholder />;

  return (
    <SelectField
      colSmInput={8}
      colSmLabel={4}
      label={t('pageCalendar.stacked.type')}
      name="type"
      options={[
        { label: t('pageCalendar.stacked.all'), value: '' },
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...filterOptions?.propertyTypes.map((type) => ({
          label: type,
          value: type,
        })),
      ]}
      {...rest}
    />
  );
};

TypeSelectField.defaultProps = {
  filterOptions: undefined,
};

export default TypeSelectField;
