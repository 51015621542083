import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';
import theme from 'styles/theme';

export const OrderViewDetailsWrapper = styled.div`
  border: 1px solid ${theme.colors.gray300};
  border-radius: 5px;
  margin-bottom: 1rem;
  display: flex;
  padding: 1.5em 0;
  margin-bottom: 32px;

  > div {
    padding: 0 1.5em;
  }

  > div:first-child {
    border-right: 1px solid ${theme.colors.gray300};
    flex: 0 0 50%;
  }

  > div:last-child {
    display: flex;
    align-items: center;
    flex: 0 0 50%;
  }

  ${mediaQuery.xs || mediaQuery.md} {
    flex-direction: column;
    padding: 0;

    > div {
      padding: 1em;
    }

    > div:first-child {
      border-bottom: 1px solid ${theme.colors.gray300};
      flex: 0 0 100%;
    }

    > div:last-child {
      flex: 0 0 100%;
    }
  }
`;

export const OrderViewDetailsItemList = styled.div`
  width: 100%;
`;

export const OrderViewDetailsItem = styled(Row)`
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export const OrderViewDetailsLeadWrapper = styled.div`
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 5px;
  &:hover {
    background: ${theme.colors.bgHover};
  }
`;
