import { useTranslation } from 'react-i18next';
import { Lead } from 'models/Leads';
import useScreenBreakpoints from 'hooks/useScreenBreakpoints';
import {
  RiskScoreTag,
  RiskScoreTagGlyphicon,
} from './LeadListItemRiskScoreTag.styles';

const LeadListItemRiskScoreTag = ({ lead }: { lead: Lead }) => {
  const { t } = useTranslation();
  const { isXS } = useScreenBreakpoints();

  if (!lead?.riskScore || lead?.riskScore === 0 || isXS) {
    return null;
  }

  return (
    <RiskScoreTag
      className="risk-score-label"
      data-testid="risk-score-label"
      $riskScore={lead?.riskScore}
    >
      <RiskScoreTagGlyphicon $riskScore={lead?.riskScore} />
      {t('componentLead.details.riskScore', { riskScore: lead?.riskScore })}
    </RiskScoreTag>
  );
};

export default LeadListItemRiskScoreTag;
