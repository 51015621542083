import useAppUser from 'hooks/useAppUser';
import { Property } from 'models/Properties';
import { Glyphicon } from 'react-bootstrap';
import { getPropertyEditPath } from 'utils/property/propertyUtils';

const PropertyListItemEditIcon = ({ property }: { property: Property }) => {
  const { isOwner } = useAppUser();

  if (isOwner) return null;

  return (
    <a
      data-testid="property-edit-icon"
      className="btn btn-link property-edit-icon"
      href={getPropertyEditPath(property)}
    >
      <Glyphicon glyph="pencil" />
    </a>
  );
};

export default PropertyListItemEditIcon;
