import { useTranslation } from 'react-i18next';
import { Transaction } from 'models/Transactions';
import { TransactionDetailsMultilineWrapper } from './OrderViewTransactionsListItem.styles';

const OrderViewTransactionsListItemDetails = ({
  transaction,
}: {
  transaction: Transaction;
}) => {
  const { t } = useTranslation();
  const { errorMessage, notes, transactionId } = transaction;

  if (errorMessage) {
    return t('pageOrder.transactions.errorMessage', { errorMessage });
  }

  if (notes && transactionId) {
    return (
      <TransactionDetailsMultilineWrapper>
        <div data-testid="transaction-id">
          {t('pageOrder.transactions.transactionId', { transactionId })}
        </div>
        <div data-testid="notes">
          {t('pageOrder.transactions.notes', { notes })}
        </div>
      </TransactionDetailsMultilineWrapper>
    );
  }

  if (notes) {
    return t('pageOrder.transactions.notes', { notes });
  }

  if (transactionId) {
    return t('pageOrder.transactions.transactionId', { transactionId });
  }

  return null;
};

export default OrderViewTransactionsListItemDetails;
