import { ModalBody, Row } from 'react-bootstrap';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import FormWithProvider from 'components/forms/form/Form';
import { PropertyExpectationType } from 'models/PropertyExpectations';
import { PropertyExpectationAddEditModalParams } from './PropertyExpectationAddEditModal.types';
import PropertyExpectationAddEditModalDescriptionField from './PropertyExpectationAddEditModalDescriptionField';
import PropertyExpectationAddEditModalFooter from './PropertyExpectationAddEditModalFooter';
import PropertyExpectationAddEditModalTypeField from './PropertyExpectationAddEditModalTypeField';
import PropertyExpectationAddEditModalAppliesToField from './PropertyExpectationAddEditModalAppliesToField';
import { propertyExpectationAddEditFormSchema } from './PropertyExpectationAddEditModal.schema';
import usePropertyExpectationAddEditFormSubmit from './usePropertyExpectationAddEditFormSubmit';

const PropertyExpectationAddEditModal = ({
  modalId,
  params,
}: {
  modalId: string;
  params: PropertyExpectationAddEditModalParams;
}) => {
  const {
    propertyExpectation,
    property: { name: propertyName, uid: propertyUid },
  } = params;

  const { isUpdating, onSubmit } = usePropertyExpectationAddEditFormSubmit({
    modalId,
    propertyUid,
  });

  const defaultValues = {
    appliesTo: PROPERTIES_SELECTION.SELECTED_PROPERTIES,
    appliesToProperties: [
      {
        isFixed: true,
        label: propertyName,
        value: propertyUid,
      },
    ],
    appliesToStatus: undefined,
    appliesToTags: undefined,
    propertyExpectation: propertyExpectation || {
      expectationType: PropertyExpectationType.REQUIRES_STAIRS,
      objectUid: propertyUid,
    },
  };

  return (
    <FormWithProvider
      defaultValues={defaultValues}
      disabled={isUpdating}
      onSubmit={onSubmit}
      schema={propertyExpectationAddEditFormSchema()}
      noValidate
    >
      <fieldset disabled={isUpdating}>
        <ModalBody>
          <Row className="d-flex flex-column">
            <PropertyExpectationAddEditModalTypeField />
            <PropertyExpectationAddEditModalDescriptionField />
            <PropertyExpectationAddEditModalAppliesToField />
          </Row>
        </ModalBody>
        <PropertyExpectationAddEditModalFooter modalId={modalId} />
      </fieldset>
    </FormWithProvider>
  );
};

export default PropertyExpectationAddEditModal;
