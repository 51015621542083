import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { channelToIconMapping } from 'components/domain/lead/LeadChannelIcon';
import {
  getColorByLeadSource,
  getLeadCheckInUtcDate,
  getLeadCheckOutUtcDate,
} from 'utils/lead/leadUtils';
import useOpenLeadModal from 'components/domain/lead/modal/useOpenLeadModal';
import Tooltip from 'components/tooltip/Tooltip';
import { LeadStatus, LeadType, SourceColor } from 'models/Leads';
import { LeadListItemProps } from 'pages/dashboard/DashboardLead.types';
import LeadListItemBasicInfo from 'components/domain/lead/list/LeadListItemBasicInfo';
import LeadListItemPriceTag from 'components/domain/lead/list/LeadListItemPriceTag';
import LeadListItemViewButton from 'components/domain/lead/list/LeadListItemViewButton';
import LeadListItemRiskScoreTag from 'components/domain/lead/list/LeadListItemRiskScoreTag';
import theme from 'styles/theme';
import {
  ActionsWrapper,
  AnchorTag,
  ButtonTag,
  ChannelWrapper,
  Container,
  Content,
  LeadHeader,
  LeftSide,
  RightSide,
  StatusIconWrapper,
  StatusTag,
  TagsWrapper,
  TopSide,
} from './LeadListItem.styles';
import LeadStatusIcon from './leadIcons/LeadStatusIcon';

const LeadListItem = ({ leadData }: LeadListItemProps) => {
  const {
    uid,
    status,
    channel,
    createdDateTimeString,
    onSign,
    onIgnore,
    assignee,
    leadType,
    paidInFull,
    referrer,
    disableActions,
    agreementSigned,
    hasCompletedPreArrivalForm,
    isLowOpacity,
    showStatusRing,
    showGuestCounter,
    showBookAndInquiryHeader,
    showCheckinInHeader,
    showCheckoutInHeader,
    showLeadStatusTags,
    showLeadStatusIcons,
    showAssigneePhoto,
    showProcessActions,
    showAssignToMe,
    showMessageAction,
  } = leadData;

  const { t } = useTranslation();
  const { updateLeadModal } = useOpenLeadModal();

  const checkInDate = getLeadCheckInUtcDate(leadData);
  const checkOutDate = getLeadCheckOutUtcDate(leadData);
  const createdDate = new Date(createdDateTimeString);

  const isInquiry = leadType === LeadType.INQUIRY;

  const channelColor = getColorByLeadSource(leadData.source);
  const referrerColor = SourceColor.DEFAULT;

  const leadHeader = {
    BOOKING: (
      <>
        {t('componentDashboardLead.bookingTypeHeader', {
          date: createdDate,
        })}{' '}
        {referrer && <span>{t('componentDashboardLead.on')} </span>}
        {referrer && <span style={{ color: referrerColor }}>{referrer} </span>}
        {t('componentDashboardLead.via')}
      </>
    ),

    INQUIRY: (
      <>
        {t('componentDashboardLead.inquiryTypeHeader', {
          date: createdDate,
        })}{' '}
        {referrer && <span>{t('componentDashboardLead.on')} </span>}
        {referrer && <span style={{ color: referrerColor }}>{referrer} </span>}
        {t('componentDashboardLead.via')}
      </>
    ),
  };

  const pendingLeadHeader = {
    BOOKING: (
      <>
        {t('componentDashboardLead.pendingBookingTypeHeader', {
          date: createdDate,
        })}{' '}
        {referrer && <span>{t('componentDashboardLead.on')} </span>}
        {referrer && <span style={{ color: referrerColor }}>{referrer} </span>}
        {t('componentDashboardLead.via')}
      </>
    ),
  };

  const viewLeadClick = (event: MouseEvent<HTMLDivElement>) => {
    if ((event.target as Element).closest('.lead-view-button')) {
      return;
    }

    updateLeadModal({ leadUid: uid });
  };

  const isStatusPending = status === LeadStatus.PENDING;
  const isStatusDuplicate = status === LeadStatus.DUPLICATE;

  const isIgnoreActionEnabled =
    isInquiry &&
    showProcessActions &&
    (status === LeadStatus.NEW || status === LeadStatus.QUOTE_SENT);

  return (
    <Container
      data-testid={`dashboard-lead-uid-${uid}`}
      isLowOpacity={isLowOpacity}
    >
      <TopSide>
        <LeadHeader>
          {showBookAndInquiryHeader && (
            <p data-testid="dashboard-lead-header-new-mode">
              {isStatusPending ? (
                <span>{pendingLeadHeader.BOOKING}</span>
              ) : (
                <span>{leadHeader?.[leadType]}</span>
              )}{' '}
              <ChannelWrapper channelColor={channelColor}>
                {channelToIconMapping[channel?.channel]}
                {channel?.bestProfile}
              </ChannelWrapper>
            </p>
          )}

          {showCheckinInHeader && (
            <p
              data-testid="dashboard-lead-header-checkin-mode"
              className="checkin-header"
            >
              <ChannelWrapper channelColor={channelColor}>
                {channelToIconMapping[channel?.channel]}
              </ChannelWrapper>
              {t('componentDashboardLead.checkinTypeHeader', {
                date: checkInDate,
              })}
            </p>
          )}

          {showCheckoutInHeader && (
            <p
              data-testid="dashboard-lead-header-checkout-mode"
              className="checkin-header"
            >
              <ChannelWrapper channelColor={channelColor}>
                {channelToIconMapping[channel?.channel]}
              </ChannelWrapper>
              {t('componentDashboardLead.checkoutTypeHeader', {
                date: checkOutDate,
              })}
            </p>
          )}
        </LeadHeader>

        <div>
          {showLeadStatusTags && (
            <TagsWrapper data-testid="dashboard-lead-tags-container">
              {isStatusPending && (
                <StatusTag backgroundColor="#FF7254">
                  {t('componentDashboardLead.pending')}
                </StatusTag>
              )}
              {isStatusDuplicate && (
                <StatusTag backgroundColor="#2D2AA5">
                  {t('componentDashboardLead.duplicate')}
                </StatusTag>
              )}
              <LeadListItemPriceTag lead={leadData} />
            </TagsWrapper>
          )}

          {showLeadStatusIcons && (
            <StatusIconWrapper data-testid="dashboard-lead-status-icons-container">
              <Tooltip
                id="lead-tick-icon-tooltip"
                text={t('componentDashboardLead.preArrivalIconMessage')}
              >
                <LeadStatusIcon
                  id="lead-tick-icon"
                  statusColor={
                    hasCompletedPreArrivalForm ? 'success' : 'neutral'
                  }
                  type="tick"
                />
              </Tooltip>
              <Tooltip
                id="lead-rental-icon-tooltip"
                text={t('componentDashboardLead.renatlIconMessage')}
              >
                <LeadStatusIcon
                  id="lead-rental-icon"
                  statusColor={agreementSigned ? 'success' : 'neutral'}
                  type="rental"
                />
              </Tooltip>
              <Tooltip
                id="lead-dollar-icon-tooltip"
                text={t('componentDashboardLead.paymentIconMessage')}
              >
                <LeadStatusIcon
                  id="lead-dollar-icon"
                  statusColor={paidInFull ? 'success' : 'warning'}
                  type="dollar"
                />
              </Tooltip>
            </StatusIconWrapper>
          )}
        </div>
      </TopSide>
      <Content>
        <LeftSide onClick={viewLeadClick}>
          <LeadListItemBasicInfo
            lead={leadData}
            showGuestCounter={showGuestCounter}
            showStatusRing={showStatusRing}
          />
        </LeftSide>

        <RightSide>
          <ActionsWrapper data-testid="dashboard-lead-action-container">
            <div className="dashboard-lead-action-container-img-wrapper show-not-on-hover">
              {!!assignee?.uid && showAssigneePhoto && assignee?.picture && (
                <img
                  data-testid="dashboard-lead-assignee-picture"
                  src={assignee?.picture}
                  alt={assignee?.bestProfile}
                />
              )}
              {!showLeadStatusIcons && (
                <LeadListItemRiskScoreTag lead={leadData} />
              )}
            </div>

            <div className="show-on-hover">
              {!assignee?.uid && showAssignToMe && (
                <ButtonTag
                  data-testid="dashboard-lead-assignee-button"
                  onClick={() => onSign(uid)}
                  backgroundColor="#8a5bda"
                  disabled={disableActions}
                >
                  {t('componentDashboardLead.assignToMe')}
                </ButtonTag>
              )}

              <LeadListItemViewButton
                backgroundColor={
                  showLeadStatusTags ? undefined : theme.colors.bgGreen
                }
                lead={leadData}
              />

              {isIgnoreActionEnabled && (
                <ButtonTag
                  data-testid="dashboard-lead-ignore-button"
                  onClick={() => onIgnore(uid)}
                  backgroundColor="#6c757d"
                  disabled={disableActions}
                >
                  {t('componentDashboardLead.ignore')}
                </ButtonTag>
              )}

              {showMessageAction && (
                <AnchorTag
                  data-testid="dashboard-lead-message-button"
                  href={`#/inbox?leadUid=${uid}`}
                  target="_blank"
                  rel="noreferrer"
                  backgroundColor="#6d747d"
                >
                  {t('componentDashboardLead.message')}
                </AnchorTag>
              )}
            </div>
          </ActionsWrapper>
        </RightSide>
      </Content>
    </Container>
  );
};

export default LeadListItem;
