import * as Yup from 'yup';

const jobSchema = () => ({
  property: Yup.string(),
  service: Yup.string(),
  date: Yup.date(),
  startTime: Yup.date(),
  endTime: Yup.date(),
  notes: Yup.string(),
});

export default jobSchema;
