import PageHeader from 'components/pageHeader/PageHeader';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Container } from '../OrderView.styles';

const OrderViewError = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <PageHeader title={t('pageOrder.orderForWithoutLead')} />

      <Row>
        <Col xs={12}>
          <div
            className="alert alert-warning"
            role="alert"
            data-testid="error-message"
          >
            {t('pageOrder.error.orderNotFound')}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default OrderViewError;
