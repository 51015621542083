const LightBluePaintSvg = () => {
  return (
    <svg
      width="413"
      height="339"
      viewBox="0 0 413 339"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.83355 66.4231C-7.15641 182.132 43.0322 317.605 144.655 336.36C246.278 355.115 407.834 263.161 412.514 199.236C417.194 135.312 379.369 111.752 285.815 62.2387C192.261 12.7258 8.82347 -49.286 0.83355 66.4231Z"
        fill="#54E0FF"
      />
    </svg>
  );
};

export default LightBluePaintSvg;
