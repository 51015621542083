import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const ChartsFilterFormWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .nav {
    display: flex;
    flex: 1 0 100%;

    .pager {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .pager::before,
    .pager::after {
      content: none;
    }

    .pager .previous > button,
    .pager .previous > span {
      float: left;
    }

    .pager li > button,
    .pager li > span {
      display: inline-block;
      padding: 5px 14px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 15px;
    }
  }

  .btn-chart-type,
  .btn-chart-metric {
    width: auto;
    border: 0;
    box-shadow: none;
    padding: 0;
    color: #737373;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='black'><polygon points='0,0 100,0 50,50'/></svg>")
      no-repeat;
    background-repeat: no-repeat;
    background-color: transparent;
    width: auto;
    padding-right: 30px;
  }

  .btn-chart-type {
    background-size: 12px;
    background-position: calc(100% - 9px) calc(50% + 4px);
  }

  .btn-chart-metric {
    background-position: calc(100% - 11px) calc(50% + 3px);
    background-size: 8px;
  }

  .btn-chart-type {
    font-size: 24px;
  }

  ${mediaQuery.xs} {
    flex-direction: column;

    .form-group {
      margin-bottom: 0px;
    }
  }
`;
