import FormWithProvider from 'components/forms/form/Form';
import styled from 'styled-components';

export const FormContainer = styled(FormWithProvider)`
  padding: 10px;

  .form-field-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  label {
    min-width: 110px;
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .password-modal-actions {
      margin-left: auto;
    }
  }
`;

export const WarningMessage = styled.div.attrs({
  className: 'alert alert-danger',
  role: 'alert',
  'data-testid': 'password-modal-warning-message',
})`
  padding: 8px 16px;
  margin-bottom: 0;
`;
