import { useTranslation } from 'react-i18next';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import API from 'services/API';
import {
  CreateOrderAdjustmentResponseTO,
  OrderAdjustmentCreationPayload,
} from 'models/OrderAdjustments';

const useUpdateOrderAdjustment = ({ orderUid }: { orderUid: string }) => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  const { mutate: updateOrderAdjustment, isLoading } = useAppMutation(
    ({
      orderAdjustment,
      uid,
    }: {
      successCallback: (response: CreateOrderAdjustmentResponseTO) => void;
      orderAdjustment: OrderAdjustmentCreationPayload;
      uid: string;
    }) =>
      API.patch<CreateOrderAdjustmentResponseTO>(
        `/api/internal/orders/${orderUid}/adjustment-items/${uid}`,
        orderAdjustment,
      ),
    {
      onSuccess: (response, { successCallback }) => {
        successCallback?.(response.data);
      },
      onError: () => {
        notifyError(
          t('componentOrderAdjustment.error.couldNotUpdateOrderAdjustment'),
        );
      },
    },
  );

  return {
    updateOrderAdjustment,
    isLoading,
  };
};

export default useUpdateOrderAdjustment;
