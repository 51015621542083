import LoaderImage from 'assets/images/loader.gif';
import { Container } from './Loader.styles';

interface Props {
  fullPage?: boolean;
  inline?: boolean;
  overlay?: boolean;
}

const Loader: React.FC<Props> = ({ fullPage, inline, overlay }: Props) => {
  const loaderImage = (
    <img className="loader" src={LoaderImage} alt="Loading..." />
  );

  if (inline) {
    return loaderImage;
  }

  return (
    <Container
      fullPage={fullPage}
      overlay={overlay}
      className="loader-container"
    >
      {loaderImage}
    </Container>
  );
};

Loader.defaultProps = {
  fullPage: false,
  inline: false,
  overlay: false,
};

export default Loader;
