import { isAfter } from 'date-fns';
import { LeadStatus } from '../../../../models/Leads';

export function requiresRebooking({
  checkInDate,
  checkOutDate,
  status,
}: {
  checkInDate: Date;
  checkOutDate: Date;
  status?: LeadStatus;
}): boolean {
  return (
    status === LeadStatus.ARCHIVED &&
    (isAfter(checkInDate, new Date()) || isAfter(checkOutDate, new Date()))
  );
}
