import ThreadAvatar from '../threads/ThreadAvatar';
import useThreadParticipantBestProfile from '../threads/useThreadParticipantBestProfile';
import { InboxMessageAvatarContainer } from './InboxMessages.styles';

const InboxMessageAvatar = ({
  senderName,
  threadUid,
}: {
  senderName: string;
  threadUid: string;
}) => {
  const bestProfile = senderName
    ? { name: senderName }
    : useThreadParticipantBestProfile(threadUid);
  return (
    <InboxMessageAvatarContainer>
      <ThreadAvatar bestProfile={bestProfile} />
    </InboxMessageAvatarContainer>
  );
};

export default InboxMessageAvatar;
