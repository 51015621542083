import useScreenBreakpoints from 'hooks/useScreenBreakpoints';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import LoadingSectionPlaceholder from '../../../LoadingSectionPlaceholder';
import { AmenityCategoryCollapse } from './CategorizedAmenities.styles';

const CategorizedAmenitiesLoadingPlaceholder = () => {
  const { isXS } = useScreenBreakpoints();

  return (
    <>
      <LoadingPlaceholder linesCount={2} />

      {Array.from({ length: 5 }).map((_, index) => (
        <AmenityCategoryCollapse
          variant="card"
          label={<LoadingPlaceholder linesCount={1} width="25%" />}
          defaultExpanded
          // eslint-disable-next-line react/no-array-index-key
          key={`amenities-loading-placeholder-${index}`}
        >
          <LoadingSectionPlaceholder columns={isXS ? 2 : 3} lines={3} />
        </AmenityCategoryCollapse>
      ))}
    </>
  );
};

export default CategorizedAmenitiesLoadingPlaceholder;
