/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LeadModeType } from 'models/DashboardLeadModes';
import DashboardContext from '../DashboardContext';
import { EndMessageParagraph } from './LeadsList.style';

interface NoLeadsMessageProps {
  isLeads: boolean;
}

const NoLeadsMessage = ({ isLeads }: NoLeadsMessageProps) => {
  const { t } = useTranslation();
  const { leadModeType } = useContext(DashboardContext);

  if (leadModeType === LeadModeType.CHECKIN) {
    return (
      <EndMessageParagraph>
        {isLeads
          ? t('pageDashboard.noMoreGuestArriving')
          : t('pageDashboard.noGuestArriving')}{' '}
        <br />
        <Trans
          i18nKey="pageDashboard.checkYourCalendarForFutureBookings"
          components={{
            1: <a href="#/calendar" />,
          }}
        />
      </EndMessageParagraph>
    );
  }

  if (leadModeType === LeadModeType.CHECKOUT) {
    return (
      <EndMessageParagraph>
        {isLeads
          ? t('pageDashboard.noMoreGuestLeaving')
          : t('pageDashboard.noGuestLeaving')}{' '}
        <br />
        <Trans
          i18nKey="pageDashboard.checkYourCalendarForFutureBookings"
          components={{
            1: <a href="#/calendar" />,
          }}
        />
      </EndMessageParagraph>
    );
  }

  return (
    <EndMessageParagraph>
      {isLeads
        ? t('pageDashboard.youHaveSeenItAll')
        : t('pageDashboard.nothingNewHere')}
    </EndMessageParagraph>
  );
};

export default NoLeadsMessage;
