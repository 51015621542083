import React from 'react';
import { DropdownButton } from 'react-bootstrap';
import GenericFormField from '../genericFormField/GenericFormField';
import {
  FieldRendererProps,
  GenericFormFieldBaseProps,
} from '../genericFormField/GenericFormField.types';
import { DropdownFieldStyled } from './DropdownField.styles';

interface DropdownFieldProps
  extends Omit<GenericFormFieldBaseProps, 'fieldRenderer'> {
  name: string;
  title: string;
  DropdownContent: React.ComponentType<FieldRendererProps>;
}

const DropdownField = ({
  name,
  id,
  title,
  DropdownContent,
  ...props
}: DropdownFieldProps) => {
  const fieldRenderer = (contentProps: FieldRendererProps) => {
    return (
      <DropdownFieldStyled>
        <DropdownButton bsStyle="default" title={title} id={id}>
          <DropdownContent {...contentProps} />
        </DropdownButton>
      </DropdownFieldStyled>
    );
  };

  return (
    <GenericFormField
      id={id}
      name={name}
      fieldRenderer={fieldRenderer}
      {...props}
    />
  );
};

export default DropdownField;
