import { ComponentPropsWithoutRef } from 'react';
import { CURRENCIES_WITHOUT_NONE } from 'models/Currency';
import { GenericFormFieldBaseProps } from '../genericFormField/GenericFormField.types';
import {
  AmountFieldStyled,
  AmountFieldWrapper,
  CurrencySelectStyled,
} from './AmountField.styles';

type InputBaseProps = Omit<GenericFormFieldBaseProps, 'fieldRenderer'> &
  ComponentPropsWithoutRef<'input'>;

export interface AmountFieldProps extends InputBaseProps {
  currencyFieldName: string;
}

const AmountField = ({
  name,
  currencyFieldName,
  min,
  ...fieldProps
}: AmountFieldProps) => {
  const options = CURRENCIES_WITHOUT_NONE.map((label) => ({
    label,
    value: label,
  }));

  return (
    <AmountFieldWrapper>
      <AmountFieldStyled
        name={name}
        label="Amount"
        type="number"
        min={min}
        leftAddon={
          <CurrencySelectStyled
            name={currencyFieldName}
            options={options}
            required
          />
        }
        {...fieldProps}
      />
    </AmountFieldWrapper>
  );
};

export default AmountField;
