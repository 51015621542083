import styled from 'styled-components';
import theme from 'styles/theme';

export const FormFieldsStyled = styled.div`
  display: flex;
  flex-direction: column;

  .checkbox {
    padding-left: 15px;
  }
`;

export const ModalDescriptionStyled = styled.p`
  border-bottom: 1px solid ${theme.colors.gray300};
  padding-bottom: 15px;
  margin-bottom: 15px;
`;
