import { ImageCircle } from 'components/image/ImageCircle.styles';
import Tooltip from 'components/tooltip/Tooltip';
import useProfileAvatar from 'hooks/useProfileAvatar';
import { Employee } from 'models/Employees';
import PropertyItemPermissionsEmployeeInfo from './PropertyItemPermissionsEmployeeInfo';

interface PropertyItemPermissionsEmployeeAvatarProps {
  employee: Employee;
  propertyUid: string;
  isAutoAssignee?: boolean;
}

const PropertyItemPermissionsEmployeeAvatar = ({
  employee,
  propertyUid,
  isAutoAssignee,
}: PropertyItemPermissionsEmployeeAvatarProps) => {
  const avatar = useProfileAvatar({
    pictureUrl: employee?.pictureUrl,
    firstName: employee?.firstName,
    lastName: employee?.lastName,
  });

  return (
    <Tooltip
      id={`tooltip-${propertyUid}-${employee.uid}-assignee`}
      text={
        <PropertyItemPermissionsEmployeeInfo
          employee={employee}
          isAutoAssignee={isAutoAssignee}
        />
      }
    >
      <ImageCircle $size="24px">{avatar}</ImageCircle>
    </Tooltip>
  );
};

PropertyItemPermissionsEmployeeAvatar.defaultProps = {
  isAutoAssignee: undefined,
};

export default PropertyItemPermissionsEmployeeAvatar;
