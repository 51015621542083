export interface AnalyticsKeyMetricsResponse {
  metricsValues: AnalyticsKeyMetricsValue[];
}

export enum AnalyticsKeyMetric {
  RENTAL_REVENUE = 'RENTAL_REVENUE',
  NIGHTS_BOOKED = 'NIGHTS_BOOKED',
  OCCUPANCY_RATE = 'OCCUPANCY_RATE',
  REV_PAR = 'REV_PAR',
  AVERAGE_DAILY_RATE = 'AVERAGE_DAILY_RATE',
}

export interface AnalyticsKeyMetricsValue {
  metric: AnalyticsKeyMetric;
  from: Date;
  to: Date;
  value: number;
  currency?: string;
}

export interface AnalyticsKeyMetricsPercentage {
  metric: AnalyticsKeyMetric;
  from: Date;
  to: Date;
  percentage: number;
}
