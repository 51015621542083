import * as Yup from 'yup';

export const accountSchema = () => ({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  phoneNumber_base: Yup.string().required(),
  email: Yup.string().email().required(),
});

export const mfaSchema = () => ({
  pinCode: Yup.string().length(6).required(),
});
