import { useTranslation } from 'react-i18next';
import CustomSelectField from 'components/forms/customSelectField/CustomSelectField';
import { VrboCancellationPolicy } from 'models/Channels';
import PropertyFormFieldLabel from 'pages/property/common/PropertyFormFieldLabel';

const optionsOrder = [
  VrboCancellationPolicy.NO_REFUND,
  VrboCancellationPolicy.STRICT,
  VrboCancellationPolicy.FIRM,
  VrboCancellationPolicy.MODERATE,
  VrboCancellationPolicy.RELAXED,
];

const PropertyVrboSettingsCancellationPolicyField = () => {
  const { t } = useTranslation();

  const fieldName = 'vrboSettings.cancellationPolicy';
  const fieldLabel = (
    <PropertyFormFieldLabel name={fieldName}>
      {t('pageProperty.mainSettings.vrboSettings.cancellationPolicy.label')}
    </PropertyFormFieldLabel>
  );

  const options = optionsOrder.map((value) => ({
    label: t(
      `pageProperty.mainSettings.vrboSettings.cancellationPolicy.option_${value}`,
    ),
    value,
  }));

  const optionRenderer = ({ children, data: { value } }) => (
    <div data-testid={`cancellationPolicyOption-${value}`}>
      <div data-testid="label">
        <b>{children}</b>
      </div>
      <div data-testid="description">
        {t(
          `pageProperty.mainSettings.vrboSettings.cancellationPolicy.optionDescription_${
            value as VrboCancellationPolicy
          }`,
        )}
      </div>
    </div>
  );

  return (
    <CustomSelectField
      label={fieldLabel}
      name={fieldName}
      options={options}
      optionRenderer={optionRenderer}
      colSmInput={7}
      colSmLabel={5}
      required
    />
  );
};

export default PropertyVrboSettingsCancellationPolicyField;
