import { useTranslation } from 'react-i18next';
import { Lead } from 'models/Leads';
import useOpenLeadModal from '../modal/useOpenLeadModal';
import { LeadViewButton } from './LeadListItemViewButton.styles';

const LeadListItemViewButton = ({
  backgroundColor,
  lead,
}: {
  backgroundColor?: string;
  lead: Lead;
}) => {
  const { updateLeadModal } = useOpenLeadModal();
  const { t } = useTranslation();

  const onClick = () => {
    updateLeadModal({ jobCreationNotAllowed: true, leadUid: lead.uid });
  };

  return (
    <LeadViewButton
      $backgroundColor={backgroundColor}
      className="lead-view-button"
      data-testid="lead-view-button"
      onClick={onClick}
    >
      {t('componentLead.details.view')}
    </LeadViewButton>
  );
};

LeadListItemViewButton.defaultProps = {
  backgroundColor: undefined,
};

export default LeadListItemViewButton;
