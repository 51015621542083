import { useContext, useMemo } from 'react';
import { startOfDay, subDays } from 'date-fns';
import useAppSelector from 'hooks/useAppSelector';
import { startDateOffsetByMode } from './CalendarContextProvider';
import CalendarContext from './CalendarContext';

const useStackedCalendarStartDate = (): Date => {
  const { filter, mode } = useContext(CalendarContext);
  const visibleDates = useAppSelector(
    (state) => state.stackedCalendar.visibleDates,
  );
  const savedFilterDate = filter?.availableFrom;

  return useMemo(() => {
    if (savedFilterDate) {
      return subDays(
        startOfDay(new Date(savedFilterDate)),
        startDateOffsetByMode[mode],
      );
    }

    return visibleDates.length
      ? visibleDates[0]
      : subDays(startOfDay(new Date()), startDateOffsetByMode[mode]);
  }, [mode, savedFilterDate, visibleDates]);
};

export default useStackedCalendarStartDate;
