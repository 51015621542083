import * as Yup from 'yup';

const addSubUnitsModalSchema = () =>
  Yup.object({
    isNonBookable: Yup.boolean().nullable(true).optional(),
    tag: Yup.string().trim().nullable(true).optional(),
    units: Yup.number().integer().min(1).required(),
  });

export type AddSubUnitsModalSchemaType = Yup.InferType<
  ReturnType<typeof addSubUnitsModalSchema>
>;

export default addSubUnitsModalSchema;
