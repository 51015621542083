import { ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import PropertySelectField from 'components/forms/domain/property/PropertySelectField';
import HomeIcon from 'components/icons/HomeIcon';
import { PropertySettingsTab } from './PropertySettingsTabs.constants';
import usePropertySettingsInitData from './usePropertySettingsInitData';

const PropertySettingsTabHeaderPropertySelector = ({
  activeTab,
  currentPropertyUid,
}: {
  activeTab: string;
  currentPropertyUid: string;
}) => {
  const navigate = useNavigate();
  const { allProperties } = usePropertySettingsInitData();

  const onChange = ({
    target: { value: selectedPropertyUid },
  }: ChangeEvent<HTMLSelectElement>) => {
    // TODO check if active tab is allowed to navigate to for selected property business type
    const selectedTab =
      activeTab !== PropertySettingsTab.mainSettings ? `/${activeTab}` : '';

    navigate(`../${selectedPropertyUid}${selectedTab}`);
  };

  return (
    <PropertySelectField
      name="selectedPropertyUid"
      properties={allProperties}
      leftAddon={<HomeIcon />}
      onChange={onChange}
      value={currentPropertyUid}
      inline
    />
  );
};

export default PropertySettingsTabHeaderPropertySelector;
