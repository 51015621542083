import { Col, ModalBody, ModalFooter, Row } from 'react-bootstrap';
import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import DateTimePickerField from 'components/forms/dateTimePickerField/DateTimePickerField';
import SelectField from 'components/forms/selectField/SelectField';
import TextField from 'components/forms/textField/TextField';
import useGetCurrencySymbol from 'hooks/useGetCurrencySymbol';
import useScreenBreakpoints from 'hooks/useScreenBreakpoints';
import { TransactionType } from 'models/Transactions';

const AddManualTransactionModalFormElements = ({
  currency,
  onCancel,
}: {
  currency: string;
  onCancel: () => void;
}) => {
  const getCurrencySymbol = useGetCurrencySymbol();
  const { isSubmitting } = useFormState();
  const { isXS } = useScreenBreakpoints();
  const { t } = useTranslation();

  const transactionTypeOptions = [
    TransactionType.SALE,
    TransactionType.REFUND,
  ].map((type: TransactionType.REFUND | TransactionType.SALE) => ({
    label: t(`componentTransaction.typeSelectOptions.${type}`),
    value: type,
  }));

  return (
    <>
      <ModalBody>
        <div data-testid="add-manual-transaction-info">
          {t('pageOrder.transactions.actions.addManualTransaction.info')}
        </div>
        <Row>
          <Col>
            <SelectField
              label={t(
                'pageOrder.transactions.actions.addManualTransaction.transactionType',
              )}
              name="type"
              options={transactionTypeOptions}
              rightTooltip={t(
                'pageOrder.transactions.actions.addManualTransaction.transactionTypeTooltip',
              )}
              colSmInput={8}
              colSmLabel={4}
              labelOnTop={isXS}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DateTimePickerField
              label={t(
                'pageOrder.transactions.actions.addManualTransaction.transactionDate',
              )}
              name="created"
              rightTooltip={t(
                'pageOrder.transactions.actions.addManualTransaction.transactionDateTooltip',
              )}
              colSmInput={8}
              colSmLabel={4}
              labelOnTop={isXS}
              required
              withTime={false}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              label={t(
                'pageOrder.transactions.actions.addManualTransaction.value',
              )}
              name="amount"
              leftAddon={getCurrencySymbol(currency)}
              rightTooltip={t(
                'pageOrder.transactions.actions.addManualTransaction.valueTooltip',
              )}
              colSmInput={8}
              colSmLabel={4}
              labelOnTop={isXS}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              label={t(
                'pageOrder.transactions.actions.addManualTransaction.notes',
              )}
              name="notes"
              componentClass="textarea"
              rightTooltip={t(
                'pageOrder.transactions.actions.addManualTransaction.notesTooltip',
              )}
              colSmInput={8}
              colSmLabel={4}
              labelOnTop={isXS}
              maxCharacters={100}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button disabled={isSubmitting} onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button bsStyle="primary" disabled={isSubmitting} type="submit">
          {t('pageOrder.transactions.actions.addManualTransaction.add')}
        </Button>
      </ModalFooter>
    </>
  );
};

export default AddManualTransactionModalFormElements;
