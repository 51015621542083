export enum LanguageCode {
  DE = 'de',
  EN = 'en',
  EN_GB = 'en-GB',
  EN_US = 'en-US',
  FR = 'fr',
  PL = 'pl',
  PT = 'pt',
  ES = 'es',
}

export enum LanguageCodeUnderscored {
  EN_US = 'en_US',
  FR_FR = 'fr_FR',
  ES_ES = 'es_ES',
  DE_DE = 'de_DE',
  NL_NL = 'nl_NL',
  PT_PT = 'pt_PT',
  PL_PL = 'pl_PL',
  KO_KR = 'ko_KR',
  NO_NO = 'no_NO',
  ZH_CN = 'zh_CN',
  TR_TR = 'tr_TR',
  IT_IT = 'it_IT',
}
