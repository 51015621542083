import styled from 'styled-components';
import { PropertyStatusStyled } from 'components/domain/property/status/PropertyStatus.styles';

export const PropertySettingsMainTabStyled = styled.div`
  display: flex;
  flex-direction: column;

  .tab-title {
    margin-bottom: 16px;

    .badge {
      font-size: 1rem;
      font-weight: normal;
      line-height: 1em;
    }

    .toggle-switch {
      display: flex;
      margin: 0;
    }
  }

  ${PropertyStatusStyled} {
    align-self: flex-end;
  }
`;
