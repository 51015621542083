import ImportBookingDotComPropertiesContextProvider from './ImportBookingDotComPropertiesContextProvider';
import ImportBookingDotComPropertiesModalInner from './ImportBookingDotComPropertiesModalInner';

const ImportBookingDotComPropertiesModal = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  return (
    <ImportBookingDotComPropertiesContextProvider>
      <ImportBookingDotComPropertiesModalInner onClose={onClose} />
    </ImportBookingDotComPropertiesContextProvider>
  );
};

export default ImportBookingDotComPropertiesModal;
