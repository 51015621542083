import { Row } from 'react-bootstrap';
import { ImageCircle } from 'components/image/ImageCircle.styles';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import theme from 'styles/theme';
import {
  PropertyListItemStyled,
  PropertyActionsColumnStyled,
  PropertyDetailsColumnStyled,
  PropertyInfoStyled,
  PropertyPermissionsColumnStyled,
} from './PropertyListItem.styles';
import PropertyItemPermissionsUsersListPlaceholder from './permissions/PropertyItemPermissionsEmployeeListPlaceholder';

const PropertyListItemPlaceholder = () => {
  return (
    <PropertyListItemStyled>
      <Row>
        <PropertyDetailsColumnStyled $canSeePermissions>
          <ImageCircle $ringColor={theme.colors.gray300}>
            <div>
              <LoadingPlaceholder circle width="60px" />
            </div>
          </ImageCircle>
          <PropertyInfoStyled>
            <LoadingPlaceholder linesCount={2.15} width="65%" />
            <LoadingPlaceholder linesCount={1.42} width="55%" />
            <LoadingPlaceholder linesCount={1.42} width="65%" />
          </PropertyInfoStyled>
        </PropertyDetailsColumnStyled>
        <PropertyPermissionsColumnStyled $canSeePermissions>
          <PropertyItemPermissionsUsersListPlaceholder />
        </PropertyPermissionsColumnStyled>
        <PropertyActionsColumnStyled $canSeePermissions>
          <LoadingPlaceholder linesCount={1.5} width="80%" />
        </PropertyActionsColumnStyled>
      </Row>
    </PropertyListItemStyled>
  );
};

export default PropertyListItemPlaceholder;
