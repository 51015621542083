/* eslint-disable no-nested-ternary */
import { Glyphicon } from 'react-bootstrap';
import styled from 'styled-components';

export const ToggleButtonWrapper = styled(Glyphicon).attrs(
  ({
    $isExpanded,
    $vertical,
  }: {
    $isExpanded: boolean;
    $vertical: boolean;
  }) => ({
    glyph: $isExpanded
      ? $vertical
        ? 'chevron-up'
        : 'chevron-right'
      : $vertical
      ? 'chevron-down'
      : 'chevron-left',
  }),
)<{
  $isExpanded: boolean;
  $vertical: boolean;
}>`
  color: black;
`;
