import { useMemo, useState } from 'react';
import AnalyticsContext from './AnalyticsContext';
import {
  AnalyticsBasicProperty,
  AnalyticsFilter,
} from './types/Analytics.types';

const AnalyticsContextProvider = ({
  children,
  analyticsFilterLocalStorageKey,
  chartsFilterLocalStorageKey,
}) => {
  const [allProperties, setAllProperties] = useState<AnalyticsBasicProperty[]>(
    [],
  );
  const [filter, setFilter] = useState<AnalyticsFilter>();
  const [leadChannels, setLeadChannels] = useState([]);
  const [employees, setEmployees] = useState([]);

  const contextValue = useMemo(
    () => ({
      allProperties,
      setAllProperties,
      filter,
      setFilter,
      leadChannels,
      setLeadChannels,
      employees,
      setEmployees,
      analyticsFilterLocalStorageKey,
      chartsFilterLocalStorageKey,
    }),
    [
      allProperties,
      filter,
      leadChannels,
      employees,
      analyticsFilterLocalStorageKey,
      chartsFilterLocalStorageKey,
    ],
  );

  return (
    <AnalyticsContext.Provider value={contextValue}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsContextProvider;
