import LostSlothSvg from '../svg/LostSlothSvg';
import { Container } from './Hero.styles';

const Hero = () => {
  return (
    <Container>
      <LostSlothSvg />
    </Container>
  );
};

export default Hero;
