import { useInfiniteQuery } from '@tanstack/react-query';
import API from '../../../services/API';
import useAppUser from '../../../hooks/useAppUser';
import { GetLeadsGraphQLResponseTO } from '../../../models/Leads';

const useFetchLeadsPaginated = ({
  conditions,
  fields,
  pageSize = 30,
}: {
  conditions: string;
  fields: string;
  pageSize?: number;
}) => {
  const { agencyUid } = useAppUser();

  const {
    data: leadsData,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['leads', agencyUid, conditions, fields],
    queryFn: async ({ pageParam = 0 }) => {
      const leadsResponse = await API.post<GetLeadsGraphQLResponseTO>(
        '/v3/graphql',
        {
          query: `{leads (
            agencyUid:"${agencyUid}", 
            offset:"${pageParam * pageSize}"
            limit:"${pageSize}",
            ${conditions}
          ){
            ${fields}
          }}`,
          variables: {},
        },
      );

      return leadsResponse.data.data.leads || [];
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length < pageSize) {
        return undefined;
      }

      return lastPage.length ? allPages.length : undefined;
    },
    enabled: !!conditions && !!fields,
    keepPreviousData: false,
  });

  const leads = leadsData?.pages?.flat(1) || [];

  return {
    leads,
    error,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetching: isFetching || isFetchingNextPage,
    isInitialFetching: !leadsData && isFetching,
  };
};

export default useFetchLeadsPaginated;
