import { useTranslation } from 'react-i18next';
import useAppMutation from '../../../hooks/useAppMutation';
import API from '../../../services/API';
import { TagType } from '../../../models/Tags';
import useNotify from '../../../hooks/useNotify';

interface ExecuteTagActionPayload {
  entityUid: string;
  successCallback?: Function;
  tag: string;
  tagType: TagType;
}

const useTagMutations = () => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  const { mutate: addTag, isLoading: isAddingTag } = useAppMutation(
    ({ tag, tagType, entityUid }: ExecuteTagActionPayload) => {
      return API.post('v3/tags', {
        objectUid: entityUid,
        tag,
        tagType,
      });
    },
    {
      onSuccess: (response, { successCallback }) => {
        successCallback?.();
      },
      onError: () => notifyError(t('componentTag.errorAddTag')),
    },
  );

  const { mutate: deleteTag, isLoading: isDeletingTag } = useAppMutation(
    ({ tag, tagType, entityUid }: ExecuteTagActionPayload) => {
      return API.delete(
        `v3/tags?tag=${encodeURIComponent(
          tag,
        )}&tagType=${tagType}&objectUid=${entityUid}`,
      );
    },
    {
      onSuccess: (response, { successCallback }) => {
        successCallback?.();
      },
      onError: () => notifyError(t('componentTag.errorDeleteTag')),
    },
  );

  return {
    addTag,
    deleteTag,
    isAddingTag,
    isDeletingTag,
  };
};

export default useTagMutations;
