import { UseQueryOptions } from '@tanstack/react-query';
import useAppQuery from 'hooks/useAppQuery';
import { GetRoomNamesResponseTO } from 'models/bookingDotCom/RoomNames';
import API from 'services/API';

export const ROOM_NAMES_BASE_QUERY_KEY = 'booking-dot-com-room-names';

type ReactQueryOptions = Omit<
  UseQueryOptions<GetRoomNamesResponseTO['roomName']>,
  'queryKey' | 'queryFn'
>;

type UseFetchBookingDotComRoomNamesArgs = {
  propertyUid: string;
} & ReactQueryOptions;

const useFetchBookingDotComRoomNames = ({
  propertyUid,
  enabled,
  ...options
}: UseFetchBookingDotComRoomNamesArgs) => {
  return useAppQuery({
    queryKey: [ROOM_NAMES_BASE_QUERY_KEY, 'detail', propertyUid],
    queryFn: async () => {
      const response = await API.get<GetRoomNamesResponseTO>(
        `/v3/bookingdotcom-room-names/${propertyUid}`,
      );

      return response.data.roomName;
    },
    enabled: enabled !== undefined ? enabled : true,
    staleTime: 30000,
    keepPreviousData: true,
    ...(options ?? {}),
  });
};

export default useFetchBookingDotComRoomNames;
