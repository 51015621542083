import { Property, PropertyBusinessType } from 'models/Properties';
import { StackedCalendarMode } from './Calendar.types';

export const stackedCalendarStyleConstants = {
  leftPanelWidth: 220,
  leftPanelWidthMobile: 160,
  border: '0.5px solid rgb(221, 221, 221)',
  selectedBorder: '0.5px solid blue',
  daysCellWidth: {
    [StackedCalendarMode.BOOKING]: 35,
    [StackedCalendarMode.PRICING]: 70,
    [StackedCalendarMode.COMBINED]: 70,
  },
  days: {
    height: 40,
  },
  headerDays: {
    height: 50,
  },
  monthCell: {
    height: 50,
  },
};

export const stackedCalendarPropertiesConstants = {
  defaultLimit: 25,
};

export const calendarModeLocalStorageKey = 'hf-calendar-mode';
export const getCalendarFilterLocalStorageKey = (userUid: string) =>
  `filter-stacked-calendar-user:${userUid}`;

export const propertiesBusinessType: Record<
  string,
  { type: PropertyBusinessType; parent?: string }
> = {};

export const setPropertiesBusinessType = (properties: Property[]) => {
  properties.forEach((property) => {
    // STANDALONE_PROPERTY or HOTEL, without parents:
    propertiesBusinessType[property.uid] = {
      type: property.businessType,
    };

    property.unitTypes?.forEach((unitType) => {
      propertiesBusinessType[unitType.uid] = {
        type: PropertyBusinessType.UNIT_TYPE,
        parent: property.uid,
      };
      unitType.units?.forEach((unit) => {
        propertiesBusinessType[unit.uid] = {
          type: PropertyBusinessType.UNIT,
          parent: unitType.uid,
        };
      });
    });

    property.subUnits?.forEach((subUnit) => {
      propertiesBusinessType[subUnit.uid] = {
        type: PropertyBusinessType.SUB_UNIT,
        parent: property.uid,
      };
    });
  });
};

export const MOVE_BOOK_WARNING_DISMISSED = 'move-book-warning-dismissed';
