import useAppMutation from 'hooks/useAppMutation';
import { BaseErrorResponse } from 'models/_base';
import API from 'services/API';
import { PropertyMainSettingsUpdateResponse } from '../mainSettings/PropertySettingsMainTab.types';
import { PropertyMainSettingsBulkUpdatePayload } from './PropertySettingsBulkSaveModal.types';

const useBulkUpdatePropertyMainSettings = () => {
  const { mutateAsync: bulkUpdatePropertyMainSettings, isLoading } =
    useAppMutation<
      PropertyMainSettingsUpdateResponse,
      BaseErrorResponse,
      PropertyMainSettingsBulkUpdatePayload
    >(async (payload) => {
      return API.patch<PropertyMainSettingsUpdateResponse, BaseErrorResponse>(
        `api/internal/properties/bulk-update-main-settings`,
        payload,
      );
    });

  return { isUpdating: isLoading, bulkUpdatePropertyMainSettings };
};

export default useBulkUpdatePropertyMainSettings;
