import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import useAppUser from 'hooks/useAppUser';
import useLocalStorage from 'hooks/useLocalStorage';
import { isDeepEqual } from 'utils/objectUtils';
import useStackedCalendarInit from '../useStackedCalendarInit';
import { getCalendarFilterLocalStorageKey } from '../Calendar.constants';
import StackedCalendarFiltersSchema, {
  StackedCalendarFiltersSchemaType,
  defaultValues as getDefaultValues,
} from './StackedCalendarFilters.schema';

const useStackedCalendarFiltersFormApplyStoredValues = () => {
  const { userUid } = useAppUser();
  const { filterOptions } = useStackedCalendarInit();
  const localStorageKey = getCalendarFilterLocalStorageKey(userUid);
  const { getValue: getStoredFilterFormValues } =
    useLocalStorage<StackedCalendarFiltersSchemaType>(localStorageKey);
  const defaultValues = getDefaultValues(filterOptions);
  const { setValue } = useFormContext();

  useEffect(() => {
    const storedFormValues = getStoredFilterFormValues();
    if (!storedFormValues || !filterOptions) {
      return;
    }

    const storedValues = StackedCalendarFiltersSchema.cast(storedFormValues);

    Object.keys(storedValues)
      .filter((key) => !isDeepEqual(storedValues[key], defaultValues[key]))
      .forEach((key) => {
        setValue(key, storedValues[key], {
          shouldDirty: true,
        });
      });
  }, []);
};

export default useStackedCalendarFiltersFormApplyStoredValues;
