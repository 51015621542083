import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Row, Col } from 'react-bootstrap';
import ImportBookingDotComPropertiesContext from '../modal/ImportBookingDotComPropertiesContext';
import { CONNECTION_STEP } from '../modal/ImportBookingDotComPropertiesModal.utils';
import BodyModalLoader from './BodyModalLoader';
import { BodyModalConnectionWrapper } from './BodyModalConnection.styles';
import LegalEntityIDInput from './form/LegalEntityIDInput';
import BodyModalConnectionWithoutProperty from './BodyModalConnectionWithoutProperty';
import BodyModalConnectionWithProperties from './BodyModalConnectionWithProperties';

const BodyModalConnection = () => {
  const { t } = useTranslation();
  const { isImportablePropertiesLoading } = useContext(
    ImportBookingDotComPropertiesContext,
  );
  const { currentStep } = useContext(ImportBookingDotComPropertiesContext);

  return (
    <>
      <Alert bsStyle="warning">
        {t(
          'pageProperties.importBookingDotComPropertiesModal.warningExceedNumberOfProperties',
        )}
      </Alert>

      <p>
        {t('pageProperties.importBookingDotComPropertiesModal.chooseBCAccount')}
      </p>

      <BodyModalConnectionWrapper>
        <LegalEntityIDInput />
      </BodyModalConnectionWrapper>

      <Row>
        {isImportablePropertiesLoading && (
          <Col xs={12}>
            <BodyModalLoader />
          </Col>
        )}

        {(currentStep === CONNECTION_STEP.CONNECT_TO_PROVIDER ||
          currentStep === CONNECTION_STEP.REFRESH) && (
          <BodyModalConnectionWithoutProperty />
        )}

        {currentStep === CONNECTION_STEP.PROPERTY_SELECTION && (
          <BodyModalConnectionWithProperties />
        )}
      </Row>
    </>
  );
};

export default BodyModalConnection;
