import styled from 'styled-components';

export const PropertySettingsTabHeaderContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`;

export const PropertySettingsTabHeaderButtons = styled.div`
  display: flex;
  gap: 1rem;

  & a,
  & button {
    align-items: center;
    display: flex;
    gap: 8px;
    height: fit-content;
    padding-left: 0;
    padding-right: 0;
  }
`;
