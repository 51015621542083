import { css } from 'styled-components';
import { stackedCalendarStyleConstants } from 'pages/stackedCalendar/Calendar.constants';
import { dayCellCommonConstants } from 'pages/calendar/common/cells/DayCellCommon.constants';
import { DayCellSelectionStyles } from './DayCellSelection.styles';

export const DayCellStyles = css`
  .body-day-cell {
    align-items: flex-end;
    background-color: transparent;
    border-bottom: ${stackedCalendarStyleConstants.border};
    border-left: ${stackedCalendarStyleConstants.border};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: ${stackedCalendarStyleConstants.days.height}px;
    justify-content: center;
    position: relative;
    padding-left: 5px;
    padding-right: 5px;
    user-select: none;

    &.date-today {
      border-left: ${dayCellCommonConstants.borderLeftToday};
    }

    &.greyed-out {
      padding: 0;

      :not(.date-before-today)::before {
        content: '';
        background-color: rgb(221 221 221 / 50%);
        height: 100%;
        width: 100%;
      }
    }

    &.unavailable {
      .day-cell-content-line {
        color: red !important;

        svg path {
          fill: red !important;
        }
      }
    }

    // order is important here to assure proper background rendering, must be .weekend -> .date-before-today -> .highlight
    &.weekend {
      background-color: rgba(102, 153, 204, 0.2);

      .day-cell-selection-segment-container {
        background-color: #e0ebf5;
      }
    }

    &.date-before-today {
      background-color: ${dayCellCommonConstants.disabledBackgroundColor};
    }

    &.highlight {
      background-color: #feffbc;
      border-color: #d4d664;
      transition: all 0.3s ease-in-out;
    }

    ${Array.from(
      new Set(Object.values(stackedCalendarStyleConstants.daysCellWidth)),
    )
      .map(
        (width) => `
      &.width-${width} {
        flex: 1 0 ${width}px;
      }
    `,
      )
      .join('\n')}

    ${DayCellSelectionStyles}
    
    &.drop-highlight {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: var(--drop-highlight-left);
        width: var(--drop-highlight-width);
        height: 100%;
        border: 2px dashed var(--drop-highlight-border-color);
        border-radius: 5px;
        z-index: 1000;
        pointer-events: none;
      }
    }
  }

  .day-cell-content-line {
    font-size: 12px;
    color: rgb(31, 31, 31);
    font-family: SourceSansPro_light, sans-serif;
  }
`;
