import useFetchAgencyIntegrationSettings from 'components/domain/integrationSettings/useFetchAgencyIntegrationSettings';
import useAppConfig from 'hooks/useAppConfig';

const guidebookBaseUrlProd = 'https://v2.hostfully.com';
const guidebookBaseUrlTest = 'https://v2staging.hostfully.com';

const usePropertyGuidebookLink = (propertyUid: string) => {
  const { showVersionNumber: isTest } = useAppConfig();
  const { integrationSettings } = useFetchAgencyIntegrationSettings();

  const hostfullyIntegrationSettings = integrationSettings?.find(
    ({ affiliateEnum }) => affiliateEnum === 'hostfully',
  );

  const { isEnabled, token } = hostfullyIntegrationSettings || {};

  if (isEnabled && token) {
    const baseUrl = isTest ? guidebookBaseUrlTest : guidebookBaseUrlProd;

    return `${baseUrl}/partner/orbirental?ref=${propertyUid}&token=${token}`;
  }

  return null;
};

export default usePropertyGuidebookLink;
