import { compareDesc, parseISO } from 'date-fns';
import {
  Thread,
  ThreadParticipant,
  ThreadParticipantReadStatus,
  ThreadParticipantType,
} from '../../../models/inbox/Threads';
import { ThreadFilterSortOrder } from '../Inbox.types';
import { UserType } from '../../../models/Users';
import { UserTypeToThreadParticipantTypeMapping } from './InboxThread.constants';

function getCurrentUserThreadParticipantUid(
  participants: ThreadParticipant[],
  userType: UserType,
): string | undefined {
  const currentUserParticipantType =
    UserTypeToThreadParticipantTypeMapping[userType];

  return participants.find(
    ({ participantType }) => participantType === currentUserParticipantType,
  )?.uid;
}

export function getThreadParticipantReadStatus(
  thread: Thread,
  userType: UserType,
): ThreadParticipantReadStatus | undefined {
  const { participants, participantsReadStatuses } = thread;
  const currentUserThreadParticipantUid = getCurrentUserThreadParticipantUid(
    participants,
    userType,
  );

  return participantsReadStatuses.find(
    ({ participantUid }) => participantUid === currentUserThreadParticipantUid,
  );
}

export function getThreadLastUpdateDate(thread: Thread): Date {
  if (!thread.lastUpdateDateObj) {
    thread.lastUpdateDateObj = parseISO(thread.lastUpdateDate);
  }

  return thread.lastUpdateDateObj;
}

export function sortThreadsByUpdateDateIfApplicable(
  threads: Thread[],
  sortBy: ThreadFilterSortOrder,
): void {
  if (sortBy === ThreadFilterSortOrder.SORT_BY_RECENT_UPDATE_DATE) {
    threads.sort((thread1, thread2) => {
      return compareDesc(
        getThreadLastUpdateDate(thread1),
        getThreadLastUpdateDate(thread2),
      );
    });
  }
}

const allowedAgencyInterlocutors = [
  ThreadParticipantType.GUEST,
  ThreadParticipantType.OWNER,
  ThreadParticipantType.SERVICE_PROVIDER,
];

export function isAllowedCommunicationThread(thread?: Thread): boolean {
  if (!thread) {
    return false;
  }

  const agencyParticipant = thread.participants.find(
    ({ participantType }) => participantType === ThreadParticipantType.AGENCY,
  );
  const interlocutor = thread.participants.find(({ participantType }) =>
    allowedAgencyInterlocutors.includes(participantType),
  );

  return !!agencyParticipant && !!interlocutor;
}
