import { useMemo, useState } from 'react';

import CalendarBodyContext from './CalendarBodyContext';

const CalendarBodyContextProvider = ({ children }) => {
  const [daysCalendar, setDaysCalendar] = useState({});

  const contextValue = useMemo(
    () => ({
      daysCalendar,
      setDaysCalendar,
    }),
    [daysCalendar],
  );
  return (
    <CalendarBodyContext.Provider value={contextValue}>
      {children}
    </CalendarBodyContext.Provider>
  );
};

export default CalendarBodyContextProvider;
