import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import ImportBookingDotComPropertiesContext from '../modal/ImportBookingDotComPropertiesContext';
import { CONNECTION_STEP } from '../modal/ImportBookingDotComPropertiesModal.utils';
import ConnectProviderButton from './button/ConnectProviderButton';
import RefreshProviderButton from './button/RefreshProviderButton';
import { BodyModalConnectionWithoutPropertyWrapper } from './BodyModalConnectionWithoutProperty.styles';

const BodyModalConnectionWithoutProperty = () => {
  const { t } = useTranslation();
  const { currentStep, importableProperties } = useContext(
    ImportBookingDotComPropertiesContext,
  );

  return (
    <>
      {importableProperties?.length === 0 && (
        <Col xs={12}>
          <p data-testid="no-importable-properties-warning">
            {t(
              'pageProperties.importBookingDotComPropertiesModal.noImportableProperty',
            )}
          </p>
        </Col>
      )}
      <Col xs={12}>
        <BodyModalConnectionWithoutPropertyWrapper>
          <p>
            {currentStep === CONNECTION_STEP.CONNECT_TO_PROVIDER && (
              <>
                {t(
                  'pageProperties.importBookingDotComPropertiesModal.setHostfullyAsYourConnectivityProvider',
                )}
                <br />
                {t(
                  'pageProperties.importBookingDotComPropertiesModal.canOnlyBeImportedOnce',
                )}
              </>
            )}

            {currentStep === CONNECTION_STEP.REFRESH &&
              t(
                'pageProperties.importBookingDotComPropertiesModal.clickOnRefreshButton',
              )}
          </p>
          <div className="text-center">
            {currentStep === CONNECTION_STEP.CONNECT_TO_PROVIDER && (
              <ConnectProviderButton
                renderAsLink={false}
                label={t(
                  'pageProperties.importBookingDotComPropertiesModal.connectProvider',
                )}
              />
            )}
            {currentStep === CONNECTION_STEP.REFRESH && (
              <RefreshProviderButton />
            )}
          </div>
        </BodyModalConnectionWithoutPropertyWrapper>
      </Col>
    </>
  );
};

export default BodyModalConnectionWithoutProperty;
