import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import useFetchEmployees from 'components/domain/employee/useFetchEmployees';
import { getCurrencyOptions } from 'utils/analytics/analyticsUtils';
import useFetchAllProperties from 'components/domain/property/useFetchAllProperties';
import useAppQuery from '../../hooks/useAppQuery';
import API from '../../services/API';
import useAppUser from '../../hooks/useAppUser';
import { GetAllLeadChannelsResponseTO } from '../../models/Leads';
import AnalyticsContext from './AnalyticsContext';
import {
  ChartFilter,
  ChartType,
  FinancialPerformanceMetric,
  PropertyPerformanceMetric,
  TeamPerformanceMetric,
} from './charts/Charts.types';
import {
  propertyAnalyticsConditionGraphQLQuery,
  propertyAnalyticsGraphQLQuery,
} from './Analytics.utils';
import { AnalyticsBasicProperty } from './types/Analytics.types';

const useAnalyticsInit = () => {
  const { isEmployee, agency } = useAppUser();
  const { setLeadChannels, setAllProperties, setEmployees } =
    useContext(AnalyticsContext);
  const [defaultCurrency, setDefaultCurrency] = useState<String>();
  const { employees, isFetching } = useFetchEmployees();
  const { chartsFilterLocalStorageKey } = useContext(AnalyticsContext);
  const { allProperties: properties, isFetching: isPropertiesLoading } =
    useFetchAllProperties({
      enabled: isEmployee,
      conditions: propertyAnalyticsConditionGraphQLQuery,
      fields: propertyAnalyticsGraphQLQuery,
    });
  const storedFilter = JSON.parse(
    localStorage.getItem(chartsFilterLocalStorageKey),
  );

  const chartFormSchema = () => ({
    type: Yup.mixed<ChartType>().oneOf(Object.values(ChartType)).required(),
    metric: Yup.mixed<
      | FinancialPerformanceMetric
      | PropertyPerformanceMetric
      | TeamPerformanceMetric
    >().required(),
    offset: Yup.number().required(),
    limit: Yup.number().required(),
  });
  const chartFormDefaultValues: ChartFilter = storedFilter || {
    type: ChartType.FINANCIAL_PERFORMANCE,
    metric: FinancialPerformanceMetric.RENTAL_REVENUE,
    offset: 0,
    limit: 25,
  };

  const { data: leadChannels, isLoading: isLeadChannelsLoading } = useAppQuery(
    ['leadChannels'],
    async () => {
      const response = await API.get<GetAllLeadChannelsResponseTO>(
        '/v3/leads-channels',
      );
      return response.data.leadChannels;
    },
    { enabled: isEmployee },
  );

  useEffect(() => {
    if (leadChannels) {
      setLeadChannels(leadChannels);
    }

    if (properties) {
      setAllProperties(properties as unknown as AnalyticsBasicProperty[]);

      setDefaultCurrency(
        getCurrencyOptions(properties as unknown as AnalyticsBasicProperty[])[0]
          ?.value || agency.currency,
      );
    }

    if (employees) {
      setEmployees(employees);
    }
  }, [leadChannels, properties, employees]);

  return {
    isLoading:
      isEmployee &&
      (isLeadChannelsLoading || isPropertiesLoading || isFetching),
    chartFormSchema,
    chartFormDefaultValues,
    defaultCurrency,
  };
};

export default useAnalyticsInit;
