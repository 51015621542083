import getPropertiesQuery from 'utils/property/getPropertiesQuery';
import useFetchAllProperties from './useFetchAllProperties';

const useFetchProperty = ({
  enabled,
  fields,
  operationName,
  propertyUid,
}: {
  enabled: boolean;
  fields: string;
  operationName?: string;
  propertyUid: string;
}) => {
  const { allProperties, isFetching } = useFetchAllProperties({
    enabled,
    conditions: getPropertiesQuery({
      filters: {
        propertyUids: [propertyUid],
        topLevelOnly: false,
      },
    }),
    fields,
    operationName,
  });

  return {
    isFetching,
    property: allProperties?.length ? allProperties[0] : undefined,
  };
};

export default useFetchProperty;
