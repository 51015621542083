import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { BulkSaveTitleStyled } from './BulkSavePropertiesModal.styles';

interface BulkSavePropertiesModalTitleProps {
  children?: ReactNode;
}
const BulkSavePropertiesModalTitle = ({
  children,
}: BulkSavePropertiesModalTitleProps) => {
  const { t } = useTranslation();

  return (
    <>
      <BulkSaveTitleStyled>
        {t('componentProperty.bulkSaveModal.bulkSave')}
        {children ? ': ' : null}
      </BulkSaveTitleStyled>
      {children}
    </>
  );
};

BulkSavePropertiesModalTitle.defaultProps = {
  children: undefined,
};

export default BulkSavePropertiesModalTitle;
