import { useContext } from 'react';
import Loader from '../../../../components/loader/Loader';
import CloseIcon from '../../../../components/icons/CloseIcon';
import InboxMessagesContext from '../InboxMessagesContext';
import { InboxMessageTemplatesContainer } from './InboxMessageTemplates.styles';
import useInboxAvailableTemplates from './useInboxAvailableTemplates';
import InboxMessageTemplatesFilter from './InboxMessageTemplatesFilter';
import InboxMessageTemplatesList from './InboxMessageTemplatesList';

const InboxMessageTemplates = () => {
  const { isLoadingTemplates } = useInboxAvailableTemplates();
  const { toggleTemplateSelector } = useContext(InboxMessagesContext);

  return (
    <InboxMessageTemplatesContainer>
      {isLoadingTemplates && <Loader />}
      {!isLoadingTemplates && (
        <>
          <CloseIcon
            data-testid="inbox-message-templates-selector-close-icon"
            onClick={toggleTemplateSelector}
          />
          <InboxMessageTemplatesFilter />
          <InboxMessageTemplatesList />
        </>
      )}
    </InboxMessageTemplatesContainer>
  );
};

export default InboxMessageTemplates;
