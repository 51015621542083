import { useContext } from 'react';
import InboxContext from '../InboxContext';
import InboxMessagesContext from './InboxMessagesContext';
import InboxMessagesList from './InboxMessagesList';
import useInboxNewMessagesCheck from './useInboxNewMessagesCheck';
import InboxMessageEditor from './editor/InboxMessageEditor';
import InboxMessageTemplates from './templates/InboxMessageTemplates';
import InboxMessagesLoader from './InboxMessagesLoader';

const InboxMessagesBody = () => {
  const { activeMessageThreadUid } = useContext(InboxContext);
  const { isLoading, isTemplateSelectorActive } =
    useContext(InboxMessagesContext);

  useInboxNewMessagesCheck();

  if (!activeMessageThreadUid) {
    return null;
  }

  return (
    <>
      {isLoading && <InboxMessagesLoader />}
      {!isLoading && (
        <>
          <InboxMessageEditor />
          {isTemplateSelectorActive && <InboxMessageTemplates />}
          {!isTemplateSelectorActive && <InboxMessagesList />}
        </>
      )}
    </>
  );
};

export default InboxMessagesBody;
