import { useContext } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LeadStatus } from 'models/Leads';
import LeadModalContext from '../../LeadModalContext';
import StarRatingField from '../../../../../forms/starRatingField/StarRatingField';
import TextField from '../../../../../forms/textField/TextField';
import {
  LeadInternalGuestNotesFormColumn,
  LeadInternalGuestNotesInfo,
} from './LeadModalInternalGuestNotesTab.styles';

const LeadModalInternalGuestNotesTab = () => {
  const { lead } = useContext(LeadModalContext);
  const { t } = useTranslation();

  const isLeadPending = lead?.status === LeadStatus.PENDING;

  return (
    <Row>
      <LeadInternalGuestNotesFormColumn md={8}>
        <StarRatingField
          label={t('componentLead.modal.form.cleanliness')}
          name="reviewByHost.cleanlinessRating"
          colSmInput={8}
          colSmLabel={4}
          readonly={isLeadPending}
        />
        <StarRatingField
          label={t('componentLead.modal.form.communication')}
          name="reviewByHost.communicationRating"
          colSmInput={8}
          colSmLabel={4}
          readonly={isLeadPending}
        />
        <StarRatingField
          label={t('componentLead.modal.form.respectHouseRules')}
          name="reviewByHost.respectRulesRating"
          colSmInput={8}
          colSmLabel={4}
          readonly={isLeadPending}
        />
        <StarRatingField
          label={t('componentLead.modal.form.overall')}
          name="reviewByHost.overallRating"
          colSmInput={8}
          colSmLabel={4}
          readonly={isLeadPending}
        />
        <TextField
          label={t('componentLead.modal.form.notes')}
          placeholder={t('componentLead.modal.form.notes')}
          name="reviewByHost.notes"
          colSmInput={8}
          colSmLabel={4}
          componentClass="textarea"
          rows={6}
        />
      </LeadInternalGuestNotesFormColumn>
      <LeadInternalGuestNotesFormColumn md={4}>
        <LeadInternalGuestNotesInfo>
          {t('componentLead.modal.form.internalGuestNotesInfo')}
        </LeadInternalGuestNotesInfo>
      </LeadInternalGuestNotesFormColumn>
    </Row>
  );
};

export default LeadModalInternalGuestNotesTab;
