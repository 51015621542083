import styled from 'styled-components';

export const DeactivationModalFooterStyled = styled.div`
  display: flex;
  justify-content: flex-end;

  > button:first-child {
    margin-right: auto;
  }
`;
