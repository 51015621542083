import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { breakpoints } from 'styles/mediaQuery';
import { CollapseSectionStyled } from 'pages/property/common/Common.styles';

export const PropertyMiscInfoCollapseStyled = styled(CollapseSectionStyled)``;

export const DateTimePickerFieldCol = styled(Col).attrs({
  xs: 12,
  sm: 7,
  smOffset: 5,
})`
  // only apply fix for > XS screens
  ${`@media (min-width: ${breakpoints.XS}px)`} {
    margin-top: -8px;
    padding-left: 0;

    .form-group {
      margin-bottom: 0;

      .control-label {
        padding-top: 0;
      }
    }
  }
`;
